const app = {
    "id": "60032b045720c30df42fdc7e",
    "type": "project",
    "children": [
        {
            "id": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
            "type": "app",
            "name": "dashboard",
            "sidebar": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
            "active": true,
            "component": "",
            "_sortIndex": 0,
            "startPage": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf"
        },
        {
            "id": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
            "type": "app",
            "name": "homeowners",
            "sidebar": "1aad9460-8ef0-11ea-981d-018b5a72c307",
            "startPage": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
            "active": false,
            "_sortIndex": 10
        },
        {
            "id": "2ad37a40-9138-11ea-bd78-399a56c40918",
            "type": "model",
            "children": [
                {
                    "id": "35bfcda0-9138-11ea-bd78-399a56c40918",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                    "name": "homeowner",
                    "_sortIndex": -1
                },
                {
                    "id": "3040a340-9138-11ea-bd78-399a56c40918",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "name",
                    "_sortIndex": 0
                },
                {
                    "id": "6fa1c410-9138-11ea-bd78-399a56c40918",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "details"
                },
                {
                    "id": "298e0e40-e9a6-11ea-bbba-5944fe032799",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "dueDate"
                },
                {
                    "id": "388318a0-e9a6-11ea-bbba-5944fe032799",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "postingDate"
                }
            ],
            "name": "billing",
            "_sortIndex": 20
        },
        {
            "id": "960f7330-e864-11ea-9def-530e14f0f646",
            "type": "model",
            "children": [
                {
                    "id": "efc17c70-e864-11ea-9def-530e14f0f646",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                    "name": "billing",
                    "_sortIndex": -1
                },
                {
                    "id": "b016f690-e864-11ea-9def-530e14f0f646",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "item",
                    "_sortIndex": 0
                },
                {
                    "id": "b43e8030-e864-11ea-9def-530e14f0f646",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "description",
                    "_sortIndex": 10
                },
                {
                    "id": "b81a5ad0-e864-11ea-9def-530e14f0f646",
                    "type": "field:number",
                    "children": [],
                    "dataType": "number",
                    "name": "amount",
                    "_sortIndex": 20
                },
                {
                    "id": "bec0ee30-e864-11ea-9def-530e14f0f646",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "state",
                    "_sortIndex": 30
                }
            ],
            "name": "billing-item"
        },
        {
            "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
            "type": "model",
            "children": [
                {
                    "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:enumeration",
                    "children": [],
                    "dataType": "enumeration",
                    "name": "type"
                },
                {
                    "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "details"
                },
                {
                    "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photos"
                },
                {
                    "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                    "name": "homeowner"
                },
                {
                    "id": "db5ce460-edd0-11ea-993f-a977bcf36a2d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "notes"
                },
                {
                    "id": "42890350-05e4-11eb-8eea-bf73dbff533a",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "type_"
                }
            ],
            "name": "comments-suggestions",
            "_sortIndex": 30
        },
        {
            "id": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
            "type": "model",
            "children": [
                {
                    "id": "1ba0dd50-8ce8-11ea-a283-ebcb859bf8c8",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "name",
                    "required": true,
                    "searchable": true
                },
                {
                    "id": "22702e60-8ce8-11ea-a283-ebcb859bf8c8",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "description",
                    "required": true
                }
            ],
            "name": "department",
            "active": false,
            "_sortIndex": 50
        },
        {
            "id": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
            "type": "model",
            "children": [
                {
                    "id": "7288d810-8c4e-11ea-9466-6f383d5eb483",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "employeeid",
                    "required": true,
                    "searchable": true,
                    "_sortIndex": 0
                },
                {
                    "id": "49b35e80-9129-11ea-a5e9-ff76f0b9af84",
                    "type": "field:email",
                    "children": [],
                    "dataType": "email",
                    "name": "email",
                    "required": true,
                    "_sortIndex": 9
                },
                {
                    "id": "780a4c60-8c4e-11ea-9466-6f383d5eb483",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "firstName",
                    "required": true,
                    "searchable": true,
                    "_sortIndex": 10
                },
                {
                    "id": "837ebb80-8c4e-11ea-9466-6f383d5eb483",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "lastName",
                    "required": true,
                    "searchable": true,
                    "_sortIndex": 20
                },
                {
                    "id": "e68f77d0-8c91-11ea-bb0b-0b00cf6ac98c",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "birthday",
                    "required": true,
                    "_sortIndex": 30
                },
                {
                    "id": "5979e880-8e9e-11ea-9c0f-c7e69d58f37d",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "picture",
                    "_sortIndex": 50
                },
                {
                    "id": "e7c20f60-14d0-11eb-a8c0-03c9560d42e7",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "role"
                },
                {
                    "id": "20b8cab0-15fe-11eb-bc4b-619622358460",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "role_"
                }
            ],
            "name": "employee",
            "active": false,
            "_sortIndex": 60
        },
        {
            "id": "ed8defd0-911e-11ea-9e19-2d2007118721",
            "type": "model",
            "children": [
                {
                    "id": "06bec240-911f-11ea-9e19-2d2007118721",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "accountNumber",
                    "_sortIndex": -1
                },
                {
                    "id": "3f18fbb0-9129-11ea-a5e9-ff76f0b9af84",
                    "type": "field:email",
                    "children": [],
                    "dataType": "email",
                    "name": "email",
                    "required": true,
                    "_sortIndex": 0
                },
                {
                    "id": "f67bdb20-911e-11ea-9e19-2d2007118721",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "name",
                    "required": true,
                    "_sortIndex": 20
                },
                {
                    "id": "138b4750-911f-11ea-9e19-2d2007118721",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "contact",
                    "_sortIndex": 70
                },
                {
                    "id": "b2c46990-e9f4-11ea-9892-473a233000d1",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "nickname"
                },
                {
                    "id": "f64a0d20-e9fc-11ea-bf3b-ef4612b5d21e",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photo"
                },
                {
                    "id": "f1f4a130-ef98-11ea-9d3f-8def54ed8352",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "parkingUnitNumber"
                },
                {
                    "id": "9fe96300-eff5-11ea-ad56-959d088b5979",
                    "type": "field:boolean",
                    "children": [],
                    "dataType": "boolean",
                    "name": "noticeShown"
                }
            ],
            "name": "homeowner",
            "_sortIndex": 70
        },
        {
            "id": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
            "type": "model",
            "children": [
                {
                    "id": "b3aa23e0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                    "name": "homeowner"
                },
                {
                    "id": "c20c3d10-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:enumeration",
                    "children": [],
                    "dataType": "enumeration",
                    "name": "topic"
                },
                {
                    "id": "d7f25150-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "title"
                },
                {
                    "id": "dd8b6c50-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "price"
                },
                {
                    "id": "e1c656e0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "description"
                },
                {
                    "id": "eb73c1a0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "contactNumber"
                },
                {
                    "id": "f1279090-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photos"
                },
                {
                    "id": "ff6cabe0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                },
                {
                    "id": "2cdc7120-db5c-11ea-968c-6f86147884ca",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "notes"
                },
                {
                    "id": "42925310-f0a4-11ea-a584-176526b5be96",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "datePosted"
                },
                {
                    "id": "68bbe9c0-05e4-11eb-8eea-bf73dbff533a",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "topic_"
                },
                {
                    "id": "3c87b120-1f81-11eb-96ab-9ff3802db7dd",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "status_"
                }
            ],
            "_sortIndex": 80,
            "name": "message-board"
        },
        {
            "id": "77e05b60-911e-11ea-9e19-2d2007118721",
            "type": "model",
            "children": [
                {
                    "id": "7bedcd50-911e-11ea-9e19-2d2007118721",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "name",
                    "required": true
                },
                {
                    "id": "8093aeb0-911e-11ea-9e19-2d2007118721",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "text",
                    "required": true
                },
                {
                    "id": "88181ef0-911e-11ea-9e19-2d2007118721",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "noticeStart",
                    "required": true
                },
                {
                    "id": "97459e70-911e-11ea-9e19-2d2007118721",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "noticeEnd"
                },
                {
                    "id": "9eb945d0-911e-11ea-9e19-2d2007118721",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "source"
                },
                {
                    "id": "a096b760-9129-11ea-a5e9-ff76f0b9af84",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "status"
                },
                {
                    "id": "46f8d4c0-08fe-11eb-ad54-a7f1ee827191",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "name": "homeowner",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                }
            ],
            "name": "notification",
            "_sortIndex": 90
        },
        {
            "id": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
            "type": "model",
            "children": [
                {
                    "id": "c4081f00-d9c6-11ea-93cf-3dd7e562aac3",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                    "name": "unit"
                },
                {
                    "id": "d7708910-d9c6-11ea-93cf-3dd7e562aac3",
                    "type": "field:enumeration",
                    "children": [],
                    "dataType": "enumeration",
                    "name": "type"
                },
                {
                    "id": "e5db54d0-d9c6-11ea-93cf-3dd7e562aac3",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "schedule"
                },
                {
                    "id": "ee4c9610-d9c6-11ea-93cf-3dd7e562aac3",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "items"
                },
                {
                    "id": "f2ed6e60-d9c6-11ea-93cf-3dd7e562aac3",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "company"
                },
                {
                    "id": "00b0fda0-d9c7-11ea-93cf-3dd7e562aac3",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                },
                {
                    "id": "5823d2a0-e828-11ea-99d9-7b0e0405473d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "notes"
                },
                {
                    "id": "fe2aaed0-05e2-11eb-8eea-bf73dbff533a",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "type_"
                },
                {
                    "id": "24bbf460-260d-11eb-bab3-998bf6bd89df",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "status_"
                }
            ],
            "name": "pass-delivery",
            "_sortIndex": 100
        },
        {
            "id": "a5454c50-911e-11ea-9e19-2d2007118721",
            "type": "model",
            "children": [
                {
                    "id": "c7d2c620-d9c7-11ea-93cf-3dd7e562aac3",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                    "name": "unit"
                },
                {
                    "id": "cfdbf6c0-d9c7-11ea-93cf-3dd7e562aac3",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "visitor",
                    "required": true
                },
                {
                    "id": "fa4d2320-d9c7-11ea-93cf-3dd7e562aac3",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "startDate"
                },
                {
                    "id": "ff0ec9e0-d9c7-11ea-93cf-3dd7e562aac3",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "endDate"
                },
                {
                    "id": "06a73750-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "visitorsId"
                },
                {
                    "id": "7b087c60-da1a-11ea-b50d-8323e621ee63",
                    "type": "field:enumeration",
                    "children": [],
                    "dataType": "enumeration",
                    "name": "type"
                },
                {
                    "id": "f23b46a0-f0d6-11ea-9422-3905f8832c96",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                },
                {
                    "id": "6b951970-f796-11ea-8826-7fd8e10f31a2",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "notes"
                },
                {
                    "id": "d69fd980-05e2-11eb-8eea-bf73dbff533a",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "type_"
                },
                {
                    "id": "38e47a20-260d-11eb-bab3-998bf6bd89df",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "status_"
                }
            ],
            "name": "pass-visitor",
            "_sortIndex": 109
        },
        {
            "id": "e682f860-ef70-11ea-983b-e7b329586acc",
            "type": "model",
            "children": [
                {
                    "id": "f6493570-ef70-11ea-983b-e7b329586acc",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "name": "homeowner",
                    "_sortIndex": -1,
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                },
                {
                    "id": "eb4c4040-ef70-11ea-983b-e7b329586acc",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "receipt",
                    "_sortIndex": 0
                },
                {
                    "id": "0a0034b0-ef71-11ea-983b-e7b329586acc",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "postingDate"
                },
                {
                    "id": "b6c349d0-f65b-11ea-bcea-e53105b58e3f",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "statement"
                },
                {
                    "id": "c8fd9420-f65b-11ea-bcea-e53105b58e3f",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "paymentDate"
                }
            ],
            "name": "payment"
        },
        {
            "id": "24ee6e90-ef71-11ea-ae43-d3d081a35642",
            "type": "model",
            "children": [
                {
                    "id": "4aae6ef0-ef71-11ea-ae43-d3d081a35642",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "name": "payment",
                    "dataModel": "555659e0-9138-11ea-bd78-399a56c40918",
                    "_sortIndex": -1
                },
                {
                    "id": "2aa37600-ef71-11ea-ae43-d3d081a35642",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "item",
                    "_sortIndex": 0
                },
                {
                    "id": "3de1e9e0-ef71-11ea-ae43-d3d081a35642",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "description",
                    "_sortIndex": 10
                },
                {
                    "id": "420ea3a0-ef71-11ea-ae43-d3d081a35642",
                    "type": "field:number",
                    "children": [],
                    "dataType": "number",
                    "name": "amount",
                    "_sortIndex": 20
                },
                {
                    "id": "46bbb0f0-ef71-11ea-ae43-d3d081a35642",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "state",
                    "_sortIndex": 30
                }
            ],
            "name": "payment-item"
        },
        {
            "id": "faa20cb0-e506-11ea-8276-65e120bbe853",
            "type": "model",
            "children": [
                {
                    "id": "fe1b06d0-e506-11ea-8276-65e120bbe853",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "name"
                },
                {
                    "id": "0704fa80-e507-11ea-8276-65e120bbe853",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "content"
                }
            ],
            "name": "terms-conditions"
        },
        {
            "id": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
            "type": "model",
            "children": [
                {
                    "id": "a86f3310-d84e-11ea-acd1-c1cd20d0424a",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "number"
                },
                {
                    "id": "bc50ebd0-d84e-11ea-acd1-c1cd20d0424a",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "type",
                    "component": ""
                },
                {
                    "id": "73fa85c0-edd0-11ea-993f-a977bcf36a2d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "areaSize"
                },
                {
                    "id": "817fd380-edd0-11ea-993f-a977bcf36a2d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "classification"
                },
                {
                    "id": "8bc3d8f0-edd0-11ea-993f-a977bcf36a2d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "projectCode"
                },
                {
                    "id": "944805f0-edd0-11ea-993f-a977bcf36a2d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "customerCode"
                },
                {
                    "id": "99e36ae0-edd0-11ea-993f-a977bcf36a2d",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "turnoverDate"
                },
                {
                    "id": "a14a4100-edd0-11ea-993f-a977bcf36a2d",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "name": "homeowner",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                },
                {
                    "id": "53405060-ef99-11ea-9d3f-8def54ed8352",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "greaseTrapCleaning"
                },
                {
                    "id": "ab1423c0-ef99-11ea-9d3f-8def54ed8352",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "airconCleaning"
                }
            ],
            "name": "unit",
            "_sortIndex": 130
        },
        {
            "id": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
            "type": "model",
            "children": [
                {
                    "id": "ffb19150-d84d-11ea-acd1-c1cd20d0424a",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "type"
                },
                {
                    "id": "09a553e0-d84e-11ea-acd1-c1cd20d0424a",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                },
                {
                    "id": "125e2570-d84e-11ea-acd1-c1cd20d0424a",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                    "name": "unit"
                },
                {
                    "id": "47a03900-e828-11ea-99d9-7b0e0405473d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "component": "",
                    "name": "notes"
                },
                {
                    "id": "13c40d30-05e4-11eb-8eea-bf73dbff533a",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "type_"
                },
                {
                    "id": "53f29810-260d-11eb-bab3-998bf6bd89df",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "status_"
                }
            ],
            "name": "unit-request",
            "_sortIndex": 140
        },
        {
            "id": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
            "type": "model",
            "children": [
                {
                    "id": "59d52f00-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "name": "unit",
                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                },
                {
                    "id": "5fa7a980-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:enumeration",
                    "children": [],
                    "dataType": "enumeration",
                    "name": "natureOfWork"
                },
                {
                    "id": "680a92e0-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "contractor"
                },
                {
                    "id": "6cc22780-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "personInCharge"
                },
                {
                    "id": "77ba88d0-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "contactPersonInCharge"
                },
                {
                    "id": "82778ed0-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "workers"
                },
                {
                    "id": "87d65ff0-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "description"
                },
                {
                    "id": "951e5690-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "materials"
                },
                {
                    "id": "9a41cc60-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "tools"
                },
                {
                    "id": "a0da7d10-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "startDate"
                },
                {
                    "id": "a5a376d0-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "endDate"
                },
                {
                    "id": "abf13bd0-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "plans"
                },
                {
                    "id": "afc85b80-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                },
                {
                    "id": "339637c0-e828-11ea-99d9-7b0e0405473d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "notes"
                },
                {
                    "id": "8e04b3c0-05e3-11eb-8eea-bf73dbff533a",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "natureOfWork_"
                },
                {
                    "id": "95f0d1f0-260d-11eb-bab3-998bf6bd89df",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "status_"
                }
            ],
            "name": "work-permit",
            "_sortIndex": 150
        },
        {
            "id": "7a9ce480-9138-11ea-bd78-399a56c40918",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                    "children": [
                        {
                            "id": "2936a240-f0b3-11ea-99d0-d9f36ea6d179",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Billing",
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        },
                        {
                            "id": "7aa7e100-9138-11ea-bd78-399a56c40918",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7aa7e102-9138-11ea-bd78-399a56c40918",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner",
                                    "saveObject": true,
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "7aa7e103-9138-11ea-bd78-399a56c40918",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7aa7e105-9138-11ea-bd78-399a56c40918",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "name",
                                    "label": "Statement",
                                    "dataField": "name"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        },
                        {
                            "id": "016566c0-e9b0-11ea-8b13-df1e5e645c34",
                            "type": "inputDate",
                            "children": [],
                            "name": "postingDate",
                            "dataField": "postingDate",
                            "label": "Posting Date",
                            "_sortIndex": 20
                        },
                        {
                            "id": "04a80ea0-e9b0-11ea-8b13-df1e5e645c34",
                            "type": "inputDate",
                            "children": [],
                            "name": "dueDate",
                            "dataField": "dueDate",
                            "label": "Due Date",
                            "_sortIndex": 30
                        },
                        {
                            "id": "9d8d05d0-efdc-11ea-b8ac-c3abdef265b0",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "component": "BillingUpload",
                            "_sortIndex": 40
                        }
                    ],
                    "_sortIndex": 0,
                    "component": "BillingUpload"
                },
                {
                    "id": "22b50110-e865-11ea-9def-530e14f0f646",
                    "type": "subView",
                    "children": [],
                    "_sortIndex": 10,
                    "view": "3acc2620-e865-11ea-9def-530e14f0f646",
                    "relation": "billing"
                },
                {
                    "id": "7aa39b40-9138-11ea-bd78-399a56c40918",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                    "_sortIndex": 20
                }
            ],
            "orientation": "vertical",
            "name": "billing-edit-page",
            "route": "/billings/:id",
            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
            "component": "",
            "_sortIndex": 160,
            "access": "accounting"
        },
        {
            "id": "3ab60610-e865-11ea-9def-530e14f0f646",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                    "children": [
                        {
                            "id": "1e47b6d0-f0b3-11ea-99d0-d9f36ea6d179",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Billing",
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        },
                        {
                            "id": "3ac76b30-e865-11ea-9def-530e14f0f646",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3ac76b32-e865-11ea-9def-530e14f0f646",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "billing",
                                    "label": "Billing",
                                    "dataField": "billing",
                                    "saveObject": true,
                                    "relation": "2ad37a40-9138-11ea-bd78-399a56c40918"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "3ac76b33-e865-11ea-9def-530e14f0f646",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3ac76b35-e865-11ea-9def-530e14f0f646",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "item",
                                    "label": "Item",
                                    "dataField": "item"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        },
                        {
                            "id": "3ac76b36-e865-11ea-9def-530e14f0f646",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3ac79240-e865-11ea-9def-530e14f0f646",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20
                        },
                        {
                            "id": "3ac79241-e865-11ea-9def-530e14f0f646",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3ac79243-e865-11ea-9def-530e14f0f646",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "amount",
                                    "label": "Amount",
                                    "dataField": "amount"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30
                        },
                        {
                            "id": "3ac79244-e865-11ea-9def-530e14f0f646",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3ac79246-e865-11ea-9def-530e14f0f646",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "state",
                                    "label": "State",
                                    "dataField": "state"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40
                        }
                    ],
                    "component": ""
                },
                {
                    "id": "3abf2dd0-e865-11ea-9def-530e14f0f646",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "billing-item-detail-page",
            "route": "/billing-items/:id",
            "dataModel": "960f7330-e864-11ea-9def-530e14f0f646",
            "access": "super-admin"
        },
        {
            "id": "7aac26c0-9138-11ea-bd78-399a56c40916",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "7aac26c0-9138-11ea-bd78-399a56c40917",
                    "children": [
                        {
                            "id": "c1bc9ce0-f658-11ea-bcea-e53105b58e3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cc9fc880-f658-11ea-bcea-e53105b58e3f",
                                    "type": "button",
                                    "children": [],
                                    "command": "new_billing",
                                    "text": "upload",
                                    "startIcon": "Publish"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "accounting"
                        },
                        {
                            "id": "520b7960-01ee-11eb-81b5-f15d97fd1729",
                            "type": "container",
                            "children": [
                                {
                                    "id": "575a6c00-01ee-11eb-81b5-f15d97fd1729",
                                    "type": "subView",
                                    "children": [],
                                    "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
                                    "component": "Searchbar",
                                    "name": "searchbar"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "7ab48b30-9138-11ea-bd78-399a56c40918",
                            "type": "table",
                            "children": [
                                {
                                    "id": "7ab94620-9138-11ea-bd78-399a56c40918",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner"
                                },
                                {
                                    "id": "7ab94622-9138-11ea-bd78-399a56c40918",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "name",
                                    "label": "Statement",
                                    "dataField": "name"
                                },
                                {
                                    "id": "91576770-f65c-11ea-bcea-e53105b58e3f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "postingDate",
                                    "label": "Posting Date",
                                    "date": true
                                },
                                {
                                    "id": "a45046d0-f65c-11ea-bcea-e53105b58e3f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "dueDate",
                                    "label": "Due Date",
                                    "date": true
                                },
                                {
                                    "id": "4f7ef760-091b-11eb-a3cc-3fd0e63e5f63",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Amount",
                                    "name": "amount",
                                    "currency": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "query": "{\"_limit\": \"100\"}"
                        }
                    ],
                    "component": "BillingUpload"
                }
            ],
            "orientation": "vertical",
            "name": "billing-list-page",
            "route": "/billings-list",
            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
            "_sortIndex": 170,
            "params": ""
        },
        {
            "id": "77a819a0-dd57-11ea-9ac6-91ffe70e2d7c",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                    "children": [
                        {
                            "id": "431dff00-f0a9-11ea-9db2-ab2487441b73",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered",
                            "text": "Comments and Suggestions",
                            "_sortIndex": 0
                        },
                        {
                            "id": "77b90990-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "09eb4ef0-ea54-11ea-8de6-3f209e03f86c",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "typ",
                                    "label": "Type",
                                    "dataField": "type",
                                    "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"}, {\"label\": \"Security\", \"value\": \"security\"}, {\"label\": \"Safety\", \"value\": \"safety\"}, {\"label\": \"PMG Crew/ Team Conduct\", \"value\": \"team-conduct\"} ,{\"label\": \"Others\", \"value\": \"others\"}]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        },
                        {
                            "id": "77b90993-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "77b90995-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "details",
                                    "label": "Details",
                                    "dataField": "details",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20
                        },
                        {
                            "id": "77b90996-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "77b90998-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "photos",
                                    "label": "Photos",
                                    "dataField": "photos",
                                    "basePath": "comments-suggestions"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30
                        },
                        {
                            "id": "77b90999-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "77b9099b-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner",
                                    "saveObject": true,
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40
                        },
                        {
                            "id": "a71fdc40-f0a3-11ea-a584-176526b5be96",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b134e270-f0a3-11ea-a584-176526b5be96",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "notes",
                                    "label": "Notes",
                                    "multiline": true,
                                    "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 49
                        }
                    ]
                },
                {
                    "id": "77b5d540-dd57-11ea-9ac6-91ffe70e2d7c",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "comments-suggestions-edit-page",
            "route": "/comments-suggestions/:id",
            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
            "_sortIndex": 180,
            "requireAuth": true,
            "access": "admin,pmg"
        },
        {
            "id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                    "children": [
                        {
                            "id": "77c369d0-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "77c824c0-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "table",
                            "children": [
                                {
                                    "id": "5a733570-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "date": true,
                                    "format": "MM/dd/yyyy hh:mm a"
                                },
                                {
                                    "id": "633ccfe0-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "type",
                                    "label": "Type"
                                },
                                {
                                    "id": "697f7150-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "details",
                                    "label": "Details"
                                },
                                {
                                    "id": "70e86a50-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "homeowner",
                                    "label": "Homeowner"
                                },
                                {
                                    "id": "76c4a8d0-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/comments-suggestions/detail"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "comments-suggestions-list-page",
            "route": "/comments-suggestions-list",
            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
            "_sortIndex": 190,
            "requireAuth": true,
            "params": "{\"_limit\": 100}"
        },
        {
            "id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "a4892271-dfa5-11ea-a94d-e5d6a6ca6c3b",
                    "children": [
                        {
                            "id": "b81715e0-dfa5-11ea-a94d-e5d6a6ca6c3b",
                            "type": "subView",
                            "children": [],
                            "view": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "dashboard-login",
            "route": "/dashboard-login",
            "component": "",
            "noPadding": false,
            "layout": "full"
        },
        {
            "id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                    "children": [
                        {
                            "id": "f6c93a60-f0ae-11ea-a61b-49eea0a1bb78",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Department",
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        },
                        {
                            "id": "99dbbf60-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "99dbbf62-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "name",
                                    "label": "Department",
                                    "dataField": "name"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "99dbbf63-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "99dbbf65-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description",
                                    "description": "",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        }
                    ],
                    "component": "ArchivedNotifications",
                    "access": "pmg"
                },
                {
                    "id": "99d8b220-912e-11ea-ad57-6526d978cdf6",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "department-detail-page",
            "route": "/departments/:id",
            "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
            "_sortIndex": 200,
            "access": "admin"
        },
        {
            "id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                    "children": [
                        {
                            "id": "94311830-912e-11ea-ad57-6526d978cdf6",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "9433b040-912e-11ea-ad57-6526d978cdf6",
                            "type": "table",
                            "children": [
                                {
                                    "id": "94364850-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "name",
                                    "label": "Name",
                                    "dataField": "name"
                                },
                                {
                                    "id": "94364852-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "department-list-page",
            "route": "/departments-list",
            "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
            "_sortIndex": 210
        },
        {
            "id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf3",
                    "children": [
                        {
                            "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Employee",
                            "className": "has-text-centered",
                            "_sortIndex": 0
                        },
                        {
                            "id": "a5a55360-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a5a55362-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "employeeid",
                                    "label": "Employee ID",
                                    "dataField": "employeeid"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a5a55363-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a5a55365-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "email",
                                    "label": "Email",
                                    "dataField": "email",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a5a55366-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a5a55368-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "firstName",
                                    "label": "First Name",
                                    "dataField": "firstName"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a5a55369-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a5a5536b-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "lastName",
                                    "label": "Last Name",
                                    "dataField": "lastName"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a5a55372-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a5a55374-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "picture",
                                    "label": "Photo",
                                    "dataField": "picture",
                                    "basePath": "employees"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "603f71b0-15fa-11eb-9b3a-dd4b5808ec5b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "632ccda0-15fa-11eb-9b3a-dd4b5808ec5b",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Role",
                                    "name": "role",
                                    "dataField": "role",
                                    "options": "[{\"value\": \"admin\",\"label\": \"Admin\"},{\"value\": \"accounting\",\"label\": \"Accounting\"},{\"value\": \"accounting-staff\",\"label\": \"Accounting Staff\"},{\"value\": \"pmg\",\"label\": \"PMG\"},{\"value\": \"pmg-ops\",\"label\": \"PMG-OPS\"},{\"value\": \"pmg-security\",\"label\": \"PMG-Security\"}]",
                                    "required": true,
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "a5a26d30-912e-11ea-ad57-6526d978cdf6",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "employee-detail-page",
            "route": "/employees/:id",
            "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
            "_sortIndex": 220,
            "access": "admin"
        },
        {
            "id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                    "children": [
                        {
                            "id": "a5aa8380-912e-11ea-ad57-6526d978cdf6",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "a5ae05f0-912e-11ea-ad57-6526d978cdf6",
                            "type": "table",
                            "children": [
                                {
                                    "id": "a5b0c510-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "employeeid",
                                    "label": "Employee ID",
                                    "dataField": "employeeid"
                                },
                                {
                                    "id": "a5b0c512-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "email",
                                    "label": "Email",
                                    "dataField": "email"
                                },
                                {
                                    "id": "a5b0c514-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "firstName",
                                    "label": "First Name",
                                    "dataField": "firstName"
                                },
                                {
                                    "id": "a5b0c516-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "lastName",
                                    "label": "Last Name",
                                    "dataField": "lastName"
                                },
                                {
                                    "id": "a5b0c51a-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "",
                                    "label": "User Role",
                                    "dataField": "role"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "employee-list-page",
            "route": "/employees-list",
            "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
            "_sortIndex": 230
        },
        {
            "id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "4840fc41-9149-11ea-a6b6-e7e287632ad5",
                    "children": [
                        {
                            "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "My Account",
                            "className": "has-text-centered mt-2",
                            "_sortIndex": 0
                        },
                        {
                            "id": "707a9a30-ea14-11ea-a21e-f569cc759e68",
                            "type": "container",
                            "children": [
                                {
                                    "id": "75cbd6c0-ea14-11ea-a21e-f569cc759e68",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "864c1500-ea14-11ea-a21e-f569cc759e68",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_profile.svg\"><img src=\"./assets/images/icon_profile.svg\" /></object>My Profile</div>",
                                            "path": "/account/profile/me"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "97a5ea60-ea14-11ea-a21e-f569cc759e68",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a301ef80-ea14-11ea-a21e-f569cc759e68",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_password.svg\"><img src=\"./assets/images/icon_password.svg\" /></object>Change Password</div>",
                                            "path": "/account/change-password"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "b0937240-ea14-11ea-a21e-f569cc759e68",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b9cc4940-ea14-11ea-a21e-f569cc759e68",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pay.svg\"><img src=\"./assets/images/icon_pay.svg\" /></object>Pay Dues</div>",
                                            "path": "/account/pay-dues"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "c3ebafb0-ea14-11ea-a21e-f569cc759e68",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ccf909e0-ea14-11ea-a21e-f569cc759e68",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_balance.svg\"><img src=\"./assets/images/icon_balance.svg\" /></object>View Balance</div>",
                                            "path": "/account/billings"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "app-menus-container"
                        }
                    ],
                    "component": "",
                    "className": ""
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-account",
            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
            "route": "/account",
            "component": "",
            "_sortIndex": 240
        },
        {
            "id": "0e752c00-96d2-11ea-9995-9d94cc393546",
            "type": "page",
            "children": [
                {
                    "id": "62b4dc20-96d2-11ea-8416-c79b465e39ab",
                    "type": "container",
                    "children": [
                        {
                            "id": "6560c240-96d2-11ea-8416-c79b465e39ab",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Billing",
                            "className": "has-text-centered my-1",
                            "_sortIndex": 0
                        },
                        {
                            "id": "fedcfca0-2704-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "08314ae0-2705-11eb-ac91-c74c3e25a4bd",
                                    "type": "button",
                                    "children": [],
                                    "variant": "text",
                                    "text": "Billing History",
                                    "path": "/account/billings/history"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center",
                            "_sortIndex": 10
                        },
                        {
                            "id": "295dc160-8d37-11eb-98a1-51a86d4f803a",
                            "type": "container",
                            "children": [
                                {
                                    "id": "32b4a7b0-8d37-11eb-98a1-51a86d4f803a",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "",
                                    "className": "has-text-weight-semibold",
                                    "component": "TotalBalance"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "",
                            "_sortIndex": 20,
                            "className": "my-1"
                        },
                        {
                            "id": "7b996350-96d2-11ea-8416-c79b465e39ab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "176e5780-f6f1-11ea-b61a-f5b3d409e561",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "18452f80-f6f1-11ea-b61a-f5b3d409e561",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "name",
                                            "label": "Statement"
                                        },
                                        {
                                            "id": "20d7db70-f6f1-11ea-b61a-f5b3d409e561",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "postingDate",
                                            "label": "Posting Date"
                                        },
                                        {
                                            "id": "27b33a70-f6f1-11ea-b61a-f5b3d409e561",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "dueDate",
                                            "label": "Due Date"
                                        },
                                        {
                                            "id": "6ec64a20-8b9c-11eb-bcdf-a5161bfc24c5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "amount",
                                            "label": "Balance",
                                            "currency": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": "/account/homeowner-billings"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "main",
                            "_sortIndex": 29
                        }
                    ],
                    "orientation": "vertical"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-billing",
            "route": "/account/billings",
            "_sortIndex": 250,
            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918"
        },
        {
            "id": "c886d3d0-914d-11ea-b2e4-0783314da7b2",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                    "children": [
                        {
                            "id": "e44eb2c0-f0ae-11ea-a61b-49eea0a1bb78",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "_sortIndex": -1,
                            "text": "Comments and Suggestions",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                    "type": "button",
                                    "children": [],
                                    "text": "Comments and Suggestions history",
                                    "path": "/filed-complaints",
                                    "variant": "text"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0,
                            "className": "justify-content-center"
                        },
                        {
                            "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "type",
                                    "label": "Type of issue",
                                    "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"}, {\"label\": \"Security\", \"value\": \"security\"}, {\"label\": \"Safety\", \"value\": \"safety\"}, {\"label\": \"PMG Crew/ Team Conduct\", \"value\": \"team-conduct\"} ,{\"label\": \"Others\", \"value\": \"others\"}]",
                                    "saveObject": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10,
                            "className": ""
                        },
                        {
                            "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "details",
                                    "label": "Details of the issue/concern",
                                    "multiline": true,
                                    "name": "",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20,
                            "className": ""
                        },
                        {
                            "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                    "type": "inputMedia",
                                    "children": [],
                                    "label": "Photo",
                                    "dataField": "photos",
                                    "basePath": "comments-suggestions",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30,
                            "className": ""
                        },
                        {
                            "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e6ce6cf0-1225-11eb-8323-55a37c955b67",
                                    "type": "subView",
                                    "children": [],
                                    "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40,
                            "className": ""
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-comments-suggestions",
            "route": "/homeowner-comments-suggestions/:id",
            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
            "className": "",
            "_sortIndex": 260,
            "access": ""
        },
        {
            "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                    "children": [
                        {
                            "id": "def43920-9150-11ea-9407-3325ebf78f84",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Comments and Suggestions",
                            "_sortIndex": 0,
                            "name": "",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                    "type": "button",
                                    "children": [],
                                    "text": "View complaint history",
                                    "path": "/filed-complaints",
                                    "variant": "text"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 9,
                            "className": "justify-content-center"
                        },
                        {
                            "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "type",
                                    "label": "Type of issue"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10,
                            "className": ""
                        },
                        {
                            "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "details",
                                    "label": "Details of the issue/concern",
                                    "multiline": true,
                                    "name": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20,
                            "className": ""
                        },
                        {
                            "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                    "type": "inputMedia",
                                    "children": [],
                                    "label": "Photo",
                                    "dataField": "photo"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40,
                            "className": ""
                        },
                        {
                            "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                    "type": "button",
                                    "children": [],
                                    "text": "Save",
                                    "command": "save",
                                    "variant": "contained",
                                    "color": "primary",
                                    "className": "",
                                    "fullWidth": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 50,
                            "className": ""
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-complaint",
            "route": "/file-complaint/:id",
            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
            "className": "",
            "_sortIndex": 270
        },
        {
            "id": "47440520-971c-11ea-8a71-fd00fe88963d",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "47440521-971c-11ea-8a71-fd00fe88963d",
                    "children": [
                        {
                            "id": "4b1a6180-971c-11ea-8a71-fd00fe88963d",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Comments and Suggestions History",
                            "className": "has-text-centered pb-2"
                        }
                    ]
                },
                {
                    "id": "52a624c0-971c-11ea-8a71-fd00fe88963d",
                    "type": "container",
                    "children": [
                        {
                            "id": "80367620-0372-11eb-b960-0956a98f4295",
                            "type": "table",
                            "children": [
                                {
                                    "id": "8194f4b0-0372-11eb-b960-0956a98f4295",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "type",
                                    "label": "Type"
                                },
                                {
                                    "id": "8ab53aa0-0372-11eb-b960-0956a98f4295",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "details",
                                    "label": "Details"
                                }
                            ],
                            "orientation": "horizontal",
                            "path": ""
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "5a577160-971c-11ea-8a71-fd00fe88963d",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
            "route": "/filed-complaints",
            "name": "homeowner-complaints",
            "className": "",
            "_sortIndex": 280
        },
        {
            "id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "4e8b5d81-9157-11ea-8382-2991800d56bc",
                    "children": [
                        {
                            "id": "268a56f0-da0a-11ea-87ea-f5980a7a1cab",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "name": "",
                            "text": "Delivery/Pull-out Pass",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "3cd79170-da0a-11ea-87ea-f5980a7a1cab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "40347810-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "button",
                                    "children": [],
                                    "text": "My Delivery / Pull-out Passes",
                                    "variant": "text",
                                    "path": "/delivery-passes"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center pt-1 pb-1"
                        },
                        {
                            "id": "590cca40-da0a-11ea-87ea-f5980a7a1cab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5f544db0-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "inputSelect",
                                    "children": [],
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "dataField": "unit",
                                    "label": "Unit",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "66e4a4d0-da0a-11ea-87ea-f5980a7a1cab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6b30cd70-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "type",
                                    "label": "Type of Pass",
                                    "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                    "saveObject": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "7d085e50-da0a-11ea-87ea-f5980a7a1cab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c833a300-edcc-11ea-abfa-8f89576fb378",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "dataField": "schedule",
                                    "label": "Schedule",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "87486c20-da0a-11ea-87ea-f5980a7a1cab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "8c652b30-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "inputList",
                                    "children": [],
                                    "label": "Item(s) - Please enumerate including quantities.",
                                    "dataField": "items",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a733cf20-da0a-11ea-87ea-f5980a7a1cab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ac4da800-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "company",
                                    "label": "Delivery Company",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a788cc00-e7f7-11ea-aa5b-4b966778e568",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "component": "TermsAndConditions",
                            "name": "delivery-pass"
                        },
                        {
                            "id": "df2a4d60-dac7-11ea-9725-dfc9cbd591dc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b51a9440-1225-11eb-8323-55a37c955b67",
                                    "type": "subView",
                                    "children": [],
                                    "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-delivery-pass",
            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
            "route": "/create-delivery-pass/:id",
            "className": "",
            "_sortIndex": 290
        },
        {
            "id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "db3a70c1-96c7-11ea-bb44-c34ef5a44500",
                    "children": [
                        {
                            "id": "e5006c90-96c7-11ea-bb44-c34ef5a44500",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Delivery Passes",
                            "className": "has-text-centered pb-2"
                        },
                        {
                            "id": "e8824050-96c7-11ea-bb44-c34ef5a44500",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f0fafba0-96c7-11ea-bb44-c34ef5a44500",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "ae53bb10-96c8-11ea-bb44-c34ef5a44500",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "unit",
                                            "label": "Unit"
                                        },
                                        {
                                            "id": "b91bbd90-96c8-11ea-bb44-c34ef5a44500",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "type",
                                            "label": "Type"
                                        },
                                        {
                                            "id": "5c160d40-0378-11eb-bfdc-3b5d643d4962",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "schedule",
                                            "date": true,
                                            "label": "Schedule",
                                            "format": "MM/dd/yyyy hh:mm a"
                                        },
                                        {
                                            "id": "647fad60-0378-11eb-bfdc-3b5d643d4962",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "company",
                                            "label": "Company"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": ""
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-delivery-passes",
            "route": "/delivery-passes",
            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
            "_sortIndex": 300
        },
        {
            "id": "91150f60-ef9a-11ea-9d3f-8def54ed8352",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "91150f61-ef9a-11ea-9d3f-8def54ed8352",
                    "children": [
                        {
                            "id": "def43920-9150-11ea-9407-3325ebf78f84",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Homeowner",
                            "_sortIndex": 0,
                            "name": "",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "9132a986-ef9a-11ea-9d3f-8def54ed8352",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9132a988-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "name",
                                    "label": "Registered Name",
                                    "dataField": "name"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0,
                            "dataField": ""
                        },
                        {
                            "id": "9132a98f-ef9a-11ea-9d3f-8def54ed8352",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9132a991-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "nickname",
                                    "label": "Nickname",
                                    "dataField": "nickname"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 19
                        },
                        {
                            "id": "9132a983-ef9a-11ea-9d3f-8def54ed8352",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9132a985-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "email",
                                    "label": "Email Address",
                                    "dataField": "email"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20
                        },
                        {
                            "id": "9132a98c-ef9a-11ea-9d3f-8def54ed8352",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9132a98e-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contact",
                                    "label": "Mobile Number",
                                    "dataField": "contact"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40
                        },
                        {
                            "id": "9132a992-ef9a-11ea-9d3f-8def54ed8352",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9132d090-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "photo",
                                    "label": "Photo",
                                    "dataField": "photo",
                                    "basePath": "homeowners"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 60
                        }
                    ]
                },
                {
                    "id": "9129cfe0-ef9a-11ea-9d3f-8def54ed8352",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-detail-page",
            "route": "/homeowners/:id",
            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
            "component": "",
            "access": "admin,accounting"
        },
        {
            "id": "913e9060-ef9a-11ea-9d3f-8def54ed8352",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "91150f61-ef9a-11ea-9d3f-8def54ed8352",
                    "children": [
                        {
                            "id": "91498ce0-ef9a-11ea-9d3f-8def54ed8352",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "9151f150-ef9a-11ea-9d3f-8def54ed8352",
                            "type": "table",
                            "children": [
                                {
                                    "id": "915acaf0-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "accountNumbe",
                                    "label": "Registered Name",
                                    "dataField": "name"
                                },
                                {
                                    "id": "915acaf4-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "firstName",
                                    "label": "Nickname",
                                    "dataField": "nickname"
                                },
                                {
                                    "id": "915acaf6-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "lastName",
                                    "label": "Email Address",
                                    "dataField": "email"
                                },
                                {
                                    "id": "915acafa-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "nickname",
                                    "label": "Mobile Number",
                                    "dataField": "contact"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-list-page",
            "route": "/homeowners-list",
            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
        },
        {
            "id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "0ad19881-93ea-11ea-99e4-a504a663bd48"
                },
                {
                    "id": "1acacc70-93ea-11ea-9384-d1e4789a27a9",
                    "type": "subView",
                    "children": [],
                    "view": "e1156440-93e9-11ea-99e4-a504a663bd48"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-login-page",
            "component": "",
            "route": "/homeowners-login",
            "_sortIndex": 330,
            "layout": "full"
        },
        {
            "id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "63850e21-a55c-11ea-8feb-c7034be1bc66",
                    "children": [
                        {
                            "id": "da5a80c0-a55c-11ea-99ce-d1e75946b32d",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Marketplace"
                        },
                        {
                            "id": "ec182100-a55c-11ea-99ce-d1e75946b32d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f232a100-a55c-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "07f88310-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "text": "",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_water.svg\"><img src=\"./assets/images/icon_water.svg\" /></object>Drinking Water</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "014dd100-a55d-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "26cecc90-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_gas.svg\"><img src=\"./assets/images/icon_gas.svg\" /></object>Gasul</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "columns is-centered mt-2"
                        },
                        {
                            "id": "b1d7e880-a55d-11ea-99ce-d1e75946b32d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "494d3680-a55d-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "516b0090-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Rice</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "63098010-a55d-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "69f72e90-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_laundry.svg\"><img src=\"./assets/images/icon_laundry.svg\" /></object>Laundry</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "columns is-centered"
                        },
                        {
                            "id": "c6888a00-a55d-11ea-99ce-d1e75946b32d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ccd51680-a55d-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d4ae5d80-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "button",
                                            "children": [],
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pet.svg\"><img src=\"./assets/images/icon_pet.svg\" /></object>Pet Grooming</div>",
                                            "className": "app-menu-item"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "f062cd90-a55d-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f2893000-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_cleaning.svg\"><img src=\"./assets/images/icon_cleaning.svg\" /></object>Cleaning Service</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "columns is-centered"
                        }
                    ],
                    "className": "has-text-centered",
                    "_sortIndex": -1
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-marketplace",
            "route": "/market-place",
            "_sortIndex": 340
        },
        {
            "id": "231003d0-c920-11ea-9080-1563a0e60ff0",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "231003d1-c920-11ea-9080-1563a0e60ff0",
                    "className": "p1",
                    "children": [
                        {
                            "id": "709bda20-c920-11ea-9080-1563a0e60ff0",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Message Board",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "53470b10-c921-11ea-84bc-97bd01295e5b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "566450a0-c921-11ea-84bc-97bd01295e5b",
                                    "type": "button",
                                    "children": [],
                                    "variant": "text",
                                    "text": "New Post",
                                    "path": "/message-board/0"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center pt-1 pb-1"
                        },
                        {
                            "id": "5b0277e0-db4b-11ea-bdb5-fdc0741685fd",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "component": "MessageBoard"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-message-board",
            "route": "/message-board",
            "_sortIndex": 350,
            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
        },
        {
            "id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "233b7c71-c927-11ea-84bc-97bd01295e5b",
                    "children": [
                        {
                            "id": "521b2680-c927-11ea-84bc-97bd01295e5b",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "New Post",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "59e133a0-c927-11ea-84bc-97bd01295e5b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "62ac7bc0-c927-11ea-84bc-97bd01295e5b",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "",
                                    "label": "Post Topic",
                                    "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"LF\", \"label\": \"LF (Looking For)\"}]",
                                    "dataField": "topic",
                                    "required": false,
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "bite-select pt-2"
                        },
                        {
                            "id": "df291320-c927-11ea-8d76-f7e6b086de0b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e2116600-c927-11ea-8d76-f7e6b086de0b",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "",
                                    "label": "Title",
                                    "dataField": "title",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "eb70b0c0-c927-11ea-8d76-f7e6b086de0b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ed3236e0-c927-11ea-8d76-f7e6b086de0b",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "",
                                    "label": "Price",
                                    "dataField": "price",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "f42897f0-c927-11ea-8d76-f7e6b086de0b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f98df8c0-c927-11ea-8d76-f7e6b086de0b",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "",
                                    "label": "Description",
                                    "multiline": true,
                                    "dataField": "description",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "0b6fc2d0-c928-11ea-8d76-f7e6b086de0b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0d2784f0-c928-11ea-8d76-f7e6b086de0b",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "",
                                    "label": "Contact Number",
                                    "dataField": "contactNumber",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "373868e0-c928-11ea-8d76-f7e6b086de0b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "38f5f760-c928-11ea-8d76-f7e6b086de0b",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "",
                                    "label": "Add Photos",
                                    "dataField": "photos",
                                    "basePath": "message-boards",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "bite-select"
                        },
                        {
                            "id": "cf8faf20-e7f7-11ea-aa5b-4b966778e568",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "component": "TermsAndConditions",
                            "name": "message-board"
                        },
                        {
                            "id": "27ad7fc0-c92b-11ea-ad72-2b470c4935f8",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c9ca9980-1225-11eb-8323-55a37c955b67",
                                    "type": "subView",
                                    "children": [],
                                    "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-new-post",
            "route": "/message-board/:id",
            "_sortIndex": 360,
            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
        },
        {
            "id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c2c20490-91b3-11ea-ba7f-61310864338b",
                    "children": [
                        {
                            "id": "d1894830-91b3-11ea-ba7f-61310864338b",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Notifications",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "26512330-96da-11ea-a306-e3cce4ba1daa",
                            "type": "container",
                            "children": [
                                {
                                    "id": "80c3ab50-4015-11eb-903d-a5a43b413486",
                                    "type": "button",
                                    "children": [],
                                    "text": "Archived Notifications",
                                    "variant": "text",
                                    "path": "/notifications-archived"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center pt-1 pb-1"
                        },
                        {
                            "id": "294a17e0-96da-11ea-a306-e3cce4ba1daa",
                            "type": "container",
                            "children": [
                                {
                                    "id": "00b6be60-08f8-11eb-a297-615290505a3f",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "023d5e60-08f8-11eb-a297-615290505a3f",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Date Created",
                                            "name": "createdAt",
                                            "date": true,
                                            "format": "MM/dd/yyyy hh:mm a",
                                            "width": "20"
                                        },
                                        {
                                            "id": "24355a40-08f8-11eb-a297-615290505a3f",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Content",
                                            "dataField": "text"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": "#"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "component": "Notifications"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-notifications",
            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
            "route": "/notifications",
            "_sortIndex": 370,
            "repeat": false
        },
        {
            "id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "a259bcb1-96d2-11ea-8416-c79b465e39ab",
                    "children": [
                        {
                            "id": "a82b73e0-96d2-11ea-8416-c79b465e39ab",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Change password"
                        },
                        {
                            "id": "d3e59b00-96d2-11ea-8416-c79b465e39ab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d049b3a0-0ff4-11eb-98c1-8382fbef0426",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "oldPassword",
                                    "label": "Old Password",
                                    "password": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "d63c5c40-96d2-11ea-8416-c79b465e39ab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d7f38630-0ff4-11eb-98c1-8382fbef0426",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "password",
                                    "description": "",
                                    "label": "New Password",
                                    "password": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e2e88c20-0ff4-11eb-98c1-8382fbef0426",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e4a15fb0-0ff4-11eb-98c1-8382fbef0426",
                                    "type": "button",
                                    "children": [],
                                    "text": "Submit",
                                    "command": "change_password"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "component": "ChangePassword"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-password",
            "route": "/account/change-password",
            "_sortIndex": 380,
            "component": ""
        },
        {
            "id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "id": "2587d890-db5e-11ea-b61b-f3aa751f3b6f",
                    "type": "container",
                    "children": [
                        {
                            "id": "c5cddbb1-c92f-11ea-baed-470d8f727899",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e297edd0-dc30-11ea-abb1-8b4052c10758",
                                    "type": "carousel",
                                    "children": [],
                                    "dataField": "photos",
                                    "text": "Preview",
                                    "direction": "ltr",
                                    "autoplay": false
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "main",
                            "component": "",
                            "flex": "1"
                        },
                        {
                            "id": "3175e480-db5e-11ea-b61b-f3aa751f3b6f",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "flex": "1",
                            "component": "MessageBoardInfo"
                        }
                    ],
                    "orientation": "vertical"
                },
                {
                    "id": "856f1e40-dc39-11ea-b64f-0dfbc9206e7f",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "component": "MessageBoardComments"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-post",
            "route": "/message-board-post/:id",
            "_sortIndex": 390,
            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
            "noPadding": true
        },
        {
            "id": "17466790-96d2-11ea-9995-9d94cc393546",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "0e752c01-96d2-11ea-9995-9d94cc393546",
                    "children": [
                        {
                            "id": "8ad561c0-96d2-11ea-8416-c79b465e39ab",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Profile",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "6beeab70-e9fe-11ea-83ad-8d1a1d915279",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "component": "AccountPhoto",
                            "dataField": "photo"
                        },
                        {
                            "id": "0bcd5e60-e9f1-11ea-847f-6170db74bd04",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c9fed520-e9f2-11ea-afe7-79ef44d4232d",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "firstName",
                                    "className": "mr-1"
                                },
                                {
                                    "id": "cebc0f10-e9f2-11ea-afe7-79ef44d4232d",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "middleInitial",
                                    "className": "mr-1"
                                },
                                {
                                    "id": "d1a5c180-e9f2-11ea-afe7-79ef44d4232d",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "lastName",
                                    "className": "mr-1"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "p-2"
                        },
                        {
                            "id": "74f1d0a0-e9f7-11ea-b01f-c974ed0277bf",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7bc95f10-e9f7-11ea-b01f-c974ed0277bf",
                                    "type": "textBlock",
                                    "children": [],
                                    "className": "mr-3 profile-label",
                                    "text": "Nickname"
                                },
                                {
                                    "id": "88bf3e10-e9f7-11ea-b01f-c974ed0277bf",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "nickname"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "p-2"
                        },
                        {
                            "id": "1e1ae290-e9f1-11ea-847f-6170db74bd04",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1ef42b90-e9f1-11ea-847f-6170db74bd04",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "",
                                    "text": "Email",
                                    "className": "mr-3 profile-label"
                                },
                                {
                                    "id": "a26fba10-e9f2-11ea-afe7-79ef44d4232d",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "email"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "p-2"
                        },
                        {
                            "id": "27b40200-e9f1-11ea-847f-6170db74bd04",
                            "type": "container",
                            "children": [
                                {
                                    "id": "2895fd90-e9f1-11ea-847f-6170db74bd04",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "",
                                    "text": "Contact",
                                    "className": "mr-3 profile-label"
                                },
                                {
                                    "id": "f9d429d0-e9f2-11ea-afe7-79ef44d4232d",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "contact"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "p-2"
                        },
                        {
                            "id": "2b4da380-e9f1-11ea-847f-6170db74bd04",
                            "type": "container",
                            "children": [
                                {
                                    "id": "2cbf5bf0-e9f1-11ea-847f-6170db74bd04",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "",
                                    "text": "Units",
                                    "className": "mr-3 profile-label"
                                },
                                {
                                    "id": "f0c967b0-e9f2-11ea-afe7-79ef44d4232d",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "unit",
                                    "component": "AccountUnits"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "p-2"
                        },
                        {
                            "id": "419db040-ea09-11ea-b4be-3d136e0d16ce",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4301fb30-ea09-11ea-b4be-3d136e0d16ce",
                                    "type": "button",
                                    "children": [],
                                    "fullWidth": false,
                                    "text": "Save",
                                    "command": "save",
                                    "startIcon": "Save"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-profile",
            "route": "/account/profile/:id",
            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
            "_sortIndex": 400
        },
        {
            "id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "df79f2a1-914a-11ea-8bb4-63310f210ad3",
                    "children": [
                        {
                            "id": "376608f0-914b-11ea-9d03-f552f48ec793",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Unit Request"
                        },
                        {
                            "id": "3e665510-914b-11ea-9d03-f552f48ec793",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4c028630-914b-11ea-93c5-9720e806f72b",
                                    "type": "button",
                                    "children": [],
                                    "text": "View service history",
                                    "path": "/service-requests",
                                    "className": "",
                                    "variant": "text"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center pt-1 pb-1",
                            "flex": "[object Object]"
                        },
                        {
                            "id": "427db210-914b-11ea-9d03-f552f48ec793",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bf2b7640-d902-11ea-b7fc-037f001b1342",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "type",
                                    "options": "[{ \"value\": \"repairs\", \"label\": \"Repairs\"},{ \"value\": \"renovation\", \"label\": \"Renovation\"},{ \"value\": \"carSticker\", \"label\": \"Car Sticker\", data: null }]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center pt-2 pb-2",
                            "name": "select"
                        },
                        {
                            "id": "44bc5770-914b-11ea-9d03-f552f48ec793",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a35c3b70-c90d-11ea-8c59-196c3ec4f839",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                    "name": "repairs"
                                },
                                {
                                    "id": "2697efe0-c911-11ea-9dd0-6723feee229e",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                    "name": "renovation"
                                },
                                {
                                    "id": "2a9de7c0-c911-11ea-9dd0-6723feee229e",
                                    "type": "subView",
                                    "children": [],
                                    "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                    "name": "carSticker"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "pt-2 pb-2",
                            "name": "select",
                            "component": "UnitRequest"
                        },
                        {
                            "id": "2ac5bef0-ad21-11ea-8384-7b922845035e",
                            "type": "container",
                            "children": [
                                {
                                    "id": "316b40e0-ad21-11ea-8384-7b922845035e",
                                    "type": "button",
                                    "children": [],
                                    "command": "save",
                                    "variant": "",
                                    "text": "submit",
                                    "color": "primary",
                                    "className": "",
                                    "fullWidth": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "className": "p-1"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-service-request",
            "route": "/create-service-request/:id",
            "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
            "_sortIndex": 410
        },
        {
            "id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "6053d8f1-91b4-11ea-ad0e-b15c87c4d0e0",
                    "children": [
                        {
                            "id": "bc5b9200-91b4-11ea-ad0e-b15c87c4d0e0",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Unit Request"
                        },
                        {
                            "id": "39f86670-91b5-11ea-ad0e-b15c87c4d0e0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3e960a70-91b5-11ea-ad0e-b15c87c4d0e0",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "424ad510-91b5-11ea-ad0e-b15c87c4d0e0",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "",
                                            "dataField": "details"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": "/create-service-request"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3c5ac070-91b5-11ea-ad0e-b15c87c4d0e0",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-service-requests",
            "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
            "route": "/service-requests",
            "_sortIndex": 420
        },
        {
            "id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "d2d04d11-d902-11ea-b7fc-037f001b1342",
                    "className": "",
                    "children": [
                        {
                            "id": "04c0e0a0-d903-11ea-b7fc-037f001b1342",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Unit Request",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "afdbb6d0-d904-11ea-8fb7-85b0ea54cdde",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bfa2b730-d904-11ea-8fb7-85b0ea54cdde",
                                    "type": "button",
                                    "children": [],
                                    "text": "View Unit Requests History",
                                    "variant": "text",
                                    "path": "/unit-requests"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center pt-1 pb-1"
                        },
                        {
                            "id": "84f0dbb0-d906-11ea-bd61-bf1e0d1003bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "020ce720-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "04ead9c0-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "unit",
                                            "label": "Unit",
                                            "dataField": "",
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "nullable": false,
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "unit-container"
                                },
                                {
                                    "id": "21b5e9f0-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "29662520-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "inputSelect",
                                            "children": [],
                                            "saveObject": true,
                                            "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Other Unit Request\", \"value\":\"others\"}]",
                                            "dataField": "type",
                                            "name": "type",
                                            "label": "Type",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "type-container"
                                },
                                {
                                    "id": "47ac1580-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                    "name": "homeowner-unit-request-service-request"
                                },
                                {
                                    "id": "60137d70-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                    "name": "homeowner-unit-request-plans-documents"
                                },
                                {
                                    "id": "71865910-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "subView",
                                    "children": [],
                                    "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                    "name": "homeowner-unit-request-refund-renovation-bond"
                                },
                                {
                                    "id": "7c6788e0-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "subView",
                                    "children": [],
                                    "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                    "name": "homeowner-unit-request-vehicle-sticker"
                                },
                                {
                                    "id": "82e844c0-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "subView",
                                    "children": [],
                                    "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                    "name": "homeowner-unit-request-grease-trap-cleaning"
                                },
                                {
                                    "id": "30acac90-d9a7-11ea-9c8b-b7f572d43c0f",
                                    "type": "subView",
                                    "children": [],
                                    "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                    "name": "homeowner-unit-request-aircon-cleaning"
                                },
                                {
                                    "id": "16922b80-d9a9-11ea-9331-090047662dd2",
                                    "type": "subView",
                                    "children": [],
                                    "name": "homeowner-unit-request-others",
                                    "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "UnitRequest",
                            "name": "homeowner-unit-request"
                        },
                        {
                            "id": "0dd9e6c0-dac8-11ea-9725-dfc9cbd591dc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c2c5e7f0-1222-11eb-a9d8-83db08ab78a0",
                                    "type": "subView",
                                    "children": [],
                                    "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": ""
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-request",
            "className": "",
            "route": "/homeowner-unit-request/:id",
            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
            "_sortIndex": 430
        },
        {
            "id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "b3e97d21-9156-11ea-88a4-7f4a31012a76",
                    "children": [
                        {
                            "id": "cda662f0-9156-11ea-88a4-7f4a31012a76",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Visitor / Tenant Passes",
                            "_sortIndex": 0,
                            "className": "has-text-centered"
                        },
                        {
                            "id": "24752260-9157-11ea-88a4-7f4a31012a76",
                            "type": "container",
                            "children": [
                                {
                                    "id": "80f84ec0-96c2-11ea-8d81-e3df0146485b",
                                    "type": "button",
                                    "children": [],
                                    "path": "/visitor-passes",
                                    "text": "My Visitor / Tenant Passes",
                                    "variant": "text"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 9,
                            "className": "justify-content-center"
                        },
                        {
                            "id": "d2c56bf0-9156-11ea-88a4-7f4a31012a76",
                            "type": "container",
                            "children": [
                                {
                                    "id": "65ddc8e0-da1a-11ea-b50d-8323e621ee63",
                                    "type": "inputSelect",
                                    "children": [],
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "dataField": "unit",
                                    "label": "Unit",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10,
                            "className": ""
                        },
                        {
                            "id": "d4c90420-9156-11ea-88a4-7f4a31012a76",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b1b7f790-da1a-11ea-b50d-8323e621ee63",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "type",
                                    "label": "Type of Pass",
                                    "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"}, {\"label\":\"Long-term Tenant (More than 3 months)\", \"value\":\"long-term\"}]",
                                    "saveObject": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20,
                            "className": ""
                        },
                        {
                            "id": "d80f2e70-9156-11ea-88a4-7f4a31012a76",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0ca06880-da1c-11ea-87e9-9de57275fc27",
                                    "type": "subView",
                                    "children": [],
                                    "name": "homeowner-visitor-pass-visitor-pass",
                                    "view": "824f51f0-da1b-11ea-87e9-9de57275fc27"
                                },
                                {
                                    "id": "f591eef0-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "subView",
                                    "children": [],
                                    "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                    "name": "homeowner-visitor-pass-short-term"
                                },
                                {
                                    "id": "8667e010-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "subView",
                                    "children": [],
                                    "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                    "name": "homeowner-visitor-pass-long-term"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30,
                            "className": "justify-content-center",
                            "component": "VisitorPass",
                            "name": "homeowner-visitor-pass"
                        },
                        {
                            "id": "da09ed00-9156-11ea-88a4-7f4a31012a76",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a2554800-1225-11eb-8323-55a37c955b67",
                                    "type": "subView",
                                    "children": [],
                                    "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40,
                            "className": "justify-content-center"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-visitor-pass",
            "route": "/create-visitor-pass/:id",
            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
            "className": "",
            "_sortIndex": 440
        },
        {
            "id": "09704a70-96c1-11ea-8d81-e3df0146485b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "id": "bf9e7ca0-96c5-11ea-8624-43dc05a96ae0",
                    "type": "container",
                    "children": [
                        {
                            "id": "c95f4850-96c5-11ea-8624-43dc05a96ae0",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Visitor Passes",
                            "className": "has-text-centered pb-2"
                        }
                    ],
                    "orientation": "vertical",
                    "_sortIndex": -1,
                    "className": ""
                },
                {
                    "name": "main",
                    "type": "container",
                    "id": "09704a71-96c1-11ea-8d81-e3df0146485b",
                    "children": [
                        {
                            "id": "e49e00e0-01e9-11eb-bf8e-6d1770bf29d6",
                            "type": "table",
                            "children": [
                                {
                                    "id": "e73f1190-01e9-11eb-bf8e-6d1770bf29d6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "unit",
                                    "name": "unit",
                                    "label": "Unit"
                                },
                                {
                                    "id": "f89675f0-01e9-11eb-bf8e-6d1770bf29d6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "type",
                                    "name": "type",
                                    "label": "Type"
                                },
                                {
                                    "id": "048237f0-01ea-11eb-bf8e-6d1770bf29d6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "startDate",
                                    "name": "startDate",
                                    "label": "Start Date"
                                },
                                {
                                    "id": "14b6ee40-01ea-11eb-bf8e-6d1770bf29d6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "endDate",
                                    "name": "endDate",
                                    "label": "End Date"
                                }
                            ],
                            "orientation": "horizontal",
                            "path": ""
                        }
                    ],
                    "_sortIndex": 0
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-visitor-passes",
            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
            "route": "/visitor-passes",
            "_sortIndex": 450
        },
        {
            "id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
            "type": "page",
            "children": [
                {
                    "id": "478f4730-c6f9-11ea-8043-0381a4f882af",
                    "type": "subView",
                    "children": [],
                    "view": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-welcome-page",
            "route": "/welcome",
            "component": "",
            "_sortIndex": 460,
            "requireAuth": true
        },
        {
            "id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "dac583a1-d9ca-11ea-b444-450a6b52ef90",
                    "children": [
                        {
                            "id": "f6978dd0-d9ca-11ea-b444-450a6b52ef90",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Work Permit",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "a2b3bab0-da09-11ea-87ea-f5980a7a1cab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a4b07510-da09-11ea-87ea-f5980a7a1cab",
                                    "type": "button",
                                    "children": [],
                                    "text": "View Work Permit History",
                                    "variant": "text",
                                    "path": "/work-permits"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center pt-1 pb-1"
                        },
                        {
                            "id": "5ad52cd0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "607dff40-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "inputSelect",
                                    "children": [],
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "9d05c150-d9cb-11ea-83f3-4b9e6a8a3ce6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bb60e760-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "natureOfWork",
                                    "label": "Nature of Work",
                                    "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                    "saveObject": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "d06acc70-d9cb-11ea-83f3-4b9e6a8a3ce6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d1a632a0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "contractor",
                                    "label": "Name of Contractor",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "dfc94700-d9cb-11ea-83f3-4b9e6a8a3ce6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e0f19a60-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "personInCharge",
                                    "label": "Name of Person in Charge",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "ec2bbfa0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "eee8e3d0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "contactPersonInCharge",
                                    "label": "Contact Number of Person in Charge",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4f549340-d9cc-11ea-83f3-4b9e6a8a3ce6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "12242dc0-d9cf-11ea-be94-03767312b052",
                                    "type": "inputList",
                                    "children": [],
                                    "label": "Workers",
                                    "dataField": "workers",
                                    "placeholder": "Worker",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "77fa9030-da06-11ea-8a8e-430e67178950",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7d094580-da06-11ea-8a8e-430e67178950",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "dataField": "description",
                                    "label": "Description of Work",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "f2f0d5b0-da06-11ea-8a8e-430e67178950",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fe279f90-da06-11ea-8a8e-430e67178950",
                                    "type": "inputList",
                                    "children": [],
                                    "dataField": "materials",
                                    "label": "List of Materials",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "2ebf0bc0-da07-11ea-8a8e-430e67178950",
                            "type": "container",
                            "children": [
                                {
                                    "id": "33285950-da07-11ea-8a8e-430e67178950",
                                    "type": "inputList",
                                    "children": [],
                                    "dataField": "tools",
                                    "label": "List of Tools",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3bd32120-da07-11ea-8a8e-430e67178950",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3e8fd020-da07-11ea-8a8e-430e67178950",
                                    "type": "inputDate",
                                    "children": [],
                                    "dataField": "startDate",
                                    "label": "Proposed Start Date ",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5217a910-da07-11ea-8a8e-430e67178950",
                            "type": "container",
                            "children": [
                                {
                                    "id": "558c3660-da07-11ea-8a8e-430e67178950",
                                    "type": "inputDate",
                                    "children": [],
                                    "label": "Target End Date",
                                    "dataField": "endDate",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "67781290-da07-11ea-8a8e-430e67178950",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6a6f0b70-da07-11ea-8a8e-430e67178950",
                                    "type": "inputMedia",
                                    "children": [],
                                    "dataField": "plans",
                                    "label": "Photo of Plans",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "814543c0-e7f7-11ea-aa5b-4b966778e568",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "work-permit",
                            "component": "TermsAndConditions"
                        },
                        {
                            "id": "f8277f00-1225-11eb-8323-55a37c955b67",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fa036af0-1225-11eb-8323-55a37c955b67",
                                    "type": "subView",
                                    "children": [],
                                    "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-work-permit",
            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
            "route": "/homeowner-work-permit/:id",
            "_sortIndex": 470
        },
        {
            "id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                    "children": [
                        {
                            "id": "def43920-9150-11ea-9407-3325ebf78f84",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Message Board",
                            "_sortIndex": 0,
                            "name": "",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "e3205a10-05e5-11eb-bd99-6f65d415d15a",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e6c1c3c0-05e5-11eb-bd99-6f65d415d15a",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "createdAt",
                                    "label": "Date Submitted"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c1110200-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c1110202-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner",
                                    "saveObject": true,
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c1110203-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "22f119c0-ea54-11ea-8de6-3f209e03f86c",
                                    "type": "inputSelect",
                                    "children": [],
                                    "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"LF\", \"label\": \"LF (Looking For)\"}]",
                                    "saveObject": true,
                                    "name": "topic",
                                    "component": "",
                                    "label": "Topic",
                                    "dataField": "topic"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c1110206-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c1110208-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c1110209-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c1112910-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "price",
                                    "label": "Price",
                                    "dataField": "price"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c1112911-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c1112913-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c1112914-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c1112916-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contactNumber",
                                    "label": "Contact Number",
                                    "dataField": "contactNumber"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c1112917-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c1112919-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "",
                                    "label": "Photos",
                                    "dataField": "photos",
                                    "basePath": "message-boards"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c111291a-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a0d34530-f0a2-11ea-be87-f549caf53aba",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Status",
                                    "dataField": "status",
                                    "options": "[{\"value\": \"done\", \"label\": \"Done\"}, {\"value\": \"approved\", \"label\": \"Approved\"}, {\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": "status"
                        },
                        {
                            "id": "c111291d-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c111291f-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "b8a70560-f0b2-11ea-99d0-d9f36ea6d179",
                            "type": "container",
                            "children": [
                                {
                                    "id": "28fa05f0-0360-11eb-ba8d-6d2ea3361677",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "label": "Date Posted",
                                    "dataField": "datePosted"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "c10987f0-df4b-11ea-a4a7-a1e8c04129ca",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "message-board-edit-page",
            "route": "/message-boards/:id",
            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
            "_sortIndex": 750,
            "access": "admin,pmg"
        },
        {
            "id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                    "children": [
                        {
                            "id": "c11b8950-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "c1217cc0-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "table",
                            "children": [
                                {
                                    "id": "c1283380-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "dataField": "",
                                    "date": true
                                },
                                {
                                    "id": "c1283382-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "topic",
                                    "label": "Topic",
                                    "dataField": "topic"
                                },
                                {
                                    "id": "c1285a90-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "c1285a92-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "price",
                                    "label": "Price",
                                    "dataField": "price"
                                },
                                {
                                    "id": "c1285a96-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "contactNumber",
                                    "label": "Description",
                                    "dataField": "description"
                                },
                                {
                                    "id": "c1285a9a-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "status",
                                    "label": "Contact Number",
                                    "dataField": "contactNumber"
                                },
                                {
                                    "id": "5fd419e0-f0a4-11ea-a584-176526b5be96",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "homeowner",
                                    "text": "",
                                    "label": "Homeowner"
                                },
                                {
                                    "id": "6097b800-f0a4-11ea-a584-176526b5be96",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Status",
                                    "dataField": "status",
                                    "name": "status"
                                },
                                {
                                    "id": "5a089900-fdf1-11ea-ba93-5145fd1d7baf",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Date Posted",
                                    "name": "",
                                    "date": true,
                                    "format": "MM-dd-yyyy hh:mm a",
                                    "dataField": "datePosted"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/message-boards/detail"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "message-board-list-page",
            "route": "/message-boards-list",
            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
            "_sortIndex": 760,
            "params": "{\"or:\":[{\"status\": \"pending\"},{\"status\": \"approved\"}]}"
        },
        {
            "id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                    "children": [
                        {
                            "id": "def43920-9150-11ea-9407-3325ebf78f84",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Notifications",
                            "_sortIndex": 0,
                            "name": "",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "a8fabff3-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a8fabff5-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "text",
                                    "label": "Text",
                                    "dataField": "text"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a8fabff6-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a8fabff8-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "noticeStart",
                                    "label": "Notice Start",
                                    "dataField": "noticeStart",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a8fabff9-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a8fabffb-912e-11ea-ad57-6526d978cdf6",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "noticeEnd",
                                    "label": "Notice End",
                                    "dataField": "noticeEnd",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a8fabfff-912e-11ea-ad57-6526d978cdf6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "219108b0-f0a3-11ea-a584-176526b5be96",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "homeowner",
                                    "label": "Homeowner",
                                    "options": "",
                                    "name": "",
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "saveObject": true,
                                    "nullable": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "a8f7b2b0-912e-11ea-ad57-6526d978cdf6",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "notification-detail-page",
            "route": "/notifications/:id",
            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
            "_sortIndex": 480,
            "access": "admin,pmg"
        },
        {
            "id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf7",
                    "children": [
                        {
                            "id": "a9012890-912e-11ea-ad57-6526d978cdf6",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "a9045ce0-912e-11ea-ad57-6526d978cdf6",
                            "type": "table",
                            "children": [
                                {
                                    "id": "b9b72450-2490-11eb-8324-69ec80c4d07e",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Created",
                                    "date": true,
                                    "width": "15"
                                },
                                {
                                    "id": "a907b840-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "homeowner",
                                    "label": "Sent To",
                                    "dataField": "homeowner",
                                    "date": false,
                                    "format": "",
                                    "width": ""
                                },
                                {
                                    "id": "a907b842-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "",
                                    "label": "Content",
                                    "dataField": "text",
                                    "width": "50"
                                },
                                {
                                    "id": "a907b844-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "noticeStart",
                                    "label": "noticeStart",
                                    "dataField": "noticeStart",
                                    "date": true
                                },
                                {
                                    "id": "a907b846-912e-11ea-ad57-6526d978cdf6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "noticeEnd",
                                    "label": "noticeEnd",
                                    "dataField": "noticeEnd",
                                    "date": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "access": ""
                        }
                    ],
                    "component": "AllNotifications"
                }
            ],
            "orientation": "vertical",
            "name": "notification-list-page",
            "route": "/notifications-list",
            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
            "_sortIndex": 490,
            "component": ""
        },
        {
            "id": "61855560-e82a-11ea-bffd-65b1c6e24159",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                    "children": [
                        {
                            "id": "a5ad4300-f0af-11ea-a61b-49eea0a1bb78",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Delivery",
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        },
                        {
                            "id": "61a11ac0-e82a-11ea-bffd-65b1c6e24159",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6e9301c0-fdd9-11ea-810b-8f97cf48f34d",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "unit",
                                    "name": "unit",
                                    "label": "Unit",
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "61a141d0-e82a-11ea-bffd-65b1c6e24159",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f0707b30-ea53-11ea-8de6-3f209e03f86c",
                                    "type": "inputSelect",
                                    "children": [],
                                    "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                    "saveObject": true,
                                    "name": "type",
                                    "label": "Type",
                                    "dataField": "type",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        },
                        {
                            "id": "61a141d3-e82a-11ea-bffd-65b1c6e24159",
                            "type": "container",
                            "children": [
                                {
                                    "id": "82f752a0-fdc6-11ea-a2b5-65e9923dd687",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "schedule",
                                    "dataField": "schedule",
                                    "label": "Schedule",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20
                        },
                        {
                            "id": "61a141d6-e82a-11ea-bffd-65b1c6e24159",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9be1f780-fdca-11ea-a5e4-656dbac2217a",
                                    "type": "inputList",
                                    "children": [],
                                    "name": "items",
                                    "label": "Items",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30
                        },
                        {
                            "id": "61a141d9-e82a-11ea-bffd-65b1c6e24159",
                            "type": "container",
                            "children": [
                                {
                                    "id": "61a141db-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "company",
                                    "label": "Company",
                                    "dataField": "company",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40
                        },
                        {
                            "id": "61a141dc-e82a-11ea-bffd-65b1c6e24159",
                            "type": "container",
                            "children": [
                                {
                                    "id": "91342c40-f0af-11ea-a61b-49eea0a1bb78",
                                    "type": "inputSelect",
                                    "children": [],
                                    "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                    "dataField": "status",
                                    "label": "Status"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 50
                        },
                        {
                            "id": "61a141df-e82a-11ea-bffd-65b1c6e24159",
                            "type": "container",
                            "children": [
                                {
                                    "id": "61a141e1-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "multiline": true,
                                    "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 60
                        }
                    ]
                },
                {
                    "id": "619ad930-e82a-11ea-bffd-65b1c6e24159",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "pass-delivery-edit-page",
            "route": "/pass-deliveries/:id",
            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
            "access": "admin,pmg"
        },
        {
            "id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                    "children": [
                        {
                            "id": "61ad9de0-e82a-11ea-bffd-65b1c6e24159",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "61b34330-e82a-11ea-bffd-65b1c6e24159",
                            "type": "table",
                            "children": [
                                {
                                    "id": "0909f810-01ec-11eb-81b5-f15d97fd1729",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "date": true
                                },
                                {
                                    "id": "61ba4810-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit"
                                },
                                {
                                    "id": "61ba4812-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "type",
                                    "label": "Type",
                                    "dataField": "type"
                                },
                                {
                                    "id": "61ba4814-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "schedule",
                                    "label": "Schedule",
                                    "dataField": "schedule",
                                    "date": true,
                                    "format": "MM/dd/yyyy hh:mm a"
                                },
                                {
                                    "id": "61ba4818-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "company",
                                    "label": "Company",
                                    "dataField": "company"
                                },
                                {
                                    "id": "61ba6f20-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status"
                                },
                                {
                                    "id": "61ba6f22-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/pass-deliveries-list/detail"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "pass-delivery-list-page",
            "route": "/pass-deliveries-list",
            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
            "params": "{\"or:\":[{\"status\": \"pending\"},{\"status\": \"approved\"}]}"
        },
        {
            "id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                    "children": [
                        {
                            "id": "c4927940-f0b2-11ea-99d0-d9f36ea6d180",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Visitor / Tenant Passes",
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        },
                        {
                            "id": "3fb18ad0-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3fb18ad2-df4c-11ea-a4a7-a1e8c04129ca",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit",
                                    "saveObject": true,
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3fb18ad9-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d5c38580-ea52-11ea-8de6-3f209e03f86c",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "type",
                                    "label": "Type",
                                    "dataField": "type",
                                    "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3fb18adc-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e8041160-ea52-11ea-8de6-3f209e03f86c",
                                    "type": "subView",
                                    "children": [],
                                    "view": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                                    "name": "homeowner-visitor-pass-visitor-pass"
                                },
                                {
                                    "id": "8b0b9a40-ea53-11ea-8de6-3f209e03f86c",
                                    "type": "subView",
                                    "children": [],
                                    "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                    "name": "homeowner-visitor-pass-long-term"
                                },
                                {
                                    "id": "983a1570-ea53-11ea-8de6-3f209e03f86c",
                                    "type": "subView",
                                    "children": [],
                                    "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                    "name": "homeowner-visitor-pass-short-term"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "VisitorPass",
                            "name": "homeowner-visitor-pass"
                        },
                        {
                            "id": "1f8ccac0-f726-11ea-80a6-010ee6be16e5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6e8fb0b0-f726-11ea-80a6-010ee6be16e5",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "status",
                                    "label": "Status",
                                    "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]",
                                    "saveObject": true,
                                    "dataField": "status"
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": "status"
                        },
                        {
                            "id": "92fe8410-f796-11ea-8826-7fd8e10f31a2",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a2dd2b20-f796-11ea-8826-7fd8e10f31a2",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "required": false,
                                    "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "3faafb20-df4c-11ea-a4a7-a1e8c04129ca",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "pass-visitor-edit-page",
            "route": "/pass-visitors/:id",
            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
            "access": "admin,pmg"
        },
        {
            "id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                    "children": [
                        {
                            "id": "4f6bd110-f7a2-11ea-a255-11f808a5c391",
                            "type": "subView",
                            "children": [],
                            "name": "search",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "3fc22ca0-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "table",
                            "children": [
                                {
                                    "id": "fb4ba560-035e-11eb-ba8d-6d2ea3361677",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Date Submitted",
                                    "name": "createdAt",
                                    "date": true
                                },
                                {
                                    "id": "3fc62440-df4c-11ea-a4a7-a1e8c04129ca",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit"
                                },
                                {
                                    "id": "3fc6244a-df4c-11ea-a4a7-a1e8c04129ca",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "type",
                                    "label": "Type",
                                    "dataField": "type"
                                },
                                {
                                    "id": "dd3b7a00-ea4f-11ea-b967-5baa6500e444",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Start Date",
                                    "dataField": "startDate",
                                    "date": true
                                },
                                {
                                    "id": "e479b980-ea4f-11ea-b967-5baa6500e444",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "endDate",
                                    "text": "",
                                    "label": "End Date",
                                    "date": true
                                },
                                {
                                    "id": "a2920620-ea55-11ea-8de6-3f209e03f86c",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "status",
                                    "label": "Status"
                                },
                                {
                                    "id": "3191a6e0-1d6a-11eb-8af7-05ffba406648",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "notes",
                                    "label": "Notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/pass-visitors-list/detail"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "pass-visitor-list-page",
            "route": "/pass-visitors-list",
            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
            "params": "{\"or:\":[{\"status\": \"pending\"},{\"status\": \"approved\"},{\"status\": \"checked-in\"}]}"
        },
        {
            "id": "50c11f60-ef74-11ea-8620-1757bce541ea",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "50c11f61-ef74-11ea-8620-1757bce541ea",
                    "children": [
                        {
                            "id": "7542f9f0-f0b2-11ea-99d0-d9f36ea6d179",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Payment",
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        },
                        {
                            "id": "50d17310-ef74-11ea-8620-1757bce541ea",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50d17312-ef74-11ea-8620-1757bce541ea",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner",
                                    "saveObject": true,
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "50d17313-ef74-11ea-8620-1757bce541ea",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50d17315-ef74-11ea-8620-1757bce541ea",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "receipt",
                                    "label": "Receipt",
                                    "dataField": "receipt"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        },
                        {
                            "id": "50d17319-ef74-11ea-8620-1757bce541ea",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50d1731b-ef74-11ea-8620-1757bce541ea",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "postingDate",
                                    "label": "Posting Date",
                                    "dataField": "postingDate",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30
                        },
                        {
                            "id": "3b2e9910-f669-11ea-a7eb-5542a4efbc2d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3e00a4d0-f669-11ea-a7eb-5542a4efbc2d",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "statement",
                                    "label": "Statement"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "_sortIndex": 0
                },
                {
                    "id": "7a7eda40-ef74-11ea-b56c-03bb3a7ec541",
                    "type": "subView",
                    "children": [],
                    "view": "927fbf90-ef71-11ea-ae43-d3d081a35642",
                    "_sortIndex": 10,
                    "relation": "payment"
                },
                {
                    "id": "50cb3180-ef74-11ea-8620-1757bce541ea",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                    "_sortIndex": 30
                }
            ],
            "orientation": "vertical",
            "name": "payment-detail-page",
            "route": "/payments/:id",
            "dataModel": "e682f860-ef70-11ea-983b-e7b329586acc",
            "access": "accounting"
        },
        {
            "id": "9273b1a0-ef71-11ea-ae43-d3d081a35642",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "9273b1a1-ef71-11ea-ae43-d3d081a35642",
                    "children": [
                        {
                            "id": "86c58d00-f0b2-11ea-99d0-d9f36ea6d179",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Payment",
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        },
                        {
                            "id": "927bef00-ef71-11ea-ae43-d3d081a35642",
                            "type": "container",
                            "children": [
                                {
                                    "id": "927bef02-ef71-11ea-ae43-d3d081a35642",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "payment",
                                    "label": "Payment",
                                    "dataField": "payment",
                                    "saveObject": true,
                                    "relation": "555659e0-9138-11ea-bd78-399a56c40918"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "927bef03-ef71-11ea-ae43-d3d081a35642",
                            "type": "container",
                            "children": [
                                {
                                    "id": "927bef05-ef71-11ea-ae43-d3d081a35642",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "item",
                                    "label": "Item",
                                    "dataField": "item"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        },
                        {
                            "id": "927bef06-ef71-11ea-ae43-d3d081a35642",
                            "type": "container",
                            "children": [
                                {
                                    "id": "927bef08-ef71-11ea-ae43-d3d081a35642",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20
                        },
                        {
                            "id": "927bef09-ef71-11ea-ae43-d3d081a35642",
                            "type": "container",
                            "children": [
                                {
                                    "id": "927bef0b-ef71-11ea-ae43-d3d081a35642",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "amount",
                                    "label": "Amount",
                                    "dataField": "amount"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30
                        },
                        {
                            "id": "927bef0c-ef71-11ea-ae43-d3d081a35642",
                            "type": "container",
                            "children": [
                                {
                                    "id": "927bef0e-ef71-11ea-ae43-d3d081a35642",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "state",
                                    "label": "State",
                                    "dataField": "state"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40
                        }
                    ]
                },
                {
                    "id": "927893a0-ef71-11ea-ae43-d3d081a35642",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "payment-item-detail-page",
            "route": "/payment-items/:id",
            "dataModel": "24ee6e90-ef71-11ea-ae43-d3d081a35642"
        },
        {
            "id": "50d606f0-ef74-11ea-8620-1757bce541eb",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "50d606f0-ef74-11ea-8620-1757bce541ec",
                    "children": [
                        {
                            "id": "19d6c0b0-f65c-11ea-bcea-e53105b58e3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1e879e90-f65c-11ea-bcea-e53105b58e3f",
                                    "type": "button",
                                    "children": [],
                                    "command": "new_payment",
                                    "text": "upload",
                                    "startIcon": "Publish"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "accounting"
                        },
                        {
                            "id": "e727bc30-01ed-11eb-81b5-f15d97fd1729",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ea6f1f00-01ed-11eb-81b5-f15d97fd1729",
                                    "type": "subView",
                                    "children": [],
                                    "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
                                    "component": "Searchbar",
                                    "name": "searchbar"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "",
                            "name": ""
                        },
                        {
                            "id": "50e10370-ef74-11ea-8620-1757bce541ea",
                            "type": "table",
                            "children": [
                                {
                                    "id": "bbf57030-f65c-11ea-bcea-e53105b58e3f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "receipt",
                                    "label": "Receipt"
                                },
                                {
                                    "id": "c247f020-f65c-11ea-bcea-e53105b58e3f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "homeowner",
                                    "label": "Homeowner"
                                },
                                {
                                    "id": "c84ec110-f65c-11ea-bcea-e53105b58e3f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "statement",
                                    "label": "Statement"
                                },
                                {
                                    "id": "2d7961b0-f669-11ea-a7eb-5542a4efbc2d",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Posting Date",
                                    "dataField": "postingDate",
                                    "date": true
                                },
                                {
                                    "id": "db6384b0-2eb6-11eb-8f48-9f702d09244d",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "amount",
                                    "label": "Amt Paid",
                                    "currency": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ],
                    "component": "PaymentUpload"
                }
            ],
            "orientation": "vertical",
            "name": "payment-list-page",
            "route": "/payments-list",
            "dataModel": "e682f860-ef70-11ea-983b-e7b329586acc"
        },
        {
            "id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                    "children": [
                        {
                            "id": "c4927940-f0b2-11ea-99d0-d9f36ea6d179",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Terms and Conditions",
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        },
                        {
                            "id": "5aa647c0-e507-11ea-8276-65e120bbe853",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5aa647c2-e507-11ea-8276-65e120bbe853",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "name",
                                    "label": "Name",
                                    "dataField": "name"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "5aa647c3-e507-11ea-8276-65e120bbe853",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5aa66ed0-e507-11ea-8276-65e120bbe853",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "content",
                                    "label": "Content",
                                    "dataField": "content",
                                    "multiline": true,
                                    "line": "20"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        }
                    ]
                },
                {
                    "id": "5aa1daf0-e507-11ea-8276-65e120bbe853",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "terms-conditions-detail-page",
            "route": "/terms-conditions/:id",
            "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853",
            "access": "admin,pmg"
        },
        {
            "id": "5aabc600-e507-11ea-8276-65e120bbe853",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                    "children": [
                        {
                            "id": "5ab16b50-e507-11ea-8276-65e120bbe853",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "5ab6e990-e507-11ea-8276-65e120bbe853",
                            "type": "table",
                            "children": [
                                {
                                    "id": "5ababa20-e507-11ea-8276-65e120bbe853",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "name",
                                    "label": "Name",
                                    "dataField": "name"
                                },
                                {
                                    "id": "5ababa22-e507-11ea-8276-65e120bbe853",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "content",
                                    "label": "Content",
                                    "dataField": "content"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "terms-conditions-list-page",
            "route": "/terms-conditions-list",
            "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853"
        },
        {
            "id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                    "children": [
                        {
                            "id": "def43920-9150-11ea-9407-3325ebf78f84",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Units",
                            "_sortIndex": 0,
                            "name": "",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "0824d8f0-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0824d8f2-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "number",
                                    "label": "Unit Number",
                                    "dataField": "number"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "0824d8f6-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b50bf760-12ab-11eb-a859-31a630fb4ca4",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "type",
                                    "label": "Type",
                                    "dataField": "type",
                                    "options": "[{\"value\":\"Studio\", \"label\": \"Studio\"},{\"value\":\"One Bedroom\", \"label\": \"One Bedroom\"},{\"value\":\"Two Bedroom\", \"label\": \"Two Bedroom\"}, {\"value\":\"Parking\", \"label\": \"Parking\"}]",
                                    "saveObject": true,
                                    "placeholder": "",
                                    "preselected": false
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "40a367e0-edd1-11ea-993f-a977bcf36a2d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "56825030-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "areaSize",
                                    "label": "Floor Area (sqm)",
                                    "dataField": "areaSize"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "",
                            "dataField": ""
                        },
                        {
                            "id": "6208e7c0-edd1-11ea-993f-a977bcf36a2d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1902f3a0-12b0-11eb-a48f-8f1c197a5101",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "classification",
                                    "dataField": "classification",
                                    "label": "Classification",
                                    "options": "[{\"value\":\"leased\", \"label\": \"Leased\"},{\"value\":\"own-use\", \"label\": \"Own Use\"},{\"value\":\"rented-out\", \"label\": \"Rented Out\"}]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "773fa930-edd1-11ea-993f-a977bcf36a2d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7942cc30-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "projectCode",
                                    "name": "projectCode",
                                    "label": "Project Code"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "9dcb8510-edd1-11ea-993f-a977bcf36a2d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b88f0570-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "customerCode",
                                    "label": "Customer Code",
                                    "dataField": "customerCode"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c24f5bf0-edd1-11ea-993f-a977bcf36a2d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d57a6ee0-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "turnoverDate",
                                    "label": "Turnover Date",
                                    "dataField": "turnoverDate"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": ""
                        },
                        {
                            "id": "f37a2e80-edd1-11ea-993f-a977bcf36a2d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c11401b0-12b0-11eb-a48f-8f1c197a5101",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "homeowner",
                                    "dataField": "homeowner",
                                    "label": "Homeowner",
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": ""
                        },
                        {
                            "id": "71d63580-ef99-11ea-9d3f-8def54ed8352",
                            "type": "container",
                            "children": [
                                {
                                    "id": "dc08f230-ef99-11ea-9d3f-8def54ed8352",
                                    "type": "header",
                                    "children": [],
                                    "size": "5",
                                    "text": "Date of Last Service"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "mt-3"
                        },
                        {
                            "id": "e7e17a50-ef99-11ea-9d3f-8def54ed8352",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f38277b0-ef99-11ea-9d3f-8def54ed8352",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "greaseTrapCleaning",
                                    "label": "Grease Trap Cleaning",
                                    "dataField": "greaseTrapCleaning"
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": "",
                            "name": ""
                        },
                        {
                            "id": "fc7ce620-ef99-11ea-9d3f-8def54ed8352",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fe46a9a0-ef99-11ea-9d3f-8def54ed8352",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "airconCleaning",
                                    "label": "A/C Cleaning",
                                    "dataField": "airconCleaning"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "081fa8d0-d84f-11ea-acd1-c1cd20d0424a",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "unit-detail-page",
            "route": "/units/:id",
            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
            "_sortIndex": 520,
            "access": "admin,accounting,pmg"
        },
        {
            "id": "0829e200-d84f-11ea-acd1-c1cd20d0424b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "0829e200-d84f-11ea-acd1-c1cd20d0424c",
                    "children": [
                        {
                            "id": "2524edb0-f65d-11ea-87ea-87e5ac731b3a",
                            "type": "container",
                            "children": [
                                {
                                    "id": "29d640c0-f65d-11ea-87ea-87e5ac731b3a",
                                    "type": "button",
                                    "children": [],
                                    "text": "Upload",
                                    "command": "upload_unit",
                                    "startIcon": "Publish"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,accounting,pmg"
                        },
                        {
                            "id": "08310df0-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "08341b30-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "table",
                            "children": [
                                {
                                    "id": "0838fd30-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "Number",
                                    "label": "Unit Number",
                                    "dataField": "number"
                                },
                                {
                                    "id": "c86067a0-f0a6-11ea-a584-176526b5be96",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Customer Code",
                                    "dataField": "customerCode"
                                },
                                {
                                    "id": "ca3834e0-f0a6-11ea-a584-176526b5be96",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "homeowner",
                                    "label": "Homeowner"
                                },
                                {
                                    "id": "fd718c50-f65d-11ea-87ea-87e5ac731b3a",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "greaseTrapCleaning",
                                    "text": "",
                                    "label": "Grease Trap Cleaning",
                                    "date": true
                                },
                                {
                                    "id": "08ac26c0-f65e-11ea-87ea-87e5ac731b3a",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "A/C Cleaning",
                                    "dataField": "airconCleaning",
                                    "date": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ],
                    "component": "UnitUpload"
                }
            ],
            "orientation": "vertical",
            "name": "unit-list-page",
            "route": "/units-list",
            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
            "_sortIndex": 530,
            "component": ""
        },
        {
            "id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                    "children": [
                        {
                            "id": "1d1b3ee0-167d-11eb-83c3-eb7956ee1f3f",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered",
                            "text": "Unit Request"
                        },
                        {
                            "id": "09c7e540-ea50-11ea-b967-5baa6500e444",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4648f090-ea50-11ea-b967-5baa6500e444",
                                    "type": "subView",
                                    "children": [],
                                    "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                    "name": "homeowner-unit-request-aircon-cleaning"
                                },
                                {
                                    "id": "4fa8fea0-ea50-11ea-b967-5baa6500e444",
                                    "type": "subView",
                                    "children": [],
                                    "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                    "name": "homeowner-unit-request-grease-trap-cleaning"
                                },
                                {
                                    "id": "53a42110-ea50-11ea-b967-5baa6500e444",
                                    "type": "subView",
                                    "children": [
                                        {
                                            "id": "1c2a9a91-ea0d-11ea-b95d-1388b7bed108",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "1c2a9a93-ea0d-11ea-b95d-1388b7bed108",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "status",
                                                    "label": "status",
                                                    "dataField": "status"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                                    "name": "homeowner-unit-request-others"
                                },
                                {
                                    "id": "570d15a0-ea50-11ea-b967-5baa6500e444",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                    "name": "homeowner-unit-request-plans-documents"
                                },
                                {
                                    "id": "5c271590-ea50-11ea-b967-5baa6500e444",
                                    "type": "subView",
                                    "children": [],
                                    "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                    "name": "homeowner-unit-request-refund-renovation-bond"
                                },
                                {
                                    "id": "60d49810-ea50-11ea-b967-5baa6500e444",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                    "name": "homeowner-unit-request-service-request"
                                },
                                {
                                    "id": "696aed80-ea50-11ea-b967-5baa6500e444",
                                    "type": "subView",
                                    "children": [],
                                    "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                    "name": "homeowner-unit-request-vehicle-sticker"
                                },
                                {
                                    "id": "a3401d40-ea51-11ea-b2b9-6908f6182fd8",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ac059900-ea51-11ea-b2b9-6908f6182fd8",
                                            "type": "inputSelect",
                                            "children": [],
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "dataField": "unit",
                                            "name": "unit",
                                            "label": "Unit",
                                            "required": false,
                                            "disabled": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "unit-container"
                                },
                                {
                                    "id": "ba232f20-ea51-11ea-b2b9-6908f6182fd8",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "bdbecc70-ea51-11ea-b2b9-6908f6182fd8",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "type",
                                            "label": "Type",
                                            "dataField": "type",
                                            "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Other Unit Request\", \"value\":\"others\"}]",
                                            "saveObject": true,
                                            "required": false,
                                            "disabled": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "type-container"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "UnitRequest",
                            "name": "homeowner-unit-request"
                        },
                        {
                            "id": "1c2a9a97-ea0d-11ea-b95d-1388b7bed108",
                            "type": "container",
                            "children": [
                                {
                                    "id": "83cd7850-f0d6-11ea-9422-3905f8832c96",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "status",
                                    "label": "Status",
                                    "saveObject": true,
                                    "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]",
                                    "name": "status"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "8ca0afb0-f0d6-11ea-9422-3905f8832c96",
                            "type": "container",
                            "children": [
                                {
                                    "id": "94d5d250-f0d6-11ea-9422-3905f8832c96",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "notes",
                                    "label": "Notes",
                                    "name": "notes",
                                    "multiline": true,
                                    "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": ""
                        }
                    ]
                },
                {
                    "id": "1c254360-ea0d-11ea-b95d-1388b7bed108",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "unit-request-edit-page",
            "route": "/unit-requests/:id",
            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
            "access": "admin,pmg"
        },
        {
            "id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                    "children": [
                        {
                            "id": "1c3b3c60-ea0d-11ea-b95d-1388b7bed108",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "1c4156e0-ea0d-11ea-b95d-1388b7bed108",
                            "type": "table",
                            "children": [
                                {
                                    "id": "c310a650-01ec-11eb-81b5-f15d97fd1729",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "date": true
                                },
                                {
                                    "id": "4d284ab0-f797-11ea-a0fb-935a3c1bc52c",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "unit",
                                    "label": "Unit",
                                    "name": "unit"
                                },
                                {
                                    "id": "55bbb9f0-f797-11ea-a0fb-935a3c1bc52c",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "type",
                                    "name": "type",
                                    "label": "Type"
                                },
                                {
                                    "id": "5eb516f0-f797-11ea-a0fb-935a3c1bc52c",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "status",
                                    "name": "status",
                                    "text": "",
                                    "label": "Status"
                                },
                                {
                                    "id": "68a270e0-f797-11ea-a0fb-935a3c1bc52c",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "notes",
                                    "dataField": "notes",
                                    "label": "Notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/unit-requests/detail",
                            "query": ""
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "unit-request-list-page",
            "route": "/unit-requests-list",
            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
            "params": "{\"status\": \"pending\"}"
        },
        {
            "id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                    "children": [
                        {
                            "id": "bb226d00-f0af-11ea-a61b-49eea0a1bb78",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Work Permit",
                            "_sortIndex": -1,
                            "className": "has-text-centered"
                        },
                        {
                            "id": "47bfd670-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "47bfd672-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit",
                                    "saveObject": true,
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "47bfd673-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "708a9ee0-05e3-11eb-8eea-bf73dbff533a",
                                    "type": "inputSelect",
                                    "children": [],
                                    "saveObject": true,
                                    "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                    "label": "Nature of Work",
                                    "dataField": "natureOfWork"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        },
                        {
                            "id": "47bfd676-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "47bfd678-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contractor",
                                    "label": "Contractor",
                                    "dataField": "contractor",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20
                        },
                        {
                            "id": "47bfd679-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "47bfd67b-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "personInCharge",
                                    "label": "Person In Charge",
                                    "dataField": "personInCharge",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30
                        },
                        {
                            "id": "47bfd67c-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "47bffd81-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contactPersonInCharge",
                                    "label": "Contact Number of Person-in-Charge",
                                    "dataField": "contactPersonInCharge",
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40
                        },
                        {
                            "id": "47bffd82-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e7ec4310-e01f-11ea-affb-21f86f398b19",
                                    "type": "inputList",
                                    "children": [],
                                    "dataField": "workers",
                                    "label": "Workers",
                                    "name": "workers",
                                    "placeholder": "Worker"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 50
                        },
                        {
                            "id": "47bffd85-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "47bffd87-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 60
                        },
                        {
                            "id": "47bffd88-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f46db560-e01f-11ea-affb-21f86f398b19",
                                    "type": "inputList",
                                    "children": [],
                                    "dataField": "materials",
                                    "name": "materials",
                                    "label": "Materials"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 80
                        },
                        {
                            "id": "47bffd8b-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "85e80220-ef9d-11ea-82b2-a7421559c7b2",
                                    "type": "inputList",
                                    "children": [],
                                    "name": "",
                                    "label": "Tools",
                                    "dataField": "tools",
                                    "placeholder": "Tool"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 90
                        },
                        {
                            "id": "47bffd8e-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "47bffd90-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "dataField": "startDate",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 100
                        },
                        {
                            "id": "47bffd91-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "47bffd93-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "endDate",
                                    "label": "End Date",
                                    "dataField": "endDate",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 110
                        },
                        {
                            "id": "47bffd94-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "47bffd96-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "plans",
                                    "label": "Plans",
                                    "dataField": "plans",
                                    "basePath": "work-permits"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 120
                        },
                        {
                            "id": "f039f7a0-f795-11ea-8826-7fd8e10f31a2",
                            "type": "container",
                            "children": [
                                {
                                    "id": "07cef460-f796-11ea-8826-7fd8e10f31a2",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status",
                                    "saveObject": true,
                                    "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "8bdb91a0-e828-11ea-99d9-7b0e0405473d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "8f530520-e828-11ea-99d9-7b0e0405473d",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "dataField": "notes",
                                    "label": "Notes",
                                    "multiline": true,
                                    "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 130
                        }
                    ]
                },
                {
                    "id": "47b79910-df4a-11ea-96aa-1f18fc6e6b6c",
                    "type": "subView",
                    "children": [],
                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                }
            ],
            "orientation": "vertical",
            "name": "work-permit-edit-page",
            "route": "/work-permits/:id",
            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
            "_sortIndex": 730,
            "access": "admin,pmg"
        },
        {
            "id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                    "children": [
                        {
                            "id": "47ce7c70-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "47d421c0-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "table",
                            "children": [
                                {
                                    "id": "382ac9d0-01ec-11eb-81b5-f15d97fd1729",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "date": true
                                },
                                {
                                    "id": "47d9c710-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit"
                                },
                                {
                                    "id": "47d9c712-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "natureOfWork",
                                    "label": "Nature of Work",
                                    "dataField": "natureOfWork"
                                },
                                {
                                    "id": "47d9c714-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "contractor",
                                    "label": "Contractor",
                                    "dataField": "contractor"
                                },
                                {
                                    "id": "cb309910-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "personInCharge",
                                    "label": "Person In Charge"
                                },
                                {
                                    "id": "d9bb9700-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "contactPersonInCharge",
                                    "name": "",
                                    "label": "Contact Number of Person-in-Charge"
                                },
                                {
                                    "id": "e4db2f60-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "startDate",
                                    "label": "Start Date",
                                    "date": true
                                },
                                {
                                    "id": "ef9aa660-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "text": "",
                                    "label": "End Date",
                                    "dataField": "endDate",
                                    "date": true
                                },
                                {
                                    "id": "f83ae6e0-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Status",
                                    "dataField": "status"
                                },
                                {
                                    "id": "fdb07450-f06e-11ea-9272-7b40a4812ca5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/work-permits/detail"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "work-permit-list-page",
            "route": "/work-permits-list",
            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
            "_sortIndex": 740,
            "params": "{\"or:\":[{\"status\": \"pending\"},{\"status\": \"approved\"}]}"
        },
        {
            "id": "3acc2620-e865-11ea-9def-530e14f0f646",
            "type": "view",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                    "children": [
                        {
                            "id": "3ad3ee50-e865-11ea-9def-530e14f0f646",
                            "type": "table",
                            "children": [
                                {
                                    "id": "3ad7e5f0-e865-11ea-9def-530e14f0f646",
                                    "type": "column",
                                    "children": [
                                        {
                                            "id": "501d2fe0-e871-11ea-808a-85e6d8103902",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "item",
                                            "label": "Item",
                                            "name": "item"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "item",
                                    "label": "Item",
                                    "dataField": "item"
                                },
                                {
                                    "id": "3ad7e5f2-e865-11ea-9def-530e14f0f646",
                                    "type": "column",
                                    "children": [
                                        {
                                            "id": "5279e490-e871-11ea-808a-85e6d8103902",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "description",
                                            "label": "Description",
                                            "name": "description"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                },
                                {
                                    "id": "3ad7e5f4-e865-11ea-9def-530e14f0f646",
                                    "type": "column",
                                    "children": [
                                        {
                                            "id": "56685cd0-e871-11ea-808a-85e6d8103902",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "amount",
                                            "label": "Amount"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "amount",
                                    "label": "Amount",
                                    "dataField": "amount",
                                    "currency": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "component": "",
                            "command": "",
                            "path": "/billing-items"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "billing-item-list-page",
            "route": "/billing-items-list",
            "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
        },
        {
            "type": "view",
            "name": "commandbar",
            "orientation": "horizontal",
            "children": [
                {
                    "id": "07107b70-1226-11eb-8323-55a37c955b67",
                    "type": "container",
                    "children": [
                        {
                            "name": "save",
                            "type": "button",
                            "id": "e7db5105-8c4a-11ea-9466-6f383d5eb483",
                            "command": "save",
                            "startIcon": "Save"
                        },
                        {
                            "name": "delete",
                            "type": "button",
                            "id": "e7db5106-8c4a-11ea-9466-6f383d5eb483",
                            "command": "delete",
                            "startIcon": "Delete"
                        },
                        {
                            "id": "b7ae5640-96f2-11eb-92a8-cf47e6ce2da4",
                            "type": "button",
                            "children": [],
                            "name": "print",
                            "startIcon": "Print",
                            "text": "print",
                            "command": "print"
                        }
                    ],
                    "orientation": "vertical",
                    "component": "ActionBar"
                }
            ],
            "id": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
            "active": false,
            "_sortIndex": 560
        },
        {
            "id": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
            "type": "view",
            "children": [
                {
                    "id": "7ee9d700-8ef4-11ea-9b90-bfc3ee1ce33c",
                    "type": "menu",
                    "children": [
                        {
                            "id": "3cf81350-9130-11ea-af3e-29e246413619",
                            "type": "menuItem",
                            "children": [],
                            "_sortIndex": 0,
                            "path": "/app",
                            "label": "Main Menu"
                        },
                        {
                            "id": "e86c29e0-912d-11ea-82bc-71c30f9d69df",
                            "type": "menuItem",
                            "children": [
                                {
                                    "id": "e66c2680-05e1-11eb-88d0-f3dc124fad2d",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Archives",
                                    "path": "/pass-visitors-list/archive"
                                }
                            ],
                            "path": "/pass-visitors-list",
                            "label": "Visitor / Tenant Passes",
                            "_sortIndex": 10,
                            "access": "admin,pmg,pmg-ops,pmg-security",
                            "name": "pass-visitors"
                        },
                        {
                            "id": "e6566940-912d-11ea-82bc-71c30f9d69df",
                            "type": "menuItem",
                            "children": [
                                {
                                    "id": "f46e6c70-05e1-11eb-88d0-f3dc124fad2d",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Archives",
                                    "path": "/pass-deliveries-list/archive"
                                }
                            ],
                            "path": "/pass-deliveries-list",
                            "label": "Delivery / Pullout Passes",
                            "_sortIndex": 20,
                            "access": "admin,pmg,pmg-ops,pmg-security",
                            "name": "pass-deliveries"
                        },
                        {
                            "id": "875d7be0-dd59-11ea-878d-279a484325f4",
                            "type": "menuItem",
                            "children": [
                                {
                                    "id": "f79b6970-05e1-11eb-88d0-f3dc124fad2d",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Archives",
                                    "path": "/work-permits-list/archive"
                                }
                            ],
                            "label": "Work Permits",
                            "path": "/work-permits-list",
                            "_sortIndex": 30,
                            "access": "admin,pmg,pmg-ops,pmg-security",
                            "name": "work-permits"
                        },
                        {
                            "id": "9c3e1dc0-914c-11ea-b5d1-292f71dadca1",
                            "type": "menuItem",
                            "children": [
                                {
                                    "id": "fb1044e0-05e1-11eb-88d0-f3dc124fad2d",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Archives",
                                    "path": "/unit-requests-list/archive"
                                }
                            ],
                            "path": "/unit-requests-list",
                            "label": "Unit Requests",
                            "_sortIndex": 40,
                            "access": "admin,pmg,pmg-ops,pmg-security,accounting,accounting-staff",
                            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                            "name": "unit-requests"
                        },
                        {
                            "id": "1854b570-914f-11ea-b2e4-0783314da7b1",
                            "type": "menuItem",
                            "children": [],
                            "path": "/comments-suggestions-list",
                            "label": "Comments & Suggestions",
                            "_sortIndex": 50,
                            "component": "",
                            "access": "admin,pmg,pmg-ops",
                            "name": "comments-suggestions"
                        },
                        {
                            "id": "d72baba0-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "menuItem",
                            "children": [
                                {
                                    "id": "002cdce0-05e2-11eb-88d0-f3dc124fad2d",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Archives",
                                    "path": "/message-boards-list/archive"
                                }
                            ],
                            "path": "/message-boards-list",
                            "label": "Message Board",
                            "_sortIndex": 60,
                            "access": "admin,pmg,pmg-ops",
                            "name": "message-boards"
                        },
                        {
                            "id": "e3379d10-912d-11ea-82bc-71c30f9d69df",
                            "type": "menuItem",
                            "children": [
                                {
                                    "id": "6aa988a0-2704-11eb-ac91-c74c3e25a4bd",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Archives",
                                    "path": "/notifications-list/archive"
                                }
                            ],
                            "path": "/notifications-list",
                            "label": "Notifications",
                            "_sortIndex": 70,
                            "access": "admin,pmg,pmg-ops,accounting,accounting-staff"
                        },
                        {
                            "id": "7abd8be0-9138-11ea-bd78-399a56c40918",
                            "type": "menuItem",
                            "children": [
                                {
                                    "id": "818631e0-2704-11eb-ac91-c74c3e25a4bd",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Archives",
                                    "path": "/billings-list/archive"
                                }
                            ],
                            "path": "/billings-list",
                            "label": "Billings",
                            "_sortIndex": 80,
                            "access": "admin,accounting,accounting-staff,pmg"
                        },
                        {
                            "id": "68e00f40-f65c-11ea-bcea-e53105b58e3f",
                            "type": "menuItem",
                            "children": [],
                            "path": "/payments-list",
                            "label": "Payments",
                            "access": "admin,accounting,accounting-staff,pmg"
                        },
                        {
                            "id": "e1041b40-912d-11ea-82bc-71c30f9d69df",
                            "type": "menuItem",
                            "children": [],
                            "path": "/homeowners-list",
                            "label": "Homeowners",
                            "_sortIndex": 90,
                            "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                        },
                        {
                            "id": "7bde5e60-dd59-11ea-878d-279a484325f4",
                            "type": "menuItem",
                            "children": [],
                            "label": "Units",
                            "path": "/units-list",
                            "_sortIndex": 100,
                            "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                        },
                        {
                            "id": "d8c9a170-912d-11ea-82bc-71c30f9d69df",
                            "type": "menuItem",
                            "children": [],
                            "path": "/departments-list",
                            "label": "Departments",
                            "_sortIndex": 110,
                            "access": "admin"
                        },
                        {
                            "id": "df5755a0-912d-11ea-82bc-71c30f9d69df",
                            "type": "menuItem",
                            "children": [],
                            "path": "/employees-list",
                            "label": "Employees",
                            "_sortIndex": 120,
                            "name": "",
                            "access": "admin"
                        },
                        {
                            "id": "9ebd1610-e7f4-11ea-b892-0f30d25d09fd",
                            "type": "menuItem",
                            "children": [],
                            "path": "/terms-conditions-list",
                            "label": "Terms and Conditions",
                            "_sortIndex": 130,
                            "access": "admin,pmg"
                        },
                        {
                            "id": "fbb2fb30-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "menuItem",
                            "children": [],
                            "path": "/activity-logs-list",
                            "label": "Activity Log",
                            "_sortIndex": 151,
                            "access": "admin"
                        },
                        {
                            "id": "fef31bf0-dfa3-11ea-a94d-e5d6a6ca6c3b",
                            "type": "menuItem",
                            "children": [],
                            "path": "/dashboard-login",
                            "label": "Logout",
                            "_sortIndex": 160
                        }
                    ],
                    "name": "",
                    "className": "dashboard-sidebar"
                }
            ],
            "orientation": "vertical",
            "name": "dashboard-sidebar",
            "active": false,
            "_sortIndex": 570
        },
        {
            "id": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf",
            "type": "view",
            "children": [
                {
                    "id": "8d112830-2fb5-11eb-b456-41b261316291",
                    "type": "container",
                    "children": [
                        {
                            "id": "3ddfb0d0-2fb8-11eb-a54c-f9fdab39b42b",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Welcome"
                        },
                        {
                            "id": "9640fe80-2fb5-11eb-b456-41b261316291",
                            "type": "container",
                            "children": [
                                {
                                    "id": "98eac1c0-2fb5-11eb-b456-41b261316291",
                                    "type": "textBlock",
                                    "children": [],
                                    "text": "Employee No.",
                                    "className": "mr-3 profile-label"
                                },
                                {
                                    "id": "a5131790-2fb5-11eb-b456-41b261316291",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "employeeid"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "ac0d4930-2fb5-11eb-b456-41b261316291",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7a7874c0-2fb6-11eb-b3b5-97b02e2ed07c",
                                    "type": "textBlock",
                                    "children": [],
                                    "text": "Name",
                                    "className": "mr-3 profile-label"
                                },
                                {
                                    "id": "afca7840-2fb5-11eb-b456-41b261316291",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "firstName",
                                    "className": "mr-1"
                                },
                                {
                                    "id": "b3fc8930-2fb5-11eb-b456-41b261316291",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "lastName"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "bbfba7b0-2fb5-11eb-b456-41b261316291",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bf5d2cc0-2fb6-11eb-b3b5-97b02e2ed07c",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "",
                                    "text": "Role",
                                    "className": "mr-3 profile-label"
                                },
                                {
                                    "id": "bcd0aaf0-2fb5-11eb-b456-41b261316291",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "role"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "main",
                    "component": "AccountStaff"
                }
            ],
            "orientation": "vertical",
            "name": "dashboard-welcome",
            "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
            "className": "p-2",
            "repeat": true
        },
        {
            "id": "1aad9460-8ef0-11ea-981d-018b5a72c307",
            "type": "view",
            "children": [
                {
                    "id": "24e082d0-8ef0-11ea-981d-018b5a72c307",
                    "type": "menu",
                    "children": [
                        {
                            "id": "26cac6a0-8ef0-11ea-981d-018b5a72c307",
                            "type": "menuItem",
                            "children": [],
                            "name": "home",
                            "_sortIndex": 0,
                            "label": "Main Menu",
                            "path": "/app"
                        },
                        {
                            "id": "13a38940-912a-11ea-a5e9-ff76f0b9af84",
                            "type": "menuItem",
                            "children": [],
                            "_sortIndex": 10,
                            "name": "account",
                            "label": "My Account",
                            "path": "/account"
                        },
                        {
                            "id": "8be32c20-060b-11eb-b5a2-dd96829f3308",
                            "type": "menuItem",
                            "children": [],
                            "label": "Notifications",
                            "path": "/notifications",
                            "name": "notifications"
                        },
                        {
                            "id": "152c2510-912a-11ea-a5e9-ff76f0b9af84",
                            "type": "menuItem",
                            "children": [],
                            "_sortIndex": 20,
                            "name": "service",
                            "label": "Unit Request",
                            "path": "/homeowner-unit-request/0"
                        },
                        {
                            "id": "17a5feb0-912a-11ea-a5e9-ff76f0b9af84",
                            "type": "menuItem",
                            "children": [],
                            "_sortIndex": 40,
                            "label": "Visitor / Tenant Passes",
                            "name": "visitor",
                            "path": "/create-visitor-pass/0"
                        },
                        {
                            "id": "19b110f0-912a-11ea-a5e9-ff76f0b9af84",
                            "type": "menuItem",
                            "children": [],
                            "_sortIndex": 50,
                            "name": "delivery",
                            "label": "Delivery / Pull-out Pass",
                            "path": "/create-delivery-pass/0"
                        },
                        {
                            "id": "58256b20-c92d-11ea-8660-8b92cdcd0efe",
                            "type": "menuItem",
                            "children": [],
                            "path": "/message-board",
                            "label": "Message Board",
                            "_sortIndex": 70
                        },
                        {
                            "id": "166eb730-912a-11ea-a5e9-ff76f0b9af84",
                            "type": "menuItem",
                            "children": [],
                            "_sortIndex": 30,
                            "label": "Comments and Suggestions",
                            "name": "complaint",
                            "path": "/homeowner-comments-suggestions/0"
                        },
                        {
                            "id": "5968bca0-d9ac-11ea-b236-955d418fe434",
                            "type": "menuItem",
                            "children": [],
                            "name": "work-permit",
                            "path": "/homeowner-work-permit/0",
                            "label": "Work Permit"
                        },
                        {
                            "id": "295939b0-8ef0-11ea-981d-018b5a72c307",
                            "type": "menuItem",
                            "children": [],
                            "name": "logout",
                            "_sortIndex": 71,
                            "label": "Logout",
                            "path": "/homeowners-login"
                        }
                    ],
                    "className": "homeowner-sidebar"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-sidebar",
            "active": false,
            "_sortIndex": 580
        },
        {
            "id": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
            "type": "view",
            "children": [
                {
                    "id": "d3e39f50-d9a6-11ea-891d-3722b00ad4e7",
                    "type": "container",
                    "children": [
                        {
                            "id": "d5efea10-d9a6-11ea-891d-3722b00ad4e7",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.units",
                            "label": "No. of Units",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "db6aa7a0-d9a6-11ea-891d-3722b00ad4e7",
                    "type": "container",
                    "children": [
                        {
                            "id": "e1bfdbe0-edcc-11ea-abfa-8f89576fb378",
                            "type": "inputDateTime",
                            "children": [],
                            "name": "meta.schedule",
                            "label": "Preferred Schedule",
                            "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "77226cd0-e7f5-11ea-b892-0f30d25d09fd",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "name": "unit-request-aircon-cleaning",
                    "component": "TermsAndConditions"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-request-aircon-cleaning",
            "_sortIndex": 590
        },
        {
            "id": "0c744880-d9a5-11ea-a304-319701533d32",
            "type": "view",
            "children": [
                {
                    "id": "1c9ddb40-d9a5-11ea-a304-319701533d32",
                    "type": "container",
                    "children": [
                        {
                            "id": "fbbdc250-edcc-11ea-abfa-8f89576fb378",
                            "type": "inputDateTime",
                            "children": [],
                            "name": "meta.schedule",
                            "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                            "label": "Preferred Schedule",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "622a6210-e7f5-11ea-b892-0f30d25d09fd",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "name": "unit-request-grease-trap-cleaning",
                    "component": "TermsAndConditions"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-request-grease-trap-cleaning",
            "_sortIndex": 600
        },
        {
            "id": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
            "type": "view",
            "children": [
                {
                    "id": "fe4688f0-d9a8-11ea-9331-090047662dd2",
                    "type": "container",
                    "children": [
                        {
                            "id": "ff497a00-d9a8-11ea-9331-090047662dd2",
                            "type": "inputText",
                            "children": [],
                            "multiline": true,
                            "name": "meta.details",
                            "label": "Please Specify",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-request-others",
            "_sortIndex": 610
        },
        {
            "id": "e60fdb40-c910-11ea-9dd0-6723feee229e",
            "type": "view",
            "children": [
                {
                    "id": "e85ead40-c910-11ea-9dd0-6723feee229e",
                    "type": "container",
                    "children": [
                        {
                            "id": "076a4bb0-c91e-11ea-831c-99fd7b91f646",
                            "type": "inputSelect",
                            "children": [],
                            "name": "meta.plans",
                            "label": "Plans Required",
                            "options": "[{ \"value\": \"standard\", \"label\": \"Standard Plan\"},{ \"value\": \"as-built\", \"label\": \"As-built Plan\"}]",
                            "className": "bite-select",
                            "saveObject": true,
                            "required": true
                        }
                    ],
                    "orientation": "horizontal",
                    "name": "homeowner-service-request-repairs",
                    "className": "bite-select"
                },
                {
                    "id": "112746d0-c91e-11ea-831c-99fd7b91f646",
                    "type": "container",
                    "children": [
                        {
                            "id": "44b19ca0-d916-11ea-9ea3-7184c3941d0d",
                            "type": "inputSelect",
                            "children": [],
                            "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                            "saveObject": true,
                            "name": "meta.paper-size",
                            "label": "Paper Size",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal",
                    "component": "UnitRequestPlansDocuments"
                },
                {
                    "id": "97e9eee0-d916-11ea-9ea3-7184c3941d0d",
                    "type": "container",
                    "children": [
                        {
                            "id": "9a6e28c0-d916-11ea-9ea3-7184c3941d0d",
                            "type": "inputSelect",
                            "children": [],
                            "name": "meta.type",
                            "label": "Type of Plan",
                            "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing\", \"label\": \"Plumbing\"},{ \"value\": \"electrical-plan\", \"label\": \"Electrical Plan\"}]",
                            "saveObject": true,
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "e91d7600-e7f4-11ea-b892-0f30d25d09fd",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "name": "unit-request-plans-documents",
                    "component": "TermsAndConditions"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-request-plans-documents",
            "_sortIndex": 620
        },
        {
            "id": "063f7330-c911-11ea-9dd0-6723feee229e",
            "type": "view",
            "children": [
                {
                    "id": "1cea7cc0-c91f-11ea-b910-c30a77ee18ba",
                    "type": "container",
                    "children": [
                        {
                            "id": "b0a9a300-ef9e-11ea-82b2-a7421559c7b2",
                            "type": "inputDate",
                            "children": [],
                            "name": "meta.inspection-date",
                            "label": "Preferred Inspection Date",
                            "description": "Monday to Friday, except Holidays",
                            "required": false,
                            "disabled": false
                        }
                    ],
                    "orientation": "horizontal",
                    "className": ""
                },
                {
                    "id": "b9eb6b30-d917-11ea-9ea3-7184c3941d0d",
                    "type": "container",
                    "children": [
                        {
                            "id": "d8830d80-ef9e-11ea-82b2-a7421559c7b2",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.additional-requests",
                            "multiline": true,
                            "label": "Additional Requests",
                            "disabled": false
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "21c2a6b0-e7f5-11ea-b892-0f30d25d09fd",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "name": "unit-request-refund-renovation-fund",
                    "component": "TermsAndConditions"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-request-refund-renovation-bond",
            "_sortIndex": 630
        },
        {
            "id": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
            "type": "view",
            "children": [
                {
                    "id": "743faf70-c90d-11ea-8c59-196c3ec4f839",
                    "type": "container",
                    "children": [
                        {
                            "id": "a9319f50-c911-11ea-98ab-e51558e3434e",
                            "type": "inputSelect",
                            "children": [],
                            "label": "Type of Work",
                            "name": "meta.type",
                            "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                            "className": "bite-select",
                            "saveObject": true,
                            "required": true
                        }
                    ],
                    "orientation": "horizontal",
                    "className": "bite-select"
                },
                {
                    "id": "c6681b80-c911-11ea-98ab-e51558e3434e",
                    "type": "container",
                    "children": [
                        {
                            "id": "c84dcb70-c911-11ea-98ab-e51558e3434e",
                            "type": "inputText",
                            "children": [],
                            "label": "Work Details",
                            "name": "meta.details",
                            "multiline": true,
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "cf0bde70-c911-11ea-98ab-e51558e3434e",
                    "type": "container",
                    "children": [
                        {
                            "id": "ccce1d40-edcd-11ea-993f-a977bcf36a2d",
                            "type": "inputDateTime",
                            "children": [],
                            "name": "meta.schedule",
                            "label": "Preferred Inspection/Work Schedule",
                            "description": " Monday to Friday only. Except Holidays",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal",
                    "className": "bite-select"
                },
                {
                    "id": "996b7430-e65b-11ea-a30a-a9b10ee3b8be",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "component": "TermsAndConditions",
                    "name": "unit-request-service-request"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-request-service-request",
            "_sortIndex": 640
        },
        {
            "id": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
            "type": "view",
            "children": [
                {
                    "id": "b33b28f0-d919-11ea-b3eb-d9cf4159b042",
                    "type": "container",
                    "children": [
                        {
                            "id": "b80cd540-d919-11ea-b3eb-d9cf4159b042",
                            "type": "inputSelect",
                            "children": [],
                            "name": "meta.make",
                            "label": "Make",
                            "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                            "saveObject": true,
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "c394f370-d919-11ea-b3eb-d9cf4159b042",
                    "type": "container",
                    "children": [
                        {
                            "id": "c6827670-d919-11ea-b3eb-d9cf4159b042",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.model",
                            "label": "Model",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "ad71c600-d9a4-11ea-a304-319701533d32",
                    "type": "container",
                    "children": [
                        {
                            "id": "b1b90ca0-d9a4-11ea-a304-319701533d32",
                            "type": "inputSelect",
                            "children": [],
                            "name": "meta.color",
                            "label": "Color",
                            "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                            "saveObject": true,
                            "required": false,
                            "disabled": false
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "c744cbe0-d9a4-11ea-a304-319701533d32",
                    "type": "container",
                    "children": [
                        {
                            "id": "cfb34e00-d9a4-11ea-a304-319701533d32",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.plate-number",
                            "label": "Plate Number",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "ddd18060-d9a4-11ea-a304-319701533d32",
                    "type": "container",
                    "children": [
                        {
                            "id": "deacec40-d9a4-11ea-a304-319701533d32",
                            "type": "inputMedia",
                            "children": [],
                            "name": "meta.OR",
                            "description": "",
                            "label": "Upload O.R. / C.R."
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "3fd52b00-e7f5-11ea-b892-0f30d25d09fd",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "name": "unit-request-vehicle-sticker",
                    "component": "TermsAndConditions"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-request-vehicle-sticker",
            "_sortIndex": 650
        },
        {
            "id": "b8fe9280-c6f5-11ea-b415-c195f50f114e",
            "type": "view",
            "children": [
                {
                    "id": "aca19b50-c6f9-11ea-a851-fde2cfff9ce9",
                    "type": "container",
                    "children": [
                        {
                            "id": "ae765b50-c6f9-11ea-a851-fde2cfff9ce9",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Select Unit"
                        }
                    ],
                    "orientation": "horizontal",
                    "className": "columns is-centered pb-1"
                },
                {
                    "id": "d8bbe3d0-c6f9-11ea-a851-fde2cfff9ce9",
                    "type": "container",
                    "children": [
                        {
                            "id": "dde9e0f0-c6f9-11ea-a851-fde2cfff9ce9",
                            "type": "container",
                            "children": [
                                {
                                    "id": "029256d0-c6fa-11ea-a851-fde2cfff9ce9",
                                    "type": "button",
                                    "children": [],
                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 01</div>",
                                    "className": "app-menu-item",
                                    "path": "/welcome/01"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "app-menu-item-container"
                        },
                        {
                            "id": "472397a0-c6fa-11ea-a851-fde2cfff9ce9",
                            "type": "container",
                            "children": [
                                {
                                    "id": "53985fc0-c6fa-11ea-a851-fde2cfff9ce9",
                                    "type": "button",
                                    "children": [],
                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 02</div>",
                                    "className": "app-menu-item",
                                    "path": "/welcome/02"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "app-menu-item-container"
                        }
                    ],
                    "orientation": "horizontal",
                    "className": "columns is-centered pt-2"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-selection",
            "className": "",
            "_sortIndex": 660
        },
        {
            "id": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
            "type": "view",
            "children": [
                {
                    "id": "38c26740-da1e-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "3d14aa60-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "inputList",
                            "children": [],
                            "name": "visitor",
                            "label": "Name/s",
                            "placeholder": "Tenant",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "49eb4190-da1e-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "4b75d930-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "inputDate",
                            "children": [],
                            "name": "startDate",
                            "label": "Start Date",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "5279ced0-da1e-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "698d6700-ef9d-11ea-82b2-a7421559c7b2",
                            "type": "inputDate",
                            "children": [],
                            "name": "endDate",
                            "label": "End Date",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "5ed590b0-da1e-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "603f80f0-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "inputMedia",
                            "children": [],
                            "name": "visitorsId",
                            "label": "Upload valid ID/ Passport (required)",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "6f845900-da1e-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "710098c0-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "inputMedia",
                            "children": [],
                            "name": "meta.contract",
                            "label": "Upload Contract of Lease (required)",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "3373c0d0-e816-11ea-b937-9954e083a5ee",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "name": "visitor-pass-long-term",
                    "component": "TermsAndConditions"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-visitor-pass-long-term",
            "_sortIndex": 670
        },
        {
            "id": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
            "type": "view",
            "children": [
                {
                    "id": "a0ff15c0-da1d-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "a2036660-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "inputList",
                            "children": [],
                            "name": "visitor",
                            "label": "Name/s",
                            "placeholder": "Tenant",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "bb50c180-da1d-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "c70a7700-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "inputDate",
                            "children": [],
                            "name": "startDate",
                            "label": "Start Date",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "cdb2b810-da1d-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "d233d360-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "inputDate",
                            "children": [],
                            "name": "endDate",
                            "label": "End Date",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "e0d391d0-da1d-11ea-abba-db7aa6b9e7d7",
                    "type": "container",
                    "children": [
                        {
                            "id": "e4f28ff0-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "inputMedia",
                            "children": [],
                            "name": "visitorsId",
                            "label": "Upload Valid ID/ Passport",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "214ab490-e816-11ea-b937-9954e083a5ee",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "name": "visitor-pass-short-term",
                    "component": "TermsAndConditions"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-visitor-pass-short-term",
            "_sortIndex": 680
        },
        {
            "id": "824f51f0-da1b-11ea-87e9-9de57275fc27",
            "type": "view",
            "children": [
                {
                    "id": "94ab5510-da1b-11ea-87e9-9de57275fc27",
                    "type": "container",
                    "children": [
                        {
                            "id": "9c1bc820-da1b-11ea-87e9-9de57275fc27",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.relationship",
                            "label": "Relationship to Owner"
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "b7ed0f00-da1b-11ea-87e9-9de57275fc27",
                    "type": "container",
                    "children": [
                        {
                            "id": "b9749960-da1b-11ea-87e9-9de57275fc27",
                            "type": "inputList",
                            "children": [],
                            "name": "visitor",
                            "description": "",
                            "label": "Name/s",
                            "placeholder": "Visitor",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "cb6d1fc0-da1b-11ea-87e9-9de57275fc27",
                    "type": "container",
                    "children": [
                        {
                            "id": "cebbae80-da1b-11ea-87e9-9de57275fc27",
                            "type": "inputDate",
                            "children": [],
                            "name": "startDate",
                            "label": "Start Date",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "d92934f0-da1b-11ea-87e9-9de57275fc27",
                    "type": "container",
                    "children": [
                        {
                            "id": "db4adc70-da1b-11ea-87e9-9de57275fc27",
                            "type": "inputDate",
                            "children": [],
                            "name": "endDate",
                            "label": "End Date",
                            "required": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "e6edfcb0-da1b-11ea-87e9-9de57275fc27",
                    "type": "container",
                    "children": [
                        {
                            "id": "ed1bdda0-da1b-11ea-87e9-9de57275fc27",
                            "type": "inputMedia",
                            "children": [],
                            "name": "visitorsId",
                            "label": "Upload Valid ID (optional)"
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "fe8ad4d0-e815-11ea-b937-9954e083a5ee",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "name": "visitor-pass-visitor-pass",
                    "component": "TermsAndConditions"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-visitor-pass-visitor-pass",
            "_sortIndex": 690
        },
        {
            "id": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
            "type": "view",
            "children": [
                {
                    "id": "e7c4b510-e7fa-11ea-ba5e-d94241a9751c",
                    "type": "container",
                    "children": [
                        {
                            "id": "465ece40-e7fa-11ea-ba5e-d94241a9751c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "25f3f810-97d7-11ea-a9fa-07f95ad6f0c6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d37f2010-97da-11ea-a9fa-07f95ad6f0c6",
                                            "type": "button",
                                            "children": [],
                                            "path": "/account",
                                            "name": "My Account",
                                            "className": "app-menu-item",
                                            "icon": "",
                                            "text": "",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_account.svg\"><img src=\"./assets/images/icon_account.svg\" /></object>My Account</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "2ef5e090-97d7-11ea-a9fa-07f95ad6f0c6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "856e1b80-9148-11ea-a6b6-e7e287632ad5",
                                            "type": "button",
                                            "children": [],
                                            "text": "",
                                            "path": "/homeowner-unit-request/0",
                                            "flex": "[object Object]",
                                            "className": "app-menu-item",
                                            "_sortIndex": 10,
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_unit_request.svg\"><img src=\"./assets/images/icon_unit_request.svg\" /></object>Unit Request</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "38053690-97d7-11ea-a9fa-07f95ad6f0c6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                            "type": "button",
                                            "children": [
                                                {
                                                    "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "",
                                                            "path": "/create-visitor-pass/0",
                                                            "_sortIndex": 30,
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                }
                                            ],
                                            "text": "",
                                            "path": "/create-visitor-pass/0",
                                            "_sortIndex": 30,
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "8ac2b370-9148-11ea-a6b6-e7e287632ad5",
                                            "type": "button",
                                            "children": [],
                                            "text": "",
                                            "path": "/create-delivery-pass/0",
                                            "_sortIndex": 40,
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_delivery.svg\"><img src=\"./assets/images/icon_delivery.svg\" /></object>Delivery Pass</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "2290a530-c921-11ea-9848-69dad3ad605b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "2b49ebf0-c921-11ea-9848-69dad3ad605b",
                                            "type": "button",
                                            "children": [],
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_board.svg\"><img src=\"./assets/images/icon_board.svg\" /></object>Message Board</div>",
                                            "className": "app-menu-item",
                                            "path": "/message-board"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "351a2490-97d7-11ea-a9fa-07f95ad6f0c6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "874e7440-9148-11ea-a6b6-e7e287632ad5",
                                            "type": "button",
                                            "children": [],
                                            "text": "",
                                            "path": "/homeowner-comments-suggestions/0",
                                            "_sortIndex": 20,
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_complaint.svg\"><img src=\"./assets/images/icon_complaint.svg\" /></object>Comments and Suggestions</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "d7653ab0-e7f9-11ea-ba5e-d94241a9751c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e1ab6300-e7f9-11ea-ba5e-d94241a9751c",
                                            "type": "button",
                                            "children": [],
                                            "path": "/homeowner-work-permit/0",
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_work_permit.svg\"><img src=\"./assets/images/icon_work_permit.svg\" /></object>Work Permit</div>"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "5e4c0520-ea13-11ea-a21e-f569cc759e68",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "68b4a990-ea13-11ea-a21e-f569cc759e68",
                                            "type": "button",
                                            "children": [],
                                            "className": "app-menu-item",
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_notifications.svg\"><img src=\"./assets/images/icon_notifications.svg\" /></object>Notifications</div>",
                                            "path": "/notifications",
                                            "disabled": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "horizontal",
                    "className": "columns is-centered app-menus-container"
                },
                {
                    "id": "d4795ff0-effd-11ea-963d-316e0da90aca",
                    "type": "container",
                    "children": [],
                    "orientation": "horizontal",
                    "component": "LoginNotice"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-welcome",
            "active": false,
            "className": "column is-centered",
            "component": "",
            "_sortIndex": 700
        },
        {
            "id": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b",
            "type": "view",
            "children": [
                {
                    "id": "bc93f270-102c-11eb-a185-f1e09385ea9b",
                    "type": "container",
                    "children": [
                        {
                            "id": "2b02be70-f0ad-11ea-a61b-49eea0a1bb78",
                            "type": "container",
                            "children": [
                                {
                                    "id": "38d84f10-f0ad-11ea-a61b-49eea0a1bb78",
                                    "type": "image",
                                    "children": [],
                                    "url": "./assets/images/myplv_icon.png",
                                    "className": "login-comp-logo"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": -1
                        },
                        {
                            "id": "28528d60-dfa8-11ea-9800-138672de1aa2",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6fbd65f0-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "712f9390-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "77274950-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": "1",
                                                    "className": "h1 logn-header",
                                                    "text": "Log In"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "login-header"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "row"
                                },
                                {
                                    "id": "7d5f3c60-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "8a375a30-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "9e527270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "email",
                                                    "label": "Email",
                                                    "className": "form-control login-form-input",
                                                    "variant": "standard"
                                                },
                                                {
                                                    "id": "b3ea9850-dfa7-11ea-9800-138672de1aa2",
                                                    "type": "image",
                                                    "children": [],
                                                    "url": "./assets/images/icon_user.svg",
                                                    "className": "login-icons"
                                                }
                                            ],
                                            "orientation": "vertical",
                                            "className": "col"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "row mt-4"
                                },
                                {
                                    "id": "c896c270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "cbd9df80-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ce124350-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "password": true,
                                                    "name": "password",
                                                    "label": "Password",
                                                    "className": "form-control login-form-input",
                                                    "variant": "standard"
                                                },
                                                {
                                                    "id": "fcc3d880-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0bdff2e0-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "text": "[Forgot your password?](/#/forgot-password)"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "mt-3 forgot-password-container"
                                                },
                                                {
                                                    "id": "c9874780-dfa7-11ea-9800-138672de1aa2",
                                                    "type": "image",
                                                    "children": [],
                                                    "url": "./assets/images/icon_login_password.svg",
                                                    "className": "login-icons"
                                                }
                                            ],
                                            "orientation": "vertical",
                                            "className": "col"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "row mt-4"
                                },
                                {
                                    "id": "10d10e10-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "15b3aa50-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "17e09c70-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "button",
                                                    "children": [],
                                                    "command": "",
                                                    "text": "Admin Login",
                                                    "fullWidth": true,
                                                    "isSubmit": true,
                                                    "path": ""
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "col"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "row mt-4"
                                }
                            ],
                            "orientation": "vertical",
                            "component": "",
                            "_sortIndex": 0
                        }
                    ],
                    "orientation": "vertical",
                    "name": "main",
                    "component": "Login"
                }
            ],
            "orientation": "vertical",
            "name": "login-dashboard",
            "component": ""
        },
        {
            "id": "e1156440-93e9-11ea-99e4-a504a663bd48",
            "type": "view",
            "children": [
                {
                    "id": "eb7f4134-93e9-11ea-99e4-a504a663bd48",
                    "type": "container",
                    "children": [
                        {
                            "id": "0e6c07e0-f0b1-11ea-9980-9d3390e2d189",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1a36ad50-f0b1-11ea-9980-9d3390e2d189",
                                    "type": "image",
                                    "children": [],
                                    "url": "./assets/images/myplv_icon.png",
                                    "className": "login-comp-logo"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": -1
                        },
                        {
                            "nodeType": "DIV",
                            "type": "container",
                            "className": "row",
                            "children": [
                                {
                                    "nodeType": "DIV",
                                    "type": "container",
                                    "className": "col",
                                    "children": [
                                        {
                                            "nodeType": "H1",
                                            "type": "header",
                                            "className": "h1",
                                            "children": [],
                                            "text": "Log In",
                                            "id": "eb7f4137-93e9-11ea-99e4-a504a663bd48"
                                        }
                                    ],
                                    "id": "eb7f4136-93e9-11ea-99e4-a504a663bd48"
                                }
                            ],
                            "id": "eb7f4135-93e9-11ea-99e4-a504a663bd48",
                            "_sortIndex": 0
                        },
                        {
                            "nodeType": "DIV",
                            "type": "container",
                            "className": "row mt-4",
                            "children": [
                                {
                                    "nodeType": "DIV",
                                    "type": "container",
                                    "className": "col",
                                    "children": [
                                        {
                                            "nodeType": "INPUT",
                                            "type": "inputText",
                                            "className": "form-control login-form-input txt-homeowner-login",
                                            "children": [],
                                            "placeholder": "Email",
                                            "id": "eb7f413a-93e9-11ea-99e4-a504a663bd48",
                                            "name": "email",
                                            "variant": "standard"
                                        },
                                        {
                                            "id": "f6061850-ad8d-11ea-a81e-a3c623c48bc9",
                                            "type": "image",
                                            "children": [],
                                            "url": "./assets/images/icon_user.svg",
                                            "className": "login-icons"
                                        }
                                    ],
                                    "id": "eb7f4139-93e9-11ea-99e4-a504a663bd48"
                                }
                            ],
                            "id": "eb7f4138-93e9-11ea-99e4-a504a663bd48",
                            "_sortIndex": 10
                        },
                        {
                            "nodeType": "DIV",
                            "type": "container",
                            "className": "row mt-3",
                            "children": [
                                {
                                    "nodeType": "DIV",
                                    "type": "container",
                                    "className": "col",
                                    "children": [
                                        {
                                            "nodeType": "INPUT",
                                            "type": "inputText",
                                            "className": "form-control login-form-input txt-homeowner-login",
                                            "children": [],
                                            "placeholder": "Password",
                                            "id": "eb7f413d-93e9-11ea-99e4-a504a663bd48",
                                            "name": "password",
                                            "password": true,
                                            "variant": "standard"
                                        },
                                        {
                                            "id": "3a4be650-ad90-11ea-a81e-a3c623c48bc9",
                                            "type": "image",
                                            "children": [],
                                            "url": "./assets/images/icon_login_password.svg",
                                            "className": "login-icons"
                                        },
                                        {
                                            "id": "5c31f5f0-ad92-11ea-a2a7-ad35397c4d32",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "5f285290-ad92-11ea-a2a7-ad35397c4d32",
                                                    "type": "paragraph",
                                                    "children": [],
                                                    "text": "[Forgot your password?](/#/forgot-password)",
                                                    "_sortIndex": -1
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "mt-3 forgot-password-container",
                                            "flex": "[object Object]"
                                        }
                                    ],
                                    "id": "eb7f413c-93e9-11ea-99e4-a504a663bd48"
                                }
                            ],
                            "id": "eb7f413b-93e9-11ea-99e4-a504a663bd48",
                            "_sortIndex": 20
                        },
                        {
                            "nodeType": "DIV",
                            "type": "container",
                            "className": "row mt-4",
                            "children": [
                                {
                                    "nodeType": "DIV",
                                    "type": "container",
                                    "className": "col",
                                    "children": [
                                        {
                                            "id": "9ceca3a0-db42-11ea-942a-259edbe0bbf2",
                                            "type": "button",
                                            "children": [],
                                            "command": "",
                                            "text": "Login",
                                            "className": "btn-homeowner-login",
                                            "isSubmit": true
                                        }
                                    ],
                                    "id": "eb7f4140-93e9-11ea-99e4-a504a663bd48"
                                }
                            ],
                            "id": "eb7f413f-93e9-11ea-99e4-a504a663bd48",
                            "_sortIndex": 30
                        }
                    ],
                    "orientation": "vertical",
                    "nodeType": "DIV",
                    "className": "",
                    "component": "Login"
                }
            ],
            "orientation": "vertical",
            "name": "login-homeowner",
            "component": "",
            "_sortIndex": 710
        },
        {
            "id": "927fbf90-ef71-11ea-ae43-d3d081a35642",
            "type": "view",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "9273b1a1-ef71-11ea-ae43-d3d081a35642",
                    "children": [
                        {
                            "id": "92867650-ef71-11ea-ae43-d3d081a35642",
                            "type": "table",
                            "children": [
                                {
                                    "id": "92895c80-ef71-11ea-ae43-d3d081a35642",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "payment",
                                    "label": "Payment",
                                    "dataField": "payment"
                                },
                                {
                                    "id": "92895c82-ef71-11ea-ae43-d3d081a35642",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "item",
                                    "label": "Item",
                                    "dataField": "item"
                                },
                                {
                                    "id": "92895c86-ef71-11ea-ae43-d3d081a35642",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "amount",
                                    "label": "Amount",
                                    "dataField": "amount",
                                    "currency": true
                                },
                                {
                                    "id": "92895c88-ef71-11ea-ae43-d3d081a35642",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "state",
                                    "label": "State",
                                    "dataField": "state"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "#"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "payment-item-list-page",
            "route": "/payment-items-list",
            "dataModel": "24ee6e90-ef71-11ea-ae43-d3d081a35642"
        },
        {
            "id": "debbf900-f0b1-11ea-99d0-d9f36ea6d179",
            "type": "model",
            "children": [
                {
                    "id": "efece9a0-f0b1-11ea-99d0-d9f36ea6d179",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "user"
                },
                {
                    "id": "0ebdbfe0-19ba-11eb-b611-03768364999e",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "model"
                },
                {
                    "id": "11b88950-19ba-11eb-b611-03768364999e",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "method"
                },
                {
                    "id": "151dad50-19ba-11eb-b611-03768364999e",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "data"
                }
            ],
            "name": "activity-log"
        },
        {
            "id": "fb9a9130-f0b1-11ea-99d0-d9f36ea6d179",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "fb9a9131-f0b1-11ea-99d0-d9f36ea6d179",
                    "children": [
                        {
                            "id": "29333c50-f0b2-11ea-99d0-d9f36ea6d179",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Activity Log",
                            "_sortIndex": -1,
                            "className": "has-text-centered"
                        },
                        {
                            "id": "fba65100-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d78bffe0-19ba-11eb-9242-33b9df06d279",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "createdAt",
                                    "label": "Date and Time"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "fba65103-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fba65105-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "user",
                                    "label": "User",
                                    "dataField": "user"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        },
                        {
                            "id": "fba65106-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fba65108-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "model",
                                    "label": "Model",
                                    "dataField": "model"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 20
                        },
                        {
                            "id": "fba65109-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fba6510b-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "method",
                                    "label": "Method",
                                    "dataField": "method",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 30
                        },
                        {
                            "id": "fba6510c-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fba6510e-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "detail",
                                    "label": "Data",
                                    "dataField": "details",
                                    "multiline": true,
                                    "line": "7"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 40
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "activityLog-detail-page",
            "route": "/activity-logs/:id",
            "dataModel": "debbf900-f0b1-11ea-99d0-d9f36ea6d179",
            "access": "noone"
        },
        {
            "id": "fba93730-f0b1-11ea-99d0-d9f36ea6d179",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "fb9a9131-f0b1-11ea-99d0-d9f36ea6d179",
                    "children": [
                        {
                            "id": "fbabcf40-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "component": "Searchbar"
                        },
                        {
                            "id": "fbadcb10-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "table",
                            "children": [
                                {
                                    "id": "97559b70-19bf-11eb-9242-33b9df06d279",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date and Time",
                                    "date": true,
                                    "format": "MM/dd/yyyy hh:mm a"
                                },
                                {
                                    "id": "fbb06322-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "user",
                                    "label": "User",
                                    "dataField": "user"
                                },
                                {
                                    "id": "fbb06324-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "model",
                                    "label": "Model",
                                    "dataField": "model"
                                },
                                {
                                    "id": "fbb06326-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "method",
                                    "label": "Method",
                                    "dataField": "method"
                                },
                                {
                                    "id": "fbb06328-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "data",
                                    "label": "Data",
                                    "dataField": "data"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "activityLog-list-page",
            "route": "/activity-logs-list",
            "dataModel": "debbf900-f0b1-11ea-99d0-d9f36ea6d179"
        },
        {
            "id": "ffb5d7c0-f6f1-11ea-b61a-f5b3d409e561",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "ffb5d7c1-f6f1-11ea-b61a-f5b3d409e561",
                    "children": [
                        {
                            "id": "1e6d3bd0-08ff-11eb-800a-9340eaf97180",
                            "type": "container",
                            "children": [
                                {
                                    "id": "200ce210-08ff-11eb-800a-9340eaf97180",
                                    "type": "button",
                                    "children": [],
                                    "text": "Back",
                                    "path": "",
                                    "variant": "text",
                                    "startIcon": "ArrowBack",
                                    "endIcon": "",
                                    "command": "back"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "575cf5d0-f6f2-11ea-b61a-f5b3d409e561",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "dataField": "name",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "65d9ea00-f6f2-11ea-b61a-f5b3d409e561",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9bb06a50-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "textBlock",
                                    "children": [],
                                    "text": "Posting Date:",
                                    "className": "mr-1"
                                },
                                {
                                    "id": "a7492a50-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "postingDate",
                                    "className": "has-text-weight-semibold"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "mx-1"
                        },
                        {
                            "id": "abc0cfc0-f6f2-11ea-b61a-f5b3d409e561",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ace838c0-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "textBlock",
                                    "children": [],
                                    "text": "Due Date:",
                                    "className": "mr-1"
                                },
                                {
                                    "id": "bc4b4730-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "textBlock",
                                    "children": [],
                                    "dataField": "dueDate",
                                    "className": "has-text-weight-semibold"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "mx-1"
                        },
                        {
                            "id": "de76d050-8828-11eb-9940-a35fc33d21dc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "eaf890c0-8828-11eb-9940-a35fc33d21dc",
                                    "type": "textBlock",
                                    "children": [],
                                    "name": "",
                                    "className": "mr-1",
                                    "text": "Total Amount:"
                                },
                                {
                                    "id": "0ab76580-8829-11eb-9940-a35fc33d21dc",
                                    "type": "textBlock",
                                    "children": [],
                                    "name": "total",
                                    "currency": true,
                                    "className": "has-text-weight-semibold"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "mx-1"
                        },
                        {
                            "id": "e1b07780-8828-11eb-9940-a35fc33d21dc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fb0c2a80-8828-11eb-9940-a35fc33d21dc",
                                    "type": "textBlock",
                                    "children": [],
                                    "className": "mr-1",
                                    "text": "Total Amount Paid:"
                                },
                                {
                                    "id": "19ef1e30-8829-11eb-9940-a35fc33d21dc",
                                    "type": "textBlock",
                                    "children": [],
                                    "name": "amountPaid",
                                    "currency": true,
                                    "className": "has-text-weight-semibold"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "mx-1"
                        },
                        {
                            "id": "1acdb3d0-8828-11eb-9940-a35fc33d21dc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "2460b8c0-8828-11eb-9940-a35fc33d21dc",
                                    "type": "textBlock",
                                    "children": [],
                                    "text": "Balance:",
                                    "className": "mr-1"
                                },
                                {
                                    "id": "3898a7d0-8828-11eb-9940-a35fc33d21dc",
                                    "type": "textBlock",
                                    "children": [],
                                    "name": "amount",
                                    "currency": true,
                                    "className": "has-text-weight-semibold"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "mx-1"
                        },
                        {
                            "id": "c25e4d20-f6f2-11ea-b61a-f5b3d409e561",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c8de1ea0-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "subView",
                                    "children": [],
                                    "view": "7c2f4560-f6f3-11ea-b61a-f5b3d409e561",
                                    "relation": "billing"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "my-2"
                        },
                        {
                            "id": "5a87c890-f6f5-11ea-8ecc-5b6063b0b693",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5b77a6d0-f6f5-11ea-8ecc-5b6063b0b693",
                                    "type": "subView",
                                    "children": [],
                                    "view": "c5bcf690-f6f4-11ea-8ecc-5b6063b0b693",
                                    "relation": "",
                                    "dataField": "name"
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": "",
                            "className": "my-2"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-billing-detail-page",
            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
            "route": "/account/homeowner-billings/:id"
        },
        {
            "id": "7c2f4560-f6f3-11ea-b61a-f5b3d409e561",
            "type": "view",
            "children": [
                {
                    "id": "9167db90-f6f3-11ea-b61a-f5b3d409e561",
                    "type": "table",
                    "children": [
                        {
                            "id": "92b47fd0-f6f3-11ea-b61a-f5b3d409e561",
                            "type": "column",
                            "children": [],
                            "orientation": "horizontal",
                            "label": "Item",
                            "name": "item"
                        },
                        {
                            "id": "83fbf7e0-060a-11eb-b075-710549412795",
                            "type": "column",
                            "children": [],
                            "orientation": "horizontal",
                            "dataField": "description",
                            "label": "Description"
                        },
                        {
                            "id": "cbabc820-f6f3-11ea-b61a-f5b3d409e561",
                            "type": "column",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "amount",
                            "label": "Amount",
                            "currency": true
                        }
                    ],
                    "orientation": "horizontal",
                    "path": "#"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-billing-item-list-page",
            "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
        },
        {
            "id": "c5bcf690-f6f4-11ea-8ecc-5b6063b0b693",
            "type": "view",
            "children": [
                {
                    "id": "d2ba76b0-f6f4-11ea-8ecc-5b6063b0b693",
                    "type": "container",
                    "children": [
                        {
                            "id": "d4cab910-f6f4-11ea-8ecc-5b6063b0b693",
                            "type": "header",
                            "children": [],
                            "size": "4",
                            "text": "Payments",
                            "className": "mt-2 mb-1"
                        }
                    ],
                    "orientation": "vertical"
                },
                {
                    "id": "780eaa80-f6fc-11ea-805b-1f4689c2a5d9",
                    "type": "container",
                    "children": [
                        {
                            "id": "94aa6d50-f6fc-11ea-805b-1f4689c2a5d9",
                            "type": "textBlock",
                            "children": [],
                            "name": "postingDate"
                        },
                        {
                            "id": "9a0cc0e0-f6fc-11ea-805b-1f4689c2a5d9",
                            "type": "textBlock",
                            "children": [],
                            "name": "receipt"
                        }
                    ],
                    "orientation": "horizontal",
                    "component": "PaymentHomeownerView"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-payment-detail-page",
            "dataModel": ""
        },
        {
            "type": "view",
            "name": "searchbar",
            "orientation": "horizontal",
            "component": "Searchbar",
            "children": [
                {
                    "id": "10c44690-96f3-11eb-a870-6d5a8f93843c",
                    "type": "container",
                    "children": [
                        {
                            "id": "e0073b50-8c64-11ea-b629-0161308cc31c",
                            "type": "button",
                            "children": [],
                            "command": "new",
                            "name": "",
                            "text": "New",
                            "className": "form-label",
                            "startIcon": "AddCircle"
                        },
                        {
                            "name": "search",
                            "type": "inputText",
                            "id": "e7db5108-8c4a-11ea-9466-6f383d5eb483",
                            "placeholder": "Search"
                        }
                    ],
                    "orientation": "horizontal",
                    "className": "searchBar"
                }
            ],
            "id": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
            "active": false,
            "_sortIndex": 720
        },
        {
            "id": "1237a590-f7a6-11ea-8efb-e99dbc19ac92",
            "type": "page",
            "children": [
                {
                    "id": "38fa2fc0-08e7-11eb-a5df-110eadfed9ed",
                    "type": "container",
                    "children": [
                        {
                            "id": "3b5a8df0-08e7-11eb-a5df-110eadfed9ed",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered",
                            "text": "Pay Dues"
                        },
                        {
                            "id": "66f6ccd0-08e7-11eb-a5df-110eadfed9ed",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6a11ef80-08e7-11eb-a5df-110eadfed9ed",
                                    "type": "button",
                                    "children": [],
                                    "text": "Payment History",
                                    "variant": "text",
                                    "path": "/account/pay-dues-history"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "justify-content-center pt-1 pb-1"
                        }
                    ],
                    "orientation": "vertical"
                },
                {
                    "id": "465b45c0-f7a6-11ea-8efb-e99dbc19ac92",
                    "type": "container",
                    "children": [
                        {
                            "id": "5738bf30-f7a6-11ea-8efb-e99dbc19ac92",
                            "type": "button",
                            "children": [],
                            "disabled": false,
                            "text": "Paymaya",
                            "path": "/account/pay-dues/paymaya/0"
                        },
                        {
                            "id": "68f0ba20-f7a6-11ea-8efb-e99dbc19ac92",
                            "type": "button",
                            "children": [],
                            "text": "Credit Card",
                            "disabled": false,
                            "path": "/account/pay-dues/credit-card/0"
                        },
                        {
                            "id": "6b19b4a0-f7a6-11ea-8efb-e99dbc19ac92",
                            "type": "button",
                            "children": [],
                            "text": "Deposit/Online Transfer",
                            "path": "/account/pay-dues/deposit/0"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "main"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-pay-dues",
            "route": "/account/pay-dues"
        },
        {
            "id": "b0779440-f7a6-11ea-8efb-e99dbc19ac92",
            "type": "page",
            "children": [
                {
                    "id": "ba2f3830-f7a6-11ea-8efb-e99dbc19ac92",
                    "type": "container",
                    "children": [
                        {
                            "id": "23c42e40-f7a7-11ea-8efb-e99dbc19ac92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "2ddf7600-f7a7-11ea-8efb-e99dbc19ac92",
                                    "type": "paragraph",
                                    "children": [],
                                    "text": "You can deposit payments directly or do bank transfer:",
                                    "className": "my-2"
                                },
                                {
                                    "id": "3dd72de0-f7a8-11ea-8efb-e99dbc19ac92",
                                    "type": "paragraph",
                                    "children": [],
                                    "text": "Account Name: Pacific Land Ventures & Property Development Inc."
                                },
                                {
                                    "id": "4c2b1640-f7a8-11ea-8efb-e99dbc19ac92",
                                    "type": "paragraph",
                                    "children": [],
                                    "text": "Account Number: 2860-0005-74"
                                },
                                {
                                    "id": "3064f2f0-f7a8-11ea-8efb-e99dbc19ac92",
                                    "type": "paragraph",
                                    "children": [],
                                    "text": "Bank: Bank of the Philippine Islands (BPI)"
                                },
                                {
                                    "id": "590752c0-f7a8-11ea-8efb-e99dbc19ac92",
                                    "type": "paragraph",
                                    "children": [],
                                    "text": "Branch: Cebu Taboan Branch"
                                },
                                {
                                    "id": "e7c265c0-fde0-11ea-b546-e584feed1730",
                                    "type": "paragraph",
                                    "children": [],
                                    "text": "BPI Swift Code: BOPIPHMM"
                                },
                                {
                                    "id": "67e66420-f7a8-11ea-8efb-e99dbc19ac92",
                                    "type": "paragraph",
                                    "children": [],
                                    "className": "my-2",
                                    "text": "Take a photo of the deposit slip or screen shot of the online/bank transfer confirmation and upload here."
                                }
                            ],
                            "orientation": "vertical"
                        },
                        {
                            "id": "579c4680-f7a7-11ea-8efb-e99dbc19ac92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bb6549d0-0370-11eb-9871-a7d3e8f638e3",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "amount",
                                    "label": "Amount",
                                    "name": "amount",
                                    "required": true,
                                    "className": "",
                                    "defaultValue": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "pb-4"
                        },
                        {
                            "id": "743c9d30-f7a7-11ea-8efb-e99dbc19ac92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5f0ce0a0-f7a7-11ea-8efb-e99dbc19ac92",
                                    "type": "inputMedia",
                                    "children": [],
                                    "label": "Proof of Payment",
                                    "dataField": "photo",
                                    "name": "photo",
                                    "required": true,
                                    "basePath": "online-payments"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "8241c950-f7a7-11ea-8efb-e99dbc19ac92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "75ba3c80-f7a7-11ea-8efb-e99dbc19ac92",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "label": "Particulars",
                                    "dataField": "particulars",
                                    "name": "particulars",
                                    "required": true,
                                    "line": "8"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a0a524d0-0370-11eb-9871-a7d3e8f638e3",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a24f7970-0370-11eb-9871-a7d3e8f638e3",
                                    "type": "button",
                                    "children": [],
                                    "command": "save",
                                    "text": "Submit",
                                    "name": "save"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4801f960-3a6c-11eb-9608-13f79b46a41f",
                            "type": "inputText",
                            "children": [],
                            "className": "hide",
                            "defaultValue": "Deposit/Online Transfer",
                            "dataField": "mode"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "main"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-pay-dues-deposit",
            "route": "/account/pay-dues/deposit/:id",
            "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3"
        },
        {
            "id": "968362c0-fdf1-11ea-ba93-5145fd1d7baf",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "968362c1-fdf1-11ea-ba93-5145fd1d7baf",
                    "component": "LoginPortal"
                }
            ],
            "orientation": "vertical",
            "name": "login-portal-page",
            "route": "/login-portal",
            "component": "",
            "layout": "full"
        },
        {
            "id": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
            "type": "view",
            "children": [
                {
                    "id": "bb8a1dc0-01ed-11eb-81b5-f15d97fd1729",
                    "type": "inputText",
                    "children": [],
                    "label": "",
                    "placeholder": "Search",
                    "name": "search"
                }
            ],
            "orientation": "vertical",
            "name": "searchbar-only",
            "component": "Searchbar"
        },
        {
            "id": "0f34a160-0370-11eb-9871-a7d3e8f638e3",
            "type": "model",
            "children": [
                {
                    "id": "1deb9150-0370-11eb-9871-a7d3e8f638e3",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "amount"
                },
                {
                    "id": "218ab110-0370-11eb-9871-a7d3e8f638e3",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "particulars"
                },
                {
                    "id": "2bc65210-0370-11eb-9871-a7d3e8f638e3",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photo"
                },
                {
                    "id": "f816f2e0-1025-11eb-b134-49e9ab8733f5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "mode"
                },
                {
                    "id": "45672940-3a77-11eb-9608-13f79b46a41f",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                }
            ],
            "name": "online-payment"
        },
        {
            "id": "cc343590-0376-11eb-b9ea-3df39e640441",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "cc343591-0376-11eb-b9ea-3df39e640441",
                    "children": [
                        {
                            "id": "f1143630-0376-11eb-b9ea-3df39e640441",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f5df04b0-0376-11eb-b9ea-3df39e640441",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "className": "has-text-centered pb-2",
                                    "text": "Work Permits"
                                }
                            ],
                            "orientation": "vertical"
                        },
                        {
                            "id": "09753580-0377-11eb-b9ea-3df39e640441",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0c79ea00-0377-11eb-b9ea-3df39e640441",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "0deab810-0377-11eb-b9ea-3df39e640441",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "unit",
                                            "label": "Unit"
                                        },
                                        {
                                            "id": "1ac0b300-0377-11eb-b9ea-3df39e640441",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "natureOfWork",
                                            "label": "Nature of Work"
                                        },
                                        {
                                            "id": "224941f0-0377-11eb-b9ea-3df39e640441",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "contractor",
                                            "label": "Contractor"
                                        },
                                        {
                                            "id": "294a5160-0377-11eb-b9ea-3df39e640441",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "personInCharge",
                                            "label": "Person In Charge"
                                        },
                                        {
                                            "id": "3457c150-0377-11eb-b9ea-3df39e640441",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "description",
                                            "label": "Description"
                                        },
                                        {
                                            "id": "415122c0-0377-11eb-b9ea-3df39e640441",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "startDate",
                                            "label": "Start Date",
                                            "date": true
                                        },
                                        {
                                            "id": "4c33d930-0377-11eb-b9ea-3df39e640441",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "endDate",
                                            "label": "End Date",
                                            "date": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": ""
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-work-permits",
            "route": "/work-permits",
            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1"
        },
        {
            "id": "7d7c1ed0-0377-11eb-bfdc-3b5d643d4962",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "7d7c1ed1-0377-11eb-bfdc-3b5d643d4962",
                    "children": [
                        {
                            "id": "963af590-0377-11eb-bfdc-3b5d643d4962",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9a9fd650-0377-11eb-bfdc-3b5d643d4962",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Unit Requests",
                                    "className": "has-text-centered pb-2"
                                }
                            ],
                            "orientation": "vertical"
                        },
                        {
                            "id": "a56085d0-0377-11eb-bfdc-3b5d643d4962",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a7a0b1d0-0377-11eb-bfdc-3b5d643d4962",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "aa7bbe40-0377-11eb-bfdc-3b5d643d4962",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "unit",
                                            "label": "Unit"
                                        },
                                        {
                                            "id": "b592f230-0377-11eb-bfdc-3b5d643d4962",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "type",
                                            "label": "Type"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": ""
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-unit-requests",
            "route": "/unit-requests",
            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
        },
        {
            "id": "b6957d90-08e7-11eb-a5df-110eadfed9ed",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "b6957d91-08e7-11eb-a5df-110eadfed9ed",
                    "children": [
                        {
                            "id": "e2bae9a0-08e7-11eb-a5df-110eadfed9ed",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered mb-2",
                            "text": "Payment History"
                        },
                        {
                            "id": "eeb04890-08e7-11eb-a5df-110eadfed9ed",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f17cd610-08e7-11eb-a5df-110eadfed9ed",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "10f41a80-08e8-11eb-a5df-110eadfed9ed",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Date",
                                            "name": "createdAt",
                                            "date": true,
                                            "format": "MM/dd/yyyy hh:mm a"
                                        },
                                        {
                                            "id": "bd909980-3a7f-11eb-b43b-35557e2f71d4",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Mode",
                                            "dataField": "mode"
                                        },
                                        {
                                            "id": "1a701500-08e8-11eb-a5df-110eadfed9ed",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Particulars",
                                            "dataField": "particulars"
                                        },
                                        {
                                            "id": "2293d280-08e8-11eb-a5df-110eadfed9ed",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "amount",
                                            "label": "Amount",
                                            "currency": true
                                        },
                                        {
                                            "id": "c83d99f0-3a7f-11eb-b43b-35557e2f71d4",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "status",
                                            "label": "Status"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": "#"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical"
                }
            ],
            "orientation": "vertical",
            "route": "/account/pay-dues-history",
            "name": "homeowner-pay-dues-history",
            "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3"
        },
        {
            "id": "1e5949d0-0ffe-11eb-a75f-a764954330b6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "1e5949d1-0ffe-11eb-a75f-a764954330b6",
                    "children": [
                        {
                            "id": "2c223040-0ffe-11eb-a75f-a764954330b6",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Forgot Password"
                        },
                        {
                            "id": "334baf40-0ffe-11eb-a75f-a764954330b6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "352ddcc0-0ffe-11eb-a75f-a764954330b6",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Email",
                                    "name": "email",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "492148c0-0ffe-11eb-a75f-a764954330b6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4ad78440-0ffe-11eb-a75f-a764954330b6",
                                    "type": "button",
                                    "children": [],
                                    "text": "Submit",
                                    "command": "forgot_password"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "component": "ForgotPassword"
                }
            ],
            "orientation": "vertical",
            "name": "forgot-password",
            "route": "/forgot-password",
            "layout": "full"
        },
        {
            "id": "7589ccc0-1008-11eb-9f4b-55fb426750d7",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "7589ccc1-1008-11eb-9f4b-55fb426750d7",
                    "children": [
                        {
                            "id": "ac994240-1008-11eb-9f4b-55fb426750d7",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Reset Password"
                        },
                        {
                            "id": "b402fe90-1008-11eb-9f4b-55fb426750d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b5601d90-1008-11eb-9f4b-55fb426750d7",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "password",
                                    "label": "New Password",
                                    "password": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "adbb41c0-568d-11eb-867b-3deeecb92532",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b148ae40-568d-11eb-867b-3deeecb92532",
                                    "type": "inputText",
                                    "children": [],
                                    "password": true,
                                    "label": "Confirm Password",
                                    "name": "passwordConfirmation"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e55ad490-1008-11eb-9f4b-55fb426750d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e6ae2f90-1008-11eb-9f4b-55fb426750d7",
                                    "type": "button",
                                    "children": [],
                                    "html": "Submit",
                                    "command": "reset_password"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "component": "ResetPassword"
                }
            ],
            "orientation": "vertical",
            "name": "reset-password",
            "route": "/reset-password",
            "layout": "full"
        },
        {
            "id": "c8274690-1221-11eb-a9d8-83db08ab78a0",
            "type": "view",
            "children": [
                {
                    "id": "c9fe4920-1224-11eb-bc75-95cdd9dff5ce",
                    "type": "container",
                    "children": [
                        {
                            "id": "d0be57f0-1224-11eb-bc75-95cdd9dff5ce",
                            "type": "button",
                            "children": [],
                            "name": "save",
                            "command": "save",
                            "text": "Submit"
                        }
                    ],
                    "orientation": "horizontal",
                    "component": "ActionBar"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-commandbar",
            "component": "ActionBar"
        },
        {
            "id": "27c30fa0-1603-11eb-8555-975f97eacdba",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "27c30fa1-1603-11eb-8555-975f97eacdba",
                    "children": [
                        {
                            "id": "5a307450-1603-11eb-8555-975f97eacdba",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Visitor / Tenant Pass",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "6cd54040-1603-11eb-8555-975f97eacdba",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b3c70ae0-1646-11eb-b8c0-fd645d6bfd69",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "unit",
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "name": "unit",
                                    "label": "Unit",
                                    "disabled": true
                                }
                            ],
                            "orientation": "vertical"
                        },
                        {
                            "id": "cf588fa0-1604-11eb-9903-d9f0b8dd94fe",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0dae7c00-1647-11eb-91d3-e743baeee069",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "type",
                                    "label": "Type",
                                    "name": "type",
                                    "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                    "saveObject": true,
                                    "disabled": true
                                }
                            ],
                            "orientation": "vertical"
                        },
                        {
                            "id": "5f33d1a0-1648-11eb-91d3-e743baeee069",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6e6a78e0-1648-11eb-91d3-e743baeee069",
                                    "type": "subView",
                                    "children": [],
                                    "view": "5e6330a0-1647-11eb-91d3-e743baeee069",
                                    "name": "view-visitor-pass-visitor-pass"
                                },
                                {
                                    "id": "d3888570-164b-11eb-8554-b34543e4ea31",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-visitor-pass-short-term",
                                    "view": "69176030-164b-11eb-8554-b34543e4ea31"
                                },
                                {
                                    "id": "a62e4d70-164c-11eb-8554-b34543e4ea31",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-visitor-pass-long-term",
                                    "view": "2d3e3830-164c-11eb-8554-b34543e4ea31"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "view-visitor-pass",
                            "component": "VisitorPass"
                        },
                        {
                            "id": "f480fa90-166a-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f914da90-166a-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status",
                                    "disabled": true,
                                    "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops,pmg-security"
                        },
                        {
                            "id": "264af350-166b-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "283cff50-166b-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputText",
                                    "children": [],
                                    "required": false,
                                    "disabled": true,
                                    "multiline": true,
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops,pmg-security"
                        },
                        {
                            "id": "a9ec78d0-164a-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "beecc0f0-164a-11eb-8554-b34543e4ea31",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "status",
                                    "name": "status",
                                    "label": "Status",
                                    "disabled": false,
                                    "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "cd2ea7f0-164a-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d1844670-164a-11eb-8554-b34543e4ea31",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "cccdf410-167b-11eb-83c3-eb7956ee1f3f",
                            "type": "subView",
                            "children": [],
                            "access": "admin,pmg",
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "pass-visitor-detail-page",
            "route": "/pass-visitors-list/detail/:id",
            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
            "access": "admin,pmg"
        },
        {
            "id": "5e6330a0-1647-11eb-91d3-e743baeee069",
            "type": "view",
            "children": [
                {
                    "id": "820755e0-1647-11eb-91d3-e743baeee069",
                    "type": "container",
                    "children": [
                        {
                            "id": "a1419150-1647-11eb-91d3-e743baeee069",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.relationship",
                            "disabled": true,
                            "label": "Relationship to Owner"
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "ee506f70-1647-11eb-91d3-e743baeee069",
                    "type": "container",
                    "children": [
                        {
                            "id": "f154fce0-1647-11eb-91d3-e743baeee069",
                            "type": "inputList",
                            "children": [],
                            "label": "Name/s",
                            "name": "visitor",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "088bd410-1648-11eb-91d3-e743baeee069",
                    "type": "container",
                    "children": [
                        {
                            "id": "0dc19960-1648-11eb-91d3-e743baeee069",
                            "type": "inputDate",
                            "children": [],
                            "name": "startDate",
                            "label": "Start Date",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "1f634d30-1648-11eb-91d3-e743baeee069",
                    "type": "container",
                    "children": [
                        {
                            "id": "209c4260-1648-11eb-91d3-e743baeee069",
                            "type": "inputDate",
                            "children": [],
                            "name": "endDate",
                            "label": "End Date",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "27a38170-164a-11eb-9056-4178972142ab",
                    "type": "container",
                    "children": [
                        {
                            "id": "29dfdce0-164a-11eb-9056-4178972142ab",
                            "type": "inputMedia",
                            "children": [],
                            "name": "visitorsId",
                            "label": "Upload Valid ID (optional)",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-visitor-pass-visitor-pass"
        },
        {
            "id": "69176030-164b-11eb-8554-b34543e4ea31",
            "type": "view",
            "children": [
                {
                    "id": "6b7107a0-164b-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "867b2df0-164b-11eb-8554-b34543e4ea31",
                            "type": "inputList",
                            "children": [],
                            "name": "visitor",
                            "label": "Names/s",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "8fb78760-164b-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "9a8f8f70-164b-11eb-8554-b34543e4ea31",
                            "type": "inputDate",
                            "children": [],
                            "name": "startDate",
                            "label": "Start Date",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "a6236d70-164b-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "acacdbe0-164b-11eb-8554-b34543e4ea31",
                            "type": "inputDate",
                            "children": [],
                            "name": "endDate",
                            "label": "End Date",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal",
                    "name": ""
                },
                {
                    "id": "bf03d5f0-164b-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "c0586970-164b-11eb-8554-b34543e4ea31",
                            "type": "inputMedia",
                            "children": [],
                            "name": "visitorsId",
                            "label": "Upload Valid ID/ Passport",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-visitor-pass-short-term"
        },
        {
            "id": "2d3e3830-164c-11eb-8554-b34543e4ea31",
            "type": "view",
            "children": [
                {
                    "id": "32066ea0-164c-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "3e658be0-164c-11eb-8554-b34543e4ea31",
                            "type": "inputList",
                            "children": [],
                            "name": "visitor",
                            "label": "Name/s",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "496fa070-164c-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "4b2abdf0-164c-11eb-8554-b34543e4ea31",
                            "type": "inputDate",
                            "children": [],
                            "name": "startDate",
                            "label": "Start Date",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "57ced370-164c-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "58fdb680-164c-11eb-8554-b34543e4ea31",
                            "type": "inputDate",
                            "children": [],
                            "name": "endDate",
                            "label": "End Date",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "68265400-164c-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "6eabf1e0-164c-11eb-8554-b34543e4ea31",
                            "type": "inputMedia",
                            "children": [],
                            "name": "visitorsId",
                            "label": "Upload valid ID/ Passport (required)",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "79741b70-164c-11eb-8554-b34543e4ea31",
                    "type": "container",
                    "children": [
                        {
                            "id": "7e686af0-164c-11eb-8554-b34543e4ea31",
                            "type": "inputMedia",
                            "children": [],
                            "name": "meta.contract",
                            "label": "Upload Contract of Lease (required)",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-visitor-pass-long-term"
        },
        {
            "id": "fcc6c530-164d-11eb-bfd1-2d1eca45ef82",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "fcc6c531-164d-11eb-bfd1-2d1eca45ef82",
                    "children": [
                        {
                            "id": "ffd3de20-164d-11eb-bfd1-2d1eca45ef82",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered",
                            "text": "Delivery / Pullout Pass"
                        },
                        {
                            "id": "1a506a70-164e-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "22ed0170-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "unit",
                                    "label": "Unit",
                                    "disabled": true,
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "dataField": "unit"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "367b9120-164e-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4543e630-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "type",
                                    "name": "type",
                                    "label": "Type",
                                    "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                    "saveObject": true,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "58e6e840-164e-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5b4fd1f0-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "disabled": true,
                                    "name": "schedule",
                                    "label": "Schedule",
                                    "dataField": "schedule"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "6ad53570-164e-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6d64b9f0-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputList",
                                    "children": [],
                                    "name": "items",
                                    "dataField": "items",
                                    "disabled": true,
                                    "label": "Item(s) - Please enumerate including quantities."
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "8b160b70-164e-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "8ded9570-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "company",
                                    "label": "Company",
                                    "dataField": "company",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "be90bb40-166b-11eb-af06-b18a05fa97c7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c1974480-166b-11eb-af06-b18a05fa97c7",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status",
                                    "disabled": true,
                                    "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                    "access": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops,pmg-security"
                        },
                        {
                            "id": "da011d70-166b-11eb-af06-b18a05fa97c7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "dbe34af0-166b-11eb-af06-b18a05fa97c7",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "disabled": true,
                                    "access": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops,pmg-security"
                        },
                        {
                            "id": "d79a35c0-164e-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e29ad470-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status",
                                    "disabled": false,
                                    "access": "",
                                    "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "",
                            "dataField": "",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "1cc8a0f0-164f-11eb-bfd1-2d1eca45ef82",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1e99de80-164f-11eb-bfd1-2d1eca45ef82",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "access": "",
                                    "disabled": false,
                                    "multiline": true,
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "d95077d0-167b-11eb-83c3-eb7956ee1f3f",
                            "type": "subView",
                            "children": [],
                            "access": "admin,pmg",
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "pass-delivery-detail-page",
            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
            "route": "/pass-deliveries-list/detail/:id"
        },
        {
            "id": "8aa9f4a0-1679-11eb-83c3-eb7956ee1f3f",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "8aa9f4a1-1679-11eb-83c3-eb7956ee1f3f",
                    "children": [
                        {
                            "id": "a10782d0-1679-11eb-83c3-eb7956ee1f3f",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Work Permit",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "b8f28ed0-1679-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bc6c4c40-1679-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "unit",
                                    "label": "Unit",
                                    "disabled": true,
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "dataField": "unit",
                                    "saveObject": false
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": ""
                        },
                        {
                            "id": "d78e8e70-1679-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "dcd45950-1679-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Nature of Work",
                                    "dataField": "natureOfWork",
                                    "disabled": true,
                                    "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "f18a03e0-1679-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f8bf90d0-1679-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Contractor",
                                    "dataField": "contractor",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "18be06a0-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1cd849d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Person In Charge",
                                    "disabled": true,
                                    "dataField": "personInCharge"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "2e6bf3e0-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3073d1d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputText",
                                    "children": [],
                                    "disabled": true,
                                    "dataField": "contactPersonInCharge",
                                    "label": "Contact Number of Person-in-Charge"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3ac14d20-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3f4aa5d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputList",
                                    "children": [],
                                    "dataField": "workers",
                                    "label": "Workers",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4fc16e30-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "533a6850-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "dataField": "description",
                                    "label": "Description",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5f0a16d0-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "63b660d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputList",
                                    "children": [],
                                    "disabled": true,
                                    "label": "Materials",
                                    "dataField": "materials"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "6c94e2d0-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6f68c350-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputList",
                                    "children": [],
                                    "label": "Tools",
                                    "dataField": "tools",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "80193950-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "815f26d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputDate",
                                    "children": [],
                                    "disabled": true,
                                    "label": "Proposed Start Date ",
                                    "dataField": "startDate"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93c0f650-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "958bf250-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputDate",
                                    "children": [],
                                    "label": "Target End Date ",
                                    "dataField": "endDate",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "9e8687d0-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a3ec5dd0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputMedia",
                                    "children": [],
                                    "dataField": "plans",
                                    "disabled": true,
                                    "label": "Plans"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c271ef40-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c4962ed0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Status",
                                    "dataField": "status",
                                    "disabled": true,
                                    "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-security,pmg-ops"
                        },
                        {
                            "id": "de128930-167a-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ee1c10d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputText",
                                    "children": [],
                                    "disabled": true,
                                    "multiline": true,
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "dataField": "",
                            "access": "pmg-security,pmg-ops"
                        },
                        {
                            "id": "0c1cbad0-167b-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0f314dd0-167b-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "status",
                                    "label": "Status",
                                    "saveObject": true,
                                    "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "1d1424e0-167b-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "219bcfe0-167b-11eb-83c3-eb7956ee1f3f",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "notes",
                                    "multiline": true,
                                    "label": "Notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "ee8c4250-167b-11eb-83c3-eb7956ee1f3f",
                            "type": "subView",
                            "children": [],
                            "access": "admin,pmg",
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        },
                        {
                            "id": "f7789510-167d-11eb-83c3-eb7956ee1f3f",
                            "type": "header",
                            "children": [],
                            "size": 2
                        },
                        {
                            "id": "02c8da60-167e-11eb-83c3-eb7956ee1f3f",
                            "type": "header",
                            "children": [],
                            "size": 2
                        },
                        {
                            "id": "0b46c5d0-167e-11eb-83c3-eb7956ee1f3f",
                            "type": "header",
                            "children": [],
                            "size": 2
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "work-permit-detail-page",
            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
            "route": "/work-permits/detail/:id"
        },
        {
            "id": "0ef575f0-167e-11eb-83c3-eb7956ee1f32",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "8aa9f4a1-1679-11eb-83c3-eb7956ee1f33",
                    "children": [
                        {
                            "id": "349d8180-167e-11eb-83c3-eb7956ee1f3f",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Unit Request",
                            "className": "has-text-centered"
                        },
                        {
                            "id": "63396bd0-167e-11eb-83c3-eb7956ee1f3f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0fc02e10-1680-11eb-b4c7-db323e346a29",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1972a1e0-1680-11eb-b4c7-db323e346a29",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "unit",
                                            "label": "Unit",
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "unit-container"
                                },
                                {
                                    "id": "3194c370-1680-11eb-b4c7-db323e346a29",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "43541160-1680-11eb-b4c7-db323e346a29",
                                            "type": "inputSelect",
                                            "children": [],
                                            "label": "Type",
                                            "dataField": "type",
                                            "disabled": true,
                                            "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "type-container"
                                },
                                {
                                    "id": "99d2dc10-1680-11eb-b4c7-db323e346a29",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-unit-request-aircon-cleaning",
                                    "view": "989101e3-d9a6-11ea-891d-3722b00ad4e7"
                                },
                                {
                                    "id": "39d51430-1681-11eb-b4c7-db323e346a29",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-unit-request-grease-trap-cleaning",
                                    "view": "0c744882-d9a5-11ea-a304-319701533d32"
                                },
                                {
                                    "id": "87c85ee0-1681-11eb-b4c7-db323e346a29",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-unit-request-others",
                                    "view": "f7b9bf21-d9a8-11ea-9331-090047662dd2"
                                },
                                {
                                    "id": "d1bcdda0-1681-11eb-b4c7-db323e346a29",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-unit-request-plans-documents",
                                    "view": "e60fdb41-c910-11ea-9dd0-6723feee229e"
                                },
                                {
                                    "id": "5096c5f0-1682-11eb-b4c7-db323e346a29",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-unit-request-refund-renovation-bond",
                                    "view": "063f7331-c911-11ea-9dd0-6723feee229e"
                                },
                                {
                                    "id": "d3ff3710-1682-11eb-b4c7-db323e346a29",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-unit-request-service-request",
                                    "view": "e00cd7b1-c90c-11ea-8c59-196c3ec4f839"
                                },
                                {
                                    "id": "84d5c0e0-1683-11eb-b4c7-db323e346a29",
                                    "type": "subView",
                                    "children": [],
                                    "name": "view-unit-request-vehicle-sticker",
                                    "view": "8df270e1-d918-11ea-b3eb-d9cf4159b042"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "UnitRequest",
                            "name": "view-unit-request"
                        },
                        {
                            "id": "9d35aec0-1683-11eb-b4c7-db323e346a29",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9edecae0-1683-11eb-b4c7-db323e346a29",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "status",
                                    "disabled": true,
                                    "label": "Status",
                                    "access": "",
                                    "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"canceled\", \"label\": \"Canceled\"}]"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops,pmg-security,accounting,accounting-staff",
                            "dataField": ""
                        },
                        {
                            "id": "c98a4120-1683-11eb-b4c7-db323e346a29",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cd82a470-1683-11eb-b4c7-db323e346a29",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "multiline": true,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops,pmg-security,accounting,accounting-staff"
                        },
                        {
                            "id": "9d35aec1-1683-11eb-b4c7-db323e346a29",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9edecae1-1683-11eb-b4c7-db323e346a29",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "status",
                                    "disabled": false,
                                    "label": "Status",
                                    "access": "",
                                    "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg",
                            "dataField": ""
                        },
                        {
                            "id": "c98a4121-1683-11eb-b4c7-db323e346a29",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cd82a471-1683-11eb-b4c7-db323e346a29",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "multiline": true,
                                    "disabled": false
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "27ca36f0-1684-11eb-b4c7-db323e346a29",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                            "access": "admin,pmg"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "unit-request-detail-page",
            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
            "route": "/unit-requests/detail/:id"
        },
        {
            "id": "989101e3-d9a6-11ea-891d-3722b00ad4e7",
            "type": "view",
            "children": [
                {
                    "id": "d3e39f53-d9a6-11ea-891d-3722b00ad4e7",
                    "type": "container",
                    "children": [
                        {
                            "id": "d5efea13-d9a6-11ea-891d-3722b00ad4e7",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.units",
                            "label": "No. of Units",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "db6aa7a3-d9a6-11ea-891d-3722b00ad4e7",
                    "type": "container",
                    "children": [
                        {
                            "id": "e1bfdbe3-edcc-11ea-abfa-8f89576fb378",
                            "type": "inputDateTime",
                            "children": [],
                            "name": "meta.schedule",
                            "label": "Preferred Schedule",
                            "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-unit-request-aircon-cleaning",
            "_sortIndex": 590
        },
        {
            "id": "0c744882-d9a5-11ea-a304-319701533d32",
            "type": "view",
            "children": [
                {
                    "id": "1c9ddb42-d9a5-11ea-a304-319701533d32",
                    "type": "container",
                    "children": [
                        {
                            "id": "fbbdc252-edcc-11ea-abfa-8f89576fb378",
                            "type": "inputDateTime",
                            "children": [],
                            "name": "meta.schedule",
                            "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                            "label": "Preferred Schedule",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-unit-request-grease-trap-cleaning",
            "_sortIndex": 600
        },
        {
            "id": "f7b9bf21-d9a8-11ea-9331-090047662dd2",
            "type": "view",
            "children": [
                {
                    "id": "fe4688f1-d9a8-11ea-9331-090047662dd2",
                    "type": "container",
                    "children": [
                        {
                            "id": "ff497a01-d9a8-11ea-9331-090047662dd2",
                            "type": "inputText",
                            "children": [],
                            "multiline": true,
                            "name": "meta.details",
                            "label": "Please Specify",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-unit-request-others",
            "_sortIndex": 610
        },
        {
            "id": "e60fdb41-c910-11ea-9dd0-6723feee229e",
            "type": "view",
            "children": [
                {
                    "id": "e85ead42-c910-11ea-9dd0-6723feee229e",
                    "type": "container",
                    "children": [
                        {
                            "id": "076a4bb2-c91e-11ea-831c-99fd7b91f646",
                            "type": "inputSelect",
                            "children": [],
                            "name": "meta.plans",
                            "label": "Plans Required",
                            "options": "[{ \"value\": \"standard\", \"label\": \"Standard Plan\"},{ \"value\": \"as-built\", \"label\": \"As-built Plan\"}]",
                            "className": "bite-select",
                            "saveObject": true,
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal",
                    "name": "homeowner-service-request-repairs",
                    "className": "bite-select"
                },
                {
                    "id": "112746d2-c91e-11ea-831c-99fd7b91f646",
                    "type": "container",
                    "children": [
                        {
                            "id": "44b19ca2-d916-11ea-9ea3-7184c3941d0d",
                            "type": "inputSelect",
                            "children": [],
                            "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                            "saveObject": true,
                            "name": "meta.paper-size",
                            "label": "Paper Size",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal",
                    "component": "UnitRequestPlansDocuments"
                },
                {
                    "id": "97e9eee2-d916-11ea-9ea3-7184c3941d0d",
                    "type": "container",
                    "children": [
                        {
                            "id": "9a6e28c2-d916-11ea-9ea3-7184c3941d0d",
                            "type": "inputSelect",
                            "children": [],
                            "name": "meta.type",
                            "label": "Type of Plan",
                            "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing\", \"label\": \"Plumbing\"},{ \"value\": \"electrical-plan\", \"label\": \"Electrical Plan\"}]",
                            "saveObject": true,
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-unit-request-plans-documents",
            "_sortIndex": 620
        },
        {
            "id": "063f7331-c911-11ea-9dd0-6723feee229e",
            "type": "view",
            "children": [
                {
                    "id": "1cea7cc1-c91f-11ea-b910-c30a77ee18ba",
                    "type": "container",
                    "children": [
                        {
                            "id": "b0a9a301-ef9e-11ea-82b2-a7421559c7b2",
                            "type": "inputDate",
                            "children": [],
                            "name": "meta.inspection-date",
                            "label": "Preferred Inspection Date",
                            "description": "Monday to Friday, except Holidays",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal",
                    "className": ""
                },
                {
                    "id": "b9eb6b31-d917-11ea-9ea3-7184c3941d0d",
                    "type": "container",
                    "children": [
                        {
                            "id": "d8830d81-ef9e-11ea-82b2-a7421559c7b2",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.additional-requests",
                            "multiline": true,
                            "label": "Additional Requests",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-unit-request-refund-renovation-bond",
            "_sortIndex": 630
        },
        {
            "id": "e00cd7b1-c90c-11ea-8c59-196c3ec4f839",
            "type": "view",
            "children": [
                {
                    "id": "743faf71-c90d-11ea-8c59-196c3ec4f839",
                    "type": "container",
                    "children": [
                        {
                            "id": "a9319f51-c911-11ea-98ab-e51558e3434e",
                            "type": "inputSelect",
                            "children": [],
                            "label": "Type of Work",
                            "name": "meta.type",
                            "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                            "className": "bite-select",
                            "saveObject": true,
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal",
                    "className": "bite-select"
                },
                {
                    "id": "c6681b81-c911-11ea-98ab-e51558e3434e",
                    "type": "container",
                    "children": [
                        {
                            "id": "c84dcb71-c911-11ea-98ab-e51558e3434e",
                            "type": "inputText",
                            "children": [],
                            "label": "Work Details",
                            "name": "meta.details",
                            "multiline": true,
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "cf0bde71-c911-11ea-98ab-e51558e3434e",
                    "type": "container",
                    "children": [
                        {
                            "id": "ccce1d41-edcd-11ea-993f-a977bcf36a2d",
                            "type": "inputDateTime",
                            "children": [],
                            "name": "meta.schedule",
                            "label": "Preferred Inspection/Work Schedule",
                            "description": " Monday to Friday only. Except Holidays",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal",
                    "className": "bite-select"
                }
            ],
            "orientation": "vertical",
            "name": "view-unit-request-service-request",
            "_sortIndex": 640
        },
        {
            "id": "8df270e1-d918-11ea-b3eb-d9cf4159b042",
            "type": "view",
            "children": [
                {
                    "id": "b33b28f1-d919-11ea-b3eb-d9cf4159b042",
                    "type": "container",
                    "children": [
                        {
                            "id": "b80cd541-d919-11ea-b3eb-d9cf4159b042",
                            "type": "inputSelect",
                            "children": [],
                            "name": "meta.make",
                            "label": "Make",
                            "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                            "saveObject": true,
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "c394f371-d919-11ea-b3eb-d9cf4159b042",
                    "type": "container",
                    "children": [
                        {
                            "id": "c6827671-d919-11ea-b3eb-d9cf4159b042",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.model",
                            "label": "Model",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "ad71c601-d9a4-11ea-a304-319701533d32",
                    "type": "container",
                    "children": [
                        {
                            "id": "b1b90ca1-d9a4-11ea-a304-319701533d32",
                            "type": "inputSelect",
                            "children": [],
                            "name": "meta.color",
                            "label": "Color",
                            "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                            "saveObject": true,
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "c744cbe1-d9a4-11ea-a304-319701533d32",
                    "type": "container",
                    "children": [
                        {
                            "id": "cfb34e01-d9a4-11ea-a304-319701533d32",
                            "type": "inputText",
                            "children": [],
                            "name": "meta.plate-number",
                            "label": "Plate Number",
                            "required": false,
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                },
                {
                    "id": "ddd18061-d9a4-11ea-a304-319701533d32",
                    "type": "container",
                    "children": [
                        {
                            "id": "deacec41-d9a4-11ea-a304-319701533d32",
                            "type": "inputMedia",
                            "children": [],
                            "name": "meta.OR",
                            "description": "",
                            "label": "Upload O.R. / C.R.",
                            "disabled": true
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "vertical",
            "name": "view-unit-request-vehicle-sticker",
            "_sortIndex": 650
        },
        {
            "id": "42414a60-1697-11eb-9686-795f0ea8c25b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "f8571491-1685-11eb-9686-795f0ea8c25b",
                    "children": [
                        {
                            "id": "4b92b270-1697-11eb-9686-795f0ea8c25b",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered",
                            "text": "Message Board"
                        },
                        {
                            "id": "0e292670-1698-11eb-9686-795f0ea8c25b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1324c8f0-1698-11eb-9686-795f0ea8c25b",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "21d96ef0-1698-11eb-9686-795f0ea8c25b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "2617dbf0-1698-11eb-9686-795f0ea8c25b",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Homeowner",
                                    "dataField": "homeowner",
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "34022d10-1698-11eb-9686-795f0ea8c25b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3743ea90-1698-11eb-9686-795f0ea8c25b",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Topic",
                                    "dataField": "topic",
                                    "disabled": true,
                                    "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"LF\", \"label\": \"LF (Looking For)\"}]"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "1bf85900-1699-11eb-9686-795f0ea8c25b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1eff5770-1699-11eb-9686-795f0ea8c25b",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Title",
                                    "dataField": "title",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "279362f0-1699-11eb-9686-795f0ea8c25b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "285923f0-1699-11eb-9686-795f0ea8c25b",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "price",
                                    "label": "Price",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "30ab4470-1699-11eb-9686-795f0ea8c25b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "31933370-1699-11eb-9686-795f0ea8c25b",
                                    "type": "inputText",
                                    "children": [],
                                    "disabled": true,
                                    "multiline": true,
                                    "dataField": "description",
                                    "label": "Description"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3f7239f0-1699-11eb-9686-795f0ea8c25b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4348e470-1699-11eb-9686-795f0ea8c25b",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "contactNumber",
                                    "label": "Contact Number",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4d43d2f0-1699-11eb-9686-795f0ea8c25b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "505c3680-1699-11eb-9686-795f0ea8c25b",
                                    "type": "inputMedia",
                                    "children": [],
                                    "basePath": "message-boards",
                                    "dataField": "photos",
                                    "label": "Photos",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "9805a1e0-169b-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9f8c0df0-169b-11eb-a20e-bba0e48fa309",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "dataField": "datePosted",
                                    "label": "Date Posted",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "b53dcbc0-169b-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b9916e70-169b-11eb-a20e-bba0e48fa309",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Status",
                                    "disabled": true,
                                    "dataField": "status",
                                    "options": "[{\"value\": \"done\", \"label\": \"Done\"}, {\"value\": \"approved\", \"label\": \"Approved\"}, {\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]"
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops"
                        },
                        {
                            "id": "cf34d460-169b-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e9280c70-169b-11eb-a20e-bba0e48fa309",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "multiline": true,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops"
                        },
                        {
                            "id": "1bd00920-169c-11eb-8c30-87365a215fb4",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1d419a80-169c-11eb-8c30-87365a215fb4",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Status",
                                    "options": "[{\"value\": \"done\", \"label\": \"Done\"}, {\"value\": \"approved\", \"label\": \"Approved\"}, {\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                    "saveObject": true,
                                    "dataField": "status",
                                    "access": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "30ee1270-169c-11eb-8c30-87365a215fb4",
                            "type": "container",
                            "children": [
                                {
                                    "id": "32d17870-169c-11eb-8c30-87365a215fb4",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "4db7ea20-169c-11eb-8c30-87365a215fb4",
                            "type": "subView",
                            "children": [],
                            "access": "admin,pmg",
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "message-board-detail-page",
            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
            "route": "/message-boards/detail/:id"
        },
        {
            "id": "89516680-169a-11eb-a20e-bba0e48fa309",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "89516681-169a-11eb-a20e-bba0e48fa309",
                    "children": [
                        {
                            "id": "92ffe2b0-169a-11eb-a20e-bba0e48fa309",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered",
                            "text": "Comments and Suggestions"
                        },
                        {
                            "id": "baf5aed0-169a-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bda405f0-169a-11eb-a20e-bba0e48fa309",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Type",
                                    "disabled": true,
                                    "dataField": "type",
                                    "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"}, {\"label\": \"Security\", \"value\": \"security\"}, {\"label\": \"Safety\", \"value\": \"safety\"}, {\"label\": \"PMG Crew/ Team Conduct\", \"value\": \"team-conduct\"} ,{\"label\": \"Others\", \"value\": \"others\"}]"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c8c8ce70-169a-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d2e5eaf0-169a-11eb-a20e-bba0e48fa309",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Details",
                                    "multiline": true,
                                    "disabled": true,
                                    "dataField": "details"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e8155960-169a-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f0ede7f0-169a-11eb-a20e-bba0e48fa309",
                                    "type": "inputMedia",
                                    "children": [],
                                    "basePath": "comments-suggestions",
                                    "label": "Photos",
                                    "disabled": true,
                                    "dataField": "photos"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "ff28a300-169a-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "00684ef0-169b-11eb-a20e-bba0e48fa309",
                                    "type": "inputSelect",
                                    "children": [],
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "dataField": "homeowner",
                                    "label": "Homeowner",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "15204370-169b-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "16cf2bf0-169b-11eb-a20e-bba0e48fa309",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "dataField": "notes",
                                    "label": "Notes",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "pmg-ops"
                        },
                        {
                            "id": "31f0aad0-169b-11eb-a20e-bba0e48fa309",
                            "type": "container",
                            "children": [
                                {
                                    "id": "336681f0-169b-11eb-a20e-bba0e48fa309",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Notes",
                                    "dataField": "notes",
                                    "multiline": true,
                                    "access": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "access": "admin,pmg"
                        },
                        {
                            "id": "4eacedf0-169b-11eb-a20e-bba0e48fa309",
                            "type": "subView",
                            "children": [],
                            "access": "admin,pmg",
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "comments-suggestions-detail-page",
            "route": "/comments-suggestions/detail/:id",
            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1"
        },
        {
            "id": "f69b9500-169d-11eb-8c30-87365a215fb4",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "f69b9501-169d-11eb-8c30-87365a215fb4",
                    "children": [
                        {
                            "id": "fe1fde30-169d-11eb-8c30-87365a215fb4",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Billing",
                            "className": "has-text-centered"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "billing-list-detail-page"
        },
        {
            "id": "c677f620-26ec-11eb-866d-ef520cc7ee92",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c677f621-26ec-11eb-866d-ef520cc7ee92",
                    "children": [
                        {
                            "id": "c6a1c540-26ec-11eb-866d-ef520cc7ee92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c6a1c542-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit",
                                    "saveObject": true,
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c6a1c543-26ec-11eb-866d-ef520cc7ee92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6fd162b0-26ed-11eb-866d-ef520cc7ee92",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Type",
                                    "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                    "saveObject": true,
                                    "dataField": "type"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c6a1ec5b-26ec-11eb-866d-ef520cc7ee92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c3d653c0-26ed-11eb-866d-ef520cc7ee92",
                                    "type": "subView",
                                    "children": [],
                                    "view": "5e6330a0-1647-11eb-91d3-e743baeee069",
                                    "name": "view-visitor-pass-visitor-pass"
                                },
                                {
                                    "id": "cf443330-26ed-11eb-866d-ef520cc7ee92",
                                    "type": "subView",
                                    "children": [],
                                    "view": "69176030-164b-11eb-8554-b34543e4ea31",
                                    "name": "view-visitor-pass-short-term"
                                },
                                {
                                    "id": "d50ce9b0-26ed-11eb-866d-ef520cc7ee92",
                                    "type": "subView",
                                    "children": [],
                                    "view": "2d3e3830-164c-11eb-8554-b34543e4ea31",
                                    "name": "view-visitor-pass-long-term"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "view-visitor-pass",
                            "component": "VisitorPass"
                        },
                        {
                            "id": "c6a1ec5e-26ec-11eb-866d-ef520cc7ee92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c6a1ec60-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c6a1ec61-26ec-11eb-866d-ef520cc7ee92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c6a1ec63-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "pass-visitor-archive-page",
            "route": "/pass-visitors/archive/:id",
            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
            "access": "noone"
        },
        {
            "id": "c6aa77d0-26ec-11eb-866d-ef520cc7ee92",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c677f621-26ec-11eb-866d-ef520cc7ee92",
                    "children": [
                        {
                            "id": "c6b57450-26ec-11eb-866d-ef520cc7ee92",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                        },
                        {
                            "id": "c6bf8670-26ec-11eb-866d-ef520cc7ee92",
                            "type": "table",
                            "children": [
                                {
                                    "id": "c6c68b50-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "dataField": "",
                                    "date": true
                                },
                                {
                                    "id": "c6c68b52-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit"
                                },
                                {
                                    "id": "c6c68b5a-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "type",
                                    "label": "Type",
                                    "dataField": "type"
                                },
                                {
                                    "id": "c6c68b54-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "dataField": "startDate"
                                },
                                {
                                    "id": "c6c68b56-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "endDate",
                                    "label": "End Date",
                                    "dataField": "endDate"
                                },
                                {
                                    "id": "c6c68b5c-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status"
                                },
                                {
                                    "id": "c6c68b5e-26ec-11eb-866d-ef520cc7ee92",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/pass-visitors/archive"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "pass-visitor-archive-list",
            "route": "/pass-visitors-list/archive",
            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
            "params": "{\"or:\":[{\"status\": \"checked-out\"},{\"status\": \"disapproved\"}]}"
        },
        {
            "id": "43514190-26ef-11eb-96bc-0f1c3895619b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "43514191-26ef-11eb-96bc-0f1c3895619b",
                    "children": [
                        {
                            "id": "436b8050-26ef-11eb-96bc-0f1c3895619b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "436b8052-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit",
                                    "saveObject": true,
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "436b8053-26ef-11eb-96bc-0f1c3895619b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "44b87d40-26f0-11eb-96bc-0f1c3895619b",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Type",
                                    "name": "type_.label"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "436b8056-26ef-11eb-96bc-0f1c3895619b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "bf28e660-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "dataField": "schedule",
                                    "format": "",
                                    "label": "Schedule"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "436ba760-26ef-11eb-96bc-0f1c3895619b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d59e1b40-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "inputList",
                                    "children": [],
                                    "label": "Items",
                                    "dataField": "items"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "436ba763-26ef-11eb-96bc-0f1c3895619b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "436ba765-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "company",
                                    "label": "Company",
                                    "dataField": "company"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "436ba766-26ef-11eb-96bc-0f1c3895619b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "436ba768-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "status_.label",
                                    "label": "Status",
                                    "dataField": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "436ba769-26ef-11eb-96bc-0f1c3895619b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "436ba76b-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "pass-delivery-archive-page",
            "route": "/pass-deliveries/archive/:id",
            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
            "access": "noone"
        },
        {
            "id": "437607a0-26ef-11eb-96bc-0f1c3895619b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "43514191-26ef-11eb-96bc-0f1c3895619b",
                    "children": [
                        {
                            "id": "437d5aa0-26ef-11eb-96bc-0f1c3895619b",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                        },
                        {
                            "id": "4385e620-26ef-11eb-96bc-0f1c3895619b",
                            "type": "table",
                            "children": [
                                {
                                    "id": "67887f10-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "date": true
                                },
                                {
                                    "id": "438e2380-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit"
                                },
                                {
                                    "id": "438e2382-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "type",
                                    "label": "Type",
                                    "dataField": "type"
                                },
                                {
                                    "id": "438e2384-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "schedule",
                                    "label": "Schedule",
                                    "dataField": "schedule"
                                },
                                {
                                    "id": "438e2388-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "company",
                                    "label": "Company",
                                    "dataField": "company"
                                },
                                {
                                    "id": "438e238a-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status"
                                },
                                {
                                    "id": "438e4a90-26ef-11eb-96bc-0f1c3895619b",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/pass-deliveries/archive"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "pass-delivery-archive-list",
            "route": "/pass-deliveries-list/archive",
            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
            "params": "{\"or:\":[{\"status\": \"done\"},{\"status\": \"cancelled\"}]}"
        },
        {
            "id": "5f9cfdb0-26f1-11eb-ac91-c74c3e25a4bd",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                    "children": [
                        {
                            "id": "5fae62d0-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae62d2-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit",
                                    "saveObject": true,
                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae62d3-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae62d5-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "natureOfWork_.label",
                                    "label": "Nature of Work",
                                    "dataField": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae62d6-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae62d8-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contractor",
                                    "label": "Contractor",
                                    "dataField": "contractor"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae62d9-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae62db-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "personInCharge",
                                    "label": "Person in Charge",
                                    "dataField": "personInCharge"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae62dc-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae62de-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contactPersonInCharge",
                                    "label": "Contact Number of Person-in-Charge",
                                    "dataField": "contactPersonInCharge"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89e0-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "09f6ea50-26f2-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputList",
                                    "children": [],
                                    "label": "Workers",
                                    "dataField": "workers"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89e3-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae89e5-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89e6-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f01dc9f0-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputList",
                                    "children": [],
                                    "label": "Materials",
                                    "dataField": "materials"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89e9-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "15a68db0-26f2-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputList",
                                    "children": [],
                                    "label": "Tools",
                                    "dataField": "tools"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89ec-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae89ee-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "dataField": "startDate",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89ef-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae89f1-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "endDate",
                                    "label": "End Date",
                                    "dataField": "endDate",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89f2-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae89f4-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "plans",
                                    "label": "Plans",
                                    "dataField": "plans",
                                    "basePath": "work-permits"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89f5-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae89f7-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "status_.label",
                                    "label": "Status",
                                    "dataField": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5fae89f8-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5fae89fa-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "work-permit-archive-page",
            "route": "/work-permits/archive/:id",
            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
            "access": "noone"
        },
        {
            "id": "5fbb8230-26f1-11eb-ac91-c74c3e25a4bd",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                    "children": [
                        {
                            "id": "5fc6ccd0-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                        },
                        {
                            "id": "5fce6df0-26f1-11eb-ac91-c74c3e25a4bd",
                            "type": "table",
                            "children": [
                                {
                                    "id": "95fb72b0-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Date Submitted",
                                    "name": "createdAt",
                                    "date": true
                                },
                                {
                                    "id": "5fd72080-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit"
                                },
                                {
                                    "id": "5fd72082-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "natureOfWork",
                                    "label": "Nature of Work",
                                    "dataField": "natureOfWork"
                                },
                                {
                                    "id": "5fd72084-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "contractor",
                                    "label": "Contractor",
                                    "dataField": "contractor"
                                },
                                {
                                    "id": "5fd72086-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "personInCharge",
                                    "label": "Person in Charge",
                                    "dataField": "personInCharge"
                                },
                                {
                                    "id": "5fd72088-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "contactPersonInCharge",
                                    "label": "Contact Number of Person-in-Charge",
                                    "dataField": "contactPersonInCharge"
                                },
                                {
                                    "id": "5fd72092-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "dataField": "startDate"
                                },
                                {
                                    "id": "5fd72094-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "endDate",
                                    "label": "End Date",
                                    "dataField": "endDate"
                                },
                                {
                                    "id": "5fd72098-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status"
                                },
                                {
                                    "id": "5fd7209a-26f1-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/work-permits/archive"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "work-permit-archive-list",
            "route": "/work-permits-list/archive",
            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
            "params": "{\"or:\":[{\"status\": \"done\"},{\"status\": \"cancelled\"}]}"
        },
        {
            "id": "cb6da100-26f3-11eb-ac91-c74c3e25a4bd",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                    "children": [
                        {
                            "id": "cb839a06-26f3-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e2736a30-2700-11eb-ac91-c74c3e25a4bd",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f1a9ea60-2700-11eb-ac91-c74c3e25a4bd",
                                            "type": "inputText",
                                            "children": [
                                                {
                                                    "id": "4eb402da-2702-11eb-ac91-c74c3e25a4bd",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "contactNumber",
                                                    "label": "Contact Number",
                                                    "dataField": "contactNumber"
                                                }
                                            ],
                                            "name": "type_.label",
                                            "label": "Type"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "type-container"
                                },
                                {
                                    "id": "ff372760-2700-11eb-ac91-c74c3e25a4bd",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "cb839a08-26f3-11eb-ac91-c74c3e25a4bd",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "unit",
                                            "label": "Unit",
                                            "dataField": "unit",
                                            "saveObject": true,
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "unit-container"
                                },
                                {
                                    "id": "1df024e0-2701-11eb-ac91-c74c3e25a4bd",
                                    "type": "subView",
                                    "children": [],
                                    "view": "989101e3-d9a6-11ea-891d-3722b00ad4e7",
                                    "name": "view-unit-request-aircon-cleaning"
                                },
                                {
                                    "id": "34aee5e0-2701-11eb-ac91-c74c3e25a4bd",
                                    "type": "subView",
                                    "children": [],
                                    "view": "0c744882-d9a5-11ea-a304-319701533d32",
                                    "name": "view-unit-request-grease-trap-cleaning"
                                },
                                {
                                    "id": "45432150-2701-11eb-ac91-c74c3e25a4bd",
                                    "type": "subView",
                                    "children": [],
                                    "view": "f7b9bf21-d9a8-11ea-9331-090047662dd2",
                                    "name": "view-unit-request-others"
                                },
                                {
                                    "id": "59330ae0-2701-11eb-ac91-c74c3e25a4bd",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e60fdb41-c910-11ea-9dd0-6723feee229e",
                                    "name": "view-unit-request-plans-documents"
                                },
                                {
                                    "id": "63b578e0-2701-11eb-ac91-c74c3e25a4bd",
                                    "type": "subView",
                                    "children": [],
                                    "view": "063f7331-c911-11ea-9dd0-6723feee229e",
                                    "name": "view-unit-request-refund-renovation-bond"
                                },
                                {
                                    "id": "71da13e0-2701-11eb-ac91-c74c3e25a4bd",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e00cd7b1-c90c-11ea-8c59-196c3ec4f839",
                                    "name": "view-unit-request-service-request"
                                },
                                {
                                    "id": "7e5d33e0-2701-11eb-ac91-c74c3e25a4bd",
                                    "type": "subView",
                                    "children": [],
                                    "view": "8df270e1-d918-11ea-b3eb-d9cf4159b042",
                                    "name": "view-unit-request-vehicle-sticker"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "view-unit-request",
                            "component": "UnitRequest"
                        },
                        {
                            "id": "cb839a03-26f3-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cb839a05-26f3-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "status_.label",
                                    "label": "Status",
                                    "dataField": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "cb839a09-26f3-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cb839a0b-26f3-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "unit-request-archive-page",
            "route": "/unit-requests/archive/:id",
            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
            "access": "noone"
        },
        {
            "id": "cb8ce8d0-26f3-11eb-ac91-c74c3e25a4bd",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                    "children": [
                        {
                            "id": "cb9637a0-26f3-11eb-ac91-c74c3e25a4bd",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                        },
                        {
                            "id": "cb9cee60-26f3-11eb-ac91-c74c3e25a4bd",
                            "type": "table",
                            "children": [
                                {
                                    "id": "1058f340-26f5-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "date": true
                                },
                                {
                                    "id": "cba504b4-26f3-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit",
                                    "label": "Unit",
                                    "dataField": "unit"
                                },
                                {
                                    "id": "cba504b0-26f3-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "type",
                                    "label": "Type",
                                    "dataField": "type"
                                },
                                {
                                    "id": "cba504b2-26f3-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status"
                                },
                                {
                                    "id": "cba504b6-26f3-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/unit-requests/archive"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "unit-request-archive-list",
            "route": "/unit-requests-list/archive",
            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
            "params": "{\"or:\":[{\"status\": \"completed\"},{\"status\": \"cancelled\"}]}"
        },
        {
            "id": "4e7217d0-2702-11eb-ac91-c74c3e25a4bd",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4b5",
                    "children": [
                        {
                            "id": "f5002f60-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fc57ea50-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "createdAt",
                                    "label": "Date Submitted"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e2b50-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e2b52-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner",
                                    "saveObject": true,
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e2b53-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e2b55-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "topic_.label",
                                    "label": "Topic",
                                    "dataField": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e2b56-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e2b58-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e2b59-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e2b5b-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "price",
                                    "label": "Price",
                                    "dataField": "price"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e2b5c-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e2b5e-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description",
                                    "multiline": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e2b5f-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e5261-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contactNumber",
                                    "label": "ContactNumber",
                                    "dataField": "contactNumber"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e5262-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e5264-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "photos",
                                    "label": "Photos",
                                    "dataField": "photos",
                                    "basePath": "message-boards"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e5265-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e5267-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "status_.label",
                                    "label": "Status",
                                    "dataField": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e5268-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e526a-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4e8e526b-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e8e526d-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "datePosted",
                                    "label": "Date Posted",
                                    "dataField": "datePosted",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "message-board-archive-page",
            "route": "/message-boards/archive/:id",
            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
            "access": "noone",
            "params": ""
        },
        {
            "id": "4e9a8760-2702-11eb-ac91-c74c3e25a4bd",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                    "children": [
                        {
                            "id": "4ea339f0-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                        },
                        {
                            "id": "4eabc570-2702-11eb-ac91-c74c3e25a4bd",
                            "type": "table",
                            "children": [
                                {
                                    "id": "844b3030-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Submitted",
                                    "date": true
                                },
                                {
                                    "id": "4eb402d2-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "topic",
                                    "label": "Topic",
                                    "dataField": "topic"
                                },
                                {
                                    "id": "4eb402d4-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "4eb402d6-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "price",
                                    "label": "Price",
                                    "dataField": "price"
                                },
                                {
                                    "id": "4eb402d8-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                },
                                {
                                    "id": "4eb402da-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "contactNumber",
                                    "label": "ContactNumber",
                                    "dataField": "contactNumber"
                                },
                                {
                                    "id": "4eb402d0-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner"
                                },
                                {
                                    "id": "4eb402de-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "status",
                                    "label": "Status",
                                    "dataField": "status"
                                },
                                {
                                    "id": "4eb429e1-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "notes",
                                    "label": "Notes",
                                    "dataField": "notes"
                                },
                                {
                                    "id": "4eb429e3-2702-11eb-ac91-c74c3e25a4bd",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "datePosted",
                                    "label": "Date Posted",
                                    "dataField": "datePosted",
                                    "date": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/message-boards/archive"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "message-board-archive-list",
            "route": "/message-boards-list/archive",
            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
            "params": "{\"or:\":[{\"status\": \"archived\"},{\"status\": \"disapproved\"}]}"
        },
        {
            "id": "14e8e930-2946-11eb-a404-b5701db75156",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "14e8e931-2946-11eb-a404-b5701db751567",
                    "children": [
                        {
                            "id": "15010513-2946-11eb-a404-b5701db75156",
                            "type": "container",
                            "children": [
                                {
                                    "id": "15010515-2946-11eb-a404-b5701db75156",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "text",
                                    "label": "Text",
                                    "dataField": "text"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "15010516-2946-11eb-a404-b5701db75156",
                            "type": "container",
                            "children": [
                                {
                                    "id": "15010518-2946-11eb-a404-b5701db75156",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "noticeStart",
                                    "label": "NoticeStart",
                                    "dataField": "noticeStart",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "15010519-2946-11eb-a404-b5701db75156",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1501051b-2946-11eb-a404-b5701db75156",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "noticeEnd",
                                    "label": "NoticeEnd",
                                    "dataField": "noticeEnd",
                                    "format": ""
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "1501051f-2946-11eb-a404-b5701db75156",
                            "type": "container",
                            "children": [
                                {
                                    "id": "15010521-2946-11eb-a404-b5701db75156",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "status_.label",
                                    "label": "Status",
                                    "dataField": "status"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "15010522-2946-11eb-a404-b5701db75156",
                            "type": "container",
                            "children": [
                                {
                                    "id": "15010524-2946-11eb-a404-b5701db75156",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner",
                                    "saveObject": true,
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "notification-archive-page",
            "route": "/notifications/archive/:id",
            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
            "access": "noone"
        },
        {
            "id": "150c9dd0-2946-11eb-a404-b5701db75156",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "14e8e931-2946-11eb-a404-b5701db75157",
                    "children": [
                        {
                            "id": "151884b0-2946-11eb-a404-b5701db75156",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                        },
                        {
                            "id": "15215e50-2946-11eb-a404-b5701db75156",
                            "type": "table",
                            "children": [
                                {
                                    "id": "152aad20-2946-11eb-a404-b5701db75156",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "label": "Date Created",
                                    "dataField": "",
                                    "date": true
                                },
                                {
                                    "id": "152ad431-2946-11eb-a404-b5701db75156",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "",
                                    "label": "Sent To",
                                    "dataField": "homeowner"
                                },
                                {
                                    "id": "152ad437-2946-11eb-a404-b5701db75156",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "text",
                                    "label": "Content",
                                    "dataField": "text"
                                },
                                {
                                    "id": "152ad433-2946-11eb-a404-b5701db75156",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "noticeStart",
                                    "label": "NoticeStart",
                                    "dataField": "noticeStart",
                                    "date": true
                                },
                                {
                                    "id": "152ad435-2946-11eb-a404-b5701db75156",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "noticeEnd",
                                    "label": "NoticeEnd",
                                    "dataField": "noticeEnd",
                                    "date": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/notifications/archive"
                        }
                    ],
                    "component": "ArchivedNotifications"
                }
            ],
            "orientation": "vertical",
            "name": "notification-archive-list",
            "route": "/notifications-list/archive",
            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721"
        },
        {
            "id": "bcd0c6c0-2e5c-11eb-95e9-b3974aa0f0c3",
            "type": "page",
            "children": [
                {
                    "id": "f24f6310-2e5c-11eb-95e9-b3974aa0f0c3",
                    "type": "container",
                    "children": [
                        {
                            "id": "f6cc86c0-2e5c-11eb-95e9-b3974aa0f0c3",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered my-1",
                            "text": "Billings History"
                        },
                        {
                            "id": "0c56bf60-2e5d-11eb-95e9-b3974aa0f0c3",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0eac4820-2e5d-11eb-95e9-b3974aa0f0c3",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "1fc2fa00-2e5d-11eb-95e9-b3974aa0f0c3",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Statement",
                                            "name": "name"
                                        },
                                        {
                                            "id": "369df590-2e5d-11eb-95e9-b3974aa0f0c3",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Posting Date",
                                            "name": "postingDate"
                                        },
                                        {
                                            "id": "457da330-2e5d-11eb-95e9-b3974aa0f0c3",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Due Date",
                                            "name": "dueDate"
                                        },
                                        {
                                            "id": "5824b910-2e5d-11eb-95e9-b3974aa0f0c3",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "total",
                                            "label": "Amount",
                                            "currency": true
                                        },
                                        {
                                            "id": "d57e5f00-8b9c-11eb-bcdf-a5161bfc24c5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "amount",
                                            "label": "Balance",
                                            "currency": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": "/account/homeowner-billings"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "main",
                    "component": "ArchivedBillings"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-history-billings",
            "route": "/account/billings/history"
        },
        {
            "id": "4a6b4a10-2eac-11eb-ba26-4746b2dda6eb",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "4a6b4a11-2eac-11eb-ba26-4746b2dda6eb",
                    "children": [
                        {
                            "id": "4a9407c0-2eac-11eb-ba26-4746b2dda6eb",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4a9407c2-2eac-11eb-ba26-4746b2dda6eb",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner",
                                    "saveObject": true,
                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4a9407c3-2eac-11eb-ba26-4746b2dda6eb",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4a9407c5-2eac-11eb-ba26-4746b2dda6eb",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "name",
                                    "label": "Statement",
                                    "dataField": "name"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4a9407c9-2eac-11eb-ba26-4746b2dda6eb",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4a9407cb-2eac-11eb-ba26-4746b2dda6eb",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "dueDate",
                                    "label": "Due Date",
                                    "dataField": "dueDate",
                                    "format": "mm/dd/yyyy"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4a9407cc-2eac-11eb-ba26-4746b2dda6eb",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4a9407ce-2eac-11eb-ba26-4746b2dda6eb",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "postingDate",
                                    "label": "Posting Date",
                                    "dataField": "postingDate",
                                    "format": "mm/dd/yyyy"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "46cc5bf0-2ead-11eb-ba26-4746b2dda6eb",
                            "type": "subView",
                            "children": [],
                            "view": "3acc2620-e865-11ea-9def-530e14f0f646",
                            "relation": "billing"
                        }
                    ],
                    "component": ""
                }
            ],
            "orientation": "vertical",
            "name": "billing-archive-page",
            "route": "/billings-archive/:id",
            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
            "access": "noone"
        },
        {
            "id": "4a9cba50-2eac-11eb-ba26-4746b2dda6ec",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "4a6b4a11-2eac-11eb-ba26-4746b2dda6ec",
                    "children": [
                        {
                            "id": "4aa34a00-2eac-11eb-ba26-4746b2dda6eb",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                        },
                        {
                            "id": "4aa8c840-2eac-11eb-ba26-4746b2dda6eb",
                            "type": "table",
                            "children": [
                                {
                                    "id": "4aadf860-2eac-11eb-ba26-4746b2dda6eb",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "homeowner",
                                    "label": "Homeowner",
                                    "dataField": "homeowner"
                                },
                                {
                                    "id": "4aadf862-2eac-11eb-ba26-4746b2dda6eb",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "name",
                                    "label": "Statement",
                                    "dataField": "name"
                                },
                                {
                                    "id": "4aadf866-2eac-11eb-ba26-4746b2dda6eb",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "dueDate",
                                    "label": "Due Date",
                                    "dataField": "dueDate",
                                    "date": true
                                },
                                {
                                    "id": "4aadf868-2eac-11eb-ba26-4746b2dda6eb",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "postingDate",
                                    "label": "Posting Date",
                                    "dataField": "postingDate",
                                    "date": true
                                },
                                {
                                    "id": "1e345c60-2ead-11eb-ba26-4746b2dda6eb",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Amount",
                                    "name": "amount",
                                    "currency": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table",
                            "path": "/billings-archive"
                        }
                    ],
                    "component": "ArchivedBillings"
                }
            ],
            "orientation": "vertical",
            "name": "billing-archive-list",
            "route": "/billings-list/archive",
            "dataModel": ""
        },
        {
            "id": "6cc2b230-3a6c-11eb-9608-13f79b46a41f",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "6cc2d940-3a6c-11eb-9608-13f79b46a41f",
                    "children": [
                        {
                            "id": "7211fd80-3a6d-11eb-9608-13f79b46a41f",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Pay with Paymaya",
                            "className": "my-2"
                        },
                        {
                            "id": "bdd82470-3a6c-11eb-9608-13f79b46a41f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c12d42e0-3a6c-11eb-9608-13f79b46a41f",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "mode",
                                    "className": "hide",
                                    "defaultValue": "Paymaya"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "cc918560-3a6c-11eb-9608-13f79b46a41f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cf4c1180-3a6c-11eb-9608-13f79b46a41f",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "amount",
                                    "label": "Amount",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "dd3db5a0-3a6c-11eb-9608-13f79b46a41f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e2bb3250-3a6c-11eb-9608-13f79b46a41f",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "particulars",
                                    "label": "Particulars",
                                    "multiline": true,
                                    "line": "8",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3ebde250-3a6d-11eb-9608-13f79b46a41f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "557af5a0-3a6d-11eb-9608-13f79b46a41f",
                                    "type": "button",
                                    "children": [],
                                    "name": "save",
                                    "command": "pay",
                                    "text": "Submit"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "component": "Paymaya"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-pay-dues-paymaya",
            "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3",
            "route": "/account/pay-dues/paymaya/:id"
        },
        {
            "id": "a83bf500-3a6d-11eb-9608-13f79b46a41f",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "6cc2d940-3a6c-11eb-9608-13f79b46a42f",
                    "children": [
                        {
                            "id": "bee50b20-3a6d-11eb-9608-13f79b46a41f",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Pay with Credit Card",
                            "className": "my-2"
                        },
                        {
                            "id": "d8f65280-3a6d-11eb-9608-13f79b46a41f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "dcdf4c80-3a6d-11eb-9608-13f79b46a41f",
                                    "type": "inputText",
                                    "children": [],
                                    "defaultValue": "Credit Card",
                                    "className": "hide",
                                    "dataField": "mode"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e8ab2a70-3a6d-11eb-9608-13f79b46a41f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "eaf2a970-3a6d-11eb-9608-13f79b46a41f",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Amount",
                                    "dataField": "amount",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "0566f860-3a6e-11eb-9608-13f79b46a41f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "08174b50-3a6e-11eb-9608-13f79b46a41f",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "line": "8",
                                    "label": "Particulars",
                                    "required": true,
                                    "dataField": "particulars"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "2113a040-3a6e-11eb-9608-13f79b46a41f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "2207c440-3a6e-11eb-9608-13f79b46a41f",
                                    "type": "button",
                                    "children": [],
                                    "name": "save",
                                    "path": "",
                                    "text": "Submit",
                                    "command": "pay"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "component": "Paymaya"
                }
            ],
            "orientation": "vertical",
            "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3",
            "route": "/account/pay-dues/credit-card/:id",
            "name": "homeowner-pay-dues-credit-card"
        },
        {
            "id": "cee2ae10-3a7c-11eb-81e5-7178adbe2b5b",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "cee2ae11-3a7c-11eb-81e5-7178adbe2b5b",
                    "children": [
                        {
                            "id": "d900b4f0-3a7c-11eb-81e5-7178adbe2b5b",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Payment Success"
                        },
                        {
                            "id": "35b06dd0-3a7d-11eb-81e5-7178adbe2b5b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3730f350-3a7d-11eb-81e5-7178adbe2b5b",
                                    "type": "textBlock",
                                    "children": [],
                                    "text": "Your online payment was successful."
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "py-1"
                        },
                        {
                            "id": "4dae0580-3a7f-11eb-b43b-35557e2f71d4",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4f08b380-3a7f-11eb-b43b-35557e2f71d4",
                                    "type": "button",
                                    "children": [],
                                    "className": "mt-2",
                                    "variant": "text",
                                    "text": "Done",
                                    "path": "/app"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-pay-dues-success",
            "route": "/account/pay-dues/success"
        },
        {
            "id": "71570230-3a7e-11eb-95a7-0f83460415e3",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "71572940-3a7e-11eb-95a7-0f83460415e3",
                    "children": [
                        {
                            "id": "7aa9c9d0-3a7e-11eb-95a7-0f83460415e3",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Payment Failed"
                        },
                        {
                            "id": "80682010-3a7e-11eb-95a7-0f83460415e3",
                            "type": "container",
                            "children": [
                                {
                                    "id": "8f2eee80-3a7e-11eb-95a7-0f83460415e3",
                                    "type": "textBlock",
                                    "children": [],
                                    "text": "Your online payment was not successful. You may try again later!"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "06547cf0-3a7f-11eb-b43b-35557e2f71d4",
                            "type": "container",
                            "children": [
                                {
                                    "id": "078cd5e0-3a7f-11eb-b43b-35557e2f71d4",
                                    "type": "button",
                                    "children": [],
                                    "text": "other payment method",
                                    "variant": "text",
                                    "path": "/account/pay-dues"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "mt-2"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-pay-dues-failed",
            "route": "/account/pay-dues/failed"
        },
        {
            "id": "17971730-4019-11eb-8b08-a5590ea41304",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "17971731-4019-11eb-8b08-a5590ea41304",
                    "component": "ArchivedNotifications",
                    "children": [
                        {
                            "id": "84e6f260-4019-11eb-8b08-a5590ea41304",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "className": "has-text-centered pb-2",
                            "text": "Archived Notifications"
                        },
                        {
                            "id": "955c8240-4019-11eb-8b08-a5590ea41304",
                            "type": "table",
                            "children": [
                                {
                                    "id": "96e1e9c0-4019-11eb-8b08-a5590ea41304",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "createdAt",
                                    "date": true,
                                    "label": "Date Created",
                                    "format": "MM/dd/yyyy hh:mm a",
                                    "width": "20"
                                },
                                {
                                    "id": "9f1585c0-4019-11eb-8b08-a5590ea41304",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "text",
                                    "label": "Content",
                                    "dataField": "text"
                                }
                            ],
                            "orientation": "horizontal",
                            "path": "#"
                        }
                    ],
                    "orientation": "vertical"
                }
            ],
            "orientation": "vertical",
            "name": "homeowner-notifications-archived",
            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
            "route": "/notifications-archived"
        }
    ],
    "server": "pacificland.bitesizedigital.net",
    "undoLength": 2,
    "name": "pacific land - midpoint",
    "_state": {},
    "activeApp": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
    "_locked": false,
    "updateKey": "df4b0c30-514a-11ec-a405-e730872e30ee",
    "": {
        "id": "5f38f1a9d3b9d51b787af797",
        "type": "project",
        "routes": [
            {
                "page": "billing-edit-page",
                "path": "/billings/:id",
                "requireAuth": false,
                "node": {
                    "_id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                    "type": "page"
                }
            },
            {
                "page": "billing-item-detail-page",
                "path": "/billing-items/:id",
                "requireAuth": false,
                "node": {
                    "_id": "3ab60610-e865-11ea-9def-530e14f0f646",
                    "type": "page"
                }
            },
            {
                "page": "billing-list-page",
                "path": "/billings-list",
                "requireAuth": false,
                "node": {
                    "_id": "7aac26c0-9138-11ea-bd78-399a56c40916",
                    "type": "page"
                }
            },
            {
                "page": "comments-suggestions-edit-page",
                "path": "/comments-suggestions/:id",
                "requireAuth": true,
                "node": {
                    "_id": "77a819a0-dd57-11ea-9ac6-91ffe70e2d7c",
                    "type": "page"
                }
            },
            {
                "page": "comments-suggestions-list-page",
                "path": "/comments-suggestions-list",
                "requireAuth": true,
                "node": {
                    "_id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                    "type": "page"
                }
            },
            {
                "page": "dashboard-login",
                "path": "/dashboard-login",
                "layout": "full",
                "requireAuth": false,
                "node": {
                    "_id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
                    "type": "page"
                }
            },
            {
                "page": "department-detail-page",
                "path": "/departments/:id",
                "requireAuth": false,
                "node": {
                    "_id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                    "type": "page"
                }
            },
            {
                "page": "department-list-page",
                "path": "/departments-list",
                "requireAuth": false,
                "node": {
                    "_id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                    "type": "page"
                }
            },
            {
                "page": "employee-detail-page",
                "path": "/employees/:id",
                "requireAuth": false,
                "node": {
                    "_id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                    "type": "page"
                }
            },
            {
                "page": "employee-list-page",
                "path": "/employees-list",
                "requireAuth": false,
                "node": {
                    "_id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-account",
                "path": "/account",
                "requireAuth": false,
                "node": {
                    "_id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-billing",
                "path": "/account/billings",
                "requireAuth": false,
                "node": {
                    "_id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-comments-suggestions",
                "path": "/homeowner-comments-suggestions/:id",
                "requireAuth": false,
                "node": {
                    "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b2",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-complaint",
                "path": "/file-complaint/:id",
                "requireAuth": false,
                "node": {
                    "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-complaints",
                "path": "/filed-complaints",
                "requireAuth": false,
                "node": {
                    "_id": "47440520-971c-11ea-8a71-fd00fe88963d",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-delivery-pass",
                "path": "/create-delivery-pass/:id",
                "requireAuth": false,
                "node": {
                    "_id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-delivery-passes",
                "path": "/delivery-passes",
                "requireAuth": false,
                "node": {
                    "_id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-detail-page",
                "path": "/homeowners/:id",
                "requireAuth": false,
                "node": {
                    "_id": "91150f60-ef9a-11ea-9d3f-8def54ed8352",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-list-page",
                "path": "/homeowners-list",
                "requireAuth": false,
                "node": {
                    "_id": "913e9060-ef9a-11ea-9d3f-8def54ed8352",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-login-page",
                "path": "/homeowners-login",
                "layout": "full",
                "requireAuth": false,
                "node": {
                    "_id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-marketplace",
                "path": "/market-place",
                "requireAuth": false,
                "node": {
                    "_id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-message-board",
                "path": "/message-board",
                "requireAuth": false,
                "node": {
                    "_id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-new-post",
                "path": "/message-board/:id",
                "requireAuth": false,
                "node": {
                    "_id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-notifications",
                "path": "/notifications",
                "requireAuth": false,
                "node": {
                    "_id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-password",
                "path": "/account/change-password",
                "requireAuth": false,
                "node": {
                    "_id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-post",
                "path": "/message-board-post/:id",
                "requireAuth": false,
                "node": {
                    "_id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-profile",
                "path": "/account/profile/:id",
                "requireAuth": false,
                "node": {
                    "_id": "17466790-96d2-11ea-9995-9d94cc393546",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-service-request",
                "path": "/create-service-request/:id",
                "requireAuth": false,
                "node": {
                    "_id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-service-requests",
                "path": "/service-requests",
                "requireAuth": false,
                "node": {
                    "_id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-unit-request",
                "path": "/homeowner-unit-request/:id",
                "requireAuth": false,
                "node": {
                    "_id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-visitor-pass",
                "path": "/create-visitor-pass/:id",
                "requireAuth": false,
                "node": {
                    "_id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-visitor-passes",
                "path": "/visitor-passes",
                "requireAuth": false,
                "node": {
                    "_id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-welcome-page",
                "path": "/welcome",
                "requireAuth": true,
                "node": {
                    "_id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-work-permit",
                "path": "/homeowner-work-permit/:id",
                "requireAuth": false,
                "node": {
                    "_id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                    "type": "page"
                }
            },
            {
                "page": "message-board-edit-page",
                "path": "/message-boards/:id",
                "requireAuth": false,
                "node": {
                    "_id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
                    "type": "page"
                }
            },
            {
                "page": "message-board-list-page",
                "path": "/message-boards-list",
                "requireAuth": false,
                "node": {
                    "_id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
                    "type": "page"
                }
            },
            {
                "page": "notification-detail-page",
                "path": "/notifications/:id",
                "requireAuth": false,
                "node": {
                    "_id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                    "type": "page"
                }
            },
            {
                "page": "notification-list-page",
                "path": "/notifications-list",
                "requireAuth": false,
                "node": {
                    "_id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                    "type": "page"
                }
            },
            {
                "page": "pass-delivery-edit-page",
                "path": "/pass-deliveries/:id",
                "requireAuth": false,
                "node": {
                    "_id": "61855560-e82a-11ea-bffd-65b1c6e24159",
                    "type": "page"
                }
            },
            {
                "page": "pass-delivery-list-page",
                "path": "/pass-deliveries-list",
                "requireAuth": false,
                "node": {
                    "_id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
                    "type": "page"
                }
            },
            {
                "page": "pass-visitor-edit-page",
                "path": "/pass-visitors/:id",
                "requireAuth": false,
                "node": {
                    "_id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
                    "type": "page"
                }
            },
            {
                "page": "pass-visitor-list-page",
                "path": "/pass-visitors-list",
                "requireAuth": false,
                "node": {
                    "_id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
                    "type": "page"
                }
            },
            {
                "page": "payment-detail-page",
                "path": "/payments/:id",
                "requireAuth": false,
                "node": {
                    "_id": "50c11f60-ef74-11ea-8620-1757bce541ea",
                    "type": "page"
                }
            },
            {
                "page": "payment-item-detail-page",
                "path": "/payment-items/:id",
                "requireAuth": false,
                "node": {
                    "_id": "9273b1a0-ef71-11ea-ae43-d3d081a35642",
                    "type": "page"
                }
            },
            {
                "page": "payment-list-page",
                "path": "/payments-list",
                "requireAuth": false,
                "node": {
                    "_id": "50d606f0-ef74-11ea-8620-1757bce541eb",
                    "type": "page"
                }
            },
            {
                "page": "terms-conditions-detail-page",
                "path": "/terms-conditions/:id",
                "requireAuth": false,
                "node": {
                    "_id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
                    "type": "page"
                }
            },
            {
                "page": "terms-conditions-list-page",
                "path": "/terms-conditions-list",
                "requireAuth": false,
                "node": {
                    "_id": "5aabc600-e507-11ea-8276-65e120bbe853",
                    "type": "page"
                }
            },
            {
                "page": "unit-detail-page",
                "path": "/units/:id",
                "requireAuth": false,
                "node": {
                    "_id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                    "type": "page"
                }
            },
            {
                "page": "unit-list-page",
                "path": "/units-list",
                "requireAuth": false,
                "node": {
                    "_id": "0829e200-d84f-11ea-acd1-c1cd20d0424b",
                    "type": "page"
                }
            },
            {
                "page": "unit-request-edit-page",
                "path": "/unit-requests/:id",
                "requireAuth": false,
                "node": {
                    "_id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
                    "type": "page"
                }
            },
            {
                "page": "unit-request-list-page",
                "path": "/unit-requests-list",
                "requireAuth": false,
                "node": {
                    "_id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
                    "type": "page"
                }
            },
            {
                "page": "work-permit-edit-page",
                "path": "/work-permits/:id",
                "requireAuth": false,
                "node": {
                    "_id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
                    "type": "page"
                }
            },
            {
                "page": "work-permit-list-page",
                "path": "/work-permits-list",
                "requireAuth": false,
                "node": {
                    "_id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
                    "type": "page"
                }
            },
            {
                "page": "activityLog-detail-page",
                "path": "/activity-logs/:id",
                "requireAuth": false,
                "node": {
                    "_id": "fb9a9130-f0b1-11ea-99d0-d9f36ea6d179",
                    "type": "page"
                }
            },
            {
                "page": "activityLog-list-page",
                "path": "/activity-logs-list",
                "requireAuth": false,
                "node": {
                    "_id": "fba93730-f0b1-11ea-99d0-d9f36ea6d179",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-billing-detail-page",
                "path": "/account/homeowner-billings/:id",
                "requireAuth": false,
                "node": {
                    "_id": "ffb5d7c0-f6f1-11ea-b61a-f5b3d409e561",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-pay-dues",
                "path": "/account/pay-dues",
                "requireAuth": false,
                "node": {
                    "_id": "1237a590-f7a6-11ea-8efb-e99dbc19ac92",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-pay-dues-deposit",
                "path": "/account/pay-dues/deposit/:id",
                "requireAuth": false,
                "node": {
                    "_id": "b0779440-f7a6-11ea-8efb-e99dbc19ac92",
                    "type": "page"
                }
            },
            {
                "page": "login-portal-page",
                "path": "/login-portal",
                "layout": "full",
                "requireAuth": false,
                "node": {
                    "_id": "968362c0-fdf1-11ea-ba93-5145fd1d7baf",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-work-permits",
                "path": "/work-permits",
                "requireAuth": false,
                "node": {
                    "_id": "cc343590-0376-11eb-b9ea-3df39e640441",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-unit-requests",
                "path": "/unit-requests",
                "requireAuth": false,
                "node": {
                    "_id": "7d7c1ed0-0377-11eb-bfdc-3b5d643d4962",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-pay-dues-history",
                "path": "/account/pay-dues-history",
                "requireAuth": false,
                "node": {
                    "_id": "b6957d90-08e7-11eb-a5df-110eadfed9ed",
                    "type": "page"
                }
            },
            {
                "page": "forgot-password",
                "path": "/forgot-password",
                "layout": "full",
                "requireAuth": false,
                "node": {
                    "_id": "1e5949d0-0ffe-11eb-a75f-a764954330b6",
                    "type": "page"
                }
            },
            {
                "page": "reset-password",
                "path": "/reset-password",
                "layout": "full",
                "requireAuth": false,
                "node": {
                    "_id": "7589ccc0-1008-11eb-9f4b-55fb426750d7",
                    "type": "page"
                }
            },
            {
                "page": "pass-visitor-detail-page",
                "path": "/pass-visitors-list/detail/:id",
                "requireAuth": false,
                "node": {
                    "_id": "27c30fa0-1603-11eb-8555-975f97eacdba",
                    "type": "page"
                }
            },
            {
                "page": "pass-delivery-detail-page",
                "path": "/pass-deliveries-list/detail/:id",
                "requireAuth": false,
                "node": {
                    "_id": "fcc6c530-164d-11eb-bfd1-2d1eca45ef82",
                    "type": "page"
                }
            },
            {
                "page": "work-permit-detail-page",
                "path": "/work-permits/detail/:id",
                "requireAuth": false,
                "node": {
                    "_id": "8aa9f4a0-1679-11eb-83c3-eb7956ee1f3f",
                    "type": "page"
                }
            },
            {
                "page": "unit-request-detail-page",
                "path": "/unit-requests/detail/:id",
                "requireAuth": false,
                "node": {
                    "_id": "0ef575f0-167e-11eb-83c3-eb7956ee1f32",
                    "type": "page"
                }
            },
            {
                "page": "message-board-detail-page",
                "path": "/message-boards/detail/:id",
                "requireAuth": false,
                "node": {
                    "_id": "42414a60-1697-11eb-9686-795f0ea8c25b",
                    "type": "page"
                }
            },
            {
                "page": "comments-suggestions-detail-page",
                "path": "/comments-suggestions/detail/:id",
                "requireAuth": false,
                "node": {
                    "_id": "89516680-169a-11eb-a20e-bba0e48fa309",
                    "type": "page"
                }
            },
            {
                "page": "billing-list-detail-page",
                "requireAuth": false,
                "node": {
                    "_id": "f69b9500-169d-11eb-8c30-87365a215fb4",
                    "type": "page"
                }
            },
            {
                "page": "pass-visitor-archive-page",
                "path": "/pass-visitors/archive/:id",
                "requireAuth": false,
                "node": {
                    "_id": "c677f620-26ec-11eb-866d-ef520cc7ee92",
                    "type": "page"
                }
            },
            {
                "page": "pass-visitor-archive-list",
                "path": "/pass-visitors-list/archive",
                "requireAuth": false,
                "node": {
                    "_id": "c6aa77d0-26ec-11eb-866d-ef520cc7ee92",
                    "type": "page"
                }
            },
            {
                "page": "pass-delivery-archive-page",
                "path": "/pass-deliveries/archive/:id",
                "requireAuth": false,
                "node": {
                    "_id": "43514190-26ef-11eb-96bc-0f1c3895619b",
                    "type": "page"
                }
            },
            {
                "page": "pass-delivery-archive-list",
                "path": "/pass-deliveries-list/archive",
                "requireAuth": false,
                "node": {
                    "_id": "437607a0-26ef-11eb-96bc-0f1c3895619b",
                    "type": "page"
                }
            },
            {
                "page": "work-permit-archive-page",
                "path": "/work-permits/archive/:id",
                "requireAuth": false,
                "node": {
                    "_id": "5f9cfdb0-26f1-11eb-ac91-c74c3e25a4bd",
                    "type": "page"
                }
            },
            {
                "page": "work-permit-archive-list",
                "path": "/work-permits-list/archive",
                "requireAuth": false,
                "node": {
                    "_id": "5fbb8230-26f1-11eb-ac91-c74c3e25a4bd",
                    "type": "page"
                }
            },
            {
                "page": "unit-request-archive-page",
                "path": "/unit-requests/archive/:id",
                "requireAuth": false,
                "node": {
                    "_id": "cb6da100-26f3-11eb-ac91-c74c3e25a4bd",
                    "type": "page"
                }
            },
            {
                "page": "unit-request-archive-list",
                "path": "/unit-requests-list/archive",
                "requireAuth": false,
                "node": {
                    "_id": "cb8ce8d0-26f3-11eb-ac91-c74c3e25a4bd",
                    "type": "page"
                }
            },
            {
                "page": "message-board-archive-page",
                "path": "/message-boards/archive/:id",
                "requireAuth": false,
                "node": {
                    "_id": "4e7217d0-2702-11eb-ac91-c74c3e25a4bd",
                    "type": "page"
                }
            },
            {
                "page": "message-board-archive-list",
                "path": "/message-boards-list/archive",
                "requireAuth": false,
                "node": {
                    "_id": "4e9a8760-2702-11eb-ac91-c74c3e25a4bd",
                    "type": "page"
                }
            },
            {
                "page": "notification-archive-page",
                "path": "/notifications/archive/:id",
                "requireAuth": false,
                "node": {
                    "_id": "14e8e930-2946-11eb-a404-b5701db75156",
                    "type": "page"
                }
            },
            {
                "page": "notification-archive-list",
                "path": "/notifications-list/archive",
                "requireAuth": false,
                "node": {
                    "_id": "150c9dd0-2946-11eb-a404-b5701db75156",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-history-billings",
                "path": "/account/billings/history",
                "requireAuth": false,
                "node": {
                    "_id": "bcd0c6c0-2e5c-11eb-95e9-b3974aa0f0c3",
                    "type": "page"
                }
            },
            {
                "page": "billing-archive-page",
                "path": "/billings-archive/:id",
                "requireAuth": false,
                "node": {
                    "_id": "4a6b4a10-2eac-11eb-ba26-4746b2dda6eb",
                    "type": "page"
                }
            },
            {
                "page": "billing-archive-list",
                "path": "/billings-list/archive",
                "requireAuth": false,
                "node": {
                    "_id": "4a9cba50-2eac-11eb-ba26-4746b2dda6ec",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-pay-dues-paymaya",
                "path": "/account/pay-dues/paymaya/:id",
                "requireAuth": false,
                "node": {
                    "_id": "6cc2b230-3a6c-11eb-9608-13f79b46a41f",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-pay-dues-credit-card",
                "path": "/account/pay-dues/credit-card/:id",
                "requireAuth": false,
                "node": {
                    "_id": "a83bf500-3a6d-11eb-9608-13f79b46a41f",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-pay-dues-success",
                "path": "/account/pay-dues/success",
                "requireAuth": false,
                "node": {
                    "_id": "cee2ae10-3a7c-11eb-81e5-7178adbe2b5b",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-pay-dues-failed",
                "path": "/account/pay-dues/failed",
                "requireAuth": false,
                "node": {
                    "_id": "71570230-3a7e-11eb-95a7-0f83460415e3",
                    "type": "page"
                }
            },
            {
                "page": "homeowner-notifications-archived",
                "path": "/notifications-archived",
                "requireAuth": false,
                "node": {
                    "_id": "17971730-4019-11eb-8b08-a5590ea41304",
                    "type": "page"
                }
            }
        ],
        "children": [
            {
                "id": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
                "type": "app",
                "name": "dashboard",
                "sidebar": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                "active": true,
                "component": "",
                "_sortIndex": 0,
                "startPage": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf"
            },
            {
                "id": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                "type": "app",
                "name": "homeowners",
                "sidebar": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                "startPage": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                "active": false,
                "_sortIndex": 10
            },
            {
                "id": "2ad37a40-9138-11ea-bd78-399a56c40918",
                "type": "model",
                "children": [
                    {
                        "id": "35bfcda0-9138-11ea-bd78-399a56c40918",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                        "name": "homeowner",
                        "_sortIndex": -1
                    },
                    {
                        "id": "3040a340-9138-11ea-bd78-399a56c40918",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "name",
                        "_sortIndex": 0
                    },
                    {
                        "id": "6fa1c410-9138-11ea-bd78-399a56c40918",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "details"
                    },
                    {
                        "id": "298e0e40-e9a6-11ea-bbba-5944fe032799",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "dueDate"
                    },
                    {
                        "id": "388318a0-e9a6-11ea-bbba-5944fe032799",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "postingDate"
                    }
                ],
                "name": "billing",
                "_sortIndex": 20
            },
            {
                "id": "960f7330-e864-11ea-9def-530e14f0f646",
                "type": "model",
                "children": [
                    {
                        "id": "efc17c70-e864-11ea-9def-530e14f0f646",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                        "name": "billing",
                        "_sortIndex": -1
                    },
                    {
                        "id": "b016f690-e864-11ea-9def-530e14f0f646",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "item",
                        "_sortIndex": 0
                    },
                    {
                        "id": "b43e8030-e864-11ea-9def-530e14f0f646",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "description",
                        "_sortIndex": 10
                    },
                    {
                        "id": "b81a5ad0-e864-11ea-9def-530e14f0f646",
                        "type": "field:number",
                        "children": [],
                        "dataType": "number",
                        "name": "amount",
                        "_sortIndex": 20
                    },
                    {
                        "id": "bec0ee30-e864-11ea-9def-530e14f0f646",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "state",
                        "_sortIndex": 30
                    }
                ],
                "name": "billing-item"
            },
            {
                "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                "type": "model",
                "children": [
                    {
                        "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:enumeration",
                        "children": [],
                        "dataType": "enumeration",
                        "name": "type"
                    },
                    {
                        "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "details"
                    },
                    {
                        "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:file",
                        "children": [],
                        "dataType": "file",
                        "collection": "file",
                        "via": "related",
                        "allowedTypes": [
                            "images",
                            "files",
                            "videos"
                        ],
                        "plugin": "upload",
                        "name": "photos"
                    },
                    {
                        "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                        "name": "homeowner"
                    },
                    {
                        "id": "db5ce460-edd0-11ea-993f-a977bcf36a2d",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "notes"
                    },
                    {
                        "id": "42890350-05e4-11eb-8eea-bf73dbff533a",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "type_"
                    }
                ],
                "name": "comments-suggestions",
                "_sortIndex": 30
            },
            {
                "id": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                "type": "model",
                "children": [
                    {
                        "id": "1ba0dd50-8ce8-11ea-a283-ebcb859bf8c8",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "name",
                        "required": true,
                        "searchable": true
                    },
                    {
                        "id": "22702e60-8ce8-11ea-a283-ebcb859bf8c8",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "description",
                        "required": true
                    }
                ],
                "name": "department",
                "active": false,
                "_sortIndex": 50
            },
            {
                "id": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                "type": "model",
                "children": [
                    {
                        "id": "7288d810-8c4e-11ea-9466-6f383d5eb483",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "employeeid",
                        "required": true,
                        "searchable": true,
                        "_sortIndex": 0
                    },
                    {
                        "id": "49b35e80-9129-11ea-a5e9-ff76f0b9af84",
                        "type": "field:email",
                        "children": [],
                        "dataType": "email",
                        "name": "email",
                        "required": true,
                        "_sortIndex": 9
                    },
                    {
                        "id": "780a4c60-8c4e-11ea-9466-6f383d5eb483",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "firstName",
                        "required": true,
                        "searchable": true,
                        "_sortIndex": 10
                    },
                    {
                        "id": "837ebb80-8c4e-11ea-9466-6f383d5eb483",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "lastName",
                        "required": true,
                        "searchable": true,
                        "_sortIndex": 20
                    },
                    {
                        "id": "e68f77d0-8c91-11ea-bb0b-0b00cf6ac98c",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "birthday",
                        "required": true,
                        "_sortIndex": 30
                    },
                    {
                        "id": "5979e880-8e9e-11ea-9c0f-c7e69d58f37d",
                        "type": "field:file",
                        "children": [],
                        "dataType": "file",
                        "collection": "file",
                        "via": "related",
                        "allowedTypes": [
                            "images",
                            "files",
                            "videos"
                        ],
                        "plugin": "upload",
                        "name": "picture",
                        "_sortIndex": 50
                    },
                    {
                        "id": "e7c20f60-14d0-11eb-a8c0-03c9560d42e7",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "role"
                    },
                    {
                        "id": "20b8cab0-15fe-11eb-bc4b-619622358460",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "role_"
                    }
                ],
                "name": "employee",
                "active": false,
                "_sortIndex": 60
            },
            {
                "id": "ed8defd0-911e-11ea-9e19-2d2007118721",
                "type": "model",
                "children": [
                    {
                        "id": "06bec240-911f-11ea-9e19-2d2007118721",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "accountNumber",
                        "_sortIndex": -1
                    },
                    {
                        "id": "3f18fbb0-9129-11ea-a5e9-ff76f0b9af84",
                        "type": "field:email",
                        "children": [],
                        "dataType": "email",
                        "name": "email",
                        "required": true,
                        "_sortIndex": 0
                    },
                    {
                        "id": "f67bdb20-911e-11ea-9e19-2d2007118721",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "name",
                        "required": true,
                        "_sortIndex": 20
                    },
                    {
                        "id": "138b4750-911f-11ea-9e19-2d2007118721",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "contact",
                        "_sortIndex": 70
                    },
                    {
                        "id": "b2c46990-e9f4-11ea-9892-473a233000d1",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "nickname"
                    },
                    {
                        "id": "f64a0d20-e9fc-11ea-bf3b-ef4612b5d21e",
                        "type": "field:file",
                        "children": [],
                        "dataType": "file",
                        "collection": "file",
                        "via": "related",
                        "allowedTypes": [
                            "images",
                            "files",
                            "videos"
                        ],
                        "plugin": "upload",
                        "name": "photo"
                    },
                    {
                        "id": "f1f4a130-ef98-11ea-9d3f-8def54ed8352",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "parkingUnitNumber"
                    },
                    {
                        "id": "9fe96300-eff5-11ea-ad56-959d088b5979",
                        "type": "field:boolean",
                        "children": [],
                        "dataType": "boolean",
                        "name": "noticeShown"
                    }
                ],
                "name": "homeowner",
                "_sortIndex": 70
            },
            {
                "id": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                "type": "model",
                "children": [
                    {
                        "id": "b3aa23e0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                        "name": "homeowner"
                    },
                    {
                        "id": "c20c3d10-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:enumeration",
                        "children": [],
                        "dataType": "enumeration",
                        "name": "topic"
                    },
                    {
                        "id": "d7f25150-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "title"
                    },
                    {
                        "id": "dd8b6c50-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "price"
                    },
                    {
                        "id": "e1c656e0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "description"
                    },
                    {
                        "id": "eb73c1a0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "contactNumber"
                    },
                    {
                        "id": "f1279090-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:file",
                        "children": [],
                        "dataType": "file",
                        "collection": "file",
                        "via": "related",
                        "allowedTypes": [
                            "images",
                            "files",
                            "videos"
                        ],
                        "plugin": "upload",
                        "name": "photos"
                    },
                    {
                        "id": "ff6cabe0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "status"
                    },
                    {
                        "id": "2cdc7120-db5c-11ea-968c-6f86147884ca",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "notes"
                    },
                    {
                        "id": "42925310-f0a4-11ea-a584-176526b5be96",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "datePosted"
                    },
                    {
                        "id": "68bbe9c0-05e4-11eb-8eea-bf73dbff533a",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "topic_"
                    },
                    {
                        "id": "3c87b120-1f81-11eb-96ab-9ff3802db7dd",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "status_"
                    }
                ],
                "_sortIndex": 80,
                "name": "message-board"
            },
            {
                "id": "77e05b60-911e-11ea-9e19-2d2007118721",
                "type": "model",
                "children": [
                    {
                        "id": "7bedcd50-911e-11ea-9e19-2d2007118721",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "name",
                        "required": true
                    },
                    {
                        "id": "8093aeb0-911e-11ea-9e19-2d2007118721",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "text",
                        "required": true
                    },
                    {
                        "id": "88181ef0-911e-11ea-9e19-2d2007118721",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "noticeStart",
                        "required": true
                    },
                    {
                        "id": "97459e70-911e-11ea-9e19-2d2007118721",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "noticeEnd"
                    },
                    {
                        "id": "9eb945d0-911e-11ea-9e19-2d2007118721",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "source"
                    },
                    {
                        "id": "a096b760-9129-11ea-a5e9-ff76f0b9af84",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "status"
                    },
                    {
                        "id": "46f8d4c0-08fe-11eb-ad54-a7f1ee827191",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "name": "homeowner",
                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                    }
                ],
                "name": "notification",
                "_sortIndex": 90
            },
            {
                "id": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                "type": "model",
                "children": [
                    {
                        "id": "c4081f00-d9c6-11ea-93cf-3dd7e562aac3",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                        "name": "unit"
                    },
                    {
                        "id": "d7708910-d9c6-11ea-93cf-3dd7e562aac3",
                        "type": "field:enumeration",
                        "children": [],
                        "dataType": "enumeration",
                        "name": "type"
                    },
                    {
                        "id": "e5db54d0-d9c6-11ea-93cf-3dd7e562aac3",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "schedule"
                    },
                    {
                        "id": "ee4c9610-d9c6-11ea-93cf-3dd7e562aac3",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "items"
                    },
                    {
                        "id": "f2ed6e60-d9c6-11ea-93cf-3dd7e562aac3",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "company"
                    },
                    {
                        "id": "00b0fda0-d9c7-11ea-93cf-3dd7e562aac3",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "status"
                    },
                    {
                        "id": "5823d2a0-e828-11ea-99d9-7b0e0405473d",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "notes"
                    },
                    {
                        "id": "fe2aaed0-05e2-11eb-8eea-bf73dbff533a",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "type_"
                    },
                    {
                        "id": "24bbf460-260d-11eb-bab3-998bf6bd89df",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "status_"
                    }
                ],
                "name": "pass-delivery",
                "_sortIndex": 100
            },
            {
                "id": "a5454c50-911e-11ea-9e19-2d2007118721",
                "type": "model",
                "children": [
                    {
                        "id": "c7d2c620-d9c7-11ea-93cf-3dd7e562aac3",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                        "name": "unit"
                    },
                    {
                        "id": "cfdbf6c0-d9c7-11ea-93cf-3dd7e562aac3",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "visitor",
                        "required": true
                    },
                    {
                        "id": "fa4d2320-d9c7-11ea-93cf-3dd7e562aac3",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "startDate"
                    },
                    {
                        "id": "ff0ec9e0-d9c7-11ea-93cf-3dd7e562aac3",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "endDate"
                    },
                    {
                        "id": "06a73750-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "field:file",
                        "children": [],
                        "dataType": "file",
                        "collection": "file",
                        "via": "related",
                        "allowedTypes": [
                            "images",
                            "files",
                            "videos"
                        ],
                        "plugin": "upload",
                        "name": "visitorsId"
                    },
                    {
                        "id": "7b087c60-da1a-11ea-b50d-8323e621ee63",
                        "type": "field:enumeration",
                        "children": [],
                        "dataType": "enumeration",
                        "name": "type"
                    },
                    {
                        "id": "f23b46a0-f0d6-11ea-9422-3905f8832c96",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "status"
                    },
                    {
                        "id": "6b951970-f796-11ea-8826-7fd8e10f31a2",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "notes"
                    },
                    {
                        "id": "d69fd980-05e2-11eb-8eea-bf73dbff533a",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "type_"
                    },
                    {
                        "id": "38e47a20-260d-11eb-bab3-998bf6bd89df",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "status_"
                    }
                ],
                "name": "pass-visitor",
                "_sortIndex": 109
            },
            {
                "id": "e682f860-ef70-11ea-983b-e7b329586acc",
                "type": "model",
                "children": [
                    {
                        "id": "f6493570-ef70-11ea-983b-e7b329586acc",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "name": "homeowner",
                        "_sortIndex": -1,
                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                    },
                    {
                        "id": "eb4c4040-ef70-11ea-983b-e7b329586acc",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "receipt",
                        "_sortIndex": 0
                    },
                    {
                        "id": "0a0034b0-ef71-11ea-983b-e7b329586acc",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "postingDate"
                    },
                    {
                        "id": "b6c349d0-f65b-11ea-bcea-e53105b58e3f",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "statement"
                    },
                    {
                        "id": "c8fd9420-f65b-11ea-bcea-e53105b58e3f",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "paymentDate"
                    }
                ],
                "name": "payment"
            },
            {
                "id": "24ee6e90-ef71-11ea-ae43-d3d081a35642",
                "type": "model",
                "children": [
                    {
                        "id": "4aae6ef0-ef71-11ea-ae43-d3d081a35642",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "name": "payment",
                        "dataModel": "555659e0-9138-11ea-bd78-399a56c40918",
                        "_sortIndex": -1
                    },
                    {
                        "id": "2aa37600-ef71-11ea-ae43-d3d081a35642",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "item",
                        "_sortIndex": 0
                    },
                    {
                        "id": "3de1e9e0-ef71-11ea-ae43-d3d081a35642",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "description",
                        "_sortIndex": 10
                    },
                    {
                        "id": "420ea3a0-ef71-11ea-ae43-d3d081a35642",
                        "type": "field:number",
                        "children": [],
                        "dataType": "number",
                        "name": "amount",
                        "_sortIndex": 20
                    },
                    {
                        "id": "46bbb0f0-ef71-11ea-ae43-d3d081a35642",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "state",
                        "_sortIndex": 30
                    }
                ],
                "name": "payment-item"
            },
            {
                "id": "faa20cb0-e506-11ea-8276-65e120bbe853",
                "type": "model",
                "children": [
                    {
                        "id": "fe1b06d0-e506-11ea-8276-65e120bbe853",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "name"
                    },
                    {
                        "id": "0704fa80-e507-11ea-8276-65e120bbe853",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "content"
                    }
                ],
                "name": "terms-conditions"
            },
            {
                "id": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                "type": "model",
                "children": [
                    {
                        "id": "a86f3310-d84e-11ea-acd1-c1cd20d0424a",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "number"
                    },
                    {
                        "id": "bc50ebd0-d84e-11ea-acd1-c1cd20d0424a",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "type",
                        "component": ""
                    },
                    {
                        "id": "73fa85c0-edd0-11ea-993f-a977bcf36a2d",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "areaSize"
                    },
                    {
                        "id": "817fd380-edd0-11ea-993f-a977bcf36a2d",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "classification"
                    },
                    {
                        "id": "8bc3d8f0-edd0-11ea-993f-a977bcf36a2d",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "projectCode"
                    },
                    {
                        "id": "944805f0-edd0-11ea-993f-a977bcf36a2d",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "customerCode"
                    },
                    {
                        "id": "99e36ae0-edd0-11ea-993f-a977bcf36a2d",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "turnoverDate"
                    },
                    {
                        "id": "a14a4100-edd0-11ea-993f-a977bcf36a2d",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "name": "homeowner",
                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                    },
                    {
                        "id": "53405060-ef99-11ea-9d3f-8def54ed8352",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "greaseTrapCleaning"
                    },
                    {
                        "id": "ab1423c0-ef99-11ea-9d3f-8def54ed8352",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "airconCleaning"
                    }
                ],
                "name": "unit",
                "_sortIndex": 130
            },
            {
                "id": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                "type": "model",
                "children": [
                    {
                        "id": "ffb19150-d84d-11ea-acd1-c1cd20d0424a",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "type"
                    },
                    {
                        "id": "09a553e0-d84e-11ea-acd1-c1cd20d0424a",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "status"
                    },
                    {
                        "id": "125e2570-d84e-11ea-acd1-c1cd20d0424a",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                        "name": "unit"
                    },
                    {
                        "id": "47a03900-e828-11ea-99d9-7b0e0405473d",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "component": "",
                        "name": "notes"
                    },
                    {
                        "id": "13c40d30-05e4-11eb-8eea-bf73dbff533a",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "type_"
                    },
                    {
                        "id": "53f29810-260d-11eb-bab3-998bf6bd89df",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "status_"
                    }
                ],
                "name": "unit-request",
                "_sortIndex": 140
            },
            {
                "id": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                "type": "model",
                "children": [
                    {
                        "id": "59d52f00-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:relation",
                        "children": [],
                        "dataType": "relation",
                        "name": "unit",
                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                    },
                    {
                        "id": "5fa7a980-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:enumeration",
                        "children": [],
                        "dataType": "enumeration",
                        "name": "natureOfWork"
                    },
                    {
                        "id": "680a92e0-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "contractor"
                    },
                    {
                        "id": "6cc22780-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "personInCharge"
                    },
                    {
                        "id": "77ba88d0-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "contactPersonInCharge"
                    },
                    {
                        "id": "82778ed0-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "workers"
                    },
                    {
                        "id": "87d65ff0-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "description"
                    },
                    {
                        "id": "951e5690-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "materials"
                    },
                    {
                        "id": "9a41cc60-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "tools"
                    },
                    {
                        "id": "a0da7d10-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "startDate"
                    },
                    {
                        "id": "a5a376d0-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:date",
                        "children": [],
                        "dataType": "date",
                        "format": "mm/dd/yyyy",
                        "name": "endDate"
                    },
                    {
                        "id": "abf13bd0-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:file",
                        "children": [],
                        "dataType": "file",
                        "collection": "file",
                        "via": "related",
                        "allowedTypes": [
                            "images",
                            "files",
                            "videos"
                        ],
                        "plugin": "upload",
                        "name": "plans"
                    },
                    {
                        "id": "afc85b80-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "status"
                    },
                    {
                        "id": "339637c0-e828-11ea-99d9-7b0e0405473d",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "notes"
                    },
                    {
                        "id": "8e04b3c0-05e3-11eb-8eea-bf73dbff533a",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "natureOfWork_"
                    },
                    {
                        "id": "95f0d1f0-260d-11eb-bab3-998bf6bd89df",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "status_"
                    }
                ],
                "name": "work-permit",
                "_sortIndex": 150
            },
            {
                "id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                        "children": [
                            {
                                "id": "2936a240-f0b3-11ea-99d0-d9f36ea6d179",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Billing",
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            },
                            {
                                "id": "7aa7e100-9138-11ea-bd78-399a56c40918",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "7aa7e102-9138-11ea-bd78-399a56c40918",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner",
                                        "saveObject": true,
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "7aa7e103-9138-11ea-bd78-399a56c40918",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "7aa7e105-9138-11ea-bd78-399a56c40918",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "name",
                                        "label": "Statement",
                                        "dataField": "name"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            },
                            {
                                "id": "016566c0-e9b0-11ea-8b13-df1e5e645c34",
                                "type": "inputDate",
                                "children": [],
                                "name": "postingDate",
                                "dataField": "postingDate",
                                "label": "Posting Date",
                                "_sortIndex": 20
                            },
                            {
                                "id": "04a80ea0-e9b0-11ea-8b13-df1e5e645c34",
                                "type": "inputDate",
                                "children": [],
                                "name": "dueDate",
                                "dataField": "dueDate",
                                "label": "Due Date",
                                "_sortIndex": 30
                            },
                            {
                                "id": "9d8d05d0-efdc-11ea-b8ac-c3abdef265b0",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "component": "BillingUpload",
                                "_sortIndex": 40
                            }
                        ],
                        "_sortIndex": 0,
                        "component": "BillingUpload"
                    },
                    {
                        "id": "22b50110-e865-11ea-9def-530e14f0f646",
                        "type": "subView",
                        "children": [],
                        "_sortIndex": 10,
                        "view": "3acc2620-e865-11ea-9def-530e14f0f646",
                        "relation": "billing"
                    },
                    {
                        "id": "7aa39b40-9138-11ea-bd78-399a56c40918",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                        "_sortIndex": 20
                    }
                ],
                "orientation": "vertical",
                "name": "billing-edit-page",
                "route": "/billings/:id",
                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                "component": "",
                "_sortIndex": 160,
                "access": "accounting"
            },
            {
                "id": "3ab60610-e865-11ea-9def-530e14f0f646",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                        "children": [
                            {
                                "id": "1e47b6d0-f0b3-11ea-99d0-d9f36ea6d179",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Billing",
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            },
                            {
                                "id": "3ac76b30-e865-11ea-9def-530e14f0f646",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3ac76b32-e865-11ea-9def-530e14f0f646",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "billing",
                                        "label": "Billing",
                                        "dataField": "billing",
                                        "saveObject": true,
                                        "relation": "2ad37a40-9138-11ea-bd78-399a56c40918"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "3ac76b33-e865-11ea-9def-530e14f0f646",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3ac76b35-e865-11ea-9def-530e14f0f646",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "item",
                                        "label": "Item",
                                        "dataField": "item"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            },
                            {
                                "id": "3ac76b36-e865-11ea-9def-530e14f0f646",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3ac79240-e865-11ea-9def-530e14f0f646",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20
                            },
                            {
                                "id": "3ac79241-e865-11ea-9def-530e14f0f646",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3ac79243-e865-11ea-9def-530e14f0f646",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "amount",
                                        "label": "Amount",
                                        "dataField": "amount"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30
                            },
                            {
                                "id": "3ac79244-e865-11ea-9def-530e14f0f646",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3ac79246-e865-11ea-9def-530e14f0f646",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "state",
                                        "label": "State",
                                        "dataField": "state"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40
                            }
                        ],
                        "component": ""
                    },
                    {
                        "id": "3abf2dd0-e865-11ea-9def-530e14f0f646",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "billing-item-detail-page",
                "route": "/billing-items/:id",
                "dataModel": "960f7330-e864-11ea-9def-530e14f0f646",
                "access": "super-admin"
            },
            {
                "id": "7aac26c0-9138-11ea-bd78-399a56c40916",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "7aac26c0-9138-11ea-bd78-399a56c40917",
                        "children": [
                            {
                                "id": "c1bc9ce0-f658-11ea-bcea-e53105b58e3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "cc9fc880-f658-11ea-bcea-e53105b58e3f",
                                        "type": "button",
                                        "children": [],
                                        "command": "new_billing",
                                        "text": "upload"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "accounting"
                            },
                            {
                                "id": "520b7960-01ee-11eb-81b5-f15d97fd1729",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "575a6c00-01ee-11eb-81b5-f15d97fd1729",
                                        "type": "subView",
                                        "children": [],
                                        "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
                                        "component": "Searchbar",
                                        "name": "searchbar"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "7ab48b30-9138-11ea-bd78-399a56c40918",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "7ab94620-9138-11ea-bd78-399a56c40918",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner"
                                    },
                                    {
                                        "id": "7ab94622-9138-11ea-bd78-399a56c40918",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "name",
                                        "label": "Statement",
                                        "dataField": "name"
                                    },
                                    {
                                        "id": "91576770-f65c-11ea-bcea-e53105b58e3f",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "postingDate",
                                        "label": "Posting Date",
                                        "date": true
                                    },
                                    {
                                        "id": "a45046d0-f65c-11ea-bcea-e53105b58e3f",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "dueDate",
                                        "label": "Due Date",
                                        "date": true
                                    },
                                    {
                                        "id": "4f7ef760-091b-11eb-a3cc-3fd0e63e5f63",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Amount",
                                        "name": "amount",
                                        "currency": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "query": "{\"_limit\": \"100\"}"
                            }
                        ],
                        "component": "BillingUpload"
                    }
                ],
                "orientation": "vertical",
                "name": "billing-list-page",
                "route": "/billings-list",
                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                "_sortIndex": 170,
                "params": ""
            },
            {
                "id": "77a819a0-dd57-11ea-9ac6-91ffe70e2d7c",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                        "children": [
                            {
                                "id": "431dff00-f0a9-11ea-9db2-ab2487441b73",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered",
                                "text": "Comments and Suggestions",
                                "_sortIndex": 0
                            },
                            {
                                "id": "77b90990-dd57-11ea-9ac6-91ffe70e2d7c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "09eb4ef0-ea54-11ea-8de6-3f209e03f86c",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "typ",
                                        "label": "Type",
                                        "dataField": "type",
                                        "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"}, {\"label\": \"Security\", \"value\": \"security\"}, {\"label\": \"Safety\", \"value\": \"safety\"}, {\"label\": \"PMG Crew/ Team Conduct\", \"value\": \"team-conduct\"} ,{\"label\": \"Others\", \"value\": \"others\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            },
                            {
                                "id": "77b90993-dd57-11ea-9ac6-91ffe70e2d7c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "77b90995-dd57-11ea-9ac6-91ffe70e2d7c",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "details",
                                        "label": "Details",
                                        "dataField": "details",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20
                            },
                            {
                                "id": "77b90996-dd57-11ea-9ac6-91ffe70e2d7c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "77b90998-dd57-11ea-9ac6-91ffe70e2d7c",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "photos",
                                        "label": "Photos",
                                        "dataField": "photos",
                                        "basePath": "comments-suggestions"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30
                            },
                            {
                                "id": "77b90999-dd57-11ea-9ac6-91ffe70e2d7c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "77b9099b-dd57-11ea-9ac6-91ffe70e2d7c",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner",
                                        "saveObject": true,
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40
                            },
                            {
                                "id": "a71fdc40-f0a3-11ea-a584-176526b5be96",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b134e270-f0a3-11ea-a584-176526b5be96",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "notes",
                                        "label": "Notes",
                                        "multiline": true,
                                        "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 49
                            }
                        ]
                    },
                    {
                        "id": "77b5d540-dd57-11ea-9ac6-91ffe70e2d7c",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "comments-suggestions-edit-page",
                "route": "/comments-suggestions/:id",
                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                "_sortIndex": 180,
                "requireAuth": true,
                "access": "admin,pmg"
            },
            {
                "id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                        "children": [
                            {
                                "id": "77c369d0-dd57-11ea-9ac6-91ffe70e2d7c",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "77c824c0-dd57-11ea-9ac6-91ffe70e2d7c",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "5a733570-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "date": true,
                                        "format": "MM/dd/yyyy hh:mm a"
                                    },
                                    {
                                        "id": "633ccfe0-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "type",
                                        "label": "Type"
                                    },
                                    {
                                        "id": "697f7150-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "details",
                                        "label": "Details"
                                    },
                                    {
                                        "id": "70e86a50-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "homeowner",
                                        "label": "Homeowner"
                                    },
                                    {
                                        "id": "76c4a8d0-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/comments-suggestions/detail"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "comments-suggestions-list-page",
                "route": "/comments-suggestions-list",
                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                "_sortIndex": 190,
                "requireAuth": true,
                "params": "{\"_limit\": 100}"
            },
            {
                "id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "a4892271-dfa5-11ea-a94d-e5d6a6ca6c3b",
                        "children": [
                            {
                                "id": "b81715e0-dfa5-11ea-a94d-e5d6a6ca6c3b",
                                "type": "subView",
                                "children": [],
                                "view": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "dashboard-login",
                "route": "/dashboard-login",
                "component": "",
                "noPadding": false,
                "layout": "full"
            },
            {
                "id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                        "children": [
                            {
                                "id": "f6c93a60-f0ae-11ea-a61b-49eea0a1bb78",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Department",
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            },
                            {
                                "id": "99dbbf60-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "99dbbf62-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "name",
                                        "label": "Department",
                                        "dataField": "name"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "99dbbf63-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "99dbbf65-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description",
                                        "description": "",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            }
                        ],
                        "component": "ArchivedNotifications",
                        "access": "pmg"
                    },
                    {
                        "id": "99d8b220-912e-11ea-ad57-6526d978cdf6",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "department-detail-page",
                "route": "/departments/:id",
                "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                "_sortIndex": 200,
                "access": "admin"
            },
            {
                "id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                        "children": [
                            {
                                "id": "94311830-912e-11ea-ad57-6526d978cdf6",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "9433b040-912e-11ea-ad57-6526d978cdf6",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "94364850-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "name",
                                        "label": "Name",
                                        "dataField": "name"
                                    },
                                    {
                                        "id": "94364852-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "department-list-page",
                "route": "/departments-list",
                "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                "_sortIndex": 210
            },
            {
                "id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf3",
                        "children": [
                            {
                                "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Employee",
                                "className": "has-text-centered",
                                "_sortIndex": 0
                            },
                            {
                                "id": "a5a55360-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a5a55362-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "employeeid",
                                        "label": "Employee ID",
                                        "dataField": "employeeid"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a5a55363-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a5a55365-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "email",
                                        "label": "Email",
                                        "dataField": "email",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a5a55366-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a5a55368-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "firstName",
                                        "label": "First Name",
                                        "dataField": "firstName"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a5a55369-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a5a5536b-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "lastName",
                                        "label": "Last Name",
                                        "dataField": "lastName"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a5a55372-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a5a55374-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "picture",
                                        "label": "Photo",
                                        "dataField": "picture",
                                        "basePath": "employees"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "603f71b0-15fa-11eb-9b3a-dd4b5808ec5b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "632ccda0-15fa-11eb-9b3a-dd4b5808ec5b",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Role",
                                        "name": "role",
                                        "dataField": "role",
                                        "options": "[{\"value\": \"admin\",\"label\": \"Admin\"},{\"value\": \"accounting\",\"label\": \"Accounting\"},{\"value\": \"accounting-staff\",\"label\": \"Accounting Staff\"},{\"value\": \"pmg\",\"label\": \"PMG\"},{\"value\": \"pmg-ops\",\"label\": \"PMG-OPS\"},{\"value\": \"pmg-security\",\"label\": \"PMG-Security\"}]",
                                        "required": true,
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    },
                    {
                        "id": "a5a26d30-912e-11ea-ad57-6526d978cdf6",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "employee-detail-page",
                "route": "/employees/:id",
                "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                "_sortIndex": 220,
                "access": "admin"
            },
            {
                "id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                        "children": [
                            {
                                "id": "a5aa8380-912e-11ea-ad57-6526d978cdf6",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "a5ae05f0-912e-11ea-ad57-6526d978cdf6",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "a5b0c510-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "employeeid",
                                        "label": "Employee ID",
                                        "dataField": "employeeid"
                                    },
                                    {
                                        "id": "a5b0c512-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "email",
                                        "label": "Email",
                                        "dataField": "email"
                                    },
                                    {
                                        "id": "a5b0c514-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "firstName",
                                        "label": "First Name",
                                        "dataField": "firstName"
                                    },
                                    {
                                        "id": "a5b0c516-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "lastName",
                                        "label": "Last Name",
                                        "dataField": "lastName"
                                    },
                                    {
                                        "id": "a5b0c51a-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "",
                                        "label": "User Role",
                                        "dataField": "role"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "employee-list-page",
                "route": "/employees-list",
                "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                "_sortIndex": 230
            },
            {
                "id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "4840fc41-9149-11ea-a6b6-e7e287632ad5",
                        "children": [
                            {
                                "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "My Account",
                                "className": "has-text-centered mt-2",
                                "_sortIndex": 0
                            },
                            {
                                "id": "707a9a30-ea14-11ea-a21e-f569cc759e68",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "75cbd6c0-ea14-11ea-a21e-f569cc759e68",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "864c1500-ea14-11ea-a21e-f569cc759e68",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_profile.svg\"><img src=\"./assets/images/icon_profile.svg\" /></object>My Profile</div>",
                                                "path": "/account/profile/me"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "97a5ea60-ea14-11ea-a21e-f569cc759e68",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a301ef80-ea14-11ea-a21e-f569cc759e68",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_password.svg\"><img src=\"./assets/images/icon_password.svg\" /></object>Change Password</div>",
                                                "path": "/account/change-password"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "b0937240-ea14-11ea-a21e-f569cc759e68",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "b9cc4940-ea14-11ea-a21e-f569cc759e68",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pay.svg\"><img src=\"./assets/images/icon_pay.svg\" /></object>Pay Dues</div>",
                                                "path": "/account/pay-dues"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "c3ebafb0-ea14-11ea-a21e-f569cc759e68",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ccf909e0-ea14-11ea-a21e-f569cc759e68",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_balance.svg\"><img src=\"./assets/images/icon_balance.svg\" /></object>View Balance</div>",
                                                "path": "/account/billings"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "app-menus-container"
                            }
                        ],
                        "component": "",
                        "className": ""
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-account",
                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                "route": "/account",
                "component": "",
                "_sortIndex": 240
            },
            {
                "id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                "type": "page",
                "children": [
                    {
                        "id": "62b4dc20-96d2-11ea-8416-c79b465e39ab",
                        "type": "container",
                        "children": [
                            {
                                "id": "6560c240-96d2-11ea-8416-c79b465e39ab",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Billing",
                                "className": "has-text-centered my-1"
                            },
                            {
                                "id": "fedcfca0-2704-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "08314ae0-2705-11eb-ac91-c74c3e25a4bd",
                                        "type": "button",
                                        "children": [],
                                        "variant": "text",
                                        "text": "Billing History",
                                        "path": "/account/billings/history"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center"
                            },
                            {
                                "id": "7b996350-96d2-11ea-8416-c79b465e39ab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "176e5780-f6f1-11ea-b61a-f5b3d409e561",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "18452f80-f6f1-11ea-b61a-f5b3d409e561",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "name",
                                                "label": "Statement"
                                            },
                                            {
                                                "id": "20d7db70-f6f1-11ea-b61a-f5b3d409e561",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "postingDate",
                                                "label": "Posting Date"
                                            },
                                            {
                                                "id": "27b33a70-f6f1-11ea-b61a-f5b3d409e561",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "dueDate",
                                                "label": "Due Date"
                                            },
                                            {
                                                "id": "ab0e7200-0901-11eb-894e-29a0b8264102",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Amount",
                                                "name": "amount",
                                                "currency": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": "/account/homeowner-billings"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "main"
                            }
                        ],
                        "orientation": "vertical"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-billing",
                "route": "/account/billings",
                "_sortIndex": 250,
                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918"
            },
            {
                "id": "c886d3d0-914d-11ea-b2e4-0783314da7b2",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                        "children": [
                            {
                                "id": "e44eb2c0-f0ae-11ea-a61b-49eea0a1bb78",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "_sortIndex": -1,
                                "text": "Comments and Suggestions",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                        "type": "button",
                                        "children": [],
                                        "text": "Comments and Suggestions history",
                                        "path": "/filed-complaints",
                                        "variant": "text"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0,
                                "className": "justify-content-center"
                            },
                            {
                                "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "type",
                                        "label": "Type of issue",
                                        "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"}, {\"label\": \"Security\", \"value\": \"security\"}, {\"label\": \"Safety\", \"value\": \"safety\"}, {\"label\": \"PMG Crew/ Team Conduct\", \"value\": \"team-conduct\"} ,{\"label\": \"Others\", \"value\": \"others\"}]",
                                        "saveObject": true,
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10,
                                "className": ""
                            },
                            {
                                "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "details",
                                        "label": "Details of the issue/concern",
                                        "multiline": true,
                                        "name": "",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20,
                                "className": ""
                            },
                            {
                                "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                        "type": "inputMedia",
                                        "children": [],
                                        "label": "Photo",
                                        "dataField": "photos",
                                        "basePath": "comments-suggestions",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30,
                                "className": ""
                            },
                            {
                                "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e6ce6cf0-1225-11eb-8323-55a37c955b67",
                                        "type": "subView",
                                        "children": [],
                                        "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40,
                                "className": ""
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-comments-suggestions",
                "route": "/homeowner-comments-suggestions/:id",
                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                "className": "",
                "_sortIndex": 260,
                "access": ""
            },
            {
                "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                        "children": [
                            {
                                "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Comments and Suggestions",
                                "_sortIndex": 0,
                                "name": "",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                        "type": "button",
                                        "children": [],
                                        "text": "View complaint history",
                                        "path": "/filed-complaints",
                                        "variant": "text"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 9,
                                "className": "justify-content-center"
                            },
                            {
                                "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "type",
                                        "label": "Type of issue"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10,
                                "className": ""
                            },
                            {
                                "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "details",
                                        "label": "Details of the issue/concern",
                                        "multiline": true,
                                        "name": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20,
                                "className": ""
                            },
                            {
                                "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                        "type": "inputMedia",
                                        "children": [],
                                        "label": "Photo",
                                        "dataField": "photo"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40,
                                "className": ""
                            },
                            {
                                "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                        "type": "button",
                                        "children": [],
                                        "text": "Save",
                                        "command": "save",
                                        "variant": "contained",
                                        "color": "primary",
                                        "className": "",
                                        "fullWidth": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 50,
                                "className": ""
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-complaint",
                "route": "/file-complaint/:id",
                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                "className": "",
                "_sortIndex": 270
            },
            {
                "id": "47440520-971c-11ea-8a71-fd00fe88963d",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "47440521-971c-11ea-8a71-fd00fe88963d",
                        "children": [
                            {
                                "id": "4b1a6180-971c-11ea-8a71-fd00fe88963d",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Comments and Suggestions History",
                                "className": "has-text-centered pb-2"
                            }
                        ]
                    },
                    {
                        "id": "52a624c0-971c-11ea-8a71-fd00fe88963d",
                        "type": "container",
                        "children": [
                            {
                                "id": "80367620-0372-11eb-b960-0956a98f4295",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "8194f4b0-0372-11eb-b960-0956a98f4295",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "type",
                                        "label": "Type"
                                    },
                                    {
                                        "id": "8ab53aa0-0372-11eb-b960-0956a98f4295",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "details",
                                        "label": "Details"
                                    }
                                ],
                                "orientation": "horizontal",
                                "path": ""
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "5a577160-971c-11ea-8a71-fd00fe88963d",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                "route": "/filed-complaints",
                "name": "homeowner-complaints",
                "className": "",
                "_sortIndex": 280
            },
            {
                "id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "4e8b5d81-9157-11ea-8382-2991800d56bc",
                        "children": [
                            {
                                "id": "268a56f0-da0a-11ea-87ea-f5980a7a1cab",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "name": "",
                                "text": "Delivery/Pull-out Pass",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "3cd79170-da0a-11ea-87ea-f5980a7a1cab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "40347810-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "button",
                                        "children": [],
                                        "text": "My Delivery / Pull-out Passes",
                                        "variant": "text",
                                        "path": "/delivery-passes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center pt-1 pb-1"
                            },
                            {
                                "id": "590cca40-da0a-11ea-87ea-f5980a7a1cab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5f544db0-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "inputSelect",
                                        "children": [],
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "dataField": "unit",
                                        "label": "Unit",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "66e4a4d0-da0a-11ea-87ea-f5980a7a1cab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6b30cd70-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "type",
                                        "label": "Type of Pass",
                                        "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                        "saveObject": true,
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "7d085e50-da0a-11ea-87ea-f5980a7a1cab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c833a300-edcc-11ea-abfa-8f89576fb378",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "dataField": "schedule",
                                        "label": "Schedule",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "87486c20-da0a-11ea-87ea-f5980a7a1cab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "8c652b30-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "inputList",
                                        "children": [],
                                        "label": "Item(s) - Please enumerate including quantities.",
                                        "dataField": "items",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a733cf20-da0a-11ea-87ea-f5980a7a1cab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ac4da800-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "company",
                                        "label": "Delivery Company",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a788cc00-e7f7-11ea-aa5b-4b966778e568",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "component": "TermsAndConditions",
                                "name": "delivery-pass"
                            },
                            {
                                "id": "df2a4d60-dac7-11ea-9725-dfc9cbd591dc",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b51a9440-1225-11eb-8323-55a37c955b67",
                                        "type": "subView",
                                        "children": [],
                                        "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-delivery-pass",
                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                "route": "/create-delivery-pass/:id",
                "className": "",
                "_sortIndex": 290
            },
            {
                "id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "db3a70c1-96c7-11ea-bb44-c34ef5a44500",
                        "children": [
                            {
                                "id": "e5006c90-96c7-11ea-bb44-c34ef5a44500",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Delivery Passes",
                                "className": "has-text-centered pb-2"
                            },
                            {
                                "id": "e8824050-96c7-11ea-bb44-c34ef5a44500",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f0fafba0-96c7-11ea-bb44-c34ef5a44500",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "ae53bb10-96c8-11ea-bb44-c34ef5a44500",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "unit",
                                                "label": "Unit"
                                            },
                                            {
                                                "id": "b91bbd90-96c8-11ea-bb44-c34ef5a44500",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "type",
                                                "label": "Type"
                                            },
                                            {
                                                "id": "5c160d40-0378-11eb-bfdc-3b5d643d4962",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "schedule",
                                                "date": true,
                                                "label": "Schedule",
                                                "format": "MM/dd/yyyy hh:mm a"
                                            },
                                            {
                                                "id": "647fad60-0378-11eb-bfdc-3b5d643d4962",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "company",
                                                "label": "Company"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-delivery-passes",
                "route": "/delivery-passes",
                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                "_sortIndex": 300
            },
            {
                "id": "91150f60-ef9a-11ea-9d3f-8def54ed8352",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "91150f61-ef9a-11ea-9d3f-8def54ed8352",
                        "children": [
                            {
                                "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Homeowner",
                                "_sortIndex": 0,
                                "name": "",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "9132a986-ef9a-11ea-9d3f-8def54ed8352",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9132a988-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "name",
                                        "label": "Registered Name",
                                        "dataField": "name"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0,
                                "dataField": ""
                            },
                            {
                                "id": "9132a98f-ef9a-11ea-9d3f-8def54ed8352",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9132a991-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "nickname",
                                        "label": "Nickname",
                                        "dataField": "nickname"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 19
                            },
                            {
                                "id": "9132a983-ef9a-11ea-9d3f-8def54ed8352",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9132a985-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "email",
                                        "label": "Email Address",
                                        "dataField": "email"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20
                            },
                            {
                                "id": "9132a98c-ef9a-11ea-9d3f-8def54ed8352",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9132a98e-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "contact",
                                        "label": "Mobile Number",
                                        "dataField": "contact"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40
                            },
                            {
                                "id": "9132a992-ef9a-11ea-9d3f-8def54ed8352",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9132d090-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "photo",
                                        "label": "Photo",
                                        "dataField": "photo",
                                        "basePath": "homeowners"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 60
                            }
                        ]
                    },
                    {
                        "id": "9129cfe0-ef9a-11ea-9d3f-8def54ed8352",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-detail-page",
                "route": "/homeowners/:id",
                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                "component": "",
                "access": "admin,accounting"
            },
            {
                "id": "913e9060-ef9a-11ea-9d3f-8def54ed8352",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "91150f61-ef9a-11ea-9d3f-8def54ed8352",
                        "children": [
                            {
                                "id": "91498ce0-ef9a-11ea-9d3f-8def54ed8352",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "9151f150-ef9a-11ea-9d3f-8def54ed8352",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "915acaf0-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "accountNumbe",
                                        "label": "Registered Name",
                                        "dataField": "name"
                                    },
                                    {
                                        "id": "915acaf4-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "firstName",
                                        "label": "Nickname",
                                        "dataField": "nickname"
                                    },
                                    {
                                        "id": "915acaf6-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "lastName",
                                        "label": "Email Address",
                                        "dataField": "email"
                                    },
                                    {
                                        "id": "915acafa-ef9a-11ea-9d3f-8def54ed8352",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "nickname",
                                        "label": "Mobile Number",
                                        "dataField": "contact"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-list-page",
                "route": "/homeowners-list",
                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
            },
            {
                "id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "0ad19881-93ea-11ea-99e4-a504a663bd48"
                    },
                    {
                        "id": "1acacc70-93ea-11ea-9384-d1e4789a27a9",
                        "type": "subView",
                        "children": [],
                        "view": "e1156440-93e9-11ea-99e4-a504a663bd48"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-login-page",
                "component": "",
                "route": "/homeowners-login",
                "_sortIndex": 330,
                "layout": "full"
            },
            {
                "id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "63850e21-a55c-11ea-8feb-c7034be1bc66",
                        "children": [
                            {
                                "id": "da5a80c0-a55c-11ea-99ce-d1e75946b32d",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Marketplace"
                            },
                            {
                                "id": "ec182100-a55c-11ea-99ce-d1e75946b32d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f232a100-a55c-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "07f88310-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "text": "",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_water.svg\"><img src=\"./assets/images/icon_water.svg\" /></object>Drinking Water</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "014dd100-a55d-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "26cecc90-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_gas.svg\"><img src=\"./assets/images/icon_gas.svg\" /></object>Gasul</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "columns is-centered mt-2"
                            },
                            {
                                "id": "b1d7e880-a55d-11ea-99ce-d1e75946b32d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "494d3680-a55d-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "516b0090-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Rice</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "63098010-a55d-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "69f72e90-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_laundry.svg\"><img src=\"./assets/images/icon_laundry.svg\" /></object>Laundry</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "columns is-centered"
                            },
                            {
                                "id": "c6888a00-a55d-11ea-99ce-d1e75946b32d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ccd51680-a55d-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d4ae5d80-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "button",
                                                "children": [],
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pet.svg\"><img src=\"./assets/images/icon_pet.svg\" /></object>Pet Grooming</div>",
                                                "className": "app-menu-item"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "f062cd90-a55d-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "f2893000-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_cleaning.svg\"><img src=\"./assets/images/icon_cleaning.svg\" /></object>Cleaning Service</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "columns is-centered"
                            }
                        ],
                        "className": "has-text-centered",
                        "_sortIndex": -1
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-marketplace",
                "route": "/market-place",
                "_sortIndex": 340
            },
            {
                "id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "231003d1-c920-11ea-9080-1563a0e60ff0",
                        "className": "p1",
                        "children": [
                            {
                                "id": "709bda20-c920-11ea-9080-1563a0e60ff0",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Message Board",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "53470b10-c921-11ea-84bc-97bd01295e5b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "566450a0-c921-11ea-84bc-97bd01295e5b",
                                        "type": "button",
                                        "children": [],
                                        "variant": "text",
                                        "text": "New Post",
                                        "path": "/message-board/0"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center pt-1 pb-1"
                            },
                            {
                                "id": "5b0277e0-db4b-11ea-bdb5-fdc0741685fd",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "component": "MessageBoard"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-message-board",
                "route": "/message-board",
                "_sortIndex": 350,
                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
            },
            {
                "id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "233b7c71-c927-11ea-84bc-97bd01295e5b",
                        "children": [
                            {
                                "id": "521b2680-c927-11ea-84bc-97bd01295e5b",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "New Post",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "59e133a0-c927-11ea-84bc-97bd01295e5b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "62ac7bc0-c927-11ea-84bc-97bd01295e5b",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "",
                                        "label": "Post Topic",
                                        "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"LF\", \"label\": \"LF (Looking For)\"}]",
                                        "dataField": "topic",
                                        "required": false,
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "bite-select pt-2"
                            },
                            {
                                "id": "df291320-c927-11ea-8d76-f7e6b086de0b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e2116600-c927-11ea-8d76-f7e6b086de0b",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "",
                                        "label": "Title",
                                        "dataField": "title",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "eb70b0c0-c927-11ea-8d76-f7e6b086de0b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ed3236e0-c927-11ea-8d76-f7e6b086de0b",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "",
                                        "label": "Price",
                                        "dataField": "price",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "f42897f0-c927-11ea-8d76-f7e6b086de0b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f98df8c0-c927-11ea-8d76-f7e6b086de0b",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "",
                                        "label": "Description",
                                        "multiline": true,
                                        "dataField": "description",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "0b6fc2d0-c928-11ea-8d76-f7e6b086de0b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "0d2784f0-c928-11ea-8d76-f7e6b086de0b",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "",
                                        "label": "Contact Number",
                                        "dataField": "contactNumber",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "373868e0-c928-11ea-8d76-f7e6b086de0b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "38f5f760-c928-11ea-8d76-f7e6b086de0b",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "",
                                        "label": "Add Photos",
                                        "dataField": "photos",
                                        "basePath": "message-boards",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "bite-select"
                            },
                            {
                                "id": "cf8faf20-e7f7-11ea-aa5b-4b966778e568",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "component": "TermsAndConditions",
                                "name": "message-board"
                            },
                            {
                                "id": "27ad7fc0-c92b-11ea-ad72-2b470c4935f8",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c9ca9980-1225-11eb-8323-55a37c955b67",
                                        "type": "subView",
                                        "children": [],
                                        "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-new-post",
                "route": "/message-board/:id",
                "_sortIndex": 360,
                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
            },
            {
                "id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c2c20490-91b3-11ea-ba7f-61310864338b",
                        "children": [
                            {
                                "id": "d1894830-91b3-11ea-ba7f-61310864338b",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Notifications",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "26512330-96da-11ea-a306-e3cce4ba1daa",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "80c3ab50-4015-11eb-903d-a5a43b413486",
                                        "type": "button",
                                        "children": [],
                                        "text": "Archived Notifications",
                                        "variant": "text",
                                        "path": "/notifications-archived"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center pt-1 pb-1"
                            },
                            {
                                "id": "294a17e0-96da-11ea-a306-e3cce4ba1daa",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "00b6be60-08f8-11eb-a297-615290505a3f",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "023d5e60-08f8-11eb-a297-615290505a3f",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Date Created",
                                                "name": "createdAt",
                                                "date": true,
                                                "format": "MM/dd/yyyy hh:mm a",
                                                "width": "20"
                                            },
                                            {
                                                "id": "24355a40-08f8-11eb-a297-615290505a3f",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Content",
                                                "dataField": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": "#"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "component": "Notifications"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-notifications",
                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                "route": "/notifications",
                "_sortIndex": 370,
                "repeat": false
            },
            {
                "id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "a259bcb1-96d2-11ea-8416-c79b465e39ab",
                        "children": [
                            {
                                "id": "a82b73e0-96d2-11ea-8416-c79b465e39ab",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Change password"
                            },
                            {
                                "id": "d3e59b00-96d2-11ea-8416-c79b465e39ab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d049b3a0-0ff4-11eb-98c1-8382fbef0426",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "oldPassword",
                                        "label": "Old Password",
                                        "password": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "d63c5c40-96d2-11ea-8416-c79b465e39ab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d7f38630-0ff4-11eb-98c1-8382fbef0426",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "password",
                                        "description": "",
                                        "label": "New Password",
                                        "password": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "e2e88c20-0ff4-11eb-98c1-8382fbef0426",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e4a15fb0-0ff4-11eb-98c1-8382fbef0426",
                                        "type": "button",
                                        "children": [],
                                        "text": "Submit",
                                        "command": "change_password"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "component": "ChangePassword"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-password",
                "route": "/account/change-password",
                "_sortIndex": 380,
                "component": ""
            },
            {
                "id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "id": "2587d890-db5e-11ea-b61b-f3aa751f3b6f",
                        "type": "container",
                        "children": [
                            {
                                "id": "c5cddbb1-c92f-11ea-baed-470d8f727899",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e297edd0-dc30-11ea-abb1-8b4052c10758",
                                        "type": "carousel",
                                        "children": [],
                                        "dataField": "photos",
                                        "text": "Preview",
                                        "direction": "ltr",
                                        "autoplay": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "main",
                                "component": "",
                                "flex": "1"
                            },
                            {
                                "id": "3175e480-db5e-11ea-b61b-f3aa751f3b6f",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "flex": "1",
                                "component": "MessageBoardInfo"
                            }
                        ],
                        "orientation": "vertical"
                    },
                    {
                        "id": "856f1e40-dc39-11ea-b64f-0dfbc9206e7f",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "component": "MessageBoardComments"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-post",
                "route": "/message-board-post/:id",
                "_sortIndex": 390,
                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                "noPadding": true
            },
            {
                "id": "17466790-96d2-11ea-9995-9d94cc393546",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "0e752c01-96d2-11ea-9995-9d94cc393546",
                        "children": [
                            {
                                "id": "8ad561c0-96d2-11ea-8416-c79b465e39ab",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Profile",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "6beeab70-e9fe-11ea-83ad-8d1a1d915279",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "component": "AccountPhoto",
                                "dataField": "photo"
                            },
                            {
                                "id": "0bcd5e60-e9f1-11ea-847f-6170db74bd04",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c9fed520-e9f2-11ea-afe7-79ef44d4232d",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "firstName",
                                        "className": "mr-1"
                                    },
                                    {
                                        "id": "cebc0f10-e9f2-11ea-afe7-79ef44d4232d",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "middleInitial",
                                        "className": "mr-1"
                                    },
                                    {
                                        "id": "d1a5c180-e9f2-11ea-afe7-79ef44d4232d",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "lastName",
                                        "className": "mr-1"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "p-2"
                            },
                            {
                                "id": "74f1d0a0-e9f7-11ea-b01f-c974ed0277bf",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "7bc95f10-e9f7-11ea-b01f-c974ed0277bf",
                                        "type": "textBlock",
                                        "children": [],
                                        "className": "mr-3 profile-label",
                                        "text": "Nickname"
                                    },
                                    {
                                        "id": "88bf3e10-e9f7-11ea-b01f-c974ed0277bf",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "nickname"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "p-2"
                            },
                            {
                                "id": "1e1ae290-e9f1-11ea-847f-6170db74bd04",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1ef42b90-e9f1-11ea-847f-6170db74bd04",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "",
                                        "text": "Email",
                                        "className": "mr-3 profile-label"
                                    },
                                    {
                                        "id": "a26fba10-e9f2-11ea-afe7-79ef44d4232d",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "email"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "p-2"
                            },
                            {
                                "id": "27b40200-e9f1-11ea-847f-6170db74bd04",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "2895fd90-e9f1-11ea-847f-6170db74bd04",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "",
                                        "text": "Contact",
                                        "className": "mr-3 profile-label"
                                    },
                                    {
                                        "id": "f9d429d0-e9f2-11ea-afe7-79ef44d4232d",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "contact"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "p-2"
                            },
                            {
                                "id": "2b4da380-e9f1-11ea-847f-6170db74bd04",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "2cbf5bf0-e9f1-11ea-847f-6170db74bd04",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "",
                                        "text": "Units",
                                        "className": "mr-3 profile-label"
                                    },
                                    {
                                        "id": "f0c967b0-e9f2-11ea-afe7-79ef44d4232d",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "unit",
                                        "component": "AccountUnits"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "p-2"
                            },
                            {
                                "id": "419db040-ea09-11ea-b4be-3d136e0d16ce",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4301fb30-ea09-11ea-b4be-3d136e0d16ce",
                                        "type": "button",
                                        "children": [],
                                        "fullWidth": false,
                                        "text": "Save",
                                        "command": "save"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-profile",
                "route": "/account/profile/:id",
                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                "_sortIndex": 400
            },
            {
                "id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "df79f2a1-914a-11ea-8bb4-63310f210ad3",
                        "children": [
                            {
                                "id": "376608f0-914b-11ea-9d03-f552f48ec793",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Unit Request"
                            },
                            {
                                "id": "3e665510-914b-11ea-9d03-f552f48ec793",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4c028630-914b-11ea-93c5-9720e806f72b",
                                        "type": "button",
                                        "children": [],
                                        "text": "View service history",
                                        "path": "/service-requests",
                                        "className": "",
                                        "variant": "text"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center pt-1 pb-1",
                                "flex": "[object Object]"
                            },
                            {
                                "id": "427db210-914b-11ea-9d03-f552f48ec793",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bf2b7640-d902-11ea-b7fc-037f001b1342",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "type",
                                        "options": "[{ \"value\": \"repairs\", \"label\": \"Repairs\"},{ \"value\": \"renovation\", \"label\": \"Renovation\"},{ \"value\": \"carSticker\", \"label\": \"Car Sticker\", data: null }]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center pt-2 pb-2",
                                "name": "select"
                            },
                            {
                                "id": "44bc5770-914b-11ea-9d03-f552f48ec793",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a35c3b70-c90d-11ea-8c59-196c3ec4f839",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                        "name": "repairs"
                                    },
                                    {
                                        "id": "2697efe0-c911-11ea-9dd0-6723feee229e",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                        "name": "renovation"
                                    },
                                    {
                                        "id": "2a9de7c0-c911-11ea-9dd0-6723feee229e",
                                        "type": "subView",
                                        "children": [],
                                        "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                        "name": "carSticker"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "pt-2 pb-2",
                                "name": "select",
                                "component": "UnitRequest"
                            },
                            {
                                "id": "2ac5bef0-ad21-11ea-8384-7b922845035e",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "316b40e0-ad21-11ea-8384-7b922845035e",
                                        "type": "button",
                                        "children": [],
                                        "command": "save",
                                        "variant": "",
                                        "text": "submit",
                                        "color": "primary",
                                        "className": "",
                                        "fullWidth": true
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "className": "p-1"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-service-request",
                "route": "/create-service-request/:id",
                "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                "_sortIndex": 410
            },
            {
                "id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "6053d8f1-91b4-11ea-ad0e-b15c87c4d0e0",
                        "children": [
                            {
                                "id": "bc5b9200-91b4-11ea-ad0e-b15c87c4d0e0",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Unit Request"
                            },
                            {
                                "id": "39f86670-91b5-11ea-ad0e-b15c87c4d0e0",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3e960a70-91b5-11ea-ad0e-b15c87c4d0e0",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "424ad510-91b5-11ea-ad0e-b15c87c4d0e0",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "",
                                                "dataField": "details"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": "/create-service-request"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3c5ac070-91b5-11ea-ad0e-b15c87c4d0e0",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-service-requests",
                "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                "route": "/service-requests",
                "_sortIndex": 420
            },
            {
                "id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "d2d04d11-d902-11ea-b7fc-037f001b1342",
                        "className": "",
                        "children": [
                            {
                                "id": "04c0e0a0-d903-11ea-b7fc-037f001b1342",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Unit Request",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "afdbb6d0-d904-11ea-8fb7-85b0ea54cdde",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bfa2b730-d904-11ea-8fb7-85b0ea54cdde",
                                        "type": "button",
                                        "children": [],
                                        "text": "View Unit Requests History",
                                        "variant": "text",
                                        "path": "/unit-requests"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center pt-1 pb-1"
                            },
                            {
                                "id": "84f0dbb0-d906-11ea-bd61-bf1e0d1003bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "020ce720-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "04ead9c0-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "unit",
                                                "label": "Unit",
                                                "dataField": "",
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "nullable": false,
                                                "required": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "unit-container"
                                    },
                                    {
                                        "id": "21b5e9f0-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "29662520-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "inputSelect",
                                                "children": [],
                                                "saveObject": true,
                                                "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Other Unit Request\", \"value\":\"others\"}]",
                                                "dataField": "type",
                                                "name": "type",
                                                "label": "Type",
                                                "required": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "type-container"
                                    },
                                    {
                                        "id": "47ac1580-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                        "name": "homeowner-unit-request-service-request"
                                    },
                                    {
                                        "id": "60137d70-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                        "name": "homeowner-unit-request-plans-documents"
                                    },
                                    {
                                        "id": "71865910-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "subView",
                                        "children": [],
                                        "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                        "name": "homeowner-unit-request-refund-renovation-bond"
                                    },
                                    {
                                        "id": "7c6788e0-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "subView",
                                        "children": [],
                                        "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                        "name": "homeowner-unit-request-vehicle-sticker"
                                    },
                                    {
                                        "id": "82e844c0-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "subView",
                                        "children": [],
                                        "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                        "name": "homeowner-unit-request-grease-trap-cleaning"
                                    },
                                    {
                                        "id": "30acac90-d9a7-11ea-9c8b-b7f572d43c0f",
                                        "type": "subView",
                                        "children": [],
                                        "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                        "name": "homeowner-unit-request-aircon-cleaning"
                                    },
                                    {
                                        "id": "16922b80-d9a9-11ea-9331-090047662dd2",
                                        "type": "subView",
                                        "children": [],
                                        "name": "homeowner-unit-request-others",
                                        "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2"
                                    }
                                ],
                                "orientation": "horizontal",
                                "component": "UnitRequest",
                                "name": "homeowner-unit-request"
                            },
                            {
                                "id": "0dd9e6c0-dac8-11ea-9725-dfc9cbd591dc",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c2c5e7f0-1222-11eb-a9d8-83db08ab78a0",
                                        "type": "subView",
                                        "children": [],
                                        "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                    }
                                ],
                                "orientation": "horizontal",
                                "component": ""
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-request",
                "className": "",
                "route": "/homeowner-unit-request/:id",
                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                "_sortIndex": 430
            },
            {
                "id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "b3e97d21-9156-11ea-88a4-7f4a31012a76",
                        "children": [
                            {
                                "id": "cda662f0-9156-11ea-88a4-7f4a31012a76",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Visitor / Tenant Passes",
                                "_sortIndex": 0,
                                "className": "has-text-centered"
                            },
                            {
                                "id": "24752260-9157-11ea-88a4-7f4a31012a76",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "80f84ec0-96c2-11ea-8d81-e3df0146485b",
                                        "type": "button",
                                        "children": [],
                                        "path": "/visitor-passes",
                                        "text": "My Visitor / Tenant Passes",
                                        "variant": "text"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 9,
                                "className": "justify-content-center"
                            },
                            {
                                "id": "d2c56bf0-9156-11ea-88a4-7f4a31012a76",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "65ddc8e0-da1a-11ea-b50d-8323e621ee63",
                                        "type": "inputSelect",
                                        "children": [],
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "dataField": "unit",
                                        "label": "Unit",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10,
                                "className": ""
                            },
                            {
                                "id": "d4c90420-9156-11ea-88a4-7f4a31012a76",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b1b7f790-da1a-11ea-b50d-8323e621ee63",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "type",
                                        "label": "Type of Pass",
                                        "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"}, {\"label\":\"Long-term Tenant (More than 3 months)\", \"value\":\"long-term\"}]",
                                        "saveObject": true,
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20,
                                "className": ""
                            },
                            {
                                "id": "d80f2e70-9156-11ea-88a4-7f4a31012a76",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "0ca06880-da1c-11ea-87e9-9de57275fc27",
                                        "type": "subView",
                                        "children": [],
                                        "name": "homeowner-visitor-pass-visitor-pass",
                                        "view": "824f51f0-da1b-11ea-87e9-9de57275fc27"
                                    },
                                    {
                                        "id": "f591eef0-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "subView",
                                        "children": [],
                                        "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                        "name": "homeowner-visitor-pass-short-term"
                                    },
                                    {
                                        "id": "8667e010-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "subView",
                                        "children": [],
                                        "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                        "name": "homeowner-visitor-pass-long-term"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30,
                                "className": "justify-content-center",
                                "component": "VisitorPass",
                                "name": "homeowner-visitor-pass"
                            },
                            {
                                "id": "da09ed00-9156-11ea-88a4-7f4a31012a76",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a2554800-1225-11eb-8323-55a37c955b67",
                                        "type": "subView",
                                        "children": [],
                                        "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40,
                                "className": "justify-content-center"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-visitor-pass",
                "route": "/create-visitor-pass/:id",
                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                "className": "",
                "_sortIndex": 440
            },
            {
                "id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "id": "bf9e7ca0-96c5-11ea-8624-43dc05a96ae0",
                        "type": "container",
                        "children": [
                            {
                                "id": "c95f4850-96c5-11ea-8624-43dc05a96ae0",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Visitor Passes",
                                "className": "has-text-centered pb-2"
                            }
                        ],
                        "orientation": "vertical",
                        "_sortIndex": -1,
                        "className": ""
                    },
                    {
                        "name": "main",
                        "type": "container",
                        "id": "09704a71-96c1-11ea-8d81-e3df0146485b",
                        "children": [
                            {
                                "id": "e49e00e0-01e9-11eb-bf8e-6d1770bf29d6",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "e73f1190-01e9-11eb-bf8e-6d1770bf29d6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "unit",
                                        "name": "unit",
                                        "label": "Unit"
                                    },
                                    {
                                        "id": "f89675f0-01e9-11eb-bf8e-6d1770bf29d6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "type",
                                        "name": "type",
                                        "label": "Type"
                                    },
                                    {
                                        "id": "048237f0-01ea-11eb-bf8e-6d1770bf29d6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "startDate",
                                        "name": "startDate",
                                        "label": "Start Date"
                                    },
                                    {
                                        "id": "14b6ee40-01ea-11eb-bf8e-6d1770bf29d6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "endDate",
                                        "name": "endDate",
                                        "label": "End Date"
                                    }
                                ],
                                "orientation": "horizontal",
                                "path": ""
                            }
                        ],
                        "_sortIndex": 0
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-visitor-passes",
                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                "route": "/visitor-passes",
                "_sortIndex": 450
            },
            {
                "id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                "type": "page",
                "children": [
                    {
                        "id": "478f4730-c6f9-11ea-8043-0381a4f882af",
                        "type": "subView",
                        "children": [],
                        "view": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-welcome-page",
                "route": "/welcome",
                "component": "",
                "_sortIndex": 460,
                "requireAuth": true
            },
            {
                "id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "dac583a1-d9ca-11ea-b444-450a6b52ef90",
                        "children": [
                            {
                                "id": "f6978dd0-d9ca-11ea-b444-450a6b52ef90",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Work Permit",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "a2b3bab0-da09-11ea-87ea-f5980a7a1cab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a4b07510-da09-11ea-87ea-f5980a7a1cab",
                                        "type": "button",
                                        "children": [],
                                        "text": "View Work Permit History",
                                        "variant": "text",
                                        "path": "/work-permits"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center pt-1 pb-1"
                            },
                            {
                                "id": "5ad52cd0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "607dff40-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "inputSelect",
                                        "children": [],
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "9d05c150-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bb60e760-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "natureOfWork",
                                        "label": "Nature of Work",
                                        "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                        "saveObject": true,
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "d06acc70-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d1a632a0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "contractor",
                                        "label": "Name of Contractor",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "dfc94700-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e0f19a60-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "personInCharge",
                                        "label": "Name of Person in Charge",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "ec2bbfa0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "eee8e3d0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "contactPersonInCharge",
                                        "label": "Contact Number of Person in Charge",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4f549340-d9cc-11ea-83f3-4b9e6a8a3ce6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "12242dc0-d9cf-11ea-be94-03767312b052",
                                        "type": "inputList",
                                        "children": [],
                                        "label": "Workers",
                                        "dataField": "workers",
                                        "placeholder": "Worker",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "77fa9030-da06-11ea-8a8e-430e67178950",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "7d094580-da06-11ea-8a8e-430e67178950",
                                        "type": "inputText",
                                        "children": [],
                                        "multiline": true,
                                        "dataField": "description",
                                        "label": "Description of Work",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "f2f0d5b0-da06-11ea-8a8e-430e67178950",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fe279f90-da06-11ea-8a8e-430e67178950",
                                        "type": "inputList",
                                        "children": [],
                                        "dataField": "materials",
                                        "label": "List of Materials",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "2ebf0bc0-da07-11ea-8a8e-430e67178950",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "33285950-da07-11ea-8a8e-430e67178950",
                                        "type": "inputList",
                                        "children": [],
                                        "dataField": "tools",
                                        "label": "List of Tools",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3bd32120-da07-11ea-8a8e-430e67178950",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3e8fd020-da07-11ea-8a8e-430e67178950",
                                        "type": "inputDate",
                                        "children": [],
                                        "dataField": "startDate",
                                        "label": "Proposed Start Date ",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5217a910-da07-11ea-8a8e-430e67178950",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "558c3660-da07-11ea-8a8e-430e67178950",
                                        "type": "inputDate",
                                        "children": [],
                                        "label": "Target End Date",
                                        "dataField": "endDate",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "67781290-da07-11ea-8a8e-430e67178950",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6a6f0b70-da07-11ea-8a8e-430e67178950",
                                        "type": "inputMedia",
                                        "children": [],
                                        "dataField": "plans",
                                        "label": "Photo of Plans",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "814543c0-e7f7-11ea-aa5b-4b966778e568",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "work-permit",
                                "component": "TermsAndConditions"
                            },
                            {
                                "id": "f8277f00-1225-11eb-8323-55a37c955b67",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fa036af0-1225-11eb-8323-55a37c955b67",
                                        "type": "subView",
                                        "children": [],
                                        "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-work-permit",
                "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                "route": "/homeowner-work-permit/:id",
                "_sortIndex": 470
            },
            {
                "id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                        "children": [
                            {
                                "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Message Board",
                                "_sortIndex": 0,
                                "name": "",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "e3205a10-05e5-11eb-bd99-6f65d415d15a",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e6c1c3c0-05e5-11eb-bd99-6f65d415d15a",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "name": "createdAt",
                                        "label": "Date Submitted"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c1110200-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c1110202-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner",
                                        "saveObject": true,
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c1110203-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "22f119c0-ea54-11ea-8de6-3f209e03f86c",
                                        "type": "inputSelect",
                                        "children": [],
                                        "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"LF\", \"label\": \"LF (Looking For)\"}]",
                                        "saveObject": true,
                                        "name": "topic",
                                        "component": "",
                                        "label": "Topic",
                                        "dataField": "topic"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c1110206-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c1110208-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "title",
                                        "label": "Title",
                                        "dataField": "title"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c1110209-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c1112910-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "price",
                                        "label": "Price",
                                        "dataField": "price"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c1112911-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c1112913-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c1112914-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c1112916-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "contactNumber",
                                        "label": "Contact Number",
                                        "dataField": "contactNumber"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c1112917-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c1112919-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "",
                                        "label": "Photos",
                                        "dataField": "photos",
                                        "basePath": "message-boards"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c111291a-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a0d34530-f0a2-11ea-be87-f549caf53aba",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Status",
                                        "dataField": "status",
                                        "options": "[{\"value\": \"done\", \"label\": \"Done\"}, {\"value\": \"approved\", \"label\": \"Approved\"}, {\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": "status"
                            },
                            {
                                "id": "c111291d-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c111291f-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "b8a70560-f0b2-11ea-99d0-d9f36ea6d179",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "28fa05f0-0360-11eb-ba8d-6d2ea3361677",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "label": "Date Posted",
                                        "dataField": "datePosted"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    },
                    {
                        "id": "c10987f0-df4b-11ea-a4a7-a1e8c04129ca",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "message-board-edit-page",
                "route": "/message-boards/:id",
                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                "_sortIndex": 750,
                "access": "admin,pmg"
            },
            {
                "id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                        "children": [
                            {
                                "id": "c11b8950-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "c1217cc0-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "c1283380-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "dataField": "",
                                        "date": true
                                    },
                                    {
                                        "id": "c1283382-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "topic",
                                        "label": "Topic",
                                        "dataField": "topic"
                                    },
                                    {
                                        "id": "c1285a90-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "title",
                                        "label": "Title",
                                        "dataField": "title"
                                    },
                                    {
                                        "id": "c1285a92-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "price",
                                        "label": "Price",
                                        "dataField": "price"
                                    },
                                    {
                                        "id": "c1285a96-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "contactNumber",
                                        "label": "Description",
                                        "dataField": "description"
                                    },
                                    {
                                        "id": "c1285a9a-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "status",
                                        "label": "Contact Number",
                                        "dataField": "contactNumber"
                                    },
                                    {
                                        "id": "5fd419e0-f0a4-11ea-a584-176526b5be96",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "homeowner",
                                        "text": "",
                                        "label": "Homeowner"
                                    },
                                    {
                                        "id": "6097b800-f0a4-11ea-a584-176526b5be96",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Status",
                                        "dataField": "status",
                                        "name": "status"
                                    },
                                    {
                                        "id": "5a089900-fdf1-11ea-ba93-5145fd1d7baf",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Date Posted",
                                        "name": "",
                                        "date": true,
                                        "format": "MM-dd-yyyy hh:mm a",
                                        "dataField": "datePosted"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/message-boards/detail"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "message-board-list-page",
                "route": "/message-boards-list",
                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                "_sortIndex": 760,
                "params": "{\"or:\":[{\"status\": \"pending\"},{\"status\": \"approved\"}]}"
            },
            {
                "id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                        "children": [
                            {
                                "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Notifications",
                                "_sortIndex": 0,
                                "name": "",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "a8fabff3-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a8fabff5-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "text",
                                        "label": "Text",
                                        "dataField": "text"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a8fabff6-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a8fabff8-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "noticeStart",
                                        "label": "Notice Start",
                                        "dataField": "noticeStart",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a8fabff9-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a8fabffb-912e-11ea-ad57-6526d978cdf6",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "noticeEnd",
                                        "label": "Notice End",
                                        "dataField": "noticeEnd",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a8fabfff-912e-11ea-ad57-6526d978cdf6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "219108b0-f0a3-11ea-a584-176526b5be96",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "homeowner",
                                        "label": "Homeowner",
                                        "options": "",
                                        "name": "",
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "saveObject": true,
                                        "nullable": true
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    },
                    {
                        "id": "a8f7b2b0-912e-11ea-ad57-6526d978cdf6",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "notification-detail-page",
                "route": "/notifications/:id",
                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                "_sortIndex": 480,
                "access": "admin,pmg"
            },
            {
                "id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf7",
                        "children": [
                            {
                                "id": "a9012890-912e-11ea-ad57-6526d978cdf6",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "a9045ce0-912e-11ea-ad57-6526d978cdf6",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "b9b72450-2490-11eb-8324-69ec80c4d07e",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Created",
                                        "date": true,
                                        "width": "15"
                                    },
                                    {
                                        "id": "a907b840-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "homeowner",
                                        "label": "Sent To",
                                        "dataField": "homeowner",
                                        "date": false,
                                        "format": "",
                                        "width": ""
                                    },
                                    {
                                        "id": "a907b842-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "",
                                        "label": "Content",
                                        "dataField": "text",
                                        "width": "50"
                                    },
                                    {
                                        "id": "a907b844-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "noticeStart",
                                        "label": "noticeStart",
                                        "dataField": "noticeStart",
                                        "date": true
                                    },
                                    {
                                        "id": "a907b846-912e-11ea-ad57-6526d978cdf6",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "noticeEnd",
                                        "label": "noticeEnd",
                                        "dataField": "noticeEnd",
                                        "date": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "access": ""
                            }
                        ],
                        "component": "AllNotifications"
                    }
                ],
                "orientation": "vertical",
                "name": "notification-list-page",
                "route": "/notifications-list",
                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                "_sortIndex": 490,
                "component": ""
            },
            {
                "id": "61855560-e82a-11ea-bffd-65b1c6e24159",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                        "children": [
                            {
                                "id": "a5ad4300-f0af-11ea-a61b-49eea0a1bb78",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Delivery",
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            },
                            {
                                "id": "61a11ac0-e82a-11ea-bffd-65b1c6e24159",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6e9301c0-fdd9-11ea-810b-8f97cf48f34d",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "unit",
                                        "name": "unit",
                                        "label": "Unit",
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "61a141d0-e82a-11ea-bffd-65b1c6e24159",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f0707b30-ea53-11ea-8de6-3f209e03f86c",
                                        "type": "inputSelect",
                                        "children": [],
                                        "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                        "saveObject": true,
                                        "name": "type",
                                        "label": "Type",
                                        "dataField": "type",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            },
                            {
                                "id": "61a141d3-e82a-11ea-bffd-65b1c6e24159",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "82f752a0-fdc6-11ea-a2b5-65e9923dd687",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "name": "schedule",
                                        "dataField": "schedule",
                                        "label": "Schedule",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20
                            },
                            {
                                "id": "61a141d6-e82a-11ea-bffd-65b1c6e24159",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9be1f780-fdca-11ea-a5e4-656dbac2217a",
                                        "type": "inputList",
                                        "children": [],
                                        "name": "items",
                                        "label": "Items",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30
                            },
                            {
                                "id": "61a141d9-e82a-11ea-bffd-65b1c6e24159",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "61a141db-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "company",
                                        "label": "Company",
                                        "dataField": "company",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40
                            },
                            {
                                "id": "61a141dc-e82a-11ea-bffd-65b1c6e24159",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "91342c40-f0af-11ea-a61b-49eea0a1bb78",
                                        "type": "inputSelect",
                                        "children": [],
                                        "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                        "dataField": "status",
                                        "label": "Status"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 50
                            },
                            {
                                "id": "61a141df-e82a-11ea-bffd-65b1c6e24159",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "61a141e1-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "multiline": true,
                                        "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 60
                            }
                        ]
                    },
                    {
                        "id": "619ad930-e82a-11ea-bffd-65b1c6e24159",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "pass-delivery-edit-page",
                "route": "/pass-deliveries/:id",
                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                "access": "admin,pmg"
            },
            {
                "id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                        "children": [
                            {
                                "id": "61ad9de0-e82a-11ea-bffd-65b1c6e24159",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "61b34330-e82a-11ea-bffd-65b1c6e24159",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "0909f810-01ec-11eb-81b5-f15d97fd1729",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "date": true
                                    },
                                    {
                                        "id": "61ba4810-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit"
                                    },
                                    {
                                        "id": "61ba4812-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "type",
                                        "label": "Type",
                                        "dataField": "type"
                                    },
                                    {
                                        "id": "61ba4814-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "schedule",
                                        "label": "Schedule",
                                        "dataField": "schedule",
                                        "date": true,
                                        "format": "MM/dd/yyyy hh:mm a"
                                    },
                                    {
                                        "id": "61ba4818-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "company",
                                        "label": "Company",
                                        "dataField": "company"
                                    },
                                    {
                                        "id": "61ba6f20-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status"
                                    },
                                    {
                                        "id": "61ba6f22-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/pass-deliveries-list/detail"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "pass-delivery-list-page",
                "route": "/pass-deliveries-list",
                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                "params": "{\"or:\":[{\"status\": \"pending\"},{\"status\": \"approved\"}]}"
            },
            {
                "id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                        "children": [
                            {
                                "id": "c4927940-f0b2-11ea-99d0-d9f36ea6d180",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Visitor / Tenant Passes",
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            },
                            {
                                "id": "3fb18ad0-df4c-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3fb18ad2-df4c-11ea-a4a7-a1e8c04129ca",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit",
                                        "saveObject": true,
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3fb18ad9-df4c-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d5c38580-ea52-11ea-8de6-3f209e03f86c",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "type",
                                        "label": "Type",
                                        "dataField": "type",
                                        "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3fb18adc-df4c-11ea-a4a7-a1e8c04129ca",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e8041160-ea52-11ea-8de6-3f209e03f86c",
                                        "type": "subView",
                                        "children": [],
                                        "view": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                                        "name": "homeowner-visitor-pass-visitor-pass"
                                    },
                                    {
                                        "id": "8b0b9a40-ea53-11ea-8de6-3f209e03f86c",
                                        "type": "subView",
                                        "children": [],
                                        "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                        "name": "homeowner-visitor-pass-long-term"
                                    },
                                    {
                                        "id": "983a1570-ea53-11ea-8de6-3f209e03f86c",
                                        "type": "subView",
                                        "children": [],
                                        "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                        "name": "homeowner-visitor-pass-short-term"
                                    }
                                ],
                                "orientation": "horizontal",
                                "component": "VisitorPass",
                                "name": "homeowner-visitor-pass"
                            },
                            {
                                "id": "1f8ccac0-f726-11ea-80a6-010ee6be16e5",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6e8fb0b0-f726-11ea-80a6-010ee6be16e5",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "status",
                                        "label": "Status",
                                        "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]",
                                        "saveObject": true,
                                        "dataField": "status"
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": "status"
                            },
                            {
                                "id": "92fe8410-f796-11ea-8826-7fd8e10f31a2",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a2dd2b20-f796-11ea-8826-7fd8e10f31a2",
                                        "type": "inputText",
                                        "children": [],
                                        "multiline": true,
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "required": false,
                                        "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    },
                    {
                        "id": "3faafb20-df4c-11ea-a4a7-a1e8c04129ca",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "pass-visitor-edit-page",
                "route": "/pass-visitors/:id",
                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                "access": "admin,pmg"
            },
            {
                "id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                        "children": [
                            {
                                "id": "4f6bd110-f7a2-11ea-a255-11f808a5c391",
                                "type": "subView",
                                "children": [],
                                "name": "search",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "3fc22ca0-df4c-11ea-a4a7-a1e8c04129ca",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "fb4ba560-035e-11eb-ba8d-6d2ea3361677",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Date Submitted",
                                        "name": "createdAt",
                                        "date": true
                                    },
                                    {
                                        "id": "3fc62440-df4c-11ea-a4a7-a1e8c04129ca",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit"
                                    },
                                    {
                                        "id": "3fc6244a-df4c-11ea-a4a7-a1e8c04129ca",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "type",
                                        "label": "Type",
                                        "dataField": "type"
                                    },
                                    {
                                        "id": "dd3b7a00-ea4f-11ea-b967-5baa6500e444",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Start Date",
                                        "dataField": "startDate",
                                        "date": true
                                    },
                                    {
                                        "id": "e479b980-ea4f-11ea-b967-5baa6500e444",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "endDate",
                                        "text": "",
                                        "label": "End Date",
                                        "date": true
                                    },
                                    {
                                        "id": "a2920620-ea55-11ea-8de6-3f209e03f86c",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "status",
                                        "label": "Status"
                                    },
                                    {
                                        "id": "3191a6e0-1d6a-11eb-8af7-05ffba406648",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "notes",
                                        "label": "Notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/pass-visitors-list/detail"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "pass-visitor-list-page",
                "route": "/pass-visitors-list",
                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                "params": "{\"or:\":[{\"status\": \"pending\"},{\"status\": \"approved\"},{\"status\": \"checked-in\"}]}"
            },
            {
                "id": "50c11f60-ef74-11ea-8620-1757bce541ea",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "50c11f61-ef74-11ea-8620-1757bce541ea",
                        "children": [
                            {
                                "id": "7542f9f0-f0b2-11ea-99d0-d9f36ea6d179",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Payment",
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            },
                            {
                                "id": "50d17310-ef74-11ea-8620-1757bce541ea",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "50d17312-ef74-11ea-8620-1757bce541ea",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner",
                                        "saveObject": true,
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "50d17313-ef74-11ea-8620-1757bce541ea",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "50d17315-ef74-11ea-8620-1757bce541ea",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "receipt",
                                        "label": "Receipt",
                                        "dataField": "receipt"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            },
                            {
                                "id": "50d17319-ef74-11ea-8620-1757bce541ea",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "50d1731b-ef74-11ea-8620-1757bce541ea",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "postingDate",
                                        "label": "Posting Date",
                                        "dataField": "postingDate",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30
                            },
                            {
                                "id": "3b2e9910-f669-11ea-a7eb-5542a4efbc2d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3e00a4d0-f669-11ea-a7eb-5542a4efbc2d",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "statement",
                                        "label": "Statement"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "_sortIndex": 0
                    },
                    {
                        "id": "7a7eda40-ef74-11ea-b56c-03bb3a7ec541",
                        "type": "subView",
                        "children": [],
                        "view": "927fbf90-ef71-11ea-ae43-d3d081a35642",
                        "_sortIndex": 10,
                        "relation": "payment"
                    },
                    {
                        "id": "50cb3180-ef74-11ea-8620-1757bce541ea",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                        "_sortIndex": 30
                    }
                ],
                "orientation": "vertical",
                "name": "payment-detail-page",
                "route": "/payments/:id",
                "dataModel": "e682f860-ef70-11ea-983b-e7b329586acc",
                "access": "accounting"
            },
            {
                "id": "9273b1a0-ef71-11ea-ae43-d3d081a35642",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "9273b1a1-ef71-11ea-ae43-d3d081a35642",
                        "children": [
                            {
                                "id": "86c58d00-f0b2-11ea-99d0-d9f36ea6d179",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Payment",
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            },
                            {
                                "id": "927bef00-ef71-11ea-ae43-d3d081a35642",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "927bef02-ef71-11ea-ae43-d3d081a35642",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "payment",
                                        "label": "Payment",
                                        "dataField": "payment",
                                        "saveObject": true,
                                        "relation": "555659e0-9138-11ea-bd78-399a56c40918"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "927bef03-ef71-11ea-ae43-d3d081a35642",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "927bef05-ef71-11ea-ae43-d3d081a35642",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "item",
                                        "label": "Item",
                                        "dataField": "item"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            },
                            {
                                "id": "927bef06-ef71-11ea-ae43-d3d081a35642",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "927bef08-ef71-11ea-ae43-d3d081a35642",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20
                            },
                            {
                                "id": "927bef09-ef71-11ea-ae43-d3d081a35642",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "927bef0b-ef71-11ea-ae43-d3d081a35642",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "amount",
                                        "label": "Amount",
                                        "dataField": "amount"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30
                            },
                            {
                                "id": "927bef0c-ef71-11ea-ae43-d3d081a35642",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "927bef0e-ef71-11ea-ae43-d3d081a35642",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "state",
                                        "label": "State",
                                        "dataField": "state"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40
                            }
                        ]
                    },
                    {
                        "id": "927893a0-ef71-11ea-ae43-d3d081a35642",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "payment-item-detail-page",
                "route": "/payment-items/:id",
                "dataModel": "24ee6e90-ef71-11ea-ae43-d3d081a35642"
            },
            {
                "id": "50d606f0-ef74-11ea-8620-1757bce541eb",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "50d606f0-ef74-11ea-8620-1757bce541ec",
                        "children": [
                            {
                                "id": "19d6c0b0-f65c-11ea-bcea-e53105b58e3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1e879e90-f65c-11ea-bcea-e53105b58e3f",
                                        "type": "button",
                                        "children": [],
                                        "command": "new_payment",
                                        "text": "upload"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "accounting"
                            },
                            {
                                "id": "e727bc30-01ed-11eb-81b5-f15d97fd1729",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ea6f1f00-01ed-11eb-81b5-f15d97fd1729",
                                        "type": "subView",
                                        "children": [],
                                        "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
                                        "component": "Searchbar",
                                        "name": "searchbar"
                                    }
                                ],
                                "orientation": "horizontal",
                                "component": "",
                                "name": ""
                            },
                            {
                                "id": "50e10370-ef74-11ea-8620-1757bce541ea",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "bbf57030-f65c-11ea-bcea-e53105b58e3f",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "receipt",
                                        "label": "Receipt"
                                    },
                                    {
                                        "id": "c247f020-f65c-11ea-bcea-e53105b58e3f",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "homeowner",
                                        "label": "Homeowner"
                                    },
                                    {
                                        "id": "c84ec110-f65c-11ea-bcea-e53105b58e3f",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "statement",
                                        "label": "Statement"
                                    },
                                    {
                                        "id": "2d7961b0-f669-11ea-a7eb-5542a4efbc2d",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Posting Date",
                                        "dataField": "postingDate",
                                        "date": true
                                    },
                                    {
                                        "id": "db6384b0-2eb6-11eb-8f48-9f702d09244d",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "amount",
                                        "label": "Amt Paid",
                                        "currency": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table"
                            }
                        ],
                        "component": "PaymentUpload"
                    }
                ],
                "orientation": "vertical",
                "name": "payment-list-page",
                "route": "/payments-list",
                "dataModel": "e682f860-ef70-11ea-983b-e7b329586acc"
            },
            {
                "id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                        "children": [
                            {
                                "id": "c4927940-f0b2-11ea-99d0-d9f36ea6d179",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Terms and Conditions",
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            },
                            {
                                "id": "5aa647c0-e507-11ea-8276-65e120bbe853",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5aa647c2-e507-11ea-8276-65e120bbe853",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "name",
                                        "label": "Name",
                                        "dataField": "name"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "5aa647c3-e507-11ea-8276-65e120bbe853",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5aa66ed0-e507-11ea-8276-65e120bbe853",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "content",
                                        "label": "Content",
                                        "dataField": "content",
                                        "multiline": true,
                                        "line": "20"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            }
                        ]
                    },
                    {
                        "id": "5aa1daf0-e507-11ea-8276-65e120bbe853",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "terms-conditions-detail-page",
                "route": "/terms-conditions/:id",
                "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853",
                "access": "admin,pmg"
            },
            {
                "id": "5aabc600-e507-11ea-8276-65e120bbe853",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                        "children": [
                            {
                                "id": "5ab16b50-e507-11ea-8276-65e120bbe853",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "5ab6e990-e507-11ea-8276-65e120bbe853",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "5ababa20-e507-11ea-8276-65e120bbe853",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "name",
                                        "label": "Name",
                                        "dataField": "name"
                                    },
                                    {
                                        "id": "5ababa22-e507-11ea-8276-65e120bbe853",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "content",
                                        "label": "Content",
                                        "dataField": "content"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "terms-conditions-list-page",
                "route": "/terms-conditions-list",
                "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853"
            },
            {
                "id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                        "children": [
                            {
                                "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Units",
                                "_sortIndex": 0,
                                "name": "",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "0824d8f0-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "0824d8f2-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "number",
                                        "label": "Unit Number",
                                        "dataField": "number"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "0824d8f6-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b50bf760-12ab-11eb-a859-31a630fb4ca4",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "type",
                                        "label": "Type",
                                        "dataField": "type",
                                        "options": "[{\"value\":\"Studio\", \"label\": \"Studio\"},{\"value\":\"One Bedroom\", \"label\": \"One Bedroom\"},{\"value\":\"Two Bedroom\", \"label\": \"Two Bedroom\"}, {\"value\":\"Parking\", \"label\": \"Parking\"}]",
                                        "saveObject": true,
                                        "placeholder": "",
                                        "preselected": false
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "40a367e0-edd1-11ea-993f-a977bcf36a2d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "56825030-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "areaSize",
                                        "label": "Floor Area (sqm)",
                                        "dataField": "areaSize"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "",
                                "dataField": ""
                            },
                            {
                                "id": "6208e7c0-edd1-11ea-993f-a977bcf36a2d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1902f3a0-12b0-11eb-a48f-8f1c197a5101",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "classification",
                                        "dataField": "classification",
                                        "label": "Classification",
                                        "options": "[{\"value\":\"leased\", \"label\": \"Leased\"},{\"value\":\"own-use\", \"label\": \"Own Use\"},{\"value\":\"rented-out\", \"label\": \"Rented Out\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "773fa930-edd1-11ea-993f-a977bcf36a2d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "7942cc30-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "projectCode",
                                        "name": "projectCode",
                                        "label": "Project Code"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "9dcb8510-edd1-11ea-993f-a977bcf36a2d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b88f0570-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "customerCode",
                                        "label": "Customer Code",
                                        "dataField": "customerCode"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c24f5bf0-edd1-11ea-993f-a977bcf36a2d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d57a6ee0-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "turnoverDate",
                                        "label": "Turnover Date",
                                        "dataField": "turnoverDate"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": ""
                            },
                            {
                                "id": "f37a2e80-edd1-11ea-993f-a977bcf36a2d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c11401b0-12b0-11eb-a48f-8f1c197a5101",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "homeowner",
                                        "dataField": "homeowner",
                                        "label": "Homeowner",
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": ""
                            },
                            {
                                "id": "71d63580-ef99-11ea-9d3f-8def54ed8352",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "dc08f230-ef99-11ea-9d3f-8def54ed8352",
                                        "type": "header",
                                        "children": [],
                                        "size": "4",
                                        "text": "Date of Last Service"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "e7e17a50-ef99-11ea-9d3f-8def54ed8352",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f38277b0-ef99-11ea-9d3f-8def54ed8352",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "greaseTrapCleaning",
                                        "label": "Grease Trap Cleaning",
                                        "dataField": "greaseTrapCleaning"
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": "",
                                "name": ""
                            },
                            {
                                "id": "fc7ce620-ef99-11ea-9d3f-8def54ed8352",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fe46a9a0-ef99-11ea-9d3f-8def54ed8352",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "airconCleaning",
                                        "label": "A/C Cleaning",
                                        "dataField": "airconCleaning"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    },
                    {
                        "id": "081fa8d0-d84f-11ea-acd1-c1cd20d0424a",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "unit-detail-page",
                "route": "/units/:id",
                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                "_sortIndex": 520,
                "access": "admin,accounting,pmg"
            },
            {
                "id": "0829e200-d84f-11ea-acd1-c1cd20d0424b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "0829e200-d84f-11ea-acd1-c1cd20d0424c",
                        "children": [
                            {
                                "id": "2524edb0-f65d-11ea-87ea-87e5ac731b3a",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "29d640c0-f65d-11ea-87ea-87e5ac731b3a",
                                        "type": "button",
                                        "children": [],
                                        "text": "Upload",
                                        "command": "upload_unit"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,accounting,pmg"
                            },
                            {
                                "id": "08310df0-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "08341b30-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "0838fd30-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "Number",
                                        "label": "Unit Number",
                                        "dataField": "number"
                                    },
                                    {
                                        "id": "c86067a0-f0a6-11ea-a584-176526b5be96",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Customer Code",
                                        "dataField": "customerCode"
                                    },
                                    {
                                        "id": "ca3834e0-f0a6-11ea-a584-176526b5be96",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "homeowner",
                                        "label": "Homeowner"
                                    },
                                    {
                                        "id": "fd718c50-f65d-11ea-87ea-87e5ac731b3a",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "greaseTrapCleaning",
                                        "text": "",
                                        "label": "Grease Trap Cleaning",
                                        "date": true
                                    },
                                    {
                                        "id": "08ac26c0-f65e-11ea-87ea-87e5ac731b3a",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "A/C Cleaning",
                                        "dataField": "airconCleaning",
                                        "date": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table"
                            }
                        ],
                        "component": "UnitUpload"
                    }
                ],
                "orientation": "vertical",
                "name": "unit-list-page",
                "route": "/units-list",
                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                "_sortIndex": 530,
                "component": ""
            },
            {
                "id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                        "children": [
                            {
                                "id": "1d1b3ee0-167d-11eb-83c3-eb7956ee1f3f",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered",
                                "text": "Unit Request"
                            },
                            {
                                "id": "09c7e540-ea50-11ea-b967-5baa6500e444",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4648f090-ea50-11ea-b967-5baa6500e444",
                                        "type": "subView",
                                        "children": [],
                                        "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                        "name": "homeowner-unit-request-aircon-cleaning"
                                    },
                                    {
                                        "id": "4fa8fea0-ea50-11ea-b967-5baa6500e444",
                                        "type": "subView",
                                        "children": [],
                                        "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                        "name": "homeowner-unit-request-grease-trap-cleaning"
                                    },
                                    {
                                        "id": "53a42110-ea50-11ea-b967-5baa6500e444",
                                        "type": "subView",
                                        "children": [
                                            {
                                                "id": "1c2a9a91-ea0d-11ea-b95d-1388b7bed108",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "1c2a9a93-ea0d-11ea-b95d-1388b7bed108",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "status",
                                                        "label": "status",
                                                        "dataField": "status"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                                        "name": "homeowner-unit-request-others"
                                    },
                                    {
                                        "id": "570d15a0-ea50-11ea-b967-5baa6500e444",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                        "name": "homeowner-unit-request-plans-documents"
                                    },
                                    {
                                        "id": "5c271590-ea50-11ea-b967-5baa6500e444",
                                        "type": "subView",
                                        "children": [],
                                        "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                        "name": "homeowner-unit-request-refund-renovation-bond"
                                    },
                                    {
                                        "id": "60d49810-ea50-11ea-b967-5baa6500e444",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                        "name": "homeowner-unit-request-service-request"
                                    },
                                    {
                                        "id": "696aed80-ea50-11ea-b967-5baa6500e444",
                                        "type": "subView",
                                        "children": [],
                                        "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                        "name": "homeowner-unit-request-vehicle-sticker"
                                    },
                                    {
                                        "id": "a3401d40-ea51-11ea-b2b9-6908f6182fd8",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ac059900-ea51-11ea-b2b9-6908f6182fd8",
                                                "type": "inputSelect",
                                                "children": [],
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "dataField": "unit",
                                                "name": "unit",
                                                "label": "Unit",
                                                "required": false,
                                                "disabled": false
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "unit-container"
                                    },
                                    {
                                        "id": "ba232f20-ea51-11ea-b2b9-6908f6182fd8",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "bdbecc70-ea51-11ea-b2b9-6908f6182fd8",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "type",
                                                "label": "Type",
                                                "dataField": "type",
                                                "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Other Unit Request\", \"value\":\"others\"}]",
                                                "saveObject": true,
                                                "required": false,
                                                "disabled": false
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "type-container"
                                    }
                                ],
                                "orientation": "horizontal",
                                "component": "UnitRequest",
                                "name": "homeowner-unit-request"
                            },
                            {
                                "id": "1c2a9a97-ea0d-11ea-b95d-1388b7bed108",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "83cd7850-f0d6-11ea-9422-3905f8832c96",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "status",
                                        "label": "Status",
                                        "saveObject": true,
                                        "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]",
                                        "name": "status"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "8ca0afb0-f0d6-11ea-9422-3905f8832c96",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "94d5d250-f0d6-11ea-9422-3905f8832c96",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "notes",
                                        "label": "Notes",
                                        "name": "notes",
                                        "multiline": true,
                                        "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": ""
                            }
                        ]
                    },
                    {
                        "id": "1c254360-ea0d-11ea-b95d-1388b7bed108",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "unit-request-edit-page",
                "route": "/unit-requests/:id",
                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                "access": "admin,pmg"
            },
            {
                "id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                        "children": [
                            {
                                "id": "1c3b3c60-ea0d-11ea-b95d-1388b7bed108",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "1c4156e0-ea0d-11ea-b95d-1388b7bed108",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "c310a650-01ec-11eb-81b5-f15d97fd1729",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "date": true
                                    },
                                    {
                                        "id": "4d284ab0-f797-11ea-a0fb-935a3c1bc52c",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "unit",
                                        "label": "Unit",
                                        "name": "unit"
                                    },
                                    {
                                        "id": "55bbb9f0-f797-11ea-a0fb-935a3c1bc52c",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "type",
                                        "name": "type",
                                        "label": "Type"
                                    },
                                    {
                                        "id": "5eb516f0-f797-11ea-a0fb-935a3c1bc52c",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "status",
                                        "name": "status",
                                        "text": "",
                                        "label": "Status"
                                    },
                                    {
                                        "id": "68a270e0-f797-11ea-a0fb-935a3c1bc52c",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "notes",
                                        "dataField": "notes",
                                        "label": "Notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/unit-requests/detail",
                                "query": ""
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "unit-request-list-page",
                "route": "/unit-requests-list",
                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                "params": "{\"status\": \"pending\"}"
            },
            {
                "id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                        "children": [
                            {
                                "id": "bb226d00-f0af-11ea-a61b-49eea0a1bb78",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Work Permit",
                                "_sortIndex": -1,
                                "className": "has-text-centered"
                            },
                            {
                                "id": "47bfd670-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "47bfd672-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit",
                                        "saveObject": true,
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "47bfd673-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "708a9ee0-05e3-11eb-8eea-bf73dbff533a",
                                        "type": "inputSelect",
                                        "children": [],
                                        "saveObject": true,
                                        "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                        "label": "Nature of Work",
                                        "dataField": "natureOfWork"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            },
                            {
                                "id": "47bfd676-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "47bfd678-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "contractor",
                                        "label": "Contractor",
                                        "dataField": "contractor",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20
                            },
                            {
                                "id": "47bfd679-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "47bfd67b-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "personInCharge",
                                        "label": "Person In Charge",
                                        "dataField": "personInCharge",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30
                            },
                            {
                                "id": "47bfd67c-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "47bffd81-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "contactPersonInCharge",
                                        "label": "Contact Number of Person-in-Charge",
                                        "dataField": "contactPersonInCharge",
                                        "required": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40
                            },
                            {
                                "id": "47bffd82-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e7ec4310-e01f-11ea-affb-21f86f398b19",
                                        "type": "inputList",
                                        "children": [],
                                        "dataField": "workers",
                                        "label": "Workers",
                                        "name": "workers",
                                        "placeholder": "Worker"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 50
                            },
                            {
                                "id": "47bffd85-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "47bffd87-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 60
                            },
                            {
                                "id": "47bffd88-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f46db560-e01f-11ea-affb-21f86f398b19",
                                        "type": "inputList",
                                        "children": [],
                                        "dataField": "materials",
                                        "name": "materials",
                                        "label": "Materials"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 80
                            },
                            {
                                "id": "47bffd8b-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "85e80220-ef9d-11ea-82b2-a7421559c7b2",
                                        "type": "inputList",
                                        "children": [],
                                        "name": "",
                                        "label": "Tools",
                                        "dataField": "tools",
                                        "placeholder": "Tool"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 90
                            },
                            {
                                "id": "47bffd8e-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "47bffd90-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "startDate",
                                        "label": "Start Date",
                                        "dataField": "startDate",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 100
                            },
                            {
                                "id": "47bffd91-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "47bffd93-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "endDate",
                                        "label": "End Date",
                                        "dataField": "endDate",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 110
                            },
                            {
                                "id": "47bffd94-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "47bffd96-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "plans",
                                        "label": "Plans",
                                        "dataField": "plans",
                                        "basePath": "work-permits"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 120
                            },
                            {
                                "id": "f039f7a0-f795-11ea-8826-7fd8e10f31a2",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "07cef460-f796-11ea-8826-7fd8e10f31a2",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status",
                                        "saveObject": true,
                                        "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "8bdb91a0-e828-11ea-99d9-7b0e0405473d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "8f530520-e828-11ea-99d9-7b0e0405473d",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "dataField": "notes",
                                        "label": "Notes",
                                        "multiline": true,
                                        "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 130
                            }
                        ]
                    },
                    {
                        "id": "47b79910-df4a-11ea-96aa-1f18fc6e6b6c",
                        "type": "subView",
                        "children": [],
                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                    }
                ],
                "orientation": "vertical",
                "name": "work-permit-edit-page",
                "route": "/work-permits/:id",
                "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                "_sortIndex": 730,
                "access": "admin,pmg"
            },
            {
                "id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                        "children": [
                            {
                                "id": "47ce7c70-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "47d421c0-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "382ac9d0-01ec-11eb-81b5-f15d97fd1729",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "date": true
                                    },
                                    {
                                        "id": "47d9c710-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit"
                                    },
                                    {
                                        "id": "47d9c712-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "natureOfWork",
                                        "label": "Nature of Work",
                                        "dataField": "natureOfWork"
                                    },
                                    {
                                        "id": "47d9c714-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "contractor",
                                        "label": "Contractor",
                                        "dataField": "contractor"
                                    },
                                    {
                                        "id": "cb309910-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "personInCharge",
                                        "label": "Person In Charge"
                                    },
                                    {
                                        "id": "d9bb9700-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "contactPersonInCharge",
                                        "name": "",
                                        "label": "Contact Number of Person-in-Charge"
                                    },
                                    {
                                        "id": "e4db2f60-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "dataField": "startDate",
                                        "label": "Start Date",
                                        "date": true
                                    },
                                    {
                                        "id": "ef9aa660-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "text": "",
                                        "label": "End Date",
                                        "dataField": "endDate",
                                        "date": true
                                    },
                                    {
                                        "id": "f83ae6e0-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Status",
                                        "dataField": "status"
                                    },
                                    {
                                        "id": "fdb07450-f06e-11ea-9272-7b40a4812ca5",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/work-permits/detail"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "work-permit-list-page",
                "route": "/work-permits-list",
                "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                "_sortIndex": 740,
                "params": "{\"or:\":[{\"status\": \"pending\"},{\"status\": \"approved\"}]}"
            },
            {
                "id": "3acc2620-e865-11ea-9def-530e14f0f646",
                "type": "view",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                        "children": [
                            {
                                "id": "3ad3ee50-e865-11ea-9def-530e14f0f646",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "3ad7e5f0-e865-11ea-9def-530e14f0f646",
                                        "type": "column",
                                        "children": [
                                            {
                                                "id": "501d2fe0-e871-11ea-808a-85e6d8103902",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "item",
                                                "label": "Item",
                                                "name": "item"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "item",
                                        "label": "Item",
                                        "dataField": "item"
                                    },
                                    {
                                        "id": "3ad7e5f2-e865-11ea-9def-530e14f0f646",
                                        "type": "column",
                                        "children": [
                                            {
                                                "id": "5279e490-e871-11ea-808a-85e6d8103902",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "description",
                                                "label": "Description",
                                                "name": "description"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description"
                                    },
                                    {
                                        "id": "3ad7e5f4-e865-11ea-9def-530e14f0f646",
                                        "type": "column",
                                        "children": [
                                            {
                                                "id": "56685cd0-e871-11ea-808a-85e6d8103902",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "amount",
                                                "label": "Amount"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "amount",
                                        "label": "Amount",
                                        "dataField": "amount",
                                        "currency": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "component": "",
                                "command": "",
                                "path": "/billing-items"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "billing-item-list-page",
                "route": "/billing-items-list",
                "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
            },
            {
                "type": "view",
                "name": "commandbar",
                "orientation": "horizontal",
                "children": [
                    {
                        "id": "07107b70-1226-11eb-8323-55a37c955b67",
                        "type": "container",
                        "children": [
                            {
                                "name": "save",
                                "type": "button",
                                "id": "e7db5105-8c4a-11ea-9466-6f383d5eb483",
                                "command": "save"
                            },
                            {
                                "name": "delete",
                                "type": "button",
                                "id": "e7db5106-8c4a-11ea-9466-6f383d5eb483",
                                "command": "delete"
                            }
                        ],
                        "orientation": "vertical",
                        "component": "ActionBar"
                    }
                ],
                "id": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                "active": false,
                "_sortIndex": 560
            },
            {
                "id": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                "type": "view",
                "children": [
                    {
                        "id": "7ee9d700-8ef4-11ea-9b90-bfc3ee1ce33c",
                        "type": "menu",
                        "children": [
                            {
                                "id": "3cf81350-9130-11ea-af3e-29e246413619",
                                "type": "menuItem",
                                "children": [],
                                "_sortIndex": 0,
                                "path": "/app",
                                "label": "Main Menu"
                            },
                            {
                                "id": "e86c29e0-912d-11ea-82bc-71c30f9d69df",
                                "type": "menuItem",
                                "children": [
                                    {
                                        "id": "e66c2680-05e1-11eb-88d0-f3dc124fad2d",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Archives",
                                        "path": "/pass-visitors-list/archive"
                                    }
                                ],
                                "path": "/pass-visitors-list",
                                "label": "Visitor / Tenant Passes",
                                "_sortIndex": 10,
                                "access": "admin,pmg,pmg-ops,pmg-security",
                                "name": "pass-visitors"
                            },
                            {
                                "id": "e6566940-912d-11ea-82bc-71c30f9d69df",
                                "type": "menuItem",
                                "children": [
                                    {
                                        "id": "f46e6c70-05e1-11eb-88d0-f3dc124fad2d",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Archives",
                                        "path": "/pass-deliveries-list/archive"
                                    }
                                ],
                                "path": "/pass-deliveries-list",
                                "label": "Delivery / Pullout Passes",
                                "_sortIndex": 20,
                                "access": "admin,pmg,pmg-ops,pmg-security",
                                "name": "pass-deliveries"
                            },
                            {
                                "id": "875d7be0-dd59-11ea-878d-279a484325f4",
                                "type": "menuItem",
                                "children": [
                                    {
                                        "id": "f79b6970-05e1-11eb-88d0-f3dc124fad2d",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Archives",
                                        "path": "/work-permits-list/archive"
                                    }
                                ],
                                "label": "Work Permits",
                                "path": "/work-permits-list",
                                "_sortIndex": 30,
                                "access": "admin,pmg,pmg-ops,pmg-security",
                                "name": "work-permits"
                            },
                            {
                                "id": "9c3e1dc0-914c-11ea-b5d1-292f71dadca1",
                                "type": "menuItem",
                                "children": [
                                    {
                                        "id": "fb1044e0-05e1-11eb-88d0-f3dc124fad2d",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Archives",
                                        "path": "/unit-requests-list/archive"
                                    }
                                ],
                                "path": "/unit-requests-list",
                                "label": "Unit Requests",
                                "_sortIndex": 40,
                                "access": "admin,pmg,pmg-ops,pmg-security,accounting,accounting-staff",
                                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                                "name": "unit-requests"
                            },
                            {
                                "id": "1854b570-914f-11ea-b2e4-0783314da7b1",
                                "type": "menuItem",
                                "children": [],
                                "path": "/comments-suggestions-list",
                                "label": "Comments & Suggestions",
                                "_sortIndex": 50,
                                "component": "",
                                "access": "admin,pmg,pmg-ops",
                                "name": "comments-suggestions"
                            },
                            {
                                "id": "d72baba0-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "menuItem",
                                "children": [
                                    {
                                        "id": "002cdce0-05e2-11eb-88d0-f3dc124fad2d",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Archives",
                                        "path": "/message-boards-list/archive"
                                    }
                                ],
                                "path": "/message-boards-list",
                                "label": "Message Board",
                                "_sortIndex": 60,
                                "access": "admin,pmg,pmg-ops",
                                "name": "message-boards"
                            },
                            {
                                "id": "e3379d10-912d-11ea-82bc-71c30f9d69df",
                                "type": "menuItem",
                                "children": [
                                    {
                                        "id": "6aa988a0-2704-11eb-ac91-c74c3e25a4bd",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Archives",
                                        "path": "/notifications-list/archive"
                                    }
                                ],
                                "path": "/notifications-list",
                                "label": "Notifications",
                                "_sortIndex": 70,
                                "access": "admin,pmg,pmg-ops,accounting,accounting-staff"
                            },
                            {
                                "id": "7abd8be0-9138-11ea-bd78-399a56c40918",
                                "type": "menuItem",
                                "children": [
                                    {
                                        "id": "818631e0-2704-11eb-ac91-c74c3e25a4bd",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Archives",
                                        "path": "/billings-list/archive"
                                    }
                                ],
                                "path": "/billings-list",
                                "label": "Billings",
                                "_sortIndex": 80,
                                "access": "admin,accounting,accounting-staff,pmg"
                            },
                            {
                                "id": "68e00f40-f65c-11ea-bcea-e53105b58e3f",
                                "type": "menuItem",
                                "children": [],
                                "path": "/payments-list",
                                "label": "Payments",
                                "access": "admin,accounting,accounting-staff,pmg"
                            },
                            {
                                "id": "e1041b40-912d-11ea-82bc-71c30f9d69df",
                                "type": "menuItem",
                                "children": [],
                                "path": "/homeowners-list",
                                "label": "Homeowners",
                                "_sortIndex": 90,
                                "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                            },
                            {
                                "id": "7bde5e60-dd59-11ea-878d-279a484325f4",
                                "type": "menuItem",
                                "children": [],
                                "label": "Units",
                                "path": "/units-list",
                                "_sortIndex": 100,
                                "access": "admin,accounting,accounting-staff,pmg,pmg-ops,pmg-security"
                            },
                            {
                                "id": "d8c9a170-912d-11ea-82bc-71c30f9d69df",
                                "type": "menuItem",
                                "children": [],
                                "path": "/departments-list",
                                "label": "Departments",
                                "_sortIndex": 110,
                                "access": "admin"
                            },
                            {
                                "id": "df5755a0-912d-11ea-82bc-71c30f9d69df",
                                "type": "menuItem",
                                "children": [],
                                "path": "/employees-list",
                                "label": "Employees",
                                "_sortIndex": 120,
                                "name": "",
                                "access": "admin"
                            },
                            {
                                "id": "9ebd1610-e7f4-11ea-b892-0f30d25d09fd",
                                "type": "menuItem",
                                "children": [],
                                "path": "/terms-conditions-list",
                                "label": "Terms and Conditions",
                                "_sortIndex": 130,
                                "access": "admin,pmg"
                            },
                            {
                                "id": "fbb2fb30-f0b1-11ea-99d0-d9f36ea6d179",
                                "type": "menuItem",
                                "children": [],
                                "path": "/activity-logs-list",
                                "label": "Activity Log",
                                "_sortIndex": 151,
                                "access": "admin"
                            },
                            {
                                "id": "fef31bf0-dfa3-11ea-a94d-e5d6a6ca6c3b",
                                "type": "menuItem",
                                "children": [],
                                "path": "/dashboard-login",
                                "label": "Logout",
                                "_sortIndex": 160
                            }
                        ],
                        "name": "",
                        "className": "dashboard-sidebar"
                    }
                ],
                "orientation": "vertical",
                "name": "dashboard-sidebar",
                "active": false,
                "_sortIndex": 570
            },
            {
                "id": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf",
                "type": "view",
                "children": [
                    {
                        "id": "8d112830-2fb5-11eb-b456-41b261316291",
                        "type": "container",
                        "children": [
                            {
                                "id": "3ddfb0d0-2fb8-11eb-a54c-f9fdab39b42b",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Welcome"
                            },
                            {
                                "id": "9640fe80-2fb5-11eb-b456-41b261316291",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "98eac1c0-2fb5-11eb-b456-41b261316291",
                                        "type": "textBlock",
                                        "children": [],
                                        "text": "Employee No.",
                                        "className": "mr-3 profile-label"
                                    },
                                    {
                                        "id": "a5131790-2fb5-11eb-b456-41b261316291",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "employeeid"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "ac0d4930-2fb5-11eb-b456-41b261316291",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "7a7874c0-2fb6-11eb-b3b5-97b02e2ed07c",
                                        "type": "textBlock",
                                        "children": [],
                                        "text": "Name",
                                        "className": "mr-3 profile-label"
                                    },
                                    {
                                        "id": "afca7840-2fb5-11eb-b456-41b261316291",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "firstName",
                                        "className": "mr-1"
                                    },
                                    {
                                        "id": "b3fc8930-2fb5-11eb-b456-41b261316291",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "lastName"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "bbfba7b0-2fb5-11eb-b456-41b261316291",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bf5d2cc0-2fb6-11eb-b3b5-97b02e2ed07c",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "",
                                        "text": "Role",
                                        "className": "mr-3 profile-label"
                                    },
                                    {
                                        "id": "bcd0aaf0-2fb5-11eb-b456-41b261316291",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "role"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "main",
                        "component": "AccountStaff"
                    }
                ],
                "orientation": "vertical",
                "name": "dashboard-welcome",
                "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                "className": "p-2",
                "repeat": true
            },
            {
                "id": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                "type": "view",
                "children": [
                    {
                        "id": "24e082d0-8ef0-11ea-981d-018b5a72c307",
                        "type": "menu",
                        "children": [
                            {
                                "id": "26cac6a0-8ef0-11ea-981d-018b5a72c307",
                                "type": "menuItem",
                                "children": [],
                                "name": "home",
                                "_sortIndex": 0,
                                "label": "Main Menu",
                                "path": "/app"
                            },
                            {
                                "id": "13a38940-912a-11ea-a5e9-ff76f0b9af84",
                                "type": "menuItem",
                                "children": [],
                                "_sortIndex": 10,
                                "name": "account",
                                "label": "My Account",
                                "path": "/account"
                            },
                            {
                                "id": "8be32c20-060b-11eb-b5a2-dd96829f3308",
                                "type": "menuItem",
                                "children": [],
                                "label": "Notifications",
                                "path": "/notifications",
                                "name": "notifications"
                            },
                            {
                                "id": "152c2510-912a-11ea-a5e9-ff76f0b9af84",
                                "type": "menuItem",
                                "children": [],
                                "_sortIndex": 20,
                                "name": "service",
                                "label": "Unit Request",
                                "path": "/homeowner-unit-request/0"
                            },
                            {
                                "id": "17a5feb0-912a-11ea-a5e9-ff76f0b9af84",
                                "type": "menuItem",
                                "children": [],
                                "_sortIndex": 40,
                                "label": "Visitor / Tenant Passes",
                                "name": "visitor",
                                "path": "/create-visitor-pass/0"
                            },
                            {
                                "id": "19b110f0-912a-11ea-a5e9-ff76f0b9af84",
                                "type": "menuItem",
                                "children": [],
                                "_sortIndex": 50,
                                "name": "delivery",
                                "label": "Delivery / Pull-out Pass",
                                "path": "/create-delivery-pass/0"
                            },
                            {
                                "id": "58256b20-c92d-11ea-8660-8b92cdcd0efe",
                                "type": "menuItem",
                                "children": [],
                                "path": "/message-board",
                                "label": "Message Board",
                                "_sortIndex": 70
                            },
                            {
                                "id": "166eb730-912a-11ea-a5e9-ff76f0b9af84",
                                "type": "menuItem",
                                "children": [],
                                "_sortIndex": 30,
                                "label": "Comments and Suggestions",
                                "name": "complaint",
                                "path": "/homeowner-comments-suggestions/0"
                            },
                            {
                                "id": "5968bca0-d9ac-11ea-b236-955d418fe434",
                                "type": "menuItem",
                                "children": [],
                                "name": "work-permit",
                                "path": "/homeowner-work-permit/0",
                                "label": "Work Permit"
                            },
                            {
                                "id": "295939b0-8ef0-11ea-981d-018b5a72c307",
                                "type": "menuItem",
                                "children": [],
                                "name": "logout",
                                "_sortIndex": 71,
                                "label": "Logout",
                                "path": "/homeowners-login"
                            }
                        ],
                        "className": "homeowner-sidebar"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-sidebar",
                "active": false,
                "_sortIndex": 580
            },
            {
                "id": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                "type": "view",
                "children": [
                    {
                        "id": "d3e39f50-d9a6-11ea-891d-3722b00ad4e7",
                        "type": "container",
                        "children": [
                            {
                                "id": "d5efea10-d9a6-11ea-891d-3722b00ad4e7",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.units",
                                "label": "No. of Units",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "db6aa7a0-d9a6-11ea-891d-3722b00ad4e7",
                        "type": "container",
                        "children": [
                            {
                                "id": "e1bfdbe0-edcc-11ea-abfa-8f89576fb378",
                                "type": "inputDateTime",
                                "children": [],
                                "name": "meta.schedule",
                                "label": "Preferred Schedule",
                                "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "77226cd0-e7f5-11ea-b892-0f30d25d09fd",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "name": "unit-request-aircon-cleaning",
                        "component": "TermsAndConditions"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-request-aircon-cleaning",
                "_sortIndex": 590
            },
            {
                "id": "0c744880-d9a5-11ea-a304-319701533d32",
                "type": "view",
                "children": [
                    {
                        "id": "1c9ddb40-d9a5-11ea-a304-319701533d32",
                        "type": "container",
                        "children": [
                            {
                                "id": "fbbdc250-edcc-11ea-abfa-8f89576fb378",
                                "type": "inputDateTime",
                                "children": [],
                                "name": "meta.schedule",
                                "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                "label": "Preferred Schedule",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "622a6210-e7f5-11ea-b892-0f30d25d09fd",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "name": "unit-request-grease-trap-cleaning",
                        "component": "TermsAndConditions"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-request-grease-trap-cleaning",
                "_sortIndex": 600
            },
            {
                "id": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                "type": "view",
                "children": [
                    {
                        "id": "fe4688f0-d9a8-11ea-9331-090047662dd2",
                        "type": "container",
                        "children": [
                            {
                                "id": "ff497a00-d9a8-11ea-9331-090047662dd2",
                                "type": "inputText",
                                "children": [],
                                "multiline": true,
                                "name": "meta.details",
                                "label": "Please Specify",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-request-others",
                "_sortIndex": 610
            },
            {
                "id": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                "type": "view",
                "children": [
                    {
                        "id": "e85ead40-c910-11ea-9dd0-6723feee229e",
                        "type": "container",
                        "children": [
                            {
                                "id": "076a4bb0-c91e-11ea-831c-99fd7b91f646",
                                "type": "inputSelect",
                                "children": [],
                                "name": "meta.plans",
                                "label": "Plans Required",
                                "options": "[{ \"value\": \"standard\", \"label\": \"Standard Plan\"},{ \"value\": \"as-built\", \"label\": \"As-built Plan\"}]",
                                "className": "bite-select",
                                "saveObject": true,
                                "required": true
                            }
                        ],
                        "orientation": "horizontal",
                        "name": "homeowner-service-request-repairs",
                        "className": "bite-select"
                    },
                    {
                        "id": "112746d0-c91e-11ea-831c-99fd7b91f646",
                        "type": "container",
                        "children": [
                            {
                                "id": "44b19ca0-d916-11ea-9ea3-7184c3941d0d",
                                "type": "inputSelect",
                                "children": [],
                                "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                "saveObject": true,
                                "name": "meta.paper-size",
                                "label": "Paper Size",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal",
                        "component": "UnitRequestPlansDocuments"
                    },
                    {
                        "id": "97e9eee0-d916-11ea-9ea3-7184c3941d0d",
                        "type": "container",
                        "children": [
                            {
                                "id": "9a6e28c0-d916-11ea-9ea3-7184c3941d0d",
                                "type": "inputSelect",
                                "children": [],
                                "name": "meta.type",
                                "label": "Type of Plan",
                                "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing\", \"label\": \"Plumbing\"},{ \"value\": \"electrical-plan\", \"label\": \"Electrical Plan\"}]",
                                "saveObject": true,
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "e91d7600-e7f4-11ea-b892-0f30d25d09fd",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "name": "unit-request-plans-documents",
                        "component": "TermsAndConditions"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-request-plans-documents",
                "_sortIndex": 620
            },
            {
                "id": "063f7330-c911-11ea-9dd0-6723feee229e",
                "type": "view",
                "children": [
                    {
                        "id": "1cea7cc0-c91f-11ea-b910-c30a77ee18ba",
                        "type": "container",
                        "children": [
                            {
                                "id": "b0a9a300-ef9e-11ea-82b2-a7421559c7b2",
                                "type": "inputDate",
                                "children": [],
                                "name": "meta.inspection-date",
                                "label": "Preferred Inspection Date",
                                "description": "Monday to Friday, except Holidays",
                                "required": false,
                                "disabled": false
                            }
                        ],
                        "orientation": "horizontal",
                        "className": ""
                    },
                    {
                        "id": "b9eb6b30-d917-11ea-9ea3-7184c3941d0d",
                        "type": "container",
                        "children": [
                            {
                                "id": "d8830d80-ef9e-11ea-82b2-a7421559c7b2",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.additional-requests",
                                "multiline": true,
                                "label": "Additional Requests",
                                "disabled": false
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "21c2a6b0-e7f5-11ea-b892-0f30d25d09fd",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "name": "unit-request-refund-renovation-fund",
                        "component": "TermsAndConditions"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-request-refund-renovation-bond",
                "_sortIndex": 630
            },
            {
                "id": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                "type": "view",
                "children": [
                    {
                        "id": "743faf70-c90d-11ea-8c59-196c3ec4f839",
                        "type": "container",
                        "children": [
                            {
                                "id": "a9319f50-c911-11ea-98ab-e51558e3434e",
                                "type": "inputSelect",
                                "children": [],
                                "label": "Type of Work",
                                "name": "meta.type",
                                "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                                "className": "bite-select",
                                "saveObject": true,
                                "required": true
                            }
                        ],
                        "orientation": "horizontal",
                        "className": "bite-select"
                    },
                    {
                        "id": "c6681b80-c911-11ea-98ab-e51558e3434e",
                        "type": "container",
                        "children": [
                            {
                                "id": "c84dcb70-c911-11ea-98ab-e51558e3434e",
                                "type": "inputText",
                                "children": [],
                                "label": "Work Details",
                                "name": "meta.details",
                                "multiline": true,
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "cf0bde70-c911-11ea-98ab-e51558e3434e",
                        "type": "container",
                        "children": [
                            {
                                "id": "ccce1d40-edcd-11ea-993f-a977bcf36a2d",
                                "type": "inputDateTime",
                                "children": [],
                                "name": "meta.schedule",
                                "label": "Preferred Inspection/Work Schedule",
                                "description": " Monday to Friday only. Except Holidays",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal",
                        "className": "bite-select"
                    },
                    {
                        "id": "996b7430-e65b-11ea-a30a-a9b10ee3b8be",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "component": "TermsAndConditions",
                        "name": "unit-request-service-request"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-request-service-request",
                "_sortIndex": 640
            },
            {
                "id": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                "type": "view",
                "children": [
                    {
                        "id": "b33b28f0-d919-11ea-b3eb-d9cf4159b042",
                        "type": "container",
                        "children": [
                            {
                                "id": "b80cd540-d919-11ea-b3eb-d9cf4159b042",
                                "type": "inputSelect",
                                "children": [],
                                "name": "meta.make",
                                "label": "Make",
                                "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                                "saveObject": true,
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "c394f370-d919-11ea-b3eb-d9cf4159b042",
                        "type": "container",
                        "children": [
                            {
                                "id": "c6827670-d919-11ea-b3eb-d9cf4159b042",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.model",
                                "label": "Model",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "ad71c600-d9a4-11ea-a304-319701533d32",
                        "type": "container",
                        "children": [
                            {
                                "id": "b1b90ca0-d9a4-11ea-a304-319701533d32",
                                "type": "inputSelect",
                                "children": [],
                                "name": "meta.color",
                                "label": "Color",
                                "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                                "saveObject": true,
                                "required": false,
                                "disabled": false
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "c744cbe0-d9a4-11ea-a304-319701533d32",
                        "type": "container",
                        "children": [
                            {
                                "id": "cfb34e00-d9a4-11ea-a304-319701533d32",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.plate-number",
                                "label": "Plate Number",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "ddd18060-d9a4-11ea-a304-319701533d32",
                        "type": "container",
                        "children": [
                            {
                                "id": "deacec40-d9a4-11ea-a304-319701533d32",
                                "type": "inputMedia",
                                "children": [],
                                "name": "meta.OR",
                                "description": "",
                                "label": "Upload O.R. / C.R."
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "3fd52b00-e7f5-11ea-b892-0f30d25d09fd",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "name": "unit-request-vehicle-sticker",
                        "component": "TermsAndConditions"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-request-vehicle-sticker",
                "_sortIndex": 650
            },
            {
                "id": "b8fe9280-c6f5-11ea-b415-c195f50f114e",
                "type": "view",
                "children": [
                    {
                        "id": "aca19b50-c6f9-11ea-a851-fde2cfff9ce9",
                        "type": "container",
                        "children": [
                            {
                                "id": "ae765b50-c6f9-11ea-a851-fde2cfff9ce9",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Select Unit"
                            }
                        ],
                        "orientation": "horizontal",
                        "className": "columns is-centered pb-1"
                    },
                    {
                        "id": "d8bbe3d0-c6f9-11ea-a851-fde2cfff9ce9",
                        "type": "container",
                        "children": [
                            {
                                "id": "dde9e0f0-c6f9-11ea-a851-fde2cfff9ce9",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "029256d0-c6fa-11ea-a851-fde2cfff9ce9",
                                        "type": "button",
                                        "children": [],
                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 01</div>",
                                        "className": "app-menu-item",
                                        "path": "/welcome/01"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "app-menu-item-container"
                            },
                            {
                                "id": "472397a0-c6fa-11ea-a851-fde2cfff9ce9",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "53985fc0-c6fa-11ea-a851-fde2cfff9ce9",
                                        "type": "button",
                                        "children": [],
                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 02</div>",
                                        "className": "app-menu-item",
                                        "path": "/welcome/02"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "app-menu-item-container"
                            }
                        ],
                        "orientation": "horizontal",
                        "className": "columns is-centered pt-2"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-selection",
                "className": "",
                "_sortIndex": 660
            },
            {
                "id": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                "type": "view",
                "children": [
                    {
                        "id": "38c26740-da1e-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "3d14aa60-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "inputList",
                                "children": [],
                                "name": "visitor",
                                "label": "Name/s",
                                "placeholder": "Tenant",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "49eb4190-da1e-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "4b75d930-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "inputDate",
                                "children": [],
                                "name": "startDate",
                                "label": "Start Date",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "5279ced0-da1e-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "698d6700-ef9d-11ea-82b2-a7421559c7b2",
                                "type": "inputDate",
                                "children": [],
                                "name": "endDate",
                                "label": "End Date",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "5ed590b0-da1e-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "603f80f0-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "inputMedia",
                                "children": [],
                                "name": "visitorsId",
                                "label": "Upload valid ID/ Passport (required)",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "6f845900-da1e-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "710098c0-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "inputMedia",
                                "children": [],
                                "name": "meta.contract",
                                "label": "Upload Contract of Lease (required)",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "3373c0d0-e816-11ea-b937-9954e083a5ee",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "name": "visitor-pass-long-term",
                        "component": "TermsAndConditions"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-visitor-pass-long-term",
                "_sortIndex": 670
            },
            {
                "id": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                "type": "view",
                "children": [
                    {
                        "id": "a0ff15c0-da1d-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "a2036660-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "inputList",
                                "children": [],
                                "name": "visitor",
                                "label": "Name/s",
                                "placeholder": "Tenant",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "bb50c180-da1d-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "c70a7700-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "inputDate",
                                "children": [],
                                "name": "startDate",
                                "label": "Start Date",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "cdb2b810-da1d-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "d233d360-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "inputDate",
                                "children": [],
                                "name": "endDate",
                                "label": "End Date",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "e0d391d0-da1d-11ea-abba-db7aa6b9e7d7",
                        "type": "container",
                        "children": [
                            {
                                "id": "e4f28ff0-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "inputMedia",
                                "children": [],
                                "name": "visitorsId",
                                "label": "Upload Valid ID/ Passport",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "214ab490-e816-11ea-b937-9954e083a5ee",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "name": "visitor-pass-short-term",
                        "component": "TermsAndConditions"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-visitor-pass-short-term",
                "_sortIndex": 680
            },
            {
                "id": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                "type": "view",
                "children": [
                    {
                        "id": "94ab5510-da1b-11ea-87e9-9de57275fc27",
                        "type": "container",
                        "children": [
                            {
                                "id": "9c1bc820-da1b-11ea-87e9-9de57275fc27",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.relationship",
                                "label": "Relationship to Owner"
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "b7ed0f00-da1b-11ea-87e9-9de57275fc27",
                        "type": "container",
                        "children": [
                            {
                                "id": "b9749960-da1b-11ea-87e9-9de57275fc27",
                                "type": "inputList",
                                "children": [],
                                "name": "visitor",
                                "description": "",
                                "label": "Name/s",
                                "placeholder": "Visitor",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "cb6d1fc0-da1b-11ea-87e9-9de57275fc27",
                        "type": "container",
                        "children": [
                            {
                                "id": "cebbae80-da1b-11ea-87e9-9de57275fc27",
                                "type": "inputDate",
                                "children": [],
                                "name": "startDate",
                                "label": "Start Date",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "d92934f0-da1b-11ea-87e9-9de57275fc27",
                        "type": "container",
                        "children": [
                            {
                                "id": "db4adc70-da1b-11ea-87e9-9de57275fc27",
                                "type": "inputDate",
                                "children": [],
                                "name": "endDate",
                                "label": "End Date",
                                "required": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "e6edfcb0-da1b-11ea-87e9-9de57275fc27",
                        "type": "container",
                        "children": [
                            {
                                "id": "ed1bdda0-da1b-11ea-87e9-9de57275fc27",
                                "type": "inputMedia",
                                "children": [],
                                "name": "visitorsId",
                                "label": "Upload Valid ID (optional)"
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "fe8ad4d0-e815-11ea-b937-9954e083a5ee",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "name": "visitor-pass-visitor-pass",
                        "component": "TermsAndConditions"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-visitor-pass-visitor-pass",
                "_sortIndex": 690
            },
            {
                "id": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                "type": "view",
                "children": [
                    {
                        "id": "e7c4b510-e7fa-11ea-ba5e-d94241a9751c",
                        "type": "container",
                        "children": [
                            {
                                "id": "465ece40-e7fa-11ea-ba5e-d94241a9751c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "25f3f810-97d7-11ea-a9fa-07f95ad6f0c6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d37f2010-97da-11ea-a9fa-07f95ad6f0c6",
                                                "type": "button",
                                                "children": [],
                                                "path": "/account",
                                                "name": "My Account",
                                                "className": "app-menu-item",
                                                "icon": "",
                                                "text": "",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_account.svg\"><img src=\"./assets/images/icon_account.svg\" /></object>My Account</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "2ef5e090-97d7-11ea-a9fa-07f95ad6f0c6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "856e1b80-9148-11ea-a6b6-e7e287632ad5",
                                                "type": "button",
                                                "children": [],
                                                "text": "",
                                                "path": "/homeowner-unit-request/0",
                                                "flex": "[object Object]",
                                                "className": "app-menu-item",
                                                "_sortIndex": 10,
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_unit_request.svg\"><img src=\"./assets/images/icon_unit_request.svg\" /></object>Unit Request</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "38053690-97d7-11ea-a9fa-07f95ad6f0c6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                "type": "button",
                                                "children": [
                                                    {
                                                        "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "",
                                                                "path": "/create-visitor-pass/0",
                                                                "_sortIndex": 30,
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    }
                                                ],
                                                "text": "",
                                                "path": "/create-visitor-pass/0",
                                                "_sortIndex": 30,
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "8ac2b370-9148-11ea-a6b6-e7e287632ad5",
                                                "type": "button",
                                                "children": [],
                                                "text": "",
                                                "path": "/create-delivery-pass/0",
                                                "_sortIndex": 40,
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_delivery.svg\"><img src=\"./assets/images/icon_delivery.svg\" /></object>Delivery Pass</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "2290a530-c921-11ea-9848-69dad3ad605b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "2b49ebf0-c921-11ea-9848-69dad3ad605b",
                                                "type": "button",
                                                "children": [],
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_board.svg\"><img src=\"./assets/images/icon_board.svg\" /></object>Message Board</div>",
                                                "className": "app-menu-item",
                                                "path": "/message-board"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "351a2490-97d7-11ea-a9fa-07f95ad6f0c6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "874e7440-9148-11ea-a6b6-e7e287632ad5",
                                                "type": "button",
                                                "children": [],
                                                "text": "",
                                                "path": "/homeowner-comments-suggestions/0",
                                                "_sortIndex": 20,
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_complaint.svg\"><img src=\"./assets/images/icon_complaint.svg\" /></object>Comments and Suggestions</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "d7653ab0-e7f9-11ea-ba5e-d94241a9751c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "e1ab6300-e7f9-11ea-ba5e-d94241a9751c",
                                                "type": "button",
                                                "children": [],
                                                "path": "/homeowner-work-permit/0",
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_work_permit.svg\"><img src=\"./assets/images/icon_work_permit.svg\" /></object>Work Permit</div>"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "5e4c0520-ea13-11ea-a21e-f569cc759e68",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "68b4a990-ea13-11ea-a21e-f569cc759e68",
                                                "type": "button",
                                                "children": [],
                                                "className": "app-menu-item",
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_notifications.svg\"><img src=\"./assets/images/icon_notifications.svg\" /></object>Notifications</div>",
                                                "path": "/notifications",
                                                "disabled": false
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "orientation": "horizontal",
                        "className": "columns is-centered app-menus-container"
                    },
                    {
                        "id": "d4795ff0-effd-11ea-963d-316e0da90aca",
                        "type": "container",
                        "children": [],
                        "orientation": "horizontal",
                        "component": "LoginNotice"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-welcome",
                "active": false,
                "className": "column is-centered",
                "component": "",
                "_sortIndex": 700
            },
            {
                "id": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b",
                "type": "view",
                "children": [
                    {
                        "id": "bc93f270-102c-11eb-a185-f1e09385ea9b",
                        "type": "container",
                        "children": [
                            {
                                "id": "2b02be70-f0ad-11ea-a61b-49eea0a1bb78",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "38d84f10-f0ad-11ea-a61b-49eea0a1bb78",
                                        "type": "image",
                                        "children": [],
                                        "url": "./assets/images/myplv_icon.png",
                                        "className": "login-comp-logo"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": -1
                            },
                            {
                                "id": "28528d60-dfa8-11ea-9800-138672de1aa2",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6fbd65f0-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "712f9390-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "77274950-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": "1",
                                                        "className": "h1 logn-header",
                                                        "text": "Log In"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "login-header"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "row"
                                    },
                                    {
                                        "id": "7d5f3c60-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "8a375a30-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9e527270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "email",
                                                        "label": "Email",
                                                        "className": "form-control login-form-input",
                                                        "variant": "standard"
                                                    },
                                                    {
                                                        "id": "b3ea9850-dfa7-11ea-9800-138672de1aa2",
                                                        "type": "image",
                                                        "children": [],
                                                        "url": "./assets/images/icon_user.svg",
                                                        "className": "login-icons"
                                                    }
                                                ],
                                                "orientation": "vertical",
                                                "className": "col"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "row mt-4"
                                    },
                                    {
                                        "id": "c896c270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "cbd9df80-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ce124350-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "password": true,
                                                        "name": "password",
                                                        "label": "Password",
                                                        "className": "form-control login-form-input",
                                                        "variant": "standard"
                                                    },
                                                    {
                                                        "id": "fcc3d880-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0bdff2e0-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "[Forgot your password?](/#/forgot-password)"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "mt-3 forgot-password-container"
                                                    },
                                                    {
                                                        "id": "c9874780-dfa7-11ea-9800-138672de1aa2",
                                                        "type": "image",
                                                        "children": [],
                                                        "url": "./assets/images/icon_login_password.svg",
                                                        "className": "login-icons"
                                                    }
                                                ],
                                                "orientation": "vertical",
                                                "className": "col"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "row mt-4"
                                    },
                                    {
                                        "id": "10d10e10-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "15b3aa50-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "17e09c70-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "local_login",
                                                        "text": "Admin Login",
                                                        "fullWidth": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "col"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "row mt-4"
                                    }
                                ],
                                "orientation": "vertical",
                                "component": "",
                                "_sortIndex": 0
                            }
                        ],
                        "orientation": "vertical",
                        "name": "main",
                        "component": "Login"
                    }
                ],
                "orientation": "vertical",
                "name": "login-dashboard",
                "component": ""
            },
            {
                "id": "e1156440-93e9-11ea-99e4-a504a663bd48",
                "type": "view",
                "children": [
                    {
                        "id": "eb7f4134-93e9-11ea-99e4-a504a663bd48",
                        "type": "container",
                        "children": [
                            {
                                "id": "0e6c07e0-f0b1-11ea-9980-9d3390e2d189",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1a36ad50-f0b1-11ea-9980-9d3390e2d189",
                                        "type": "image",
                                        "children": [],
                                        "url": "./assets/images/myplv_icon.png",
                                        "className": "login-comp-logo"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": -1
                            },
                            {
                                "nodeType": "DIV",
                                "type": "container",
                                "className": "row",
                                "children": [
                                    {
                                        "nodeType": "DIV",
                                        "type": "container",
                                        "className": "col",
                                        "children": [
                                            {
                                                "nodeType": "H1",
                                                "type": "header",
                                                "className": "h1",
                                                "children": [],
                                                "text": "Log In",
                                                "id": "eb7f4137-93e9-11ea-99e4-a504a663bd48"
                                            }
                                        ],
                                        "id": "eb7f4136-93e9-11ea-99e4-a504a663bd48"
                                    }
                                ],
                                "id": "eb7f4135-93e9-11ea-99e4-a504a663bd48",
                                "_sortIndex": 0
                            },
                            {
                                "nodeType": "DIV",
                                "type": "container",
                                "className": "row mt-4",
                                "children": [
                                    {
                                        "nodeType": "DIV",
                                        "type": "container",
                                        "className": "col",
                                        "children": [
                                            {
                                                "nodeType": "INPUT",
                                                "type": "inputText",
                                                "className": "form-control login-form-input txt-homeowner-login",
                                                "children": [],
                                                "placeholder": "Email",
                                                "id": "eb7f413a-93e9-11ea-99e4-a504a663bd48",
                                                "name": "email",
                                                "variant": "standard"
                                            },
                                            {
                                                "id": "f6061850-ad8d-11ea-a81e-a3c623c48bc9",
                                                "type": "image",
                                                "children": [],
                                                "url": "./assets/images/icon_user.svg",
                                                "className": "login-icons"
                                            }
                                        ],
                                        "id": "eb7f4139-93e9-11ea-99e4-a504a663bd48"
                                    }
                                ],
                                "id": "eb7f4138-93e9-11ea-99e4-a504a663bd48",
                                "_sortIndex": 10
                            },
                            {
                                "nodeType": "DIV",
                                "type": "container",
                                "className": "row mt-3",
                                "children": [
                                    {
                                        "nodeType": "DIV",
                                        "type": "container",
                                        "className": "col",
                                        "children": [
                                            {
                                                "nodeType": "INPUT",
                                                "type": "inputText",
                                                "className": "form-control login-form-input txt-homeowner-login",
                                                "children": [],
                                                "placeholder": "Password",
                                                "id": "eb7f413d-93e9-11ea-99e4-a504a663bd48",
                                                "name": "password",
                                                "password": true,
                                                "variant": "standard"
                                            },
                                            {
                                                "id": "3a4be650-ad90-11ea-a81e-a3c623c48bc9",
                                                "type": "image",
                                                "children": [],
                                                "url": "./assets/images/icon_login_password.svg",
                                                "className": "login-icons"
                                            },
                                            {
                                                "id": "5c31f5f0-ad92-11ea-a2a7-ad35397c4d32",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "5f285290-ad92-11ea-a2a7-ad35397c4d32",
                                                        "type": "paragraph",
                                                        "children": [],
                                                        "text": "[Forgot your password?](/#/forgot-password)",
                                                        "_sortIndex": -1
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "mt-3 forgot-password-container",
                                                "flex": "[object Object]"
                                            }
                                        ],
                                        "id": "eb7f413c-93e9-11ea-99e4-a504a663bd48"
                                    }
                                ],
                                "id": "eb7f413b-93e9-11ea-99e4-a504a663bd48",
                                "_sortIndex": 20
                            },
                            {
                                "nodeType": "DIV",
                                "type": "container",
                                "className": "row mt-4",
                                "children": [
                                    {
                                        "nodeType": "DIV",
                                        "type": "container",
                                        "className": "col",
                                        "children": [
                                            {
                                                "id": "9ceca3a0-db42-11ea-942a-259edbe0bbf2",
                                                "type": "button",
                                                "children": [],
                                                "command": "portal_login",
                                                "text": "Login",
                                                "className": "btn-homeowner-login"
                                            }
                                        ],
                                        "id": "eb7f4140-93e9-11ea-99e4-a504a663bd48"
                                    }
                                ],
                                "id": "eb7f413f-93e9-11ea-99e4-a504a663bd48",
                                "_sortIndex": 30
                            }
                        ],
                        "orientation": "vertical",
                        "nodeType": "DIV",
                        "className": "",
                        "component": "Login"
                    }
                ],
                "orientation": "vertical",
                "name": "login-homeowner",
                "component": "",
                "_sortIndex": 710
            },
            {
                "id": "927fbf90-ef71-11ea-ae43-d3d081a35642",
                "type": "view",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "9273b1a1-ef71-11ea-ae43-d3d081a35642",
                        "children": [
                            {
                                "id": "92867650-ef71-11ea-ae43-d3d081a35642",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "92895c80-ef71-11ea-ae43-d3d081a35642",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "payment",
                                        "label": "Payment",
                                        "dataField": "payment"
                                    },
                                    {
                                        "id": "92895c82-ef71-11ea-ae43-d3d081a35642",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "item",
                                        "label": "Item",
                                        "dataField": "item"
                                    },
                                    {
                                        "id": "92895c86-ef71-11ea-ae43-d3d081a35642",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "amount",
                                        "label": "Amount",
                                        "dataField": "amount"
                                    },
                                    {
                                        "id": "92895c88-ef71-11ea-ae43-d3d081a35642",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "state",
                                        "label": "State",
                                        "dataField": "state"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "#"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "payment-item-list-page",
                "route": "/payment-items-list",
                "dataModel": "24ee6e90-ef71-11ea-ae43-d3d081a35642"
            },
            {
                "id": "debbf900-f0b1-11ea-99d0-d9f36ea6d179",
                "type": "model",
                "children": [
                    {
                        "id": "efece9a0-f0b1-11ea-99d0-d9f36ea6d179",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "user"
                    },
                    {
                        "id": "0ebdbfe0-19ba-11eb-b611-03768364999e",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "model"
                    },
                    {
                        "id": "11b88950-19ba-11eb-b611-03768364999e",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "method"
                    },
                    {
                        "id": "151dad50-19ba-11eb-b611-03768364999e",
                        "type": "field:json",
                        "children": [],
                        "dataType": "json",
                        "name": "data"
                    }
                ],
                "name": "activity-log"
            },
            {
                "id": "fb9a9130-f0b1-11ea-99d0-d9f36ea6d179",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "fb9a9131-f0b1-11ea-99d0-d9f36ea6d179",
                        "children": [
                            {
                                "id": "29333c50-f0b2-11ea-99d0-d9f36ea6d179",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Activity Log",
                                "_sortIndex": -1,
                                "className": "has-text-centered"
                            },
                            {
                                "id": "fba65100-f0b1-11ea-99d0-d9f36ea6d179",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d78bffe0-19ba-11eb-9242-33b9df06d279",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "name": "createdAt",
                                        "label": "Date and Time"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0
                            },
                            {
                                "id": "fba65103-f0b1-11ea-99d0-d9f36ea6d179",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fba65105-f0b1-11ea-99d0-d9f36ea6d179",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "user",
                                        "label": "User",
                                        "dataField": "user"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10
                            },
                            {
                                "id": "fba65106-f0b1-11ea-99d0-d9f36ea6d179",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fba65108-f0b1-11ea-99d0-d9f36ea6d179",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "model",
                                        "label": "Model",
                                        "dataField": "model"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 20
                            },
                            {
                                "id": "fba65109-f0b1-11ea-99d0-d9f36ea6d179",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fba6510b-f0b1-11ea-99d0-d9f36ea6d179",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "method",
                                        "label": "Method",
                                        "dataField": "method",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 30
                            },
                            {
                                "id": "fba6510c-f0b1-11ea-99d0-d9f36ea6d179",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fba6510e-f0b1-11ea-99d0-d9f36ea6d179",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "detail",
                                        "label": "Data",
                                        "dataField": "details",
                                        "multiline": true,
                                        "line": "7"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 40
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "activityLog-detail-page",
                "route": "/activity-logs/:id",
                "dataModel": "debbf900-f0b1-11ea-99d0-d9f36ea6d179",
                "access": "noone"
            },
            {
                "id": "fba93730-f0b1-11ea-99d0-d9f36ea6d179",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "fb9a9131-f0b1-11ea-99d0-d9f36ea6d179",
                        "children": [
                            {
                                "id": "fbabcf40-f0b1-11ea-99d0-d9f36ea6d179",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "component": "Searchbar"
                            },
                            {
                                "id": "fbadcb10-f0b1-11ea-99d0-d9f36ea6d179",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "97559b70-19bf-11eb-9242-33b9df06d279",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date and Time",
                                        "date": true,
                                        "format": "MM/dd/yyyy hh:mm a"
                                    },
                                    {
                                        "id": "fbb06322-f0b1-11ea-99d0-d9f36ea6d179",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "user",
                                        "label": "User",
                                        "dataField": "user"
                                    },
                                    {
                                        "id": "fbb06324-f0b1-11ea-99d0-d9f36ea6d179",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "model",
                                        "label": "Model",
                                        "dataField": "model"
                                    },
                                    {
                                        "id": "fbb06326-f0b1-11ea-99d0-d9f36ea6d179",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "method",
                                        "label": "Method",
                                        "dataField": "method"
                                    },
                                    {
                                        "id": "fbb06328-f0b1-11ea-99d0-d9f36ea6d179",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "data",
                                        "label": "Data",
                                        "dataField": "data"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "activityLog-list-page",
                "route": "/activity-logs-list",
                "dataModel": "debbf900-f0b1-11ea-99d0-d9f36ea6d179"
            },
            {
                "id": "ffb5d7c0-f6f1-11ea-b61a-f5b3d409e561",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "ffb5d7c1-f6f1-11ea-b61a-f5b3d409e561",
                        "children": [
                            {
                                "id": "1e6d3bd0-08ff-11eb-800a-9340eaf97180",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "200ce210-08ff-11eb-800a-9340eaf97180",
                                        "type": "button",
                                        "children": [],
                                        "text": "Back",
                                        "path": "/account/billings",
                                        "variant": "text",
                                        "startIcon": "muiArrowBack",
                                        "endIcon": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "575cf5d0-f6f2-11ea-b61a-f5b3d409e561",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "dataField": "name",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "65d9ea00-f6f2-11ea-b61a-f5b3d409e561",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9bb06a50-f6f2-11ea-b61a-f5b3d409e561",
                                        "type": "textBlock",
                                        "children": [],
                                        "text": "Posting Date:",
                                        "className": "mr-1"
                                    },
                                    {
                                        "id": "a7492a50-f6f2-11ea-b61a-f5b3d409e561",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "postingDate"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "mx-1"
                            },
                            {
                                "id": "abc0cfc0-f6f2-11ea-b61a-f5b3d409e561",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ace838c0-f6f2-11ea-b61a-f5b3d409e561",
                                        "type": "textBlock",
                                        "children": [],
                                        "text": "Due Date:",
                                        "className": "mr-1"
                                    },
                                    {
                                        "id": "bc4b4730-f6f2-11ea-b61a-f5b3d409e561",
                                        "type": "textBlock",
                                        "children": [],
                                        "dataField": "dueDate"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "mx-1"
                            },
                            {
                                "id": "c25e4d20-f6f2-11ea-b61a-f5b3d409e561",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c8de1ea0-f6f2-11ea-b61a-f5b3d409e561",
                                        "type": "subView",
                                        "children": [],
                                        "view": "7c2f4560-f6f3-11ea-b61a-f5b3d409e561",
                                        "relation": "billing"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "my-2"
                            },
                            {
                                "id": "5a87c890-f6f5-11ea-8ecc-5b6063b0b693",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5b77a6d0-f6f5-11ea-8ecc-5b6063b0b693",
                                        "type": "subView",
                                        "children": [],
                                        "view": "c5bcf690-f6f4-11ea-8ecc-5b6063b0b693",
                                        "relation": "",
                                        "dataField": "name"
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": "",
                                "className": "my-2"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-billing-detail-page",
                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                "route": "/account/homeowner-billings/:id"
            },
            {
                "id": "7c2f4560-f6f3-11ea-b61a-f5b3d409e561",
                "type": "view",
                "children": [
                    {
                        "id": "9167db90-f6f3-11ea-b61a-f5b3d409e561",
                        "type": "table",
                        "children": [
                            {
                                "id": "92b47fd0-f6f3-11ea-b61a-f5b3d409e561",
                                "type": "column",
                                "children": [],
                                "orientation": "horizontal",
                                "label": "Item",
                                "name": "item"
                            },
                            {
                                "id": "83fbf7e0-060a-11eb-b075-710549412795",
                                "type": "column",
                                "children": [],
                                "orientation": "horizontal",
                                "dataField": "description",
                                "label": "Description"
                            },
                            {
                                "id": "cbabc820-f6f3-11ea-b61a-f5b3d409e561",
                                "type": "column",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "amount",
                                "label": "Amount"
                            }
                        ],
                        "orientation": "horizontal",
                        "path": "#"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-billing-item-list-page",
                "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
            },
            {
                "id": "c5bcf690-f6f4-11ea-8ecc-5b6063b0b693",
                "type": "view",
                "children": [
                    {
                        "id": "d2ba76b0-f6f4-11ea-8ecc-5b6063b0b693",
                        "type": "container",
                        "children": [
                            {
                                "id": "d4cab910-f6f4-11ea-8ecc-5b6063b0b693",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Payments",
                                "className": "mt-2 mb-1"
                            }
                        ],
                        "orientation": "vertical"
                    },
                    {
                        "id": "780eaa80-f6fc-11ea-805b-1f4689c2a5d9",
                        "type": "container",
                        "children": [
                            {
                                "id": "94aa6d50-f6fc-11ea-805b-1f4689c2a5d9",
                                "type": "textBlock",
                                "children": [],
                                "name": "postingDate"
                            },
                            {
                                "id": "9a0cc0e0-f6fc-11ea-805b-1f4689c2a5d9",
                                "type": "textBlock",
                                "children": [],
                                "name": "receipt"
                            }
                        ],
                        "orientation": "horizontal",
                        "component": "PaymentHomeownerView"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-payment-detail-page",
                "dataModel": ""
            },
            {
                "type": "view",
                "name": "searchbar",
                "orientation": "horizontal",
                "component": "Searchbar",
                "children": [
                    {
                        "name": "search",
                        "type": "inputText",
                        "id": "e7db5108-8c4a-11ea-9466-6f383d5eb483",
                        "placeholder": "Search"
                    },
                    {
                        "id": "e0073b50-8c64-11ea-b629-0161308cc31c",
                        "type": "button",
                        "children": [],
                        "command": "new",
                        "name": "",
                        "text": "New"
                    }
                ],
                "id": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                "active": false,
                "_sortIndex": 720
            },
            {
                "id": "1237a590-f7a6-11ea-8efb-e99dbc19ac92",
                "type": "page",
                "children": [
                    {
                        "id": "38fa2fc0-08e7-11eb-a5df-110eadfed9ed",
                        "type": "container",
                        "children": [
                            {
                                "id": "3b5a8df0-08e7-11eb-a5df-110eadfed9ed",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered",
                                "text": "Pay Dues"
                            },
                            {
                                "id": "66f6ccd0-08e7-11eb-a5df-110eadfed9ed",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6a11ef80-08e7-11eb-a5df-110eadfed9ed",
                                        "type": "button",
                                        "children": [],
                                        "text": "Payment History",
                                        "variant": "text",
                                        "path": "/account/pay-dues-history"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "justify-content-center pt-1 pb-1"
                            }
                        ],
                        "orientation": "vertical"
                    },
                    {
                        "id": "465b45c0-f7a6-11ea-8efb-e99dbc19ac92",
                        "type": "container",
                        "children": [
                            {
                                "id": "5738bf30-f7a6-11ea-8efb-e99dbc19ac92",
                                "type": "button",
                                "children": [],
                                "disabled": false,
                                "text": "Paymaya",
                                "path": "/account/pay-dues/paymaya/0"
                            },
                            {
                                "id": "68f0ba20-f7a6-11ea-8efb-e99dbc19ac92",
                                "type": "button",
                                "children": [],
                                "text": "Credit Card",
                                "disabled": false,
                                "path": "/account/pay-dues/credit-card/0"
                            },
                            {
                                "id": "6b19b4a0-f7a6-11ea-8efb-e99dbc19ac92",
                                "type": "button",
                                "children": [],
                                "text": "Deposit/Online Transfer",
                                "path": "/account/pay-dues/deposit/0"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "main"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-pay-dues",
                "route": "/account/pay-dues"
            },
            {
                "id": "b0779440-f7a6-11ea-8efb-e99dbc19ac92",
                "type": "page",
                "children": [
                    {
                        "id": "ba2f3830-f7a6-11ea-8efb-e99dbc19ac92",
                        "type": "container",
                        "children": [
                            {
                                "id": "23c42e40-f7a7-11ea-8efb-e99dbc19ac92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "2ddf7600-f7a7-11ea-8efb-e99dbc19ac92",
                                        "type": "paragraph",
                                        "children": [],
                                        "text": "You can deposit payments directly or do bank transfer:",
                                        "className": "my-2"
                                    },
                                    {
                                        "id": "3dd72de0-f7a8-11ea-8efb-e99dbc19ac92",
                                        "type": "paragraph",
                                        "children": [],
                                        "text": "Account Name: Pacific Land Ventures & Property Development Inc."
                                    },
                                    {
                                        "id": "4c2b1640-f7a8-11ea-8efb-e99dbc19ac92",
                                        "type": "paragraph",
                                        "children": [],
                                        "text": "Account Number: 2860-0005-74"
                                    },
                                    {
                                        "id": "3064f2f0-f7a8-11ea-8efb-e99dbc19ac92",
                                        "type": "paragraph",
                                        "children": [],
                                        "text": "Bank: Bank of the Philippine Islands (BPI)"
                                    },
                                    {
                                        "id": "590752c0-f7a8-11ea-8efb-e99dbc19ac92",
                                        "type": "paragraph",
                                        "children": [],
                                        "text": "Branch: Cebu Taboan Branch"
                                    },
                                    {
                                        "id": "e7c265c0-fde0-11ea-b546-e584feed1730",
                                        "type": "paragraph",
                                        "children": [],
                                        "text": "BPI Swift Code: BOPIPHMM"
                                    },
                                    {
                                        "id": "67e66420-f7a8-11ea-8efb-e99dbc19ac92",
                                        "type": "paragraph",
                                        "children": [],
                                        "className": "my-2",
                                        "text": "Take a photo of the deposit slip or screen shot of the online/bank transfer confirmation and upload here."
                                    }
                                ],
                                "orientation": "vertical"
                            },
                            {
                                "id": "579c4680-f7a7-11ea-8efb-e99dbc19ac92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bb6549d0-0370-11eb-9871-a7d3e8f638e3",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "amount",
                                        "label": "Amount",
                                        "name": "amount",
                                        "required": true,
                                        "className": "",
                                        "defaultValue": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "pb-4"
                            },
                            {
                                "id": "743c9d30-f7a7-11ea-8efb-e99dbc19ac92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5f0ce0a0-f7a7-11ea-8efb-e99dbc19ac92",
                                        "type": "inputMedia",
                                        "children": [],
                                        "label": "Proof of Payment",
                                        "dataField": "photo",
                                        "name": "photo",
                                        "required": true,
                                        "basePath": "online-payments"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "8241c950-f7a7-11ea-8efb-e99dbc19ac92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "75ba3c80-f7a7-11ea-8efb-e99dbc19ac92",
                                        "type": "inputText",
                                        "children": [],
                                        "multiline": true,
                                        "label": "Particulars",
                                        "dataField": "particulars",
                                        "name": "particulars",
                                        "required": true,
                                        "line": "8"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "a0a524d0-0370-11eb-9871-a7d3e8f638e3",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a24f7970-0370-11eb-9871-a7d3e8f638e3",
                                        "type": "button",
                                        "children": [],
                                        "command": "save",
                                        "text": "Submit",
                                        "name": "save"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4801f960-3a6c-11eb-9608-13f79b46a41f",
                                "type": "inputText",
                                "children": [],
                                "className": "hide",
                                "defaultValue": "Deposit/Online Transfer",
                                "dataField": "mode"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "main"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-pay-dues-deposit",
                "route": "/account/pay-dues/deposit/:id",
                "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3"
            },
            {
                "id": "968362c0-fdf1-11ea-ba93-5145fd1d7baf",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "968362c1-fdf1-11ea-ba93-5145fd1d7baf",
                        "component": "LoginPortal"
                    }
                ],
                "orientation": "vertical",
                "name": "login-portal-page",
                "route": "/login-portal",
                "component": "",
                "layout": "full"
            },
            {
                "id": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
                "type": "view",
                "children": [
                    {
                        "id": "bb8a1dc0-01ed-11eb-81b5-f15d97fd1729",
                        "type": "inputText",
                        "children": [],
                        "label": "",
                        "placeholder": "Search",
                        "name": "search"
                    }
                ],
                "orientation": "vertical",
                "name": "searchbar-only",
                "component": "Searchbar"
            },
            {
                "id": "0f34a160-0370-11eb-9871-a7d3e8f638e3",
                "type": "model",
                "children": [
                    {
                        "id": "1deb9150-0370-11eb-9871-a7d3e8f638e3",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "amount"
                    },
                    {
                        "id": "218ab110-0370-11eb-9871-a7d3e8f638e3",
                        "type": "field",
                        "children": [],
                        "dataType": "string",
                        "name": "particulars"
                    },
                    {
                        "id": "2bc65210-0370-11eb-9871-a7d3e8f638e3",
                        "type": "field:file",
                        "children": [],
                        "dataType": "file",
                        "collection": "file",
                        "via": "related",
                        "allowedTypes": [
                            "images",
                            "files",
                            "videos"
                        ],
                        "plugin": "upload",
                        "name": "photo"
                    },
                    {
                        "id": "f816f2e0-1025-11eb-b134-49e9ab8733f5",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "mode"
                    },
                    {
                        "id": "45672940-3a77-11eb-9608-13f79b46a41f",
                        "type": "field:text",
                        "children": [],
                        "dataType": "text",
                        "name": "status"
                    }
                ],
                "name": "online-payment"
            },
            {
                "id": "cc343590-0376-11eb-b9ea-3df39e640441",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "cc343591-0376-11eb-b9ea-3df39e640441",
                        "children": [
                            {
                                "id": "f1143630-0376-11eb-b9ea-3df39e640441",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f5df04b0-0376-11eb-b9ea-3df39e640441",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "className": "has-text-centered pb-2",
                                        "text": "Work Permits"
                                    }
                                ],
                                "orientation": "vertical"
                            },
                            {
                                "id": "09753580-0377-11eb-b9ea-3df39e640441",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "0c79ea00-0377-11eb-b9ea-3df39e640441",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "0deab810-0377-11eb-b9ea-3df39e640441",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "unit",
                                                "label": "Unit"
                                            },
                                            {
                                                "id": "1ac0b300-0377-11eb-b9ea-3df39e640441",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "natureOfWork",
                                                "label": "Nature of Work"
                                            },
                                            {
                                                "id": "224941f0-0377-11eb-b9ea-3df39e640441",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "contractor",
                                                "label": "Contractor"
                                            },
                                            {
                                                "id": "294a5160-0377-11eb-b9ea-3df39e640441",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "personInCharge",
                                                "label": "Person In Charge"
                                            },
                                            {
                                                "id": "3457c150-0377-11eb-b9ea-3df39e640441",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "description",
                                                "label": "Description"
                                            },
                                            {
                                                "id": "415122c0-0377-11eb-b9ea-3df39e640441",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "startDate",
                                                "label": "Start Date",
                                                "date": true
                                            },
                                            {
                                                "id": "4c33d930-0377-11eb-b9ea-3df39e640441",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "endDate",
                                                "label": "End Date",
                                                "date": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": ""
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-work-permits",
                "route": "/work-permits",
                "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1"
            },
            {
                "id": "7d7c1ed0-0377-11eb-bfdc-3b5d643d4962",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "7d7c1ed1-0377-11eb-bfdc-3b5d643d4962",
                        "children": [
                            {
                                "id": "963af590-0377-11eb-bfdc-3b5d643d4962",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9a9fd650-0377-11eb-bfdc-3b5d643d4962",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Unit Requests",
                                        "className": "has-text-centered pb-2"
                                    }
                                ],
                                "orientation": "vertical"
                            },
                            {
                                "id": "a56085d0-0377-11eb-bfdc-3b5d643d4962",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a7a0b1d0-0377-11eb-bfdc-3b5d643d4962",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "aa7bbe40-0377-11eb-bfdc-3b5d643d4962",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "unit",
                                                "label": "Unit"
                                            },
                                            {
                                                "id": "b592f230-0377-11eb-bfdc-3b5d643d4962",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "type",
                                                "label": "Type"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-unit-requests",
                "route": "/unit-requests",
                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
            },
            {
                "id": "b6957d90-08e7-11eb-a5df-110eadfed9ed",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "b6957d91-08e7-11eb-a5df-110eadfed9ed",
                        "children": [
                            {
                                "id": "e2bae9a0-08e7-11eb-a5df-110eadfed9ed",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered mb-2",
                                "text": "Payment History"
                            },
                            {
                                "id": "eeb04890-08e7-11eb-a5df-110eadfed9ed",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f17cd610-08e7-11eb-a5df-110eadfed9ed",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "10f41a80-08e8-11eb-a5df-110eadfed9ed",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Date",
                                                "name": "createdAt",
                                                "date": true,
                                                "format": "MM/dd/yyyy hh:mm a"
                                            },
                                            {
                                                "id": "bd909980-3a7f-11eb-b43b-35557e2f71d4",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Mode",
                                                "dataField": "mode"
                                            },
                                            {
                                                "id": "1a701500-08e8-11eb-a5df-110eadfed9ed",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Particulars",
                                                "dataField": "particulars"
                                            },
                                            {
                                                "id": "2293d280-08e8-11eb-a5df-110eadfed9ed",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "amount",
                                                "label": "Amount",
                                                "currency": true
                                            },
                                            {
                                                "id": "c83d99f0-3a7f-11eb-b43b-35557e2f71d4",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "status",
                                                "label": "Status"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": "#"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "orientation": "vertical"
                    }
                ],
                "orientation": "vertical",
                "route": "/account/pay-dues-history",
                "name": "homeowner-pay-dues-history",
                "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3"
            },
            {
                "id": "1e5949d0-0ffe-11eb-a75f-a764954330b6",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "1e5949d1-0ffe-11eb-a75f-a764954330b6",
                        "children": [
                            {
                                "id": "2c223040-0ffe-11eb-a75f-a764954330b6",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Forgot Password"
                            },
                            {
                                "id": "334baf40-0ffe-11eb-a75f-a764954330b6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "352ddcc0-0ffe-11eb-a75f-a764954330b6",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Email",
                                        "name": "email",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "492148c0-0ffe-11eb-a75f-a764954330b6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4ad78440-0ffe-11eb-a75f-a764954330b6",
                                        "type": "button",
                                        "children": [],
                                        "text": "Submit",
                                        "command": "forgot_password"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "component": "ForgotPassword"
                    }
                ],
                "orientation": "vertical",
                "name": "forgot-password",
                "route": "/forgot-password",
                "layout": "full"
            },
            {
                "id": "7589ccc0-1008-11eb-9f4b-55fb426750d7",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "7589ccc1-1008-11eb-9f4b-55fb426750d7",
                        "children": [
                            {
                                "id": "ac994240-1008-11eb-9f4b-55fb426750d7",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Reset Password"
                            },
                            {
                                "id": "b402fe90-1008-11eb-9f4b-55fb426750d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b5601d90-1008-11eb-9f4b-55fb426750d7",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "password",
                                        "label": "New Password",
                                        "password": true,
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "adbb41c0-568d-11eb-867b-3deeecb92532",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b148ae40-568d-11eb-867b-3deeecb92532",
                                        "type": "inputText",
                                        "children": [],
                                        "password": true,
                                        "label": "Confirm Password",
                                        "name": "passwordConfirmation"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "e55ad490-1008-11eb-9f4b-55fb426750d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e6ae2f90-1008-11eb-9f4b-55fb426750d7",
                                        "type": "button",
                                        "children": [],
                                        "html": "Submit",
                                        "command": "reset_password"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "component": "ResetPassword"
                    }
                ],
                "orientation": "vertical",
                "name": "reset-password",
                "route": "/reset-password",
                "layout": "full"
            },
            {
                "id": "c8274690-1221-11eb-a9d8-83db08ab78a0",
                "type": "view",
                "children": [
                    {
                        "id": "c9fe4920-1224-11eb-bc75-95cdd9dff5ce",
                        "type": "container",
                        "children": [
                            {
                                "id": "d0be57f0-1224-11eb-bc75-95cdd9dff5ce",
                                "type": "button",
                                "children": [],
                                "name": "save",
                                "command": "save",
                                "text": "Submit"
                            }
                        ],
                        "orientation": "horizontal",
                        "component": "ActionBar"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-commandbar",
                "component": "ActionBar"
            },
            {
                "id": "27c30fa0-1603-11eb-8555-975f97eacdba",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "27c30fa1-1603-11eb-8555-975f97eacdba",
                        "children": [
                            {
                                "id": "5a307450-1603-11eb-8555-975f97eacdba",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Visitor / Tenant Pass",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "6cd54040-1603-11eb-8555-975f97eacdba",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b3c70ae0-1646-11eb-b8c0-fd645d6bfd69",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "unit",
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "name": "unit",
                                        "label": "Unit",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "vertical"
                            },
                            {
                                "id": "cf588fa0-1604-11eb-9903-d9f0b8dd94fe",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "0dae7c00-1647-11eb-91d3-e743baeee069",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "type",
                                        "label": "Type",
                                        "name": "type",
                                        "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                        "saveObject": true,
                                        "disabled": true
                                    }
                                ],
                                "orientation": "vertical"
                            },
                            {
                                "id": "5f33d1a0-1648-11eb-91d3-e743baeee069",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6e6a78e0-1648-11eb-91d3-e743baeee069",
                                        "type": "subView",
                                        "children": [],
                                        "view": "5e6330a0-1647-11eb-91d3-e743baeee069",
                                        "name": "view-visitor-pass-visitor-pass"
                                    },
                                    {
                                        "id": "d3888570-164b-11eb-8554-b34543e4ea31",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-visitor-pass-short-term",
                                        "view": "69176030-164b-11eb-8554-b34543e4ea31"
                                    },
                                    {
                                        "id": "a62e4d70-164c-11eb-8554-b34543e4ea31",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-visitor-pass-long-term",
                                        "view": "2d3e3830-164c-11eb-8554-b34543e4ea31"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "view-visitor-pass",
                                "component": "VisitorPass"
                            },
                            {
                                "id": "f480fa90-166a-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f914da90-166a-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status",
                                        "disabled": true,
                                        "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops,pmg-security"
                            },
                            {
                                "id": "264af350-166b-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "283cff50-166b-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputText",
                                        "children": [],
                                        "required": false,
                                        "disabled": true,
                                        "multiline": true,
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops,pmg-security"
                            },
                            {
                                "id": "a9ec78d0-164a-11eb-8554-b34543e4ea31",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "beecc0f0-164a-11eb-8554-b34543e4ea31",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "status",
                                        "name": "status",
                                        "label": "Status",
                                        "disabled": false,
                                        "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "cd2ea7f0-164a-11eb-8554-b34543e4ea31",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d1844670-164a-11eb-8554-b34543e4ea31",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "cccdf410-167b-11eb-83c3-eb7956ee1f3f",
                                "type": "subView",
                                "children": [],
                                "access": "admin,pmg",
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "pass-visitor-detail-page",
                "route": "/pass-visitors-list/detail/:id",
                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                "access": "admin,pmg"
            },
            {
                "id": "5e6330a0-1647-11eb-91d3-e743baeee069",
                "type": "view",
                "children": [
                    {
                        "id": "820755e0-1647-11eb-91d3-e743baeee069",
                        "type": "container",
                        "children": [
                            {
                                "id": "a1419150-1647-11eb-91d3-e743baeee069",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.relationship",
                                "disabled": true,
                                "label": "Relationship to Owner"
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "ee506f70-1647-11eb-91d3-e743baeee069",
                        "type": "container",
                        "children": [
                            {
                                "id": "f154fce0-1647-11eb-91d3-e743baeee069",
                                "type": "inputList",
                                "children": [],
                                "label": "Name/s",
                                "name": "visitor",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "088bd410-1648-11eb-91d3-e743baeee069",
                        "type": "container",
                        "children": [
                            {
                                "id": "0dc19960-1648-11eb-91d3-e743baeee069",
                                "type": "inputDate",
                                "children": [],
                                "name": "startDate",
                                "label": "Start Date",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "1f634d30-1648-11eb-91d3-e743baeee069",
                        "type": "container",
                        "children": [
                            {
                                "id": "209c4260-1648-11eb-91d3-e743baeee069",
                                "type": "inputDate",
                                "children": [],
                                "name": "endDate",
                                "label": "End Date",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "27a38170-164a-11eb-9056-4178972142ab",
                        "type": "container",
                        "children": [
                            {
                                "id": "29dfdce0-164a-11eb-9056-4178972142ab",
                                "type": "inputMedia",
                                "children": [],
                                "name": "visitorsId",
                                "label": "Upload Valid ID (optional)",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-visitor-pass-visitor-pass"
            },
            {
                "id": "69176030-164b-11eb-8554-b34543e4ea31",
                "type": "view",
                "children": [
                    {
                        "id": "6b7107a0-164b-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "867b2df0-164b-11eb-8554-b34543e4ea31",
                                "type": "inputList",
                                "children": [],
                                "name": "visitor",
                                "label": "Names/s",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "8fb78760-164b-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "9a8f8f70-164b-11eb-8554-b34543e4ea31",
                                "type": "inputDate",
                                "children": [],
                                "name": "startDate",
                                "label": "Start Date",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "a6236d70-164b-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "acacdbe0-164b-11eb-8554-b34543e4ea31",
                                "type": "inputDate",
                                "children": [],
                                "name": "endDate",
                                "label": "End Date",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal",
                        "name": ""
                    },
                    {
                        "id": "bf03d5f0-164b-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "c0586970-164b-11eb-8554-b34543e4ea31",
                                "type": "inputMedia",
                                "children": [],
                                "name": "visitorsId",
                                "label": "Upload Valid ID/ Passport",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-visitor-pass-short-term"
            },
            {
                "id": "2d3e3830-164c-11eb-8554-b34543e4ea31",
                "type": "view",
                "children": [
                    {
                        "id": "32066ea0-164c-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "3e658be0-164c-11eb-8554-b34543e4ea31",
                                "type": "inputList",
                                "children": [],
                                "name": "visitor",
                                "label": "Name/s",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "496fa070-164c-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "4b2abdf0-164c-11eb-8554-b34543e4ea31",
                                "type": "inputDate",
                                "children": [],
                                "name": "startDate",
                                "label": "Start Date",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "57ced370-164c-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "58fdb680-164c-11eb-8554-b34543e4ea31",
                                "type": "inputDate",
                                "children": [],
                                "name": "endDate",
                                "label": "End Date",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "68265400-164c-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "6eabf1e0-164c-11eb-8554-b34543e4ea31",
                                "type": "inputMedia",
                                "children": [],
                                "name": "visitorsId",
                                "label": "Upload valid ID/ Passport (required)",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "79741b70-164c-11eb-8554-b34543e4ea31",
                        "type": "container",
                        "children": [
                            {
                                "id": "7e686af0-164c-11eb-8554-b34543e4ea31",
                                "type": "inputMedia",
                                "children": [],
                                "name": "meta.contract",
                                "label": "Upload Contract of Lease (required)",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-visitor-pass-long-term"
            },
            {
                "id": "fcc6c530-164d-11eb-bfd1-2d1eca45ef82",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "fcc6c531-164d-11eb-bfd1-2d1eca45ef82",
                        "children": [
                            {
                                "id": "ffd3de20-164d-11eb-bfd1-2d1eca45ef82",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered",
                                "text": "Delivery / Pullout Pass"
                            },
                            {
                                "id": "1a506a70-164e-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "22ed0170-164e-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "unit",
                                        "label": "Unit",
                                        "disabled": true,
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "dataField": "unit"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "367b9120-164e-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4543e630-164e-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "type",
                                        "name": "type",
                                        "label": "Type",
                                        "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                        "saveObject": true,
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "58e6e840-164e-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5b4fd1f0-164e-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "disabled": true,
                                        "name": "schedule",
                                        "label": "Schedule",
                                        "dataField": "schedule"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "6ad53570-164e-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6d64b9f0-164e-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputList",
                                        "children": [],
                                        "name": "items",
                                        "dataField": "items",
                                        "disabled": true,
                                        "label": "Item(s) - Please enumerate including quantities."
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "8b160b70-164e-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "8ded9570-164e-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "company",
                                        "label": "Company",
                                        "dataField": "company",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "be90bb40-166b-11eb-af06-b18a05fa97c7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c1974480-166b-11eb-af06-b18a05fa97c7",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status",
                                        "disabled": true,
                                        "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                        "access": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops,pmg-security"
                            },
                            {
                                "id": "da011d70-166b-11eb-af06-b18a05fa97c7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "dbe34af0-166b-11eb-af06-b18a05fa97c7",
                                        "type": "inputText",
                                        "children": [],
                                        "multiline": true,
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "disabled": true,
                                        "access": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops,pmg-security"
                            },
                            {
                                "id": "d79a35c0-164e-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e29ad470-164e-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status",
                                        "disabled": false,
                                        "access": "",
                                        "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "",
                                "dataField": "",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "1cc8a0f0-164f-11eb-bfd1-2d1eca45ef82",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1e99de80-164f-11eb-bfd1-2d1eca45ef82",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "access": "",
                                        "disabled": false,
                                        "multiline": true,
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "d95077d0-167b-11eb-83c3-eb7956ee1f3f",
                                "type": "subView",
                                "children": [],
                                "access": "admin,pmg",
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "pass-delivery-detail-page",
                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                "route": "/pass-deliveries-list/detail/:id"
            },
            {
                "id": "8aa9f4a0-1679-11eb-83c3-eb7956ee1f3f",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "8aa9f4a1-1679-11eb-83c3-eb7956ee1f3f",
                        "children": [
                            {
                                "id": "a10782d0-1679-11eb-83c3-eb7956ee1f3f",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Work Permit",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "b8f28ed0-1679-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bc6c4c40-1679-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "unit",
                                        "label": "Unit",
                                        "disabled": true,
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "dataField": "unit",
                                        "saveObject": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": ""
                            },
                            {
                                "id": "d78e8e70-1679-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "dcd45950-1679-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Nature of Work",
                                        "dataField": "natureOfWork",
                                        "disabled": true,
                                        "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "f18a03e0-1679-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f8bf90d0-1679-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Contractor",
                                        "dataField": "contractor",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "18be06a0-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1cd849d0-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Person In Charge",
                                        "disabled": true,
                                        "dataField": "personInCharge"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "2e6bf3e0-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3073d1d0-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputText",
                                        "children": [],
                                        "disabled": true,
                                        "dataField": "contactPersonInCharge",
                                        "label": "Contact Number of Person-in-Charge"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3ac14d20-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3f4aa5d0-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputList",
                                        "children": [],
                                        "dataField": "workers",
                                        "label": "Workers",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4fc16e30-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "533a6850-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputText",
                                        "children": [],
                                        "multiline": true,
                                        "dataField": "description",
                                        "label": "Description",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5f0a16d0-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "63b660d0-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputList",
                                        "children": [],
                                        "disabled": true,
                                        "label": "Materials",
                                        "dataField": "materials"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "6c94e2d0-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6f68c350-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputList",
                                        "children": [],
                                        "label": "Tools",
                                        "dataField": "tools",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "80193950-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "815f26d0-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputDate",
                                        "children": [],
                                        "disabled": true,
                                        "label": "Proposed Start Date ",
                                        "dataField": "startDate"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "93c0f650-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "958bf250-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputDate",
                                        "children": [],
                                        "label": "Target End Date ",
                                        "dataField": "endDate",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "9e8687d0-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a3ec5dd0-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputMedia",
                                        "children": [],
                                        "dataField": "plans",
                                        "disabled": true,
                                        "label": "Plans"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c271ef40-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c4962ed0-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Status",
                                        "dataField": "status",
                                        "disabled": true,
                                        "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-security,pmg-ops"
                            },
                            {
                                "id": "de128930-167a-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ee1c10d0-167a-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputText",
                                        "children": [],
                                        "disabled": true,
                                        "multiline": true,
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "dataField": "",
                                "access": "pmg-security,pmg-ops"
                            },
                            {
                                "id": "0c1cbad0-167b-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "0f314dd0-167b-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "status",
                                        "label": "Status",
                                        "saveObject": true,
                                        "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "1d1424e0-167b-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "219bcfe0-167b-11eb-83c3-eb7956ee1f3f",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "notes",
                                        "multiline": true,
                                        "label": "Notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "ee8c4250-167b-11eb-83c3-eb7956ee1f3f",
                                "type": "subView",
                                "children": [],
                                "access": "admin,pmg",
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            },
                            {
                                "id": "f7789510-167d-11eb-83c3-eb7956ee1f3f",
                                "type": "header",
                                "children": [],
                                "size": 2
                            },
                            {
                                "id": "02c8da60-167e-11eb-83c3-eb7956ee1f3f",
                                "type": "header",
                                "children": [],
                                "size": 2
                            },
                            {
                                "id": "0b46c5d0-167e-11eb-83c3-eb7956ee1f3f",
                                "type": "header",
                                "children": [],
                                "size": 2
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "work-permit-detail-page",
                "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                "route": "/work-permits/detail/:id"
            },
            {
                "id": "0ef575f0-167e-11eb-83c3-eb7956ee1f32",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "8aa9f4a1-1679-11eb-83c3-eb7956ee1f33",
                        "children": [
                            {
                                "id": "349d8180-167e-11eb-83c3-eb7956ee1f3f",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Unit Request",
                                "className": "has-text-centered"
                            },
                            {
                                "id": "63396bd0-167e-11eb-83c3-eb7956ee1f3f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "0fc02e10-1680-11eb-b4c7-db323e346a29",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "1972a1e0-1680-11eb-b4c7-db323e346a29",
                                                "type": "inputSelect",
                                                "children": [],
                                                "dataField": "unit",
                                                "label": "Unit",
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "disabled": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "unit-container"
                                    },
                                    {
                                        "id": "3194c370-1680-11eb-b4c7-db323e346a29",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "43541160-1680-11eb-b4c7-db323e346a29",
                                                "type": "inputSelect",
                                                "children": [],
                                                "label": "Type",
                                                "dataField": "type",
                                                "disabled": true,
                                                "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "type-container"
                                    },
                                    {
                                        "id": "99d2dc10-1680-11eb-b4c7-db323e346a29",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-unit-request-aircon-cleaning",
                                        "view": "989101e3-d9a6-11ea-891d-3722b00ad4e7"
                                    },
                                    {
                                        "id": "39d51430-1681-11eb-b4c7-db323e346a29",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-unit-request-grease-trap-cleaning",
                                        "view": "0c744882-d9a5-11ea-a304-319701533d32"
                                    },
                                    {
                                        "id": "87c85ee0-1681-11eb-b4c7-db323e346a29",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-unit-request-others",
                                        "view": "f7b9bf21-d9a8-11ea-9331-090047662dd2"
                                    },
                                    {
                                        "id": "d1bcdda0-1681-11eb-b4c7-db323e346a29",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-unit-request-plans-documents",
                                        "view": "e60fdb41-c910-11ea-9dd0-6723feee229e"
                                    },
                                    {
                                        "id": "5096c5f0-1682-11eb-b4c7-db323e346a29",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-unit-request-refund-renovation-bond",
                                        "view": "063f7331-c911-11ea-9dd0-6723feee229e"
                                    },
                                    {
                                        "id": "d3ff3710-1682-11eb-b4c7-db323e346a29",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-unit-request-service-request",
                                        "view": "e00cd7b1-c90c-11ea-8c59-196c3ec4f839"
                                    },
                                    {
                                        "id": "84d5c0e0-1683-11eb-b4c7-db323e346a29",
                                        "type": "subView",
                                        "children": [],
                                        "name": "view-unit-request-vehicle-sticker",
                                        "view": "8df270e1-d918-11ea-b3eb-d9cf4159b042"
                                    }
                                ],
                                "orientation": "horizontal",
                                "component": "UnitRequest",
                                "name": "view-unit-request"
                            },
                            {
                                "id": "9d35aec0-1683-11eb-b4c7-db323e346a29",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9edecae0-1683-11eb-b4c7-db323e346a29",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "status",
                                        "disabled": true,
                                        "label": "Status",
                                        "access": "",
                                        "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"canceled\", \"label\": \"Canceled\"}]"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops,pmg-security,accounting,accounting-staff",
                                "dataField": ""
                            },
                            {
                                "id": "c98a4120-1683-11eb-b4c7-db323e346a29",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "cd82a470-1683-11eb-b4c7-db323e346a29",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "multiline": true,
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops,pmg-security,accounting,accounting-staff"
                            },
                            {
                                "id": "9d35aec1-1683-11eb-b4c7-db323e346a29",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9edecae1-1683-11eb-b4c7-db323e346a29",
                                        "type": "inputSelect",
                                        "children": [],
                                        "dataField": "status",
                                        "disabled": false,
                                        "label": "Status",
                                        "access": "",
                                        "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg",
                                "dataField": ""
                            },
                            {
                                "id": "c98a4121-1683-11eb-b4c7-db323e346a29",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "cd82a471-1683-11eb-b4c7-db323e346a29",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "multiline": true,
                                        "disabled": false
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "27ca36f0-1684-11eb-b4c7-db323e346a29",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                                "access": "admin,pmg"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "unit-request-detail-page",
                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                "route": "/unit-requests/detail/:id"
            },
            {
                "id": "989101e3-d9a6-11ea-891d-3722b00ad4e7",
                "type": "view",
                "children": [
                    {
                        "id": "d3e39f53-d9a6-11ea-891d-3722b00ad4e7",
                        "type": "container",
                        "children": [
                            {
                                "id": "d5efea13-d9a6-11ea-891d-3722b00ad4e7",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.units",
                                "label": "No. of Units",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "db6aa7a3-d9a6-11ea-891d-3722b00ad4e7",
                        "type": "container",
                        "children": [
                            {
                                "id": "e1bfdbe3-edcc-11ea-abfa-8f89576fb378",
                                "type": "inputDateTime",
                                "children": [],
                                "name": "meta.schedule",
                                "label": "Preferred Schedule",
                                "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-unit-request-aircon-cleaning",
                "_sortIndex": 590
            },
            {
                "id": "0c744882-d9a5-11ea-a304-319701533d32",
                "type": "view",
                "children": [
                    {
                        "id": "1c9ddb42-d9a5-11ea-a304-319701533d32",
                        "type": "container",
                        "children": [
                            {
                                "id": "fbbdc252-edcc-11ea-abfa-8f89576fb378",
                                "type": "inputDateTime",
                                "children": [],
                                "name": "meta.schedule",
                                "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                "label": "Preferred Schedule",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-unit-request-grease-trap-cleaning",
                "_sortIndex": 600
            },
            {
                "id": "f7b9bf21-d9a8-11ea-9331-090047662dd2",
                "type": "view",
                "children": [
                    {
                        "id": "fe4688f1-d9a8-11ea-9331-090047662dd2",
                        "type": "container",
                        "children": [
                            {
                                "id": "ff497a01-d9a8-11ea-9331-090047662dd2",
                                "type": "inputText",
                                "children": [],
                                "multiline": true,
                                "name": "meta.details",
                                "label": "Please Specify",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-unit-request-others",
                "_sortIndex": 610
            },
            {
                "id": "e60fdb41-c910-11ea-9dd0-6723feee229e",
                "type": "view",
                "children": [
                    {
                        "id": "e85ead42-c910-11ea-9dd0-6723feee229e",
                        "type": "container",
                        "children": [
                            {
                                "id": "076a4bb2-c91e-11ea-831c-99fd7b91f646",
                                "type": "inputSelect",
                                "children": [],
                                "name": "meta.plans",
                                "label": "Plans Required",
                                "options": "[{ \"value\": \"standard\", \"label\": \"Standard Plan\"},{ \"value\": \"as-built\", \"label\": \"As-built Plan\"}]",
                                "className": "bite-select",
                                "saveObject": true,
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal",
                        "name": "homeowner-service-request-repairs",
                        "className": "bite-select"
                    },
                    {
                        "id": "112746d2-c91e-11ea-831c-99fd7b91f646",
                        "type": "container",
                        "children": [
                            {
                                "id": "44b19ca2-d916-11ea-9ea3-7184c3941d0d",
                                "type": "inputSelect",
                                "children": [],
                                "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                "saveObject": true,
                                "name": "meta.paper-size",
                                "label": "Paper Size",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal",
                        "component": "UnitRequestPlansDocuments"
                    },
                    {
                        "id": "97e9eee2-d916-11ea-9ea3-7184c3941d0d",
                        "type": "container",
                        "children": [
                            {
                                "id": "9a6e28c2-d916-11ea-9ea3-7184c3941d0d",
                                "type": "inputSelect",
                                "children": [],
                                "name": "meta.type",
                                "label": "Type of Plan",
                                "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing\", \"label\": \"Plumbing\"},{ \"value\": \"electrical-plan\", \"label\": \"Electrical Plan\"}]",
                                "saveObject": true,
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-unit-request-plans-documents",
                "_sortIndex": 620
            },
            {
                "id": "063f7331-c911-11ea-9dd0-6723feee229e",
                "type": "view",
                "children": [
                    {
                        "id": "1cea7cc1-c91f-11ea-b910-c30a77ee18ba",
                        "type": "container",
                        "children": [
                            {
                                "id": "b0a9a301-ef9e-11ea-82b2-a7421559c7b2",
                                "type": "inputDate",
                                "children": [],
                                "name": "meta.inspection-date",
                                "label": "Preferred Inspection Date",
                                "description": "Monday to Friday, except Holidays",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal",
                        "className": ""
                    },
                    {
                        "id": "b9eb6b31-d917-11ea-9ea3-7184c3941d0d",
                        "type": "container",
                        "children": [
                            {
                                "id": "d8830d81-ef9e-11ea-82b2-a7421559c7b2",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.additional-requests",
                                "multiline": true,
                                "label": "Additional Requests",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-unit-request-refund-renovation-bond",
                "_sortIndex": 630
            },
            {
                "id": "e00cd7b1-c90c-11ea-8c59-196c3ec4f839",
                "type": "view",
                "children": [
                    {
                        "id": "743faf71-c90d-11ea-8c59-196c3ec4f839",
                        "type": "container",
                        "children": [
                            {
                                "id": "a9319f51-c911-11ea-98ab-e51558e3434e",
                                "type": "inputSelect",
                                "children": [],
                                "label": "Type of Work",
                                "name": "meta.type",
                                "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                                "className": "bite-select",
                                "saveObject": true,
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal",
                        "className": "bite-select"
                    },
                    {
                        "id": "c6681b81-c911-11ea-98ab-e51558e3434e",
                        "type": "container",
                        "children": [
                            {
                                "id": "c84dcb71-c911-11ea-98ab-e51558e3434e",
                                "type": "inputText",
                                "children": [],
                                "label": "Work Details",
                                "name": "meta.details",
                                "multiline": true,
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "cf0bde71-c911-11ea-98ab-e51558e3434e",
                        "type": "container",
                        "children": [
                            {
                                "id": "ccce1d41-edcd-11ea-993f-a977bcf36a2d",
                                "type": "inputDateTime",
                                "children": [],
                                "name": "meta.schedule",
                                "label": "Preferred Inspection/Work Schedule",
                                "description": " Monday to Friday only. Except Holidays",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal",
                        "className": "bite-select"
                    }
                ],
                "orientation": "vertical",
                "name": "view-unit-request-service-request",
                "_sortIndex": 640
            },
            {
                "id": "8df270e1-d918-11ea-b3eb-d9cf4159b042",
                "type": "view",
                "children": [
                    {
                        "id": "b33b28f1-d919-11ea-b3eb-d9cf4159b042",
                        "type": "container",
                        "children": [
                            {
                                "id": "b80cd541-d919-11ea-b3eb-d9cf4159b042",
                                "type": "inputSelect",
                                "children": [],
                                "name": "meta.make",
                                "label": "Make",
                                "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                                "saveObject": true,
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "c394f371-d919-11ea-b3eb-d9cf4159b042",
                        "type": "container",
                        "children": [
                            {
                                "id": "c6827671-d919-11ea-b3eb-d9cf4159b042",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.model",
                                "label": "Model",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "ad71c601-d9a4-11ea-a304-319701533d32",
                        "type": "container",
                        "children": [
                            {
                                "id": "b1b90ca1-d9a4-11ea-a304-319701533d32",
                                "type": "inputSelect",
                                "children": [],
                                "name": "meta.color",
                                "label": "Color",
                                "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                                "saveObject": true,
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "c744cbe1-d9a4-11ea-a304-319701533d32",
                        "type": "container",
                        "children": [
                            {
                                "id": "cfb34e01-d9a4-11ea-a304-319701533d32",
                                "type": "inputText",
                                "children": [],
                                "name": "meta.plate-number",
                                "label": "Plate Number",
                                "required": false,
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    },
                    {
                        "id": "ddd18061-d9a4-11ea-a304-319701533d32",
                        "type": "container",
                        "children": [
                            {
                                "id": "deacec41-d9a4-11ea-a304-319701533d32",
                                "type": "inputMedia",
                                "children": [],
                                "name": "meta.OR",
                                "description": "",
                                "label": "Upload O.R. / C.R.",
                                "disabled": true
                            }
                        ],
                        "orientation": "horizontal"
                    }
                ],
                "orientation": "vertical",
                "name": "view-unit-request-vehicle-sticker",
                "_sortIndex": 650
            },
            {
                "id": "42414a60-1697-11eb-9686-795f0ea8c25b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "f8571491-1685-11eb-9686-795f0ea8c25b",
                        "children": [
                            {
                                "id": "4b92b270-1697-11eb-9686-795f0ea8c25b",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered",
                                "text": "Message Board"
                            },
                            {
                                "id": "0e292670-1698-11eb-9686-795f0ea8c25b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1324c8f0-1698-11eb-9686-795f0ea8c25b",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "21d96ef0-1698-11eb-9686-795f0ea8c25b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "2617dbf0-1698-11eb-9686-795f0ea8c25b",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Homeowner",
                                        "dataField": "homeowner",
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "34022d10-1698-11eb-9686-795f0ea8c25b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3743ea90-1698-11eb-9686-795f0ea8c25b",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Topic",
                                        "dataField": "topic",
                                        "disabled": true,
                                        "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"LF\", \"label\": \"LF (Looking For)\"}]"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "1bf85900-1699-11eb-9686-795f0ea8c25b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1eff5770-1699-11eb-9686-795f0ea8c25b",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Title",
                                        "dataField": "title",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "279362f0-1699-11eb-9686-795f0ea8c25b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "285923f0-1699-11eb-9686-795f0ea8c25b",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "price",
                                        "label": "Price",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "30ab4470-1699-11eb-9686-795f0ea8c25b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "31933370-1699-11eb-9686-795f0ea8c25b",
                                        "type": "inputText",
                                        "children": [],
                                        "disabled": true,
                                        "multiline": true,
                                        "dataField": "description",
                                        "label": "Description"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3f7239f0-1699-11eb-9686-795f0ea8c25b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4348e470-1699-11eb-9686-795f0ea8c25b",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "contactNumber",
                                        "label": "Contact Number",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4d43d2f0-1699-11eb-9686-795f0ea8c25b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "505c3680-1699-11eb-9686-795f0ea8c25b",
                                        "type": "inputMedia",
                                        "children": [],
                                        "basePath": "message-boards",
                                        "dataField": "photos",
                                        "label": "Photos",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "9805a1e0-169b-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9f8c0df0-169b-11eb-a20e-bba0e48fa309",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "dataField": "datePosted",
                                        "label": "Date Posted",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "b53dcbc0-169b-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b9916e70-169b-11eb-a20e-bba0e48fa309",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Status",
                                        "disabled": true,
                                        "dataField": "status",
                                        "options": "[{\"value\": \"done\", \"label\": \"Done\"}, {\"value\": \"approved\", \"label\": \"Approved\"}, {\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]"
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops"
                            },
                            {
                                "id": "cf34d460-169b-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e9280c70-169b-11eb-a20e-bba0e48fa309",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "multiline": true,
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops"
                            },
                            {
                                "id": "1bd00920-169c-11eb-8c30-87365a215fb4",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1d419a80-169c-11eb-8c30-87365a215fb4",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Status",
                                        "options": "[{\"value\": \"done\", \"label\": \"Done\"}, {\"value\": \"approved\", \"label\": \"Approved\"}, {\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                        "saveObject": true,
                                        "dataField": "status",
                                        "access": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "30ee1270-169c-11eb-8c30-87365a215fb4",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "32d17870-169c-11eb-8c30-87365a215fb4",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "4db7ea20-169c-11eb-8c30-87365a215fb4",
                                "type": "subView",
                                "children": [],
                                "access": "admin,pmg",
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "message-board-detail-page",
                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                "route": "/message-boards/detail/:id"
            },
            {
                "id": "89516680-169a-11eb-a20e-bba0e48fa309",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "89516681-169a-11eb-a20e-bba0e48fa309",
                        "children": [
                            {
                                "id": "92ffe2b0-169a-11eb-a20e-bba0e48fa309",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered",
                                "text": "Comments and Suggestions"
                            },
                            {
                                "id": "baf5aed0-169a-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bda405f0-169a-11eb-a20e-bba0e48fa309",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Type",
                                        "disabled": true,
                                        "dataField": "type",
                                        "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"}, {\"label\": \"Security\", \"value\": \"security\"}, {\"label\": \"Safety\", \"value\": \"safety\"}, {\"label\": \"PMG Crew/ Team Conduct\", \"value\": \"team-conduct\"} ,{\"label\": \"Others\", \"value\": \"others\"}]"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c8c8ce70-169a-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d2e5eaf0-169a-11eb-a20e-bba0e48fa309",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Details",
                                        "multiline": true,
                                        "disabled": true,
                                        "dataField": "details"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "e8155960-169a-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f0ede7f0-169a-11eb-a20e-bba0e48fa309",
                                        "type": "inputMedia",
                                        "children": [],
                                        "basePath": "comments-suggestions",
                                        "label": "Photos",
                                        "disabled": true,
                                        "dataField": "photos"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "ff28a300-169a-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "00684ef0-169b-11eb-a20e-bba0e48fa309",
                                        "type": "inputSelect",
                                        "children": [],
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "dataField": "homeowner",
                                        "label": "Homeowner",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "15204370-169b-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "16cf2bf0-169b-11eb-a20e-bba0e48fa309",
                                        "type": "inputText",
                                        "children": [],
                                        "multiline": true,
                                        "dataField": "notes",
                                        "label": "Notes",
                                        "disabled": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "pmg-ops"
                            },
                            {
                                "id": "31f0aad0-169b-11eb-a20e-bba0e48fa309",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "336681f0-169b-11eb-a20e-bba0e48fa309",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Notes",
                                        "dataField": "notes",
                                        "multiline": true,
                                        "access": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "access": "admin,pmg"
                            },
                            {
                                "id": "4eacedf0-169b-11eb-a20e-bba0e48fa309",
                                "type": "subView",
                                "children": [],
                                "access": "admin,pmg",
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "comments-suggestions-detail-page",
                "route": "/comments-suggestions/detail/:id",
                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1"
            },
            {
                "id": "f69b9500-169d-11eb-8c30-87365a215fb4",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "f69b9501-169d-11eb-8c30-87365a215fb4",
                        "children": [
                            {
                                "id": "fe1fde30-169d-11eb-8c30-87365a215fb4",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Billing",
                                "className": "has-text-centered"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "billing-list-detail-page"
            },
            {
                "id": "c677f620-26ec-11eb-866d-ef520cc7ee92",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c677f621-26ec-11eb-866d-ef520cc7ee92",
                        "children": [
                            {
                                "id": "c6a1c540-26ec-11eb-866d-ef520cc7ee92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c6a1c542-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit",
                                        "saveObject": true,
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c6a1c543-26ec-11eb-866d-ef520cc7ee92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6fd162b0-26ed-11eb-866d-ef520cc7ee92",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Type",
                                        "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                        "saveObject": true,
                                        "dataField": "type"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c6a1ec5b-26ec-11eb-866d-ef520cc7ee92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c3d653c0-26ed-11eb-866d-ef520cc7ee92",
                                        "type": "subView",
                                        "children": [],
                                        "view": "5e6330a0-1647-11eb-91d3-e743baeee069",
                                        "name": "view-visitor-pass-visitor-pass"
                                    },
                                    {
                                        "id": "cf443330-26ed-11eb-866d-ef520cc7ee92",
                                        "type": "subView",
                                        "children": [],
                                        "view": "69176030-164b-11eb-8554-b34543e4ea31",
                                        "name": "view-visitor-pass-short-term"
                                    },
                                    {
                                        "id": "d50ce9b0-26ed-11eb-866d-ef520cc7ee92",
                                        "type": "subView",
                                        "children": [],
                                        "view": "2d3e3830-164c-11eb-8554-b34543e4ea31",
                                        "name": "view-visitor-pass-long-term"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "view-visitor-pass",
                                "component": "VisitorPass"
                            },
                            {
                                "id": "c6a1ec5e-26ec-11eb-866d-ef520cc7ee92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c6a1ec60-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c6a1ec61-26ec-11eb-866d-ef520cc7ee92",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c6a1ec63-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "pass-visitor-archive-page",
                "route": "/pass-visitors/archive/:id",
                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                "access": "noone"
            },
            {
                "id": "c6aa77d0-26ec-11eb-866d-ef520cc7ee92",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "c677f621-26ec-11eb-866d-ef520cc7ee92",
                        "children": [
                            {
                                "id": "c6b57450-26ec-11eb-866d-ef520cc7ee92",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                            },
                            {
                                "id": "c6bf8670-26ec-11eb-866d-ef520cc7ee92",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "c6c68b50-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "dataField": "",
                                        "date": true
                                    },
                                    {
                                        "id": "c6c68b52-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit"
                                    },
                                    {
                                        "id": "c6c68b5a-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "type",
                                        "label": "Type",
                                        "dataField": "type"
                                    },
                                    {
                                        "id": "c6c68b54-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "startDate",
                                        "label": "Start Date",
                                        "dataField": "startDate"
                                    },
                                    {
                                        "id": "c6c68b56-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "endDate",
                                        "label": "End Date",
                                        "dataField": "endDate"
                                    },
                                    {
                                        "id": "c6c68b5c-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status"
                                    },
                                    {
                                        "id": "c6c68b5e-26ec-11eb-866d-ef520cc7ee92",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/pass-visitors/archive"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "pass-visitor-archive-list",
                "route": "/pass-visitors-list/archive",
                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                "params": "{\"or:\":[{\"status\": \"checked-out\"},{\"status\": \"disapproved\"}]}"
            },
            {
                "id": "43514190-26ef-11eb-96bc-0f1c3895619b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "43514191-26ef-11eb-96bc-0f1c3895619b",
                        "children": [
                            {
                                "id": "436b8050-26ef-11eb-96bc-0f1c3895619b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "436b8052-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit",
                                        "saveObject": true,
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "436b8053-26ef-11eb-96bc-0f1c3895619b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "44b87d40-26f0-11eb-96bc-0f1c3895619b",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Type",
                                        "name": "type_.label"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "436b8056-26ef-11eb-96bc-0f1c3895619b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bf28e660-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "dataField": "schedule",
                                        "format": "",
                                        "label": "Schedule"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "436ba760-26ef-11eb-96bc-0f1c3895619b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d59e1b40-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "inputList",
                                        "children": [],
                                        "label": "Items",
                                        "dataField": "items"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "436ba763-26ef-11eb-96bc-0f1c3895619b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "436ba765-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "company",
                                        "label": "Company",
                                        "dataField": "company"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "436ba766-26ef-11eb-96bc-0f1c3895619b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "436ba768-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "status_.label",
                                        "label": "Status",
                                        "dataField": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "436ba769-26ef-11eb-96bc-0f1c3895619b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "436ba76b-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "pass-delivery-archive-page",
                "route": "/pass-deliveries/archive/:id",
                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                "access": "noone"
            },
            {
                "id": "437607a0-26ef-11eb-96bc-0f1c3895619b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "43514191-26ef-11eb-96bc-0f1c3895619b",
                        "children": [
                            {
                                "id": "437d5aa0-26ef-11eb-96bc-0f1c3895619b",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                            },
                            {
                                "id": "4385e620-26ef-11eb-96bc-0f1c3895619b",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "67887f10-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "date": true
                                    },
                                    {
                                        "id": "438e2380-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit"
                                    },
                                    {
                                        "id": "438e2382-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "type",
                                        "label": "Type",
                                        "dataField": "type"
                                    },
                                    {
                                        "id": "438e2384-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "schedule",
                                        "label": "Schedule",
                                        "dataField": "schedule"
                                    },
                                    {
                                        "id": "438e2388-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "company",
                                        "label": "Company",
                                        "dataField": "company"
                                    },
                                    {
                                        "id": "438e238a-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status"
                                    },
                                    {
                                        "id": "438e4a90-26ef-11eb-96bc-0f1c3895619b",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/pass-deliveries/archive"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "pass-delivery-archive-list",
                "route": "/pass-deliveries-list/archive",
                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                "params": "{\"or:\":[{\"status\": \"done\"},{\"status\": \"cancelled\"}]}"
            },
            {
                "id": "5f9cfdb0-26f1-11eb-ac91-c74c3e25a4bd",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                        "children": [
                            {
                                "id": "5fae62d0-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae62d2-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit",
                                        "saveObject": true,
                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae62d3-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae62d5-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "natureOfWork_.label",
                                        "label": "Nature of Work",
                                        "dataField": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae62d6-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae62d8-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "contractor",
                                        "label": "Contractor",
                                        "dataField": "contractor"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae62d9-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae62db-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "personInCharge",
                                        "label": "Person in Charge",
                                        "dataField": "personInCharge"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae62dc-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae62de-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "contactPersonInCharge",
                                        "label": "Contact Number of Person-in-Charge",
                                        "dataField": "contactPersonInCharge"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89e0-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "09f6ea50-26f2-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputList",
                                        "children": [],
                                        "label": "Workers",
                                        "dataField": "workers"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89e3-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae89e5-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89e6-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "f01dc9f0-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputList",
                                        "children": [],
                                        "label": "Materials",
                                        "dataField": "materials"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89e9-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "15a68db0-26f2-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputList",
                                        "children": [],
                                        "label": "Tools",
                                        "dataField": "tools"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89ec-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae89ee-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "startDate",
                                        "label": "Start Date",
                                        "dataField": "startDate",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89ef-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae89f1-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "endDate",
                                        "label": "End Date",
                                        "dataField": "endDate",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89f2-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae89f4-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "plans",
                                        "label": "Plans",
                                        "dataField": "plans",
                                        "basePath": "work-permits"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89f5-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae89f7-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "status_.label",
                                        "label": "Status",
                                        "dataField": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5fae89f8-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5fae89fa-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "work-permit-archive-page",
                "route": "/work-permits/archive/:id",
                "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                "access": "noone"
            },
            {
                "id": "5fbb8230-26f1-11eb-ac91-c74c3e25a4bd",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                        "children": [
                            {
                                "id": "5fc6ccd0-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                            },
                            {
                                "id": "5fce6df0-26f1-11eb-ac91-c74c3e25a4bd",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "95fb72b0-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Date Submitted",
                                        "name": "createdAt",
                                        "date": true
                                    },
                                    {
                                        "id": "5fd72080-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit"
                                    },
                                    {
                                        "id": "5fd72082-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "natureOfWork",
                                        "label": "Nature of Work",
                                        "dataField": "natureOfWork"
                                    },
                                    {
                                        "id": "5fd72084-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "contractor",
                                        "label": "Contractor",
                                        "dataField": "contractor"
                                    },
                                    {
                                        "id": "5fd72086-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "personInCharge",
                                        "label": "Person in Charge",
                                        "dataField": "personInCharge"
                                    },
                                    {
                                        "id": "5fd72088-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "contactPersonInCharge",
                                        "label": "Contact Number of Person-in-Charge",
                                        "dataField": "contactPersonInCharge"
                                    },
                                    {
                                        "id": "5fd72092-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "startDate",
                                        "label": "Start Date",
                                        "dataField": "startDate"
                                    },
                                    {
                                        "id": "5fd72094-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "endDate",
                                        "label": "End Date",
                                        "dataField": "endDate"
                                    },
                                    {
                                        "id": "5fd72098-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status"
                                    },
                                    {
                                        "id": "5fd7209a-26f1-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/work-permits/archive"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "work-permit-archive-list",
                "route": "/work-permits-list/archive",
                "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                "params": "{\"or:\":[{\"status\": \"done\"},{\"status\": \"cancelled\"}]}"
            },
            {
                "id": "cb6da100-26f3-11eb-ac91-c74c3e25a4bd",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                        "children": [
                            {
                                "id": "cb839a06-26f3-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e2736a30-2700-11eb-ac91-c74c3e25a4bd",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "f1a9ea60-2700-11eb-ac91-c74c3e25a4bd",
                                                "type": "inputText",
                                                "children": [
                                                    {
                                                        "id": "4eb402da-2702-11eb-ac91-c74c3e25a4bd",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "contactNumber",
                                                        "label": "Contact Number",
                                                        "dataField": "contactNumber"
                                                    }
                                                ],
                                                "name": "type_.label",
                                                "label": "Type"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "type-container"
                                    },
                                    {
                                        "id": "ff372760-2700-11eb-ac91-c74c3e25a4bd",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "cb839a08-26f3-11eb-ac91-c74c3e25a4bd",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "unit",
                                                "label": "Unit",
                                                "dataField": "unit",
                                                "saveObject": true,
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "unit-container"
                                    },
                                    {
                                        "id": "1df024e0-2701-11eb-ac91-c74c3e25a4bd",
                                        "type": "subView",
                                        "children": [],
                                        "view": "989101e3-d9a6-11ea-891d-3722b00ad4e7",
                                        "name": "view-unit-request-aircon-cleaning"
                                    },
                                    {
                                        "id": "34aee5e0-2701-11eb-ac91-c74c3e25a4bd",
                                        "type": "subView",
                                        "children": [],
                                        "view": "0c744882-d9a5-11ea-a304-319701533d32",
                                        "name": "view-unit-request-grease-trap-cleaning"
                                    },
                                    {
                                        "id": "45432150-2701-11eb-ac91-c74c3e25a4bd",
                                        "type": "subView",
                                        "children": [],
                                        "view": "f7b9bf21-d9a8-11ea-9331-090047662dd2",
                                        "name": "view-unit-request-others"
                                    },
                                    {
                                        "id": "59330ae0-2701-11eb-ac91-c74c3e25a4bd",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e60fdb41-c910-11ea-9dd0-6723feee229e",
                                        "name": "view-unit-request-plans-documents"
                                    },
                                    {
                                        "id": "63b578e0-2701-11eb-ac91-c74c3e25a4bd",
                                        "type": "subView",
                                        "children": [],
                                        "view": "063f7331-c911-11ea-9dd0-6723feee229e",
                                        "name": "view-unit-request-refund-renovation-bond"
                                    },
                                    {
                                        "id": "71da13e0-2701-11eb-ac91-c74c3e25a4bd",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e00cd7b1-c90c-11ea-8c59-196c3ec4f839",
                                        "name": "view-unit-request-service-request"
                                    },
                                    {
                                        "id": "7e5d33e0-2701-11eb-ac91-c74c3e25a4bd",
                                        "type": "subView",
                                        "children": [],
                                        "view": "8df270e1-d918-11ea-b3eb-d9cf4159b042",
                                        "name": "view-unit-request-vehicle-sticker"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "view-unit-request",
                                "component": "UnitRequest"
                            },
                            {
                                "id": "cb839a03-26f3-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "cb839a05-26f3-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "status_.label",
                                        "label": "Status",
                                        "dataField": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "cb839a09-26f3-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "cb839a0b-26f3-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "unit-request-archive-page",
                "route": "/unit-requests/archive/:id",
                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                "access": "noone"
            },
            {
                "id": "cb8ce8d0-26f3-11eb-ac91-c74c3e25a4bd",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                        "children": [
                            {
                                "id": "cb9637a0-26f3-11eb-ac91-c74c3e25a4bd",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                            },
                            {
                                "id": "cb9cee60-26f3-11eb-ac91-c74c3e25a4bd",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "1058f340-26f5-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "date": true
                                    },
                                    {
                                        "id": "cba504b4-26f3-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "unit",
                                        "label": "Unit",
                                        "dataField": "unit"
                                    },
                                    {
                                        "id": "cba504b0-26f3-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "type",
                                        "label": "Type",
                                        "dataField": "type"
                                    },
                                    {
                                        "id": "cba504b2-26f3-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status"
                                    },
                                    {
                                        "id": "cba504b6-26f3-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/unit-requests/archive"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "unit-request-archive-list",
                "route": "/unit-requests-list/archive",
                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                "params": "{\"or:\":[{\"status\": \"completed\"},{\"status\": \"cancelled\"}]}"
            },
            {
                "id": "4e7217d0-2702-11eb-ac91-c74c3e25a4bd",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4b5",
                        "children": [
                            {
                                "id": "f5002f60-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fc57ea50-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "createdAt",
                                        "label": "Date Submitted"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e2b50-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e2b52-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner",
                                        "saveObject": true,
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e2b53-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e2b55-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "topic_.label",
                                        "label": "Topic",
                                        "dataField": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e2b56-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e2b58-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "title",
                                        "label": "Title",
                                        "dataField": "title"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e2b59-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e2b5b-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "price",
                                        "label": "Price",
                                        "dataField": "price"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e2b5c-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e2b5e-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e2b5f-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e5261-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "contactNumber",
                                        "label": "ContactNumber",
                                        "dataField": "contactNumber"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e5262-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e5264-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "photos",
                                        "label": "Photos",
                                        "dataField": "photos",
                                        "basePath": "message-boards"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e5265-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e5267-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "status_.label",
                                        "label": "Status",
                                        "dataField": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e5268-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e526a-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4e8e526b-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4e8e526d-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "datePosted",
                                        "label": "Date Posted",
                                        "dataField": "datePosted",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "message-board-archive-page",
                "route": "/message-boards/archive/:id",
                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                "access": "noone",
                "params": ""
            },
            {
                "id": "4e9a8760-2702-11eb-ac91-c74c3e25a4bd",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "5f9cfdb1-26f1-11eb-ac91-c74c3e25a4bd",
                        "children": [
                            {
                                "id": "4ea339f0-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                            },
                            {
                                "id": "4eabc570-2702-11eb-ac91-c74c3e25a4bd",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "844b3030-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Submitted",
                                        "date": true
                                    },
                                    {
                                        "id": "4eb402d2-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "topic",
                                        "label": "Topic",
                                        "dataField": "topic"
                                    },
                                    {
                                        "id": "4eb402d4-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "title",
                                        "label": "Title",
                                        "dataField": "title"
                                    },
                                    {
                                        "id": "4eb402d6-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "price",
                                        "label": "Price",
                                        "dataField": "price"
                                    },
                                    {
                                        "id": "4eb402d8-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "description",
                                        "label": "Description",
                                        "dataField": "description"
                                    },
                                    {
                                        "id": "4eb402da-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "contactNumber",
                                        "label": "ContactNumber",
                                        "dataField": "contactNumber"
                                    },
                                    {
                                        "id": "4eb402d0-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner"
                                    },
                                    {
                                        "id": "4eb402de-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "status",
                                        "label": "Status",
                                        "dataField": "status"
                                    },
                                    {
                                        "id": "4eb429e1-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "notes",
                                        "label": "Notes",
                                        "dataField": "notes"
                                    },
                                    {
                                        "id": "4eb429e3-2702-11eb-ac91-c74c3e25a4bd",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "datePosted",
                                        "label": "Date Posted",
                                        "dataField": "datePosted",
                                        "date": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/message-boards/archive"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "message-board-archive-list",
                "route": "/message-boards-list/archive",
                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                "params": "{\"or:\":[{\"status\": \"archived\"},{\"status\": \"disapproved\"}]}"
            },
            {
                "id": "14e8e930-2946-11eb-a404-b5701db75156",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "14e8e931-2946-11eb-a404-b5701db751567",
                        "children": [
                            {
                                "id": "15010513-2946-11eb-a404-b5701db75156",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "15010515-2946-11eb-a404-b5701db75156",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "text",
                                        "label": "Text",
                                        "dataField": "text"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "15010516-2946-11eb-a404-b5701db75156",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "15010518-2946-11eb-a404-b5701db75156",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "noticeStart",
                                        "label": "NoticeStart",
                                        "dataField": "noticeStart",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "15010519-2946-11eb-a404-b5701db75156",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "1501051b-2946-11eb-a404-b5701db75156",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "noticeEnd",
                                        "label": "NoticeEnd",
                                        "dataField": "noticeEnd",
                                        "format": ""
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "1501051f-2946-11eb-a404-b5701db75156",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "15010521-2946-11eb-a404-b5701db75156",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "status_.label",
                                        "label": "Status",
                                        "dataField": "status"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "15010522-2946-11eb-a404-b5701db75156",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "15010524-2946-11eb-a404-b5701db75156",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner",
                                        "saveObject": true,
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "notification-archive-page",
                "route": "/notifications/archive/:id",
                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                "access": "noone"
            },
            {
                "id": "150c9dd0-2946-11eb-a404-b5701db75156",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "14e8e931-2946-11eb-a404-b5701db75157",
                        "children": [
                            {
                                "id": "151884b0-2946-11eb-a404-b5701db75156",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                            },
                            {
                                "id": "15215e50-2946-11eb-a404-b5701db75156",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "152aad20-2946-11eb-a404-b5701db75156",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "label": "Date Created",
                                        "dataField": "",
                                        "date": true
                                    },
                                    {
                                        "id": "152ad431-2946-11eb-a404-b5701db75156",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "",
                                        "label": "Sent To",
                                        "dataField": "homeowner"
                                    },
                                    {
                                        "id": "152ad437-2946-11eb-a404-b5701db75156",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "text",
                                        "label": "Content",
                                        "dataField": "text"
                                    },
                                    {
                                        "id": "152ad433-2946-11eb-a404-b5701db75156",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "noticeStart",
                                        "label": "NoticeStart",
                                        "dataField": "noticeStart",
                                        "date": true
                                    },
                                    {
                                        "id": "152ad435-2946-11eb-a404-b5701db75156",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "noticeEnd",
                                        "label": "NoticeEnd",
                                        "dataField": "noticeEnd",
                                        "date": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/notifications/archive"
                            }
                        ],
                        "component": "ArchivedNotifications"
                    }
                ],
                "orientation": "vertical",
                "name": "notification-archive-list",
                "route": "/notifications-list/archive",
                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721"
            },
            {
                "id": "bcd0c6c0-2e5c-11eb-95e9-b3974aa0f0c3",
                "type": "page",
                "children": [
                    {
                        "id": "f24f6310-2e5c-11eb-95e9-b3974aa0f0c3",
                        "type": "container",
                        "children": [
                            {
                                "id": "f6cc86c0-2e5c-11eb-95e9-b3974aa0f0c3",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered my-1",
                                "text": "Billings History"
                            },
                            {
                                "id": "0c56bf60-2e5d-11eb-95e9-b3974aa0f0c3",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "0eac4820-2e5d-11eb-95e9-b3974aa0f0c3",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "1fc2fa00-2e5d-11eb-95e9-b3974aa0f0c3",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Statement",
                                                "name": "name"
                                            },
                                            {
                                                "id": "369df590-2e5d-11eb-95e9-b3974aa0f0c3",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Posting Date",
                                                "name": "postingDate"
                                            },
                                            {
                                                "id": "457da330-2e5d-11eb-95e9-b3974aa0f0c3",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "Due Date",
                                                "name": "dueDate"
                                            },
                                            {
                                                "id": "5824b910-2e5d-11eb-95e9-b3974aa0f0c3",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "amount",
                                                "label": "Amount"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": "/account/homeowner-billings"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "main",
                        "component": "ArchivedBillings"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-history-billings",
                "route": "/account/billings/history"
            },
            {
                "id": "4a6b4a10-2eac-11eb-ba26-4746b2dda6eb",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "4a6b4a11-2eac-11eb-ba26-4746b2dda6eb",
                        "children": [
                            {
                                "id": "4a9407c0-2eac-11eb-ba26-4746b2dda6eb",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4a9407c2-2eac-11eb-ba26-4746b2dda6eb",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner",
                                        "saveObject": true,
                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4a9407c3-2eac-11eb-ba26-4746b2dda6eb",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4a9407c5-2eac-11eb-ba26-4746b2dda6eb",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "name",
                                        "label": "Statement",
                                        "dataField": "name"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4a9407c9-2eac-11eb-ba26-4746b2dda6eb",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4a9407cb-2eac-11eb-ba26-4746b2dda6eb",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "dueDate",
                                        "label": "Due Date",
                                        "dataField": "dueDate",
                                        "format": "mm/dd/yyyy"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "4a9407cc-2eac-11eb-ba26-4746b2dda6eb",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4a9407ce-2eac-11eb-ba26-4746b2dda6eb",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "postingDate",
                                        "label": "Posting Date",
                                        "dataField": "postingDate",
                                        "format": "mm/dd/yyyy"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "46cc5bf0-2ead-11eb-ba26-4746b2dda6eb",
                                "type": "subView",
                                "children": [],
                                "view": "3acc2620-e865-11ea-9def-530e14f0f646",
                                "relation": "billing"
                            }
                        ],
                        "component": ""
                    }
                ],
                "orientation": "vertical",
                "name": "billing-archive-page",
                "route": "/billings-archive/:id",
                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                "access": "noone"
            },
            {
                "id": "4a9cba50-2eac-11eb-ba26-4746b2dda6ec",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "4a6b4a11-2eac-11eb-ba26-4746b2dda6ec",
                        "children": [
                            {
                                "id": "4aa34a00-2eac-11eb-ba26-4746b2dda6eb",
                                "type": "subView",
                                "children": [],
                                "name": "searchbar",
                                "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729"
                            },
                            {
                                "id": "4aa8c840-2eac-11eb-ba26-4746b2dda6eb",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "4aadf860-2eac-11eb-ba26-4746b2dda6eb",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "homeowner",
                                        "label": "Homeowner",
                                        "dataField": "homeowner"
                                    },
                                    {
                                        "id": "4aadf862-2eac-11eb-ba26-4746b2dda6eb",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "name",
                                        "label": "Statement",
                                        "dataField": "name"
                                    },
                                    {
                                        "id": "4aadf866-2eac-11eb-ba26-4746b2dda6eb",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "dueDate",
                                        "label": "Due Date",
                                        "dataField": "dueDate",
                                        "date": true
                                    },
                                    {
                                        "id": "4aadf868-2eac-11eb-ba26-4746b2dda6eb",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "postingDate",
                                        "label": "Posting Date",
                                        "dataField": "postingDate",
                                        "date": true
                                    },
                                    {
                                        "id": "1e345c60-2ead-11eb-ba26-4746b2dda6eb",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "label": "Amount",
                                        "name": "amount",
                                        "currency": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "table",
                                "path": "/billings-archive"
                            }
                        ],
                        "component": "ArchivedBillings"
                    }
                ],
                "orientation": "vertical",
                "name": "billing-archive-list",
                "route": "/billings-list/archive",
                "dataModel": ""
            },
            {
                "id": "6cc2b230-3a6c-11eb-9608-13f79b46a41f",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "6cc2d940-3a6c-11eb-9608-13f79b46a41f",
                        "children": [
                            {
                                "id": "7211fd80-3a6d-11eb-9608-13f79b46a41f",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Pay with Paymaya",
                                "className": "my-2"
                            },
                            {
                                "id": "bdd82470-3a6c-11eb-9608-13f79b46a41f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c12d42e0-3a6c-11eb-9608-13f79b46a41f",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "mode",
                                        "className": "hide",
                                        "defaultValue": "Paymaya"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "cc918560-3a6c-11eb-9608-13f79b46a41f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "cf4c1180-3a6c-11eb-9608-13f79b46a41f",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "amount",
                                        "label": "Amount",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "dd3db5a0-3a6c-11eb-9608-13f79b46a41f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e2bb3250-3a6c-11eb-9608-13f79b46a41f",
                                        "type": "inputText",
                                        "children": [],
                                        "dataField": "particulars",
                                        "label": "Particulars",
                                        "multiline": true,
                                        "line": "8",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3ebde250-3a6d-11eb-9608-13f79b46a41f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "557af5a0-3a6d-11eb-9608-13f79b46a41f",
                                        "type": "button",
                                        "children": [],
                                        "name": "save",
                                        "command": "pay",
                                        "text": "Submit"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "component": "Paymaya"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-pay-dues-paymaya",
                "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3",
                "route": "/account/pay-dues/paymaya/:id"
            },
            {
                "id": "a83bf500-3a6d-11eb-9608-13f79b46a41f",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "6cc2d940-3a6c-11eb-9608-13f79b46a42f",
                        "children": [
                            {
                                "id": "bee50b20-3a6d-11eb-9608-13f79b46a41f",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Pay with Credit Card",
                                "className": "my-2"
                            },
                            {
                                "id": "d8f65280-3a6d-11eb-9608-13f79b46a41f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "dcdf4c80-3a6d-11eb-9608-13f79b46a41f",
                                        "type": "inputText",
                                        "children": [],
                                        "defaultValue": "Credit Card",
                                        "className": "hide",
                                        "dataField": "mode"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "e8ab2a70-3a6d-11eb-9608-13f79b46a41f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "eaf2a970-3a6d-11eb-9608-13f79b46a41f",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Amount",
                                        "dataField": "amount",
                                        "required": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "0566f860-3a6e-11eb-9608-13f79b46a41f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "08174b50-3a6e-11eb-9608-13f79b46a41f",
                                        "type": "inputText",
                                        "children": [],
                                        "multiline": true,
                                        "line": "8",
                                        "label": "Particulars",
                                        "required": true,
                                        "dataField": "particulars"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "2113a040-3a6e-11eb-9608-13f79b46a41f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "2207c440-3a6e-11eb-9608-13f79b46a41f",
                                        "type": "button",
                                        "children": [],
                                        "name": "save",
                                        "path": "",
                                        "text": "Submit",
                                        "command": "pay"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "component": "Paymaya"
                    }
                ],
                "orientation": "vertical",
                "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3",
                "route": "/account/pay-dues/credit-card/:id",
                "name": "homeowner-pay-dues-credit-card"
            },
            {
                "id": "cee2ae10-3a7c-11eb-81e5-7178adbe2b5b",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "cee2ae11-3a7c-11eb-81e5-7178adbe2b5b",
                        "children": [
                            {
                                "id": "d900b4f0-3a7c-11eb-81e5-7178adbe2b5b",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Payment Success"
                            },
                            {
                                "id": "35b06dd0-3a7d-11eb-81e5-7178adbe2b5b",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3730f350-3a7d-11eb-81e5-7178adbe2b5b",
                                        "type": "textBlock",
                                        "children": [],
                                        "text": "Your online payment was successful."
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "py-1"
                            },
                            {
                                "id": "4dae0580-3a7f-11eb-b43b-35557e2f71d4",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4f08b380-3a7f-11eb-b43b-35557e2f71d4",
                                        "type": "button",
                                        "children": [],
                                        "className": "mt-2",
                                        "variant": "text",
                                        "text": "Done",
                                        "path": "/app"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-pay-dues-success",
                "route": "/account/pay-dues/success"
            },
            {
                "id": "71570230-3a7e-11eb-95a7-0f83460415e3",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "71572940-3a7e-11eb-95a7-0f83460415e3",
                        "children": [
                            {
                                "id": "7aa9c9d0-3a7e-11eb-95a7-0f83460415e3",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "text": "Payment Failed"
                            },
                            {
                                "id": "80682010-3a7e-11eb-95a7-0f83460415e3",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "8f2eee80-3a7e-11eb-95a7-0f83460415e3",
                                        "type": "textBlock",
                                        "children": [],
                                        "text": "Your online payment was not successful. You may try again later!"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "06547cf0-3a7f-11eb-b43b-35557e2f71d4",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "078cd5e0-3a7f-11eb-b43b-35557e2f71d4",
                                        "type": "button",
                                        "children": [],
                                        "text": "other payment method",
                                        "variant": "text",
                                        "path": "/account/pay-dues"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "mt-2"
                            }
                        ]
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-pay-dues-failed",
                "route": "/account/pay-dues/failed"
            },
            {
                "id": "17971730-4019-11eb-8b08-a5590ea41304",
                "type": "page",
                "children": [
                    {
                        "name": "main",
                        "type": "container",
                        "id": "17971731-4019-11eb-8b08-a5590ea41304",
                        "component": "ArchivedNotifications",
                        "children": [
                            {
                                "id": "84e6f260-4019-11eb-8b08-a5590ea41304",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "className": "has-text-centered pb-2",
                                "text": "Archived Notifications"
                            },
                            {
                                "id": "955c8240-4019-11eb-8b08-a5590ea41304",
                                "type": "table",
                                "children": [
                                    {
                                        "id": "96e1e9c0-4019-11eb-8b08-a5590ea41304",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "createdAt",
                                        "date": true,
                                        "label": "Date Created",
                                        "format": "MM/dd/yyyy hh:mm a",
                                        "width": "20"
                                    },
                                    {
                                        "id": "9f1585c0-4019-11eb-8b08-a5590ea41304",
                                        "type": "column",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "text",
                                        "label": "Content",
                                        "dataField": "text"
                                    }
                                ],
                                "orientation": "horizontal",
                                "path": "#"
                            }
                        ],
                        "orientation": "vertical"
                    }
                ],
                "orientation": "vertical",
                "name": "homeowner-notifications-archived",
                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                "route": "/notifications-archived"
            }
        ],
        "server": "pacificland.bitesizedigital.net",
        "undoLength": 0,
        "name": "pacific land - midpoint",
        "_state": {},
        "activeApp": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
        "_locked": false,
        "updateKey": "e715e090-8731-11eb-af99-d36daa69b705",
        "": {
            "id": "5f30e16ddf453329f6ef693d",
            "type": "project",
            "routes": [
                {
                    "page": "billing-detail-page",
                    "path": "/billings/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                        "type": "page"
                    }
                },
                {
                    "page": "billing-item-detail-page",
                    "path": "/billing-items/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "3ab60610-e865-11ea-9def-530e14f0f646",
                        "type": "page"
                    }
                },
                {
                    "page": "billing-list-page",
                    "path": "/billings-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "7aac26c0-9138-11ea-bd78-399a56c40916",
                        "type": "page"
                    }
                },
                {
                    "page": "comments-suggestions-list-page",
                    "path": "/comments-suggestions-list",
                    "requireAuth": true,
                    "node": {
                        "_id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                        "type": "page"
                    }
                },
                {
                    "page": "dashboard-login",
                    "path": "/dashboard-login",
                    "layout": "full",
                    "requireAuth": false,
                    "node": {
                        "_id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
                        "type": "page"
                    }
                },
                {
                    "page": "department-detail-page",
                    "path": "/departments/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                        "type": "page"
                    }
                },
                {
                    "page": "department-list-page",
                    "path": "/departments-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                        "type": "page"
                    }
                },
                {
                    "page": "employee-detail-page",
                    "path": "/employees/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                        "type": "page"
                    }
                },
                {
                    "page": "employee-list-page",
                    "path": "/employees-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-account",
                    "path": "/account",
                    "requireAuth": false,
                    "node": {
                        "_id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-billing",
                    "path": "/account/billings",
                    "requireAuth": false,
                    "node": {
                        "_id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-comments-suggestions",
                    "path": "/homeowner-comments-suggestions/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-complaint",
                    "path": "/file-complaint/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-complaints",
                    "path": "/filed-complaints",
                    "requireAuth": false,
                    "node": {
                        "_id": "47440520-971c-11ea-8a71-fd00fe88963d",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-delivery-pass",
                    "path": "/create-delivery-pass/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-delivery-passes",
                    "path": "/delivery-passes",
                    "requireAuth": false,
                    "node": {
                        "_id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-detail-page",
                    "path": "/homeowners/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "91150f60-ef9a-11ea-9d3f-8def54ed8352",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-list-page",
                    "path": "/homeowners-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "913e9060-ef9a-11ea-9d3f-8def54ed8352",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-login-page",
                    "path": "/homeowners-login",
                    "layout": "full",
                    "requireAuth": false,
                    "node": {
                        "_id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-marketplace",
                    "path": "/market-place",
                    "requireAuth": false,
                    "node": {
                        "_id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-message-board",
                    "path": "/message-board",
                    "requireAuth": false,
                    "node": {
                        "_id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-new-post",
                    "path": "/message-board/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-notifications",
                    "path": "/notifications",
                    "requireAuth": false,
                    "node": {
                        "_id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-password",
                    "path": "/account/change-password",
                    "requireAuth": false,
                    "node": {
                        "_id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-post",
                    "path": "/message-board-post/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-profile",
                    "path": "/account/profile/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "17466790-96d2-11ea-9995-9d94cc393546",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-service-request",
                    "path": "/create-service-request/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-service-requests",
                    "path": "/service-requests",
                    "requireAuth": false,
                    "node": {
                        "_id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-unit-request",
                    "path": "/homeowner-unit-request/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-visitor-pass",
                    "path": "/create-visitor-pass/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-visitor-passes",
                    "path": "/visitor-passes",
                    "requireAuth": false,
                    "node": {
                        "_id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-welcome-page",
                    "path": "/welcome",
                    "requireAuth": true,
                    "node": {
                        "_id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-work-permit",
                    "path": "/homeowner-work-permit/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                        "type": "page"
                    }
                },
                {
                    "page": "message-board-detail-page",
                    "path": "/message-boards/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
                        "type": "page"
                    }
                },
                {
                    "page": "message-board-list-page",
                    "path": "/message-boards-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
                        "type": "page"
                    }
                },
                {
                    "page": "notification-detail-page",
                    "path": "/notifications/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                        "type": "page"
                    }
                },
                {
                    "page": "notification-list-page",
                    "path": "/notifications-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                        "type": "page"
                    }
                },
                {
                    "page": "pass-delivery-edit-page",
                    "path": "/pass-deliveries/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "61855560-e82a-11ea-bffd-65b1c6e24159",
                        "type": "page"
                    }
                },
                {
                    "page": "pass-delivery-list-page",
                    "path": "/pass-deliveries-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
                        "type": "page"
                    }
                },
                {
                    "page": "pass-visitor-edit-page",
                    "path": "/pass-visitors/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
                        "type": "page"
                    }
                },
                {
                    "page": "pass-visitor-list-page",
                    "path": "/pass-visitors-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
                        "type": "page"
                    }
                },
                {
                    "page": "payment-detail-page",
                    "path": "/payments/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "50c11f60-ef74-11ea-8620-1757bce541ea",
                        "type": "page"
                    }
                },
                {
                    "page": "payment-item-detail-page",
                    "path": "/payment-items/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "9273b1a0-ef71-11ea-ae43-d3d081a35642",
                        "type": "page"
                    }
                },
                {
                    "page": "payment-list-page",
                    "path": "/payments-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "50d606f0-ef74-11ea-8620-1757bce541eb",
                        "type": "page"
                    }
                },
                {
                    "page": "terms-conditions-detail-page",
                    "path": "/terms-conditions/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
                        "type": "page"
                    }
                },
                {
                    "page": "terms-conditions-list-page",
                    "path": "/terms-conditions-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "5aabc600-e507-11ea-8276-65e120bbe853",
                        "type": "page"
                    }
                },
                {
                    "page": "unit-detail-page",
                    "path": "/units/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                        "type": "page"
                    }
                },
                {
                    "page": "unit-list-page",
                    "path": "/units-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "0829e200-d84f-11ea-acd1-c1cd20d0424b",
                        "type": "page"
                    }
                },
                {
                    "page": "unit-request-edit-page",
                    "path": "/unit-requests/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
                        "type": "page"
                    }
                },
                {
                    "page": "unit-request-list-page",
                    "path": "/unit-requests-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
                        "type": "page"
                    }
                },
                {
                    "page": "work-permit-edit-page",
                    "path": "/work-permits/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
                        "type": "page"
                    }
                },
                {
                    "page": "work-permit-list-page",
                    "path": "/work-permits-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
                        "type": "page"
                    }
                },
                {
                    "page": "activityLog-detail-page",
                    "path": "/activityLogs/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "fb9a9130-f0b1-11ea-99d0-d9f36ea6d179",
                        "type": "page"
                    }
                },
                {
                    "page": "activityLog-list-page",
                    "path": "/activityLogs-list",
                    "requireAuth": false,
                    "node": {
                        "_id": "fba93730-f0b1-11ea-99d0-d9f36ea6d179",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-billing-detail-page",
                    "path": "/account/homeowner-billings/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "ffb5d7c0-f6f1-11ea-b61a-f5b3d409e561",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-pay-dues",
                    "path": "/account/pay-dues",
                    "requireAuth": false,
                    "node": {
                        "_id": "1237a590-f7a6-11ea-8efb-e99dbc19ac92",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-pay-dues-deposit",
                    "path": "/account/pay-dues/deposit/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "b0779440-f7a6-11ea-8efb-e99dbc19ac92",
                        "type": "page"
                    }
                },
                {
                    "page": "login-portal-page",
                    "path": "/login-portal",
                    "layout": "full",
                    "requireAuth": false,
                    "node": {
                        "_id": "968362c0-fdf1-11ea-ba93-5145fd1d7baf",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-work-permits",
                    "path": "/work-permits",
                    "requireAuth": false,
                    "node": {
                        "_id": "cc343590-0376-11eb-b9ea-3df39e640441",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-unit-requests",
                    "path": "/unit-requests",
                    "requireAuth": false,
                    "node": {
                        "_id": "7d7c1ed0-0377-11eb-bfdc-3b5d643d4962",
                        "type": "page"
                    }
                },
                {
                    "page": "homeowner-pay-dues-history",
                    "path": "/account/pay-dues-history",
                    "requireAuth": false,
                    "node": {
                        "_id": "b6957d90-08e7-11eb-a5df-110eadfed9ed",
                        "type": "page"
                    }
                },
                {
                    "page": "forgot-password",
                    "path": "/forgot-password",
                    "layout": "full",
                    "requireAuth": false,
                    "node": {
                        "_id": "1e5949d0-0ffe-11eb-a75f-a764954330b6",
                        "type": "page"
                    }
                },
                {
                    "page": "reset-password",
                    "path": "/reset-password",
                    "layout": "full",
                    "requireAuth": false,
                    "node": {
                        "_id": "7589ccc0-1008-11eb-9f4b-55fb426750d7",
                        "type": "page"
                    }
                },
                {
                    "page": "pass-visitor-detail-page",
                    "path": "/pass-visitors-list/detail/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "27c30fa0-1603-11eb-8555-975f97eacdba",
                        "type": "page"
                    }
                },
                {
                    "page": "pass-delivery-detail-page",
                    "path": "/pass-deliveries-list/detail/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "fcc6c530-164d-11eb-bfd1-2d1eca45ef82",
                        "type": "page"
                    }
                },
                {
                    "page": "work-permit-detail-page",
                    "path": "/work-permits/detail/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "8aa9f4a0-1679-11eb-83c3-eb7956ee1f3f",
                        "type": "page"
                    }
                },
                {
                    "page": "unit-request-detail-page",
                    "path": "/unit-requests/detail/:id",
                    "requireAuth": false,
                    "node": {
                        "_id": "0ef575f0-167e-11eb-83c3-eb7956ee1f32",
                        "type": "page"
                    }
                }
            ],
            "children": [
                {
                    "id": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
                    "type": "app",
                    "name": "dashboard",
                    "sidebar": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                    "active": true,
                    "component": "",
                    "_sortIndex": 0,
                    "startPage": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf"
                },
                {
                    "id": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                    "type": "app",
                    "name": "homeowners",
                    "sidebar": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                    "startPage": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                    "active": false,
                    "_sortIndex": 10
                },
                {
                    "id": "2ad37a40-9138-11ea-bd78-399a56c40918",
                    "type": "model",
                    "children": [
                        {
                            "id": "35bfcda0-9138-11ea-bd78-399a56c40918",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                            "name": "homeowner",
                            "_sortIndex": -1
                        },
                        {
                            "id": "3040a340-9138-11ea-bd78-399a56c40918",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "name",
                            "_sortIndex": 0
                        },
                        {
                            "id": "6fa1c410-9138-11ea-bd78-399a56c40918",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "details"
                        },
                        {
                            "id": "298e0e40-e9a6-11ea-bbba-5944fe032799",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "dueDate"
                        },
                        {
                            "id": "388318a0-e9a6-11ea-bbba-5944fe032799",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "postingDate"
                        }
                    ],
                    "name": "billing",
                    "_sortIndex": 20
                },
                {
                    "id": "960f7330-e864-11ea-9def-530e14f0f646",
                    "type": "model",
                    "children": [
                        {
                            "id": "efc17c70-e864-11ea-9def-530e14f0f646",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                            "name": "billing",
                            "_sortIndex": -1
                        },
                        {
                            "id": "b016f690-e864-11ea-9def-530e14f0f646",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "item",
                            "_sortIndex": 0
                        },
                        {
                            "id": "b43e8030-e864-11ea-9def-530e14f0f646",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "description",
                            "_sortIndex": 10
                        },
                        {
                            "id": "b81a5ad0-e864-11ea-9def-530e14f0f646",
                            "type": "field:number",
                            "children": [],
                            "dataType": "number",
                            "name": "amount",
                            "_sortIndex": 20
                        },
                        {
                            "id": "bec0ee30-e864-11ea-9def-530e14f0f646",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "state",
                            "_sortIndex": 30
                        }
                    ],
                    "name": "billing-item"
                },
                {
                    "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                    "type": "model",
                    "children": [
                        {
                            "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:enumeration",
                            "children": [],
                            "dataType": "enumeration",
                            "name": "type"
                        },
                        {
                            "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "details"
                        },
                        {
                            "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:file",
                            "children": [],
                            "dataType": "file",
                            "collection": "file",
                            "via": "related",
                            "allowedTypes": [
                                "images",
                                "files",
                                "videos"
                            ],
                            "plugin": "upload",
                            "name": "photos"
                        },
                        {
                            "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                            "name": "homeowner"
                        },
                        {
                            "id": "db5ce460-edd0-11ea-993f-a977bcf36a2d",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "notes"
                        },
                        {
                            "id": "42890350-05e4-11eb-8eea-bf73dbff533a",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "type_"
                        }
                    ],
                    "name": "comments-suggestions",
                    "_sortIndex": 30
                },
                {
                    "id": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                    "type": "model",
                    "children": [
                        {
                            "id": "1ba0dd50-8ce8-11ea-a283-ebcb859bf8c8",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "name",
                            "required": true,
                            "searchable": true
                        },
                        {
                            "id": "22702e60-8ce8-11ea-a283-ebcb859bf8c8",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "description",
                            "required": true
                        }
                    ],
                    "name": "department",
                    "active": false,
                    "_sortIndex": 50
                },
                {
                    "id": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                    "type": "model",
                    "children": [
                        {
                            "id": "7288d810-8c4e-11ea-9466-6f383d5eb483",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "employeeid",
                            "required": true,
                            "searchable": true,
                            "_sortIndex": 0
                        },
                        {
                            "id": "49b35e80-9129-11ea-a5e9-ff76f0b9af84",
                            "type": "field:email",
                            "children": [],
                            "dataType": "email",
                            "name": "email",
                            "required": true,
                            "_sortIndex": 9
                        },
                        {
                            "id": "780a4c60-8c4e-11ea-9466-6f383d5eb483",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "firstName",
                            "required": true,
                            "searchable": true,
                            "_sortIndex": 10
                        },
                        {
                            "id": "837ebb80-8c4e-11ea-9466-6f383d5eb483",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "lastName",
                            "required": true,
                            "searchable": true,
                            "_sortIndex": 20
                        },
                        {
                            "id": "e68f77d0-8c91-11ea-bb0b-0b00cf6ac98c",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "birthday",
                            "required": true,
                            "_sortIndex": 30
                        },
                        {
                            "id": "5979e880-8e9e-11ea-9c0f-c7e69d58f37d",
                            "type": "field:file",
                            "children": [],
                            "dataType": "file",
                            "collection": "file",
                            "via": "related",
                            "allowedTypes": [
                                "images",
                                "files",
                                "videos"
                            ],
                            "plugin": "upload",
                            "name": "picture",
                            "_sortIndex": 50
                        },
                        {
                            "id": "e7c20f60-14d0-11eb-a8c0-03c9560d42e7",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "role"
                        },
                        {
                            "id": "20b8cab0-15fe-11eb-bc4b-619622358460",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "role_"
                        }
                    ],
                    "name": "employee",
                    "active": false,
                    "_sortIndex": 60
                },
                {
                    "id": "ed8defd0-911e-11ea-9e19-2d2007118721",
                    "type": "model",
                    "children": [
                        {
                            "id": "06bec240-911f-11ea-9e19-2d2007118721",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "accountNumber",
                            "_sortIndex": -1
                        },
                        {
                            "id": "3f18fbb0-9129-11ea-a5e9-ff76f0b9af84",
                            "type": "field:email",
                            "children": [],
                            "dataType": "email",
                            "name": "email",
                            "required": true,
                            "_sortIndex": 0
                        },
                        {
                            "id": "f67bdb20-911e-11ea-9e19-2d2007118721",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "name",
                            "required": true,
                            "_sortIndex": 20
                        },
                        {
                            "id": "138b4750-911f-11ea-9e19-2d2007118721",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "contact",
                            "_sortIndex": 70
                        },
                        {
                            "id": "b2c46990-e9f4-11ea-9892-473a233000d1",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "nickname"
                        },
                        {
                            "id": "f64a0d20-e9fc-11ea-bf3b-ef4612b5d21e",
                            "type": "field:file",
                            "children": [],
                            "dataType": "file",
                            "collection": "file",
                            "via": "related",
                            "allowedTypes": [
                                "images",
                                "files",
                                "videos"
                            ],
                            "plugin": "upload",
                            "name": "photo"
                        },
                        {
                            "id": "f1f4a130-ef98-11ea-9d3f-8def54ed8352",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "parkingUnitNumber"
                        },
                        {
                            "id": "9fe96300-eff5-11ea-ad56-959d088b5979",
                            "type": "field:boolean",
                            "children": [],
                            "dataType": "boolean",
                            "name": "noticeShown"
                        }
                    ],
                    "name": "homeowner",
                    "_sortIndex": 70
                },
                {
                    "id": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                    "type": "model",
                    "children": [
                        {
                            "id": "b3aa23e0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                            "name": "homeowner"
                        },
                        {
                            "id": "c20c3d10-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:enumeration",
                            "children": [],
                            "dataType": "enumeration",
                            "name": "topic"
                        },
                        {
                            "id": "d7f25150-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "title"
                        },
                        {
                            "id": "dd8b6c50-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "price"
                        },
                        {
                            "id": "e1c656e0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "description"
                        },
                        {
                            "id": "eb73c1a0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "contactNumber"
                        },
                        {
                            "id": "f1279090-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:file",
                            "children": [],
                            "dataType": "file",
                            "collection": "file",
                            "via": "related",
                            "allowedTypes": [
                                "images",
                                "files",
                                "videos"
                            ],
                            "plugin": "upload",
                            "name": "photos"
                        },
                        {
                            "id": "ff6cabe0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "status"
                        },
                        {
                            "id": "2cdc7120-db5c-11ea-968c-6f86147884ca",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "notes"
                        },
                        {
                            "id": "42925310-f0a4-11ea-a584-176526b5be96",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "datePosted"
                        },
                        {
                            "id": "68bbe9c0-05e4-11eb-8eea-bf73dbff533a",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "topic_"
                        }
                    ],
                    "_sortIndex": 80,
                    "name": "message-board"
                },
                {
                    "id": "77e05b60-911e-11ea-9e19-2d2007118721",
                    "type": "model",
                    "children": [
                        {
                            "id": "7bedcd50-911e-11ea-9e19-2d2007118721",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "name",
                            "required": true
                        },
                        {
                            "id": "8093aeb0-911e-11ea-9e19-2d2007118721",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "text",
                            "required": true
                        },
                        {
                            "id": "88181ef0-911e-11ea-9e19-2d2007118721",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "noticeStart",
                            "required": true
                        },
                        {
                            "id": "97459e70-911e-11ea-9e19-2d2007118721",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "noticeEnd"
                        },
                        {
                            "id": "9eb945d0-911e-11ea-9e19-2d2007118721",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "source"
                        },
                        {
                            "id": "a096b760-9129-11ea-a5e9-ff76f0b9af84",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "status"
                        },
                        {
                            "id": "46f8d4c0-08fe-11eb-ad54-a7f1ee827191",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "name": "homeowner",
                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                        }
                    ],
                    "name": "notification",
                    "_sortIndex": 90
                },
                {
                    "id": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                    "type": "model",
                    "children": [
                        {
                            "id": "c4081f00-d9c6-11ea-93cf-3dd7e562aac3",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                            "name": "unit"
                        },
                        {
                            "id": "d7708910-d9c6-11ea-93cf-3dd7e562aac3",
                            "type": "field:enumeration",
                            "children": [],
                            "dataType": "enumeration",
                            "name": "type"
                        },
                        {
                            "id": "e5db54d0-d9c6-11ea-93cf-3dd7e562aac3",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "schedule"
                        },
                        {
                            "id": "ee4c9610-d9c6-11ea-93cf-3dd7e562aac3",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "items"
                        },
                        {
                            "id": "f2ed6e60-d9c6-11ea-93cf-3dd7e562aac3",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "company"
                        },
                        {
                            "id": "00b0fda0-d9c7-11ea-93cf-3dd7e562aac3",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "status"
                        },
                        {
                            "id": "5823d2a0-e828-11ea-99d9-7b0e0405473d",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "notes"
                        },
                        {
                            "id": "fe2aaed0-05e2-11eb-8eea-bf73dbff533a",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "type_"
                        }
                    ],
                    "name": "pass-delivery",
                    "_sortIndex": 100
                },
                {
                    "id": "a5454c50-911e-11ea-9e19-2d2007118721",
                    "type": "model",
                    "children": [
                        {
                            "id": "c7d2c620-d9c7-11ea-93cf-3dd7e562aac3",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                            "name": "unit"
                        },
                        {
                            "id": "cfdbf6c0-d9c7-11ea-93cf-3dd7e562aac3",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "visitor",
                            "required": true
                        },
                        {
                            "id": "fa4d2320-d9c7-11ea-93cf-3dd7e562aac3",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "startDate"
                        },
                        {
                            "id": "ff0ec9e0-d9c7-11ea-93cf-3dd7e562aac3",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "endDate"
                        },
                        {
                            "id": "06a73750-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "field:file",
                            "children": [],
                            "dataType": "file",
                            "collection": "file",
                            "via": "related",
                            "allowedTypes": [
                                "images",
                                "files",
                                "videos"
                            ],
                            "plugin": "upload",
                            "name": "visitorsId"
                        },
                        {
                            "id": "7b087c60-da1a-11ea-b50d-8323e621ee63",
                            "type": "field:enumeration",
                            "children": [],
                            "dataType": "enumeration",
                            "name": "type"
                        },
                        {
                            "id": "f23b46a0-f0d6-11ea-9422-3905f8832c96",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "status"
                        },
                        {
                            "id": "6b951970-f796-11ea-8826-7fd8e10f31a2",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "notes"
                        },
                        {
                            "id": "d69fd980-05e2-11eb-8eea-bf73dbff533a",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "type_"
                        }
                    ],
                    "name": "pass-visitor",
                    "_sortIndex": 109
                },
                {
                    "id": "e682f860-ef70-11ea-983b-e7b329586acc",
                    "type": "model",
                    "children": [
                        {
                            "id": "f6493570-ef70-11ea-983b-e7b329586acc",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "name": "homeowner",
                            "_sortIndex": -1,
                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                        },
                        {
                            "id": "eb4c4040-ef70-11ea-983b-e7b329586acc",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "receipt",
                            "_sortIndex": 0
                        },
                        {
                            "id": "0a0034b0-ef71-11ea-983b-e7b329586acc",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "postingDate"
                        },
                        {
                            "id": "b6c349d0-f65b-11ea-bcea-e53105b58e3f",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "statement"
                        },
                        {
                            "id": "c8fd9420-f65b-11ea-bcea-e53105b58e3f",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "paymentDate"
                        }
                    ],
                    "name": "payment"
                },
                {
                    "id": "24ee6e90-ef71-11ea-ae43-d3d081a35642",
                    "type": "model",
                    "children": [
                        {
                            "id": "4aae6ef0-ef71-11ea-ae43-d3d081a35642",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "name": "payment",
                            "dataModel": "555659e0-9138-11ea-bd78-399a56c40918",
                            "_sortIndex": -1
                        },
                        {
                            "id": "2aa37600-ef71-11ea-ae43-d3d081a35642",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "item",
                            "_sortIndex": 0
                        },
                        {
                            "id": "3de1e9e0-ef71-11ea-ae43-d3d081a35642",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "description",
                            "_sortIndex": 10
                        },
                        {
                            "id": "420ea3a0-ef71-11ea-ae43-d3d081a35642",
                            "type": "field:number",
                            "children": [],
                            "dataType": "number",
                            "name": "amount",
                            "_sortIndex": 20
                        },
                        {
                            "id": "46bbb0f0-ef71-11ea-ae43-d3d081a35642",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "state",
                            "_sortIndex": 30
                        }
                    ],
                    "name": "payment-item"
                },
                {
                    "id": "faa20cb0-e506-11ea-8276-65e120bbe853",
                    "type": "model",
                    "children": [
                        {
                            "id": "fe1b06d0-e506-11ea-8276-65e120bbe853",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "name"
                        },
                        {
                            "id": "0704fa80-e507-11ea-8276-65e120bbe853",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "content"
                        }
                    ],
                    "name": "terms-conditions"
                },
                {
                    "id": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                    "type": "model",
                    "children": [
                        {
                            "id": "a86f3310-d84e-11ea-acd1-c1cd20d0424a",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "number"
                        },
                        {
                            "id": "bc50ebd0-d84e-11ea-acd1-c1cd20d0424a",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "type",
                            "component": ""
                        },
                        {
                            "id": "73fa85c0-edd0-11ea-993f-a977bcf36a2d",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "areaSize"
                        },
                        {
                            "id": "817fd380-edd0-11ea-993f-a977bcf36a2d",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "classification"
                        },
                        {
                            "id": "8bc3d8f0-edd0-11ea-993f-a977bcf36a2d",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "projectCode"
                        },
                        {
                            "id": "944805f0-edd0-11ea-993f-a977bcf36a2d",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "customerCode"
                        },
                        {
                            "id": "99e36ae0-edd0-11ea-993f-a977bcf36a2d",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "turnoverDate"
                        },
                        {
                            "id": "a14a4100-edd0-11ea-993f-a977bcf36a2d",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "name": "homeowner",
                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                        },
                        {
                            "id": "53405060-ef99-11ea-9d3f-8def54ed8352",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "greaseTrapCleaning"
                        },
                        {
                            "id": "ab1423c0-ef99-11ea-9d3f-8def54ed8352",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "airconCleaning"
                        }
                    ],
                    "name": "unit",
                    "_sortIndex": 130
                },
                {
                    "id": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                    "type": "model",
                    "children": [
                        {
                            "id": "ffb19150-d84d-11ea-acd1-c1cd20d0424a",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "type"
                        },
                        {
                            "id": "09a553e0-d84e-11ea-acd1-c1cd20d0424a",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "status"
                        },
                        {
                            "id": "125e2570-d84e-11ea-acd1-c1cd20d0424a",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                            "name": "unit"
                        },
                        {
                            "id": "47a03900-e828-11ea-99d9-7b0e0405473d",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "component": "",
                            "name": "notes"
                        },
                        {
                            "id": "13c40d30-05e4-11eb-8eea-bf73dbff533a",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "type_"
                        }
                    ],
                    "name": "unit-request",
                    "_sortIndex": 140
                },
                {
                    "id": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                    "type": "model",
                    "children": [
                        {
                            "id": "59d52f00-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:relation",
                            "children": [],
                            "dataType": "relation",
                            "name": "unit",
                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                        },
                        {
                            "id": "5fa7a980-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:enumeration",
                            "children": [],
                            "dataType": "enumeration",
                            "name": "natureOfWork"
                        },
                        {
                            "id": "680a92e0-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "contractor"
                        },
                        {
                            "id": "6cc22780-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "personInCharge"
                        },
                        {
                            "id": "77ba88d0-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "contactPersonInCharge"
                        },
                        {
                            "id": "82778ed0-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "workers"
                        },
                        {
                            "id": "87d65ff0-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "description"
                        },
                        {
                            "id": "951e5690-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "materials"
                        },
                        {
                            "id": "9a41cc60-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "tools"
                        },
                        {
                            "id": "a0da7d10-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "startDate"
                        },
                        {
                            "id": "a5a376d0-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "endDate"
                        },
                        {
                            "id": "abf13bd0-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:file",
                            "children": [],
                            "dataType": "file",
                            "collection": "file",
                            "via": "related",
                            "allowedTypes": [
                                "images",
                                "files",
                                "videos"
                            ],
                            "plugin": "upload",
                            "name": "plans"
                        },
                        {
                            "id": "afc85b80-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "status"
                        },
                        {
                            "id": "339637c0-e828-11ea-99d9-7b0e0405473d",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "notes"
                        },
                        {
                            "id": "8e04b3c0-05e3-11eb-8eea-bf73dbff533a",
                            "type": "field:json",
                            "children": [],
                            "dataType": "json",
                            "name": "natureOfWork_"
                        }
                    ],
                    "name": "work-permit",
                    "_sortIndex": 150
                },
                {
                    "id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                            "children": [
                                {
                                    "id": "2936a240-f0b3-11ea-99d0-d9f36ea6d179",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Billing",
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "7aa7e100-9138-11ea-bd78-399a56c40918",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "7aa7e102-9138-11ea-bd78-399a56c40918",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "homeowner",
                                            "label": "Homeowner",
                                            "dataField": "homeowner",
                                            "saveObject": true,
                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "7aa7e103-9138-11ea-bd78-399a56c40918",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "7aa7e105-9138-11ea-bd78-399a56c40918",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "name",
                                            "label": "Name",
                                            "dataField": "name"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "016566c0-e9b0-11ea-8b13-df1e5e645c34",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "postingDate",
                                    "dataField": "postingDate",
                                    "label": "Posting Date",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "04a80ea0-e9b0-11ea-8b13-df1e5e645c34",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "dueDate",
                                    "dataField": "dueDate",
                                    "label": "Due Date",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "9d8d05d0-efdc-11ea-b8ac-c3abdef265b0",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "component": "BillingUpload",
                                    "_sortIndex": 40
                                }
                            ],
                            "_sortIndex": 0,
                            "component": "BillingUpload"
                        },
                        {
                            "id": "22b50110-e865-11ea-9def-530e14f0f646",
                            "type": "subView",
                            "children": [],
                            "_sortIndex": 10,
                            "view": "3acc2620-e865-11ea-9def-530e14f0f646",
                            "relation": "billing"
                        },
                        {
                            "id": "7aa39b40-9138-11ea-bd78-399a56c40918",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                            "_sortIndex": 20
                        }
                    ],
                    "orientation": "vertical",
                    "name": "billing-detail-page",
                    "route": "/billings/:id",
                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                    "component": "",
                    "_sortIndex": 160
                },
                {
                    "id": "3ab60610-e865-11ea-9def-530e14f0f646",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                            "children": [
                                {
                                    "id": "1e47b6d0-f0b3-11ea-99d0-d9f36ea6d179",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Billing",
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "3ac76b30-e865-11ea-9def-530e14f0f646",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3ac76b32-e865-11ea-9def-530e14f0f646",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "billing",
                                            "label": "Billing",
                                            "dataField": "billing",
                                            "saveObject": true,
                                            "relation": "2ad37a40-9138-11ea-bd78-399a56c40918"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "3ac76b33-e865-11ea-9def-530e14f0f646",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3ac76b35-e865-11ea-9def-530e14f0f646",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "item",
                                            "label": "Item",
                                            "dataField": "item"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "3ac76b36-e865-11ea-9def-530e14f0f646",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3ac79240-e865-11ea-9def-530e14f0f646",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "description",
                                            "label": "Description",
                                            "dataField": "description"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "3ac79241-e865-11ea-9def-530e14f0f646",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3ac79243-e865-11ea-9def-530e14f0f646",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "amount",
                                            "label": "Amount",
                                            "dataField": "amount"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "3ac79244-e865-11ea-9def-530e14f0f646",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3ac79246-e865-11ea-9def-530e14f0f646",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "state",
                                            "label": "State",
                                            "dataField": "state"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40
                                }
                            ],
                            "component": ""
                        },
                        {
                            "id": "3abf2dd0-e865-11ea-9def-530e14f0f646",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "billing-item-detail-page",
                    "route": "/billing-items/:id",
                    "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
                },
                {
                    "id": "7aac26c0-9138-11ea-bd78-399a56c40916",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "7aac26c0-9138-11ea-bd78-399a56c40917",
                            "children": [
                                {
                                    "id": "c1bc9ce0-f658-11ea-bcea-e53105b58e3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "cc9fc880-f658-11ea-bcea-e53105b58e3f",
                                            "type": "button",
                                            "children": [],
                                            "command": "new_billing",
                                            "text": "upload"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "520b7960-01ee-11eb-81b5-f15d97fd1729",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "575a6c00-01ee-11eb-81b5-f15d97fd1729",
                                            "type": "subView",
                                            "children": [],
                                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
                                            "component": "Searchbar",
                                            "name": "searchbar"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "7ab48b30-9138-11ea-bd78-399a56c40918",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "7ab94620-9138-11ea-bd78-399a56c40918",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "homeowner",
                                            "label": "Homeowner",
                                            "dataField": "homeowner"
                                        },
                                        {
                                            "id": "7ab94622-9138-11ea-bd78-399a56c40918",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "name",
                                            "label": "Statement",
                                            "dataField": "name"
                                        },
                                        {
                                            "id": "91576770-f65c-11ea-bcea-e53105b58e3f",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "postingDate",
                                            "label": "Posting Date",
                                            "date": true
                                        },
                                        {
                                            "id": "a45046d0-f65c-11ea-bcea-e53105b58e3f",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "dueDate",
                                            "label": "Due Date",
                                            "date": true
                                        },
                                        {
                                            "id": "4f7ef760-091b-11eb-a3cc-3fd0e63e5f63",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Amount",
                                            "name": "amount",
                                            "currency": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "query": "{\"_limit\": \"100\"}"
                                }
                            ],
                            "component": "BillingUpload"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "billing-list-page",
                    "route": "/billings-list",
                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                    "_sortIndex": 170
                },
                {
                    "id": "77a819a0-dd57-11ea-9ac6-91ffe70e2d7c",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                            "children": [
                                {
                                    "id": "431dff00-f0a9-11ea-9db2-ab2487441b73",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "className": "has-text-centered",
                                    "text": "Comments and Suggestions",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "77b90990-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "09eb4ef0-ea54-11ea-8de6-3f209e03f86c",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "typ",
                                            "label": "Type",
                                            "dataField": "type",
                                            "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"}, {\"label\": \"Security\", \"value\": \"security\"}, {\"label\": \"Safety\", \"value\": \"safety\"}, {\"label\": \"PMG Crew/ Team Conduct\", \"value\": \"team-conduct\"} ,{\"label\": \"Others\", \"value\": \"others\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "77b90993-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "77b90995-dd57-11ea-9ac6-91ffe70e2d7c",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "details",
                                            "label": "Details",
                                            "dataField": "details",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "77b90996-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "77b90998-dd57-11ea-9ac6-91ffe70e2d7c",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "photos",
                                            "label": "Photos",
                                            "dataField": "photos",
                                            "basePath": "comments-suggestions"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "77b90999-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "77b9099b-dd57-11ea-9ac6-91ffe70e2d7c",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "homeowner",
                                            "label": "Homeowner",
                                            "dataField": "homeowner",
                                            "saveObject": true,
                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40
                                },
                                {
                                    "id": "a71fdc40-f0a3-11ea-a584-176526b5be96",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b134e270-f0a3-11ea-a584-176526b5be96",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "notes",
                                            "label": "Notes",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 49
                                }
                            ]
                        },
                        {
                            "id": "77b5d540-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "comments-suggestions-edit-page",
                    "route": "/comments-suggestions/:id",
                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                    "_sortIndex": 180,
                    "requireAuth": true
                },
                {
                    "id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                            "children": [
                                {
                                    "id": "77c369d0-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "77c824c0-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "5a733570-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "createdAt",
                                            "label": "Date Submitted",
                                            "date": true,
                                            "format": "MM/dd/yyyy hh:mm a"
                                        },
                                        {
                                            "id": "633ccfe0-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "type",
                                            "label": "Type"
                                        },
                                        {
                                            "id": "697f7150-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "details",
                                            "label": "Details"
                                        },
                                        {
                                            "id": "70e86a50-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "homeowner",
                                            "label": "Homeowner"
                                        },
                                        {
                                            "id": "76c4a8d0-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Notes",
                                            "dataField": "notes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "path": "/comments-suggestions/detail"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "comments-suggestions-list-page",
                    "route": "/comments-suggestions-list",
                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                    "_sortIndex": 190,
                    "requireAuth": true
                },
                {
                    "id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "a4892271-dfa5-11ea-a94d-e5d6a6ca6c3b",
                            "children": [
                                {
                                    "id": "b81715e0-dfa5-11ea-a94d-e5d6a6ca6c3b",
                                    "type": "subView",
                                    "children": [],
                                    "view": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "dashboard-login",
                    "route": "/dashboard-login",
                    "component": "",
                    "noPadding": false,
                    "layout": "full"
                },
                {
                    "id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                            "children": [
                                {
                                    "id": "f6c93a60-f0ae-11ea-a61b-49eea0a1bb78",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Department",
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "99dbbf60-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "99dbbf62-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "name",
                                            "label": "Department",
                                            "dataField": "name"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "99dbbf63-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "99dbbf65-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "description",
                                            "label": "Description",
                                            "dataField": "description",
                                            "description": "",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                }
                            ]
                        },
                        {
                            "id": "99d8b220-912e-11ea-ad57-6526d978cdf6",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "department-detail-page",
                    "route": "/departments/:id",
                    "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                    "_sortIndex": 200
                },
                {
                    "id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                            "children": [
                                {
                                    "id": "94311830-912e-11ea-ad57-6526d978cdf6",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "9433b040-912e-11ea-ad57-6526d978cdf6",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "94364850-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "name",
                                            "label": "Name",
                                            "dataField": "name"
                                        },
                                        {
                                            "id": "94364852-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "description",
                                            "label": "Description",
                                            "dataField": "description"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "department-list-page",
                    "route": "/departments-list",
                    "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                    "_sortIndex": 210
                },
                {
                    "id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf3",
                            "children": [
                                {
                                    "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Employee",
                                    "className": "has-text-centered",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "a5a55360-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a5a55362-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "employeeid",
                                            "label": "Employee ID",
                                            "dataField": "employeeid"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a5a55363-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a5a55365-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "email",
                                            "label": "Email",
                                            "dataField": "email"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a5a55366-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a5a55368-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "firstName",
                                            "label": "First Name",
                                            "dataField": "firstName"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a5a55369-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a5a5536b-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "lastName",
                                            "label": "Last Name",
                                            "dataField": "lastName"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a5a5536c-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a5a5536e-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "birthday",
                                            "label": "Birthday",
                                            "dataField": "birthday",
                                            "format": "mm/dd/yyyy"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a5a55372-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a5a55374-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "picture",
                                            "label": "Photo",
                                            "dataField": "picture",
                                            "basePath": "employees"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "603f71b0-15fa-11eb-9b3a-dd4b5808ec5b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "632ccda0-15fa-11eb-9b3a-dd4b5808ec5b",
                                            "type": "inputSelect",
                                            "children": [],
                                            "label": "Role",
                                            "name": "role",
                                            "dataField": "role",
                                            "options": "[{\"value\": \"admin\",\"label\": \"Admin\"},{\"value\": \"accounting\",\"label\": \"Accounting\"},{\"value\": \"accounting-staff\",\"label\": \"Accounting Staff\"},{\"value\": \"pmg\",\"label\": \"PMG\"},{\"value\": \"pmg-ops\",\"label\": \"PMG-OPS\"},{\"value\": \"pmg-security\",\"label\": \"PMG-Security\"}]",
                                            "required": true,
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        },
                        {
                            "id": "a5a26d30-912e-11ea-ad57-6526d978cdf6",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "employee-detail-page",
                    "route": "/employees/:id",
                    "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                    "_sortIndex": 220
                },
                {
                    "id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                            "children": [
                                {
                                    "id": "a5aa8380-912e-11ea-ad57-6526d978cdf6",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "a5ae05f0-912e-11ea-ad57-6526d978cdf6",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "a5b0c510-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "employeeid",
                                            "label": "Employee ID",
                                            "dataField": "employeeid"
                                        },
                                        {
                                            "id": "a5b0c512-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "email",
                                            "label": "Email",
                                            "dataField": "email"
                                        },
                                        {
                                            "id": "a5b0c514-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "firstName",
                                            "label": "First Name",
                                            "dataField": "firstName"
                                        },
                                        {
                                            "id": "a5b0c516-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "lastName",
                                            "label": "Last Name",
                                            "dataField": "lastName"
                                        },
                                        {
                                            "id": "a5b0c518-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "birthday",
                                            "label": "Birthday",
                                            "dataField": "birthday"
                                        },
                                        {
                                            "id": "a5b0c51a-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "department",
                                            "label": "Department",
                                            "dataField": "department"
                                        },
                                        {
                                            "id": "a5b0c51c-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "picture",
                                            "label": "Photo",
                                            "dataField": "picture"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "employee-list-page",
                    "route": "/employees-list",
                    "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                    "_sortIndex": 230
                },
                {
                    "id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "4840fc41-9149-11ea-a6b6-e7e287632ad5",
                            "children": [
                                {
                                    "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "My Account",
                                    "className": "has-text-centered mt-2",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "707a9a30-ea14-11ea-a21e-f569cc759e68",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "75cbd6c0-ea14-11ea-a21e-f569cc759e68",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "864c1500-ea14-11ea-a21e-f569cc759e68",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_profile.svg\"><img src=\"./assets/images/icon_profile.svg\" /></object>My Profile</div>",
                                                    "path": "/account/profile/me"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "97a5ea60-ea14-11ea-a21e-f569cc759e68",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a301ef80-ea14-11ea-a21e-f569cc759e68",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_password.svg\"><img src=\"./assets/images/icon_password.svg\" /></object>Change Password</div>",
                                                    "path": "/account/change-password"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "b0937240-ea14-11ea-a21e-f569cc759e68",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "b9cc4940-ea14-11ea-a21e-f569cc759e68",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pay.svg\"><img src=\"./assets/images/icon_pay.svg\" /></object>Pay Dues</div>",
                                                    "path": "/account/pay-dues"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "c3ebafb0-ea14-11ea-a21e-f569cc759e68",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ccf909e0-ea14-11ea-a21e-f569cc759e68",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_balance.svg\"><img src=\"./assets/images/icon_balance.svg\" /></object>View Balance</div>",
                                                    "path": "/account/billings"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menus-container"
                                }
                            ],
                            "component": "",
                            "className": ""
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-account",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                    "route": "/account",
                    "component": "",
                    "_sortIndex": 240
                },
                {
                    "id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                    "type": "page",
                    "children": [
                        {
                            "id": "62b4dc20-96d2-11ea-8416-c79b465e39ab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6560c240-96d2-11ea-8416-c79b465e39ab",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Billing",
                                    "className": "has-text-centered my-1"
                                },
                                {
                                    "id": "7b996350-96d2-11ea-8416-c79b465e39ab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "176e5780-f6f1-11ea-b61a-f5b3d409e561",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "18452f80-f6f1-11ea-b61a-f5b3d409e561",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "name",
                                                    "label": "Statement"
                                                },
                                                {
                                                    "id": "20d7db70-f6f1-11ea-b61a-f5b3d409e561",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "postingDate",
                                                    "label": "Posting Date"
                                                },
                                                {
                                                    "id": "27b33a70-f6f1-11ea-b61a-f5b3d409e561",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "dueDate",
                                                    "label": "Due Date"
                                                },
                                                {
                                                    "id": "ab0e7200-0901-11eb-894e-29a0b8264102",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "label": "Amount",
                                                    "name": "amount",
                                                    "currency": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "path": "/account/homeowner-billings"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "main"
                                }
                            ],
                            "orientation": "vertical"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-billing",
                    "route": "/account/billings",
                    "_sortIndex": 250,
                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918"
                },
                {
                    "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                            "children": [
                                {
                                    "id": "e44eb2c0-f0ae-11ea-a61b-49eea0a1bb78",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "_sortIndex": -1,
                                    "text": "Comments and Suggestions",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                            "type": "button",
                                            "children": [],
                                            "text": "Comments and Suggestions history",
                                            "path": "/filed-complaints",
                                            "variant": "text"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0,
                                    "className": "justify-content-center"
                                },
                                {
                                    "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "type",
                                            "label": "Type of issue",
                                            "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"}, {\"label\": \"Security\", \"value\": \"security\"}, {\"label\": \"Safety\", \"value\": \"safety\"}, {\"label\": \"PMG Crew/ Team Conduct\", \"value\": \"team-conduct\"} ,{\"label\": \"Others\", \"value\": \"others\"}]",
                                            "saveObject": true,
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10,
                                    "className": ""
                                },
                                {
                                    "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "details",
                                            "label": "Details of the issue/concern",
                                            "multiline": true,
                                            "name": "",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20,
                                    "className": ""
                                },
                                {
                                    "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                            "type": "inputMedia",
                                            "children": [],
                                            "label": "Photo",
                                            "dataField": "photos",
                                            "basePath": "comments-suggestions",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30,
                                    "className": ""
                                },
                                {
                                    "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e6ce6cf0-1225-11eb-8323-55a37c955b67",
                                            "type": "subView",
                                            "children": [],
                                            "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40,
                                    "className": ""
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-comments-suggestions",
                    "route": "/homeowner-comments-suggestions/:id",
                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                    "className": "",
                    "_sortIndex": 260
                },
                {
                    "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                            "children": [
                                {
                                    "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Comments and Suggestions",
                                    "_sortIndex": 0,
                                    "name": "",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                            "type": "button",
                                            "children": [],
                                            "text": "View complaint history",
                                            "path": "/filed-complaints",
                                            "variant": "text"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 9,
                                    "className": "justify-content-center"
                                },
                                {
                                    "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "type",
                                            "label": "Type of issue"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10,
                                    "className": ""
                                },
                                {
                                    "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "details",
                                            "label": "Details of the issue/concern",
                                            "multiline": true,
                                            "name": ""
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20,
                                    "className": ""
                                },
                                {
                                    "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                            "type": "inputMedia",
                                            "children": [],
                                            "label": "Photo",
                                            "dataField": "photo"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40,
                                    "className": ""
                                },
                                {
                                    "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                            "type": "button",
                                            "children": [],
                                            "text": "Save",
                                            "command": "save",
                                            "variant": "contained",
                                            "color": "primary",
                                            "className": "",
                                            "fullWidth": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 50,
                                    "className": ""
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-complaint",
                    "route": "/file-complaint/:id",
                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                    "className": "",
                    "_sortIndex": 270
                },
                {
                    "id": "47440520-971c-11ea-8a71-fd00fe88963d",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "47440521-971c-11ea-8a71-fd00fe88963d",
                            "children": [
                                {
                                    "id": "4b1a6180-971c-11ea-8a71-fd00fe88963d",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "View Comments and Suggestion History",
                                    "className": "has-text-centered"
                                }
                            ]
                        },
                        {
                            "id": "52a624c0-971c-11ea-8a71-fd00fe88963d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "80367620-0372-11eb-b960-0956a98f4295",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "8194f4b0-0372-11eb-b960-0956a98f4295",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "type",
                                            "label": "Type"
                                        },
                                        {
                                            "id": "8ab53aa0-0372-11eb-b960-0956a98f4295",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "details",
                                            "label": "Details"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": "/homeowner-comments-suggestions"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5a577160-971c-11ea-8a71-fd00fe88963d",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                    "route": "/filed-complaints",
                    "name": "homeowner-complaints",
                    "className": "",
                    "_sortIndex": 280
                },
                {
                    "id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "4e8b5d81-9157-11ea-8382-2991800d56bc",
                            "children": [
                                {
                                    "id": "268a56f0-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "name": "",
                                    "text": "Delivery/Pull-out Pass",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "3cd79170-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "40347810-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "button",
                                            "children": [],
                                            "text": "My Delivery / Pull-out Passes",
                                            "variant": "text",
                                            "path": "/delivery-passes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "justify-content-center pt-1 pb-1"
                                },
                                {
                                    "id": "590cca40-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "5f544db0-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "inputSelect",
                                            "children": [],
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "dataField": "unit",
                                            "label": "Unit",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "66e4a4d0-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6b30cd70-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "type",
                                            "label": "Type of Pass",
                                            "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                            "saveObject": true,
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "7d085e50-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c833a300-edcc-11ea-abfa-8f89576fb378",
                                            "type": "inputDateTime",
                                            "children": [],
                                            "dataField": "schedule",
                                            "label": "Schedule",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "87486c20-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "8c652b30-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "inputList",
                                            "children": [],
                                            "label": "Item(s) - Please enumerate including quantities.",
                                            "dataField": "items",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a733cf20-da0a-11ea-87ea-f5980a7a1cab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ac4da800-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "company",
                                            "label": "Delivery Company",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a788cc00-e7f7-11ea-aa5b-4b966778e568",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "component": "TermsAndConditions",
                                    "name": "delivery-pass"
                                },
                                {
                                    "id": "df2a4d60-dac7-11ea-9725-dfc9cbd591dc",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b51a9440-1225-11eb-8323-55a37c955b67",
                                            "type": "subView",
                                            "children": [],
                                            "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-delivery-pass",
                    "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                    "route": "/create-delivery-pass/:id",
                    "className": "",
                    "_sortIndex": 290
                },
                {
                    "id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "db3a70c1-96c7-11ea-bb44-c34ef5a44500",
                            "children": [
                                {
                                    "id": "e5006c90-96c7-11ea-bb44-c34ef5a44500",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Delivery Passes",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "e8824050-96c7-11ea-bb44-c34ef5a44500",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f0fafba0-96c7-11ea-bb44-c34ef5a44500",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "ae53bb10-96c8-11ea-bb44-c34ef5a44500",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "unit",
                                                    "label": "Unit"
                                                },
                                                {
                                                    "id": "b91bbd90-96c8-11ea-bb44-c34ef5a44500",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "type",
                                                    "label": "Type"
                                                },
                                                {
                                                    "id": "5c160d40-0378-11eb-bfdc-3b5d643d4962",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "schedule",
                                                    "date": true,
                                                    "label": "Schedule",
                                                    "format": "MM/dd/yyyy hh:mm a"
                                                },
                                                {
                                                    "id": "647fad60-0378-11eb-bfdc-3b5d643d4962",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "company",
                                                    "label": "Company"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "path": "/pass-deliveries-list/detail"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-delivery-passes",
                    "route": "/delivery-passes",
                    "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                    "_sortIndex": 300
                },
                {
                    "id": "91150f60-ef9a-11ea-9d3f-8def54ed8352",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "91150f61-ef9a-11ea-9d3f-8def54ed8352",
                            "children": [
                                {
                                    "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Homeowner",
                                    "_sortIndex": 0,
                                    "name": "",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "9132a986-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9132a988-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "name",
                                            "label": "Registered Name",
                                            "dataField": "name"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0,
                                    "dataField": ""
                                },
                                {
                                    "id": "9132a98f-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9132a991-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "nickname",
                                            "label": "Nickname",
                                            "dataField": "nickname"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 19
                                },
                                {
                                    "id": "9132a983-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9132a985-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "email",
                                            "label": "Email Address",
                                            "dataField": "email"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "9132a98c-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9132a98e-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "contact",
                                            "label": "Mobile Number",
                                            "dataField": "contact"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40
                                },
                                {
                                    "id": "9132a992-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9132d090-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "photo",
                                            "label": "Photo",
                                            "dataField": "photo",
                                            "basePath": "homeowners"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 60
                                }
                            ]
                        },
                        {
                            "id": "9129cfe0-ef9a-11ea-9d3f-8def54ed8352",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-detail-page",
                    "route": "/homeowners/:id",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                },
                {
                    "id": "913e9060-ef9a-11ea-9d3f-8def54ed8352",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "91150f61-ef9a-11ea-9d3f-8def54ed8352",
                            "children": [
                                {
                                    "id": "91498ce0-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "9151f150-ef9a-11ea-9d3f-8def54ed8352",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "915acaf0-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "accountNumbe",
                                            "label": "Registered Name",
                                            "dataField": "name"
                                        },
                                        {
                                            "id": "915acaf4-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "firstName",
                                            "label": "Nickname",
                                            "dataField": "nickname"
                                        },
                                        {
                                            "id": "915acaf6-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "lastName",
                                            "label": "Email Address",
                                            "dataField": "email"
                                        },
                                        {
                                            "id": "915acafa-ef9a-11ea-9d3f-8def54ed8352",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "nickname",
                                            "label": "Mobile Number",
                                            "dataField": "contact"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-list-page",
                    "route": "/homeowners-list",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                },
                {
                    "id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "0ad19881-93ea-11ea-99e4-a504a663bd48"
                        },
                        {
                            "id": "1acacc70-93ea-11ea-9384-d1e4789a27a9",
                            "type": "subView",
                            "children": [],
                            "view": "e1156440-93e9-11ea-99e4-a504a663bd48"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-login-page",
                    "component": "",
                    "route": "/homeowners-login",
                    "_sortIndex": 330,
                    "layout": "full"
                },
                {
                    "id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "63850e21-a55c-11ea-8feb-c7034be1bc66",
                            "children": [
                                {
                                    "id": "da5a80c0-a55c-11ea-99ce-d1e75946b32d",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Marketplace"
                                },
                                {
                                    "id": "ec182100-a55c-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f232a100-a55c-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "07f88310-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "text": "",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_water.svg\"><img src=\"./assets/images/icon_water.svg\" /></object>Drinking Water</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "014dd100-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "26cecc90-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_gas.svg\"><img src=\"./assets/images/icon_gas.svg\" /></object>Gasul</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "columns is-centered mt-2"
                                },
                                {
                                    "id": "b1d7e880-a55d-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "494d3680-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "516b0090-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Rice</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "63098010-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "69f72e90-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_laundry.svg\"><img src=\"./assets/images/icon_laundry.svg\" /></object>Laundry</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "columns is-centered"
                                },
                                {
                                    "id": "c6888a00-a55d-11ea-99ce-d1e75946b32d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ccd51680-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d4ae5d80-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "button",
                                                    "children": [],
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pet.svg\"><img src=\"./assets/images/icon_pet.svg\" /></object>Pet Grooming</div>",
                                                    "className": "app-menu-item"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "f062cd90-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "f2893000-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_cleaning.svg\"><img src=\"./assets/images/icon_cleaning.svg\" /></object>Cleaning Service</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "columns is-centered"
                                }
                            ],
                            "className": "has-text-centered",
                            "_sortIndex": -1
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-marketplace",
                    "route": "/market-place",
                    "_sortIndex": 340
                },
                {
                    "id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "231003d1-c920-11ea-9080-1563a0e60ff0",
                            "className": "p1",
                            "children": [
                                {
                                    "id": "709bda20-c920-11ea-9080-1563a0e60ff0",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Message Board",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "53470b10-c921-11ea-84bc-97bd01295e5b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "566450a0-c921-11ea-84bc-97bd01295e5b",
                                            "type": "button",
                                            "children": [],
                                            "variant": "text",
                                            "text": "New Post",
                                            "path": "/message-board/0"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "justify-content-center pt-1 pb-1"
                                },
                                {
                                    "id": "5b0277e0-db4b-11ea-bdb5-fdc0741685fd",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "component": "MessageBoard"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-message-board",
                    "route": "/message-board",
                    "_sortIndex": 350,
                    "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                },
                {
                    "id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "233b7c71-c927-11ea-84bc-97bd01295e5b",
                            "children": [
                                {
                                    "id": "521b2680-c927-11ea-84bc-97bd01295e5b",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "New Post",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "59e133a0-c927-11ea-84bc-97bd01295e5b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "62ac7bc0-c927-11ea-84bc-97bd01295e5b",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "",
                                            "label": "Post Topic",
                                            "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"LF\", \"label\": \"LF (Looking For)\"}]",
                                            "dataField": "topic",
                                            "required": true,
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "bite-select pt-2"
                                },
                                {
                                    "id": "df291320-c927-11ea-8d76-f7e6b086de0b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e2116600-c927-11ea-8d76-f7e6b086de0b",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "",
                                            "label": "Title",
                                            "dataField": "title",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "eb70b0c0-c927-11ea-8d76-f7e6b086de0b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ed3236e0-c927-11ea-8d76-f7e6b086de0b",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "",
                                            "label": "Price",
                                            "dataField": "price",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "f42897f0-c927-11ea-8d76-f7e6b086de0b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f98df8c0-c927-11ea-8d76-f7e6b086de0b",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "",
                                            "label": "Description",
                                            "multiline": true,
                                            "dataField": "description",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "0b6fc2d0-c928-11ea-8d76-f7e6b086de0b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "0d2784f0-c928-11ea-8d76-f7e6b086de0b",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "",
                                            "label": "Contact Number",
                                            "dataField": "contactNumber",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "373868e0-c928-11ea-8d76-f7e6b086de0b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "38f5f760-c928-11ea-8d76-f7e6b086de0b",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "",
                                            "label": "Add Photos",
                                            "dataField": "photos",
                                            "basePath": "message-boards"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "bite-select"
                                },
                                {
                                    "id": "cf8faf20-e7f7-11ea-aa5b-4b966778e568",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "component": "TermsAndConditions",
                                    "name": "message-board"
                                },
                                {
                                    "id": "27ad7fc0-c92b-11ea-ad72-2b470c4935f8",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c9ca9980-1225-11eb-8323-55a37c955b67",
                                            "type": "subView",
                                            "children": [],
                                            "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-new-post",
                    "route": "/message-board/:id",
                    "_sortIndex": 360,
                    "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                },
                {
                    "id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "c2c20490-91b3-11ea-ba7f-61310864338b",
                            "children": [
                                {
                                    "id": "d1894830-91b3-11ea-ba7f-61310864338b",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Notifications",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "26512330-96da-11ea-a306-e3cce4ba1daa",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "294a17e0-96da-11ea-a306-e3cce4ba1daa",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "00b6be60-08f8-11eb-a297-615290505a3f",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "023d5e60-08f8-11eb-a297-615290505a3f",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "label": "Date Created",
                                                    "name": "createdAt",
                                                    "date": true,
                                                    "format": "MM/dd/yyyy hh:mm a"
                                                },
                                                {
                                                    "id": "24355a40-08f8-11eb-a297-615290505a3f",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "label": "Content",
                                                    "dataField": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "path": "#"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "component": "Notifications"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-notifications",
                    "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                    "route": "/notifications",
                    "_sortIndex": 370,
                    "repeat": false
                },
                {
                    "id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "a259bcb1-96d2-11ea-8416-c79b465e39ab",
                            "children": [
                                {
                                    "id": "a82b73e0-96d2-11ea-8416-c79b465e39ab",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Change password"
                                },
                                {
                                    "id": "d3e59b00-96d2-11ea-8416-c79b465e39ab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d049b3a0-0ff4-11eb-98c1-8382fbef0426",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "oldPassword",
                                            "label": "Old Password"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "d63c5c40-96d2-11ea-8416-c79b465e39ab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d7f38630-0ff4-11eb-98c1-8382fbef0426",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "password",
                                            "description": "",
                                            "label": "New Password"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "e2e88c20-0ff4-11eb-98c1-8382fbef0426",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e4a15fb0-0ff4-11eb-98c1-8382fbef0426",
                                            "type": "button",
                                            "children": [],
                                            "text": "Submit",
                                            "command": "change_password"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "component": "ChangePassword"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-password",
                    "route": "/account/change-password",
                    "_sortIndex": 380,
                    "component": ""
                },
                {
                    "id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "id": "2587d890-db5e-11ea-b61b-f3aa751f3b6f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c5cddbb1-c92f-11ea-baed-470d8f727899",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e297edd0-dc30-11ea-abb1-8b4052c10758",
                                            "type": "carousel",
                                            "children": [],
                                            "dataField": "photos",
                                            "text": "Preview",
                                            "direction": "ltr",
                                            "autoplay": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "main",
                                    "component": "",
                                    "flex": "1"
                                },
                                {
                                    "id": "3175e480-db5e-11ea-b61b-f3aa751f3b6f",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "flex": "1",
                                    "component": "MessageBoardInfo"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "856f1e40-dc39-11ea-b64f-0dfbc9206e7f",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "component": "MessageBoardComments"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-post",
                    "route": "/message-board-post/:id",
                    "_sortIndex": 390,
                    "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                    "noPadding": true
                },
                {
                    "id": "17466790-96d2-11ea-9995-9d94cc393546",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "0e752c01-96d2-11ea-9995-9d94cc393546",
                            "children": [
                                {
                                    "id": "8ad561c0-96d2-11ea-8416-c79b465e39ab",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Profile",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "6beeab70-e9fe-11ea-83ad-8d1a1d915279",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "component": "AccountPhoto",
                                    "dataField": "photo"
                                },
                                {
                                    "id": "0bcd5e60-e9f1-11ea-847f-6170db74bd04",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c9fed520-e9f2-11ea-afe7-79ef44d4232d",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "firstName",
                                            "className": "mr-1"
                                        },
                                        {
                                            "id": "cebc0f10-e9f2-11ea-afe7-79ef44d4232d",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "middleInitial",
                                            "className": "mr-1"
                                        },
                                        {
                                            "id": "d1a5c180-e9f2-11ea-afe7-79ef44d4232d",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "lastName",
                                            "className": "mr-1"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "p-2"
                                },
                                {
                                    "id": "74f1d0a0-e9f7-11ea-b01f-c974ed0277bf",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "7bc95f10-e9f7-11ea-b01f-c974ed0277bf",
                                            "type": "textBlock",
                                            "children": [],
                                            "className": "mr-3 profile-label",
                                            "text": "Nickname"
                                        },
                                        {
                                            "id": "88bf3e10-e9f7-11ea-b01f-c974ed0277bf",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "nickname"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "p-2"
                                },
                                {
                                    "id": "1e1ae290-e9f1-11ea-847f-6170db74bd04",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1ef42b90-e9f1-11ea-847f-6170db74bd04",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "",
                                            "text": "Email",
                                            "className": "mr-3 profile-label"
                                        },
                                        {
                                            "id": "a26fba10-e9f2-11ea-afe7-79ef44d4232d",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "email"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "p-2"
                                },
                                {
                                    "id": "239e0490-e9f1-11ea-847f-6170db74bd04",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ab7ced30-e9f2-11ea-afe7-79ef44d4232d",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "",
                                            "text": "Account No.",
                                            "className": "mr-3 profile-label"
                                        },
                                        {
                                            "id": "dc4b9600-e9f2-11ea-afe7-79ef44d4232d",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "accountNumber"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "p-2"
                                },
                                {
                                    "id": "27b40200-e9f1-11ea-847f-6170db74bd04",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "2895fd90-e9f1-11ea-847f-6170db74bd04",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "",
                                            "text": "Contact",
                                            "className": "mr-3 profile-label"
                                        },
                                        {
                                            "id": "f9d429d0-e9f2-11ea-afe7-79ef44d4232d",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "contact"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "p-2"
                                },
                                {
                                    "id": "2b4da380-e9f1-11ea-847f-6170db74bd04",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "2cbf5bf0-e9f1-11ea-847f-6170db74bd04",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "",
                                            "text": "Units",
                                            "className": "mr-3 profile-label"
                                        },
                                        {
                                            "id": "f0c967b0-e9f2-11ea-afe7-79ef44d4232d",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "unit",
                                            "component": "AccountUnits"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "p-2"
                                },
                                {
                                    "id": "419db040-ea09-11ea-b4be-3d136e0d16ce",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "4301fb30-ea09-11ea-b4be-3d136e0d16ce",
                                            "type": "button",
                                            "children": [],
                                            "fullWidth": false,
                                            "text": "Save",
                                            "command": "save"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-profile",
                    "route": "/account/profile/:id",
                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                    "_sortIndex": 400
                },
                {
                    "id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "df79f2a1-914a-11ea-8bb4-63310f210ad3",
                            "children": [
                                {
                                    "id": "376608f0-914b-11ea-9d03-f552f48ec793",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Unit Request"
                                },
                                {
                                    "id": "3e665510-914b-11ea-9d03-f552f48ec793",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "4c028630-914b-11ea-93c5-9720e806f72b",
                                            "type": "button",
                                            "children": [],
                                            "text": "View service history",
                                            "path": "/service-requests",
                                            "className": "",
                                            "variant": "text"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "justify-content-center pt-1 pb-1",
                                    "flex": "[object Object]"
                                },
                                {
                                    "id": "427db210-914b-11ea-9d03-f552f48ec793",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "bf2b7640-d902-11ea-b7fc-037f001b1342",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "type",
                                            "options": "[{ \"value\": \"repairs\", \"label\": \"Repairs\"},{ \"value\": \"renovation\", \"label\": \"Renovation\"},{ \"value\": \"carSticker\", \"label\": \"Car Sticker\", data: null }]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "justify-content-center pt-2 pb-2",
                                    "name": "select"
                                },
                                {
                                    "id": "44bc5770-914b-11ea-9d03-f552f48ec793",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a35c3b70-c90d-11ea-8c59-196c3ec4f839",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                            "name": "repairs"
                                        },
                                        {
                                            "id": "2697efe0-c911-11ea-9dd0-6723feee229e",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                            "name": "renovation"
                                        },
                                        {
                                            "id": "2a9de7c0-c911-11ea-9dd0-6723feee229e",
                                            "type": "subView",
                                            "children": [],
                                            "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                            "name": "carSticker"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "pt-2 pb-2",
                                    "name": "select",
                                    "component": "UnitRequest"
                                },
                                {
                                    "id": "2ac5bef0-ad21-11ea-8384-7b922845035e",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "316b40e0-ad21-11ea-8384-7b922845035e",
                                            "type": "button",
                                            "children": [],
                                            "command": "save",
                                            "variant": "",
                                            "text": "submit",
                                            "color": "primary",
                                            "className": "",
                                            "fullWidth": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "className": "p-1"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-service-request",
                    "route": "/create-service-request/:id",
                    "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                    "_sortIndex": 410
                },
                {
                    "id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "6053d8f1-91b4-11ea-ad0e-b15c87c4d0e0",
                            "children": [
                                {
                                    "id": "bc5b9200-91b4-11ea-ad0e-b15c87c4d0e0",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Unit Request"
                                },
                                {
                                    "id": "39f86670-91b5-11ea-ad0e-b15c87c4d0e0",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3e960a70-91b5-11ea-ad0e-b15c87c4d0e0",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "424ad510-91b5-11ea-ad0e-b15c87c4d0e0",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "",
                                                    "dataField": "details"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "path": "/create-service-request"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "3c5ac070-91b5-11ea-ad0e-b15c87c4d0e0",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-service-requests",
                    "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                    "route": "/service-requests",
                    "_sortIndex": 420
                },
                {
                    "id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "d2d04d11-d902-11ea-b7fc-037f001b1342",
                            "className": "",
                            "children": [
                                {
                                    "id": "04c0e0a0-d903-11ea-b7fc-037f001b1342",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Unit Request",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "afdbb6d0-d904-11ea-8fb7-85b0ea54cdde",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "bfa2b730-d904-11ea-8fb7-85b0ea54cdde",
                                            "type": "button",
                                            "children": [],
                                            "text": "View Unit Requests History",
                                            "variant": "text",
                                            "path": "/unit-requests"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "justify-content-center pt-1 pb-1"
                                },
                                {
                                    "id": "84f0dbb0-d906-11ea-bd61-bf1e0d1003bd",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "020ce720-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "04ead9c0-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "unit",
                                                    "label": "Unit",
                                                    "dataField": "",
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                    "nullable": false,
                                                    "required": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "unit-container"
                                        },
                                        {
                                            "id": "21b5e9f0-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "29662520-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "saveObject": true,
                                                    "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                    "dataField": "type",
                                                    "name": "type",
                                                    "label": "Type",
                                                    "required": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "type-container"
                                        },
                                        {
                                            "id": "47ac1580-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                            "name": "homeowner-unit-request-service-request"
                                        },
                                        {
                                            "id": "60137d70-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                            "name": "homeowner-unit-request-plans-documents"
                                        },
                                        {
                                            "id": "71865910-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "subView",
                                            "children": [],
                                            "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                            "name": "homeowner-unit-request-refund-renovation-bond"
                                        },
                                        {
                                            "id": "7c6788e0-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "subView",
                                            "children": [],
                                            "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                            "name": "homeowner-unit-request-vehicle-sticker"
                                        },
                                        {
                                            "id": "82e844c0-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "subView",
                                            "children": [],
                                            "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                            "name": "homeowner-unit-request-grease-trap-cleaning"
                                        },
                                        {
                                            "id": "30acac90-d9a7-11ea-9c8b-b7f572d43c0f",
                                            "type": "subView",
                                            "children": [],
                                            "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                            "name": "homeowner-unit-request-aircon-cleaning"
                                        },
                                        {
                                            "id": "16922b80-d9a9-11ea-9331-090047662dd2",
                                            "type": "subView",
                                            "children": [],
                                            "name": "homeowner-unit-request-others",
                                            "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "component": "UnitRequest",
                                    "name": "homeowner-unit-request"
                                },
                                {
                                    "id": "0dd9e6c0-dac8-11ea-9725-dfc9cbd591dc",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c2c5e7f0-1222-11eb-a9d8-83db08ab78a0",
                                            "type": "subView",
                                            "children": [],
                                            "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "component": ""
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-request",
                    "className": "",
                    "route": "/homeowner-unit-request/:id",
                    "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                    "_sortIndex": 430
                },
                {
                    "id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "b3e97d21-9156-11ea-88a4-7f4a31012a76",
                            "children": [
                                {
                                    "id": "cda662f0-9156-11ea-88a4-7f4a31012a76",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Visitor / Tenant Passes",
                                    "_sortIndex": 0,
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "24752260-9157-11ea-88a4-7f4a31012a76",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "80f84ec0-96c2-11ea-8d81-e3df0146485b",
                                            "type": "button",
                                            "children": [],
                                            "path": "/visitor-passes",
                                            "text": "My Visitor / Tenant Passes",
                                            "variant": "text"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 9,
                                    "className": "justify-content-center"
                                },
                                {
                                    "id": "d2c56bf0-9156-11ea-88a4-7f4a31012a76",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "65ddc8e0-da1a-11ea-b50d-8323e621ee63",
                                            "type": "inputSelect",
                                            "children": [],
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "dataField": "unit",
                                            "label": "Unit",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10,
                                    "className": ""
                                },
                                {
                                    "id": "d4c90420-9156-11ea-88a4-7f4a31012a76",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b1b7f790-da1a-11ea-b50d-8323e621ee63",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "type",
                                            "label": "Type of Pass",
                                            "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"}, {\"label\":\"Long-term Tenant (More than 3 months)\", \"value\":\"long-term\"}]",
                                            "saveObject": true,
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20,
                                    "className": ""
                                },
                                {
                                    "id": "d80f2e70-9156-11ea-88a4-7f4a31012a76",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "0ca06880-da1c-11ea-87e9-9de57275fc27",
                                            "type": "subView",
                                            "children": [],
                                            "name": "homeowner-visitor-pass-visitor-pass",
                                            "view": "824f51f0-da1b-11ea-87e9-9de57275fc27"
                                        },
                                        {
                                            "id": "f591eef0-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "subView",
                                            "children": [],
                                            "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                            "name": "homeowner-visitor-pass-short-term"
                                        },
                                        {
                                            "id": "8667e010-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "subView",
                                            "children": [],
                                            "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                            "name": "homeowner-visitor-pass-long-term"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30,
                                    "className": "justify-content-center",
                                    "component": "VisitorPass",
                                    "name": "homeowner-visitor-pass"
                                },
                                {
                                    "id": "da09ed00-9156-11ea-88a4-7f4a31012a76",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a2554800-1225-11eb-8323-55a37c955b67",
                                            "type": "subView",
                                            "children": [],
                                            "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40,
                                    "className": "justify-content-center"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-visitor-pass",
                    "route": "/create-visitor-pass/:id",
                    "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                    "className": "",
                    "_sortIndex": 440
                },
                {
                    "id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "id": "bf9e7ca0-96c5-11ea-8624-43dc05a96ae0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c95f4850-96c5-11ea-8624-43dc05a96ae0",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Visitor Passes",
                                    "className": "has-text-centered"
                                }
                            ],
                            "orientation": "vertical",
                            "_sortIndex": -1,
                            "className": ""
                        },
                        {
                            "name": "main",
                            "type": "container",
                            "id": "09704a71-96c1-11ea-8d81-e3df0146485b",
                            "children": [
                                {
                                    "id": "e49e00e0-01e9-11eb-bf8e-6d1770bf29d6",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "e73f1190-01e9-11eb-bf8e-6d1770bf29d6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "unit",
                                            "name": "unit",
                                            "label": "Unit"
                                        },
                                        {
                                            "id": "f89675f0-01e9-11eb-bf8e-6d1770bf29d6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "type",
                                            "name": "type",
                                            "label": "Type"
                                        },
                                        {
                                            "id": "048237f0-01ea-11eb-bf8e-6d1770bf29d6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "startDate",
                                            "name": "startDate",
                                            "label": "Start Date"
                                        },
                                        {
                                            "id": "14b6ee40-01ea-11eb-bf8e-6d1770bf29d6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "endDate",
                                            "name": "endDate",
                                            "label": "End Date"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "path": "/pass-visitors-detail"
                                }
                            ],
                            "_sortIndex": 0
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-visitor-passes",
                    "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                    "route": "/visitor-passes",
                    "_sortIndex": 450
                },
                {
                    "id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                    "type": "page",
                    "children": [
                        {
                            "id": "478f4730-c6f9-11ea-8043-0381a4f882af",
                            "type": "subView",
                            "children": [],
                            "view": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-welcome-page",
                    "route": "/welcome",
                    "component": "",
                    "_sortIndex": 460,
                    "requireAuth": true
                },
                {
                    "id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "dac583a1-d9ca-11ea-b444-450a6b52ef90",
                            "children": [
                                {
                                    "id": "f6978dd0-d9ca-11ea-b444-450a6b52ef90",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Work Permit",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "a2b3bab0-da09-11ea-87ea-f5980a7a1cab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a4b07510-da09-11ea-87ea-f5980a7a1cab",
                                            "type": "button",
                                            "children": [],
                                            "text": "View Work Permit History",
                                            "variant": "text",
                                            "path": "/work-permits"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "justify-content-center pt-1 pb-1"
                                },
                                {
                                    "id": "5ad52cd0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "607dff40-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "inputSelect",
                                            "children": [],
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "name": "unit",
                                            "label": "Unit",
                                            "dataField": "unit",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "9d05c150-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "bb60e760-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "natureOfWork",
                                            "label": "Nature of Work",
                                            "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                            "saveObject": true,
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "d06acc70-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d1a632a0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "contractor",
                                            "label": "Name of Contractor",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "dfc94700-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e0f19a60-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "personInCharge",
                                            "label": "Name of Person in Charge",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "ec2bbfa0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "eee8e3d0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "contactPersonInCharge",
                                            "label": "Contact Number of Person in Charge",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "4f549340-d9cc-11ea-83f3-4b9e6a8a3ce6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "12242dc0-d9cf-11ea-be94-03767312b052",
                                            "type": "inputList",
                                            "children": [],
                                            "label": "Workers",
                                            "dataField": "workers",
                                            "placeholder": "Worker",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "77fa9030-da06-11ea-8a8e-430e67178950",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "7d094580-da06-11ea-8a8e-430e67178950",
                                            "type": "inputText",
                                            "children": [],
                                            "multiline": true,
                                            "dataField": "description",
                                            "label": "Description of Work",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "f2f0d5b0-da06-11ea-8a8e-430e67178950",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "fe279f90-da06-11ea-8a8e-430e67178950",
                                            "type": "inputList",
                                            "children": [],
                                            "dataField": "materials",
                                            "label": "List of Materials",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "2ebf0bc0-da07-11ea-8a8e-430e67178950",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "33285950-da07-11ea-8a8e-430e67178950",
                                            "type": "inputList",
                                            "children": [],
                                            "dataField": "tools",
                                            "label": "List of Tools",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "3bd32120-da07-11ea-8a8e-430e67178950",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3e8fd020-da07-11ea-8a8e-430e67178950",
                                            "type": "inputDate",
                                            "children": [],
                                            "dataField": "startDate",
                                            "label": "Proposed Start Date ",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "5217a910-da07-11ea-8a8e-430e67178950",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "558c3660-da07-11ea-8a8e-430e67178950",
                                            "type": "inputDate",
                                            "children": [],
                                            "label": "Target End Date",
                                            "dataField": "endDate",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "67781290-da07-11ea-8a8e-430e67178950",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6a6f0b70-da07-11ea-8a8e-430e67178950",
                                            "type": "inputMedia",
                                            "children": [],
                                            "dataField": "plans",
                                            "label": "Photo of Plans",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "814543c0-e7f7-11ea-aa5b-4b966778e568",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "work-permit",
                                    "component": "TermsAndConditions"
                                },
                                {
                                    "id": "f8277f00-1225-11eb-8323-55a37c955b67",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "fa036af0-1225-11eb-8323-55a37c955b67",
                                            "type": "subView",
                                            "children": [],
                                            "view": "c8274690-1221-11eb-a9d8-83db08ab78a0"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-work-permit",
                    "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                    "route": "/homeowner-work-permit/:id",
                    "_sortIndex": 470
                },
                {
                    "id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                            "children": [
                                {
                                    "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Message Board",
                                    "_sortIndex": 0,
                                    "name": "",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "e3205a10-05e5-11eb-bd99-6f65d415d15a",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e6c1c3c0-05e5-11eb-bd99-6f65d415d15a",
                                            "type": "inputDateTime",
                                            "children": [],
                                            "name": "createdAt",
                                            "label": "Date Submitted"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c1110200-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c1110202-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "homeowner",
                                            "label": "Homeowner",
                                            "dataField": "homeowner",
                                            "saveObject": true,
                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c1110203-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "22f119c0-ea54-11ea-8de6-3f209e03f86c",
                                            "type": "inputSelect",
                                            "children": [],
                                            "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"LF\", \"label\": \"LF (Looking For)\"}]",
                                            "saveObject": true,
                                            "name": "topic",
                                            "component": "",
                                            "label": "Topic",
                                            "dataField": "topic"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c1110206-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c1110208-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "title",
                                            "label": "Title",
                                            "dataField": "title"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c1110209-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c1112910-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "price",
                                            "label": "Price",
                                            "dataField": "price"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c1112911-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c1112913-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "description",
                                            "label": "Description",
                                            "dataField": "description",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c1112914-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c1112916-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "contactNumber",
                                            "label": "Contact Number",
                                            "dataField": "contactNumber"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c1112917-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c1112919-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "",
                                            "label": "Photos",
                                            "dataField": "photos",
                                            "basePath": "message-boards"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c111291a-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a0d34530-f0a2-11ea-be87-f549caf53aba",
                                            "type": "inputSelect",
                                            "children": [],
                                            "label": "Status",
                                            "dataField": "status",
                                            "options": "[{\"value\": \"done\", \"label\": \"Done\"}, {\"value\": \"approved\", \"label\": \"Approved\"}, {\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                            "saveObject": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": "status"
                                },
                                {
                                    "id": "c111291d-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c111291f-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "notes",
                                            "label": "Notes",
                                            "dataField": "notes",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "b8a70560-f0b2-11ea-99d0-d9f36ea6d179",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "28fa05f0-0360-11eb-ba8d-6d2ea3361677",
                                            "type": "inputDateTime",
                                            "children": [],
                                            "label": "Date Posted",
                                            "dataField": "datePosted"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        },
                        {
                            "id": "c10987f0-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "message-board-edit-page",
                    "route": "/message-boards/:id",
                    "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                    "_sortIndex": 750
                },
                {
                    "id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                            "children": [
                                {
                                    "id": "c11b8950-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "c1217cc0-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "c1283380-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "createdAt",
                                            "label": "Date Submitted",
                                            "dataField": "",
                                            "date": true
                                        },
                                        {
                                            "id": "c1283382-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "topic",
                                            "label": "Topic",
                                            "dataField": "topic"
                                        },
                                        {
                                            "id": "c1285a90-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "title",
                                            "label": "Title",
                                            "dataField": "title"
                                        },
                                        {
                                            "id": "c1285a92-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "price",
                                            "label": "Price",
                                            "dataField": "price"
                                        },
                                        {
                                            "id": "c1285a96-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "contactNumber",
                                            "label": "Description",
                                            "dataField": "description"
                                        },
                                        {
                                            "id": "c1285a9a-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "status",
                                            "label": "Contact Number",
                                            "dataField": "contactNumber"
                                        },
                                        {
                                            "id": "5fd419e0-f0a4-11ea-a584-176526b5be96",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "homeowner",
                                            "text": "",
                                            "label": "Homeowner"
                                        },
                                        {
                                            "id": "6097b800-f0a4-11ea-a584-176526b5be96",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Status",
                                            "dataField": "status",
                                            "name": "status"
                                        },
                                        {
                                            "id": "5a089900-fdf1-11ea-ba93-5145fd1d7baf",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Date Posted",
                                            "name": "",
                                            "date": true,
                                            "format": "MM-dd-yyyy hh:mm a",
                                            "dataField": "datePosted"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "message-board-list-page",
                    "route": "/message-boards-list",
                    "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                    "_sortIndex": 760
                },
                {
                    "id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                            "children": [
                                {
                                    "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Notifications",
                                    "_sortIndex": 0,
                                    "name": "",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "a8fabff0-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a8fabff2-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "name",
                                            "label": "Name",
                                            "dataField": "name"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a8fabff3-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a8fabff5-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "text",
                                            "label": "Text",
                                            "dataField": "text"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a8fabff6-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a8fabff8-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "noticeStart",
                                            "label": "Notice Start",
                                            "dataField": "noticeStart",
                                            "format": "mm/dd/yyyy"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a8fabff9-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a8fabffb-912e-11ea-ad57-6526d978cdf6",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "noticeEnd",
                                            "label": "Notice End",
                                            "dataField": "noticeEnd",
                                            "format": "mm/dd/yyyy"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a8fabfff-912e-11ea-ad57-6526d978cdf6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "219108b0-f0a3-11ea-a584-176526b5be96",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "homeowner",
                                            "label": "Homeowner",
                                            "options": "",
                                            "name": "",
                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                            "saveObject": true,
                                            "nullable": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        },
                        {
                            "id": "a8f7b2b0-912e-11ea-ad57-6526d978cdf6",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "notification-detail-page",
                    "route": "/notifications/:id",
                    "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                    "_sortIndex": 480
                },
                {
                    "id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                            "children": [
                                {
                                    "id": "a9012890-912e-11ea-ad57-6526d978cdf6",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "a9045ce0-912e-11ea-ad57-6526d978cdf6",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "a907b840-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "createdAt",
                                            "label": "Sent To",
                                            "dataField": "name",
                                            "date": true,
                                            "format": "MM/dd/yyyy hh:mm a"
                                        },
                                        {
                                            "id": "a907b842-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "",
                                            "label": "Content",
                                            "dataField": "text"
                                        },
                                        {
                                            "id": "a907b844-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "noticeStart",
                                            "label": "noticeStart",
                                            "dataField": "noticeStart",
                                            "date": true
                                        },
                                        {
                                            "id": "a907b846-912e-11ea-ad57-6526d978cdf6",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "noticeEnd",
                                            "label": "noticeEnd",
                                            "dataField": "noticeEnd",
                                            "date": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "notification-list-page",
                    "route": "/notifications-list",
                    "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                    "_sortIndex": 490
                },
                {
                    "id": "61855560-e82a-11ea-bffd-65b1c6e24159",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                            "children": [
                                {
                                    "id": "a5ad4300-f0af-11ea-a61b-49eea0a1bb78",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Delivery",
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "61a11ac0-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6e9301c0-fdd9-11ea-810b-8f97cf48f34d",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "unit",
                                            "name": "unit",
                                            "label": "Unit",
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "61a141d0-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f0707b30-ea53-11ea-8de6-3f209e03f86c",
                                            "type": "inputSelect",
                                            "children": [],
                                            "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                            "saveObject": true,
                                            "name": "type",
                                            "label": "Type",
                                            "dataField": "type",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "61a141d3-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "82f752a0-fdc6-11ea-a2b5-65e9923dd687",
                                            "type": "inputDateTime",
                                            "children": [],
                                            "name": "schedule",
                                            "dataField": "schedule",
                                            "label": "Schedule",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "61a141d6-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9be1f780-fdca-11ea-a5e4-656dbac2217a",
                                            "type": "inputList",
                                            "children": [],
                                            "name": "items",
                                            "label": "Items",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "61a141d9-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "61a141db-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "company",
                                            "label": "Company",
                                            "dataField": "company",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40
                                },
                                {
                                    "id": "61a141dc-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "91342c40-f0af-11ea-a61b-49eea0a1bb78",
                                            "type": "inputSelect",
                                            "children": [],
                                            "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                            "dataField": "status",
                                            "label": "Status"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 50
                                },
                                {
                                    "id": "61a141df-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "61a141e1-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "notes",
                                            "label": "Notes",
                                            "dataField": "notes",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 60
                                }
                            ]
                        },
                        {
                            "id": "619ad930-e82a-11ea-bffd-65b1c6e24159",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "pass-delivery-edit-page",
                    "route": "/pass-deliveries/:id",
                    "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84"
                },
                {
                    "id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                            "children": [
                                {
                                    "id": "61ad9de0-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "61b34330-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "0909f810-01ec-11eb-81b5-f15d97fd1729",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "createdAt",
                                            "label": "Date Submitted",
                                            "date": true
                                        },
                                        {
                                            "id": "61ba4810-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "unit",
                                            "label": "Unit",
                                            "dataField": "unit"
                                        },
                                        {
                                            "id": "61ba4812-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "type",
                                            "label": "Type",
                                            "dataField": "type"
                                        },
                                        {
                                            "id": "61ba4814-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "schedule",
                                            "label": "Schedule",
                                            "dataField": "schedule",
                                            "date": true,
                                            "format": "MM/dd/yyyy hh:mm a"
                                        },
                                        {
                                            "id": "61ba4818-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "company",
                                            "label": "Company",
                                            "dataField": "company"
                                        },
                                        {
                                            "id": "61ba6f20-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "status",
                                            "label": "Status",
                                            "dataField": "status"
                                        },
                                        {
                                            "id": "61ba6f22-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "notes",
                                            "label": "Notes",
                                            "dataField": "notes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "path": "/pass-deliveries-list/detail"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "pass-delivery-list-page",
                    "route": "/pass-deliveries-list",
                    "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84"
                },
                {
                    "id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                            "children": [
                                {
                                    "id": "c4927940-f0b2-11ea-99d0-d9f36ea6d180",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Visitor / Tenant Passes",
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "3fb18ad0-df4c-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3fb18ad2-df4c-11ea-a4a7-a1e8c04129ca",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "unit",
                                            "label": "Unit",
                                            "dataField": "unit",
                                            "saveObject": true,
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "3fb18ad9-df4c-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d5c38580-ea52-11ea-8de6-3f209e03f86c",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "type",
                                            "label": "Type",
                                            "dataField": "type",
                                            "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "3fb18adc-df4c-11ea-a4a7-a1e8c04129ca",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e8041160-ea52-11ea-8de6-3f209e03f86c",
                                            "type": "subView",
                                            "children": [],
                                            "view": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                                            "name": "homeowner-visitor-pass-visitor-pass"
                                        },
                                        {
                                            "id": "8b0b9a40-ea53-11ea-8de6-3f209e03f86c",
                                            "type": "subView",
                                            "children": [],
                                            "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                            "name": "homeowner-visitor-pass-long-term"
                                        },
                                        {
                                            "id": "983a1570-ea53-11ea-8de6-3f209e03f86c",
                                            "type": "subView",
                                            "children": [],
                                            "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                            "name": "homeowner-visitor-pass-short-term"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "component": "VisitorPass",
                                    "name": "homeowner-visitor-pass"
                                },
                                {
                                    "id": "1f8ccac0-f726-11ea-80a6-010ee6be16e5",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6e8fb0b0-f726-11ea-80a6-010ee6be16e5",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "status",
                                            "label": "Status",
                                            "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]",
                                            "saveObject": true,
                                            "dataField": "status"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": "status"
                                },
                                {
                                    "id": "92fe8410-f796-11ea-8826-7fd8e10f31a2",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a2dd2b20-f796-11ea-8826-7fd8e10f31a2",
                                            "type": "inputText",
                                            "children": [],
                                            "multiline": true,
                                            "name": "notes",
                                            "label": "Notes",
                                            "dataField": "notes",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        },
                        {
                            "id": "3faafb20-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "pass-visitor-edit-page",
                    "route": "/pass-visitors/:id",
                    "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721"
                },
                {
                    "id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                            "children": [
                                {
                                    "id": "4f6bd110-f7a2-11ea-a255-11f808a5c391",
                                    "type": "subView",
                                    "children": [],
                                    "name": "search",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "3fc22ca0-df4c-11ea-a4a7-a1e8c04129ca",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "fb4ba560-035e-11eb-ba8d-6d2ea3361677",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Date Submitted",
                                            "name": "createdAt",
                                            "date": true
                                        },
                                        {
                                            "id": "3fc62440-df4c-11ea-a4a7-a1e8c04129ca",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "unit",
                                            "label": "Unit",
                                            "dataField": "unit"
                                        },
                                        {
                                            "id": "3fc6244a-df4c-11ea-a4a7-a1e8c04129ca",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "type",
                                            "label": "Type",
                                            "dataField": "type"
                                        },
                                        {
                                            "id": "dd3b7a00-ea4f-11ea-b967-5baa6500e444",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Start Date",
                                            "dataField": "startDate",
                                            "date": true
                                        },
                                        {
                                            "id": "e479b980-ea4f-11ea-b967-5baa6500e444",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "endDate",
                                            "text": "",
                                            "label": "End Date",
                                            "date": true
                                        },
                                        {
                                            "id": "a2920620-ea55-11ea-8de6-3f209e03f86c",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "status",
                                            "label": "Status"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "path": "/pass-visitors-list/detail"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "pass-visitor-list-page",
                    "route": "/pass-visitors-list",
                    "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721"
                },
                {
                    "id": "50c11f60-ef74-11ea-8620-1757bce541ea",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "50c11f61-ef74-11ea-8620-1757bce541ea",
                            "children": [
                                {
                                    "id": "7542f9f0-f0b2-11ea-99d0-d9f36ea6d179",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Payment",
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "50d17310-ef74-11ea-8620-1757bce541ea",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "50d17312-ef74-11ea-8620-1757bce541ea",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "homeowner",
                                            "label": "Homeowner",
                                            "dataField": "homeowner",
                                            "saveObject": true,
                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "50d17313-ef74-11ea-8620-1757bce541ea",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "50d17315-ef74-11ea-8620-1757bce541ea",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "receipt",
                                            "label": "Receipt",
                                            "dataField": "receipt"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "50d17319-ef74-11ea-8620-1757bce541ea",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "50d1731b-ef74-11ea-8620-1757bce541ea",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "postingDate",
                                            "label": "Posting Date",
                                            "dataField": "postingDate",
                                            "format": ""
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "3b2e9910-f669-11ea-a7eb-5542a4efbc2d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3e00a4d0-f669-11ea-a7eb-5542a4efbc2d",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "statement",
                                            "label": "Statement"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "_sortIndex": 0
                        },
                        {
                            "id": "7a7eda40-ef74-11ea-b56c-03bb3a7ec541",
                            "type": "subView",
                            "children": [],
                            "view": "927fbf90-ef71-11ea-ae43-d3d081a35642",
                            "_sortIndex": 10,
                            "relation": "payment"
                        },
                        {
                            "id": "50cb3180-ef74-11ea-8620-1757bce541ea",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                            "_sortIndex": 30
                        }
                    ],
                    "orientation": "vertical",
                    "name": "payment-detail-page",
                    "route": "/payments/:id",
                    "dataModel": "e682f860-ef70-11ea-983b-e7b329586acc"
                },
                {
                    "id": "9273b1a0-ef71-11ea-ae43-d3d081a35642",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "9273b1a1-ef71-11ea-ae43-d3d081a35642",
                            "children": [
                                {
                                    "id": "86c58d00-f0b2-11ea-99d0-d9f36ea6d179",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Payment",
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "927bef00-ef71-11ea-ae43-d3d081a35642",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "927bef02-ef71-11ea-ae43-d3d081a35642",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "payment",
                                            "label": "Payment",
                                            "dataField": "payment",
                                            "saveObject": true,
                                            "relation": "555659e0-9138-11ea-bd78-399a56c40918"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "927bef03-ef71-11ea-ae43-d3d081a35642",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "927bef05-ef71-11ea-ae43-d3d081a35642",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "item",
                                            "label": "Item",
                                            "dataField": "item"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "927bef06-ef71-11ea-ae43-d3d081a35642",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "927bef08-ef71-11ea-ae43-d3d081a35642",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "description",
                                            "label": "Description",
                                            "dataField": "description"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "927bef09-ef71-11ea-ae43-d3d081a35642",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "927bef0b-ef71-11ea-ae43-d3d081a35642",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "amount",
                                            "label": "Amount",
                                            "dataField": "amount"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "927bef0c-ef71-11ea-ae43-d3d081a35642",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "927bef0e-ef71-11ea-ae43-d3d081a35642",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "state",
                                            "label": "State",
                                            "dataField": "state"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40
                                }
                            ]
                        },
                        {
                            "id": "927893a0-ef71-11ea-ae43-d3d081a35642",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "payment-item-detail-page",
                    "route": "/payment-items/:id",
                    "dataModel": "24ee6e90-ef71-11ea-ae43-d3d081a35642"
                },
                {
                    "id": "50d606f0-ef74-11ea-8620-1757bce541eb",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "50d606f0-ef74-11ea-8620-1757bce541ec",
                            "children": [
                                {
                                    "id": "19d6c0b0-f65c-11ea-bcea-e53105b58e3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1e879e90-f65c-11ea-bcea-e53105b58e3f",
                                            "type": "button",
                                            "children": [],
                                            "command": "new_payment",
                                            "text": "upload"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "e727bc30-01ed-11eb-81b5-f15d97fd1729",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ea6f1f00-01ed-11eb-81b5-f15d97fd1729",
                                            "type": "subView",
                                            "children": [],
                                            "view": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
                                            "component": "Searchbar",
                                            "name": "searchbar"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "component": "",
                                    "name": ""
                                },
                                {
                                    "id": "50e10370-ef74-11ea-8620-1757bce541ea",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "bbf57030-f65c-11ea-bcea-e53105b58e3f",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "receipt",
                                            "label": "Receipt"
                                        },
                                        {
                                            "id": "c247f020-f65c-11ea-bcea-e53105b58e3f",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "homeowner",
                                            "label": "Homeowner"
                                        },
                                        {
                                            "id": "c84ec110-f65c-11ea-bcea-e53105b58e3f",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "statement",
                                            "label": "Statement"
                                        },
                                        {
                                            "id": "2d7961b0-f669-11ea-a7eb-5542a4efbc2d",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Posting Date",
                                            "dataField": "postingDate",
                                            "date": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ],
                            "component": "PaymentUpload"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "payment-list-page",
                    "route": "/payments-list",
                    "dataModel": "e682f860-ef70-11ea-983b-e7b329586acc"
                },
                {
                    "id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                            "children": [
                                {
                                    "id": "c4927940-f0b2-11ea-99d0-d9f36ea6d179",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Terms and Conditions",
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "5aa647c0-e507-11ea-8276-65e120bbe853",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "5aa647c2-e507-11ea-8276-65e120bbe853",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "name",
                                            "label": "Name",
                                            "dataField": "name"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "5aa647c3-e507-11ea-8276-65e120bbe853",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "5aa66ed0-e507-11ea-8276-65e120bbe853",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "content",
                                            "label": "Content",
                                            "dataField": "content",
                                            "multiline": true,
                                            "line": "20"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                }
                            ]
                        },
                        {
                            "id": "5aa1daf0-e507-11ea-8276-65e120bbe853",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "terms-conditions-detail-page",
                    "route": "/terms-conditions/:id",
                    "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853"
                },
                {
                    "id": "5aabc600-e507-11ea-8276-65e120bbe853",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                            "children": [
                                {
                                    "id": "5ab16b50-e507-11ea-8276-65e120bbe853",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "5ab6e990-e507-11ea-8276-65e120bbe853",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "5ababa20-e507-11ea-8276-65e120bbe853",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "name",
                                            "label": "Name",
                                            "dataField": "name"
                                        },
                                        {
                                            "id": "5ababa22-e507-11ea-8276-65e120bbe853",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "content",
                                            "label": "Content",
                                            "dataField": "content"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "terms-conditions-list-page",
                    "route": "/terms-conditions-list",
                    "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853"
                },
                {
                    "id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                            "children": [
                                {
                                    "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Units",
                                    "_sortIndex": 0,
                                    "name": "",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "0824d8f0-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "0824d8f2-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "number",
                                            "label": "Unit Number",
                                            "dataField": "number"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "0824d8f6-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b50bf760-12ab-11eb-a859-31a630fb4ca4",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "type",
                                            "label": "Type",
                                            "dataField": "type",
                                            "options": "[{\"value\":\"Studio\", \"label\": \"Studio\"},{\"value\":\"One Bedroom\", \"label\": \"One Bedroom\"},{\"value\":\"Two Bedroom\", \"label\": \"Two Bedroom\"}, {\"value\":\"Parking\", \"label\": \"Parking\"}]",
                                            "saveObject": true,
                                            "placeholder": "",
                                            "preselected": false
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "40a367e0-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "56825030-edd1-11ea-993f-a977bcf36a2d",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "areaSize",
                                            "label": "Floor Area (sqm)",
                                            "dataField": "areaSize"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "",
                                    "dataField": ""
                                },
                                {
                                    "id": "6208e7c0-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1902f3a0-12b0-11eb-a48f-8f1c197a5101",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "classification",
                                            "dataField": "classification",
                                            "label": "Classification",
                                            "options": "[{\"value\":\"leased\", \"label\": \"Leased\"},{\"value\":\"own-use\", \"label\": \"Own Use\"},{\"value\":\"rented-out\", \"label\": \"Rented Out\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "773fa930-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "7942cc30-edd1-11ea-993f-a977bcf36a2d",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "projectCode",
                                            "name": "projectCode",
                                            "label": "Project Code"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "9dcb8510-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b88f0570-edd1-11ea-993f-a977bcf36a2d",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "customerCode",
                                            "label": "Customer Code",
                                            "dataField": "customerCode"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c24f5bf0-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d57a6ee0-edd1-11ea-993f-a977bcf36a2d",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "turnoverDate",
                                            "label": "Turnover Date",
                                            "dataField": "turnoverDate"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": ""
                                },
                                {
                                    "id": "f37a2e80-edd1-11ea-993f-a977bcf36a2d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c11401b0-12b0-11eb-a48f-8f1c197a5101",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "homeowner",
                                            "dataField": "homeowner",
                                            "label": "Homeowner",
                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": ""
                                },
                                {
                                    "id": "71d63580-ef99-11ea-9d3f-8def54ed8352",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "dc08f230-ef99-11ea-9d3f-8def54ed8352",
                                            "type": "header",
                                            "children": [],
                                            "size": "4",
                                            "text": "Date of Last Service"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "e7e17a50-ef99-11ea-9d3f-8def54ed8352",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f38277b0-ef99-11ea-9d3f-8def54ed8352",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "greaseTrapCleaning",
                                            "label": "Grease Trap Cleaning",
                                            "dataField": "greaseTrapCleaning"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": "",
                                    "name": ""
                                },
                                {
                                    "id": "fc7ce620-ef99-11ea-9d3f-8def54ed8352",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "fe46a9a0-ef99-11ea-9d3f-8def54ed8352",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "airconCleaning",
                                            "label": "A/C Cleaning",
                                            "dataField": "airconCleaning"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        },
                        {
                            "id": "081fa8d0-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "unit-detail-page",
                    "route": "/units/:id",
                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                    "_sortIndex": 520
                },
                {
                    "id": "0829e200-d84f-11ea-acd1-c1cd20d0424b",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "0829e200-d84f-11ea-acd1-c1cd20d0424c",
                            "children": [
                                {
                                    "id": "2524edb0-f65d-11ea-87ea-87e5ac731b3a",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "29d640c0-f65d-11ea-87ea-87e5ac731b3a",
                                            "type": "button",
                                            "children": [],
                                            "text": "Upload",
                                            "command": "upload_unit"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "08310df0-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "08341b30-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "0838fd30-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "Number",
                                            "label": "Unit Number",
                                            "dataField": "number"
                                        },
                                        {
                                            "id": "c86067a0-f0a6-11ea-a584-176526b5be96",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Customer Code",
                                            "dataField": "customerCode"
                                        },
                                        {
                                            "id": "ca3834e0-f0a6-11ea-a584-176526b5be96",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "homeowner",
                                            "label": "Homeowner"
                                        },
                                        {
                                            "id": "fd718c50-f65d-11ea-87ea-87e5ac731b3a",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "greaseTrapCleaning",
                                            "text": "",
                                            "label": "Grease Trap Cleaning",
                                            "date": true
                                        },
                                        {
                                            "id": "08ac26c0-f65e-11ea-87ea-87e5ac731b3a",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "A/C Cleaning",
                                            "dataField": "airconCleaning",
                                            "date": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ],
                            "component": "UnitUpload"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "unit-list-page",
                    "route": "/units-list",
                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                    "_sortIndex": 530,
                    "component": ""
                },
                {
                    "id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                            "children": [
                                {
                                    "id": "1d1b3ee0-167d-11eb-83c3-eb7956ee1f3f",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "className": "has-text-centered",
                                    "text": "Unit Request"
                                },
                                {
                                    "id": "09c7e540-ea50-11ea-b967-5baa6500e444",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "4648f090-ea50-11ea-b967-5baa6500e444",
                                            "type": "subView",
                                            "children": [],
                                            "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                            "name": "homeowner-unit-request-aircon-cleaning"
                                        },
                                        {
                                            "id": "4fa8fea0-ea50-11ea-b967-5baa6500e444",
                                            "type": "subView",
                                            "children": [],
                                            "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                            "name": "homeowner-unit-request-grease-trap-cleaning"
                                        },
                                        {
                                            "id": "53a42110-ea50-11ea-b967-5baa6500e444",
                                            "type": "subView",
                                            "children": [
                                                {
                                                    "id": "1c2a9a91-ea0d-11ea-b95d-1388b7bed108",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "1c2a9a93-ea0d-11ea-b95d-1388b7bed108",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "status",
                                                            "label": "status",
                                                            "dataField": "status"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ],
                                            "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                                            "name": "homeowner-unit-request-others"
                                        },
                                        {
                                            "id": "570d15a0-ea50-11ea-b967-5baa6500e444",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                            "name": "homeowner-unit-request-plans-documents"
                                        },
                                        {
                                            "id": "5c271590-ea50-11ea-b967-5baa6500e444",
                                            "type": "subView",
                                            "children": [],
                                            "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                            "name": "homeowner-unit-request-refund-renovation-bond"
                                        },
                                        {
                                            "id": "60d49810-ea50-11ea-b967-5baa6500e444",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                            "name": "homeowner-unit-request-service-request"
                                        },
                                        {
                                            "id": "696aed80-ea50-11ea-b967-5baa6500e444",
                                            "type": "subView",
                                            "children": [],
                                            "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                            "name": "homeowner-unit-request-vehicle-sticker"
                                        },
                                        {
                                            "id": "a3401d40-ea51-11ea-b2b9-6908f6182fd8",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ac059900-ea51-11ea-b2b9-6908f6182fd8",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                    "dataField": "unit",
                                                    "name": "unit",
                                                    "label": "Unit",
                                                    "required": false,
                                                    "disabled": false
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "unit-container"
                                        },
                                        {
                                            "id": "ba232f20-ea51-11ea-b2b9-6908f6182fd8",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "bdbecc70-ea51-11ea-b2b9-6908f6182fd8",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "type",
                                                    "label": "Type",
                                                    "dataField": "type",
                                                    "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                    "saveObject": true,
                                                    "required": false,
                                                    "disabled": false
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "type-container"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "component": "UnitRequest",
                                    "name": "homeowner-unit-request"
                                },
                                {
                                    "id": "1c2a9a97-ea0d-11ea-b95d-1388b7bed108",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "83cd7850-f0d6-11ea-9422-3905f8832c96",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "status",
                                            "label": "Status",
                                            "saveObject": true,
                                            "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"canceled\", \"label\": \"Canceled\"}]",
                                            "name": "status"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "8ca0afb0-f0d6-11ea-9422-3905f8832c96",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "94d5d250-f0d6-11ea-9422-3905f8832c96",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "notes",
                                            "label": "Notes",
                                            "name": "notes",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": ""
                                }
                            ]
                        },
                        {
                            "id": "1c254360-ea0d-11ea-b95d-1388b7bed108",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "unit-request-edit-page",
                    "route": "/unit-requests/:id",
                    "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
                },
                {
                    "id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                            "children": [
                                {
                                    "id": "1c3b3c60-ea0d-11ea-b95d-1388b7bed108",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "1c4156e0-ea0d-11ea-b95d-1388b7bed108",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "c310a650-01ec-11eb-81b5-f15d97fd1729",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "createdAt",
                                            "label": "Date Submitted",
                                            "date": true
                                        },
                                        {
                                            "id": "4d284ab0-f797-11ea-a0fb-935a3c1bc52c",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "unit",
                                            "label": "Unit",
                                            "name": "unit"
                                        },
                                        {
                                            "id": "55bbb9f0-f797-11ea-a0fb-935a3c1bc52c",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "type",
                                            "name": "type",
                                            "label": "Type"
                                        },
                                        {
                                            "id": "5eb516f0-f797-11ea-a0fb-935a3c1bc52c",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "status",
                                            "name": "status",
                                            "text": "",
                                            "label": "Status"
                                        },
                                        {
                                            "id": "68a270e0-f797-11ea-a0fb-935a3c1bc52c",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "notes",
                                            "dataField": "notes",
                                            "label": "Notes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "path": "/unit-requests/detail"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "unit-request-list-page",
                    "route": "/unit-requests-list",
                    "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
                },
                {
                    "id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                            "children": [
                                {
                                    "id": "bb226d00-f0af-11ea-a61b-49eea0a1bb78",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Work Permit",
                                    "_sortIndex": -1,
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "47bfd670-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "47bfd672-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "unit",
                                            "label": "Unit",
                                            "dataField": "unit",
                                            "saveObject": true,
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "47bfd673-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "708a9ee0-05e3-11eb-8eea-bf73dbff533a",
                                            "type": "inputSelect",
                                            "children": [],
                                            "saveObject": true,
                                            "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                            "label": "Nature of Work",
                                            "dataField": "natureOfWork"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "47bfd676-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "47bfd678-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "contractor",
                                            "label": "Contractor",
                                            "dataField": "contractor",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "47bfd679-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "47bfd67b-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "personInCharge",
                                            "label": "Person In Charge",
                                            "dataField": "personInCharge",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "47bfd67c-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "47bffd81-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "contactPersonInCharge",
                                            "label": "Contact Number of Person-in-Charge",
                                            "dataField": "contactPersonInCharge",
                                            "required": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40
                                },
                                {
                                    "id": "47bffd82-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e7ec4310-e01f-11ea-affb-21f86f398b19",
                                            "type": "inputList",
                                            "children": [],
                                            "dataField": "workers",
                                            "label": "Workers",
                                            "name": "workers",
                                            "placeholder": "Worker"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 50
                                },
                                {
                                    "id": "47bffd85-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "47bffd87-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "description",
                                            "label": "Description",
                                            "dataField": "description",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 60
                                },
                                {
                                    "id": "47bffd88-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f46db560-e01f-11ea-affb-21f86f398b19",
                                            "type": "inputList",
                                            "children": [],
                                            "dataField": "materials",
                                            "name": "materials",
                                            "label": "Materials"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 80
                                },
                                {
                                    "id": "47bffd8b-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "85e80220-ef9d-11ea-82b2-a7421559c7b2",
                                            "type": "inputList",
                                            "children": [],
                                            "name": "",
                                            "label": "Tools",
                                            "dataField": "tools",
                                            "placeholder": "Tool"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 90
                                },
                                {
                                    "id": "47bffd8e-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "47bffd90-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "startDate",
                                            "label": "Start Date",
                                            "dataField": "startDate",
                                            "format": ""
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 100
                                },
                                {
                                    "id": "47bffd91-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "47bffd93-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "endDate",
                                            "label": "End Date",
                                            "dataField": "endDate",
                                            "format": ""
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 110
                                },
                                {
                                    "id": "47bffd94-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "47bffd96-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "plans",
                                            "label": "Plans",
                                            "dataField": "plans",
                                            "basePath": "work-permits"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 120
                                },
                                {
                                    "id": "f039f7a0-f795-11ea-8826-7fd8e10f31a2",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "07cef460-f796-11ea-8826-7fd8e10f31a2",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "status",
                                            "label": "Status",
                                            "dataField": "status",
                                            "saveObject": true,
                                            "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "8bdb91a0-e828-11ea-99d9-7b0e0405473d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "8f530520-e828-11ea-99d9-7b0e0405473d",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "notes",
                                            "dataField": "notes",
                                            "label": "Notes",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 130
                                }
                            ]
                        },
                        {
                            "id": "47b79910-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "work-permit-edit-page",
                    "route": "/work-permits/:id",
                    "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                    "_sortIndex": 730
                },
                {
                    "id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                            "children": [
                                {
                                    "id": "47ce7c70-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "component": "Searchbar"
                                },
                                {
                                    "id": "47d421c0-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "382ac9d0-01ec-11eb-81b5-f15d97fd1729",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "createdAt",
                                            "label": "Date Submitted",
                                            "date": true
                                        },
                                        {
                                            "id": "47d9c710-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "unit",
                                            "label": "Unit",
                                            "dataField": "unit"
                                        },
                                        {
                                            "id": "47d9c712-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "natureOfWork",
                                            "label": "Nature of Work",
                                            "dataField": "natureOfWork"
                                        },
                                        {
                                            "id": "47d9c714-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "contractor",
                                            "label": "Contractor",
                                            "dataField": "contractor"
                                        },
                                        {
                                            "id": "cb309910-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "personInCharge",
                                            "label": "Person In Charge"
                                        },
                                        {
                                            "id": "d9bb9700-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "contactPersonInCharge",
                                            "name": "",
                                            "label": "Contact Number of Person-in-Charge"
                                        },
                                        {
                                            "id": "e4db2f60-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "dataField": "startDate",
                                            "label": "Start Date",
                                            "date": true
                                        },
                                        {
                                            "id": "ef9aa660-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "text": "",
                                            "label": "End Date",
                                            "dataField": "endDate",
                                            "date": true
                                        },
                                        {
                                            "id": "f83ae6e0-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Status",
                                            "dataField": "status"
                                        },
                                        {
                                            "id": "fdb07450-f06e-11ea-9272-7b40a4812ca5",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "label": "Notes",
                                            "dataField": "notes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "path": "/work-permits/detail"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "work-permit-list-page",
                    "route": "/work-permits-list",
                    "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                    "_sortIndex": 740
                },
                {
                    "id": "3acc2620-e865-11ea-9def-530e14f0f646",
                    "type": "view",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                            "children": [
                                {
                                    "id": "3ad3ee50-e865-11ea-9def-530e14f0f646",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "3ad7e5f0-e865-11ea-9def-530e14f0f646",
                                            "type": "column",
                                            "children": [
                                                {
                                                    "id": "501d2fe0-e871-11ea-808a-85e6d8103902",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "item",
                                                    "label": "Item",
                                                    "name": "item"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "item",
                                            "label": "Item",
                                            "dataField": "item"
                                        },
                                        {
                                            "id": "3ad7e5f2-e865-11ea-9def-530e14f0f646",
                                            "type": "column",
                                            "children": [
                                                {
                                                    "id": "5279e490-e871-11ea-808a-85e6d8103902",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "description",
                                                    "label": "Description",
                                                    "name": "description"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "description",
                                            "label": "Description",
                                            "dataField": "description"
                                        },
                                        {
                                            "id": "3ad7e5f4-e865-11ea-9def-530e14f0f646",
                                            "type": "column",
                                            "children": [
                                                {
                                                    "id": "56685cd0-e871-11ea-808a-85e6d8103902",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "amount",
                                                    "label": "Amount"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "amount",
                                            "label": "Amount",
                                            "dataField": "amount"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "component": "",
                                    "command": "",
                                    "path": "/billing-items"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "billing-item-list-page",
                    "route": "/billing-items-list",
                    "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
                },
                {
                    "type": "view",
                    "name": "commandbar",
                    "orientation": "horizontal",
                    "children": [
                        {
                            "id": "07107b70-1226-11eb-8323-55a37c955b67",
                            "type": "container",
                            "children": [
                                {
                                    "name": "save",
                                    "type": "button",
                                    "id": "e7db5105-8c4a-11ea-9466-6f383d5eb483",
                                    "command": "save"
                                },
                                {
                                    "name": "delete",
                                    "type": "button",
                                    "id": "e7db5106-8c4a-11ea-9466-6f383d5eb483",
                                    "command": "delete"
                                }
                            ],
                            "orientation": "vertical",
                            "component": "ActionBar"
                        }
                    ],
                    "id": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                    "active": false,
                    "_sortIndex": 560
                },
                {
                    "id": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                    "type": "view",
                    "children": [
                        {
                            "id": "7ee9d700-8ef4-11ea-9b90-bfc3ee1ce33c",
                            "type": "menu",
                            "children": [
                                {
                                    "id": "3cf81350-9130-11ea-af3e-29e246413619",
                                    "type": "menuItem",
                                    "children": [],
                                    "_sortIndex": 0,
                                    "path": "/app",
                                    "label": "Main Menu"
                                },
                                {
                                    "id": "e86c29e0-912d-11ea-82bc-71c30f9d69df",
                                    "type": "menuItem",
                                    "children": [
                                        {
                                            "id": "e66c2680-05e1-11eb-88d0-f3dc124fad2d",
                                            "type": "menuItem",
                                            "children": [],
                                            "label": "Archives"
                                        }
                                    ],
                                    "path": "/pass-visitors-list",
                                    "label": "Visitor / Tenant Passes",
                                    "_sortIndex": 10,
                                    "access": "admin,pmg,pmg-ops,pmg-security"
                                },
                                {
                                    "id": "e6566940-912d-11ea-82bc-71c30f9d69df",
                                    "type": "menuItem",
                                    "children": [
                                        {
                                            "id": "f46e6c70-05e1-11eb-88d0-f3dc124fad2d",
                                            "type": "menuItem",
                                            "children": [],
                                            "label": "Archives"
                                        }
                                    ],
                                    "path": "/pass-deliveries-list",
                                    "label": "Delivery / Pullout Passes",
                                    "_sortIndex": 20,
                                    "access": "admin,pmg,pmg-ops,pmg-security"
                                },
                                {
                                    "id": "875d7be0-dd59-11ea-878d-279a484325f4",
                                    "type": "menuItem",
                                    "children": [
                                        {
                                            "id": "f79b6970-05e1-11eb-88d0-f3dc124fad2d",
                                            "type": "menuItem",
                                            "children": [],
                                            "label": "Archives"
                                        }
                                    ],
                                    "label": "Work Permits",
                                    "path": "/work-permits-list",
                                    "_sortIndex": 30,
                                    "access": "admin,pmg,pmg-ops"
                                },
                                {
                                    "id": "9c3e1dc0-914c-11ea-b5d1-292f71dadca1",
                                    "type": "menuItem",
                                    "children": [
                                        {
                                            "id": "fb1044e0-05e1-11eb-88d0-f3dc124fad2d",
                                            "type": "menuItem",
                                            "children": [],
                                            "label": "Archives"
                                        }
                                    ],
                                    "path": "/unit-requests-list",
                                    "label": "Unit Requests",
                                    "_sortIndex": 40,
                                    "access": "admin,pmg,pmg-ops,pmg-security"
                                },
                                {
                                    "id": "1854b570-914f-11ea-b2e4-0783314da7b1",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/comments-suggestions-list",
                                    "label": "Comments & Suggestions",
                                    "_sortIndex": 50,
                                    "component": "",
                                    "access": "admin,pmg,pmg-ops"
                                },
                                {
                                    "id": "d72baba0-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "menuItem",
                                    "children": [
                                        {
                                            "id": "002cdce0-05e2-11eb-88d0-f3dc124fad2d",
                                            "type": "menuItem",
                                            "children": [],
                                            "label": "Archives"
                                        }
                                    ],
                                    "path": "/message-boards-list",
                                    "label": "Message Board",
                                    "_sortIndex": 60,
                                    "access": "admin,pmg,pmg-ops"
                                },
                                {
                                    "id": "e3379d10-912d-11ea-82bc-71c30f9d69df",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/notifications-list",
                                    "label": "Notifications",
                                    "_sortIndex": 70,
                                    "access": "admin,pmg,pmg-ops"
                                },
                                {
                                    "id": "7abd8be0-9138-11ea-bd78-399a56c40918",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/billings-list",
                                    "label": "Billings",
                                    "_sortIndex": 80,
                                    "access": "accounting"
                                },
                                {
                                    "id": "68e00f40-f65c-11ea-bcea-e53105b58e3f",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/payments-list",
                                    "label": "Payments",
                                    "access": "accounting"
                                },
                                {
                                    "id": "e1041b40-912d-11ea-82bc-71c30f9d69df",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/homeowners-list",
                                    "label": "Homeowners",
                                    "_sortIndex": 90,
                                    "access": "accounting"
                                },
                                {
                                    "id": "7bde5e60-dd59-11ea-878d-279a484325f4",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Units",
                                    "path": "/units-list",
                                    "_sortIndex": 100,
                                    "access": "accounting"
                                },
                                {
                                    "id": "d8c9a170-912d-11ea-82bc-71c30f9d69df",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/departments-list",
                                    "label": "Departments",
                                    "_sortIndex": 110
                                },
                                {
                                    "id": "df5755a0-912d-11ea-82bc-71c30f9d69df",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/employees-list",
                                    "label": "Employees",
                                    "_sortIndex": 120
                                },
                                {
                                    "id": "9ebd1610-e7f4-11ea-b892-0f30d25d09fd",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/terms-conditions-list",
                                    "label": "Terms and Conditions",
                                    "_sortIndex": 130,
                                    "access": "pmg"
                                },
                                {
                                    "id": "fbb2fb30-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/activityLogs-list",
                                    "label": "Activity Log",
                                    "_sortIndex": 151,
                                    "access": "admin"
                                },
                                {
                                    "id": "fef31bf0-dfa3-11ea-a94d-e5d6a6ca6c3b",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/dashboard-login",
                                    "label": "Logout",
                                    "_sortIndex": 160
                                }
                            ],
                            "name": "",
                            "className": "dashboard-sidebar"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "dashboard-sidebar",
                    "active": false,
                    "_sortIndex": 570
                },
                {
                    "id": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf",
                    "type": "view",
                    "children": [
                        {
                            "id": "60fee2a0-dfab-11ea-a2d5-af9e56bb6ecf",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "name": "Welcome",
                            "text": "Welcome"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "dashboard-welcome"
                },
                {
                    "id": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                    "type": "view",
                    "children": [
                        {
                            "id": "24e082d0-8ef0-11ea-981d-018b5a72c307",
                            "type": "menu",
                            "children": [
                                {
                                    "id": "26cac6a0-8ef0-11ea-981d-018b5a72c307",
                                    "type": "menuItem",
                                    "children": [],
                                    "name": "home",
                                    "_sortIndex": 0,
                                    "label": "Main Menu",
                                    "path": "/app"
                                },
                                {
                                    "id": "13a38940-912a-11ea-a5e9-ff76f0b9af84",
                                    "type": "menuItem",
                                    "children": [],
                                    "_sortIndex": 10,
                                    "name": "account",
                                    "label": "My Account",
                                    "path": "/account"
                                },
                                {
                                    "id": "8be32c20-060b-11eb-b5a2-dd96829f3308",
                                    "type": "menuItem",
                                    "children": [],
                                    "label": "Notifications",
                                    "path": "/notifications"
                                },
                                {
                                    "id": "152c2510-912a-11ea-a5e9-ff76f0b9af84",
                                    "type": "menuItem",
                                    "children": [],
                                    "_sortIndex": 20,
                                    "name": "service",
                                    "label": "Unit Request",
                                    "path": "/homeowner-unit-request/0"
                                },
                                {
                                    "id": "17a5feb0-912a-11ea-a5e9-ff76f0b9af84",
                                    "type": "menuItem",
                                    "children": [],
                                    "_sortIndex": 40,
                                    "label": "Visitor / Tenant Passes",
                                    "name": "visitor",
                                    "path": "/create-visitor-pass/0"
                                },
                                {
                                    "id": "19b110f0-912a-11ea-a5e9-ff76f0b9af84",
                                    "type": "menuItem",
                                    "children": [],
                                    "_sortIndex": 50,
                                    "name": "delivery",
                                    "label": "Delivery / Pull-out Pass",
                                    "path": "/create-delivery-pass/0"
                                },
                                {
                                    "id": "58256b20-c92d-11ea-8660-8b92cdcd0efe",
                                    "type": "menuItem",
                                    "children": [],
                                    "path": "/message-board",
                                    "label": "Message Board",
                                    "_sortIndex": 70
                                },
                                {
                                    "id": "166eb730-912a-11ea-a5e9-ff76f0b9af84",
                                    "type": "menuItem",
                                    "children": [],
                                    "_sortIndex": 30,
                                    "label": "Comments and Suggestions",
                                    "name": "complaint",
                                    "path": "/homeowner-comments-suggestions/0"
                                },
                                {
                                    "id": "5968bca0-d9ac-11ea-b236-955d418fe434",
                                    "type": "menuItem",
                                    "children": [],
                                    "name": "work-permit",
                                    "path": "/homeowner-work-permit/0",
                                    "label": "Work Permit"
                                },
                                {
                                    "id": "295939b0-8ef0-11ea-981d-018b5a72c307",
                                    "type": "menuItem",
                                    "children": [],
                                    "name": "logout",
                                    "_sortIndex": 71,
                                    "label": "Logout",
                                    "path": "/homeowners-login"
                                }
                            ],
                            "className": "homeowner-sidebar"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-sidebar",
                    "active": false,
                    "_sortIndex": 580
                },
                {
                    "id": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                    "type": "view",
                    "children": [
                        {
                            "id": "d3e39f50-d9a6-11ea-891d-3722b00ad4e7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d5efea10-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.units",
                                    "label": "No. of Units",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "db6aa7a0-d9a6-11ea-891d-3722b00ad4e7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e1bfdbe0-edcc-11ea-abfa-8f89576fb378",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "meta.schedule",
                                    "label": "Preferred Schedule",
                                    "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "77226cd0-e7f5-11ea-b892-0f30d25d09fd",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "unit-request-aircon-cleaning",
                            "component": "TermsAndConditions"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-request-aircon-cleaning",
                    "_sortIndex": 590
                },
                {
                    "id": "0c744880-d9a5-11ea-a304-319701533d32",
                    "type": "view",
                    "children": [
                        {
                            "id": "1c9ddb40-d9a5-11ea-a304-319701533d32",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fbbdc250-edcc-11ea-abfa-8f89576fb378",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "meta.schedule",
                                    "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                    "label": "Preferred Schedule",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "622a6210-e7f5-11ea-b892-0f30d25d09fd",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "unit-request-grease-trap-cleaning",
                            "component": "TermsAndConditions"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-request-grease-trap-cleaning",
                    "_sortIndex": 600
                },
                {
                    "id": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                    "type": "view",
                    "children": [
                        {
                            "id": "fe4688f0-d9a8-11ea-9331-090047662dd2",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ff497a00-d9a8-11ea-9331-090047662dd2",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "name": "meta.details",
                                    "label": "Please Specify",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-request-others",
                    "_sortIndex": 610
                },
                {
                    "id": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                    "type": "view",
                    "children": [
                        {
                            "id": "e85ead40-c910-11ea-9dd0-6723feee229e",
                            "type": "container",
                            "children": [
                                {
                                    "id": "076a4bb0-c91e-11ea-831c-99fd7b91f646",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "meta.plans",
                                    "label": "Plans Required",
                                    "options": "[{ \"value\": \"standard\", \"label\": \"Standard Plan\"},{ \"value\": \"as-built\", \"label\": \"As-built Plan\"}]",
                                    "className": "bite-select",
                                    "saveObject": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "homeowner-service-request-repairs",
                            "className": "bite-select"
                        },
                        {
                            "id": "112746d0-c91e-11ea-831c-99fd7b91f646",
                            "type": "container",
                            "children": [
                                {
                                    "id": "44b19ca0-d916-11ea-9ea3-7184c3941d0d",
                                    "type": "inputSelect",
                                    "children": [],
                                    "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                    "saveObject": true,
                                    "name": "meta.paper-size",
                                    "label": "Paper Size",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "UnitRequestPlansDocuments"
                        },
                        {
                            "id": "97e9eee0-d916-11ea-9ea3-7184c3941d0d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9a6e28c0-d916-11ea-9ea3-7184c3941d0d",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "meta.type",
                                    "label": "Type of Plan",
                                    "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing\", \"label\": \"Plumbing\"},{ \"value\": \"electrical-plan\", \"label\": \"Electrical Plan\"}]",
                                    "saveObject": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e91d7600-e7f4-11ea-b892-0f30d25d09fd",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "unit-request-plans-documents",
                            "component": "TermsAndConditions"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-request-plans-documents",
                    "_sortIndex": 620
                },
                {
                    "id": "063f7330-c911-11ea-9dd0-6723feee229e",
                    "type": "view",
                    "children": [
                        {
                            "id": "1cea7cc0-c91f-11ea-b910-c30a77ee18ba",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b0a9a300-ef9e-11ea-82b2-a7421559c7b2",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "meta.inspection-date",
                                    "label": "Preferred Inspection Date",
                                    "description": "Monday to Friday, except Holidays",
                                    "required": false,
                                    "disabled": false
                                }
                            ],
                            "orientation": "horizontal",
                            "className": ""
                        },
                        {
                            "id": "b9eb6b30-d917-11ea-9ea3-7184c3941d0d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d8830d80-ef9e-11ea-82b2-a7421559c7b2",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.additional-requests",
                                    "multiline": true,
                                    "label": "Additional Requests",
                                    "disabled": false
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "21c2a6b0-e7f5-11ea-b892-0f30d25d09fd",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "unit-request-refund-renovation-fund",
                            "component": "TermsAndConditions"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-request-refund-renovation-bond",
                    "_sortIndex": 630
                },
                {
                    "id": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                    "type": "view",
                    "children": [
                        {
                            "id": "743faf70-c90d-11ea-8c59-196c3ec4f839",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a9319f50-c911-11ea-98ab-e51558e3434e",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Type of Work",
                                    "name": "meta.type",
                                    "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                                    "className": "bite-select",
                                    "saveObject": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "bite-select"
                        },
                        {
                            "id": "c6681b80-c911-11ea-98ab-e51558e3434e",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c84dcb70-c911-11ea-98ab-e51558e3434e",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Work Details",
                                    "name": "meta.details",
                                    "multiline": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "cf0bde70-c911-11ea-98ab-e51558e3434e",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ccce1d40-edcd-11ea-993f-a977bcf36a2d",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "meta.schedule",
                                    "label": "Preferred Inspection/Work Schedule",
                                    "description": " Monday to Friday only. Except Holidays",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "bite-select"
                        },
                        {
                            "id": "996b7430-e65b-11ea-a30a-a9b10ee3b8be",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "component": "TermsAndConditions",
                            "name": "unit-request-service-request"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-request-service-request",
                    "_sortIndex": 640
                },
                {
                    "id": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                    "type": "view",
                    "children": [
                        {
                            "id": "b33b28f0-d919-11ea-b3eb-d9cf4159b042",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b80cd540-d919-11ea-b3eb-d9cf4159b042",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "meta.make",
                                    "label": "Make",
                                    "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                                    "saveObject": true,
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c394f370-d919-11ea-b3eb-d9cf4159b042",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c6827670-d919-11ea-b3eb-d9cf4159b042",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.model",
                                    "label": "Model",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "ad71c600-d9a4-11ea-a304-319701533d32",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b1b90ca0-d9a4-11ea-a304-319701533d32",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "meta.color",
                                    "label": "Color",
                                    "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                                    "saveObject": true,
                                    "required": false,
                                    "disabled": false
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c744cbe0-d9a4-11ea-a304-319701533d32",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cfb34e00-d9a4-11ea-a304-319701533d32",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.plate-number",
                                    "label": "Plate Number",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "ddd18060-d9a4-11ea-a304-319701533d32",
                            "type": "container",
                            "children": [
                                {
                                    "id": "deacec40-d9a4-11ea-a304-319701533d32",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "meta.OR",
                                    "description": "",
                                    "label": "Upload O.R. / C.R."
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3fd52b00-e7f5-11ea-b892-0f30d25d09fd",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "unit-request-vehicle-sticker",
                            "component": "TermsAndConditions"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-request-vehicle-sticker",
                    "_sortIndex": 650
                },
                {
                    "id": "b8fe9280-c6f5-11ea-b415-c195f50f114e",
                    "type": "view",
                    "children": [
                        {
                            "id": "aca19b50-c6f9-11ea-a851-fde2cfff9ce9",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ae765b50-c6f9-11ea-a851-fde2cfff9ce9",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Select Unit"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "columns is-centered pb-1"
                        },
                        {
                            "id": "d8bbe3d0-c6f9-11ea-a851-fde2cfff9ce9",
                            "type": "container",
                            "children": [
                                {
                                    "id": "dde9e0f0-c6f9-11ea-a851-fde2cfff9ce9",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "029256d0-c6fa-11ea-a851-fde2cfff9ce9",
                                            "type": "button",
                                            "children": [],
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 01</div>",
                                            "className": "app-menu-item",
                                            "path": "/welcome/01"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                },
                                {
                                    "id": "472397a0-c6fa-11ea-a851-fde2cfff9ce9",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "53985fc0-c6fa-11ea-a851-fde2cfff9ce9",
                                            "type": "button",
                                            "children": [],
                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 02</div>",
                                            "className": "app-menu-item",
                                            "path": "/welcome/02"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "app-menu-item-container"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "columns is-centered pt-2"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-selection",
                    "className": "",
                    "_sortIndex": 660
                },
                {
                    "id": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                    "type": "view",
                    "children": [
                        {
                            "id": "38c26740-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3d14aa60-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "inputList",
                                    "children": [],
                                    "name": "visitor",
                                    "label": "Name/s",
                                    "placeholder": "Tenant",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "49eb4190-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4b75d930-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5279ced0-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "698d6700-ef9d-11ea-82b2-a7421559c7b2",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "endDate",
                                    "label": "End Date",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "5ed590b0-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "603f80f0-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "visitorsId",
                                    "label": "Upload valid ID/ Passport (required)",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "6f845900-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "710098c0-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "meta.contract",
                                    "label": "Upload Contract of Lease (required)",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "3373c0d0-e816-11ea-b937-9954e083a5ee",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "visitor-pass-long-term",
                            "component": "TermsAndConditions"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-visitor-pass-long-term",
                    "_sortIndex": 670
                },
                {
                    "id": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                    "type": "view",
                    "children": [
                        {
                            "id": "a0ff15c0-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a2036660-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "inputList",
                                    "children": [],
                                    "name": "visitor",
                                    "label": "Name/s",
                                    "placeholder": "Tenant",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "bb50c180-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c70a7700-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "cdb2b810-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d233d360-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "endDate",
                                    "label": "End Date",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e0d391d0-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e4f28ff0-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "visitorsId",
                                    "label": "Upload Valid ID/ Passport",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "214ab490-e816-11ea-b937-9954e083a5ee",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "visitor-pass-short-term",
                            "component": "TermsAndConditions"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-visitor-pass-short-term",
                    "_sortIndex": 680
                },
                {
                    "id": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                    "type": "view",
                    "children": [
                        {
                            "id": "94ab5510-da1b-11ea-87e9-9de57275fc27",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9c1bc820-da1b-11ea-87e9-9de57275fc27",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.relationship",
                                    "label": "Relationship to Owner"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "b7ed0f00-da1b-11ea-87e9-9de57275fc27",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b9749960-da1b-11ea-87e9-9de57275fc27",
                                    "type": "inputList",
                                    "children": [],
                                    "name": "visitor",
                                    "description": "",
                                    "label": "Name/s",
                                    "placeholder": "Visitor",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "cb6d1fc0-da1b-11ea-87e9-9de57275fc27",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cebbae80-da1b-11ea-87e9-9de57275fc27",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "d92934f0-da1b-11ea-87e9-9de57275fc27",
                            "type": "container",
                            "children": [
                                {
                                    "id": "db4adc70-da1b-11ea-87e9-9de57275fc27",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "endDate",
                                    "label": "End Date",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e6edfcb0-da1b-11ea-87e9-9de57275fc27",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ed1bdda0-da1b-11ea-87e9-9de57275fc27",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "visitorsId",
                                    "label": "Upload Valid ID (optional)"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "fe8ad4d0-e815-11ea-b937-9954e083a5ee",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "name": "visitor-pass-visitor-pass",
                            "component": "TermsAndConditions"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-visitor-pass-visitor-pass",
                    "_sortIndex": 690
                },
                {
                    "id": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                    "type": "view",
                    "children": [
                        {
                            "id": "e7c4b510-e7fa-11ea-ba5e-d94241a9751c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "465ece40-e7fa-11ea-ba5e-d94241a9751c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "25f3f810-97d7-11ea-a9fa-07f95ad6f0c6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d37f2010-97da-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "button",
                                                    "children": [],
                                                    "path": "/account",
                                                    "name": "My Account",
                                                    "className": "app-menu-item",
                                                    "icon": "",
                                                    "text": "",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_account.svg\"><img src=\"./assets/images/icon_account.svg\" /></object>My Account</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "2ef5e090-97d7-11ea-a9fa-07f95ad6f0c6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "856e1b80-9148-11ea-a6b6-e7e287632ad5",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "",
                                                    "path": "/homeowner-unit-request/0",
                                                    "flex": "[object Object]",
                                                    "className": "app-menu-item",
                                                    "_sortIndex": 10,
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_unit_request.svg\"><img src=\"./assets/images/icon_unit_request.svg\" /></object>Unit Request</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "38053690-97d7-11ea-a9fa-07f95ad6f0c6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                    "type": "button",
                                                    "children": [
                                                        {
                                                            "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "text": "",
                                                                    "path": "/create-visitor-pass/0",
                                                                    "_sortIndex": 30,
                                                                    "className": "app-menu-item",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        }
                                                    ],
                                                    "text": "",
                                                    "path": "/create-visitor-pass/0",
                                                    "_sortIndex": 30,
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "8ac2b370-9148-11ea-a6b6-e7e287632ad5",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "",
                                                    "path": "/create-delivery-pass/0",
                                                    "_sortIndex": 40,
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_delivery.svg\"><img src=\"./assets/images/icon_delivery.svg\" /></object>Delivery Pass</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "2290a530-c921-11ea-9848-69dad3ad605b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "2b49ebf0-c921-11ea-9848-69dad3ad605b",
                                                    "type": "button",
                                                    "children": [],
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_board.svg\"><img src=\"./assets/images/icon_board.svg\" /></object>Message Board</div>",
                                                    "className": "app-menu-item",
                                                    "path": "/message-board"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "351a2490-97d7-11ea-a9fa-07f95ad6f0c6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "874e7440-9148-11ea-a6b6-e7e287632ad5",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "",
                                                    "path": "/homeowner-comments-suggestions/0",
                                                    "_sortIndex": 20,
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_complaint.svg\"><img src=\"./assets/images/icon_complaint.svg\" /></object>Comments and Suggestions</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "d7653ab0-e7f9-11ea-ba5e-d94241a9751c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "e1ab6300-e7f9-11ea-ba5e-d94241a9751c",
                                                    "type": "button",
                                                    "children": [],
                                                    "path": "/homeowner-work-permit/0",
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_work_permit.svg\"><img src=\"./assets/images/icon_work_permit.svg\" /></object>Work Permit</div>"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "5e4c0520-ea13-11ea-a21e-f569cc759e68",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "68b4a990-ea13-11ea-a21e-f569cc759e68",
                                                    "type": "button",
                                                    "children": [],
                                                    "className": "app-menu-item",
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_notifications.svg\"><img src=\"./assets/images/icon_notifications.svg\" /></object>Notifications</div>",
                                                    "path": "/notifications",
                                                    "disabled": false
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "columns is-centered app-menus-container"
                        },
                        {
                            "id": "d4795ff0-effd-11ea-963d-316e0da90aca",
                            "type": "container",
                            "children": [],
                            "orientation": "horizontal",
                            "component": "LoginNotice"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-welcome",
                    "active": false,
                    "className": "column is-centered",
                    "component": "",
                    "_sortIndex": 700
                },
                {
                    "id": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b",
                    "type": "view",
                    "children": [
                        {
                            "id": "bc93f270-102c-11eb-a185-f1e09385ea9b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "2b02be70-f0ad-11ea-a61b-49eea0a1bb78",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "38d84f10-f0ad-11ea-a61b-49eea0a1bb78",
                                            "type": "image",
                                            "children": [],
                                            "url": "./assets/images/myplv_icon.png",
                                            "className": "login-comp-logo"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "28528d60-dfa8-11ea-9800-138672de1aa2",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6fbd65f0-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "712f9390-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "77274950-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "header",
                                                            "children": [],
                                                            "size": "1",
                                                            "className": "h1 logn-header",
                                                            "text": "Log In"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "login-header"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "row"
                                        },
                                        {
                                            "id": "7d5f3c60-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "8a375a30-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "9e527270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "email",
                                                            "label": "Email",
                                                            "className": "form-control login-form-input",
                                                            "variant": "standard"
                                                        },
                                                        {
                                                            "id": "b3ea9850-dfa7-11ea-9800-138672de1aa2",
                                                            "type": "image",
                                                            "children": [],
                                                            "url": "./assets/images/icon_user.svg",
                                                            "className": "login-icons"
                                                        }
                                                    ],
                                                    "orientation": "vertical",
                                                    "className": "col"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "row mt-4"
                                        },
                                        {
                                            "id": "c896c270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "cbd9df80-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ce124350-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "password": true,
                                                            "name": "password",
                                                            "label": "Password",
                                                            "className": "form-control login-form-input",
                                                            "variant": "standard"
                                                        },
                                                        {
                                                            "id": "fcc3d880-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "0bdff2e0-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                                    "type": "paragraph",
                                                                    "children": [],
                                                                    "text": "[Forgot your password?](/#/forgot-password)"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "mt-3 forgot-password-container"
                                                        },
                                                        {
                                                            "id": "c9874780-dfa7-11ea-9800-138672de1aa2",
                                                            "type": "image",
                                                            "children": [],
                                                            "url": "./assets/images/icon_login_password.svg",
                                                            "className": "login-icons"
                                                        }
                                                    ],
                                                    "orientation": "vertical",
                                                    "className": "col"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "row mt-4"
                                        },
                                        {
                                            "id": "10d10e10-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "15b3aa50-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "17e09c70-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "button",
                                                            "children": [],
                                                            "command": "local_login",
                                                            "text": "Admin Login",
                                                            "fullWidth": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "col"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "row mt-4"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "component": "",
                                    "_sortIndex": 0
                                }
                            ],
                            "orientation": "vertical",
                            "name": "main",
                            "component": "Login"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "login-dashboard",
                    "component": ""
                },
                {
                    "id": "e1156440-93e9-11ea-99e4-a504a663bd48",
                    "type": "view",
                    "children": [
                        {
                            "id": "eb7f4134-93e9-11ea-99e4-a504a663bd48",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0e6c07e0-f0b1-11ea-9980-9d3390e2d189",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1a36ad50-f0b1-11ea-9980-9d3390e2d189",
                                            "type": "image",
                                            "children": [],
                                            "url": "./assets/images/myplv_icon.png",
                                            "className": "login-comp-logo"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": -1
                                },
                                {
                                    "nodeType": "DIV",
                                    "type": "container",
                                    "className": "row",
                                    "children": [
                                        {
                                            "nodeType": "DIV",
                                            "type": "container",
                                            "className": "col",
                                            "children": [
                                                {
                                                    "nodeType": "H1",
                                                    "type": "header",
                                                    "className": "h1",
                                                    "children": [],
                                                    "text": "Log In",
                                                    "id": "eb7f4137-93e9-11ea-99e4-a504a663bd48"
                                                }
                                            ],
                                            "id": "eb7f4136-93e9-11ea-99e4-a504a663bd48"
                                        }
                                    ],
                                    "id": "eb7f4135-93e9-11ea-99e4-a504a663bd48",
                                    "_sortIndex": 0
                                },
                                {
                                    "nodeType": "DIV",
                                    "type": "container",
                                    "className": "row mt-4",
                                    "children": [
                                        {
                                            "nodeType": "DIV",
                                            "type": "container",
                                            "className": "col",
                                            "children": [
                                                {
                                                    "nodeType": "INPUT",
                                                    "type": "inputText",
                                                    "className": "form-control login-form-input txt-homeowner-login",
                                                    "children": [],
                                                    "placeholder": "Email",
                                                    "id": "eb7f413a-93e9-11ea-99e4-a504a663bd48",
                                                    "name": "email",
                                                    "variant": "standard"
                                                },
                                                {
                                                    "id": "f6061850-ad8d-11ea-a81e-a3c623c48bc9",
                                                    "type": "image",
                                                    "children": [],
                                                    "url": "./assets/images/icon_user.svg",
                                                    "className": "login-icons"
                                                }
                                            ],
                                            "id": "eb7f4139-93e9-11ea-99e4-a504a663bd48"
                                        }
                                    ],
                                    "id": "eb7f4138-93e9-11ea-99e4-a504a663bd48",
                                    "_sortIndex": 10
                                },
                                {
                                    "nodeType": "DIV",
                                    "type": "container",
                                    "className": "row mt-3",
                                    "children": [
                                        {
                                            "nodeType": "DIV",
                                            "type": "container",
                                            "className": "col",
                                            "children": [
                                                {
                                                    "nodeType": "INPUT",
                                                    "type": "inputText",
                                                    "className": "form-control login-form-input txt-homeowner-login",
                                                    "children": [],
                                                    "placeholder": "Password",
                                                    "id": "eb7f413d-93e9-11ea-99e4-a504a663bd48",
                                                    "name": "password",
                                                    "password": true,
                                                    "variant": "standard"
                                                },
                                                {
                                                    "id": "3a4be650-ad90-11ea-a81e-a3c623c48bc9",
                                                    "type": "image",
                                                    "children": [],
                                                    "url": "./assets/images/icon_login_password.svg",
                                                    "className": "login-icons"
                                                },
                                                {
                                                    "id": "5c31f5f0-ad92-11ea-a2a7-ad35397c4d32",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "5f285290-ad92-11ea-a2a7-ad35397c4d32",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "text": "[Forgot your password?](/#/forgot-password)",
                                                            "_sortIndex": -1
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "mt-3 forgot-password-container",
                                                    "flex": "[object Object]"
                                                }
                                            ],
                                            "id": "eb7f413c-93e9-11ea-99e4-a504a663bd48"
                                        }
                                    ],
                                    "id": "eb7f413b-93e9-11ea-99e4-a504a663bd48",
                                    "_sortIndex": 20
                                },
                                {
                                    "nodeType": "DIV",
                                    "type": "container",
                                    "className": "row mt-4",
                                    "children": [
                                        {
                                            "nodeType": "DIV",
                                            "type": "container",
                                            "className": "col",
                                            "children": [
                                                {
                                                    "id": "9ceca3a0-db42-11ea-942a-259edbe0bbf2",
                                                    "type": "button",
                                                    "children": [],
                                                    "command": "portal_login",
                                                    "text": "Login",
                                                    "className": "btn-homeowner-login"
                                                }
                                            ],
                                            "id": "eb7f4140-93e9-11ea-99e4-a504a663bd48"
                                        }
                                    ],
                                    "id": "eb7f413f-93e9-11ea-99e4-a504a663bd48",
                                    "_sortIndex": 30
                                }
                            ],
                            "orientation": "vertical",
                            "nodeType": "DIV",
                            "className": "",
                            "component": "Login"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "login-homeowner",
                    "component": "",
                    "_sortIndex": 710
                },
                {
                    "id": "927fbf90-ef71-11ea-ae43-d3d081a35642",
                    "type": "view",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "9273b1a1-ef71-11ea-ae43-d3d081a35642",
                            "children": [
                                {
                                    "id": "92867650-ef71-11ea-ae43-d3d081a35642",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "92895c80-ef71-11ea-ae43-d3d081a35642",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "payment",
                                            "label": "Payment",
                                            "dataField": "payment"
                                        },
                                        {
                                            "id": "92895c82-ef71-11ea-ae43-d3d081a35642",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "item",
                                            "label": "Item",
                                            "dataField": "item"
                                        },
                                        {
                                            "id": "92895c86-ef71-11ea-ae43-d3d081a35642",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "amount",
                                            "label": "Amount",
                                            "dataField": "amount"
                                        },
                                        {
                                            "id": "92895c88-ef71-11ea-ae43-d3d081a35642",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "state",
                                            "label": "State",
                                            "dataField": "state"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "path": "#"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "payment-item-list-page",
                    "route": "/payment-items-list",
                    "dataModel": "24ee6e90-ef71-11ea-ae43-d3d081a35642"
                },
                {
                    "id": "debbf900-f0b1-11ea-99d0-d9f36ea6d179",
                    "type": "model",
                    "children": [
                        {
                            "id": "e8b7f8f0-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "field:date",
                            "children": [],
                            "dataType": "date",
                            "format": "mm/dd/yyyy",
                            "name": "dateAndTime"
                        },
                        {
                            "id": "efece9a0-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "user"
                        },
                        {
                            "id": "f2204460-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "item"
                        },
                        {
                            "id": "f3f04970-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "detailsOfItem"
                        },
                        {
                            "id": "f88c8de0-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "detailsOfActivity"
                        }
                    ],
                    "name": "activity-log"
                },
                {
                    "id": "fb9a9130-f0b1-11ea-99d0-d9f36ea6d179",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "fb9a9131-f0b1-11ea-99d0-d9f36ea6d179",
                            "children": [
                                {
                                    "id": "29333c50-f0b2-11ea-99d0-d9f36ea6d179",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Activity Log",
                                    "_sortIndex": -1,
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "fba65100-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "fba65102-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "dateAndTime",
                                            "label": "Date and Time",
                                            "dataField": "dateAndTime",
                                            "format": "mm/dd/yyyy"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "fba65103-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "fba65105-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "user",
                                            "label": "User",
                                            "dataField": "user"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "fba65106-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "fba65108-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "item",
                                            "label": "Item",
                                            "dataField": "item"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "fba65109-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "fba6510b-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "detailsOfItem",
                                            "label": "Details of Item",
                                            "dataField": "detailsOfItem",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "fba6510c-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "fba6510e-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "detailsOfActivity",
                                            "label": "Details of Activity",
                                            "dataField": "detailsOfActivity",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 40
                                }
                            ]
                        },
                        {
                            "id": "fba3e000-f0b1-11ea-99d0-d9f36ea6d179",
                            "type": "subView",
                            "children": [],
                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "activityLog-detail-page",
                    "route": "/activityLogs/:id",
                    "dataModel": "debbf900-f0b1-11ea-99d0-d9f36ea6d179"
                },
                {
                    "id": "fba93730-f0b1-11ea-99d0-d9f36ea6d179",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "fb9a9131-f0b1-11ea-99d0-d9f36ea6d179",
                            "children": [
                                {
                                    "id": "fbabcf40-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "subView",
                                    "children": [],
                                    "name": "searchbar",
                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                },
                                {
                                    "id": "fbadcb10-f0b1-11ea-99d0-d9f36ea6d179",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "fbb06320-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "dateAndTime",
                                            "label": "Date and Time",
                                            "dataField": "dateAndTime"
                                        },
                                        {
                                            "id": "fbb06322-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "user",
                                            "label": "User",
                                            "dataField": "user"
                                        },
                                        {
                                            "id": "fbb06324-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "item",
                                            "label": "Item",
                                            "dataField": "item"
                                        },
                                        {
                                            "id": "fbb06326-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "detailsOfItem",
                                            "label": "Details of Item",
                                            "dataField": "detailsOfItem"
                                        },
                                        {
                                            "id": "fbb06328-f0b1-11ea-99d0-d9f36ea6d179",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "detailsOfActivity",
                                            "label": "Details of Activity",
                                            "dataField": "detailsOfActivity"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "activityLog-list-page",
                    "route": "/activityLogs-list",
                    "dataModel": "debbf900-f0b1-11ea-99d0-d9f36ea6d179"
                },
                {
                    "id": "ffb5d7c0-f6f1-11ea-b61a-f5b3d409e561",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "ffb5d7c1-f6f1-11ea-b61a-f5b3d409e561",
                            "children": [
                                {
                                    "id": "1e6d3bd0-08ff-11eb-800a-9340eaf97180",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "200ce210-08ff-11eb-800a-9340eaf97180",
                                            "type": "button",
                                            "children": [],
                                            "text": "Back",
                                            "path": "/account/billings",
                                            "variant": "text",
                                            "startIcon": "muiArrowBack",
                                            "endIcon": ""
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "575cf5d0-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "dataField": "name",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "65d9ea00-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9bb06a50-f6f2-11ea-b61a-f5b3d409e561",
                                            "type": "textBlock",
                                            "children": [],
                                            "text": "Posting Date:",
                                            "className": "mr-1"
                                        },
                                        {
                                            "id": "a7492a50-f6f2-11ea-b61a-f5b3d409e561",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "postingDate"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "mx-1"
                                },
                                {
                                    "id": "abc0cfc0-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ace838c0-f6f2-11ea-b61a-f5b3d409e561",
                                            "type": "textBlock",
                                            "children": [],
                                            "text": "Due Date:",
                                            "className": "mr-1"
                                        },
                                        {
                                            "id": "bc4b4730-f6f2-11ea-b61a-f5b3d409e561",
                                            "type": "textBlock",
                                            "children": [],
                                            "dataField": "dueDate"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "mx-1"
                                },
                                {
                                    "id": "c25e4d20-f6f2-11ea-b61a-f5b3d409e561",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c8de1ea0-f6f2-11ea-b61a-f5b3d409e561",
                                            "type": "subView",
                                            "children": [],
                                            "view": "7c2f4560-f6f3-11ea-b61a-f5b3d409e561",
                                            "relation": "billing"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "my-2"
                                },
                                {
                                    "id": "5a87c890-f6f5-11ea-8ecc-5b6063b0b693",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "5b77a6d0-f6f5-11ea-8ecc-5b6063b0b693",
                                            "type": "subView",
                                            "children": [],
                                            "view": "c5bcf690-f6f4-11ea-8ecc-5b6063b0b693",
                                            "relation": "",
                                            "dataField": "name"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": "",
                                    "className": "my-2"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-billing-detail-page",
                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                    "route": "/account/homeowner-billings/:id"
                },
                {
                    "id": "7c2f4560-f6f3-11ea-b61a-f5b3d409e561",
                    "type": "view",
                    "children": [
                        {
                            "id": "9167db90-f6f3-11ea-b61a-f5b3d409e561",
                            "type": "table",
                            "children": [
                                {
                                    "id": "92b47fd0-f6f3-11ea-b61a-f5b3d409e561",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "label": "Item",
                                    "name": "item"
                                },
                                {
                                    "id": "83fbf7e0-060a-11eb-b075-710549412795",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "dataField": "description",
                                    "label": "Description"
                                },
                                {
                                    "id": "cbabc820-f6f3-11ea-b61a-f5b3d409e561",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "amount",
                                    "label": "Amount"
                                }
                            ],
                            "orientation": "horizontal",
                            "path": "#"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-billing-item-list-page",
                    "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
                },
                {
                    "id": "c5bcf690-f6f4-11ea-8ecc-5b6063b0b693",
                    "type": "view",
                    "children": [
                        {
                            "id": "d2ba76b0-f6f4-11ea-8ecc-5b6063b0b693",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d4cab910-f6f4-11ea-8ecc-5b6063b0b693",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Payments",
                                    "className": "mt-2 mb-1"
                                }
                            ],
                            "orientation": "vertical"
                        },
                        {
                            "id": "780eaa80-f6fc-11ea-805b-1f4689c2a5d9",
                            "type": "container",
                            "children": [
                                {
                                    "id": "94aa6d50-f6fc-11ea-805b-1f4689c2a5d9",
                                    "type": "textBlock",
                                    "children": [],
                                    "name": "postingDate"
                                },
                                {
                                    "id": "9a0cc0e0-f6fc-11ea-805b-1f4689c2a5d9",
                                    "type": "textBlock",
                                    "children": [],
                                    "name": "receipt"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "PaymentHomeownerView"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-payment-detail-page",
                    "dataModel": ""
                },
                {
                    "type": "view",
                    "name": "searchbar",
                    "orientation": "horizontal",
                    "component": "Searchbar",
                    "children": [
                        {
                            "name": "search",
                            "type": "inputText",
                            "id": "e7db5108-8c4a-11ea-9466-6f383d5eb483",
                            "placeholder": "Search"
                        },
                        {
                            "id": "e0073b50-8c64-11ea-b629-0161308cc31c",
                            "type": "button",
                            "children": [],
                            "command": "new",
                            "name": "",
                            "text": "New"
                        }
                    ],
                    "id": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                    "active": false,
                    "_sortIndex": 720
                },
                {
                    "id": "1237a590-f7a6-11ea-8efb-e99dbc19ac92",
                    "type": "page",
                    "children": [
                        {
                            "id": "38fa2fc0-08e7-11eb-a5df-110eadfed9ed",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3b5a8df0-08e7-11eb-a5df-110eadfed9ed",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "className": "has-text-centered",
                                    "text": "Pay Dues"
                                },
                                {
                                    "id": "66f6ccd0-08e7-11eb-a5df-110eadfed9ed",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6a11ef80-08e7-11eb-a5df-110eadfed9ed",
                                            "type": "button",
                                            "children": [],
                                            "text": "Payment History",
                                            "variant": "text",
                                            "path": "/account/pay-dues-history"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "justify-content-center pt-1 pb-1"
                                }
                            ],
                            "orientation": "vertical"
                        },
                        {
                            "id": "465b45c0-f7a6-11ea-8efb-e99dbc19ac92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "5738bf30-f7a6-11ea-8efb-e99dbc19ac92",
                                    "type": "button",
                                    "children": [],
                                    "disabled": true,
                                    "text": "Paymaya"
                                },
                                {
                                    "id": "68f0ba20-f7a6-11ea-8efb-e99dbc19ac92",
                                    "type": "button",
                                    "children": [],
                                    "text": "Credit Card",
                                    "disabled": true
                                },
                                {
                                    "id": "6b19b4a0-f7a6-11ea-8efb-e99dbc19ac92",
                                    "type": "button",
                                    "children": [],
                                    "text": "Deposit/Online Transfer",
                                    "path": "/account/pay-dues/deposit/0"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "main"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-pay-dues",
                    "route": "/account/pay-dues"
                },
                {
                    "id": "b0779440-f7a6-11ea-8efb-e99dbc19ac92",
                    "type": "page",
                    "children": [
                        {
                            "id": "ba2f3830-f7a6-11ea-8efb-e99dbc19ac92",
                            "type": "container",
                            "children": [
                                {
                                    "id": "23c42e40-f7a7-11ea-8efb-e99dbc19ac92",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "2ddf7600-f7a7-11ea-8efb-e99dbc19ac92",
                                            "type": "paragraph",
                                            "children": [],
                                            "text": "You can deposit payments directly or do bank transfer:",
                                            "className": "my-2"
                                        },
                                        {
                                            "id": "3dd72de0-f7a8-11ea-8efb-e99dbc19ac92",
                                            "type": "paragraph",
                                            "children": [],
                                            "text": "Account Name: Pacific Land Ventures & Property Development Inc."
                                        },
                                        {
                                            "id": "4c2b1640-f7a8-11ea-8efb-e99dbc19ac92",
                                            "type": "paragraph",
                                            "children": [],
                                            "text": "Account Number: 2860-0005-74"
                                        },
                                        {
                                            "id": "3064f2f0-f7a8-11ea-8efb-e99dbc19ac92",
                                            "type": "paragraph",
                                            "children": [],
                                            "text": "Bank: Bank of the Philippine Islands (BPI)"
                                        },
                                        {
                                            "id": "590752c0-f7a8-11ea-8efb-e99dbc19ac92",
                                            "type": "paragraph",
                                            "children": [],
                                            "text": "Branch: Cebu Taboan Branch"
                                        },
                                        {
                                            "id": "e7c265c0-fde0-11ea-b546-e584feed1730",
                                            "type": "paragraph",
                                            "children": [],
                                            "text": "BPI Swift Code: BOPIPHMM"
                                        },
                                        {
                                            "id": "67e66420-f7a8-11ea-8efb-e99dbc19ac92",
                                            "type": "paragraph",
                                            "children": [],
                                            "className": "my-2",
                                            "text": "Take a photo of the deposit slip or screen shot of the online/bank transfer confirmation and upload here."
                                        }
                                    ],
                                    "orientation": "vertical"
                                },
                                {
                                    "id": "579c4680-f7a7-11ea-8efb-e99dbc19ac92",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "bb6549d0-0370-11eb-9871-a7d3e8f638e3",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "amount",
                                            "label": "Amount",
                                            "name": "amount",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "pb-4"
                                },
                                {
                                    "id": "743c9d30-f7a7-11ea-8efb-e99dbc19ac92",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "5f0ce0a0-f7a7-11ea-8efb-e99dbc19ac92",
                                            "type": "inputMedia",
                                            "children": [],
                                            "label": "Proof of Payment",
                                            "dataField": "photo",
                                            "name": "photo",
                                            "required": true,
                                            "basePath": "online-payments"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "8241c950-f7a7-11ea-8efb-e99dbc19ac92",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "75ba3c80-f7a7-11ea-8efb-e99dbc19ac92",
                                            "type": "inputText",
                                            "children": [],
                                            "multiline": true,
                                            "label": "Particulars",
                                            "dataField": "particulars",
                                            "name": "particulars",
                                            "required": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "a0a524d0-0370-11eb-9871-a7d3e8f638e3",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a24f7970-0370-11eb-9871-a7d3e8f638e3",
                                            "type": "button",
                                            "children": [],
                                            "command": "save",
                                            "text": "Submit",
                                            "name": "save"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "main"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-pay-dues-deposit",
                    "route": "/account/pay-dues/deposit/:id",
                    "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3"
                },
                {
                    "id": "968362c0-fdf1-11ea-ba93-5145fd1d7baf",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "968362c1-fdf1-11ea-ba93-5145fd1d7baf",
                            "component": "LoginPortal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "login-portal-page",
                    "route": "/login-portal",
                    "component": "",
                    "layout": "full"
                },
                {
                    "id": "b06ea410-01ed-11eb-81b5-f15d97fd1729",
                    "type": "view",
                    "children": [
                        {
                            "id": "bb8a1dc0-01ed-11eb-81b5-f15d97fd1729",
                            "type": "inputText",
                            "children": [],
                            "label": "",
                            "placeholder": "Search",
                            "name": "search"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "searchbar-only",
                    "component": "Searchbar"
                },
                {
                    "id": "0f34a160-0370-11eb-9871-a7d3e8f638e3",
                    "type": "model",
                    "children": [
                        {
                            "id": "1deb9150-0370-11eb-9871-a7d3e8f638e3",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "amount"
                        },
                        {
                            "id": "218ab110-0370-11eb-9871-a7d3e8f638e3",
                            "type": "field",
                            "children": [],
                            "dataType": "string",
                            "name": "particulars"
                        },
                        {
                            "id": "2bc65210-0370-11eb-9871-a7d3e8f638e3",
                            "type": "field:file",
                            "children": [],
                            "dataType": "file",
                            "collection": "file",
                            "via": "related",
                            "allowedTypes": [
                                "images",
                                "files",
                                "videos"
                            ],
                            "plugin": "upload",
                            "name": "photo"
                        },
                        {
                            "id": "f816f2e0-1025-11eb-b134-49e9ab8733f5",
                            "type": "field:text",
                            "children": [],
                            "dataType": "text",
                            "name": "mode"
                        }
                    ],
                    "name": "online-payment"
                },
                {
                    "id": "cc343590-0376-11eb-b9ea-3df39e640441",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "cc343591-0376-11eb-b9ea-3df39e640441",
                            "children": [
                                {
                                    "id": "f1143630-0376-11eb-b9ea-3df39e640441",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f5df04b0-0376-11eb-b9ea-3df39e640441",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "className": "has-text-centered",
                                            "text": "Work Permits"
                                        }
                                    ],
                                    "orientation": "vertical"
                                },
                                {
                                    "id": "09753580-0377-11eb-b9ea-3df39e640441",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "0c79ea00-0377-11eb-b9ea-3df39e640441",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "0deab810-0377-11eb-b9ea-3df39e640441",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "unit",
                                                    "label": "Unit"
                                                },
                                                {
                                                    "id": "1ac0b300-0377-11eb-b9ea-3df39e640441",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "natureOfWork",
                                                    "label": "Nature of Work"
                                                },
                                                {
                                                    "id": "224941f0-0377-11eb-b9ea-3df39e640441",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "contractor",
                                                    "label": "Contractor"
                                                },
                                                {
                                                    "id": "294a5160-0377-11eb-b9ea-3df39e640441",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "personInCharge",
                                                    "label": "Person In Charge"
                                                },
                                                {
                                                    "id": "3457c150-0377-11eb-b9ea-3df39e640441",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "description",
                                                    "label": "Description"
                                                },
                                                {
                                                    "id": "415122c0-0377-11eb-b9ea-3df39e640441",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "startDate",
                                                    "label": "Start Date",
                                                    "date": true
                                                },
                                                {
                                                    "id": "4c33d930-0377-11eb-b9ea-3df39e640441",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "endDate",
                                                    "label": "End Date",
                                                    "date": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "path": "/homeowner-work-permit"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": ""
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-work-permits",
                    "route": "/work-permits",
                    "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1"
                },
                {
                    "id": "7d7c1ed0-0377-11eb-bfdc-3b5d643d4962",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "7d7c1ed1-0377-11eb-bfdc-3b5d643d4962",
                            "children": [
                                {
                                    "id": "963af590-0377-11eb-bfdc-3b5d643d4962",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9a9fd650-0377-11eb-bfdc-3b5d643d4962",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Unit Requests",
                                            "className": "has-text-centered"
                                        }
                                    ],
                                    "orientation": "vertical"
                                },
                                {
                                    "id": "a56085d0-0377-11eb-bfdc-3b5d643d4962",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a7a0b1d0-0377-11eb-bfdc-3b5d643d4962",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "aa7bbe40-0377-11eb-bfdc-3b5d643d4962",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "unit",
                                                    "label": "Unit"
                                                },
                                                {
                                                    "id": "b592f230-0377-11eb-bfdc-3b5d643d4962",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "type",
                                                    "label": "Type"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "path": "/homeowner-unit-request"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-unit-requests",
                    "route": "/unit-requests",
                    "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
                },
                {
                    "id": "b6957d90-08e7-11eb-a5df-110eadfed9ed",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "b6957d91-08e7-11eb-a5df-110eadfed9ed",
                            "children": [
                                {
                                    "id": "e2bae9a0-08e7-11eb-a5df-110eadfed9ed",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "className": "has-text-centered",
                                    "text": "Payment History"
                                },
                                {
                                    "id": "eeb04890-08e7-11eb-a5df-110eadfed9ed",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f17cd610-08e7-11eb-a5df-110eadfed9ed",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "10f41a80-08e8-11eb-a5df-110eadfed9ed",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "label": "Date",
                                                    "name": "createdAt",
                                                    "date": true,
                                                    "format": "MM/dd/yyyy hh:mm a"
                                                },
                                                {
                                                    "id": "1a701500-08e8-11eb-a5df-110eadfed9ed",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "label": "Particulars",
                                                    "dataField": "particulars"
                                                },
                                                {
                                                    "id": "2293d280-08e8-11eb-a5df-110eadfed9ed",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "amount",
                                                    "label": "Amount"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "path": "#"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "orientation": "vertical"
                        }
                    ],
                    "orientation": "vertical",
                    "route": "/account/pay-dues-history",
                    "name": "homeowner-pay-dues-history",
                    "dataModel": "0f34a160-0370-11eb-9871-a7d3e8f638e3"
                },
                {
                    "id": "1e5949d0-0ffe-11eb-a75f-a764954330b6",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "1e5949d1-0ffe-11eb-a75f-a764954330b6",
                            "children": [
                                {
                                    "id": "2c223040-0ffe-11eb-a75f-a764954330b6",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Forgot Password"
                                },
                                {
                                    "id": "334baf40-0ffe-11eb-a75f-a764954330b6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "352ddcc0-0ffe-11eb-a75f-a764954330b6",
                                            "type": "inputText",
                                            "children": [],
                                            "label": "Email",
                                            "name": "email"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "492148c0-0ffe-11eb-a75f-a764954330b6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "4ad78440-0ffe-11eb-a75f-a764954330b6",
                                            "type": "button",
                                            "children": [],
                                            "text": "Submit",
                                            "command": "forgot_password"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "component": "ForgotPassword"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "forgot-password",
                    "route": "/forgot-password",
                    "layout": "full"
                },
                {
                    "id": "7589ccc0-1008-11eb-9f4b-55fb426750d7",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "7589ccc1-1008-11eb-9f4b-55fb426750d7",
                            "children": [
                                {
                                    "id": "ac994240-1008-11eb-9f4b-55fb426750d7",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Reset Password"
                                },
                                {
                                    "id": "b402fe90-1008-11eb-9f4b-55fb426750d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b5601d90-1008-11eb-9f4b-55fb426750d7",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "password",
                                            "label": "New Password"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c0a71410-1008-11eb-9f4b-55fb426750d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c24c3890-1008-11eb-9f4b-55fb426750d7",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "passwordConfirmation",
                                            "label": "Confirm New Password"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "e55ad490-1008-11eb-9f4b-55fb426750d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e6ae2f90-1008-11eb-9f4b-55fb426750d7",
                                            "type": "button",
                                            "children": [],
                                            "html": "Submit",
                                            "command": "reset_password"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "component": "ResetPassword"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "reset-password",
                    "route": "/reset-password",
                    "layout": "full"
                },
                {
                    "id": "c8274690-1221-11eb-a9d8-83db08ab78a0",
                    "type": "view",
                    "children": [
                        {
                            "id": "c9fe4920-1224-11eb-bc75-95cdd9dff5ce",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d0be57f0-1224-11eb-bc75-95cdd9dff5ce",
                                    "type": "button",
                                    "children": [],
                                    "name": "save",
                                    "command": "save",
                                    "text": "Submit"
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "ActionBar"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "homeowner-commandbar",
                    "component": "ActionBar"
                },
                {
                    "id": "27c30fa0-1603-11eb-8555-975f97eacdba",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "27c30fa1-1603-11eb-8555-975f97eacdba",
                            "children": [
                                {
                                    "id": "5a307450-1603-11eb-8555-975f97eacdba",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Visitor / Tenant Pass",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "6cd54040-1603-11eb-8555-975f97eacdba",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b3c70ae0-1646-11eb-b8c0-fd645d6bfd69",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "unit",
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "name": "unit",
                                            "label": "Unit",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "vertical"
                                },
                                {
                                    "id": "cf588fa0-1604-11eb-9903-d9f0b8dd94fe",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "0dae7c00-1647-11eb-91d3-e743baeee069",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "type",
                                            "label": "Type",
                                            "name": "type",
                                            "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                            "saveObject": true,
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "vertical"
                                },
                                {
                                    "id": "5f33d1a0-1648-11eb-91d3-e743baeee069",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6e6a78e0-1648-11eb-91d3-e743baeee069",
                                            "type": "subView",
                                            "children": [],
                                            "view": "5e6330a0-1647-11eb-91d3-e743baeee069",
                                            "name": "view-visitor-pass-visitor-pass"
                                        },
                                        {
                                            "id": "d3888570-164b-11eb-8554-b34543e4ea31",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-visitor-pass-short-term",
                                            "view": "69176030-164b-11eb-8554-b34543e4ea31"
                                        },
                                        {
                                            "id": "a62e4d70-164c-11eb-8554-b34543e4ea31",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-visitor-pass-long-term",
                                            "view": "2d3e3830-164c-11eb-8554-b34543e4ea31"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "view-visitor-pass",
                                    "component": "VisitorPass"
                                },
                                {
                                    "id": "f480fa90-166a-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f914da90-166a-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "status",
                                            "label": "Status",
                                            "dataField": "status",
                                            "disabled": true,
                                            "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "pmg-ops,pmg-security"
                                },
                                {
                                    "id": "264af350-166b-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "283cff50-166b-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputText",
                                            "children": [],
                                            "required": false,
                                            "disabled": true,
                                            "multiline": true,
                                            "name": "notes",
                                            "label": "Notes",
                                            "dataField": "notes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "pmg-ops,pmg-security"
                                },
                                {
                                    "id": "a9ec78d0-164a-11eb-8554-b34543e4ea31",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "beecc0f0-164a-11eb-8554-b34543e4ea31",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "status",
                                            "name": "status",
                                            "label": "Status",
                                            "disabled": false,
                                            "options": "[{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"disapproved\", \"label\": \"Disapproved\"},{\"value\":\"checked-in\", \"label\": \"Checked In\"},{\"value\":\"checked-out\", \"label\": \"Checked Out\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "admin,pmg"
                                },
                                {
                                    "id": "cd2ea7f0-164a-11eb-8554-b34543e4ea31",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d1844670-164a-11eb-8554-b34543e4ea31",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "notes",
                                            "label": "Notes",
                                            "dataField": "notes",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "admin,pmg"
                                },
                                {
                                    "id": "cccdf410-167b-11eb-83c3-eb7956ee1f3f",
                                    "type": "subView",
                                    "children": [],
                                    "access": "admin,pmg",
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "pass-visitor-detail-page",
                    "route": "/pass-visitors-list/detail/:id",
                    "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721"
                },
                {
                    "id": "5e6330a0-1647-11eb-91d3-e743baeee069",
                    "type": "view",
                    "children": [
                        {
                            "id": "820755e0-1647-11eb-91d3-e743baeee069",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a1419150-1647-11eb-91d3-e743baeee069",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.relationship",
                                    "disabled": true,
                                    "label": "Relationship to Owner"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "ee506f70-1647-11eb-91d3-e743baeee069",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f154fce0-1647-11eb-91d3-e743baeee069",
                                    "type": "inputList",
                                    "children": [],
                                    "label": "Name/s",
                                    "name": "visitor",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "088bd410-1648-11eb-91d3-e743baeee069",
                            "type": "container",
                            "children": [
                                {
                                    "id": "0dc19960-1648-11eb-91d3-e743baeee069",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "1f634d30-1648-11eb-91d3-e743baeee069",
                            "type": "container",
                            "children": [
                                {
                                    "id": "209c4260-1648-11eb-91d3-e743baeee069",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "endDate",
                                    "label": "End Date",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "27a38170-164a-11eb-9056-4178972142ab",
                            "type": "container",
                            "children": [
                                {
                                    "id": "29dfdce0-164a-11eb-9056-4178972142ab",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "visitorsId",
                                    "label": "Upload Valid ID (optional)",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-visitor-pass-visitor-pass"
                },
                {
                    "id": "69176030-164b-11eb-8554-b34543e4ea31",
                    "type": "view",
                    "children": [
                        {
                            "id": "6b7107a0-164b-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "867b2df0-164b-11eb-8554-b34543e4ea31",
                                    "type": "inputList",
                                    "children": [],
                                    "name": "visitor",
                                    "label": "Names/s",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "8fb78760-164b-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9a8f8f70-164b-11eb-8554-b34543e4ea31",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "a6236d70-164b-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "acacdbe0-164b-11eb-8554-b34543e4ea31",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "endDate",
                                    "label": "End Date",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": ""
                        },
                        {
                            "id": "bf03d5f0-164b-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c0586970-164b-11eb-8554-b34543e4ea31",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "visitorsId",
                                    "label": "Upload Valid ID/ Passport",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-visitor-pass-short-term"
                },
                {
                    "id": "2d3e3830-164c-11eb-8554-b34543e4ea31",
                    "type": "view",
                    "children": [
                        {
                            "id": "32066ea0-164c-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "3e658be0-164c-11eb-8554-b34543e4ea31",
                                    "type": "inputList",
                                    "children": [],
                                    "name": "visitor",
                                    "label": "Name/s",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "496fa070-164c-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4b2abdf0-164c-11eb-8554-b34543e4ea31",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "startDate",
                                    "label": "Start Date",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "57ced370-164c-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "58fdb680-164c-11eb-8554-b34543e4ea31",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "endDate",
                                    "label": "End Date",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "68265400-164c-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6eabf1e0-164c-11eb-8554-b34543e4ea31",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "visitorsId",
                                    "label": "Upload valid ID/ Passport (required)",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "79741b70-164c-11eb-8554-b34543e4ea31",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7e686af0-164c-11eb-8554-b34543e4ea31",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "meta.contract",
                                    "label": "Upload Contract of Lease (required)",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-visitor-pass-long-term"
                },
                {
                    "id": "fcc6c530-164d-11eb-bfd1-2d1eca45ef82",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "fcc6c531-164d-11eb-bfd1-2d1eca45ef82",
                            "children": [
                                {
                                    "id": "ffd3de20-164d-11eb-bfd1-2d1eca45ef82",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "className": "has-text-centered",
                                    "text": "Delivery / Pullout Pass"
                                },
                                {
                                    "id": "1a506a70-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "22ed0170-164e-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "unit",
                                            "label": "Unit",
                                            "disabled": true,
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "dataField": "unit"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "367b9120-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "4543e630-164e-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "type",
                                            "name": "type",
                                            "label": "Type",
                                            "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                            "saveObject": true,
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "58e6e840-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "5b4fd1f0-164e-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputDateTime",
                                            "children": [],
                                            "disabled": true,
                                            "name": "schedule",
                                            "label": "Schedule",
                                            "dataField": "schedule"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "6ad53570-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6d64b9f0-164e-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputList",
                                            "children": [],
                                            "name": "items",
                                            "dataField": "items",
                                            "disabled": true,
                                            "label": "Item(s) - Please enumerate including quantities."
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "8b160b70-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "8ded9570-164e-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "company",
                                            "label": "Company",
                                            "dataField": "company",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "be90bb40-166b-11eb-af06-b18a05fa97c7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c1974480-166b-11eb-af06-b18a05fa97c7",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "status",
                                            "label": "Status",
                                            "dataField": "status",
                                            "disabled": true,
                                            "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                            "access": ""
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "pmg-ops,pmg-security"
                                },
                                {
                                    "id": "da011d70-166b-11eb-af06-b18a05fa97c7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "dbe34af0-166b-11eb-af06-b18a05fa97c7",
                                            "type": "inputText",
                                            "children": [],
                                            "multiline": true,
                                            "name": "notes",
                                            "label": "Notes",
                                            "dataField": "notes",
                                            "disabled": true,
                                            "access": ""
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "pmg-ops,pmg-security"
                                },
                                {
                                    "id": "d79a35c0-164e-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e29ad470-164e-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "status",
                                            "label": "Status",
                                            "dataField": "status",
                                            "disabled": false,
                                            "access": "",
                                            "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\": \"approved\", \"label\": \"Approved\"},{\"value\": \"pending\", \"label\": \"Pending\"}, {\"value\": \"cancelled\", \"label\": \"Cancelled\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "",
                                    "dataField": "",
                                    "access": "admin,pmg"
                                },
                                {
                                    "id": "1cc8a0f0-164f-11eb-bfd1-2d1eca45ef82",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1e99de80-164f-11eb-bfd1-2d1eca45ef82",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "notes",
                                            "label": "Notes",
                                            "access": "",
                                            "disabled": false,
                                            "multiline": true,
                                            "dataField": "notes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "admin,pmg"
                                },
                                {
                                    "id": "d95077d0-167b-11eb-83c3-eb7956ee1f3f",
                                    "type": "subView",
                                    "children": [],
                                    "access": "admin,pmg",
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "pass-delivery-detail-page",
                    "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                    "route": "/pass-deliveries-list/detail/:id"
                },
                {
                    "id": "8aa9f4a0-1679-11eb-83c3-eb7956ee1f3f",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "8aa9f4a1-1679-11eb-83c3-eb7956ee1f3f",
                            "children": [
                                {
                                    "id": "a10782d0-1679-11eb-83c3-eb7956ee1f3f",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Work Permit",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "b8f28ed0-1679-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "bc6c4c40-1679-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "unit",
                                            "label": "Unit",
                                            "disabled": true,
                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "dataField": "unit"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": ""
                                },
                                {
                                    "id": "d78e8e70-1679-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "dcd45950-1679-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputSelect",
                                            "children": [],
                                            "label": "Nature of Work",
                                            "dataField": "natureOfWork",
                                            "disabled": true,
                                            "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "f18a03e0-1679-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "f8bf90d0-1679-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputText",
                                            "children": [],
                                            "label": "Contractor",
                                            "dataField": "contractor",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "18be06a0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1cd849d0-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputText",
                                            "children": [],
                                            "label": "Person In Charge",
                                            "disabled": true,
                                            "dataField": "personInCharge"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "2e6bf3e0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3073d1d0-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputText",
                                            "children": [],
                                            "disabled": true,
                                            "dataField": "contactPersonInCharge",
                                            "label": "Contact Number of Person-in-Charge"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "3ac14d20-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3f4aa5d0-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputList",
                                            "children": [],
                                            "dataField": "workers",
                                            "label": "Workers",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "4fc16e30-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "533a6850-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputText",
                                            "children": [],
                                            "multiline": true,
                                            "dataField": "description",
                                            "label": "Description",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "5f0a16d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "63b660d0-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputList",
                                            "children": [],
                                            "disabled": true,
                                            "label": "Materials",
                                            "dataField": "materials"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "6c94e2d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6f68c350-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputList",
                                            "children": [],
                                            "label": "Tools",
                                            "dataField": "tools",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "80193950-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "815f26d0-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputDate",
                                            "children": [],
                                            "disabled": true,
                                            "label": "Proposed Start Date ",
                                            "dataField": "startDate"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "93c0f650-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "958bf250-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputDate",
                                            "children": [],
                                            "label": "Target End Date ",
                                            "dataField": "endDate",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "9e8687d0-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a3ec5dd0-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputMedia",
                                            "children": [],
                                            "dataField": "plans",
                                            "disabled": true,
                                            "label": "Plans"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c271ef40-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c4962ed0-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputSelect",
                                            "children": [],
                                            "label": "Status",
                                            "dataField": "status",
                                            "disabled": true,
                                            "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "pmg-security,pmg-ops"
                                },
                                {
                                    "id": "de128930-167a-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ee1c10d0-167a-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputText",
                                            "children": [],
                                            "disabled": true,
                                            "multiline": true,
                                            "label": "Notes",
                                            "dataField": "notes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "dataField": "",
                                    "access": "pmg-security,pmg-ops"
                                },
                                {
                                    "id": "0c1cbad0-167b-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "0f314dd0-167b-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "status",
                                            "label": "Statsus",
                                            "saveObject": true,
                                            "options": "[{\"value\":\"done\", \"label\": \"Done\"},{\"value\":\"approved\", \"label\": \"Approved\"},{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"cancelled\", \"label\": \"Cancelled\"}]"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "admin,pmg"
                                },
                                {
                                    "id": "1d1424e0-167b-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "219bcfe0-167b-11eb-83c3-eb7956ee1f3f",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "notes",
                                            "multiline": true,
                                            "label": "Notes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "admin,pmg"
                                },
                                {
                                    "id": "ee8c4250-167b-11eb-83c3-eb7956ee1f3f",
                                    "type": "subView",
                                    "children": [],
                                    "access": "admin,pmg",
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                },
                                {
                                    "id": "f7789510-167d-11eb-83c3-eb7956ee1f3f",
                                    "type": "header",
                                    "children": [],
                                    "size": 2
                                },
                                {
                                    "id": "02c8da60-167e-11eb-83c3-eb7956ee1f3f",
                                    "type": "header",
                                    "children": [],
                                    "size": 2
                                },
                                {
                                    "id": "0b46c5d0-167e-11eb-83c3-eb7956ee1f3f",
                                    "type": "header",
                                    "children": [],
                                    "size": 2
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "work-permit-detail-page",
                    "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                    "route": "/work-permits/detail/:id"
                },
                {
                    "id": "0ef575f0-167e-11eb-83c3-eb7956ee1f32",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "8aa9f4a1-1679-11eb-83c3-eb7956ee1f33",
                            "children": [
                                {
                                    "id": "349d8180-167e-11eb-83c3-eb7956ee1f3f",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "text": "Unit Request",
                                    "className": "has-text-centered"
                                },
                                {
                                    "id": "63396bd0-167e-11eb-83c3-eb7956ee1f3f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "0fc02e10-1680-11eb-b4c7-db323e346a29",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "1972a1e0-1680-11eb-b4c7-db323e346a29",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "dataField": "unit",
                                                    "label": "Unit",
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                    "disabled": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "unit-container"
                                        },
                                        {
                                            "id": "3194c370-1680-11eb-b4c7-db323e346a29",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "43541160-1680-11eb-b4c7-db323e346a29",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "label": "Type",
                                                    "dataField": "type",
                                                    "disabled": true,
                                                    "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "type-container"
                                        },
                                        {
                                            "id": "99d2dc10-1680-11eb-b4c7-db323e346a29",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-unit-request-aircon-cleaning",
                                            "view": "989101e3-d9a6-11ea-891d-3722b00ad4e7"
                                        },
                                        {
                                            "id": "39d51430-1681-11eb-b4c7-db323e346a29",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-unit-request-grease-trap-cleaning",
                                            "view": "0c744882-d9a5-11ea-a304-319701533d32"
                                        },
                                        {
                                            "id": "87c85ee0-1681-11eb-b4c7-db323e346a29",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-unit-request-others",
                                            "view": "f7b9bf21-d9a8-11ea-9331-090047662dd2"
                                        },
                                        {
                                            "id": "d1bcdda0-1681-11eb-b4c7-db323e346a29",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-unit-request-plans-documents",
                                            "view": "e60fdb41-c910-11ea-9dd0-6723feee229e"
                                        },
                                        {
                                            "id": "5096c5f0-1682-11eb-b4c7-db323e346a29",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-unit-request-refund-renovation-bond",
                                            "view": "063f7331-c911-11ea-9dd0-6723feee229e"
                                        },
                                        {
                                            "id": "d3ff3710-1682-11eb-b4c7-db323e346a29",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-unit-request-service-request",
                                            "view": "e00cd7b1-c90c-11ea-8c59-196c3ec4f839"
                                        },
                                        {
                                            "id": "84d5c0e0-1683-11eb-b4c7-db323e346a29",
                                            "type": "subView",
                                            "children": [],
                                            "name": "view-unit-request-vehicle-sticker",
                                            "view": "8df270e1-d918-11ea-b3eb-d9cf4159b042"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "component": "UnitRequest",
                                    "name": "view-unit-request"
                                },
                                {
                                    "id": "9d35aec0-1683-11eb-b4c7-db323e346a29",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9edecae0-1683-11eb-b4c7-db323e346a29",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "status",
                                            "disabled": true,
                                            "label": "Status",
                                            "access": "",
                                            "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"canceled\", \"label\": \"Canceled\"}]"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "pmg-ops,pmg-security,accounting,accounting-staff",
                                    "dataField": ""
                                },
                                {
                                    "id": "c98a4120-1683-11eb-b4c7-db323e346a29",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "cd82a470-1683-11eb-b4c7-db323e346a29",
                                            "type": "inputText",
                                            "children": [],
                                            "label": "Notes",
                                            "dataField": "notes",
                                            "multiline": true,
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "pmg-ops,pmg-security,accounting,accounting-staff"
                                },
                                {
                                    "id": "9d35aec1-1683-11eb-b4c7-db323e346a29",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9edecae1-1683-11eb-b4c7-db323e346a29",
                                            "type": "inputSelect",
                                            "children": [],
                                            "dataField": "status",
                                            "disabled": false,
                                            "label": "Status",
                                            "access": "",
                                            "options": "[{\"value\":\"pending\", \"label\": \"Pending\"},{\"value\":\"completed\", \"label\": \"Completed\"},{\"value\":\"canceled\", \"label\": \"Canceled\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "admin,pmg",
                                    "dataField": ""
                                },
                                {
                                    "id": "c98a4121-1683-11eb-b4c7-db323e346a29",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "cd82a471-1683-11eb-b4c7-db323e346a29",
                                            "type": "inputText",
                                            "children": [],
                                            "label": "Notes",
                                            "dataField": "notes",
                                            "multiline": true,
                                            "disabled": false
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "access": "admin,pmg"
                                },
                                {
                                    "id": "27ca36f0-1684-11eb-b4c7-db323e346a29",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                                    "access": "admin,pmg"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "unit-request-detail-page",
                    "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                    "route": "/unit-requests/detail/:id"
                },
                {
                    "id": "989101e3-d9a6-11ea-891d-3722b00ad4e7",
                    "type": "view",
                    "children": [
                        {
                            "id": "d3e39f53-d9a6-11ea-891d-3722b00ad4e7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d5efea13-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.units",
                                    "label": "No. of Units",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "db6aa7a3-d9a6-11ea-891d-3722b00ad4e7",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e1bfdbe3-edcc-11ea-abfa-8f89576fb378",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "meta.schedule",
                                    "label": "Preferred Schedule",
                                    "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-unit-request-aircon-cleaning",
                    "_sortIndex": 590
                },
                {
                    "id": "0c744882-d9a5-11ea-a304-319701533d32",
                    "type": "view",
                    "children": [
                        {
                            "id": "1c9ddb42-d9a5-11ea-a304-319701533d32",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fbbdc252-edcc-11ea-abfa-8f89576fb378",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "meta.schedule",
                                    "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                    "label": "Preferred Schedule",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-unit-request-grease-trap-cleaning",
                    "_sortIndex": 600
                },
                {
                    "id": "f7b9bf21-d9a8-11ea-9331-090047662dd2",
                    "type": "view",
                    "children": [
                        {
                            "id": "fe4688f1-d9a8-11ea-9331-090047662dd2",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ff497a01-d9a8-11ea-9331-090047662dd2",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "name": "meta.details",
                                    "label": "Please Specify",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-unit-request-others",
                    "_sortIndex": 610
                },
                {
                    "id": "e60fdb41-c910-11ea-9dd0-6723feee229e",
                    "type": "view",
                    "children": [
                        {
                            "id": "e85ead42-c910-11ea-9dd0-6723feee229e",
                            "type": "container",
                            "children": [
                                {
                                    "id": "076a4bb2-c91e-11ea-831c-99fd7b91f646",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "meta.plans",
                                    "label": "Plans Required",
                                    "options": "[{ \"value\": \"standard\", \"label\": \"Standard Plan\"},{ \"value\": \"as-built\", \"label\": \"As-built Plan\"}]",
                                    "className": "bite-select",
                                    "saveObject": true,
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "homeowner-service-request-repairs",
                            "className": "bite-select"
                        },
                        {
                            "id": "112746d2-c91e-11ea-831c-99fd7b91f646",
                            "type": "container",
                            "children": [
                                {
                                    "id": "44b19ca2-d916-11ea-9ea3-7184c3941d0d",
                                    "type": "inputSelect",
                                    "children": [],
                                    "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                    "saveObject": true,
                                    "name": "meta.paper-size",
                                    "label": "Paper Size",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "UnitRequestPlansDocuments"
                        },
                        {
                            "id": "97e9eee2-d916-11ea-9ea3-7184c3941d0d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9a6e28c2-d916-11ea-9ea3-7184c3941d0d",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "meta.type",
                                    "label": "Type of Plan",
                                    "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing\", \"label\": \"Plumbing\"},{ \"value\": \"electrical-plan\", \"label\": \"Electrical Plan\"}]",
                                    "saveObject": true,
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-unit-request-plans-documents",
                    "_sortIndex": 620
                },
                {
                    "id": "063f7331-c911-11ea-9dd0-6723feee229e",
                    "type": "view",
                    "children": [
                        {
                            "id": "1cea7cc1-c91f-11ea-b910-c30a77ee18ba",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b0a9a301-ef9e-11ea-82b2-a7421559c7b2",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "meta.inspection-date",
                                    "label": "Preferred Inspection Date",
                                    "description": "Monday to Friday, except Holidays",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "className": ""
                        },
                        {
                            "id": "b9eb6b31-d917-11ea-9ea3-7184c3941d0d",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d8830d81-ef9e-11ea-82b2-a7421559c7b2",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.additional-requests",
                                    "multiline": true,
                                    "label": "Additional Requests",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-unit-request-refund-renovation-bond",
                    "_sortIndex": 630
                },
                {
                    "id": "e00cd7b1-c90c-11ea-8c59-196c3ec4f839",
                    "type": "view",
                    "children": [
                        {
                            "id": "743faf71-c90d-11ea-8c59-196c3ec4f839",
                            "type": "container",
                            "children": [
                                {
                                    "id": "a9319f51-c911-11ea-98ab-e51558e3434e",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Type of Work",
                                    "name": "meta.type",
                                    "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                                    "className": "bite-select",
                                    "saveObject": true,
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "bite-select"
                        },
                        {
                            "id": "c6681b81-c911-11ea-98ab-e51558e3434e",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c84dcb71-c911-11ea-98ab-e51558e3434e",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Work Details",
                                    "name": "meta.details",
                                    "multiline": true,
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "cf0bde71-c911-11ea-98ab-e51558e3434e",
                            "type": "container",
                            "children": [
                                {
                                    "id": "ccce1d41-edcd-11ea-993f-a977bcf36a2d",
                                    "type": "inputDateTime",
                                    "children": [],
                                    "name": "meta.schedule",
                                    "label": "Preferred Inspection/Work Schedule",
                                    "description": " Monday to Friday only. Except Holidays",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "bite-select"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-unit-request-service-request",
                    "_sortIndex": 640
                },
                {
                    "id": "8df270e1-d918-11ea-b3eb-d9cf4159b042",
                    "type": "view",
                    "children": [
                        {
                            "id": "b33b28f1-d919-11ea-b3eb-d9cf4159b042",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b80cd541-d919-11ea-b3eb-d9cf4159b042",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "meta.make",
                                    "label": "Make",
                                    "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                                    "saveObject": true,
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c394f371-d919-11ea-b3eb-d9cf4159b042",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c6827671-d919-11ea-b3eb-d9cf4159b042",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.model",
                                    "label": "Model",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "ad71c601-d9a4-11ea-a304-319701533d32",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b1b90ca1-d9a4-11ea-a304-319701533d32",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "meta.color",
                                    "label": "Color",
                                    "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                                    "saveObject": true,
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c744cbe1-d9a4-11ea-a304-319701533d32",
                            "type": "container",
                            "children": [
                                {
                                    "id": "cfb34e01-d9a4-11ea-a304-319701533d32",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "meta.plate-number",
                                    "label": "Plate Number",
                                    "required": false,
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "ddd18061-d9a4-11ea-a304-319701533d32",
                            "type": "container",
                            "children": [
                                {
                                    "id": "deacec41-d9a4-11ea-a304-319701533d32",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "meta.OR",
                                    "description": "",
                                    "label": "Upload O.R. / C.R.",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "orientation": "vertical",
                    "name": "view-unit-request-vehicle-sticker",
                    "_sortIndex": 650
                },
                {
                    "id": "42414a60-1697-11eb-9686-795f0ea8c25b",
                    "type": "page",
                    "children": [
                        {
                            "name": "main",
                            "type": "container",
                            "id": "f8571491-1685-11eb-9686-795f0ea8c25b",
                            "children": [
                                {
                                    "id": "4b92b270-1697-11eb-9686-795f0ea8c25b",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "className": "has-text-centered",
                                    "text": "Message Board"
                                },
                                {
                                    "id": "0e292670-1698-11eb-9686-795f0ea8c25b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1324c8f0-1698-11eb-9686-795f0ea8c25b",
                                            "type": "inputDateTime",
                                            "children": [],
                                            "name": "createdAt",
                                            "label": "Date Submitted",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "21d96ef0-1698-11eb-9686-795f0ea8c25b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "2617dbf0-1698-11eb-9686-795f0ea8c25b",
                                            "type": "inputSelect",
                                            "children": [],
                                            "label": "Homeowner",
                                            "dataField": "homeowner",
                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "34022d10-1698-11eb-9686-795f0ea8c25b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3743ea90-1698-11eb-9686-795f0ea8c25b",
                                            "type": "inputSelect",
                                            "children": [],
                                            "label": "Topic",
                                            "dataField": "topic",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "1bf85900-1699-11eb-9686-795f0ea8c25b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "1eff5770-1699-11eb-9686-795f0ea8c25b",
                                            "type": "inputText",
                                            "children": [],
                                            "label": "Title",
                                            "dataField": "title",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "279362f0-1699-11eb-9686-795f0ea8c25b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "285923f0-1699-11eb-9686-795f0ea8c25b",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "price",
                                            "label": "Price",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "30ab4470-1699-11eb-9686-795f0ea8c25b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "31933370-1699-11eb-9686-795f0ea8c25b",
                                            "type": "inputText",
                                            "children": [],
                                            "disabled": true,
                                            "multiline": true,
                                            "dataField": "description",
                                            "label": "Description"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "3f7239f0-1699-11eb-9686-795f0ea8c25b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "4348e470-1699-11eb-9686-795f0ea8c25b",
                                            "type": "inputText",
                                            "children": [],
                                            "dataField": "contactNumber",
                                            "label": "Contact Number",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "4d43d2f0-1699-11eb-9686-795f0ea8c25b",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "505c3680-1699-11eb-9686-795f0ea8c25b",
                                            "type": "inputMedia",
                                            "children": [],
                                            "basePath": "message-boards",
                                            "dataField": "photos",
                                            "label": "Photos",
                                            "disabled": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ]
                        }
                    ],
                    "orientation": "vertical",
                    "name": "message-board-detail-page",
                    "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                }
            ],
            "server": "pacificland.bitesizedigital.net",
            "undoLength": 51,
            "name": "pacific land - midpoint",
            "_state": {},
            "activeApp": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
            "_locked": false,
            "updateKey": "eeef6560-1685-11eb-b4c7-db323e346a29",
            "": {
                "id": "5f30e16ddf453329f6ef693d",
                "type": "project",
                "routes": [
                    {
                        "page": "billing-detail-page",
                        "path": "/billings/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                            "type": "page"
                        }
                    },
                    {
                        "page": "billing-item-detail-page",
                        "path": "/billing-items/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "3ab60610-e865-11ea-9def-530e14f0f646",
                            "type": "page"
                        }
                    },
                    {
                        "page": "billing-list-page",
                        "path": "/billings-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                            "type": "page"
                        }
                    },
                    {
                        "page": "comments-suggestions-list-page",
                        "path": "/comments-suggestions-list",
                        "requireAuth": true,
                        "node": {
                            "_id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "page"
                        }
                    },
                    {
                        "page": "dashboard-login",
                        "path": "/dashboard-login",
                        "layout": "full",
                        "requireAuth": false,
                        "node": {
                            "_id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
                            "type": "page"
                        }
                    },
                    {
                        "page": "department-detail-page",
                        "path": "/departments/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                            "type": "page"
                        }
                    },
                    {
                        "page": "department-list-page",
                        "path": "/departments-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                            "type": "page"
                        }
                    },
                    {
                        "page": "employee-detail-page",
                        "path": "/employees/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                            "type": "page"
                        }
                    },
                    {
                        "page": "employee-list-page",
                        "path": "/employees-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-account",
                        "path": "/my-account/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-billing",
                        "path": "/billing",
                        "requireAuth": false,
                        "node": {
                            "_id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-comments-suggestions",
                        "path": "/homeowner-comments-suggestions/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-complaint",
                        "path": "/file-complaint/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-complaints",
                        "path": "/filed-complaints",
                        "requireAuth": false,
                        "node": {
                            "_id": "47440520-971c-11ea-8a71-fd00fe88963d",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-delivery-pass",
                        "path": "/create-delivery-pass/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-delivery-passes",
                        "path": "/delivery-passes",
                        "requireAuth": false,
                        "node": {
                            "_id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-login-page",
                        "path": "/homeowners-login",
                        "layout": "full",
                        "requireAuth": false,
                        "node": {
                            "_id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-marketplace",
                        "path": "/market-place",
                        "requireAuth": false,
                        "node": {
                            "_id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-message-board",
                        "path": "/message-board",
                        "requireAuth": false,
                        "node": {
                            "_id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-new-post",
                        "path": "/new-post/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-notifications",
                        "path": "/notifications",
                        "requireAuth": false,
                        "node": {
                            "_id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-password",
                        "path": "/change-password",
                        "requireAuth": false,
                        "node": {
                            "_id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-post",
                        "path": "/post/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-profile",
                        "path": "/profile/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "17466790-96d2-11ea-9995-9d94cc393546",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-service-request",
                        "path": "/create-service-request/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-service-requests",
                        "path": "/service-requests",
                        "requireAuth": false,
                        "node": {
                            "_id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-unit-request",
                        "path": "/homeowner-unit-request/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-visitor-pass",
                        "path": "/create-visitor-pass/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-visitor-passes",
                        "path": "/visitor-passes",
                        "requireAuth": false,
                        "node": {
                            "_id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-welcome-page",
                        "path": "/welcome",
                        "requireAuth": false,
                        "node": {
                            "_id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                            "type": "page"
                        }
                    },
                    {
                        "page": "homeowner-work-permit",
                        "path": "/homeowner-work-permit/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                            "type": "page"
                        }
                    },
                    {
                        "page": "message-board-detail-page",
                        "path": "/message-boards/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "page"
                        }
                    },
                    {
                        "page": "message-board-list-page",
                        "path": "/message-boards-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "page"
                        }
                    },
                    {
                        "page": "notification-detail-page",
                        "path": "/notifications/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                            "type": "page"
                        }
                    },
                    {
                        "page": "notification-list-page",
                        "path": "/notifications-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                            "type": "page"
                        }
                    },
                    {
                        "page": "pass-delivery-detail-page",
                        "path": "/pass-deliveries/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "61855560-e82a-11ea-bffd-65b1c6e24159",
                            "type": "page"
                        }
                    },
                    {
                        "page": "pass-delivery-list-page",
                        "path": "/pass-deliveries-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
                            "type": "page"
                        }
                    },
                    {
                        "page": "pass-visitor-detail-page",
                        "path": "/pass-visitors/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "page"
                        }
                    },
                    {
                        "page": "pass-visitor-list-page",
                        "path": "/pass-visitors-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "page"
                        }
                    },
                    {
                        "page": "terms-conditions-detail-page",
                        "path": "/terms-conditions/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
                            "type": "page"
                        }
                    },
                    {
                        "page": "terms-conditions-list-page",
                        "path": "/terms-conditions-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "5aabc600-e507-11ea-8276-65e120bbe853",
                            "type": "page"
                        }
                    },
                    {
                        "page": "unit-detail-page",
                        "path": "/units/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "page"
                        }
                    },
                    {
                        "page": "unit-list-page",
                        "path": "/units-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "page"
                        }
                    },
                    {
                        "page": "unit-request-detail-page",
                        "path": "/unit-requests/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
                            "type": "page"
                        }
                    },
                    {
                        "page": "unit-request-list-page",
                        "path": "/unit-requests-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
                            "type": "page"
                        }
                    },
                    {
                        "page": "work-permit-detail-page",
                        "path": "/work-permits/:id",
                        "requireAuth": false,
                        "node": {
                            "_id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "page"
                        }
                    },
                    {
                        "page": "work-permit-list-page",
                        "path": "/work-permits-list",
                        "requireAuth": false,
                        "node": {
                            "_id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "page"
                        }
                    }
                ],
                "children": [
                    {
                        "id": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
                        "type": "app",
                        "name": "dashboard",
                        "sidebar": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                        "active": true,
                        "component": "",
                        "_sortIndex": 0,
                        "startPage": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf"
                    },
                    {
                        "id": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                        "type": "app",
                        "name": "homeowners",
                        "sidebar": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                        "startPage": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                        "active": false,
                        "_sortIndex": 10
                    },
                    {
                        "id": "2ad37a40-9138-11ea-bd78-399a56c40918",
                        "type": "model",
                        "children": [
                            {
                                "id": "35bfcda0-9138-11ea-bd78-399a56c40918",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                "name": "homeowner",
                                "_sortIndex": -1
                            },
                            {
                                "id": "3040a340-9138-11ea-bd78-399a56c40918",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "name",
                                "_sortIndex": 0
                            },
                            {
                                "id": "40b4ac80-9138-11ea-bd78-399a56c40918",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "description"
                            },
                            {
                                "id": "45aeef70-9138-11ea-bd78-399a56c40918",
                                "type": "field:number",
                                "children": [],
                                "dataType": "number",
                                "name": "amount"
                            },
                            {
                                "id": "6fa1c410-9138-11ea-bd78-399a56c40918",
                                "type": "field:json",
                                "children": [],
                                "dataType": "json",
                                "name": "details"
                            },
                            {
                                "id": "298e0e40-e9a6-11ea-bbba-5944fe032799",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "dueDate"
                            },
                            {
                                "id": "388318a0-e9a6-11ea-bbba-5944fe032799",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "postingDate"
                            }
                        ],
                        "name": "billing",
                        "_sortIndex": 20
                    },
                    {
                        "id": "960f7330-e864-11ea-9def-530e14f0f646",
                        "type": "model",
                        "children": [
                            {
                                "id": "efc17c70-e864-11ea-9def-530e14f0f646",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                "name": "billing",
                                "_sortIndex": -1
                            },
                            {
                                "id": "b016f690-e864-11ea-9def-530e14f0f646",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "item",
                                "_sortIndex": 0
                            },
                            {
                                "id": "b43e8030-e864-11ea-9def-530e14f0f646",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "description",
                                "_sortIndex": 10
                            },
                            {
                                "id": "b81a5ad0-e864-11ea-9def-530e14f0f646",
                                "type": "field:number",
                                "children": [],
                                "dataType": "number",
                                "name": "amount",
                                "_sortIndex": 20
                            },
                            {
                                "id": "bec0ee30-e864-11ea-9def-530e14f0f646",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "state",
                                "_sortIndex": 30
                            }
                        ],
                        "name": "billing-item"
                    },
                    {
                        "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                        "type": "model",
                        "children": [
                            {
                                "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:enumeration",
                                "children": [],
                                "dataType": "enumeration",
                                "name": "type"
                            },
                            {
                                "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "details"
                            },
                            {
                                "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:file",
                                "children": [],
                                "dataType": "file",
                                "collection": "file",
                                "via": "related",
                                "allowedTypes": [
                                    "images",
                                    "files",
                                    "videos"
                                ],
                                "plugin": "upload",
                                "name": "photos"
                            },
                            {
                                "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                "name": "homeowner"
                            },
                            {
                                "id": "db5ce460-edd0-11ea-993f-a977bcf36a2d",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "notes"
                            }
                        ],
                        "name": "comments-suggestions",
                        "_sortIndex": 30
                    },
                    {
                        "id": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                        "type": "model",
                        "children": [
                            {
                                "id": "1ba0dd50-8ce8-11ea-a283-ebcb859bf8c8",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "name",
                                "required": true,
                                "searchable": true
                            },
                            {
                                "id": "22702e60-8ce8-11ea-a283-ebcb859bf8c8",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "description",
                                "required": true
                            }
                        ],
                        "name": "department",
                        "active": false,
                        "_sortIndex": 50
                    },
                    {
                        "id": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                        "type": "model",
                        "children": [
                            {
                                "id": "7288d810-8c4e-11ea-9466-6f383d5eb483",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "employeeid",
                                "required": true,
                                "searchable": true,
                                "_sortIndex": 0
                            },
                            {
                                "id": "49b35e80-9129-11ea-a5e9-ff76f0b9af84",
                                "type": "field:email",
                                "children": [],
                                "dataType": "email",
                                "name": "email",
                                "required": true,
                                "_sortIndex": 9
                            },
                            {
                                "id": "780a4c60-8c4e-11ea-9466-6f383d5eb483",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "firstName",
                                "required": true,
                                "searchable": true,
                                "_sortIndex": 10
                            },
                            {
                                "id": "837ebb80-8c4e-11ea-9466-6f383d5eb483",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "lastName",
                                "required": true,
                                "searchable": true,
                                "_sortIndex": 20
                            },
                            {
                                "id": "e68f77d0-8c91-11ea-bb0b-0b00cf6ac98c",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "birthday",
                                "required": true,
                                "_sortIndex": 30
                            },
                            {
                                "id": "c11b9d10-8e7d-11ea-810e-c1e68e180dd7",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                "name": "department",
                                "_sortIndex": 40
                            },
                            {
                                "id": "5979e880-8e9e-11ea-9c0f-c7e69d58f37d",
                                "type": "field:file",
                                "children": [],
                                "dataType": "file",
                                "collection": "file",
                                "via": "related",
                                "allowedTypes": [
                                    "images",
                                    "files",
                                    "videos"
                                ],
                                "plugin": "upload",
                                "name": "picture",
                                "_sortIndex": 50
                            }
                        ],
                        "name": "employee",
                        "active": false,
                        "_sortIndex": 60
                    },
                    {
                        "id": "ed8defd0-911e-11ea-9e19-2d2007118721",
                        "type": "model",
                        "children": [
                            {
                                "id": "06bec240-911f-11ea-9e19-2d2007118721",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "accountNumber",
                                "_sortIndex": -1
                            },
                            {
                                "id": "3f18fbb0-9129-11ea-a5e9-ff76f0b9af84",
                                "type": "field:email",
                                "children": [],
                                "dataType": "email",
                                "name": "email",
                                "required": true,
                                "_sortIndex": 0
                            },
                            {
                                "id": "f15753e0-911e-11ea-9e19-2d2007118721",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "firstName",
                                "required": true,
                                "_sortIndex": 10
                            },
                            {
                                "id": "f67bdb20-911e-11ea-9e19-2d2007118721",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "lastName",
                                "required": true,
                                "_sortIndex": 20
                            },
                            {
                                "id": "138b4750-911f-11ea-9e19-2d2007118721",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "contact",
                                "_sortIndex": 70
                            },
                            {
                                "id": "b2c46990-e9f4-11ea-9892-473a233000d1",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "nickname"
                            },
                            {
                                "id": "f64a0d20-e9fc-11ea-bf3b-ef4612b5d21e",
                                "type": "field:file",
                                "children": [],
                                "dataType": "file",
                                "collection": "file",
                                "via": "related",
                                "allowedTypes": [
                                    "images",
                                    "files",
                                    "videos"
                                ],
                                "plugin": "upload",
                                "name": "photo"
                            },
                            {
                                "id": "f1f4a130-ef98-11ea-9d3f-8def54ed8352",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "parkingUnitNumber"
                            }
                        ],
                        "name": "homeowner",
                        "_sortIndex": 70
                    },
                    {
                        "id": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                        "type": "model",
                        "children": [
                            {
                                "id": "b3aa23e0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                "name": "homeowner"
                            },
                            {
                                "id": "c20c3d10-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:enumeration",
                                "children": [],
                                "dataType": "enumeration",
                                "name": "topic"
                            },
                            {
                                "id": "d7f25150-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "title"
                            },
                            {
                                "id": "dd8b6c50-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "price"
                            },
                            {
                                "id": "e1c656e0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "description"
                            },
                            {
                                "id": "eb73c1a0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "contactNumber"
                            },
                            {
                                "id": "f1279090-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:file",
                                "children": [],
                                "dataType": "file",
                                "collection": "file",
                                "via": "related",
                                "allowedTypes": [
                                    "images",
                                    "files",
                                    "videos"
                                ],
                                "plugin": "upload",
                                "name": "photos"
                            },
                            {
                                "id": "ff6cabe0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "status"
                            },
                            {
                                "id": "2cdc7120-db5c-11ea-968c-6f86147884ca",
                                "type": "field:json",
                                "children": [],
                                "dataType": "json",
                                "name": "comments"
                            }
                        ],
                        "_sortIndex": 80,
                        "name": "message-board"
                    },
                    {
                        "id": "77e05b60-911e-11ea-9e19-2d2007118721",
                        "type": "model",
                        "children": [
                            {
                                "id": "7bedcd50-911e-11ea-9e19-2d2007118721",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "name",
                                "required": true
                            },
                            {
                                "id": "8093aeb0-911e-11ea-9e19-2d2007118721",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "text",
                                "required": true
                            },
                            {
                                "id": "88181ef0-911e-11ea-9e19-2d2007118721",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "noticeStart",
                                "required": true
                            },
                            {
                                "id": "97459e70-911e-11ea-9e19-2d2007118721",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "noticeEnd"
                            },
                            {
                                "id": "9eb945d0-911e-11ea-9e19-2d2007118721",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "source"
                            },
                            {
                                "id": "a096b760-9129-11ea-a5e9-ff76f0b9af84",
                                "type": "field",
                                "children": [],
                                "dataType": "string",
                                "name": "status"
                            }
                        ],
                        "name": "notification",
                        "_sortIndex": 90
                    },
                    {
                        "id": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                        "type": "model",
                        "children": [
                            {
                                "id": "c4081f00-d9c6-11ea-93cf-3dd7e562aac3",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                "name": "unit"
                            },
                            {
                                "id": "d7708910-d9c6-11ea-93cf-3dd7e562aac3",
                                "type": "field:enumeration",
                                "children": [],
                                "dataType": "enumeration",
                                "name": "type"
                            },
                            {
                                "id": "e5db54d0-d9c6-11ea-93cf-3dd7e562aac3",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "schedule"
                            },
                            {
                                "id": "ee4c9610-d9c6-11ea-93cf-3dd7e562aac3",
                                "type": "field:json",
                                "children": [],
                                "dataType": "json",
                                "name": "items"
                            },
                            {
                                "id": "f2ed6e60-d9c6-11ea-93cf-3dd7e562aac3",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "company"
                            },
                            {
                                "id": "00b0fda0-d9c7-11ea-93cf-3dd7e562aac3",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "status"
                            },
                            {
                                "id": "5823d2a0-e828-11ea-99d9-7b0e0405473d",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "notes"
                            }
                        ],
                        "name": "pass-delivery",
                        "_sortIndex": 100
                    },
                    {
                        "id": "a5454c50-911e-11ea-9e19-2d2007118721",
                        "type": "model",
                        "children": [
                            {
                                "id": "c7d2c620-d9c7-11ea-93cf-3dd7e562aac3",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                "name": "unit"
                            },
                            {
                                "id": "cfdbf6c0-d9c7-11ea-93cf-3dd7e562aac3",
                                "type": "field:json",
                                "children": [],
                                "dataType": "json",
                                "name": "visitor",
                                "required": true
                            },
                            {
                                "id": "fa4d2320-d9c7-11ea-93cf-3dd7e562aac3",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "startDate"
                            },
                            {
                                "id": "ff0ec9e0-d9c7-11ea-93cf-3dd7e562aac3",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "endDate"
                            },
                            {
                                "id": "06a73750-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "field:file",
                                "children": [],
                                "dataType": "file",
                                "collection": "file",
                                "via": "related",
                                "allowedTypes": [
                                    "images",
                                    "files",
                                    "videos"
                                ],
                                "plugin": "upload",
                                "name": "visitorsId"
                            },
                            {
                                "id": "7b087c60-da1a-11ea-b50d-8323e621ee63",
                                "type": "field:enumeration",
                                "children": [],
                                "dataType": "enumeration",
                                "name": "type"
                            },
                            {
                                "id": "f20d6ce0-ea54-11ea-8de6-3f209e03f86c",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "status"
                            }
                        ],
                        "name": "pass-visitor",
                        "_sortIndex": 109
                    },
                    {
                        "id": "555659e0-9138-11ea-bd78-399a56c40918",
                        "type": "model",
                        "children": [
                            {
                                "id": "5a734000-9138-11ea-bd78-399a56c40918",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                "name": "billing"
                            },
                            {
                                "id": "5ecc8800-9138-11ea-bd78-399a56c40918",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                "name": "homeowner"
                            },
                            {
                                "id": "697da720-9138-11ea-bd78-399a56c40918",
                                "type": "field:number",
                                "children": [],
                                "dataType": "number",
                                "name": "amount"
                            }
                        ],
                        "name": "payment",
                        "_sortIndex": 120
                    },
                    {
                        "id": "faa20cb0-e506-11ea-8276-65e120bbe853",
                        "type": "model",
                        "children": [
                            {
                                "id": "fe1b06d0-e506-11ea-8276-65e120bbe853",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "name"
                            },
                            {
                                "id": "0704fa80-e507-11ea-8276-65e120bbe853",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "content"
                            }
                        ],
                        "name": "terms-conditions"
                    },
                    {
                        "id": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                        "type": "model",
                        "children": [
                            {
                                "id": "a86f3310-d84e-11ea-acd1-c1cd20d0424a",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "number"
                            },
                            {
                                "id": "b91aee20-d84e-11ea-acd1-c1cd20d0424a",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "location"
                            },
                            {
                                "id": "bc50ebd0-d84e-11ea-acd1-c1cd20d0424a",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "type",
                                "component": ""
                            },
                            {
                                "id": "73fa85c0-edd0-11ea-993f-a977bcf36a2d",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "areaSize"
                            },
                            {
                                "id": "817fd380-edd0-11ea-993f-a977bcf36a2d",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "classification"
                            },
                            {
                                "id": "8bc3d8f0-edd0-11ea-993f-a977bcf36a2d",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "projectCode"
                            },
                            {
                                "id": "944805f0-edd0-11ea-993f-a977bcf36a2d",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "customerCode"
                            },
                            {
                                "id": "99e36ae0-edd0-11ea-993f-a977bcf36a2d",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "turnoverDate"
                            },
                            {
                                "id": "a14a4100-edd0-11ea-993f-a977bcf36a2d",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "name": "homeowner",
                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                            },
                            {
                                "id": "53405060-ef99-11ea-9d3f-8def54ed8352",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "greaseTrapCleaning"
                            },
                            {
                                "id": "ab1423c0-ef99-11ea-9d3f-8def54ed8352",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "airconCleaning"
                            }
                        ],
                        "name": "unit",
                        "_sortIndex": 130
                    },
                    {
                        "id": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                        "type": "model",
                        "children": [
                            {
                                "id": "ffb19150-d84d-11ea-acd1-c1cd20d0424a",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "type"
                            },
                            {
                                "id": "09a553e0-d84e-11ea-acd1-c1cd20d0424a",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "status"
                            },
                            {
                                "id": "125e2570-d84e-11ea-acd1-c1cd20d0424a",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                "name": "unit"
                            },
                            {
                                "id": "47a03900-e828-11ea-99d9-7b0e0405473d",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "component": "",
                                "name": "notes"
                            }
                        ],
                        "name": "unit-request",
                        "_sortIndex": 140
                    },
                    {
                        "id": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                        "type": "model",
                        "children": [
                            {
                                "id": "59d52f00-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:relation",
                                "children": [],
                                "dataType": "relation",
                                "name": "unit",
                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                            },
                            {
                                "id": "5fa7a980-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:enumeration",
                                "children": [],
                                "dataType": "enumeration",
                                "name": "natureOfWork"
                            },
                            {
                                "id": "680a92e0-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "contractor"
                            },
                            {
                                "id": "6cc22780-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "personInCharge"
                            },
                            {
                                "id": "77ba88d0-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "contactPersonInCharge"
                            },
                            {
                                "id": "82778ed0-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:json",
                                "children": [],
                                "dataType": "json",
                                "name": "workers"
                            },
                            {
                                "id": "87d65ff0-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "description"
                            },
                            {
                                "id": "951e5690-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:json",
                                "children": [],
                                "dataType": "json",
                                "name": "materials"
                            },
                            {
                                "id": "9a41cc60-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:json",
                                "children": [],
                                "dataType": "json",
                                "name": "tools"
                            },
                            {
                                "id": "a0da7d10-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "startDate"
                            },
                            {
                                "id": "a5a376d0-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:date",
                                "children": [],
                                "dataType": "date",
                                "format": "mm/dd/yyyy",
                                "name": "endDate"
                            },
                            {
                                "id": "abf13bd0-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:file",
                                "children": [],
                                "dataType": "file",
                                "collection": "file",
                                "via": "related",
                                "allowedTypes": [
                                    "images",
                                    "files",
                                    "videos"
                                ],
                                "plugin": "upload",
                                "name": "plans"
                            },
                            {
                                "id": "afc85b80-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "status"
                            },
                            {
                                "id": "339637c0-e828-11ea-99d9-7b0e0405473d",
                                "type": "field:text",
                                "children": [],
                                "dataType": "text",
                                "name": "notes"
                            }
                        ],
                        "name": "work-permit",
                        "_sortIndex": 150
                    },
                    {
                        "id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                "children": [
                                    {
                                        "id": "7aa7e100-9138-11ea-bd78-399a56c40918",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7aa7e102-9138-11ea-bd78-399a56c40918",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "homeowner",
                                                "label": "homeowner",
                                                "dataField": "homeowner",
                                                "saveObject": true,
                                                "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "7aa7e103-9138-11ea-bd78-399a56c40918",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7aa7e105-9138-11ea-bd78-399a56c40918",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "name",
                                                "label": "name",
                                                "dataField": "name"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "7aa7e106-9138-11ea-bd78-399a56c40918",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7aa7e108-9138-11ea-bd78-399a56c40918",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "description",
                                                "label": "description",
                                                "dataField": "description"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "7aa7e109-9138-11ea-bd78-399a56c40918",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7aa7e10b-9138-11ea-bd78-399a56c40918",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "amount",
                                                "label": "amount",
                                                "dataField": "amount"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "7aa7e10c-9138-11ea-bd78-399a56c40918",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7aa7e10e-9138-11ea-bd78-399a56c40918",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "details",
                                                "label": "details",
                                                "dataField": "details"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "016566c0-e9b0-11ea-8b13-df1e5e645c34",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "postingDate",
                                        "dataField": "postingDate"
                                    },
                                    {
                                        "id": "04a80ea0-e9b0-11ea-8b13-df1e5e645c34",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "dueDate",
                                        "dataField": "dueDate"
                                    }
                                ],
                                "_sortIndex": 0
                            },
                            {
                                "id": "21002eb0-e871-11ea-808a-85e6d8103902",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "2576c2b0-e871-11ea-808a-85e6d8103902",
                                        "type": "button",
                                        "children": [],
                                        "text": "Add Item",
                                        "command": "newItem",
                                        "name": "addItem"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 9
                            },
                            {
                                "id": "22b50110-e865-11ea-9def-530e14f0f646",
                                "type": "subView",
                                "children": [],
                                "_sortIndex": 10,
                                "view": "3acc2620-e865-11ea-9def-530e14f0f646",
                                "relation": "billing"
                            },
                            {
                                "id": "7aa39b40-9138-11ea-bd78-399a56c40918",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                                "_sortIndex": 20
                            }
                        ],
                        "orientation": "vertical",
                        "name": "billing-detail-page",
                        "route": "/billings/:id",
                        "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                        "component": "",
                        "_sortIndex": 160
                    },
                    {
                        "id": "3ab60610-e865-11ea-9def-530e14f0f646",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                                "children": [
                                    {
                                        "id": "3ac76b30-e865-11ea-9def-530e14f0f646",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3ac76b32-e865-11ea-9def-530e14f0f646",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "billing",
                                                "label": "billing",
                                                "dataField": "billing",
                                                "saveObject": true,
                                                "relation": "2ad37a40-9138-11ea-bd78-399a56c40918"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "3ac76b33-e865-11ea-9def-530e14f0f646",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3ac76b35-e865-11ea-9def-530e14f0f646",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "item",
                                                "label": "item",
                                                "dataField": "item"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "3ac76b36-e865-11ea-9def-530e14f0f646",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3ac79240-e865-11ea-9def-530e14f0f646",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "description",
                                                "label": "description",
                                                "dataField": "description"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "3ac79241-e865-11ea-9def-530e14f0f646",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3ac79243-e865-11ea-9def-530e14f0f646",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "amount",
                                                "label": "amount",
                                                "dataField": "amount"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "3ac79244-e865-11ea-9def-530e14f0f646",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3ac79246-e865-11ea-9def-530e14f0f646",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "state",
                                                "label": "state",
                                                "dataField": "state"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "3abf2dd0-e865-11ea-9def-530e14f0f646",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "billing-item-detail-page",
                        "route": "/billing-items/:id",
                        "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
                    },
                    {
                        "id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                "children": [
                                    {
                                        "id": "7ab04570-9138-11ea-bd78-399a56c40918",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "7ab48b30-9138-11ea-bd78-399a56c40918",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "7ab94620-9138-11ea-bd78-399a56c40918",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "homeowner",
                                                "label": "homeowner",
                                                "dataField": "homeowner"
                                            },
                                            {
                                                "id": "7ab94622-9138-11ea-bd78-399a56c40918",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "name",
                                                "label": "name",
                                                "dataField": "name"
                                            },
                                            {
                                                "id": "7ab94624-9138-11ea-bd78-399a56c40918",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "description",
                                                "label": "description",
                                                "dataField": "description"
                                            },
                                            {
                                                "id": "7ab94626-9138-11ea-bd78-399a56c40918",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "amount",
                                                "label": "amount",
                                                "dataField": "amount"
                                            },
                                            {
                                                "id": "7ab94628-9138-11ea-bd78-399a56c40918",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "details",
                                                "label": "details",
                                                "dataField": "details"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "billing-list-page",
                        "route": "/billings-list",
                        "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                        "_sortIndex": 170
                    },
                    {
                        "id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                                "children": [
                                    {
                                        "id": "77c369d0-dd57-11ea-9ac6-91ffe70e2d7c",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "77c824c0-dd57-11ea-9ac6-91ffe70e2d7c",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "77cd54e0-dd57-11ea-9ac6-91ffe70e2d7c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "type",
                                                "label": "type",
                                                "dataField": "type"
                                            },
                                            {
                                                "id": "77cd7bf2-dd57-11ea-9ac6-91ffe70e2d7c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "homeowner",
                                                "label": "homeowner",
                                                "dataField": "homeowner"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "comments-suggestions-list-page",
                        "route": "/comments-suggestions-list",
                        "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                        "_sortIndex": 190,
                        "requireAuth": true
                    },
                    {
                        "id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "a4892271-dfa5-11ea-a94d-e5d6a6ca6c3b",
                                "children": [
                                    {
                                        "id": "b81715e0-dfa5-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "subView",
                                        "children": [],
                                        "view": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "dashboard-login",
                        "route": "/dashboard-login",
                        "component": "",
                        "noPadding": false,
                        "layout": "full"
                    },
                    {
                        "id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                "children": [
                                    {
                                        "id": "99dbbf60-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "99dbbf62-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "name",
                                                "label": "Department",
                                                "dataField": "name"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "99dbbf63-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "99dbbf65-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "description",
                                                "label": "Description",
                                                "dataField": "description",
                                                "description": "",
                                                "multiline": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "f68ae050-e9f4-11ea-9892-473a233000d1",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "99d8b220-912e-11ea-ad57-6526d978cdf6",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "department-detail-page",
                        "route": "/departments/:id",
                        "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                        "_sortIndex": 200
                    },
                    {
                        "id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                "children": [
                                    {
                                        "id": "94311830-912e-11ea-ad57-6526d978cdf6",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "9433b040-912e-11ea-ad57-6526d978cdf6",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "94364850-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "name",
                                                "label": "Name",
                                                "dataField": "name"
                                            },
                                            {
                                                "id": "94364852-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "description",
                                                "label": "Description",
                                                "dataField": "description"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "department-list-page",
                        "route": "/departments-list",
                        "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                        "_sortIndex": 210
                    },
                    {
                        "id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                "children": [
                                    {
                                        "id": "a5a55360-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a5a55362-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "employeeid",
                                                "label": "employeeid",
                                                "dataField": "employeeid"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a5a55363-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a5a55365-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "email",
                                                "label": "email",
                                                "dataField": "email"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a5a55366-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a5a55368-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "firstName",
                                                "label": "firstName",
                                                "dataField": "firstName"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a5a55369-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a5a5536b-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "lastName",
                                                "label": "lastName",
                                                "dataField": "lastName"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a5a5536c-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a5a5536e-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "birthday",
                                                "label": "birthday",
                                                "dataField": "birthday",
                                                "format": "mm/dd/yyyy"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a5a5536f-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a5a55371-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "department",
                                                "label": "department",
                                                "dataField": "department",
                                                "saveObject": true,
                                                "relation": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a5a55372-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a5a55374-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "picture",
                                                "label": "picture",
                                                "dataField": "picture",
                                                "basePath": "employees"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "a5a26d30-912e-11ea-ad57-6526d978cdf6",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "employee-detail-page",
                        "route": "/employees/:id",
                        "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                        "_sortIndex": 220
                    },
                    {
                        "id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                "children": [
                                    {
                                        "id": "a5aa8380-912e-11ea-ad57-6526d978cdf6",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "a5ae05f0-912e-11ea-ad57-6526d978cdf6",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "a5b0c510-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "employeeid",
                                                "label": "employeeid",
                                                "dataField": "employeeid"
                                            },
                                            {
                                                "id": "a5b0c512-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "email",
                                                "label": "email",
                                                "dataField": "email"
                                            },
                                            {
                                                "id": "a5b0c514-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "firstName",
                                                "label": "firstName",
                                                "dataField": "firstName"
                                            },
                                            {
                                                "id": "a5b0c516-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "lastName",
                                                "label": "lastName",
                                                "dataField": "lastName"
                                            },
                                            {
                                                "id": "a5b0c518-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "birthday",
                                                "label": "birthday",
                                                "dataField": "birthday"
                                            },
                                            {
                                                "id": "a5b0c51a-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "department",
                                                "label": "department",
                                                "dataField": "department"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "employee-list-page",
                        "route": "/employees-list",
                        "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                        "_sortIndex": 230
                    },
                    {
                        "id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "4840fc41-9149-11ea-a6b6-e7e287632ad5",
                                "children": [
                                    {
                                        "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "My Account",
                                        "className": "has-text-centered mt-2",
                                        "_sortIndex": 0
                                    },
                                    {
                                        "id": "707a9a30-ea14-11ea-a21e-f569cc759e68",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "75cbd6c0-ea14-11ea-a21e-f569cc759e68",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "864c1500-ea14-11ea-a21e-f569cc759e68",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_profile.svg\"><img src=\"./assets/images/icon_profile.svg\" /></object>My Profile</div>",
                                                        "path": "/profile/me"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "97a5ea60-ea14-11ea-a21e-f569cc759e68",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a301ef80-ea14-11ea-a21e-f569cc759e68",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_password.svg\"><img src=\"./assets/images/icon_password.svg\" /></object>Change Password</div>",
                                                        "path": "/change-password"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "b0937240-ea14-11ea-a21e-f569cc759e68",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "b9cc4940-ea14-11ea-a21e-f569cc759e68",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pay.svg\"><img src=\"./assets/images/icon_pay.svg\" /></object>Pay Dues</div>",
                                                        "path": "#"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "c3ebafb0-ea14-11ea-a21e-f569cc759e68",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ccf909e0-ea14-11ea-a21e-f569cc759e68",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_balance.svg\"><img src=\"./assets/images/icon_balance.svg\" /></object>View Balance</div>",
                                                        "path": "#"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menus-container"
                                    }
                                ],
                                "component": "Account",
                                "className": ""
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-account",
                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                        "route": "/my-account/:id",
                        "component": "",
                        "_sortIndex": 240
                    },
                    {
                        "id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                        "type": "page",
                        "children": [
                            {
                                "id": "62b4dc20-96d2-11ea-8416-c79b465e39ab",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6560c240-96d2-11ea-8416-c79b465e39ab",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Billing"
                                    },
                                    {
                                        "id": "71d451e0-96d2-11ea-8416-c79b465e39ab",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "7b996350-96d2-11ea-8416-c79b465e39ab",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-billing",
                        "route": "/billing",
                        "_sortIndex": 250
                    },
                    {
                        "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                "children": [
                                    {
                                        "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Comments and Suggestions",
                                        "_sortIndex": 0,
                                        "name": "",
                                        "className": "has-text-centered"
                                    },
                                    {
                                        "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                                "type": "button",
                                                "children": [],
                                                "text": "View complaint history",
                                                "path": "/filed-complaints",
                                                "variant": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 9,
                                        "className": "justify-content-center"
                                    },
                                    {
                                        "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                                "type": "inputSelect",
                                                "children": [],
                                                "dataField": "type",
                                                "label": "Type of issue",
                                                "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 10,
                                        "className": ""
                                    },
                                    {
                                        "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "details",
                                                "label": "Details of the issue/concern",
                                                "multiline": true,
                                                "name": ""
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 20,
                                        "className": ""
                                    },
                                    {
                                        "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                                "type": "inputMedia",
                                                "children": [],
                                                "label": "Photo",
                                                "dataField": "photo"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 40,
                                        "className": ""
                                    },
                                    {
                                        "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                                "type": "button",
                                                "children": [],
                                                "text": "Save",
                                                "command": "save",
                                                "variant": "contained",
                                                "color": "primary",
                                                "className": "",
                                                "fullWidth": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 50,
                                        "className": ""
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-comments-suggestions",
                        "route": "/homeowner-comments-suggestions/:id",
                        "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                        "className": "",
                        "_sortIndex": 260
                    },
                    {
                        "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                "children": [
                                    {
                                        "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Comments and Suggestions",
                                        "_sortIndex": 0,
                                        "name": "",
                                        "className": "has-text-centered"
                                    },
                                    {
                                        "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                                "type": "button",
                                                "children": [],
                                                "text": "View complaint history",
                                                "path": "/filed-complaints",
                                                "variant": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 9,
                                        "className": "justify-content-center"
                                    },
                                    {
                                        "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                                "type": "inputSelect",
                                                "children": [],
                                                "dataField": "type",
                                                "label": "Type of issue"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 10,
                                        "className": ""
                                    },
                                    {
                                        "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "details",
                                                "label": "Details of the issue/concern",
                                                "multiline": true,
                                                "name": ""
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 20,
                                        "className": ""
                                    },
                                    {
                                        "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                                "type": "inputMedia",
                                                "children": [],
                                                "label": "Photo",
                                                "dataField": "photo"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 40,
                                        "className": ""
                                    },
                                    {
                                        "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                                "type": "button",
                                                "children": [],
                                                "text": "Save",
                                                "command": "save",
                                                "variant": "contained",
                                                "color": "primary",
                                                "className": "",
                                                "fullWidth": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 50,
                                        "className": ""
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-complaint",
                        "route": "/file-complaint/:id",
                        "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                        "className": "",
                        "_sortIndex": 270
                    },
                    {
                        "id": "47440520-971c-11ea-8a71-fd00fe88963d",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "47440521-971c-11ea-8a71-fd00fe88963d",
                                "children": [
                                    {
                                        "id": "4b1a6180-971c-11ea-8a71-fd00fe88963d",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Complaints",
                                        "className": "has-text-centered"
                                    }
                                ]
                            },
                            {
                                "id": "52a624c0-971c-11ea-8a71-fd00fe88963d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "5d6c5280-971c-11ea-8a71-fd00fe88963d",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "63bf6eb0-971c-11ea-8a71-fd00fe88963d",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "item"
                                            },
                                            {
                                                "id": "6749a6e0-971c-11ea-8a71-fd00fe88963d",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "item"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5a577160-971c-11ea-8a71-fd00fe88963d",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal"
                            }
                        ],
                        "orientation": "vertical",
                        "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                        "route": "/filed-complaints",
                        "name": "homeowner-complaints",
                        "className": "",
                        "_sortIndex": 280
                    },
                    {
                        "id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "4e8b5d81-9157-11ea-8382-2991800d56bc",
                                "children": [
                                    {
                                        "id": "268a56f0-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "name": "",
                                        "text": "Delivery/ Pull-out Pass"
                                    },
                                    {
                                        "id": "3cd79170-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "40347810-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "button",
                                                "children": [],
                                                "text": "View History",
                                                "variant": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "justify-content-center pt-1 pb-1"
                                    },
                                    {
                                        "id": "590cca40-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "5f544db0-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "inputSelect",
                                                "children": [],
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "dataField": "unit",
                                                "label": "Unit"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "66e4a4d0-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "6b30cd70-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "inputSelect",
                                                "children": [],
                                                "dataField": "type",
                                                "label": "Type of Pass",
                                                "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "7d085e50-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c833a300-edcc-11ea-abfa-8f89576fb378",
                                                "type": "inputDateTime",
                                                "children": [],
                                                "dataField": "schedule",
                                                "label": "Schedule"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "87486c20-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "8c652b30-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "inputList",
                                                "children": [],
                                                "label": "Item(s) - Please enumerate including quantities.",
                                                "dataField": "items"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a733cf20-da0a-11ea-87ea-f5980a7a1cab",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ac4da800-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "company",
                                                "label": "Delivery Company"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a788cc00-e7f7-11ea-aa5b-4b966778e568",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "component": "TermsAndConditions",
                                        "name": "delivery-pass"
                                    },
                                    {
                                        "id": "df2a4d60-dac7-11ea-9725-dfc9cbd591dc",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "e07a7410-dac7-11ea-9725-dfc9cbd591dc",
                                                "type": "button",
                                                "children": [],
                                                "command": "save",
                                                "text": "Submit",
                                                "fullWidth": true,
                                                "className": "mt-1"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-delivery-pass",
                        "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                        "route": "/create-delivery-pass/:id",
                        "className": "",
                        "_sortIndex": 290
                    },
                    {
                        "id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "db3a70c1-96c7-11ea-bb44-c34ef5a44500",
                                "children": [
                                    {
                                        "id": "e5006c90-96c7-11ea-bb44-c34ef5a44500",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Delivery Passes"
                                    },
                                    {
                                        "id": "e8824050-96c7-11ea-bb44-c34ef5a44500",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "f0fafba0-96c7-11ea-bb44-c34ef5a44500",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "ae53bb10-96c8-11ea-bb44-c34ef5a44500",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "dataField": "courier"
                                                    },
                                                    {
                                                        "id": "b91bbd90-96c8-11ea-bb44-c34ef5a44500",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "dataField": "items"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "path": "/create-delivery-pass"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "ed6551c0-96c7-11ea-bb44-c34ef5a44500",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-delivery-passes",
                        "route": "/delivery-passes",
                        "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                        "_sortIndex": 300
                    },
                    {
                        "id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "0ad19881-93ea-11ea-99e4-a504a663bd48"
                            },
                            {
                                "id": "1acacc70-93ea-11ea-9384-d1e4789a27a9",
                                "type": "subView",
                                "children": [],
                                "view": "e1156440-93e9-11ea-99e4-a504a663bd48"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-login-page",
                        "component": "",
                        "route": "/homeowners-login",
                        "_sortIndex": 330,
                        "layout": "full"
                    },
                    {
                        "id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "63850e21-a55c-11ea-8feb-c7034be1bc66",
                                "children": [
                                    {
                                        "id": "da5a80c0-a55c-11ea-99ce-d1e75946b32d",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Marketplace"
                                    },
                                    {
                                        "id": "ec182100-a55c-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "f232a100-a55c-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "07f88310-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "text": "",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_water.svg\"><img src=\"./assets/images/icon_water.svg\" /></object>Drinking Water</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "014dd100-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "26cecc90-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_gas.svg\"><img src=\"./assets/images/icon_gas.svg\" /></object>Gasul</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "columns is-centered mt-2"
                                    },
                                    {
                                        "id": "b1d7e880-a55d-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "494d3680-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "516b0090-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Rice</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "63098010-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "69f72e90-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_laundry.svg\"><img src=\"./assets/images/icon_laundry.svg\" /></object>Laundry</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "columns is-centered"
                                    },
                                    {
                                        "id": "c6888a00-a55d-11ea-99ce-d1e75946b32d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ccd51680-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d4ae5d80-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "button",
                                                        "children": [],
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pet.svg\"><img src=\"./assets/images/icon_pet.svg\" /></object>Pet Grooming</div>",
                                                        "className": "app-menu-item"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "f062cd90-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "f2893000-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_cleaning.svg\"><img src=\"./assets/images/icon_cleaning.svg\" /></object>Cleaning Service</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "columns is-centered"
                                    }
                                ],
                                "className": "has-text-centered",
                                "_sortIndex": -1
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-marketplace",
                        "route": "/market-place",
                        "_sortIndex": 340
                    },
                    {
                        "id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "231003d1-c920-11ea-9080-1563a0e60ff0",
                                "className": "p1",
                                "children": [
                                    {
                                        "id": "709bda20-c920-11ea-9080-1563a0e60ff0",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Message Board",
                                        "className": "has-text-centered"
                                    },
                                    {
                                        "id": "53470b10-c921-11ea-84bc-97bd01295e5b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "566450a0-c921-11ea-84bc-97bd01295e5b",
                                                "type": "button",
                                                "children": [],
                                                "variant": "text",
                                                "text": "New Post",
                                                "path": "/new-post/0"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "justify-content-center pt-1 pb-1"
                                    },
                                    {
                                        "id": "5b0277e0-db4b-11ea-bdb5-fdc0741685fd",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "component": "MessageBoard"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-message-board",
                        "route": "/message-board",
                        "_sortIndex": 350,
                        "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                    },
                    {
                        "id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "233b7c71-c927-11ea-84bc-97bd01295e5b",
                                "children": [
                                    {
                                        "id": "521b2680-c927-11ea-84bc-97bd01295e5b",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "New Post",
                                        "className": "has-text-centered"
                                    },
                                    {
                                        "id": "59e133a0-c927-11ea-84bc-97bd01295e5b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "62ac7bc0-c927-11ea-84bc-97bd01295e5b",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "",
                                                "label": "Post Topic",
                                                "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"lf\", \"label\": \"LF (Looking For)\"}]",
                                                "dataField": "topic"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "bite-select pt-2"
                                    },
                                    {
                                        "id": "df291320-c927-11ea-8d76-f7e6b086de0b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "e2116600-c927-11ea-8d76-f7e6b086de0b",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "",
                                                "label": "Title",
                                                "dataField": "title"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "eb70b0c0-c927-11ea-8d76-f7e6b086de0b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ed3236e0-c927-11ea-8d76-f7e6b086de0b",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "",
                                                "label": "Price",
                                                "dataField": "price"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "f42897f0-c927-11ea-8d76-f7e6b086de0b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "f98df8c0-c927-11ea-8d76-f7e6b086de0b",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "",
                                                "label": "Description",
                                                "multiline": true,
                                                "dataField": "description"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "0b6fc2d0-c928-11ea-8d76-f7e6b086de0b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "0d2784f0-c928-11ea-8d76-f7e6b086de0b",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "",
                                                "label": "Contact Number",
                                                "dataField": "contactNumber"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "373868e0-c928-11ea-8d76-f7e6b086de0b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "38f5f760-c928-11ea-8d76-f7e6b086de0b",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "",
                                                "label": "Add Photos",
                                                "dataField": "photos"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "bite-select"
                                    },
                                    {
                                        "id": "cf8faf20-e7f7-11ea-aa5b-4b966778e568",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "component": "TermsAndConditions",
                                        "name": "message-board"
                                    },
                                    {
                                        "id": "27ad7fc0-c92b-11ea-ad72-2b470c4935f8",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "28c00130-c92b-11ea-ad72-2b470c4935f8",
                                                "type": "button",
                                                "children": [],
                                                "text": "Submit",
                                                "fullWidth": true,
                                                "command": "save",
                                                "className": "mt-1"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-new-post",
                        "route": "/new-post/:id",
                        "_sortIndex": 360,
                        "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                    },
                    {
                        "id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "c2c20490-91b3-11ea-ba7f-61310864338b",
                                "children": [
                                    {
                                        "id": "d1894830-91b3-11ea-ba7f-61310864338b",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Notifications"
                                    },
                                    {
                                        "id": "26512330-96da-11ea-a306-e3cce4ba1daa",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "294a17e0-96da-11ea-a306-e3cce4ba1daa",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "2e1311a0-96da-11ea-a306-e3cce4ba1daa",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "327a8a70-96da-11ea-a306-e3cce4ba1daa",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "dataField": "name"
                                                    },
                                                    {
                                                        "id": "346c2140-96da-11ea-a306-e3cce4ba1daa",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "dataField": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-notifications",
                        "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                        "route": "/notifications",
                        "_sortIndex": 370
                    },
                    {
                        "id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "a259bcb1-96d2-11ea-8416-c79b465e39ab",
                                "children": [
                                    {
                                        "id": "a82b73e0-96d2-11ea-8416-c79b465e39ab",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Change password"
                                    },
                                    {
                                        "id": "d3e59b00-96d2-11ea-8416-c79b465e39ab",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "d63c5c40-96d2-11ea-8416-c79b465e39ab",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "aab74ee0-96d2-11ea-8416-c79b465e39ab",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-password",
                        "route": "/change-password",
                        "_sortIndex": 380
                    },
                    {
                        "id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "id": "2587d890-db5e-11ea-b61b-f3aa751f3b6f",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c5cddbb1-c92f-11ea-baed-470d8f727899",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "e297edd0-dc30-11ea-abb1-8b4052c10758",
                                                "type": "carousel",
                                                "children": [],
                                                "dataField": "photos",
                                                "text": "Preview",
                                                "direction": "ltr",
                                                "autoplay": false
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "main",
                                        "component": "",
                                        "flex": "1"
                                    },
                                    {
                                        "id": "3175e480-db5e-11ea-b61b-f3aa751f3b6f",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "flex": "1",
                                        "component": "MessageBoardInfo"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "856f1e40-dc39-11ea-b64f-0dfbc9206e7f",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "component": "MessageBoardComments"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-post",
                        "route": "/post/:id",
                        "_sortIndex": 390,
                        "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                        "noPadding": true
                    },
                    {
                        "id": "17466790-96d2-11ea-9995-9d94cc393546",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "0e752c01-96d2-11ea-9995-9d94cc393546",
                                "children": [
                                    {
                                        "id": "8ad561c0-96d2-11ea-8416-c79b465e39ab",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Profile"
                                    },
                                    {
                                        "id": "6beeab70-e9fe-11ea-83ad-8d1a1d915279",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "component": "AccountPhoto",
                                        "dataField": "photo"
                                    },
                                    {
                                        "id": "0bcd5e60-e9f1-11ea-847f-6170db74bd04",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c9fed520-e9f2-11ea-afe7-79ef44d4232d",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "firstName",
                                                "className": "mr-1"
                                            },
                                            {
                                                "id": "cebc0f10-e9f2-11ea-afe7-79ef44d4232d",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "middleInitial",
                                                "className": "mr-1"
                                            },
                                            {
                                                "id": "d1a5c180-e9f2-11ea-afe7-79ef44d4232d",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "lastName",
                                                "className": "mr-1"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "p-2"
                                    },
                                    {
                                        "id": "74f1d0a0-e9f7-11ea-b01f-c974ed0277bf",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7bc95f10-e9f7-11ea-b01f-c974ed0277bf",
                                                "type": "textBlock",
                                                "children": [],
                                                "className": "mr-3 profile-label",
                                                "text": "Nickname"
                                            },
                                            {
                                                "id": "88bf3e10-e9f7-11ea-b01f-c974ed0277bf",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "nickname"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "p-2"
                                    },
                                    {
                                        "id": "1e1ae290-e9f1-11ea-847f-6170db74bd04",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "1ef42b90-e9f1-11ea-847f-6170db74bd04",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "",
                                                "text": "Email",
                                                "className": "mr-3 profile-label"
                                            },
                                            {
                                                "id": "a26fba10-e9f2-11ea-afe7-79ef44d4232d",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "email"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "p-2"
                                    },
                                    {
                                        "id": "239e0490-e9f1-11ea-847f-6170db74bd04",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ab7ced30-e9f2-11ea-afe7-79ef44d4232d",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "",
                                                "text": "Account No.",
                                                "className": "mr-3 profile-label"
                                            },
                                            {
                                                "id": "dc4b9600-e9f2-11ea-afe7-79ef44d4232d",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "accountNumber"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "p-2"
                                    },
                                    {
                                        "id": "27b40200-e9f1-11ea-847f-6170db74bd04",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "2895fd90-e9f1-11ea-847f-6170db74bd04",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "",
                                                "text": "Contact",
                                                "className": "mr-3 profile-label"
                                            },
                                            {
                                                "id": "f9d429d0-e9f2-11ea-afe7-79ef44d4232d",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "contact"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "p-2"
                                    },
                                    {
                                        "id": "2b4da380-e9f1-11ea-847f-6170db74bd04",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "2cbf5bf0-e9f1-11ea-847f-6170db74bd04",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "",
                                                "text": "Units",
                                                "className": "mr-3 profile-label"
                                            },
                                            {
                                                "id": "f0c967b0-e9f2-11ea-afe7-79ef44d4232d",
                                                "type": "textBlock",
                                                "children": [],
                                                "dataField": "unit",
                                                "component": "AccountUnits"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "p-2"
                                    },
                                    {
                                        "id": "419db040-ea09-11ea-b4be-3d136e0d16ce",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "4301fb30-ea09-11ea-b4be-3d136e0d16ce",
                                                "type": "button",
                                                "children": [],
                                                "fullWidth": false,
                                                "text": "Save",
                                                "command": "save"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-profile",
                        "route": "/profile/:id",
                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                        "_sortIndex": 400
                    },
                    {
                        "id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "df79f2a1-914a-11ea-8bb4-63310f210ad3",
                                "children": [
                                    {
                                        "id": "376608f0-914b-11ea-9d03-f552f48ec793",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Unit Request"
                                    },
                                    {
                                        "id": "3e665510-914b-11ea-9d03-f552f48ec793",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "4c028630-914b-11ea-93c5-9720e806f72b",
                                                "type": "button",
                                                "children": [],
                                                "text": "View service history",
                                                "path": "/service-requests",
                                                "className": "",
                                                "variant": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "justify-content-center pt-1 pb-1",
                                        "flex": "[object Object]"
                                    },
                                    {
                                        "id": "427db210-914b-11ea-9d03-f552f48ec793",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "bf2b7640-d902-11ea-b7fc-037f001b1342",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "type",
                                                "options": "[{ \"value\": \"repairs\", \"label\": \"Repairs\"},{ \"value\": \"renovation\", \"label\": \"Renovation\"},{ \"value\": \"carSticker\", \"label\": \"Car Sticker\", data: null }]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "justify-content-center pt-2 pb-2",
                                        "name": "select"
                                    },
                                    {
                                        "id": "44bc5770-914b-11ea-9d03-f552f48ec793",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a35c3b70-c90d-11ea-8c59-196c3ec4f839",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                "name": "repairs"
                                            },
                                            {
                                                "id": "2697efe0-c911-11ea-9dd0-6723feee229e",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                "name": "renovation"
                                            },
                                            {
                                                "id": "2a9de7c0-c911-11ea-9dd0-6723feee229e",
                                                "type": "subView",
                                                "children": [],
                                                "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                "name": "carSticker"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "pt-2 pb-2",
                                        "name": "select",
                                        "component": "UnitRequest"
                                    },
                                    {
                                        "id": "2ac5bef0-ad21-11ea-8384-7b922845035e",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "316b40e0-ad21-11ea-8384-7b922845035e",
                                                "type": "button",
                                                "children": [],
                                                "command": "save",
                                                "variant": "",
                                                "text": "submit",
                                                "color": "primary",
                                                "className": "",
                                                "fullWidth": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "className": "p-1"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-service-request",
                        "route": "/create-service-request/:id",
                        "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                        "_sortIndex": 410
                    },
                    {
                        "id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "6053d8f1-91b4-11ea-ad0e-b15c87c4d0e0",
                                "children": [
                                    {
                                        "id": "bc5b9200-91b4-11ea-ad0e-b15c87c4d0e0",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Unit Request"
                                    },
                                    {
                                        "id": "39f86670-91b5-11ea-ad0e-b15c87c4d0e0",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3e960a70-91b5-11ea-ad0e-b15c87c4d0e0",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "424ad510-91b5-11ea-ad0e-b15c87c4d0e0",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "",
                                                        "dataField": "details"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "path": "/create-service-request"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "3c5ac070-91b5-11ea-ad0e-b15c87c4d0e0",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-service-requests",
                        "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                        "route": "/service-requests",
                        "_sortIndex": 420
                    },
                    {
                        "id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "d2d04d11-d902-11ea-b7fc-037f001b1342",
                                "className": "",
                                "children": [
                                    {
                                        "id": "04c0e0a0-d903-11ea-b7fc-037f001b1342",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Unit Request",
                                        "className": "has-text-centered"
                                    },
                                    {
                                        "id": "afdbb6d0-d904-11ea-8fb7-85b0ea54cdde",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "bfa2b730-d904-11ea-8fb7-85b0ea54cdde",
                                                "type": "button",
                                                "children": [],
                                                "text": "View Unit Requests History",
                                                "variant": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "justify-content-center pt-1 pb-1"
                                    },
                                    {
                                        "id": "84f0dbb0-d906-11ea-bd61-bf1e0d1003bd",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "020ce720-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "04ead9c0-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "unit",
                                                        "label": "Unit",
                                                        "dataField": "",
                                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                        "nullable": false
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "unit-container"
                                            },
                                            {
                                                "id": "21b5e9f0-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "29662520-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "saveObject": true,
                                                        "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                        "dataField": "type",
                                                        "name": "type",
                                                        "label": "Type"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "type-container"
                                            },
                                            {
                                                "id": "47ac1580-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                "name": "homeowner-unit-request-service-request"
                                            },
                                            {
                                                "id": "60137d70-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                "name": "homeowner-unit-request-plans-documents"
                                            },
                                            {
                                                "id": "71865910-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "subView",
                                                "children": [],
                                                "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                "name": "homeowner-unit-request-refund-renovation-bond"
                                            },
                                            {
                                                "id": "7c6788e0-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "subView",
                                                "children": [],
                                                "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                                "name": "homeowner-unit-request-vehicle-sticker"
                                            },
                                            {
                                                "id": "82e844c0-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "subView",
                                                "children": [],
                                                "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                                "name": "homeowner-unit-request-grease-trap-cleaning"
                                            },
                                            {
                                                "id": "30acac90-d9a7-11ea-9c8b-b7f572d43c0f",
                                                "type": "subView",
                                                "children": [],
                                                "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                                "name": "homeowner-unit-request-aircon-cleaning"
                                            },
                                            {
                                                "id": "16922b80-d9a9-11ea-9331-090047662dd2",
                                                "type": "subView",
                                                "children": [],
                                                "name": "homeowner-unit-request-others",
                                                "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "component": "UnitRequest",
                                        "name": ""
                                    },
                                    {
                                        "id": "0dd9e6c0-dac8-11ea-9725-dfc9cbd591dc",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "0f33f880-dac8-11ea-9725-dfc9cbd591dc",
                                                "type": "button",
                                                "children": [],
                                                "text": "Submit",
                                                "fullWidth": true,
                                                "className": "mt-1",
                                                "command": "save"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-request",
                        "className": "",
                        "route": "/homeowner-unit-request/:id",
                        "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                        "_sortIndex": 430
                    },
                    {
                        "id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "b3e97d21-9156-11ea-88a4-7f4a31012a76",
                                "children": [
                                    {
                                        "id": "cda662f0-9156-11ea-88a4-7f4a31012a76",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Visitor Pass",
                                        "_sortIndex": 0,
                                        "className": "has-text-centered"
                                    },
                                    {
                                        "id": "24752260-9157-11ea-88a4-7f4a31012a76",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "80f84ec0-96c2-11ea-8d81-e3df0146485b",
                                                "type": "button",
                                                "children": [],
                                                "path": "/visitor-passes",
                                                "text": "My passes",
                                                "variant": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 9,
                                        "className": "justify-content-center"
                                    },
                                    {
                                        "id": "d2c56bf0-9156-11ea-88a4-7f4a31012a76",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "65ddc8e0-da1a-11ea-b50d-8323e621ee63",
                                                "type": "inputSelect",
                                                "children": [],
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "dataField": "unit",
                                                "label": "Unit"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 10,
                                        "className": ""
                                    },
                                    {
                                        "id": "d4c90420-9156-11ea-88a4-7f4a31012a76",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "b1b7f790-da1a-11ea-b50d-8323e621ee63",
                                                "type": "inputSelect",
                                                "children": [],
                                                "dataField": "type",
                                                "label": "Type of Pass",
                                                "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 20,
                                        "className": ""
                                    },
                                    {
                                        "id": "d80f2e70-9156-11ea-88a4-7f4a31012a76",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "0ca06880-da1c-11ea-87e9-9de57275fc27",
                                                "type": "subView",
                                                "children": [],
                                                "name": "homeowner-visitor-pass-visitor-pass",
                                                "view": "824f51f0-da1b-11ea-87e9-9de57275fc27"
                                            },
                                            {
                                                "id": "f591eef0-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "subView",
                                                "children": [],
                                                "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                                "name": "homeowner-visitor-pass-short-term"
                                            },
                                            {
                                                "id": "8667e010-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "subView",
                                                "children": [],
                                                "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                                "name": "homeowner-visitor-pass-long-term"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 30,
                                        "className": "justify-content-center",
                                        "component": "VisitorPass"
                                    },
                                    {
                                        "id": "da09ed00-9156-11ea-88a4-7f4a31012a76",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "fdeeebd0-9156-11ea-88a4-7f4a31012a76",
                                                "type": "button",
                                                "children": [],
                                                "command": "save",
                                                "text": "Submit",
                                                "variant": "",
                                                "fullWidth": true,
                                                "className": "mt-1"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 40,
                                        "className": "justify-content-center"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-visitor-pass",
                        "route": "/create-visitor-pass/:id",
                        "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                        "className": "",
                        "_sortIndex": 440
                    },
                    {
                        "id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "id": "bf9e7ca0-96c5-11ea-8624-43dc05a96ae0",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c95f4850-96c5-11ea-8624-43dc05a96ae0",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Visitor Passes"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": -1
                            },
                            {
                                "name": "main",
                                "type": "container",
                                "id": "09704a71-96c1-11ea-8d81-e3df0146485b",
                                "children": [
                                    {
                                        "id": "6534ea90-96c2-11ea-8d81-e3df0146485b",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "678b84c0-96c2-11ea-8d81-e3df0146485b",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "visitor"
                                            },
                                            {
                                                "id": "6a228350-96c2-11ea-8d81-e3df0146485b",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "purpose"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "path": "/create-visitor-pass"
                                    }
                                ],
                                "_sortIndex": 0
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-visitor-passes",
                        "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                        "route": "/visitor-passes",
                        "_sortIndex": 450
                    },
                    {
                        "id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                        "type": "page",
                        "children": [
                            {
                                "id": "478f4730-c6f9-11ea-8043-0381a4f882af",
                                "type": "subView",
                                "children": [],
                                "view": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-welcome-page",
                        "route": "/welcome",
                        "component": "",
                        "_sortIndex": 460,
                        "requireAuth": false
                    },
                    {
                        "id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "dac583a1-d9ca-11ea-b444-450a6b52ef90",
                                "children": [
                                    {
                                        "id": "f6978dd0-d9ca-11ea-b444-450a6b52ef90",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Work Permit",
                                        "className": "has-text-centered"
                                    },
                                    {
                                        "id": "a2b3bab0-da09-11ea-87ea-f5980a7a1cab",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a4b07510-da09-11ea-87ea-f5980a7a1cab",
                                                "type": "button",
                                                "children": [],
                                                "text": "View Work Permit History",
                                                "variant": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "justify-content-center pt-1 pb-1"
                                    },
                                    {
                                        "id": "5ad52cd0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "607dff40-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "inputSelect",
                                                "children": [],
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "name": "unit",
                                                "label": "Unit",
                                                "dataField": "unit"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "9d05c150-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "bb60e760-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "inputSelect",
                                                "children": [],
                                                "dataField": "natureOfWork",
                                                "label": "Nature of Work",
                                                "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "d06acc70-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d1a632a0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "contractor",
                                                "label": "Name of Contractor"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "dfc94700-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "e0f19a60-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "personInCharge",
                                                "label": "Name of Person in Charge"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "ec2bbfa0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "eee8e3d0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "contactPersonInCharge",
                                                "label": "Contact Number of Person in Charge"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "4f549340-d9cc-11ea-83f3-4b9e6a8a3ce6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "12242dc0-d9cf-11ea-be94-03767312b052",
                                                "type": "inputList",
                                                "children": [],
                                                "label": "Workers",
                                                "dataField": "workers",
                                                "placeholder": "Worker"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "77fa9030-da06-11ea-8a8e-430e67178950",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7d094580-da06-11ea-8a8e-430e67178950",
                                                "type": "inputText",
                                                "children": [],
                                                "multiline": true,
                                                "dataField": "description",
                                                "label": "Description of Work"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "f2f0d5b0-da06-11ea-8a8e-430e67178950",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "fe279f90-da06-11ea-8a8e-430e67178950",
                                                "type": "inputList",
                                                "children": [],
                                                "dataField": "materials",
                                                "label": "List of Materials"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "2ebf0bc0-da07-11ea-8a8e-430e67178950",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "33285950-da07-11ea-8a8e-430e67178950",
                                                "type": "inputList",
                                                "children": [],
                                                "dataField": "tools",
                                                "label": "List of Tools"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "3bd32120-da07-11ea-8a8e-430e67178950",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3e8fd020-da07-11ea-8a8e-430e67178950",
                                                "type": "inputDate",
                                                "children": [],
                                                "dataField": "startDate",
                                                "label": "Proposed Start Date "
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "5217a910-da07-11ea-8a8e-430e67178950",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "558c3660-da07-11ea-8a8e-430e67178950",
                                                "type": "inputDate",
                                                "children": [],
                                                "label": "Target End Date",
                                                "dataField": "endDate"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "67781290-da07-11ea-8a8e-430e67178950",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "6a6f0b70-da07-11ea-8a8e-430e67178950",
                                                "type": "inputMedia",
                                                "children": [],
                                                "dataField": "plans",
                                                "label": "Photo of Plans"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "814543c0-e7f7-11ea-aa5b-4b966778e568",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "name": "work-permit",
                                        "component": "TermsAndConditions"
                                    },
                                    {
                                        "id": "3ad7b170-dac8-11ea-9725-dfc9cbd591dc",
                                        "type": "button",
                                        "children": [],
                                        "command": "save",
                                        "text": "Submit",
                                        "className": "mt-1"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-work-permit",
                        "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                        "route": "/homeowner-work-permit/:id",
                        "_sortIndex": 470
                    },
                    {
                        "id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                                "children": [
                                    {
                                        "id": "c1110200-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c1110202-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "homeowner",
                                                "label": "homeowner",
                                                "dataField": "homeowner",
                                                "saveObject": true,
                                                "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c1110203-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "22f119c0-ea54-11ea-8de6-3f209e03f86c",
                                                "type": "inputSelect",
                                                "children": [],
                                                "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"lf\", \"label\": \"LF (Looking For)\"}]",
                                                "saveObject": true,
                                                "name": "topic",
                                                "component": "",
                                                "label": "topic",
                                                "dataField": "topic"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c1110206-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c1110208-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "title",
                                                "label": "title",
                                                "dataField": "title"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c1110209-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c1112910-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "price",
                                                "label": "price",
                                                "dataField": "price"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c1112911-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c1112913-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "description",
                                                "label": "description",
                                                "dataField": "description",
                                                "multiline": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c1112914-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c1112916-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "contactNumber",
                                                "label": "contactNumber",
                                                "dataField": "contactNumber"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c1112917-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c1112919-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "photos",
                                                "label": "photos",
                                                "dataField": "photos",
                                                "basePath": "message-boards"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c111291a-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "b01b8f10-ea54-11ea-8de6-3f209e03f86c",
                                                "type": "subView",
                                                "children": [],
                                                "view": "75908760-ea54-11ea-8de6-3f209e03f86c"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c111291d-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c111291f-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "comments",
                                                "label": "comments",
                                                "dataField": "comments"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "0aa00380-ea55-11ea-8de6-3f209e03f86c",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "c10987f0-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "message-board-detail-page",
                        "route": "/message-boards/:id",
                        "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                        "_sortIndex": 750
                    },
                    {
                        "id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                                "children": [
                                    {
                                        "id": "c11b8950-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "c1217cc0-df4b-11ea-a4a7-a1e8c04129ca",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "c1283380-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "homeowner",
                                                "label": "homeowner",
                                                "dataField": "homeowner"
                                            },
                                            {
                                                "id": "c1283382-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "topic",
                                                "label": "topic",
                                                "dataField": "topic"
                                            },
                                            {
                                                "id": "c1285a90-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "title",
                                                "label": "title",
                                                "dataField": "title"
                                            },
                                            {
                                                "id": "c1285a92-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "price",
                                                "label": "price",
                                                "dataField": "price"
                                            },
                                            {
                                                "id": "c1285a96-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "contactNumber",
                                                "label": "contactNumber",
                                                "dataField": "contactNumber"
                                            },
                                            {
                                                "id": "c1285a9a-df4b-11ea-a4a7-a1e8c04129ca",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "status",
                                                "label": "status",
                                                "dataField": "status"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "message-board-list-page",
                        "route": "/message-boards-list",
                        "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                        "_sortIndex": 760
                    },
                    {
                        "id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                "children": [
                                    {
                                        "id": "a8fabff0-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a8fabff2-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "name",
                                                "label": "name",
                                                "dataField": "name"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a8fabff3-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a8fabff5-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "text",
                                                "label": "text",
                                                "dataField": "text"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a8fabff6-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a8fabff8-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "noticeStart",
                                                "label": "noticeStart",
                                                "dataField": "noticeStart",
                                                "format": "mm/dd/yyyy"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a8fabff9-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a8fabffb-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "noticeEnd",
                                                "label": "noticeEnd",
                                                "dataField": "noticeEnd",
                                                "format": "mm/dd/yyyy"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a8fabffc-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a8fabffe-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "source",
                                                "label": "source",
                                                "dataField": "source"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "a8fabfff-912e-11ea-ad57-6526d978cdf6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a8fac001-912e-11ea-ad57-6526d978cdf6",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "status",
                                                "label": "status",
                                                "dataField": "status"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "a8f7b2b0-912e-11ea-ad57-6526d978cdf6",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "notification-detail-page",
                        "route": "/notifications/:id",
                        "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                        "_sortIndex": 480
                    },
                    {
                        "id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                "children": [
                                    {
                                        "id": "a9012890-912e-11ea-ad57-6526d978cdf6",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "a9045ce0-912e-11ea-ad57-6526d978cdf6",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "a907b840-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "name",
                                                "label": "name",
                                                "dataField": "name"
                                            },
                                            {
                                                "id": "a907b842-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "text",
                                                "label": "text",
                                                "dataField": "text"
                                            },
                                            {
                                                "id": "a907b844-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "noticeStart",
                                                "label": "noticeStart",
                                                "dataField": "noticeStart"
                                            },
                                            {
                                                "id": "a907b846-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "noticeEnd",
                                                "label": "noticeEnd",
                                                "dataField": "noticeEnd"
                                            },
                                            {
                                                "id": "a907b848-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "source",
                                                "label": "source",
                                                "dataField": "source"
                                            },
                                            {
                                                "id": "a907b84a-912e-11ea-ad57-6526d978cdf6",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "status",
                                                "label": "status",
                                                "dataField": "status"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "notification-list-page",
                        "route": "/notifications-list",
                        "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                        "_sortIndex": 490
                    },
                    {
                        "id": "61855560-e82a-11ea-bffd-65b1c6e24159",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                                "children": [
                                    {
                                        "id": "61a11ac0-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "61a11ac2-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "unit",
                                                "label": "unit",
                                                "dataField": "unit",
                                                "saveObject": true,
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "61a141d0-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "f0707b30-ea53-11ea-8de6-3f209e03f86c",
                                                "type": "inputSelect",
                                                "children": [],
                                                "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                                "saveObject": true,
                                                "name": "type",
                                                "label": "type",
                                                "dataField": "type"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "61a141d3-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "61a141d5-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "schedule",
                                                "label": "schedule",
                                                "dataField": "schedule",
                                                "format": "mm/dd/yyyy"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "61a141d6-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "61a141d8-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "items",
                                                "label": "items",
                                                "dataField": "items"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "61a141d9-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "61a141db-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "company",
                                                "label": "company",
                                                "dataField": "company"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "61a141dc-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "61a141de-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "status",
                                                "label": "status",
                                                "dataField": "status"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "61a141df-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "61a141e1-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "notes",
                                                "label": "notes",
                                                "dataField": "notes",
                                                "multiline": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "619ad930-e82a-11ea-bffd-65b1c6e24159",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "pass-delivery-detail-page",
                        "route": "/pass-deliveries/:id",
                        "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84"
                    },
                    {
                        "id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                                "children": [
                                    {
                                        "id": "61ad9de0-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "61b34330-e82a-11ea-bffd-65b1c6e24159",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "61ba4810-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "unit",
                                                "label": "unit",
                                                "dataField": "unit"
                                            },
                                            {
                                                "id": "61ba4812-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "type",
                                                "label": "type",
                                                "dataField": "type"
                                            },
                                            {
                                                "id": "61ba4814-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "schedule",
                                                "label": "schedule",
                                                "dataField": "schedule"
                                            },
                                            {
                                                "id": "61ba4818-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "company",
                                                "label": "company",
                                                "dataField": "company"
                                            },
                                            {
                                                "id": "61ba6f20-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "status",
                                                "label": "status",
                                                "dataField": "status"
                                            },
                                            {
                                                "id": "61ba6f22-e82a-11ea-bffd-65b1c6e24159",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "notes",
                                                "label": "notes",
                                                "dataField": "notes"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "pass-delivery-list-page",
                        "route": "/pass-deliveries-list",
                        "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84"
                    },
                    {
                        "id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                                "children": [
                                    {
                                        "id": "3fb18ad0-df4c-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3fb18ad2-df4c-11ea-a4a7-a1e8c04129ca",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "unit",
                                                "label": "unit",
                                                "dataField": "unit",
                                                "saveObject": true,
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "3fb18ad9-df4c-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d5c38580-ea52-11ea-8de6-3f209e03f86c",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "type",
                                                "label": "type",
                                                "dataField": "type",
                                                "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "3fb18adc-df4c-11ea-a4a7-a1e8c04129ca",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "e8041160-ea52-11ea-8de6-3f209e03f86c",
                                                "type": "subView",
                                                "children": [],
                                                "view": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                                                "name": "homeowner-visitor-pass-visitor-pass"
                                            },
                                            {
                                                "id": "8b0b9a40-ea53-11ea-8de6-3f209e03f86c",
                                                "type": "subView",
                                                "children": [],
                                                "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                                "name": "homeowner-visitor-pass-long-term"
                                            },
                                            {
                                                "id": "983a1570-ea53-11ea-8de6-3f209e03f86c",
                                                "type": "subView",
                                                "children": [],
                                                "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                                "name": "homeowner-visitor-pass-short-term"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "component": "VisitorPass"
                                    },
                                    {
                                        "id": "61a2cb40-ea55-11ea-8de6-3f209e03f86c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "73c065d0-ea55-11ea-8de6-3f209e03f86c",
                                                "type": "subView",
                                                "children": [],
                                                "view": "75908760-ea54-11ea-8de6-3f209e03f86c"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "3faafb20-df4c-11ea-a4a7-a1e8c04129ca",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "pass-visitor-detail-page",
                        "route": "/pass-visitors/:id",
                        "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721"
                    },
                    {
                        "id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                                "children": [
                                    {
                                        "id": "3fbe5c10-df4c-11ea-a4a7-a1e8c04129ca",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "3fc22ca0-df4c-11ea-a4a7-a1e8c04129ca",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "3fc62440-df4c-11ea-a4a7-a1e8c04129ca",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "unit",
                                                "label": "unit",
                                                "dataField": "unit"
                                            },
                                            {
                                                "id": "3fc6244a-df4c-11ea-a4a7-a1e8c04129ca",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "type",
                                                "label": "type",
                                                "dataField": "type"
                                            },
                                            {
                                                "id": "dd3b7a00-ea4f-11ea-b967-5baa6500e444",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "label": "startDate",
                                                "dataField": "startDate"
                                            },
                                            {
                                                "id": "e479b980-ea4f-11ea-b967-5baa6500e444",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "endDate",
                                                "text": "",
                                                "label": "endDate"
                                            },
                                            {
                                                "id": "a2920620-ea55-11ea-8de6-3f209e03f86c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "status",
                                                "label": "status"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "pass-visitor-list-page",
                        "route": "/pass-visitors-list",
                        "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721"
                    },
                    {
                        "id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                                "children": [
                                    {
                                        "id": "5aa647c0-e507-11ea-8276-65e120bbe853",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "5aa647c2-e507-11ea-8276-65e120bbe853",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "name",
                                                "label": "name",
                                                "dataField": "name"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "5aa647c3-e507-11ea-8276-65e120bbe853",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "5aa66ed0-e507-11ea-8276-65e120bbe853",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "content",
                                                "label": "content",
                                                "dataField": "content",
                                                "multiline": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "5aa1daf0-e507-11ea-8276-65e120bbe853",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "terms-conditions-detail-page",
                        "route": "/terms-conditions/:id",
                        "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853"
                    },
                    {
                        "id": "5aabc600-e507-11ea-8276-65e120bbe853",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                                "children": [
                                    {
                                        "id": "5ab16b50-e507-11ea-8276-65e120bbe853",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "5ab6e990-e507-11ea-8276-65e120bbe853",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "5ababa20-e507-11ea-8276-65e120bbe853",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "name",
                                                "label": "name",
                                                "dataField": "name"
                                            },
                                            {
                                                "id": "5ababa22-e507-11ea-8276-65e120bbe853",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "content",
                                                "label": "content",
                                                "dataField": "content"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "terms-conditions-list-page",
                        "route": "/terms-conditions-list",
                        "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853"
                    },
                    {
                        "id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                "children": [
                                    {
                                        "id": "0824d8f0-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "0824d8f2-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "number",
                                                "label": "number",
                                                "dataField": "number"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "0824d8f3-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "0824d8f5-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "location",
                                                "label": "location",
                                                "dataField": "location",
                                                "multiline": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "0824d8f6-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "0c808b00-edd1-11ea-993f-a977bcf36a2d",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "type",
                                                "label": "Type",
                                                "dataField": "type"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "40a367e0-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "56825030-edd1-11ea-993f-a977bcf36a2d",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "areaSize",
                                                "label": "areaSize",
                                                "dataField": "areaSize"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "",
                                        "dataField": ""
                                    },
                                    {
                                        "id": "6208e7c0-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "68d94a40-edd1-11ea-993f-a977bcf36a2d",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "classification",
                                                "label": "classification",
                                                "dataField": "classification"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "773fa930-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "7942cc30-edd1-11ea-993f-a977bcf36a2d",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "projectCode",
                                                "name": "projectCode",
                                                "label": "projectCode"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "9dcb8510-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "b88f0570-edd1-11ea-993f-a977bcf36a2d",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "customerCode",
                                                "label": "customerCode",
                                                "dataField": "customerCode"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c24f5bf0-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d57a6ee0-edd1-11ea-993f-a977bcf36a2d",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "turnoverDate",
                                                "label": "turnoverDate",
                                                "dataField": "turnoverDate"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": ""
                                    },
                                    {
                                        "id": "f37a2e80-edd1-11ea-993f-a977bcf36a2d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "03f69c30-edd2-11ea-993f-a977bcf36a2d",
                                                "type": "inputSelect",
                                                "children": [],
                                                "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                                "label": "homeowner"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "71d63580-ef99-11ea-9d3f-8def54ed8352",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "dc08f230-ef99-11ea-9d3f-8def54ed8352",
                                                "type": "header",
                                                "children": [],
                                                "size": "4",
                                                "text": "Date of Last Service"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "e7e17a50-ef99-11ea-9d3f-8def54ed8352",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "f38277b0-ef99-11ea-9d3f-8def54ed8352",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "greaseTrapCleaning",
                                                "label": "greaseTrapCleaning",
                                                "dataField": "greaseTrapCleaning"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "dataField": "",
                                        "name": ""
                                    },
                                    {
                                        "id": "fc7ce620-ef99-11ea-9d3f-8def54ed8352",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "fe46a9a0-ef99-11ea-9d3f-8def54ed8352",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "airconCleaning",
                                                "label": "airconCleaning",
                                                "dataField": "airconCleaning"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "081fa8d0-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "unit-detail-page",
                        "route": "/units/:id",
                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                        "_sortIndex": 520
                    },
                    {
                        "id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                "children": [
                                    {
                                        "id": "08310df0-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "08341b30-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "0838fd30-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "number",
                                                "label": "number",
                                                "dataField": "number"
                                            },
                                            {
                                                "id": "0838fd32-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "address",
                                                "label": "address",
                                                "dataField": "address"
                                            },
                                            {
                                                "id": "0838fd34-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "description",
                                                "label": "description",
                                                "dataField": "description"
                                            },
                                            {
                                                "id": "0838fd36-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "homeowner",
                                                "label": "homeowner",
                                                "dataField": "homeowner"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "unit-list-page",
                        "route": "/units-list",
                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                        "_sortIndex": 530
                    },
                    {
                        "id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                                "children": [
                                    {
                                        "id": "1c2a7380-ea0d-11ea-b95d-1388b7bed108",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "558d36f0-ea51-11ea-b2b9-6908f6182fd8",
                                                "type": "inputSelect",
                                                "children": [],
                                                "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                "saveObject": true,
                                                "name": "type",
                                                "label": "type",
                                                "dataField": "type"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "09c7e540-ea50-11ea-b967-5baa6500e444",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "4648f090-ea50-11ea-b967-5baa6500e444",
                                                "type": "subView",
                                                "children": [],
                                                "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                                "name": "homeowner-unit-request-aircon-cleaning"
                                            },
                                            {
                                                "id": "4fa8fea0-ea50-11ea-b967-5baa6500e444",
                                                "type": "subView",
                                                "children": [],
                                                "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                                "name": "homeowner-unit-request-grease-trap-cleaning"
                                            },
                                            {
                                                "id": "53a42110-ea50-11ea-b967-5baa6500e444",
                                                "type": "subView",
                                                "children": [
                                                    {
                                                        "id": "1c2a9a91-ea0d-11ea-b95d-1388b7bed108",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "1c2a9a93-ea0d-11ea-b95d-1388b7bed108",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "status",
                                                                "label": "status",
                                                                "dataField": "status"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ],
                                                "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                                                "name": "homeowner-unit-request-others"
                                            },
                                            {
                                                "id": "570d15a0-ea50-11ea-b967-5baa6500e444",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                "name": "homeowner-unit-request-plans-documents"
                                            },
                                            {
                                                "id": "5c271590-ea50-11ea-b967-5baa6500e444",
                                                "type": "subView",
                                                "children": [],
                                                "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                "name": "homeowner-unit-request-refund-renovation-bond"
                                            },
                                            {
                                                "id": "60d49810-ea50-11ea-b967-5baa6500e444",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                "name": "homeowner-unit-request-service-request"
                                            },
                                            {
                                                "id": "696aed80-ea50-11ea-b967-5baa6500e444",
                                                "type": "subView",
                                                "children": [],
                                                "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                                "name": "homeowner-unit-request-vehicle-sticker"
                                            },
                                            {
                                                "id": "a3401d40-ea51-11ea-b2b9-6908f6182fd8",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ac059900-ea51-11ea-b2b9-6908f6182fd8",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                        "dataField": "unit",
                                                        "name": "unit",
                                                        "label": "Unit"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "unit-container"
                                            },
                                            {
                                                "id": "ba232f20-ea51-11ea-b2b9-6908f6182fd8",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "bdbecc70-ea51-11ea-b2b9-6908f6182fd8",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "type",
                                                        "label": "Type",
                                                        "dataField": "type",
                                                        "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "type-container"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "component": "UnitRequest"
                                    },
                                    {
                                        "id": "1c2a9a97-ea0d-11ea-b95d-1388b7bed108",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "1c2a9a99-ea0d-11ea-b95d-1388b7bed108",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "notes",
                                                "label": "notes",
                                                "dataField": "notes"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "1c254360-ea0d-11ea-b95d-1388b7bed108",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "unit-request-detail-page",
                        "route": "/unit-requests/:id",
                        "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
                    },
                    {
                        "id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                                "children": [
                                    {
                                        "id": "1c3b3c60-ea0d-11ea-b95d-1388b7bed108",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "1c4156e0-ea0d-11ea-b95d-1388b7bed108",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "1c48a9e0-ea0d-11ea-b95d-1388b7bed108",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "type",
                                                "label": "type",
                                                "dataField": "type"
                                            },
                                            {
                                                "id": "1c48a9e4-ea0d-11ea-b95d-1388b7bed108",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "unit",
                                                "label": "unit",
                                                "dataField": "unit"
                                            },
                                            {
                                                "id": "5335e730-ea56-11ea-9dc5-b194cfe3f86a",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "status",
                                                "label": "status"
                                            },
                                            {
                                                "id": "6023afe0-ea56-11ea-9dc5-b194cfe3f86a",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "dataField": "notes",
                                                "label": "notes"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "unit-request-list-page",
                        "route": "/unit-requests-list",
                        "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
                    },
                    {
                        "id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                                "children": [
                                    {
                                        "id": "47bfd670-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bfd672-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "unit",
                                                "label": "unit",
                                                "dataField": "unit",
                                                "saveObject": true,
                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bfd673-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bfd675-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "natureOfWork",
                                                "label": "natureOfWork",
                                                "dataField": "natureOfWork"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bfd676-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bfd678-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "contractor",
                                                "label": "contractor",
                                                "dataField": "contractor"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bfd679-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bfd67b-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "personInCharge",
                                                "label": "personInCharge",
                                                "dataField": "personInCharge"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bfd67c-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bffd81-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "contactPersonInCharge",
                                                "label": "contactPersonInCharge",
                                                "dataField": "contactPersonInCharge"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bffd82-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "e7ec4310-e01f-11ea-affb-21f86f398b19",
                                                "type": "inputList",
                                                "children": [],
                                                "dataField": "workers",
                                                "label": "workers",
                                                "name": "workers",
                                                "placeholder": "Worker"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bffd85-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bffd87-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "description",
                                                "label": "description",
                                                "dataField": "description",
                                                "multiline": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bffd88-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "f46db560-e01f-11ea-affb-21f86f398b19",
                                                "type": "inputList",
                                                "children": [],
                                                "dataField": "materials",
                                                "name": "materials",
                                                "label": "materials"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bffd8b-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bffd8d-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "tools",
                                                "label": "tools",
                                                "dataField": "tools"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bffd8e-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bffd90-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "startDate",
                                                "label": "startDate",
                                                "dataField": "startDate",
                                                "format": "mm/dd/yyyy"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bffd91-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bffd93-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "endDate",
                                                "label": "endDate",
                                                "dataField": "endDate",
                                                "format": "mm/dd/yyyy"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bffd94-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "47bffd96-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "plans",
                                                "label": "plans",
                                                "dataField": "plans",
                                                "basePath": "work-permits"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "47bffd97-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ce47dcf0-ea54-11ea-8de6-3f209e03f86c",
                                                "type": "subView",
                                                "children": [],
                                                "view": "75908760-ea54-11ea-8de6-3f209e03f86c"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "8bdb91a0-e828-11ea-99d9-7b0e0405473d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "8f530520-e828-11ea-99d9-7b0e0405473d",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "notes",
                                                "dataField": "notes",
                                                "label": "notes",
                                                "multiline": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ]
                            },
                            {
                                "id": "47b79910-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "subView",
                                "children": [],
                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "work-permit-detail-page",
                        "route": "/work-permits/:id",
                        "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                        "_sortIndex": 730
                    },
                    {
                        "id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
                        "type": "page",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                                "children": [
                                    {
                                        "id": "47ce7c70-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "subView",
                                        "children": [],
                                        "name": "searchbar",
                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "47d421c0-df4a-11ea-96aa-1f18fc6e6b6c",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "47d9c710-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "unit",
                                                "label": "unit",
                                                "dataField": "unit"
                                            },
                                            {
                                                "id": "47d9c712-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "natureOfWork",
                                                "label": "natureOfWork",
                                                "dataField": "natureOfWork"
                                            },
                                            {
                                                "id": "47d9c714-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "contractor",
                                                "label": "contractor",
                                                "dataField": "contractor"
                                            },
                                            {
                                                "id": "47d9c71c-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "description",
                                                "label": "description",
                                                "dataField": "description"
                                            },
                                            {
                                                "id": "47d9c722-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "startDate",
                                                "label": "startDate",
                                                "dataField": "startDate"
                                            },
                                            {
                                                "id": "47d9c724-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "endDate",
                                                "label": "endDate",
                                                "dataField": "endDate"
                                            },
                                            {
                                                "id": "47d9c728-df4a-11ea-96aa-1f18fc6e6b6c",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "status",
                                                "label": "status",
                                                "dataField": "status"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "work-permit-list-page",
                        "route": "/work-permits-list",
                        "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                        "_sortIndex": 740
                    },
                    {
                        "id": "3acc2620-e865-11ea-9def-530e14f0f646",
                        "type": "view",
                        "children": [
                            {
                                "name": "main",
                                "type": "container",
                                "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                                "children": [
                                    {
                                        "id": "3ad3ee50-e865-11ea-9def-530e14f0f646",
                                        "type": "table",
                                        "children": [
                                            {
                                                "id": "3ad7bee0-e865-11ea-9def-530e14f0f646",
                                                "type": "column",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "name": "billing",
                                                "label": "billing",
                                                "dataField": "billing"
                                            },
                                            {
                                                "id": "3ad7e5f0-e865-11ea-9def-530e14f0f646",
                                                "type": "column",
                                                "children": [
                                                    {
                                                        "id": "501d2fe0-e871-11ea-808a-85e6d8103902",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "item"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "item",
                                                "label": "item",
                                                "dataField": "item"
                                            },
                                            {
                                                "id": "3ad7e5f2-e865-11ea-9def-530e14f0f646",
                                                "type": "column",
                                                "children": [
                                                    {
                                                        "id": "5279e490-e871-11ea-808a-85e6d8103902",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "description"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "description",
                                                "label": "description",
                                                "dataField": "description"
                                            },
                                            {
                                                "id": "3ad7e5f4-e865-11ea-9def-530e14f0f646",
                                                "type": "column",
                                                "children": [
                                                    {
                                                        "id": "56685cd0-e871-11ea-808a-85e6d8103902",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "amount"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "amount",
                                                "label": "amount",
                                                "dataField": "amount"
                                            },
                                            {
                                                "id": "3ad7e5f6-e865-11ea-9def-530e14f0f646",
                                                "type": "column",
                                                "children": [
                                                    {
                                                        "id": "58cc6480-e871-11ea-808a-85e6d8103902",
                                                        "type": "inputText",
                                                        "children": []
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "state",
                                                "label": "state",
                                                "dataField": "state"
                                            },
                                            {
                                                "id": "6d0df080-e871-11ea-808a-85e6d8103902",
                                                "type": "column",
                                                "children": [
                                                    {
                                                        "id": "7088bf60-e871-11ea-808a-85e6d8103902",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "deleteItem",
                                                        "text": "delete item"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "table",
                                        "component": "",
                                        "command": "openItem"
                                    }
                                ]
                            }
                        ],
                        "orientation": "vertical",
                        "name": "billing-item-list-page",
                        "route": "/billing-items-list",
                        "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
                    },
                    {
                        "id": "84c241b0-ea09-11ea-91c2-e33bb0a096cf",
                        "type": "view",
                        "children": [
                            {
                                "id": "919381b0-ea09-11ea-91c2-e33bb0a096cf",
                                "type": "button",
                                "children": [],
                                "name": "save",
                                "command": "save_dashboard",
                                "text": "Save"
                            },
                            {
                                "id": "9cdf5a30-ea09-11ea-91c2-e33bb0a096cf",
                                "type": "button",
                                "children": [],
                                "path": "",
                                "text": "Delete",
                                "command": "delete_dashboard",
                                "name": "delete"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "commandbar-dashboard"
                    },
                    {
                        "type": "view",
                        "name": "commandbar",
                        "orientation": "horizontal",
                        "children": [
                            {
                                "name": "save",
                                "type": "button",
                                "id": "e7db5105-8c4a-11ea-9466-6f383d5eb483",
                                "command": "save"
                            },
                            {
                                "name": "delete",
                                "type": "button",
                                "id": "e7db5106-8c4a-11ea-9466-6f383d5eb483",
                                "command": "delete"
                            }
                        ],
                        "id": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                        "active": false,
                        "_sortIndex": 560
                    },
                    {
                        "id": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                        "type": "view",
                        "children": [
                            {
                                "id": "7ee9d700-8ef4-11ea-9b90-bfc3ee1ce33c",
                                "type": "menu",
                                "children": [
                                    {
                                        "id": "3cf81350-9130-11ea-af3e-29e246413619",
                                        "type": "menuItem",
                                        "children": [],
                                        "_sortIndex": 0,
                                        "path": "/",
                                        "label": "Main Menu"
                                    },
                                    {
                                        "id": "e86c29e0-912d-11ea-82bc-71c30f9d69df",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/pass-visitors-list",
                                        "label": "Visitor Passes",
                                        "_sortIndex": 70
                                    },
                                    {
                                        "id": "e6566940-912d-11ea-82bc-71c30f9d69df",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/pass-deliveries-list",
                                        "label": "Delivery / Pullout Passes",
                                        "_sortIndex": 60
                                    },
                                    {
                                        "id": "875d7be0-dd59-11ea-878d-279a484325f4",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Work Permits",
                                        "path": "/work-permits-list"
                                    },
                                    {
                                        "id": "9c3e1dc0-914c-11ea-b5d1-292f71dadca1",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/unit-requests-list",
                                        "label": "Unit Requests",
                                        "_sortIndex": 80
                                    },
                                    {
                                        "id": "1854b570-914f-11ea-b2e4-0783314da7b1",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/comments-suggestions-list",
                                        "label": "Comments & Suggestions",
                                        "_sortIndex": 19
                                    },
                                    {
                                        "id": "d72baba0-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/message-boards-list",
                                        "label": "Message Board"
                                    },
                                    {
                                        "id": "e3379d10-912d-11ea-82bc-71c30f9d69df",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/notifications-list",
                                        "label": "Notifications",
                                        "_sortIndex": 50
                                    },
                                    {
                                        "id": "7abd8be0-9138-11ea-bd78-399a56c40918",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/billings-list",
                                        "label": "Billings",
                                        "_sortIndex": 10
                                    },
                                    {
                                        "id": "e1041b40-912d-11ea-82bc-71c30f9d69df",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/homeowners-list",
                                        "label": "Homeowners",
                                        "_sortIndex": 40
                                    },
                                    {
                                        "id": "7bde5e60-dd59-11ea-878d-279a484325f4",
                                        "type": "menuItem",
                                        "children": [],
                                        "label": "Units",
                                        "path": "/units-list"
                                    },
                                    {
                                        "id": "d8c9a170-912d-11ea-82bc-71c30f9d69df",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/departments-list",
                                        "label": "Departments",
                                        "_sortIndex": 20
                                    },
                                    {
                                        "id": "df5755a0-912d-11ea-82bc-71c30f9d69df",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/employees-list",
                                        "label": "Employees",
                                        "_sortIndex": 30
                                    },
                                    {
                                        "id": "9ebd1610-e7f4-11ea-b892-0f30d25d09fd",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/terms-conditions-list",
                                        "label": "Terms and Conditions"
                                    },
                                    {
                                        "id": "fef31bf0-dfa3-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/dashboard-login",
                                        "label": "Logout"
                                    }
                                ],
                                "name": "",
                                "className": "dashboard-sidebar"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "dashboard-sidebar",
                        "active": false,
                        "_sortIndex": 570
                    },
                    {
                        "id": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf",
                        "type": "view",
                        "children": [
                            {
                                "id": "60fee2a0-dfab-11ea-a2d5-af9e56bb6ecf",
                                "type": "header",
                                "children": [],
                                "size": 2,
                                "name": "Welcome",
                                "text": "Welcome"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "dashboard-welcome"
                    },
                    {
                        "id": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                        "type": "view",
                        "children": [
                            {
                                "id": "24e082d0-8ef0-11ea-981d-018b5a72c307",
                                "type": "menu",
                                "children": [
                                    {
                                        "id": "26cac6a0-8ef0-11ea-981d-018b5a72c307",
                                        "type": "menuItem",
                                        "children": [],
                                        "name": "home",
                                        "_sortIndex": 0,
                                        "label": "Main Menu",
                                        "path": "/app"
                                    },
                                    {
                                        "id": "13a38940-912a-11ea-a5e9-ff76f0b9af84",
                                        "type": "menuItem",
                                        "children": [],
                                        "_sortIndex": 10,
                                        "name": "account",
                                        "label": "My Account",
                                        "path": "/my-account/me"
                                    },
                                    {
                                        "id": "152c2510-912a-11ea-a5e9-ff76f0b9af84",
                                        "type": "menuItem",
                                        "children": [],
                                        "_sortIndex": 20,
                                        "name": "service",
                                        "label": "Unit Request",
                                        "path": "/homeowner-unit-request/0"
                                    },
                                    {
                                        "id": "17a5feb0-912a-11ea-a5e9-ff76f0b9af84",
                                        "type": "menuItem",
                                        "children": [],
                                        "_sortIndex": 40,
                                        "label": "Visitor Pass",
                                        "name": "visitor",
                                        "path": "/create-visitor-pass/0"
                                    },
                                    {
                                        "id": "19b110f0-912a-11ea-a5e9-ff76f0b9af84",
                                        "type": "menuItem",
                                        "children": [],
                                        "_sortIndex": 50,
                                        "name": "delivery",
                                        "label": "Delivery pass",
                                        "path": "/create-delivery-pass/0"
                                    },
                                    {
                                        "id": "58256b20-c92d-11ea-8660-8b92cdcd0efe",
                                        "type": "menuItem",
                                        "children": [],
                                        "path": "/message-board",
                                        "label": "Message Board",
                                        "_sortIndex": 70
                                    },
                                    {
                                        "id": "166eb730-912a-11ea-a5e9-ff76f0b9af84",
                                        "type": "menuItem",
                                        "children": [],
                                        "_sortIndex": 30,
                                        "label": "Comments and Suggestions",
                                        "name": "complaint",
                                        "path": "/homeowner-comments-suggestions/0"
                                    },
                                    {
                                        "id": "5968bca0-d9ac-11ea-b236-955d418fe434",
                                        "type": "menuItem",
                                        "children": [],
                                        "name": "work-permit",
                                        "path": "/homeowner-work-permit/0",
                                        "label": "Work Permit"
                                    },
                                    {
                                        "id": "295939b0-8ef0-11ea-981d-018b5a72c307",
                                        "type": "menuItem",
                                        "children": [],
                                        "name": "logout",
                                        "_sortIndex": 71,
                                        "label": "Logout",
                                        "path": "/homeowners-login"
                                    }
                                ],
                                "className": "homeowner-sidebar"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-sidebar",
                        "active": false,
                        "_sortIndex": 580
                    },
                    {
                        "id": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                        "type": "view",
                        "children": [
                            {
                                "id": "d3e39f50-d9a6-11ea-891d-3722b00ad4e7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d5efea10-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "meta.units",
                                        "label": "No. of Units"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "db6aa7a0-d9a6-11ea-891d-3722b00ad4e7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e1bfdbe0-edcc-11ea-abfa-8f89576fb378",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "name": "meta.schedule",
                                        "label": "Preferred Schedule",
                                        "description": "10am-4pm, Mondays to Fridays only. Except Holidays"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "77226cd0-e7f5-11ea-b892-0f30d25d09fd",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "unit-request-aircon-cleaning",
                                "component": "TermsAndConditions"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-request-aircon-cleaning",
                        "_sortIndex": 590
                    },
                    {
                        "id": "0c744880-d9a5-11ea-a304-319701533d32",
                        "type": "view",
                        "children": [
                            {
                                "id": "1c9ddb40-d9a5-11ea-a304-319701533d32",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "fbbdc250-edcc-11ea-abfa-8f89576fb378",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "name": "meta.schedule",
                                        "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                        "label": "Preferred Schedule"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "622a6210-e7f5-11ea-b892-0f30d25d09fd",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "unit-request-grease-trap-cleaning",
                                "component": "TermsAndConditions"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-request-grease-trap-cleaning",
                        "_sortIndex": 600
                    },
                    {
                        "id": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                        "type": "view",
                        "children": [
                            {
                                "id": "fe4688f0-d9a8-11ea-9331-090047662dd2",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ff497a00-d9a8-11ea-9331-090047662dd2",
                                        "type": "inputText",
                                        "children": [],
                                        "multiline": true,
                                        "name": "meta.details",
                                        "label": "Please Specify"
                                    }
                                ],
                                "orientation": "horizontal"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-request-others",
                        "_sortIndex": 610
                    },
                    {
                        "id": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                        "type": "view",
                        "children": [
                            {
                                "id": "e85ead40-c910-11ea-9dd0-6723feee229e",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "076a4bb0-c91e-11ea-831c-99fd7b91f646",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "meta.plans",
                                        "label": "Plans Required",
                                        "options": "[{ \"value\": \"as-built\", \"label\": \"As-built Plan\"}]",
                                        "className": "bite-select",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "name": "homeowner-service-request-repairs",
                                "className": "bite-select"
                            },
                            {
                                "id": "112746d0-c91e-11ea-831c-99fd7b91f646",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "44b19ca0-d916-11ea-9ea3-7184c3941d0d",
                                        "type": "inputSelect",
                                        "children": [],
                                        "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                        "saveObject": true,
                                        "name": "meta.paper-size",
                                        "label": "Paper Size"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "97e9eee0-d916-11ea-9ea3-7184c3941d0d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9a6e28c0-d916-11ea-9ea3-7184c3941d0d",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "meta.type",
                                        "label": "Type of Plan",
                                        "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing\", \"label\": \"Plumbing\"},{ \"value\": \"electrical-plan\", \"label\": \"Electrical Plan\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "e91d7600-e7f4-11ea-b892-0f30d25d09fd",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "unit-request-plans-documents",
                                "component": "TermsAndConditions"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-request-plans-documents",
                        "_sortIndex": 620
                    },
                    {
                        "id": "063f7330-c911-11ea-9dd0-6723feee229e",
                        "type": "view",
                        "children": [
                            {
                                "id": "03ee4ee0-c91f-11ea-b910-c30a77ee18ba",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "59be4b60-d917-11ea-9ea3-7184c3941d0d",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "meta.date",
                                        "label": "Date of Bond Payment"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "1cea7cc0-c91f-11ea-b910-c30a77ee18ba",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "8dc40350-d917-11ea-9ea3-7184c3941d0d",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "meta.amount",
                                        "label": "Amount"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": ""
                            },
                            {
                                "id": "9be36e30-d917-11ea-9ea3-7184c3941d0d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9fea9e90-d917-11ea-9ea3-7184c3941d0d",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "meta.OR-no",
                                        "label": "O.R./C.R. No."
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "b9eb6b30-d917-11ea-9ea3-7184c3941d0d",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "bb01bd30-d917-11ea-9ea3-7184c3941d0d",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "meta.inspection-date",
                                        "description": "",
                                        "label": "Inspection Date"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "21c2a6b0-e7f5-11ea-b892-0f30d25d09fd",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "unit-request-refund-renovation-fund",
                                "component": "TermsAndConditions"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-request-refund-renovation-bond",
                        "_sortIndex": 630
                    },
                    {
                        "id": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                        "type": "view",
                        "children": [
                            {
                                "id": "743faf70-c90d-11ea-8c59-196c3ec4f839",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a9319f50-c911-11ea-98ab-e51558e3434e",
                                        "type": "inputSelect",
                                        "children": [],
                                        "label": "Type of Work",
                                        "name": "meta.type",
                                        "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                                        "className": "bite-select",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "bite-select"
                            },
                            {
                                "id": "c6681b80-c911-11ea-98ab-e51558e3434e",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c84dcb70-c911-11ea-98ab-e51558e3434e",
                                        "type": "inputText",
                                        "children": [],
                                        "label": "Work Details",
                                        "name": "meta.details",
                                        "multiline": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "cf0bde70-c911-11ea-98ab-e51558e3434e",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ccce1d40-edcd-11ea-993f-a977bcf36a2d",
                                        "type": "inputDateTime",
                                        "children": [],
                                        "name": "meta.schedule",
                                        "label": "Preferred Inspection/Work Schedule",
                                        "description": " Monday to Friday only. Except Holidays"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "bite-select"
                            },
                            {
                                "id": "996b7430-e65b-11ea-a30a-a9b10ee3b8be",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "component": "TermsAndConditions",
                                "name": "unit-request-service-request"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-request-service-request",
                        "_sortIndex": 640
                    },
                    {
                        "id": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                        "type": "view",
                        "children": [
                            {
                                "id": "9a02aad0-d918-11ea-b3eb-d9cf4159b042",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a6157cd0-d918-11ea-b3eb-d9cf4159b042",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "meta.parking-unit-number",
                                        "label": "Parking Unit Number"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "02e8c340-d919-11ea-b3eb-d9cf4159b042",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "25111df0-d919-11ea-b3eb-d9cf4159b042",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "meta.status",
                                        "label": "Status",
                                        "options": "[{\"label\":\"Owned\", \"value\":\"owned\"},{\"label\":\"Rented\", \"value\":\"rented\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "b33b28f0-d919-11ea-b3eb-d9cf4159b042",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b80cd540-d919-11ea-b3eb-d9cf4159b042",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "meta.make",
                                        "label": "Make",
                                        "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c394f370-d919-11ea-b3eb-d9cf4159b042",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c6827670-d919-11ea-b3eb-d9cf4159b042",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "meta.model",
                                        "label": "Model"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "ad71c600-d9a4-11ea-a304-319701533d32",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b1b90ca0-d9a4-11ea-a304-319701533d32",
                                        "type": "inputSelect",
                                        "children": [],
                                        "name": "meta.color",
                                        "label": "Color",
                                        "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                                        "saveObject": true
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "c744cbe0-d9a4-11ea-a304-319701533d32",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "cfb34e00-d9a4-11ea-a304-319701533d32",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "meta.plate-number",
                                        "label": "Plate Number"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "ddd18060-d9a4-11ea-a304-319701533d32",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "deacec40-d9a4-11ea-a304-319701533d32",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "meta.OR",
                                        "description": "",
                                        "label": "Upload O.R. / C.R."
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3fd52b00-e7f5-11ea-b892-0f30d25d09fd",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "unit-request-vehicle-sticker",
                                "component": "TermsAndConditions"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-request-vehicle-sticker",
                        "_sortIndex": 650
                    },
                    {
                        "id": "b8fe9280-c6f5-11ea-b415-c195f50f114e",
                        "type": "view",
                        "children": [
                            {
                                "id": "aca19b50-c6f9-11ea-a851-fde2cfff9ce9",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ae765b50-c6f9-11ea-a851-fde2cfff9ce9",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Select Unit"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "columns is-centered pb-1"
                            },
                            {
                                "id": "d8bbe3d0-c6f9-11ea-a851-fde2cfff9ce9",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "dde9e0f0-c6f9-11ea-a851-fde2cfff9ce9",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "029256d0-c6fa-11ea-a851-fde2cfff9ce9",
                                                "type": "button",
                                                "children": [],
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 01</div>",
                                                "className": "app-menu-item",
                                                "path": "/welcome/01"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    },
                                    {
                                        "id": "472397a0-c6fa-11ea-a851-fde2cfff9ce9",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "53985fc0-c6fa-11ea-a851-fde2cfff9ce9",
                                                "type": "button",
                                                "children": [],
                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 02</div>",
                                                "className": "app-menu-item",
                                                "path": "/welcome/02"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "app-menu-item-container"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": "columns is-centered pt-2"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-unit-selection",
                        "className": "",
                        "_sortIndex": 660
                    },
                    {
                        "id": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                        "type": "view",
                        "children": [
                            {
                                "id": "38c26740-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "3d14aa60-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputList",
                                        "children": [],
                                        "name": "visitor",
                                        "label": "Name/s",
                                        "placeholder": "Tenant"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "49eb4190-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "4b75d930-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "startDate",
                                        "label": "Start Date"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5279ced0-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "542d4b30-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "endDate",
                                        "label": "End Date"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "5ed590b0-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "603f80f0-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "visitorsId",
                                        "label": "Upload valid ID/ Passport (required)"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "6f845900-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "710098c0-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "meta.contract",
                                        "label": "Upload Contract of Lease (required)"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "3373c0d0-e816-11ea-b937-9954e083a5ee",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "visitor-pass-long-term",
                                "component": "TermsAndConditions"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-visitor-pass-long-term",
                        "_sortIndex": 670
                    },
                    {
                        "id": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                        "type": "view",
                        "children": [
                            {
                                "id": "a0ff15c0-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a2036660-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputList",
                                        "children": [],
                                        "name": "visitor",
                                        "label": "Name/s",
                                        "placeholder": "Tenant"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "bb50c180-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c70a7700-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "startDate",
                                        "label": "Start Date"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "cdb2b810-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "d233d360-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "endDate",
                                        "label": "End Date"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "e0d391d0-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "e4f28ff0-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "visitorsId",
                                        "label": "Upload Valid ID/ Passport"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "214ab490-e816-11ea-b937-9954e083a5ee",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "visitor-pass-short-term",
                                "component": "TermsAndConditions"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-visitor-pass-short-term",
                        "_sortIndex": 680
                    },
                    {
                        "id": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                        "type": "view",
                        "children": [
                            {
                                "id": "94ab5510-da1b-11ea-87e9-9de57275fc27",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "9c1bc820-da1b-11ea-87e9-9de57275fc27",
                                        "type": "inputText",
                                        "children": [],
                                        "name": "meta.relationship",
                                        "label": "Relationship to Owner"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "b7ed0f00-da1b-11ea-87e9-9de57275fc27",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "b9749960-da1b-11ea-87e9-9de57275fc27",
                                        "type": "inputList",
                                        "children": [],
                                        "name": "visitor",
                                        "description": "",
                                        "label": "Name/s",
                                        "placeholder": "Visitor"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "cb6d1fc0-da1b-11ea-87e9-9de57275fc27",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "cebbae80-da1b-11ea-87e9-9de57275fc27",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "startDate",
                                        "label": "Start Date"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "d92934f0-da1b-11ea-87e9-9de57275fc27",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "db4adc70-da1b-11ea-87e9-9de57275fc27",
                                        "type": "inputDate",
                                        "children": [],
                                        "name": "endDate",
                                        "label": "End Date"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "e6edfcb0-da1b-11ea-87e9-9de57275fc27",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "ed1bdda0-da1b-11ea-87e9-9de57275fc27",
                                        "type": "inputMedia",
                                        "children": [],
                                        "name": "visitorsId",
                                        "label": "Upload Valid ID (optional)"
                                    }
                                ],
                                "orientation": "horizontal"
                            },
                            {
                                "id": "fe8ad4d0-e815-11ea-b937-9954e083a5ee",
                                "type": "container",
                                "children": [],
                                "orientation": "horizontal",
                                "name": "visitor-pass-visitor-pass",
                                "component": "TermsAndConditions"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-visitor-pass-visitor-pass",
                        "_sortIndex": 690
                    },
                    {
                        "id": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                        "type": "view",
                        "children": [
                            {
                                "id": "29bfe9a0-97d6-11ea-a9fa-07f95ad6f0c6",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "a1eade70-8ef4-11ea-9b90-bfc3ee1ce33c",
                                        "type": "header",
                                        "children": [],
                                        "size": 2,
                                        "text": "Welcome",
                                        "_sortIndex": 0,
                                        "component": ""
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 0,
                                "className": "columns is-centered py-2"
                            },
                            {
                                "id": "78539d80-9148-11ea-a6b6-e7e287632ad5",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "c87fc130-9148-11ea-a6b6-e7e287632ad5",
                                        "type": "button",
                                        "children": [],
                                        "text": "View Notifications",
                                        "path": "/notifications",
                                        "className": "",
                                        "variant": "text"
                                    }
                                ],
                                "orientation": "horizontal",
                                "_sortIndex": 10,
                                "className": "columns is-centered p-2"
                            },
                            {
                                "id": "e7c4b510-e7fa-11ea-ba5e-d94241a9751c",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "465ece40-e7fa-11ea-ba5e-d94241a9751c",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "25f3f810-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d37f2010-97da-11ea-a9fa-07f95ad6f0c6",
                                                        "type": "button",
                                                        "children": [],
                                                        "path": "/my-account/me",
                                                        "name": "My Account",
                                                        "className": "app-menu-item",
                                                        "icon": "",
                                                        "text": "",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_account.svg\"><img src=\"./assets/images/icon_account.svg\" /></object>My Account</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "2ef5e090-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "856e1b80-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "",
                                                        "path": "/homeowner-unit-request/0",
                                                        "flex": "[object Object]",
                                                        "className": "app-menu-item",
                                                        "_sortIndex": 10,
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_unit_request.svg\"><img src=\"./assets/images/icon_unit_request.svg\" /></object>Unit Request</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "351a2490-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "874e7440-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "",
                                                        "path": "/homeowner-comments-suggestions/0",
                                                        "_sortIndex": 20,
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_complaint.svg\"><img src=\"./assets/images/icon_complaint.svg\" /></object>Comments and Suggestions</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "38053690-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [
                                                            {
                                                                "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "text": "",
                                                                        "path": "/create-visitor-pass/0",
                                                                        "_sortIndex": 30,
                                                                        "className": "app-menu-item",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            }
                                                        ],
                                                        "text": "",
                                                        "path": "/create-visitor-pass/0",
                                                        "_sortIndex": 30,
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "8ac2b370-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "",
                                                        "path": "/create-delivery-pass/0",
                                                        "_sortIndex": 40,
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_delivery.svg\"><img src=\"./assets/images/icon_delivery.svg\" /></object>Delivery Pass</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "2290a530-c921-11ea-9848-69dad3ad605b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "2b49ebf0-c921-11ea-9848-69dad3ad605b",
                                                        "type": "button",
                                                        "children": [],
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_board.svg\"><img src=\"./assets/images/icon_board.svg\" /></object>Message Board</div>",
                                                        "className": "app-menu-item",
                                                        "path": "/message-board"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "d7653ab0-e7f9-11ea-ba5e-d94241a9751c",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "e1ab6300-e7f9-11ea-ba5e-d94241a9751c",
                                                        "type": "button",
                                                        "children": [],
                                                        "path": "/homeowner-work-permit/0",
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_work_permit.svg\"><img src=\"./assets/images/icon_work_permit.svg\" /></object>Work Permit</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "5e4c0520-ea13-11ea-a21e-f569cc759e68",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "68b4a990-ea13-11ea-a21e-f569cc759e68",
                                                        "type": "button",
                                                        "children": [],
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Dummy</div>",
                                                        "path": "#"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "columns app-menus-container"
                                    }
                                ],
                                "orientation": "horizontal",
                                "className": ""
                            }
                        ],
                        "orientation": "vertical",
                        "name": "homeowner-welcome",
                        "active": false,
                        "className": "column is-centered",
                        "component": "",
                        "_sortIndex": 700
                    },
                    {
                        "id": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b",
                        "type": "view",
                        "children": [
                            {
                                "id": "28528d60-dfa8-11ea-9800-138672de1aa2",
                                "type": "container",
                                "children": [
                                    {
                                        "id": "6fbd65f0-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "712f9390-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "77274950-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": "1",
                                                        "className": "h1",
                                                        "text": "Log In"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "col"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "row"
                                    },
                                    {
                                        "id": "7d5f3c60-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "8a375a30-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9e527270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "email",
                                                        "label": "Email",
                                                        "className": "form-control login-form-input",
                                                        "variant": "standard"
                                                    },
                                                    {
                                                        "id": "b3ea9850-dfa7-11ea-9800-138672de1aa2",
                                                        "type": "image",
                                                        "children": [],
                                                        "url": "./assets/images/icon_user.svg",
                                                        "className": "login-icons"
                                                    }
                                                ],
                                                "orientation": "vertical",
                                                "className": "col"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "row mt-4"
                                    },
                                    {
                                        "id": "c896c270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "cbd9df80-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ce124350-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "password": true,
                                                        "name": "password",
                                                        "label": "Password",
                                                        "className": "form-control login-form-input",
                                                        "variant": "standard"
                                                    },
                                                    {
                                                        "id": "fcc3d880-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0bdff2e0-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "[Forgot your password?](https://www.froala.com)"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "mt-3 forgot-password-container"
                                                    },
                                                    {
                                                        "id": "c9874780-dfa7-11ea-9800-138672de1aa2",
                                                        "type": "image",
                                                        "children": [],
                                                        "url": "./assets/images/icon_login_password.svg",
                                                        "className": "login-icons"
                                                    }
                                                ],
                                                "orientation": "vertical",
                                                "className": "col"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "row mt-4"
                                    },
                                    {
                                        "id": "10d10e10-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "15b3aa50-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "17e09c70-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "local_login",
                                                        "text": "Login",
                                                        "fullWidth": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "col"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "row mt-4"
                                    }
                                ],
                                "orientation": "vertical",
                                "component": "Login"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "login-dashboard",
                        "component": ""
                    },
                    {
                        "id": "e1156440-93e9-11ea-99e4-a504a663bd48",
                        "type": "view",
                        "children": [
                            {
                                "id": "eb7f4134-93e9-11ea-99e4-a504a663bd48",
                                "type": "container",
                                "children": [
                                    {
                                        "nodeType": "DIV",
                                        "type": "container",
                                        "className": "row",
                                        "children": [
                                            {
                                                "nodeType": "DIV",
                                                "type": "container",
                                                "className": "col",
                                                "children": [
                                                    {
                                                        "nodeType": "H1",
                                                        "type": "header",
                                                        "className": "h1",
                                                        "children": [],
                                                        "text": "Log In",
                                                        "id": "eb7f4137-93e9-11ea-99e4-a504a663bd48"
                                                    }
                                                ],
                                                "id": "eb7f4136-93e9-11ea-99e4-a504a663bd48"
                                            }
                                        ],
                                        "id": "eb7f4135-93e9-11ea-99e4-a504a663bd48"
                                    },
                                    {
                                        "nodeType": "DIV",
                                        "type": "container",
                                        "className": "row mt-4",
                                        "children": [
                                            {
                                                "nodeType": "DIV",
                                                "type": "container",
                                                "className": "col",
                                                "children": [
                                                    {
                                                        "nodeType": "INPUT",
                                                        "type": "inputText",
                                                        "className": "form-control login-form-input txt-homeowner-login",
                                                        "children": [],
                                                        "placeholder": "Email",
                                                        "id": "eb7f413a-93e9-11ea-99e4-a504a663bd48",
                                                        "name": "email",
                                                        "variant": "standard"
                                                    },
                                                    {
                                                        "id": "f6061850-ad8d-11ea-a81e-a3c623c48bc9",
                                                        "type": "image",
                                                        "children": [],
                                                        "url": "./assets/images/icon_user.svg",
                                                        "className": "login-icons"
                                                    }
                                                ],
                                                "id": "eb7f4139-93e9-11ea-99e4-a504a663bd48"
                                            }
                                        ],
                                        "id": "eb7f4138-93e9-11ea-99e4-a504a663bd48"
                                    },
                                    {
                                        "nodeType": "DIV",
                                        "type": "container",
                                        "className": "row mt-3",
                                        "children": [
                                            {
                                                "nodeType": "DIV",
                                                "type": "container",
                                                "className": "col",
                                                "children": [
                                                    {
                                                        "nodeType": "INPUT",
                                                        "type": "inputText",
                                                        "className": "form-control login-form-input txt-homeowner-login",
                                                        "children": [],
                                                        "placeholder": "Password",
                                                        "id": "eb7f413d-93e9-11ea-99e4-a504a663bd48",
                                                        "name": "password",
                                                        "password": true,
                                                        "variant": "standard"
                                                    },
                                                    {
                                                        "id": "3a4be650-ad90-11ea-a81e-a3c623c48bc9",
                                                        "type": "image",
                                                        "children": [],
                                                        "url": "./assets/images/icon_login_password.svg",
                                                        "className": "login-icons"
                                                    },
                                                    {
                                                        "id": "5c31f5f0-ad92-11ea-a2a7-ad35397c4d32",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "5f285290-ad92-11ea-a2a7-ad35397c4d32",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "[Forgot your password?](https://www.froala.com)",
                                                                "_sortIndex": -1
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "mt-3 forgot-password-container",
                                                        "flex": "[object Object]"
                                                    }
                                                ],
                                                "id": "eb7f413c-93e9-11ea-99e4-a504a663bd48"
                                            }
                                        ],
                                        "id": "eb7f413b-93e9-11ea-99e4-a504a663bd48"
                                    },
                                    {
                                        "nodeType": "DIV",
                                        "type": "container",
                                        "className": "row mt-4",
                                        "children": [
                                            {
                                                "nodeType": "DIV",
                                                "type": "container",
                                                "className": "col",
                                                "children": [
                                                    {
                                                        "id": "9ceca3a0-db42-11ea-942a-259edbe0bbf2",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "portal_login",
                                                        "text": "Login",
                                                        "className": "btn-homeowner-login"
                                                    }
                                                ],
                                                "id": "eb7f4140-93e9-11ea-99e4-a504a663bd48"
                                            }
                                        ],
                                        "id": "eb7f413f-93e9-11ea-99e4-a504a663bd48"
                                    }
                                ],
                                "orientation": "vertical",
                                "nodeType": "DIV",
                                "className": "",
                                "component": "Login"
                            }
                        ],
                        "orientation": "vertical",
                        "name": "login",
                        "component": "",
                        "_sortIndex": 710
                    },
                    {
                        "type": "view",
                        "name": "searchbar",
                        "orientation": "horizontal",
                        "component": "Searchbar",
                        "children": [
                            {
                                "name": "search",
                                "type": "inputText",
                                "id": "e7db5108-8c4a-11ea-9466-6f383d5eb483"
                            },
                            {
                                "id": "e0073b50-8c64-11ea-b629-0161308cc31c",
                                "type": "button",
                                "children": [],
                                "command": "new",
                                "name": "",
                                "text": "New"
                            }
                        ],
                        "id": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                        "active": false,
                        "_sortIndex": 720
                    },
                    {
                        "id": "75908760-ea54-11ea-8de6-3f209e03f86c",
                        "type": "view",
                        "children": [
                            {
                                "id": "8a890750-ea54-11ea-8de6-3f209e03f86c",
                                "type": "inputSelect",
                                "children": [],
                                "name": "status",
                                "label": "status",
                                "options": "[{\"label\": \"Approved\", \"value\": \"approved\"}, {\"label\": \"Pending\", \"value\": \"pending\"}, {\"label\": \"Canceled\", \"value\": \"canceled\"}]",
                                "saveObject": true
                            }
                        ],
                        "orientation": "vertical",
                        "name": "status"
                    }
                ],
                "server": "pacificland.bitesizedigital.net",
                "undoLength": 241,
                "name": "pacific land - midpoint",
                "_state": {},
                "activeApp": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                "_locked": false,
                "updateKey": "323880e0-ef99-11ea-9d3f-8def54ed8352",
                "": {
                    "id": "5f30e16ddf453329f6ef693d",
                    "type": "project",
                    "routes": [
                        {
                            "page": "billing-detail-page",
                            "path": "/billings/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                                "type": "page"
                            }
                        },
                        {
                            "page": "billing-item-detail-page",
                            "path": "/billing-items/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "3ab60610-e865-11ea-9def-530e14f0f646",
                                "type": "page"
                            }
                        },
                        {
                            "page": "billing-list-page",
                            "path": "/billings-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                                "type": "page"
                            }
                        },
                        {
                            "page": "comments-suggestions-list-page",
                            "path": "/comments-suggestions-list",
                            "requireAuth": true,
                            "node": {
                                "_id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                                "type": "page"
                            }
                        },
                        {
                            "page": "dashboard-login",
                            "path": "/dashboard-login",
                            "requireAuth": false,
                            "node": {
                                "_id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
                                "type": "page"
                            }
                        },
                        {
                            "page": "department-detail-page",
                            "path": "/departments/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                                "type": "page"
                            }
                        },
                        {
                            "page": "department-list-page",
                            "path": "/departments-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                                "type": "page"
                            }
                        },
                        {
                            "page": "employee-detail-page",
                            "path": "/employees/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                                "type": "page"
                            }
                        },
                        {
                            "page": "employee-list-page",
                            "path": "/employees-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-account",
                            "path": "/my-account/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-billing",
                            "path": "/billing",
                            "requireAuth": false,
                            "node": {
                                "_id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-comments-suggestions",
                            "path": "/homeowner-comments-suggestions/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-complaint",
                            "path": "/file-complaint/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-complaints",
                            "path": "/filed-complaints",
                            "requireAuth": false,
                            "node": {
                                "_id": "47440520-971c-11ea-8a71-fd00fe88963d",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-delivery-pass",
                            "path": "/create-delivery-pass/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-delivery-passes",
                            "path": "/delivery-passes",
                            "requireAuth": false,
                            "node": {
                                "_id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-login-page",
                            "path": "/homeowners-login",
                            "layout": "full",
                            "requireAuth": false,
                            "node": {
                                "_id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-marketplace",
                            "path": "/market-place",
                            "requireAuth": false,
                            "node": {
                                "_id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-message-board",
                            "path": "/message-board",
                            "requireAuth": false,
                            "node": {
                                "_id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-new-post",
                            "path": "/new-post/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-notifications",
                            "path": "/notifications",
                            "requireAuth": false,
                            "node": {
                                "_id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-password",
                            "path": "/change-password",
                            "requireAuth": false,
                            "node": {
                                "_id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-post",
                            "path": "/post/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-profile",
                            "path": "/profile/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "17466790-96d2-11ea-9995-9d94cc393546",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-service-request",
                            "path": "/create-service-request/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-service-requests",
                            "path": "/service-requests",
                            "requireAuth": false,
                            "node": {
                                "_id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-unit-request",
                            "path": "/homeowner-unit-request/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-visitor-pass",
                            "path": "/create-visitor-pass/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-visitor-passes",
                            "path": "/visitor-passes",
                            "requireAuth": false,
                            "node": {
                                "_id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-welcome-page",
                            "path": "/welcome",
                            "requireAuth": false,
                            "node": {
                                "_id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                                "type": "page"
                            }
                        },
                        {
                            "page": "homeowner-work-permit",
                            "path": "/homeowner-work-permit/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                                "type": "page"
                            }
                        },
                        {
                            "page": "message-board-detail-page",
                            "path": "/message-boards/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "page"
                            }
                        },
                        {
                            "page": "message-board-list-page",
                            "path": "/message-boards-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
                                "type": "page"
                            }
                        },
                        {
                            "page": "notification-detail-page",
                            "path": "/notifications/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                                "type": "page"
                            }
                        },
                        {
                            "page": "notification-list-page",
                            "path": "/notifications-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                                "type": "page"
                            }
                        },
                        {
                            "page": "pass-delivery-detail-page",
                            "path": "/pass-deliveries/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "61855560-e82a-11ea-bffd-65b1c6e24159",
                                "type": "page"
                            }
                        },
                        {
                            "page": "pass-delivery-list-page",
                            "path": "/pass-deliveries-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
                                "type": "page"
                            }
                        },
                        {
                            "page": "pass-visitor-detail-page",
                            "path": "/pass-visitors/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
                                "type": "page"
                            }
                        },
                        {
                            "page": "pass-visitor-list-page",
                            "path": "/pass-visitors-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
                                "type": "page"
                            }
                        },
                        {
                            "page": "terms-conditions-detail-page",
                            "path": "/terms-conditions/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
                                "type": "page"
                            }
                        },
                        {
                            "page": "terms-conditions-list-page",
                            "path": "/terms-conditions-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "5aabc600-e507-11ea-8276-65e120bbe853",
                                "type": "page"
                            }
                        },
                        {
                            "page": "unit-detail-page",
                            "path": "/units/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "page"
                            }
                        },
                        {
                            "page": "unit-list-page",
                            "path": "/units-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "page"
                            }
                        },
                        {
                            "page": "unit-request-detail-page",
                            "path": "/unit-requests/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
                                "type": "page"
                            }
                        },
                        {
                            "page": "unit-request-list-page",
                            "path": "/unit-requests-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
                                "type": "page"
                            }
                        },
                        {
                            "page": "work-permit-detail-page",
                            "path": "/work-permits/:id",
                            "requireAuth": false,
                            "node": {
                                "_id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "page"
                            }
                        },
                        {
                            "page": "work-permit-list-page",
                            "path": "/work-permits-list",
                            "requireAuth": false,
                            "node": {
                                "_id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
                                "type": "page"
                            }
                        }
                    ],
                    "children": [
                        {
                            "id": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
                            "type": "app",
                            "name": "dashboard",
                            "sidebar": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                            "active": true,
                            "component": "",
                            "_sortIndex": 0,
                            "startPage": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf"
                        },
                        {
                            "id": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                            "type": "app",
                            "name": "homeowners",
                            "sidebar": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                            "startPage": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                            "active": false,
                            "_sortIndex": 10
                        },
                        {
                            "id": "2ad37a40-9138-11ea-bd78-399a56c40918",
                            "type": "model",
                            "children": [
                                {
                                    "id": "35bfcda0-9138-11ea-bd78-399a56c40918",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "name": "homeowner",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "3040a340-9138-11ea-bd78-399a56c40918",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "name",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "40b4ac80-9138-11ea-bd78-399a56c40918",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "description"
                                },
                                {
                                    "id": "45aeef70-9138-11ea-bd78-399a56c40918",
                                    "type": "field:number",
                                    "children": [],
                                    "dataType": "number",
                                    "name": "amount"
                                },
                                {
                                    "id": "6fa1c410-9138-11ea-bd78-399a56c40918",
                                    "type": "field:json",
                                    "children": [],
                                    "dataType": "json",
                                    "name": "details"
                                },
                                {
                                    "id": "298e0e40-e9a6-11ea-bbba-5944fe032799",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "dueDate"
                                },
                                {
                                    "id": "388318a0-e9a6-11ea-bbba-5944fe032799",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "postingDate"
                                }
                            ],
                            "name": "billing",
                            "_sortIndex": 20
                        },
                        {
                            "id": "960f7330-e864-11ea-9def-530e14f0f646",
                            "type": "model",
                            "children": [
                                {
                                    "id": "efc17c70-e864-11ea-9def-530e14f0f646",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                    "name": "billing",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "b016f690-e864-11ea-9def-530e14f0f646",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "item",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "b43e8030-e864-11ea-9def-530e14f0f646",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "description",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "b81a5ad0-e864-11ea-9def-530e14f0f646",
                                    "type": "field:number",
                                    "children": [],
                                    "dataType": "number",
                                    "name": "amount",
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "bec0ee30-e864-11ea-9def-530e14f0f646",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "state",
                                    "_sortIndex": 30
                                }
                            ],
                            "name": "billing-item"
                        },
                        {
                            "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                            "type": "model",
                            "children": [
                                {
                                    "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:enumeration",
                                    "children": [],
                                    "dataType": "enumeration",
                                    "name": "type"
                                },
                                {
                                    "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "details"
                                },
                                {
                                    "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:file",
                                    "children": [],
                                    "dataType": "file",
                                    "collection": "file",
                                    "via": "related",
                                    "allowedTypes": [
                                        "images",
                                        "files",
                                        "videos"
                                    ],
                                    "plugin": "upload",
                                    "name": "photos"
                                },
                                {
                                    "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "name": "homeowner"
                                }
                            ],
                            "name": "comments-suggestions",
                            "_sortIndex": 30
                        },
                        {
                            "id": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                            "type": "model",
                            "children": [
                                {
                                    "id": "1ba0dd50-8ce8-11ea-a283-ebcb859bf8c8",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "name",
                                    "required": true,
                                    "searchable": true
                                },
                                {
                                    "id": "22702e60-8ce8-11ea-a283-ebcb859bf8c8",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "description",
                                    "required": true
                                }
                            ],
                            "name": "department",
                            "active": false,
                            "_sortIndex": 50
                        },
                        {
                            "id": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                            "type": "model",
                            "children": [
                                {
                                    "id": "7288d810-8c4e-11ea-9466-6f383d5eb483",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "employeeid",
                                    "required": true,
                                    "searchable": true,
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "49b35e80-9129-11ea-a5e9-ff76f0b9af84",
                                    "type": "field:email",
                                    "children": [],
                                    "dataType": "email",
                                    "name": "email",
                                    "required": true,
                                    "_sortIndex": 9
                                },
                                {
                                    "id": "780a4c60-8c4e-11ea-9466-6f383d5eb483",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "firstName",
                                    "required": true,
                                    "searchable": true,
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "837ebb80-8c4e-11ea-9466-6f383d5eb483",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "lastName",
                                    "required": true,
                                    "searchable": true,
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "e68f77d0-8c91-11ea-bb0b-0b00cf6ac98c",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "birthday",
                                    "required": true,
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "c11b9d10-8e7d-11ea-810e-c1e68e180dd7",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                    "name": "department",
                                    "_sortIndex": 40
                                },
                                {
                                    "id": "5979e880-8e9e-11ea-9c0f-c7e69d58f37d",
                                    "type": "field:file",
                                    "children": [],
                                    "dataType": "file",
                                    "collection": "file",
                                    "via": "related",
                                    "allowedTypes": [
                                        "images",
                                        "files",
                                        "videos"
                                    ],
                                    "plugin": "upload",
                                    "name": "picture",
                                    "_sortIndex": 50
                                }
                            ],
                            "name": "employee",
                            "active": false,
                            "_sortIndex": 60
                        },
                        {
                            "id": "ed8defd0-911e-11ea-9e19-2d2007118721",
                            "type": "model",
                            "children": [
                                {
                                    "id": "06bec240-911f-11ea-9e19-2d2007118721",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "accountNumber",
                                    "_sortIndex": -1
                                },
                                {
                                    "id": "3f18fbb0-9129-11ea-a5e9-ff76f0b9af84",
                                    "type": "field:email",
                                    "children": [],
                                    "dataType": "email",
                                    "name": "email",
                                    "required": true,
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "f15753e0-911e-11ea-9e19-2d2007118721",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "firstName",
                                    "required": true,
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "fa966c70-911e-11ea-9e19-2d2007118721",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "middleInitial",
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "f67bdb20-911e-11ea-9e19-2d2007118721",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "lastName",
                                    "required": true,
                                    "_sortIndex": 20
                                },
                                {
                                    "id": "138b4750-911f-11ea-9e19-2d2007118721",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "contact",
                                    "_sortIndex": 70
                                },
                                {
                                    "id": "b2c46990-e9f4-11ea-9892-473a233000d1",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "nickname"
                                },
                                {
                                    "id": "f64a0d20-e9fc-11ea-bf3b-ef4612b5d21e",
                                    "type": "field:file",
                                    "children": [],
                                    "dataType": "file",
                                    "collection": "file",
                                    "via": "related",
                                    "allowedTypes": [
                                        "images",
                                        "files",
                                        "videos"
                                    ],
                                    "plugin": "upload",
                                    "name": "photo"
                                }
                            ],
                            "name": "homeowner",
                            "_sortIndex": 70
                        },
                        {
                            "id": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                            "type": "model",
                            "children": [
                                {
                                    "id": "b3aa23e0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "name": "homeowner"
                                },
                                {
                                    "id": "c20c3d10-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:enumeration",
                                    "children": [],
                                    "dataType": "enumeration",
                                    "name": "topic"
                                },
                                {
                                    "id": "d7f25150-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "title"
                                },
                                {
                                    "id": "dd8b6c50-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "price"
                                },
                                {
                                    "id": "e1c656e0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "description"
                                },
                                {
                                    "id": "eb73c1a0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "contactNumber"
                                },
                                {
                                    "id": "f1279090-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:file",
                                    "children": [],
                                    "dataType": "file",
                                    "collection": "file",
                                    "via": "related",
                                    "allowedTypes": [
                                        "images",
                                        "files",
                                        "videos"
                                    ],
                                    "plugin": "upload",
                                    "name": "photos"
                                },
                                {
                                    "id": "ff6cabe0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "status"
                                },
                                {
                                    "id": "2cdc7120-db5c-11ea-968c-6f86147884ca",
                                    "type": "field:json",
                                    "children": [],
                                    "dataType": "json",
                                    "name": "comments"
                                }
                            ],
                            "_sortIndex": 80,
                            "name": "message-board"
                        },
                        {
                            "id": "77e05b60-911e-11ea-9e19-2d2007118721",
                            "type": "model",
                            "children": [
                                {
                                    "id": "7bedcd50-911e-11ea-9e19-2d2007118721",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "name",
                                    "required": true
                                },
                                {
                                    "id": "8093aeb0-911e-11ea-9e19-2d2007118721",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "text",
                                    "required": true
                                },
                                {
                                    "id": "88181ef0-911e-11ea-9e19-2d2007118721",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "noticeStart",
                                    "required": true
                                },
                                {
                                    "id": "97459e70-911e-11ea-9e19-2d2007118721",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "noticeEnd"
                                },
                                {
                                    "id": "9eb945d0-911e-11ea-9e19-2d2007118721",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "source"
                                },
                                {
                                    "id": "a096b760-9129-11ea-a5e9-ff76f0b9af84",
                                    "type": "field",
                                    "children": [],
                                    "dataType": "string",
                                    "name": "status"
                                }
                            ],
                            "name": "notification",
                            "_sortIndex": 90
                        },
                        {
                            "id": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                            "type": "model",
                            "children": [
                                {
                                    "id": "c4081f00-d9c6-11ea-93cf-3dd7e562aac3",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "name": "unit"
                                },
                                {
                                    "id": "d7708910-d9c6-11ea-93cf-3dd7e562aac3",
                                    "type": "field:enumeration",
                                    "children": [],
                                    "dataType": "enumeration",
                                    "name": "type"
                                },
                                {
                                    "id": "e5db54d0-d9c6-11ea-93cf-3dd7e562aac3",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "schedule"
                                },
                                {
                                    "id": "ee4c9610-d9c6-11ea-93cf-3dd7e562aac3",
                                    "type": "field:json",
                                    "children": [],
                                    "dataType": "json",
                                    "name": "items"
                                },
                                {
                                    "id": "f2ed6e60-d9c6-11ea-93cf-3dd7e562aac3",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "company"
                                },
                                {
                                    "id": "00b0fda0-d9c7-11ea-93cf-3dd7e562aac3",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "status"
                                },
                                {
                                    "id": "5823d2a0-e828-11ea-99d9-7b0e0405473d",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "notes"
                                }
                            ],
                            "name": "pass-delivery",
                            "_sortIndex": 100
                        },
                        {
                            "id": "a5454c50-911e-11ea-9e19-2d2007118721",
                            "type": "model",
                            "children": [
                                {
                                    "id": "c7d2c620-d9c7-11ea-93cf-3dd7e562aac3",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "name": "unit"
                                },
                                {
                                    "id": "cfdbf6c0-d9c7-11ea-93cf-3dd7e562aac3",
                                    "type": "field:json",
                                    "children": [],
                                    "dataType": "json",
                                    "name": "visitor",
                                    "required": true
                                },
                                {
                                    "id": "fa4d2320-d9c7-11ea-93cf-3dd7e562aac3",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "startDate"
                                },
                                {
                                    "id": "ff0ec9e0-d9c7-11ea-93cf-3dd7e562aac3",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "endDate"
                                },
                                {
                                    "id": "06a73750-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "field:file",
                                    "children": [],
                                    "dataType": "file",
                                    "collection": "file",
                                    "via": "related",
                                    "allowedTypes": [
                                        "images",
                                        "files",
                                        "videos"
                                    ],
                                    "plugin": "upload",
                                    "name": "visitorsId"
                                },
                                {
                                    "id": "7b087c60-da1a-11ea-b50d-8323e621ee63",
                                    "type": "field:enumeration",
                                    "children": [],
                                    "dataType": "enumeration",
                                    "name": "type"
                                },
                                {
                                    "id": "f20d6ce0-ea54-11ea-8de6-3f209e03f86c",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "status"
                                }
                            ],
                            "name": "pass-visitor",
                            "_sortIndex": 109
                        },
                        {
                            "id": "555659e0-9138-11ea-bd78-399a56c40918",
                            "type": "model",
                            "children": [
                                {
                                    "id": "5a734000-9138-11ea-bd78-399a56c40918",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                    "name": "billing"
                                },
                                {
                                    "id": "5ecc8800-9138-11ea-bd78-399a56c40918",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "name": "homeowner"
                                },
                                {
                                    "id": "697da720-9138-11ea-bd78-399a56c40918",
                                    "type": "field:number",
                                    "children": [],
                                    "dataType": "number",
                                    "name": "amount"
                                }
                            ],
                            "name": "payment",
                            "_sortIndex": 120
                        },
                        {
                            "id": "faa20cb0-e506-11ea-8276-65e120bbe853",
                            "type": "model",
                            "children": [
                                {
                                    "id": "fe1b06d0-e506-11ea-8276-65e120bbe853",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "name"
                                },
                                {
                                    "id": "0704fa80-e507-11ea-8276-65e120bbe853",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "content"
                                }
                            ],
                            "name": "terms-conditions"
                        },
                        {
                            "id": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                            "type": "model",
                            "children": [
                                {
                                    "id": "a86f3310-d84e-11ea-acd1-c1cd20d0424a",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "number"
                                },
                                {
                                    "id": "b91aee20-d84e-11ea-acd1-c1cd20d0424a",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "address"
                                },
                                {
                                    "id": "bc50ebd0-d84e-11ea-acd1-c1cd20d0424a",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "description"
                                },
                                {
                                    "id": "c2e7a0b0-d84e-11ea-acd1-c1cd20d0424a",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "name": "homeowner",
                                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                },
                                {
                                    "id": "ae9f6c60-d9ab-11ea-b236-955d418fe434",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "status"
                                }
                            ],
                            "name": "unit",
                            "_sortIndex": 130
                        },
                        {
                            "id": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                            "type": "model",
                            "children": [
                                {
                                    "id": "ffb19150-d84d-11ea-acd1-c1cd20d0424a",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "type"
                                },
                                {
                                    "id": "09a553e0-d84e-11ea-acd1-c1cd20d0424a",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "status"
                                },
                                {
                                    "id": "125e2570-d84e-11ea-acd1-c1cd20d0424a",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "name": "unit"
                                },
                                {
                                    "id": "47a03900-e828-11ea-99d9-7b0e0405473d",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "component": "",
                                    "name": "notes"
                                }
                            ],
                            "name": "unit-request",
                            "_sortIndex": 140
                        },
                        {
                            "id": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                            "type": "model",
                            "children": [
                                {
                                    "id": "59d52f00-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:relation",
                                    "children": [],
                                    "dataType": "relation",
                                    "name": "unit",
                                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                },
                                {
                                    "id": "5fa7a980-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:enumeration",
                                    "children": [],
                                    "dataType": "enumeration",
                                    "name": "natureOfWork"
                                },
                                {
                                    "id": "680a92e0-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "contractor"
                                },
                                {
                                    "id": "6cc22780-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "personInCharge"
                                },
                                {
                                    "id": "77ba88d0-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "contactPersonInCharge"
                                },
                                {
                                    "id": "82778ed0-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:json",
                                    "children": [],
                                    "dataType": "json",
                                    "name": "workers"
                                },
                                {
                                    "id": "87d65ff0-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "description"
                                },
                                {
                                    "id": "951e5690-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:json",
                                    "children": [],
                                    "dataType": "json",
                                    "name": "materials"
                                },
                                {
                                    "id": "9a41cc60-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:json",
                                    "children": [],
                                    "dataType": "json",
                                    "name": "tools"
                                },
                                {
                                    "id": "a0da7d10-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "startDate"
                                },
                                {
                                    "id": "a5a376d0-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:date",
                                    "children": [],
                                    "dataType": "date",
                                    "format": "mm/dd/yyyy",
                                    "name": "endDate"
                                },
                                {
                                    "id": "abf13bd0-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:file",
                                    "children": [],
                                    "dataType": "file",
                                    "collection": "file",
                                    "via": "related",
                                    "allowedTypes": [
                                        "images",
                                        "files",
                                        "videos"
                                    ],
                                    "plugin": "upload",
                                    "name": "plans"
                                },
                                {
                                    "id": "afc85b80-dd5a-11ea-b1bd-f3d0d3926db1",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "status"
                                },
                                {
                                    "id": "339637c0-e828-11ea-99d9-7b0e0405473d",
                                    "type": "field:text",
                                    "children": [],
                                    "dataType": "text",
                                    "name": "notes"
                                }
                            ],
                            "name": "work-permit",
                            "_sortIndex": 150
                        },
                        {
                            "id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                    "children": [
                                        {
                                            "id": "7aa7e100-9138-11ea-bd78-399a56c40918",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7aa7e102-9138-11ea-bd78-399a56c40918",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "homeowner",
                                                    "label": "homeowner",
                                                    "dataField": "homeowner",
                                                    "saveObject": true,
                                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "7aa7e103-9138-11ea-bd78-399a56c40918",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7aa7e105-9138-11ea-bd78-399a56c40918",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "name",
                                                    "label": "name",
                                                    "dataField": "name"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "7aa7e106-9138-11ea-bd78-399a56c40918",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7aa7e108-9138-11ea-bd78-399a56c40918",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "7aa7e109-9138-11ea-bd78-399a56c40918",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7aa7e10b-9138-11ea-bd78-399a56c40918",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "amount",
                                                    "label": "amount",
                                                    "dataField": "amount"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "7aa7e10c-9138-11ea-bd78-399a56c40918",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7aa7e10e-9138-11ea-bd78-399a56c40918",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "details",
                                                    "label": "details",
                                                    "dataField": "details"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "016566c0-e9b0-11ea-8b13-df1e5e645c34",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "postingDate",
                                            "dataField": "postingDate"
                                        },
                                        {
                                            "id": "04a80ea0-e9b0-11ea-8b13-df1e5e645c34",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "dueDate",
                                            "dataField": "dueDate"
                                        }
                                    ],
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "21002eb0-e871-11ea-808a-85e6d8103902",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "2576c2b0-e871-11ea-808a-85e6d8103902",
                                            "type": "button",
                                            "children": [],
                                            "text": "Add Item",
                                            "command": "newItem",
                                            "name": "addItem"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 9
                                },
                                {
                                    "id": "22b50110-e865-11ea-9def-530e14f0f646",
                                    "type": "subView",
                                    "children": [],
                                    "_sortIndex": 10,
                                    "view": "3acc2620-e865-11ea-9def-530e14f0f646",
                                    "relation": "billing"
                                },
                                {
                                    "id": "7aa39b40-9138-11ea-bd78-399a56c40918",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                                    "_sortIndex": 20
                                }
                            ],
                            "orientation": "vertical",
                            "name": "billing-detail-page",
                            "route": "/billings/:id",
                            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                            "component": "",
                            "_sortIndex": 160
                        },
                        {
                            "id": "3ab60610-e865-11ea-9def-530e14f0f646",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                                    "children": [
                                        {
                                            "id": "3ac76b30-e865-11ea-9def-530e14f0f646",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3ac76b32-e865-11ea-9def-530e14f0f646",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "billing",
                                                    "label": "billing",
                                                    "dataField": "billing",
                                                    "saveObject": true,
                                                    "relation": "2ad37a40-9138-11ea-bd78-399a56c40918"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "3ac76b33-e865-11ea-9def-530e14f0f646",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3ac76b35-e865-11ea-9def-530e14f0f646",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "item",
                                                    "label": "item",
                                                    "dataField": "item"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "3ac76b36-e865-11ea-9def-530e14f0f646",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3ac79240-e865-11ea-9def-530e14f0f646",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "3ac79241-e865-11ea-9def-530e14f0f646",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3ac79243-e865-11ea-9def-530e14f0f646",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "amount",
                                                    "label": "amount",
                                                    "dataField": "amount"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "3ac79244-e865-11ea-9def-530e14f0f646",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3ac79246-e865-11ea-9def-530e14f0f646",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "state",
                                                    "label": "state",
                                                    "dataField": "state"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "3abf2dd0-e865-11ea-9def-530e14f0f646",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "billing-item-detail-page",
                            "route": "/billing-items/:id",
                            "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
                        },
                        {
                            "id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                    "children": [
                                        {
                                            "id": "7ab04570-9138-11ea-bd78-399a56c40918",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "7ab48b30-9138-11ea-bd78-399a56c40918",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "7ab94620-9138-11ea-bd78-399a56c40918",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "homeowner",
                                                    "label": "homeowner",
                                                    "dataField": "homeowner"
                                                },
                                                {
                                                    "id": "7ab94622-9138-11ea-bd78-399a56c40918",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "name",
                                                    "label": "name",
                                                    "dataField": "name"
                                                },
                                                {
                                                    "id": "7ab94624-9138-11ea-bd78-399a56c40918",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description"
                                                },
                                                {
                                                    "id": "7ab94626-9138-11ea-bd78-399a56c40918",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "amount",
                                                    "label": "amount",
                                                    "dataField": "amount"
                                                },
                                                {
                                                    "id": "7ab94628-9138-11ea-bd78-399a56c40918",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "details",
                                                    "label": "details",
                                                    "dataField": "details"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "billing-list-page",
                            "route": "/billings-list",
                            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                            "_sortIndex": 170
                        },
                        {
                            "id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                                    "children": [
                                        {
                                            "id": "77c369d0-dd57-11ea-9ac6-91ffe70e2d7c",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "77c824c0-dd57-11ea-9ac6-91ffe70e2d7c",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "77cd54e0-dd57-11ea-9ac6-91ffe70e2d7c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "type",
                                                    "label": "type",
                                                    "dataField": "type"
                                                },
                                                {
                                                    "id": "77cd54e2-dd57-11ea-9ac6-91ffe70e2d7c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "details",
                                                    "label": "details",
                                                    "dataField": "details"
                                                },
                                                {
                                                    "id": "77cd7bf2-dd57-11ea-9ac6-91ffe70e2d7c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "homeowner",
                                                    "label": "homeowner",
                                                    "dataField": "homeowner"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "comments-suggestions-list-page",
                            "route": "/comments-suggestions-list",
                            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                            "_sortIndex": 190,
                            "requireAuth": true
                        },
                        {
                            "id": "a4892270-dfa5-11ea-a94d-e5d6a6ca6c3b",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "a4892271-dfa5-11ea-a94d-e5d6a6ca6c3b",
                                    "children": [
                                        {
                                            "id": "b81715e0-dfa5-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "subView",
                                            "children": [],
                                            "view": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "dashboard-login",
                            "route": "/dashboard-login",
                            "component": "",
                            "noPadding": false
                        },
                        {
                            "id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                    "children": [
                                        {
                                            "id": "99dbbf60-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "99dbbf62-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "name",
                                                    "label": "Department",
                                                    "dataField": "name"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "99dbbf63-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "99dbbf65-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "description",
                                                    "label": "Description",
                                                    "dataField": "description",
                                                    "description": "",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "f68ae050-e9f4-11ea-9892-473a233000d1",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "99d8b220-912e-11ea-ad57-6526d978cdf6",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "department-detail-page",
                            "route": "/departments/:id",
                            "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                            "_sortIndex": 200
                        },
                        {
                            "id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                    "children": [
                                        {
                                            "id": "94311830-912e-11ea-ad57-6526d978cdf6",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "9433b040-912e-11ea-ad57-6526d978cdf6",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "94364850-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "name",
                                                    "label": "Name",
                                                    "dataField": "name"
                                                },
                                                {
                                                    "id": "94364852-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "description",
                                                    "label": "Description",
                                                    "dataField": "description"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "department-list-page",
                            "route": "/departments-list",
                            "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                            "_sortIndex": 210
                        },
                        {
                            "id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                    "children": [
                                        {
                                            "id": "a5a55360-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a5a55362-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "employeeid",
                                                    "label": "employeeid",
                                                    "dataField": "employeeid"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a5a55363-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a5a55365-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "email",
                                                    "label": "email",
                                                    "dataField": "email"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a5a55366-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a5a55368-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "firstName",
                                                    "label": "firstName",
                                                    "dataField": "firstName"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a5a55369-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a5a5536b-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "lastName",
                                                    "label": "lastName",
                                                    "dataField": "lastName"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a5a5536c-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a5a5536e-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "birthday",
                                                    "label": "birthday",
                                                    "dataField": "birthday",
                                                    "format": "mm/dd/yyyy"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a5a5536f-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a5a55371-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "department",
                                                    "label": "department",
                                                    "dataField": "department",
                                                    "saveObject": true,
                                                    "relation": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a5a55372-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a5a55374-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "picture",
                                                    "label": "picture",
                                                    "dataField": "picture",
                                                    "basePath": "employees"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "a5a26d30-912e-11ea-ad57-6526d978cdf6",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "employee-detail-page",
                            "route": "/employees/:id",
                            "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                            "_sortIndex": 220
                        },
                        {
                            "id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                    "children": [
                                        {
                                            "id": "a5aa8380-912e-11ea-ad57-6526d978cdf6",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "a5ae05f0-912e-11ea-ad57-6526d978cdf6",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "a5b0c510-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "employeeid",
                                                    "label": "employeeid",
                                                    "dataField": "employeeid"
                                                },
                                                {
                                                    "id": "a5b0c512-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "email",
                                                    "label": "email",
                                                    "dataField": "email"
                                                },
                                                {
                                                    "id": "a5b0c514-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "firstName",
                                                    "label": "firstName",
                                                    "dataField": "firstName"
                                                },
                                                {
                                                    "id": "a5b0c516-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "lastName",
                                                    "label": "lastName",
                                                    "dataField": "lastName"
                                                },
                                                {
                                                    "id": "a5b0c518-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "birthday",
                                                    "label": "birthday",
                                                    "dataField": "birthday"
                                                },
                                                {
                                                    "id": "a5b0c51a-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "department",
                                                    "label": "department",
                                                    "dataField": "department"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "employee-list-page",
                            "route": "/employees-list",
                            "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                            "_sortIndex": 230
                        },
                        {
                            "id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "4840fc41-9149-11ea-a6b6-e7e287632ad5",
                                    "children": [
                                        {
                                            "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "My Account",
                                            "className": "has-text-centered mt-2",
                                            "_sortIndex": 0
                                        },
                                        {
                                            "id": "707a9a30-ea14-11ea-a21e-f569cc759e68",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "75cbd6c0-ea14-11ea-a21e-f569cc759e68",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "864c1500-ea14-11ea-a21e-f569cc759e68",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_profile.svg\"><img src=\"./assets/images/icon_profile.svg\" /></object>My Profile</div>",
                                                            "path": "/profile/me"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "97a5ea60-ea14-11ea-a21e-f569cc759e68",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a301ef80-ea14-11ea-a21e-f569cc759e68",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_password.svg\"><img src=\"./assets/images/icon_password.svg\" /></object>Change Password</div>",
                                                            "path": "/change-password"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "b0937240-ea14-11ea-a21e-f569cc759e68",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "b9cc4940-ea14-11ea-a21e-f569cc759e68",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pay.svg\"><img src=\"./assets/images/icon_pay.svg\" /></object>Pay Dues</div>",
                                                            "path": "#"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "c3ebafb0-ea14-11ea-a21e-f569cc759e68",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ccf909e0-ea14-11ea-a21e-f569cc759e68",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_balance.svg\"><img src=\"./assets/images/icon_balance.svg\" /></object>View Balance</div>",
                                                            "path": "#"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menus-container"
                                        }
                                    ],
                                    "component": "Account",
                                    "className": ""
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-account",
                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                            "route": "/my-account/:id",
                            "component": "",
                            "_sortIndex": 240
                        },
                        {
                            "id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                            "type": "page",
                            "children": [
                                {
                                    "id": "62b4dc20-96d2-11ea-8416-c79b465e39ab",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6560c240-96d2-11ea-8416-c79b465e39ab",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Billing"
                                        },
                                        {
                                            "id": "71d451e0-96d2-11ea-8416-c79b465e39ab",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "7b996350-96d2-11ea-8416-c79b465e39ab",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-billing",
                            "route": "/billing",
                            "_sortIndex": 250
                        },
                        {
                            "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                    "children": [
                                        {
                                            "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Comments and Suggestions",
                                            "_sortIndex": 0,
                                            "name": "",
                                            "className": "has-text-centered"
                                        },
                                        {
                                            "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "View complaint history",
                                                    "path": "/filed-complaints",
                                                    "variant": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 9,
                                            "className": "justify-content-center"
                                        },
                                        {
                                            "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "dataField": "type",
                                                    "label": "Type of issue",
                                                    "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 10,
                                            "className": ""
                                        },
                                        {
                                            "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "details",
                                                    "label": "Details of the issue/concern",
                                                    "multiline": true,
                                                    "name": ""
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 20,
                                            "className": ""
                                        },
                                        {
                                            "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "label": "Photo",
                                                    "dataField": "photo"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 40,
                                            "className": ""
                                        },
                                        {
                                            "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "Save",
                                                    "command": "save",
                                                    "variant": "contained",
                                                    "color": "primary",
                                                    "className": "",
                                                    "fullWidth": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 50,
                                            "className": ""
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-comments-suggestions",
                            "route": "/homeowner-comments-suggestions/:id",
                            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                            "className": "",
                            "_sortIndex": 260
                        },
                        {
                            "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                    "children": [
                                        {
                                            "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Comments and Suggestions",
                                            "_sortIndex": 0,
                                            "name": "",
                                            "className": "has-text-centered"
                                        },
                                        {
                                            "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "View complaint history",
                                                    "path": "/filed-complaints",
                                                    "variant": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 9,
                                            "className": "justify-content-center"
                                        },
                                        {
                                            "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "dataField": "type",
                                                    "label": "Type of issue"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 10,
                                            "className": ""
                                        },
                                        {
                                            "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "details",
                                                    "label": "Details of the issue/concern",
                                                    "multiline": true,
                                                    "name": ""
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 20,
                                            "className": ""
                                        },
                                        {
                                            "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "label": "Photo",
                                                    "dataField": "photo"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 40,
                                            "className": ""
                                        },
                                        {
                                            "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "Save",
                                                    "command": "save",
                                                    "variant": "contained",
                                                    "color": "primary",
                                                    "className": "",
                                                    "fullWidth": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 50,
                                            "className": ""
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-complaint",
                            "route": "/file-complaint/:id",
                            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                            "className": "",
                            "_sortIndex": 270
                        },
                        {
                            "id": "47440520-971c-11ea-8a71-fd00fe88963d",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "47440521-971c-11ea-8a71-fd00fe88963d",
                                    "children": [
                                        {
                                            "id": "4b1a6180-971c-11ea-8a71-fd00fe88963d",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Complaints",
                                            "className": "has-text-centered"
                                        }
                                    ]
                                },
                                {
                                    "id": "52a624c0-971c-11ea-8a71-fd00fe88963d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "5d6c5280-971c-11ea-8a71-fd00fe88963d",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "63bf6eb0-971c-11ea-8a71-fd00fe88963d",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "item"
                                                },
                                                {
                                                    "id": "6749a6e0-971c-11ea-8a71-fd00fe88963d",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "item"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "5a577160-971c-11ea-8a71-fd00fe88963d",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal"
                                }
                            ],
                            "orientation": "vertical",
                            "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                            "route": "/filed-complaints",
                            "name": "homeowner-complaints",
                            "className": "",
                            "_sortIndex": 280
                        },
                        {
                            "id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "4e8b5d81-9157-11ea-8382-2991800d56bc",
                                    "children": [
                                        {
                                            "id": "268a56f0-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "name": "",
                                            "text": "Delivery/ Pull-out Pass"
                                        },
                                        {
                                            "id": "3cd79170-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "40347810-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "View History",
                                                    "variant": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "justify-content-center pt-1 pb-1"
                                        },
                                        {
                                            "id": "590cca40-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "5f544db0-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                    "dataField": "unit",
                                                    "label": "Unit"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "66e4a4d0-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "6b30cd70-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "dataField": "type",
                                                    "label": "Type of Pass",
                                                    "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "7d085e50-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d3f88870-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "inputDateTime",
                                                    "children": [],
                                                    "dataField": "schedule",
                                                    "label": "Schedule"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "87486c20-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "8c652b30-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "label": "Item(s) - Please enumerate including quantities.",
                                                    "dataField": "items"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a733cf20-da0a-11ea-87ea-f5980a7a1cab",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ac4da800-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "company",
                                                    "label": "Delivery Company"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a788cc00-e7f7-11ea-aa5b-4b966778e568",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "component": "TermsAndConditions",
                                            "name": "delivery-pass"
                                        },
                                        {
                                            "id": "df2a4d60-dac7-11ea-9725-dfc9cbd591dc",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "e07a7410-dac7-11ea-9725-dfc9cbd591dc",
                                                    "type": "button",
                                                    "children": [],
                                                    "command": "save",
                                                    "text": "Submit",
                                                    "fullWidth": true,
                                                    "className": "mt-1"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-delivery-pass",
                            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                            "route": "/create-delivery-pass/:id",
                            "className": "",
                            "_sortIndex": 290
                        },
                        {
                            "id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "db3a70c1-96c7-11ea-bb44-c34ef5a44500",
                                    "children": [
                                        {
                                            "id": "e5006c90-96c7-11ea-bb44-c34ef5a44500",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Delivery Passes"
                                        },
                                        {
                                            "id": "e8824050-96c7-11ea-bb44-c34ef5a44500",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "f0fafba0-96c7-11ea-bb44-c34ef5a44500",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "ae53bb10-96c8-11ea-bb44-c34ef5a44500",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "dataField": "courier"
                                                        },
                                                        {
                                                            "id": "b91bbd90-96c8-11ea-bb44-c34ef5a44500",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "dataField": "items"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "path": "/create-delivery-pass"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "ed6551c0-96c7-11ea-bb44-c34ef5a44500",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-delivery-passes",
                            "route": "/delivery-passes",
                            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                            "_sortIndex": 300
                        },
                        {
                            "id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "0ad19881-93ea-11ea-99e4-a504a663bd48"
                                },
                                {
                                    "id": "1acacc70-93ea-11ea-9384-d1e4789a27a9",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e1156440-93e9-11ea-99e4-a504a663bd48"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-login-page",
                            "component": "",
                            "route": "/homeowners-login",
                            "_sortIndex": 330,
                            "layout": "full"
                        },
                        {
                            "id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "63850e21-a55c-11ea-8feb-c7034be1bc66",
                                    "children": [
                                        {
                                            "id": "da5a80c0-a55c-11ea-99ce-d1e75946b32d",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Marketplace"
                                        },
                                        {
                                            "id": "ec182100-a55c-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "f232a100-a55c-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "07f88310-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "text": "",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_water.svg\"><img src=\"./assets/images/icon_water.svg\" /></object>Drinking Water</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "014dd100-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "26cecc90-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_gas.svg\"><img src=\"./assets/images/icon_gas.svg\" /></object>Gasul</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "columns is-centered mt-2"
                                        },
                                        {
                                            "id": "b1d7e880-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "494d3680-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "516b0090-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Rice</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "63098010-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "69f72e90-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_laundry.svg\"><img src=\"./assets/images/icon_laundry.svg\" /></object>Laundry</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "columns is-centered"
                                        },
                                        {
                                            "id": "c6888a00-a55d-11ea-99ce-d1e75946b32d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ccd51680-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "d4ae5d80-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "button",
                                                            "children": [],
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pet.svg\"><img src=\"./assets/images/icon_pet.svg\" /></object>Pet Grooming</div>",
                                                            "className": "app-menu-item"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "f062cd90-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "f2893000-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_cleaning.svg\"><img src=\"./assets/images/icon_cleaning.svg\" /></object>Cleaning Service</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "columns is-centered"
                                        }
                                    ],
                                    "className": "has-text-centered",
                                    "_sortIndex": -1
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-marketplace",
                            "route": "/market-place",
                            "_sortIndex": 340
                        },
                        {
                            "id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "231003d1-c920-11ea-9080-1563a0e60ff0",
                                    "className": "p1",
                                    "children": [
                                        {
                                            "id": "709bda20-c920-11ea-9080-1563a0e60ff0",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Message Board",
                                            "className": "has-text-centered"
                                        },
                                        {
                                            "id": "53470b10-c921-11ea-84bc-97bd01295e5b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "566450a0-c921-11ea-84bc-97bd01295e5b",
                                                    "type": "button",
                                                    "children": [],
                                                    "variant": "text",
                                                    "text": "New Post",
                                                    "path": "/new-post/0"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "justify-content-center pt-1 pb-1"
                                        },
                                        {
                                            "id": "5b0277e0-db4b-11ea-bdb5-fdc0741685fd",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "component": "MessageBoard"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-message-board",
                            "route": "/message-board",
                            "_sortIndex": 350,
                            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                        },
                        {
                            "id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "233b7c71-c927-11ea-84bc-97bd01295e5b",
                                    "children": [
                                        {
                                            "id": "521b2680-c927-11ea-84bc-97bd01295e5b",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "New Post",
                                            "className": "has-text-centered"
                                        },
                                        {
                                            "id": "59e133a0-c927-11ea-84bc-97bd01295e5b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "62ac7bc0-c927-11ea-84bc-97bd01295e5b",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "",
                                                    "label": "Post Topic",
                                                    "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"lf\", \"label\": \"LF (Looking For)\"}]",
                                                    "dataField": "topic"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "bite-select pt-2"
                                        },
                                        {
                                            "id": "df291320-c927-11ea-8d76-f7e6b086de0b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "e2116600-c927-11ea-8d76-f7e6b086de0b",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "",
                                                    "label": "Title",
                                                    "dataField": "title"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "eb70b0c0-c927-11ea-8d76-f7e6b086de0b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ed3236e0-c927-11ea-8d76-f7e6b086de0b",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "",
                                                    "label": "Price",
                                                    "dataField": "price"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "f42897f0-c927-11ea-8d76-f7e6b086de0b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "f98df8c0-c927-11ea-8d76-f7e6b086de0b",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "",
                                                    "label": "Description",
                                                    "multiline": true,
                                                    "dataField": "description"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "0b6fc2d0-c928-11ea-8d76-f7e6b086de0b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "0d2784f0-c928-11ea-8d76-f7e6b086de0b",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "",
                                                    "label": "Contact Number",
                                                    "dataField": "contactNumber"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "373868e0-c928-11ea-8d76-f7e6b086de0b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "38f5f760-c928-11ea-8d76-f7e6b086de0b",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "",
                                                    "label": "Add Photos",
                                                    "dataField": "photos"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "bite-select"
                                        },
                                        {
                                            "id": "cf8faf20-e7f7-11ea-aa5b-4b966778e568",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "component": "TermsAndConditions",
                                            "name": "message-board"
                                        },
                                        {
                                            "id": "27ad7fc0-c92b-11ea-ad72-2b470c4935f8",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "28c00130-c92b-11ea-ad72-2b470c4935f8",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "Submit",
                                                    "fullWidth": true,
                                                    "command": "save",
                                                    "className": "mt-1"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-new-post",
                            "route": "/new-post/:id",
                            "_sortIndex": 360,
                            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                        },
                        {
                            "id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "c2c20490-91b3-11ea-ba7f-61310864338b",
                                    "children": [
                                        {
                                            "id": "d1894830-91b3-11ea-ba7f-61310864338b",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Notifications"
                                        },
                                        {
                                            "id": "26512330-96da-11ea-a306-e3cce4ba1daa",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "294a17e0-96da-11ea-a306-e3cce4ba1daa",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "2e1311a0-96da-11ea-a306-e3cce4ba1daa",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "327a8a70-96da-11ea-a306-e3cce4ba1daa",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "dataField": "name"
                                                        },
                                                        {
                                                            "id": "346c2140-96da-11ea-a306-e3cce4ba1daa",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "dataField": "text"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-notifications",
                            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                            "route": "/notifications",
                            "_sortIndex": 370
                        },
                        {
                            "id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "a259bcb1-96d2-11ea-8416-c79b465e39ab",
                                    "children": [
                                        {
                                            "id": "a82b73e0-96d2-11ea-8416-c79b465e39ab",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Change password"
                                        },
                                        {
                                            "id": "d3e59b00-96d2-11ea-8416-c79b465e39ab",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "d63c5c40-96d2-11ea-8416-c79b465e39ab",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "aab74ee0-96d2-11ea-8416-c79b465e39ab",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-password",
                            "route": "/change-password",
                            "_sortIndex": 380
                        },
                        {
                            "id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "id": "2587d890-db5e-11ea-b61b-f3aa751f3b6f",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c5cddbb1-c92f-11ea-baed-470d8f727899",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "e297edd0-dc30-11ea-abb1-8b4052c10758",
                                                    "type": "carousel",
                                                    "children": [],
                                                    "dataField": "photos",
                                                    "text": "Preview",
                                                    "direction": "ltr",
                                                    "autoplay": false
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "main",
                                            "component": "",
                                            "flex": "1"
                                        },
                                        {
                                            "id": "3175e480-db5e-11ea-b61b-f3aa751f3b6f",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "flex": "1",
                                            "component": "MessageBoardInfo"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "856f1e40-dc39-11ea-b64f-0dfbc9206e7f",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "component": "MessageBoardComments"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-post",
                            "route": "/post/:id",
                            "_sortIndex": 390,
                            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                            "noPadding": true
                        },
                        {
                            "id": "17466790-96d2-11ea-9995-9d94cc393546",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "0e752c01-96d2-11ea-9995-9d94cc393546",
                                    "children": [
                                        {
                                            "id": "8ad561c0-96d2-11ea-8416-c79b465e39ab",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Profile"
                                        },
                                        {
                                            "id": "6beeab70-e9fe-11ea-83ad-8d1a1d915279",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "component": "AccountPhoto",
                                            "dataField": "photo"
                                        },
                                        {
                                            "id": "0bcd5e60-e9f1-11ea-847f-6170db74bd04",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c9fed520-e9f2-11ea-afe7-79ef44d4232d",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "firstName",
                                                    "className": "mr-1"
                                                },
                                                {
                                                    "id": "cebc0f10-e9f2-11ea-afe7-79ef44d4232d",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "middleInitial",
                                                    "className": "mr-1"
                                                },
                                                {
                                                    "id": "d1a5c180-e9f2-11ea-afe7-79ef44d4232d",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "lastName",
                                                    "className": "mr-1"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "p-2"
                                        },
                                        {
                                            "id": "74f1d0a0-e9f7-11ea-b01f-c974ed0277bf",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7bc95f10-e9f7-11ea-b01f-c974ed0277bf",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "className": "mr-3 profile-label",
                                                    "text": "Nickname"
                                                },
                                                {
                                                    "id": "88bf3e10-e9f7-11ea-b01f-c974ed0277bf",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "nickname"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "p-2"
                                        },
                                        {
                                            "id": "1e1ae290-e9f1-11ea-847f-6170db74bd04",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "1ef42b90-e9f1-11ea-847f-6170db74bd04",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "",
                                                    "text": "Email",
                                                    "className": "mr-3 profile-label"
                                                },
                                                {
                                                    "id": "a26fba10-e9f2-11ea-afe7-79ef44d4232d",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "email"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "p-2"
                                        },
                                        {
                                            "id": "239e0490-e9f1-11ea-847f-6170db74bd04",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ab7ced30-e9f2-11ea-afe7-79ef44d4232d",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "",
                                                    "text": "Account No.",
                                                    "className": "mr-3 profile-label"
                                                },
                                                {
                                                    "id": "dc4b9600-e9f2-11ea-afe7-79ef44d4232d",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "accountNumber"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "p-2"
                                        },
                                        {
                                            "id": "27b40200-e9f1-11ea-847f-6170db74bd04",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "2895fd90-e9f1-11ea-847f-6170db74bd04",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "",
                                                    "text": "Contact",
                                                    "className": "mr-3 profile-label"
                                                },
                                                {
                                                    "id": "f9d429d0-e9f2-11ea-afe7-79ef44d4232d",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "contact"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "p-2"
                                        },
                                        {
                                            "id": "2b4da380-e9f1-11ea-847f-6170db74bd04",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "2cbf5bf0-e9f1-11ea-847f-6170db74bd04",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "",
                                                    "text": "Units",
                                                    "className": "mr-3 profile-label"
                                                },
                                                {
                                                    "id": "f0c967b0-e9f2-11ea-afe7-79ef44d4232d",
                                                    "type": "textBlock",
                                                    "children": [],
                                                    "dataField": "unit",
                                                    "component": "AccountUnits"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "p-2"
                                        },
                                        {
                                            "id": "419db040-ea09-11ea-b4be-3d136e0d16ce",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "4301fb30-ea09-11ea-b4be-3d136e0d16ce",
                                                    "type": "button",
                                                    "children": [],
                                                    "fullWidth": false,
                                                    "text": "Save",
                                                    "command": "save"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-profile",
                            "route": "/profile/:id",
                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                            "_sortIndex": 400
                        },
                        {
                            "id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "df79f2a1-914a-11ea-8bb4-63310f210ad3",
                                    "children": [
                                        {
                                            "id": "376608f0-914b-11ea-9d03-f552f48ec793",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Unit Request"
                                        },
                                        {
                                            "id": "3e665510-914b-11ea-9d03-f552f48ec793",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "4c028630-914b-11ea-93c5-9720e806f72b",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "View service history",
                                                    "path": "/service-requests",
                                                    "className": "",
                                                    "variant": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "justify-content-center pt-1 pb-1",
                                            "flex": "[object Object]"
                                        },
                                        {
                                            "id": "427db210-914b-11ea-9d03-f552f48ec793",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "bf2b7640-d902-11ea-b7fc-037f001b1342",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "type",
                                                    "options": "[{ \"value\": \"repairs\", \"label\": \"Repairs\"},{ \"value\": \"renovation\", \"label\": \"Renovation\"},{ \"value\": \"carSticker\", \"label\": \"Car Sticker\", data: null }]",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "justify-content-center pt-2 pb-2",
                                            "name": "select"
                                        },
                                        {
                                            "id": "44bc5770-914b-11ea-9d03-f552f48ec793",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a35c3b70-c90d-11ea-8c59-196c3ec4f839",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                    "name": "repairs"
                                                },
                                                {
                                                    "id": "2697efe0-c911-11ea-9dd0-6723feee229e",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                    "name": "renovation"
                                                },
                                                {
                                                    "id": "2a9de7c0-c911-11ea-9dd0-6723feee229e",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                    "name": "carSticker"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "pt-2 pb-2",
                                            "name": "select",
                                            "component": "UnitRequest"
                                        },
                                        {
                                            "id": "2ac5bef0-ad21-11ea-8384-7b922845035e",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "316b40e0-ad21-11ea-8384-7b922845035e",
                                                    "type": "button",
                                                    "children": [],
                                                    "command": "save",
                                                    "variant": "",
                                                    "text": "submit",
                                                    "color": "primary",
                                                    "className": "",
                                                    "fullWidth": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "className": "p-1"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-service-request",
                            "route": "/create-service-request/:id",
                            "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                            "_sortIndex": 410
                        },
                        {
                            "id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "6053d8f1-91b4-11ea-ad0e-b15c87c4d0e0",
                                    "children": [
                                        {
                                            "id": "bc5b9200-91b4-11ea-ad0e-b15c87c4d0e0",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Unit Request"
                                        },
                                        {
                                            "id": "39f86670-91b5-11ea-ad0e-b15c87c4d0e0",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3e960a70-91b5-11ea-ad0e-b15c87c4d0e0",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "424ad510-91b5-11ea-ad0e-b15c87c4d0e0",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "",
                                                            "dataField": "details"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "path": "/create-service-request"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "3c5ac070-91b5-11ea-ad0e-b15c87c4d0e0",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-service-requests",
                            "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                            "route": "/service-requests",
                            "_sortIndex": 420
                        },
                        {
                            "id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "d2d04d11-d902-11ea-b7fc-037f001b1342",
                                    "className": "",
                                    "children": [
                                        {
                                            "id": "04c0e0a0-d903-11ea-b7fc-037f001b1342",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Unit Request",
                                            "className": "has-text-centered"
                                        },
                                        {
                                            "id": "afdbb6d0-d904-11ea-8fb7-85b0ea54cdde",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "bfa2b730-d904-11ea-8fb7-85b0ea54cdde",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "View Unit Requests History",
                                                    "variant": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "justify-content-center pt-1 pb-1"
                                        },
                                        {
                                            "id": "84f0dbb0-d906-11ea-bd61-bf1e0d1003bd",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "020ce720-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "04ead9c0-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "name": "unit",
                                                            "label": "Unit",
                                                            "dataField": "",
                                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                            "nullable": false
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "unit-container"
                                                },
                                                {
                                                    "id": "21b5e9f0-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "29662520-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "saveObject": true,
                                                            "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                            "dataField": "type",
                                                            "name": "type",
                                                            "label": "Type"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "type-container"
                                                },
                                                {
                                                    "id": "47ac1580-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                    "name": "homeowner-unit-request-service-request"
                                                },
                                                {
                                                    "id": "60137d70-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                    "name": "homeowner-unit-request-plans-documents"
                                                },
                                                {
                                                    "id": "71865910-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                    "name": "homeowner-unit-request-refund-renovation-bond"
                                                },
                                                {
                                                    "id": "7c6788e0-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                                    "name": "homeowner-unit-request-vehicle-sticker"
                                                },
                                                {
                                                    "id": "82e844c0-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                                    "name": "homeowner-unit-request-grease-trap-cleaning"
                                                },
                                                {
                                                    "id": "30acac90-d9a7-11ea-9c8b-b7f572d43c0f",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                                    "name": "homeowner-unit-request-aircon-cleaning"
                                                },
                                                {
                                                    "id": "16922b80-d9a9-11ea-9331-090047662dd2",
                                                    "type": "subView",
                                                    "children": [],
                                                    "name": "homeowner-unit-request-others",
                                                    "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "component": "UnitRequest",
                                            "name": ""
                                        },
                                        {
                                            "id": "0dd9e6c0-dac8-11ea-9725-dfc9cbd591dc",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "0f33f880-dac8-11ea-9725-dfc9cbd591dc",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "Submit",
                                                    "fullWidth": true,
                                                    "className": "mt-1",
                                                    "command": "save"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-request",
                            "className": "",
                            "route": "/homeowner-unit-request/:id",
                            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                            "_sortIndex": 430
                        },
                        {
                            "id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "b3e97d21-9156-11ea-88a4-7f4a31012a76",
                                    "children": [
                                        {
                                            "id": "cda662f0-9156-11ea-88a4-7f4a31012a76",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Visitor Pass",
                                            "_sortIndex": 0,
                                            "className": "has-text-centered"
                                        },
                                        {
                                            "id": "24752260-9157-11ea-88a4-7f4a31012a76",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "80f84ec0-96c2-11ea-8d81-e3df0146485b",
                                                    "type": "button",
                                                    "children": [],
                                                    "path": "/visitor-passes",
                                                    "text": "My passes",
                                                    "variant": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 9,
                                            "className": "justify-content-center"
                                        },
                                        {
                                            "id": "d2c56bf0-9156-11ea-88a4-7f4a31012a76",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "65ddc8e0-da1a-11ea-b50d-8323e621ee63",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                    "dataField": "unit",
                                                    "label": "Unit"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 10,
                                            "className": ""
                                        },
                                        {
                                            "id": "d4c90420-9156-11ea-88a4-7f4a31012a76",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "b1b7f790-da1a-11ea-b50d-8323e621ee63",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "dataField": "type",
                                                    "label": "Type of Pass",
                                                    "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 20,
                                            "className": ""
                                        },
                                        {
                                            "id": "d80f2e70-9156-11ea-88a4-7f4a31012a76",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "0ca06880-da1c-11ea-87e9-9de57275fc27",
                                                    "type": "subView",
                                                    "children": [],
                                                    "name": "homeowner-visitor-pass-visitor-pass",
                                                    "view": "824f51f0-da1b-11ea-87e9-9de57275fc27"
                                                },
                                                {
                                                    "id": "f591eef0-da1d-11ea-abba-db7aa6b9e7d7",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                                    "name": "homeowner-visitor-pass-short-term"
                                                },
                                                {
                                                    "id": "8667e010-da1e-11ea-abba-db7aa6b9e7d7",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                                    "name": "homeowner-visitor-pass-long-term"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 30,
                                            "className": "justify-content-center",
                                            "component": "VisitorPass"
                                        },
                                        {
                                            "id": "da09ed00-9156-11ea-88a4-7f4a31012a76",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "fdeeebd0-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "button",
                                                    "children": [],
                                                    "command": "save",
                                                    "text": "Submit",
                                                    "variant": "",
                                                    "fullWidth": true,
                                                    "className": "mt-1"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 40,
                                            "className": "justify-content-center"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-visitor-pass",
                            "route": "/create-visitor-pass/:id",
                            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                            "className": "",
                            "_sortIndex": 440
                        },
                        {
                            "id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "id": "bf9e7ca0-96c5-11ea-8624-43dc05a96ae0",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c95f4850-96c5-11ea-8624-43dc05a96ae0",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Visitor Passes"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": -1
                                },
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "09704a71-96c1-11ea-8d81-e3df0146485b",
                                    "children": [
                                        {
                                            "id": "6534ea90-96c2-11ea-8d81-e3df0146485b",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "678b84c0-96c2-11ea-8d81-e3df0146485b",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "visitor"
                                                },
                                                {
                                                    "id": "6a228350-96c2-11ea-8d81-e3df0146485b",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "purpose"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "path": "/create-visitor-pass"
                                        }
                                    ],
                                    "_sortIndex": 0
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-visitor-passes",
                            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                            "route": "/visitor-passes",
                            "_sortIndex": 450
                        },
                        {
                            "id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                            "type": "page",
                            "children": [
                                {
                                    "id": "478f4730-c6f9-11ea-8043-0381a4f882af",
                                    "type": "subView",
                                    "children": [],
                                    "view": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-welcome-page",
                            "route": "/welcome",
                            "component": "",
                            "_sortIndex": 460,
                            "requireAuth": false
                        },
                        {
                            "id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "dac583a1-d9ca-11ea-b444-450a6b52ef90",
                                    "children": [
                                        {
                                            "id": "f6978dd0-d9ca-11ea-b444-450a6b52ef90",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Work Permit",
                                            "className": "has-text-centered"
                                        },
                                        {
                                            "id": "a2b3bab0-da09-11ea-87ea-f5980a7a1cab",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a4b07510-da09-11ea-87ea-f5980a7a1cab",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "View Work Permit History",
                                                    "variant": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "justify-content-center pt-1 pb-1"
                                        },
                                        {
                                            "id": "5ad52cd0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "607dff40-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                    "name": "unit",
                                                    "label": "Unit",
                                                    "dataField": "unit"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "9d05c150-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "bb60e760-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "dataField": "natureOfWork",
                                                    "label": "Nature of Work",
                                                    "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "d06acc70-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d1a632a0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "contractor",
                                                    "label": "Name of Contractor"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "dfc94700-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "e0f19a60-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "personInCharge",
                                                    "label": "Name of Person in Charge"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "ec2bbfa0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "eee8e3d0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "contactPersonInCharge",
                                                    "label": "Contact Number of Person in Charge"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "4f549340-d9cc-11ea-83f3-4b9e6a8a3ce6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "12242dc0-d9cf-11ea-be94-03767312b052",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "label": "Workers",
                                                    "dataField": "workers",
                                                    "placeholder": "Worker"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "77fa9030-da06-11ea-8a8e-430e67178950",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "7d094580-da06-11ea-8a8e-430e67178950",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "multiline": true,
                                                    "dataField": "description",
                                                    "label": "Description of Work"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "f2f0d5b0-da06-11ea-8a8e-430e67178950",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "fe279f90-da06-11ea-8a8e-430e67178950",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "dataField": "materials",
                                                    "label": "List of Materials"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "2ebf0bc0-da07-11ea-8a8e-430e67178950",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "33285950-da07-11ea-8a8e-430e67178950",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "dataField": "tools",
                                                    "label": "List of Tools"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "3bd32120-da07-11ea-8a8e-430e67178950",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3e8fd020-da07-11ea-8a8e-430e67178950",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "dataField": "startDate",
                                                    "label": "Proposed Start Date "
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "5217a910-da07-11ea-8a8e-430e67178950",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "558c3660-da07-11ea-8a8e-430e67178950",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "label": "Target End Date",
                                                    "dataField": "endDate"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "67781290-da07-11ea-8a8e-430e67178950",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "6a6f0b70-da07-11ea-8a8e-430e67178950",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "dataField": "plans",
                                                    "label": "Photo of Plans"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "814543c0-e7f7-11ea-aa5b-4b966778e568",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "work-permit",
                                            "component": "TermsAndConditions"
                                        },
                                        {
                                            "id": "3ad7b170-dac8-11ea-9725-dfc9cbd591dc",
                                            "type": "button",
                                            "children": [],
                                            "command": "save",
                                            "text": "Submit",
                                            "className": "mt-1"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-work-permit",
                            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                            "route": "/homeowner-work-permit/:id",
                            "_sortIndex": 470
                        },
                        {
                            "id": "c0fa6cc0-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                                    "children": [
                                        {
                                            "id": "c1110200-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c1110202-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "homeowner",
                                                    "label": "homeowner",
                                                    "dataField": "homeowner",
                                                    "saveObject": true,
                                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c1110203-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "22f119c0-ea54-11ea-8de6-3f209e03f86c",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"lf\", \"label\": \"LF (Looking For)\"}]",
                                                    "saveObject": true,
                                                    "name": "topic",
                                                    "component": "",
                                                    "label": "topic",
                                                    "dataField": "topic"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c1110206-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c1110208-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "title",
                                                    "label": "title",
                                                    "dataField": "title"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c1110209-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c1112910-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "price",
                                                    "label": "price",
                                                    "dataField": "price"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c1112911-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c1112913-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c1112914-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c1112916-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "contactNumber",
                                                    "label": "contactNumber",
                                                    "dataField": "contactNumber"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c1112917-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c1112919-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "photos",
                                                    "label": "photos",
                                                    "dataField": "photos",
                                                    "basePath": "message-boards"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c111291a-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "b01b8f10-ea54-11ea-8de6-3f209e03f86c",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "75908760-ea54-11ea-8de6-3f209e03f86c"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c111291d-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c111291f-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "comments",
                                                    "label": "comments",
                                                    "dataField": "comments"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "0aa00380-ea55-11ea-8de6-3f209e03f86c",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "c10987f0-df4b-11ea-a4a7-a1e8c04129ca",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "message-board-detail-page",
                            "route": "/message-boards/:id",
                            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                            "_sortIndex": 750
                        },
                        {
                            "id": "c116ce60-df4b-11ea-a4a7-a1e8c04129ca",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                                    "children": [
                                        {
                                            "id": "c11b8950-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "c1217cc0-df4b-11ea-a4a7-a1e8c04129ca",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "c1283380-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "homeowner",
                                                    "label": "homeowner",
                                                    "dataField": "homeowner"
                                                },
                                                {
                                                    "id": "c1283382-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "topic",
                                                    "label": "topic",
                                                    "dataField": "topic"
                                                },
                                                {
                                                    "id": "c1285a90-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "title",
                                                    "label": "title",
                                                    "dataField": "title"
                                                },
                                                {
                                                    "id": "c1285a92-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "price",
                                                    "label": "price",
                                                    "dataField": "price"
                                                },
                                                {
                                                    "id": "c1285a96-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "contactNumber",
                                                    "label": "contactNumber",
                                                    "dataField": "contactNumber"
                                                },
                                                {
                                                    "id": "c1285a9a-df4b-11ea-a4a7-a1e8c04129ca",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "status",
                                                    "label": "status",
                                                    "dataField": "status"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "message-board-list-page",
                            "route": "/message-boards-list",
                            "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                            "_sortIndex": 760
                        },
                        {
                            "id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                    "children": [
                                        {
                                            "id": "a8fabff0-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a8fabff2-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "name",
                                                    "label": "name",
                                                    "dataField": "name"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a8fabff3-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a8fabff5-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "text",
                                                    "label": "text",
                                                    "dataField": "text"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a8fabff6-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a8fabff8-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "noticeStart",
                                                    "label": "noticeStart",
                                                    "dataField": "noticeStart",
                                                    "format": "mm/dd/yyyy"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a8fabff9-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a8fabffb-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "noticeEnd",
                                                    "label": "noticeEnd",
                                                    "dataField": "noticeEnd",
                                                    "format": "mm/dd/yyyy"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a8fabffc-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a8fabffe-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "source",
                                                    "label": "source",
                                                    "dataField": "source"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "a8fabfff-912e-11ea-ad57-6526d978cdf6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a8fac001-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "status",
                                                    "label": "status",
                                                    "dataField": "status"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "a8f7b2b0-912e-11ea-ad57-6526d978cdf6",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "notification-detail-page",
                            "route": "/notifications/:id",
                            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                            "_sortIndex": 480
                        },
                        {
                            "id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                    "children": [
                                        {
                                            "id": "a9012890-912e-11ea-ad57-6526d978cdf6",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "a9045ce0-912e-11ea-ad57-6526d978cdf6",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "a907b840-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "name",
                                                    "label": "name",
                                                    "dataField": "name"
                                                },
                                                {
                                                    "id": "a907b842-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "text",
                                                    "label": "text",
                                                    "dataField": "text"
                                                },
                                                {
                                                    "id": "a907b844-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "noticeStart",
                                                    "label": "noticeStart",
                                                    "dataField": "noticeStart"
                                                },
                                                {
                                                    "id": "a907b846-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "noticeEnd",
                                                    "label": "noticeEnd",
                                                    "dataField": "noticeEnd"
                                                },
                                                {
                                                    "id": "a907b848-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "source",
                                                    "label": "source",
                                                    "dataField": "source"
                                                },
                                                {
                                                    "id": "a907b84a-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "status",
                                                    "label": "status",
                                                    "dataField": "status"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "notification-list-page",
                            "route": "/notifications-list",
                            "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                            "_sortIndex": 490
                        },
                        {
                            "id": "61855560-e82a-11ea-bffd-65b1c6e24159",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                                    "children": [
                                        {
                                            "id": "61a11ac0-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "61a11ac2-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "unit",
                                                    "label": "unit",
                                                    "dataField": "unit",
                                                    "saveObject": true,
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "61a141d0-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "f0707b30-ea53-11ea-8de6-3f209e03f86c",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                                    "saveObject": true,
                                                    "name": "type",
                                                    "label": "type",
                                                    "dataField": "type"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "61a141d3-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "61a141d5-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "schedule",
                                                    "label": "schedule",
                                                    "dataField": "schedule",
                                                    "format": "mm/dd/yyyy"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "61a141d6-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "61a141d8-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "items",
                                                    "label": "items",
                                                    "dataField": "items"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "61a141d9-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "61a141db-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "company",
                                                    "label": "company",
                                                    "dataField": "company"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "61a141dc-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "61a141de-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "status",
                                                    "label": "status",
                                                    "dataField": "status"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "61a141df-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "61a141e1-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "notes",
                                                    "label": "notes",
                                                    "dataField": "notes",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "619ad930-e82a-11ea-bffd-65b1c6e24159",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "pass-delivery-detail-page",
                            "route": "/pass-deliveries/:id",
                            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84"
                        },
                        {
                            "id": "61a7d180-e82a-11ea-bffd-65b1c6e24159",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "61855561-e82a-11ea-bffd-65b1c6e24159",
                                    "children": [
                                        {
                                            "id": "61ad9de0-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "61b34330-e82a-11ea-bffd-65b1c6e24159",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "61ba4810-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "unit",
                                                    "label": "unit",
                                                    "dataField": "unit"
                                                },
                                                {
                                                    "id": "61ba4812-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "type",
                                                    "label": "type",
                                                    "dataField": "type"
                                                },
                                                {
                                                    "id": "61ba4814-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "schedule",
                                                    "label": "schedule",
                                                    "dataField": "schedule"
                                                },
                                                {
                                                    "id": "61ba4818-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "company",
                                                    "label": "company",
                                                    "dataField": "company"
                                                },
                                                {
                                                    "id": "61ba6f20-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "status",
                                                    "label": "status",
                                                    "dataField": "status"
                                                },
                                                {
                                                    "id": "61ba6f22-e82a-11ea-bffd-65b1c6e24159",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "notes",
                                                    "label": "notes",
                                                    "dataField": "notes"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "pass-delivery-list-page",
                            "route": "/pass-deliveries-list",
                            "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84"
                        },
                        {
                            "id": "3f904730-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                                    "children": [
                                        {
                                            "id": "3fb18ad0-df4c-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3fb18ad2-df4c-11ea-a4a7-a1e8c04129ca",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "unit",
                                                    "label": "unit",
                                                    "dataField": "unit",
                                                    "saveObject": true,
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "3fb18ad9-df4c-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d5c38580-ea52-11ea-8de6-3f209e03f86c",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "type",
                                                    "label": "type",
                                                    "dataField": "type",
                                                    "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "3fb18adc-df4c-11ea-a4a7-a1e8c04129ca",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "e8041160-ea52-11ea-8de6-3f209e03f86c",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                                                    "name": "homeowner-visitor-pass-visitor-pass"
                                                },
                                                {
                                                    "id": "8b0b9a40-ea53-11ea-8de6-3f209e03f86c",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                                    "name": "homeowner-visitor-pass-long-term"
                                                },
                                                {
                                                    "id": "983a1570-ea53-11ea-8de6-3f209e03f86c",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                                    "name": "homeowner-visitor-pass-short-term"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "component": "VisitorPass"
                                        },
                                        {
                                            "id": "61a2cb40-ea55-11ea-8de6-3f209e03f86c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "73c065d0-ea55-11ea-8de6-3f209e03f86c",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "75908760-ea54-11ea-8de6-3f209e03f86c"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "3faafb20-df4c-11ea-a4a7-a1e8c04129ca",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "pass-visitor-detail-page",
                            "route": "/pass-visitors/:id",
                            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721"
                        },
                        {
                            "id": "3fb77e40-df4c-11ea-a4a7-a1e8c04129ca",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "c0fa6cc1-df4b-11ea-a4a7-a1e8c04129ca",
                                    "children": [
                                        {
                                            "id": "3fbe5c10-df4c-11ea-a4a7-a1e8c04129ca",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "3fc22ca0-df4c-11ea-a4a7-a1e8c04129ca",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "3fc62440-df4c-11ea-a4a7-a1e8c04129ca",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "unit",
                                                    "label": "unit",
                                                    "dataField": "unit"
                                                },
                                                {
                                                    "id": "3fc6244a-df4c-11ea-a4a7-a1e8c04129ca",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "type",
                                                    "label": "type",
                                                    "dataField": "type"
                                                },
                                                {
                                                    "id": "dd3b7a00-ea4f-11ea-b967-5baa6500e444",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "label": "startDate",
                                                    "dataField": "startDate"
                                                },
                                                {
                                                    "id": "e479b980-ea4f-11ea-b967-5baa6500e444",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "endDate",
                                                    "text": "",
                                                    "label": "endDate"
                                                },
                                                {
                                                    "id": "a2920620-ea55-11ea-8de6-3f209e03f86c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "status",
                                                    "label": "status"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "pass-visitor-list-page",
                            "route": "/pass-visitors-list",
                            "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721"
                        },
                        {
                            "id": "5a90c3f0-e507-11ea-8276-65e120bbe853",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                                    "children": [
                                        {
                                            "id": "5aa647c0-e507-11ea-8276-65e120bbe853",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "5aa647c2-e507-11ea-8276-65e120bbe853",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "name",
                                                    "label": "name",
                                                    "dataField": "name"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "5aa647c3-e507-11ea-8276-65e120bbe853",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "5aa66ed0-e507-11ea-8276-65e120bbe853",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "content",
                                                    "label": "content",
                                                    "dataField": "content",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "5aa1daf0-e507-11ea-8276-65e120bbe853",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "terms-conditions-detail-page",
                            "route": "/terms-conditions/:id",
                            "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853"
                        },
                        {
                            "id": "5aabc600-e507-11ea-8276-65e120bbe853",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "5a90eb00-e507-11ea-8276-65e120bbe853",
                                    "children": [
                                        {
                                            "id": "5ab16b50-e507-11ea-8276-65e120bbe853",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "5ab6e990-e507-11ea-8276-65e120bbe853",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "5ababa20-e507-11ea-8276-65e120bbe853",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "name",
                                                    "label": "name",
                                                    "dataField": "name"
                                                },
                                                {
                                                    "id": "5ababa22-e507-11ea-8276-65e120bbe853",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "content",
                                                    "label": "content",
                                                    "dataField": "content"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "terms-conditions-list-page",
                            "route": "/terms-conditions-list",
                            "dataModel": "faa20cb0-e506-11ea-8276-65e120bbe853"
                        },
                        {
                            "id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                    "children": [
                                        {
                                            "id": "0824d8f0-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "0824d8f2-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "number",
                                                    "label": "number",
                                                    "dataField": "number"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "0824d8f3-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "0824d8f5-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "address",
                                                    "label": "address",
                                                    "dataField": "address",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "0824d8f6-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "0824d8f8-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "0824d8f9-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "0824d8fb-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "homeowner",
                                                    "label": "homeowner",
                                                    "dataField": "homeowner",
                                                    "saveObject": true,
                                                    "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "081fa8d0-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "unit-detail-page",
                            "route": "/units/:id",
                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                            "_sortIndex": 520
                        },
                        {
                            "id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                    "children": [
                                        {
                                            "id": "08310df0-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "08341b30-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "0838fd30-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "number",
                                                    "label": "number",
                                                    "dataField": "number"
                                                },
                                                {
                                                    "id": "0838fd32-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "address",
                                                    "label": "address",
                                                    "dataField": "address"
                                                },
                                                {
                                                    "id": "0838fd34-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description"
                                                },
                                                {
                                                    "id": "0838fd36-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "homeowner",
                                                    "label": "homeowner",
                                                    "dataField": "homeowner"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "unit-list-page",
                            "route": "/units-list",
                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                            "_sortIndex": 530
                        },
                        {
                            "id": "1c0eae20-ea0d-11ea-b95d-1388b7bed108",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                                    "children": [
                                        {
                                            "id": "1c2a7380-ea0d-11ea-b95d-1388b7bed108",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "558d36f0-ea51-11ea-b2b9-6908f6182fd8",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                    "saveObject": true,
                                                    "name": "type",
                                                    "label": "type",
                                                    "dataField": "type"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "09c7e540-ea50-11ea-b967-5baa6500e444",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "4648f090-ea50-11ea-b967-5baa6500e444",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                                    "name": "homeowner-unit-request-aircon-cleaning"
                                                },
                                                {
                                                    "id": "4fa8fea0-ea50-11ea-b967-5baa6500e444",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                                    "name": "homeowner-unit-request-grease-trap-cleaning"
                                                },
                                                {
                                                    "id": "53a42110-ea50-11ea-b967-5baa6500e444",
                                                    "type": "subView",
                                                    "children": [
                                                        {
                                                            "id": "1c2a9a91-ea0d-11ea-b95d-1388b7bed108",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "1c2a9a93-ea0d-11ea-b95d-1388b7bed108",
                                                                    "type": "inputText",
                                                                    "children": [],
                                                                    "name": "status",
                                                                    "label": "status",
                                                                    "dataField": "status"
                                                                }
                                                            ],
                                                            "orientation": "horizontal"
                                                        }
                                                    ],
                                                    "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                                                    "name": "homeowner-unit-request-others"
                                                },
                                                {
                                                    "id": "570d15a0-ea50-11ea-b967-5baa6500e444",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                    "name": "homeowner-unit-request-plans-documents"
                                                },
                                                {
                                                    "id": "5c271590-ea50-11ea-b967-5baa6500e444",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                    "name": "homeowner-unit-request-refund-renovation-bond"
                                                },
                                                {
                                                    "id": "60d49810-ea50-11ea-b967-5baa6500e444",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                    "name": "homeowner-unit-request-service-request"
                                                },
                                                {
                                                    "id": "696aed80-ea50-11ea-b967-5baa6500e444",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                                    "name": "homeowner-unit-request-vehicle-sticker"
                                                },
                                                {
                                                    "id": "a3401d40-ea51-11ea-b2b9-6908f6182fd8",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ac059900-ea51-11ea-b2b9-6908f6182fd8",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                            "dataField": "unit",
                                                            "name": "unit",
                                                            "label": "Unit"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "unit-container"
                                                },
                                                {
                                                    "id": "ba232f20-ea51-11ea-b2b9-6908f6182fd8",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "bdbecc70-ea51-11ea-b2b9-6908f6182fd8",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "name": "type",
                                                            "label": "Type",
                                                            "dataField": "type",
                                                            "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                            "saveObject": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "type-container"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "component": "UnitRequest"
                                        },
                                        {
                                            "id": "1c2a9a97-ea0d-11ea-b95d-1388b7bed108",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "1c2a9a99-ea0d-11ea-b95d-1388b7bed108",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "notes",
                                                    "label": "notes",
                                                    "dataField": "notes"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "1c254360-ea0d-11ea-b95d-1388b7bed108",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "unit-request-detail-page",
                            "route": "/unit-requests/:id",
                            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
                        },
                        {
                            "id": "1c32d7f0-ea0d-11ea-b95d-1388b7bed108",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "1c0eae21-ea0d-11ea-b95d-1388b7bed108",
                                    "children": [
                                        {
                                            "id": "1c3b3c60-ea0d-11ea-b95d-1388b7bed108",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "1c4156e0-ea0d-11ea-b95d-1388b7bed108",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "1c48a9e0-ea0d-11ea-b95d-1388b7bed108",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "type",
                                                    "label": "type",
                                                    "dataField": "type"
                                                },
                                                {
                                                    "id": "1c48a9e4-ea0d-11ea-b95d-1388b7bed108",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "unit",
                                                    "label": "unit",
                                                    "dataField": "unit"
                                                },
                                                {
                                                    "id": "5335e730-ea56-11ea-9dc5-b194cfe3f86a",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "status",
                                                    "label": "status"
                                                },
                                                {
                                                    "id": "6023afe0-ea56-11ea-9dc5-b194cfe3f86a",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "dataField": "notes",
                                                    "label": "notes"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "unit-request-list-page",
                            "route": "/unit-requests-list",
                            "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a"
                        },
                        {
                            "id": "47a151f0-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "children": [
                                        {
                                            "id": "47bfd670-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bfd672-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "unit",
                                                    "label": "unit",
                                                    "dataField": "unit",
                                                    "saveObject": true,
                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bfd673-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bfd675-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "natureOfWork",
                                                    "label": "natureOfWork",
                                                    "dataField": "natureOfWork"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bfd676-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bfd678-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "contractor",
                                                    "label": "contractor",
                                                    "dataField": "contractor"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bfd679-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bfd67b-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "personInCharge",
                                                    "label": "personInCharge",
                                                    "dataField": "personInCharge"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bfd67c-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bffd81-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "contactPersonInCharge",
                                                    "label": "contactPersonInCharge",
                                                    "dataField": "contactPersonInCharge"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bffd82-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "e7ec4310-e01f-11ea-affb-21f86f398b19",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "dataField": "workers",
                                                    "label": "workers",
                                                    "name": "workers",
                                                    "placeholder": "Worker"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bffd85-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bffd87-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bffd88-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "f46db560-e01f-11ea-affb-21f86f398b19",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "dataField": "materials",
                                                    "name": "materials",
                                                    "label": "materials"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bffd8b-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bffd8d-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "tools",
                                                    "label": "tools",
                                                    "dataField": "tools"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bffd8e-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bffd90-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "startDate",
                                                    "label": "startDate",
                                                    "dataField": "startDate",
                                                    "format": "mm/dd/yyyy"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bffd91-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bffd93-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "endDate",
                                                    "label": "endDate",
                                                    "dataField": "endDate",
                                                    "format": "mm/dd/yyyy"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bffd94-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "47bffd96-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "plans",
                                                    "label": "plans",
                                                    "dataField": "plans",
                                                    "basePath": "work-permits"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "47bffd97-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ce47dcf0-ea54-11ea-8de6-3f209e03f86c",
                                                    "type": "subView",
                                                    "children": [],
                                                    "view": "75908760-ea54-11ea-8de6-3f209e03f86c"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "8bdb91a0-e828-11ea-99d9-7b0e0405473d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "8f530520-e828-11ea-99d9-7b0e0405473d",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "notes",
                                                    "dataField": "notes",
                                                    "label": "notes",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ]
                                },
                                {
                                    "id": "47b79910-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "type": "subView",
                                    "children": [],
                                    "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "work-permit-detail-page",
                            "route": "/work-permits/:id",
                            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                            "_sortIndex": 730
                        },
                        {
                            "id": "47c7c5b0-df4a-11ea-96aa-1f18fc6e6b6c",
                            "type": "page",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "47a151f1-df4a-11ea-96aa-1f18fc6e6b6c",
                                    "children": [
                                        {
                                            "id": "47ce7c70-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "subView",
                                            "children": [],
                                            "name": "searchbar",
                                            "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "47d421c0-df4a-11ea-96aa-1f18fc6e6b6c",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "47d9c710-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "unit",
                                                    "label": "unit",
                                                    "dataField": "unit"
                                                },
                                                {
                                                    "id": "47d9c712-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "natureOfWork",
                                                    "label": "natureOfWork",
                                                    "dataField": "natureOfWork"
                                                },
                                                {
                                                    "id": "47d9c714-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "contractor",
                                                    "label": "contractor",
                                                    "dataField": "contractor"
                                                },
                                                {
                                                    "id": "47d9c71c-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description"
                                                },
                                                {
                                                    "id": "47d9c722-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "startDate",
                                                    "label": "startDate",
                                                    "dataField": "startDate"
                                                },
                                                {
                                                    "id": "47d9c724-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "endDate",
                                                    "label": "endDate",
                                                    "dataField": "endDate"
                                                },
                                                {
                                                    "id": "47d9c728-df4a-11ea-96aa-1f18fc6e6b6c",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "status",
                                                    "label": "status",
                                                    "dataField": "status"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "work-permit-list-page",
                            "route": "/work-permits-list",
                            "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                            "_sortIndex": 740
                        },
                        {
                            "id": "3acc2620-e865-11ea-9def-530e14f0f646",
                            "type": "view",
                            "children": [
                                {
                                    "name": "main",
                                    "type": "container",
                                    "id": "ce87a071-e864-11ea-9def-530e14f0f646",
                                    "children": [
                                        {
                                            "id": "3ad3ee50-e865-11ea-9def-530e14f0f646",
                                            "type": "table",
                                            "children": [
                                                {
                                                    "id": "3ad7bee0-e865-11ea-9def-530e14f0f646",
                                                    "type": "column",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "name": "billing",
                                                    "label": "billing",
                                                    "dataField": "billing"
                                                },
                                                {
                                                    "id": "3ad7e5f0-e865-11ea-9def-530e14f0f646",
                                                    "type": "column",
                                                    "children": [
                                                        {
                                                            "id": "501d2fe0-e871-11ea-808a-85e6d8103902",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "item"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "item",
                                                    "label": "item",
                                                    "dataField": "item"
                                                },
                                                {
                                                    "id": "3ad7e5f2-e865-11ea-9def-530e14f0f646",
                                                    "type": "column",
                                                    "children": [
                                                        {
                                                            "id": "5279e490-e871-11ea-808a-85e6d8103902",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "description"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "description",
                                                    "label": "description",
                                                    "dataField": "description"
                                                },
                                                {
                                                    "id": "3ad7e5f4-e865-11ea-9def-530e14f0f646",
                                                    "type": "column",
                                                    "children": [
                                                        {
                                                            "id": "56685cd0-e871-11ea-808a-85e6d8103902",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "amount"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "amount",
                                                    "label": "amount",
                                                    "dataField": "amount"
                                                },
                                                {
                                                    "id": "3ad7e5f6-e865-11ea-9def-530e14f0f646",
                                                    "type": "column",
                                                    "children": [
                                                        {
                                                            "id": "58cc6480-e871-11ea-808a-85e6d8103902",
                                                            "type": "inputText",
                                                            "children": []
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "state",
                                                    "label": "state",
                                                    "dataField": "state"
                                                },
                                                {
                                                    "id": "6d0df080-e871-11ea-808a-85e6d8103902",
                                                    "type": "column",
                                                    "children": [
                                                        {
                                                            "id": "7088bf60-e871-11ea-808a-85e6d8103902",
                                                            "type": "button",
                                                            "children": [],
                                                            "command": "deleteItem",
                                                            "text": "delete item"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "table",
                                            "component": "",
                                            "command": "openItem"
                                        }
                                    ]
                                }
                            ],
                            "orientation": "vertical",
                            "name": "billing-item-list-page",
                            "route": "/billing-items-list",
                            "dataModel": "960f7330-e864-11ea-9def-530e14f0f646"
                        },
                        {
                            "id": "84c241b0-ea09-11ea-91c2-e33bb0a096cf",
                            "type": "view",
                            "children": [
                                {
                                    "id": "919381b0-ea09-11ea-91c2-e33bb0a096cf",
                                    "type": "button",
                                    "children": [],
                                    "name": "save",
                                    "command": "save_dashboard",
                                    "text": "Save"
                                },
                                {
                                    "id": "9cdf5a30-ea09-11ea-91c2-e33bb0a096cf",
                                    "type": "button",
                                    "children": [],
                                    "path": "",
                                    "text": "Delete",
                                    "command": "delete_dashboard",
                                    "name": "delete"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "commandbar-dashboard"
                        },
                        {
                            "type": "view",
                            "name": "commandbar",
                            "orientation": "horizontal",
                            "children": [
                                {
                                    "name": "save",
                                    "type": "button",
                                    "id": "e7db5105-8c4a-11ea-9466-6f383d5eb483",
                                    "command": "save"
                                },
                                {
                                    "name": "delete",
                                    "type": "button",
                                    "id": "e7db5106-8c4a-11ea-9466-6f383d5eb483",
                                    "command": "delete"
                                }
                            ],
                            "id": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                            "active": false,
                            "_sortIndex": 560
                        },
                        {
                            "id": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                            "type": "view",
                            "children": [
                                {
                                    "id": "7ee9d700-8ef4-11ea-9b90-bfc3ee1ce33c",
                                    "type": "menu",
                                    "children": [
                                        {
                                            "id": "3cf81350-9130-11ea-af3e-29e246413619",
                                            "type": "menuItem",
                                            "children": [],
                                            "_sortIndex": 0,
                                            "path": "/",
                                            "label": "Main Menu"
                                        },
                                        {
                                            "id": "e86c29e0-912d-11ea-82bc-71c30f9d69df",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/pass-visitors-list",
                                            "label": "Visitor Passes",
                                            "_sortIndex": 70
                                        },
                                        {
                                            "id": "e6566940-912d-11ea-82bc-71c30f9d69df",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/pass-deliveries-list",
                                            "label": "Delivery / Pullout Passes",
                                            "_sortIndex": 60
                                        },
                                        {
                                            "id": "875d7be0-dd59-11ea-878d-279a484325f4",
                                            "type": "menuItem",
                                            "children": [],
                                            "label": "Work Permits",
                                            "path": "/work-permits-list"
                                        },
                                        {
                                            "id": "9c3e1dc0-914c-11ea-b5d1-292f71dadca1",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/unit-requests-list",
                                            "label": "Unit Requests",
                                            "_sortIndex": 80
                                        },
                                        {
                                            "id": "1854b570-914f-11ea-b2e4-0783314da7b1",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/comments-suggestions-list",
                                            "label": "Comments & Suggestions",
                                            "_sortIndex": 19
                                        },
                                        {
                                            "id": "d72baba0-dd5a-11ea-b1bd-f3d0d3926db1",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/message-boards-list",
                                            "label": "Message Board"
                                        },
                                        {
                                            "id": "e3379d10-912d-11ea-82bc-71c30f9d69df",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/notifications-list",
                                            "label": "Notifications",
                                            "_sortIndex": 50
                                        },
                                        {
                                            "id": "7abd8be0-9138-11ea-bd78-399a56c40918",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/billings-list",
                                            "label": "Billings",
                                            "_sortIndex": 10
                                        },
                                        {
                                            "id": "e1041b40-912d-11ea-82bc-71c30f9d69df",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/homeowners-list",
                                            "label": "Homeowners",
                                            "_sortIndex": 40
                                        },
                                        {
                                            "id": "7bde5e60-dd59-11ea-878d-279a484325f4",
                                            "type": "menuItem",
                                            "children": [],
                                            "label": "Units",
                                            "path": "/units-list"
                                        },
                                        {
                                            "id": "d8c9a170-912d-11ea-82bc-71c30f9d69df",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/departments-list",
                                            "label": "Departments",
                                            "_sortIndex": 20
                                        },
                                        {
                                            "id": "df5755a0-912d-11ea-82bc-71c30f9d69df",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/employees-list",
                                            "label": "Employees",
                                            "_sortIndex": 30
                                        },
                                        {
                                            "id": "9ebd1610-e7f4-11ea-b892-0f30d25d09fd",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/terms-conditions-list",
                                            "label": "Terms and Conditions"
                                        },
                                        {
                                            "id": "fef31bf0-dfa3-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/dashboard-login",
                                            "label": "Logout"
                                        }
                                    ],
                                    "name": "",
                                    "className": "dashboard-sidebar"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "dashboard-sidebar",
                            "active": false,
                            "_sortIndex": 570
                        },
                        {
                            "id": "5a17eae0-dfab-11ea-a2d5-af9e56bb6ecf",
                            "type": "view",
                            "children": [
                                {
                                    "id": "60fee2a0-dfab-11ea-a2d5-af9e56bb6ecf",
                                    "type": "header",
                                    "children": [],
                                    "size": 2,
                                    "name": "Welcome",
                                    "text": "Welcome"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "dashboard-welcome"
                        },
                        {
                            "id": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                            "type": "view",
                            "children": [
                                {
                                    "id": "24e082d0-8ef0-11ea-981d-018b5a72c307",
                                    "type": "menu",
                                    "children": [
                                        {
                                            "id": "26cac6a0-8ef0-11ea-981d-018b5a72c307",
                                            "type": "menuItem",
                                            "children": [],
                                            "name": "home",
                                            "_sortIndex": 0,
                                            "label": "Main Menu",
                                            "path": "/app"
                                        },
                                        {
                                            "id": "13a38940-912a-11ea-a5e9-ff76f0b9af84",
                                            "type": "menuItem",
                                            "children": [],
                                            "_sortIndex": 10,
                                            "name": "account",
                                            "label": "My Account",
                                            "path": "/my-account/me"
                                        },
                                        {
                                            "id": "152c2510-912a-11ea-a5e9-ff76f0b9af84",
                                            "type": "menuItem",
                                            "children": [],
                                            "_sortIndex": 20,
                                            "name": "service",
                                            "label": "Unit Request",
                                            "path": "/homeowner-unit-request/0"
                                        },
                                        {
                                            "id": "17a5feb0-912a-11ea-a5e9-ff76f0b9af84",
                                            "type": "menuItem",
                                            "children": [],
                                            "_sortIndex": 40,
                                            "label": "Visitor Pass",
                                            "name": "visitor",
                                            "path": "/create-visitor-pass/0"
                                        },
                                        {
                                            "id": "19b110f0-912a-11ea-a5e9-ff76f0b9af84",
                                            "type": "menuItem",
                                            "children": [],
                                            "_sortIndex": 50,
                                            "name": "delivery",
                                            "label": "Delivery pass",
                                            "path": "/create-delivery-pass/0"
                                        },
                                        {
                                            "id": "58256b20-c92d-11ea-8660-8b92cdcd0efe",
                                            "type": "menuItem",
                                            "children": [],
                                            "path": "/message-board",
                                            "label": "Message Board",
                                            "_sortIndex": 70
                                        },
                                        {
                                            "id": "166eb730-912a-11ea-a5e9-ff76f0b9af84",
                                            "type": "menuItem",
                                            "children": [],
                                            "_sortIndex": 30,
                                            "label": "Comments and Suggestions",
                                            "name": "complaint",
                                            "path": "/homeowner-comments-suggestions/0"
                                        },
                                        {
                                            "id": "5968bca0-d9ac-11ea-b236-955d418fe434",
                                            "type": "menuItem",
                                            "children": [],
                                            "name": "work-permit",
                                            "path": "/homeowner-work-permit/0",
                                            "label": "Work Permit"
                                        },
                                        {
                                            "id": "295939b0-8ef0-11ea-981d-018b5a72c307",
                                            "type": "menuItem",
                                            "children": [],
                                            "name": "logout",
                                            "_sortIndex": 71,
                                            "label": "Logout",
                                            "path": "/homeowners-login"
                                        }
                                    ],
                                    "className": "homeowner-sidebar"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-sidebar",
                            "active": false,
                            "_sortIndex": 580
                        },
                        {
                            "id": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                            "type": "view",
                            "children": [
                                {
                                    "id": "d3e39f50-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d5efea10-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "meta.units",
                                            "label": "No. of Units"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "db6aa7a0-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "2d6be400-d9a8-11ea-b622-15dab671ce91",
                                            "type": "inputDateTime",
                                            "children": [],
                                            "name": "meta.schedule",
                                            "label": "Preferred Schedule ",
                                            "description": "10am-4pm, Mondays to Fridays only. Except Holidays"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "77226cd0-e7f5-11ea-b892-0f30d25d09fd",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit-request-aircon-cleaning",
                                    "component": "TermsAndConditions"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-request-aircon-cleaning",
                            "_sortIndex": 590
                        },
                        {
                            "id": "0c744880-d9a5-11ea-a304-319701533d32",
                            "type": "view",
                            "children": [
                                {
                                    "id": "1c9ddb40-d9a5-11ea-a304-319701533d32",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "267c1a00-d9a5-11ea-a304-319701533d32",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "meta.schedule",
                                            "description": "10am-4pm, Mondays to Fridays only. Except Holidays",
                                            "label": "Preferred Schedule"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "622a6210-e7f5-11ea-b892-0f30d25d09fd",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit-request-grease-trap-cleaning",
                                    "component": "TermsAndConditions"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-request-grease-trap-cleaning",
                            "_sortIndex": 600
                        },
                        {
                            "id": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                            "type": "view",
                            "children": [
                                {
                                    "id": "fe4688f0-d9a8-11ea-9331-090047662dd2",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ff497a00-d9a8-11ea-9331-090047662dd2",
                                            "type": "inputText",
                                            "children": [],
                                            "multiline": true,
                                            "name": "meta.details",
                                            "label": "Please Specify"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-request-others",
                            "_sortIndex": 610
                        },
                        {
                            "id": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                            "type": "view",
                            "children": [
                                {
                                    "id": "e85ead40-c910-11ea-9dd0-6723feee229e",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "076a4bb0-c91e-11ea-831c-99fd7b91f646",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "meta.plans",
                                            "label": "Plans Required",
                                            "options": "[{ \"value\": \"standard\", \"label\": \"Standard Plan\"},{ \"value\": \"as-built\", \"label\": \"As-built Plan\"}]",
                                            "className": "bite-select",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "homeowner-service-request-repairs",
                                    "className": "bite-select"
                                },
                                {
                                    "id": "112746d0-c91e-11ea-831c-99fd7b91f646",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "44b19ca0-d916-11ea-9ea3-7184c3941d0d",
                                            "type": "inputSelect",
                                            "children": [],
                                            "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                            "saveObject": true,
                                            "name": "meta.paper-size",
                                            "label": "Paper Size"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "97e9eee0-d916-11ea-9ea3-7184c3941d0d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9a6e28c0-d916-11ea-9ea3-7184c3941d0d",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "meta.type",
                                            "label": "Type of Plan",
                                            "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing\", \"label\": \"Plumbing\"},{ \"value\": \"electrical-plan\", \"label\": \"Electrical Plan\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "e91d7600-e7f4-11ea-b892-0f30d25d09fd",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit-request-plans-documents",
                                    "component": "TermsAndConditions"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-request-plans-documents",
                            "_sortIndex": 620
                        },
                        {
                            "id": "063f7330-c911-11ea-9dd0-6723feee229e",
                            "type": "view",
                            "children": [
                                {
                                    "id": "03ee4ee0-c91f-11ea-b910-c30a77ee18ba",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "59be4b60-d917-11ea-9ea3-7184c3941d0d",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "meta.date",
                                            "label": "Date of Bond Payment"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "1cea7cc0-c91f-11ea-b910-c30a77ee18ba",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "8dc40350-d917-11ea-9ea3-7184c3941d0d",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "meta.amount",
                                            "label": "Amount"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": ""
                                },
                                {
                                    "id": "9be36e30-d917-11ea-9ea3-7184c3941d0d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9fea9e90-d917-11ea-9ea3-7184c3941d0d",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "meta.OR-no",
                                            "label": "O.R./C.R. No."
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "b9eb6b30-d917-11ea-9ea3-7184c3941d0d",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "bb01bd30-d917-11ea-9ea3-7184c3941d0d",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "meta.inspection-date",
                                            "description": "",
                                            "label": "Inspection Date"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "21c2a6b0-e7f5-11ea-b892-0f30d25d09fd",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit-request-refund-renovation-fund",
                                    "component": "TermsAndConditions"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-request-refund-renovation-bond",
                            "_sortIndex": 630
                        },
                        {
                            "id": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                            "type": "view",
                            "children": [
                                {
                                    "id": "743faf70-c90d-11ea-8c59-196c3ec4f839",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a9319f50-c911-11ea-98ab-e51558e3434e",
                                            "type": "inputSelect",
                                            "children": [],
                                            "label": "Type of Work",
                                            "name": "meta.type",
                                            "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                                            "className": "bite-select",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "bite-select"
                                },
                                {
                                    "id": "c6681b80-c911-11ea-98ab-e51558e3434e",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c84dcb70-c911-11ea-98ab-e51558e3434e",
                                            "type": "inputText",
                                            "children": [],
                                            "label": "Work Details",
                                            "name": "meta.details",
                                            "multiline": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "cf0bde70-c911-11ea-98ab-e51558e3434e",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d46647a0-d908-11ea-8bc1-2d8749eeeb64",
                                            "type": "inputText",
                                            "children": [],
                                            "label": "Preferred Schedule",
                                            "name": "meta.schedule"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "bite-select"
                                },
                                {
                                    "id": "996b7430-e65b-11ea-a30a-a9b10ee3b8be",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "component": "TermsAndConditions",
                                    "name": "unit-request-service-request"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-request-service-request",
                            "_sortIndex": 640
                        },
                        {
                            "id": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                            "type": "view",
                            "children": [
                                {
                                    "id": "9a02aad0-d918-11ea-b3eb-d9cf4159b042",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a6157cd0-d918-11ea-b3eb-d9cf4159b042",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "meta.parking-unit-number",
                                            "label": "Parking Unit Number"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "02e8c340-d919-11ea-b3eb-d9cf4159b042",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "25111df0-d919-11ea-b3eb-d9cf4159b042",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "meta.status",
                                            "label": "Status",
                                            "options": "[{\"label\":\"Owned\", \"value\":\"owned\"},{\"label\":\"Rented\", \"value\":\"rented\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "b33b28f0-d919-11ea-b3eb-d9cf4159b042",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b80cd540-d919-11ea-b3eb-d9cf4159b042",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "meta.make",
                                            "label": "Make",
                                            "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c394f370-d919-11ea-b3eb-d9cf4159b042",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c6827670-d919-11ea-b3eb-d9cf4159b042",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "meta.model",
                                            "label": "Model"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "ad71c600-d9a4-11ea-a304-319701533d32",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b1b90ca0-d9a4-11ea-a304-319701533d32",
                                            "type": "inputSelect",
                                            "children": [],
                                            "name": "meta.color",
                                            "label": "Color",
                                            "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                                            "saveObject": true
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "c744cbe0-d9a4-11ea-a304-319701533d32",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "cfb34e00-d9a4-11ea-a304-319701533d32",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "meta.plate-number",
                                            "label": "Plate Number"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "ddd18060-d9a4-11ea-a304-319701533d32",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "deacec40-d9a4-11ea-a304-319701533d32",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "meta.OR",
                                            "description": "",
                                            "label": "Upload O.R. / C.R."
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "3fd52b00-e7f5-11ea-b892-0f30d25d09fd",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "unit-request-vehicle-sticker",
                                    "component": "TermsAndConditions"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-request-vehicle-sticker",
                            "_sortIndex": 650
                        },
                        {
                            "id": "b8fe9280-c6f5-11ea-b415-c195f50f114e",
                            "type": "view",
                            "children": [
                                {
                                    "id": "aca19b50-c6f9-11ea-a851-fde2cfff9ce9",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ae765b50-c6f9-11ea-a851-fde2cfff9ce9",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Select Unit"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "columns is-centered pb-1"
                                },
                                {
                                    "id": "d8bbe3d0-c6f9-11ea-a851-fde2cfff9ce9",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "dde9e0f0-c6f9-11ea-a851-fde2cfff9ce9",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "029256d0-c6fa-11ea-a851-fde2cfff9ce9",
                                                    "type": "button",
                                                    "children": [],
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 01</div>",
                                                    "className": "app-menu-item",
                                                    "path": "/welcome/01"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        },
                                        {
                                            "id": "472397a0-c6fa-11ea-a851-fde2cfff9ce9",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "53985fc0-c6fa-11ea-a851-fde2cfff9ce9",
                                                    "type": "button",
                                                    "children": [],
                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 02</div>",
                                                    "className": "app-menu-item",
                                                    "path": "/welcome/02"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "app-menu-item-container"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "columns is-centered pt-2"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-unit-selection",
                            "className": "",
                            "_sortIndex": 660
                        },
                        {
                            "id": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                            "type": "view",
                            "children": [
                                {
                                    "id": "38c26740-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "3d14aa60-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputList",
                                            "children": [],
                                            "name": "visitor",
                                            "label": "Name/s",
                                            "placeholder": "Tenant"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "49eb4190-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "4b75d930-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "startDate",
                                            "label": "Start Date"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "5279ced0-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "542d4b30-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "endDate",
                                            "label": "End Date"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "5ed590b0-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "603f80f0-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "visitorsId",
                                            "label": "Upload valid ID/ Passport (required)"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "6f845900-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "710098c0-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "meta.contract",
                                            "label": "Upload Contract of Lease (required)"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "3373c0d0-e816-11ea-b937-9954e083a5ee",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "visitor-pass-long-term",
                                    "component": "TermsAndConditions"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-visitor-pass-long-term",
                            "_sortIndex": 670
                        },
                        {
                            "id": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                            "type": "view",
                            "children": [
                                {
                                    "id": "a0ff15c0-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a2036660-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputList",
                                            "children": [],
                                            "name": "visitor",
                                            "label": "Name/s",
                                            "placeholder": "Tenant"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "bb50c180-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c70a7700-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "startDate",
                                            "label": "Start Date"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "cdb2b810-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "d233d360-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "endDate",
                                            "label": "End Date"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "e0d391d0-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "e4f28ff0-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "visitorsId",
                                            "label": "Upload Valid ID/ Passport"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "214ab490-e816-11ea-b937-9954e083a5ee",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "visitor-pass-short-term",
                                    "component": "TermsAndConditions"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-visitor-pass-short-term",
                            "_sortIndex": 680
                        },
                        {
                            "id": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                            "type": "view",
                            "children": [
                                {
                                    "id": "94ab5510-da1b-11ea-87e9-9de57275fc27",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "9c1bc820-da1b-11ea-87e9-9de57275fc27",
                                            "type": "inputText",
                                            "children": [],
                                            "name": "meta.relationship",
                                            "label": "Relationship to Owner"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "b7ed0f00-da1b-11ea-87e9-9de57275fc27",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "b9749960-da1b-11ea-87e9-9de57275fc27",
                                            "type": "inputList",
                                            "children": [],
                                            "name": "visitor",
                                            "description": "",
                                            "label": "Name/s",
                                            "placeholder": "Visitor"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "cb6d1fc0-da1b-11ea-87e9-9de57275fc27",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "cebbae80-da1b-11ea-87e9-9de57275fc27",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "startDate",
                                            "label": "Start Date"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "d92934f0-da1b-11ea-87e9-9de57275fc27",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "db4adc70-da1b-11ea-87e9-9de57275fc27",
                                            "type": "inputDate",
                                            "children": [],
                                            "name": "endDate",
                                            "label": "End Date"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "e6edfcb0-da1b-11ea-87e9-9de57275fc27",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "ed1bdda0-da1b-11ea-87e9-9de57275fc27",
                                            "type": "inputMedia",
                                            "children": [],
                                            "name": "visitorsId",
                                            "label": "Upload Valid ID (optional)"
                                        }
                                    ],
                                    "orientation": "horizontal"
                                },
                                {
                                    "id": "fe8ad4d0-e815-11ea-b937-9954e083a5ee",
                                    "type": "container",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "visitor-pass-visitor-pass",
                                    "component": "TermsAndConditions"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-visitor-pass-visitor-pass",
                            "_sortIndex": 690
                        },
                        {
                            "id": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                            "type": "view",
                            "children": [
                                {
                                    "id": "29bfe9a0-97d6-11ea-a9fa-07f95ad6f0c6",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "a1eade70-8ef4-11ea-9b90-bfc3ee1ce33c",
                                            "type": "header",
                                            "children": [],
                                            "size": 2,
                                            "text": "Welcome",
                                            "_sortIndex": 0,
                                            "component": ""
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 0,
                                    "className": "columns is-centered py-2"
                                },
                                {
                                    "id": "78539d80-9148-11ea-a6b6-e7e287632ad5",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "c87fc130-9148-11ea-a6b6-e7e287632ad5",
                                            "type": "button",
                                            "children": [],
                                            "text": "View Notifications",
                                            "path": "/notifications",
                                            "className": "",
                                            "variant": "text"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "_sortIndex": 10,
                                    "className": "columns is-centered p-2"
                                },
                                {
                                    "id": "e7c4b510-e7fa-11ea-ba5e-d94241a9751c",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "465ece40-e7fa-11ea-ba5e-d94241a9751c",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "25f3f810-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "d37f2010-97da-11ea-a9fa-07f95ad6f0c6",
                                                            "type": "button",
                                                            "children": [],
                                                            "path": "/my-account/me",
                                                            "name": "My Account",
                                                            "className": "app-menu-item",
                                                            "icon": "",
                                                            "text": "",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_account.svg\"><img src=\"./assets/images/icon_account.svg\" /></object>My Account</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "2ef5e090-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "856e1b80-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "",
                                                            "path": "/homeowner-unit-request/0",
                                                            "flex": "[object Object]",
                                                            "className": "app-menu-item",
                                                            "_sortIndex": 10,
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_unit_request.svg\"><img src=\"./assets/images/icon_unit_request.svg\" /></object>Unit Request</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "351a2490-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "874e7440-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "",
                                                            "path": "/homeowner-comments-suggestions/0",
                                                            "_sortIndex": 20,
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_complaint.svg\"><img src=\"./assets/images/icon_complaint.svg\" /></object>Comments and Suggestions</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "38053690-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [
                                                                {
                                                                    "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                                    "type": "container",
                                                                    "children": [
                                                                        {
                                                                            "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                                            "type": "button",
                                                                            "children": [],
                                                                            "text": "",
                                                                            "path": "/create-visitor-pass/0",
                                                                            "_sortIndex": 30,
                                                                            "className": "app-menu-item",
                                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                                        }
                                                                    ],
                                                                    "orientation": "horizontal",
                                                                    "className": "app-menu-item-container"
                                                                }
                                                            ],
                                                            "text": "",
                                                            "path": "/create-visitor-pass/0",
                                                            "_sortIndex": 30,
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "8ac2b370-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "",
                                                            "path": "/create-delivery-pass/0",
                                                            "_sortIndex": 40,
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_delivery.svg\"><img src=\"./assets/images/icon_delivery.svg\" /></object>Delivery Pass</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "2290a530-c921-11ea-9848-69dad3ad605b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "2b49ebf0-c921-11ea-9848-69dad3ad605b",
                                                            "type": "button",
                                                            "children": [],
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_board.svg\"><img src=\"./assets/images/icon_board.svg\" /></object>Message Board</div>",
                                                            "className": "app-menu-item",
                                                            "path": "/message-board"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "d7653ab0-e7f9-11ea-ba5e-d94241a9751c",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "e1ab6300-e7f9-11ea-ba5e-d94241a9751c",
                                                            "type": "button",
                                                            "children": [],
                                                            "path": "/homeowner-work-permit/0",
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_work_permit.svg\"><img src=\"./assets/images/icon_work_permit.svg\" /></object>Work Permit</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "5e4c0520-ea13-11ea-a21e-f569cc759e68",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "68b4a990-ea13-11ea-a21e-f569cc759e68",
                                                            "type": "button",
                                                            "children": [],
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Dummy</div>",
                                                            "path": "#"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "columns app-menus-container"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": ""
                                }
                            ],
                            "orientation": "vertical",
                            "name": "homeowner-welcome",
                            "active": false,
                            "className": "column is-centered",
                            "component": "",
                            "_sortIndex": 700
                        },
                        {
                            "id": "c3314c80-dfa4-11ea-a94d-e5d6a6ca6c3b",
                            "type": "view",
                            "children": [
                                {
                                    "id": "28528d60-dfa8-11ea-9800-138672de1aa2",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "6fbd65f0-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "712f9390-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "77274950-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "header",
                                                            "children": [],
                                                            "size": "1",
                                                            "className": "h1",
                                                            "text": "Log In"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "col"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "row"
                                        },
                                        {
                                            "id": "7d5f3c60-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "8a375a30-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "9e527270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "email",
                                                            "label": "Email",
                                                            "className": "form-control login-form-input",
                                                            "variant": "standard"
                                                        },
                                                        {
                                                            "id": "b3ea9850-dfa7-11ea-9800-138672de1aa2",
                                                            "type": "image",
                                                            "children": [],
                                                            "url": "./assets/images/icon_user.svg",
                                                            "className": "login-icons"
                                                        }
                                                    ],
                                                    "orientation": "vertical",
                                                    "className": "col"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "row mt-4"
                                        },
                                        {
                                            "id": "c896c270-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "cbd9df80-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ce124350-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "password": true,
                                                            "name": "password",
                                                            "label": "Password",
                                                            "className": "form-control login-form-input",
                                                            "variant": "standard"
                                                        },
                                                        {
                                                            "id": "fcc3d880-dfa6-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "0bdff2e0-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                                    "type": "paragraph",
                                                                    "children": [],
                                                                    "text": "[Forgot your password?](https://www.froala.com)"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "mt-3 forgot-password-container"
                                                        },
                                                        {
                                                            "id": "c9874780-dfa7-11ea-9800-138672de1aa2",
                                                            "type": "image",
                                                            "children": [],
                                                            "url": "./assets/images/icon_login_password.svg",
                                                            "className": "login-icons"
                                                        }
                                                    ],
                                                    "orientation": "vertical",
                                                    "className": "col"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "row mt-4"
                                        },
                                        {
                                            "id": "10d10e10-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "15b3aa50-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "17e09c70-dfa7-11ea-a94d-e5d6a6ca6c3b",
                                                            "type": "button",
                                                            "children": [],
                                                            "command": "local_login",
                                                            "text": "Login",
                                                            "fullWidth": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "col"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "row mt-4"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "component": "Login"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "login-dashboard",
                            "component": ""
                        },
                        {
                            "id": "e1156440-93e9-11ea-99e4-a504a663bd48",
                            "type": "view",
                            "children": [
                                {
                                    "id": "eb7f4134-93e9-11ea-99e4-a504a663bd48",
                                    "type": "container",
                                    "children": [
                                        {
                                            "nodeType": "DIV",
                                            "type": "container",
                                            "className": "row",
                                            "children": [
                                                {
                                                    "nodeType": "DIV",
                                                    "type": "container",
                                                    "className": "col",
                                                    "children": [
                                                        {
                                                            "nodeType": "H1",
                                                            "type": "header",
                                                            "className": "h1",
                                                            "children": [],
                                                            "text": "Log In",
                                                            "id": "eb7f4137-93e9-11ea-99e4-a504a663bd48"
                                                        }
                                                    ],
                                                    "id": "eb7f4136-93e9-11ea-99e4-a504a663bd48"
                                                }
                                            ],
                                            "id": "eb7f4135-93e9-11ea-99e4-a504a663bd48"
                                        },
                                        {
                                            "nodeType": "DIV",
                                            "type": "container",
                                            "className": "row mt-4",
                                            "children": [
                                                {
                                                    "nodeType": "DIV",
                                                    "type": "container",
                                                    "className": "col",
                                                    "children": [
                                                        {
                                                            "nodeType": "INPUT",
                                                            "type": "inputText",
                                                            "className": "form-control login-form-input txt-homeowner-login",
                                                            "children": [],
                                                            "placeholder": "Email",
                                                            "id": "eb7f413a-93e9-11ea-99e4-a504a663bd48",
                                                            "name": "email",
                                                            "variant": "standard"
                                                        },
                                                        {
                                                            "id": "f6061850-ad8d-11ea-a81e-a3c623c48bc9",
                                                            "type": "image",
                                                            "children": [],
                                                            "url": "./assets/images/icon_user.svg",
                                                            "className": "login-icons"
                                                        }
                                                    ],
                                                    "id": "eb7f4139-93e9-11ea-99e4-a504a663bd48"
                                                }
                                            ],
                                            "id": "eb7f4138-93e9-11ea-99e4-a504a663bd48"
                                        },
                                        {
                                            "nodeType": "DIV",
                                            "type": "container",
                                            "className": "row mt-3",
                                            "children": [
                                                {
                                                    "nodeType": "DIV",
                                                    "type": "container",
                                                    "className": "col",
                                                    "children": [
                                                        {
                                                            "nodeType": "INPUT",
                                                            "type": "inputText",
                                                            "className": "form-control login-form-input txt-homeowner-login",
                                                            "children": [],
                                                            "placeholder": "Password",
                                                            "id": "eb7f413d-93e9-11ea-99e4-a504a663bd48",
                                                            "name": "password",
                                                            "password": true,
                                                            "variant": "standard"
                                                        },
                                                        {
                                                            "id": "3a4be650-ad90-11ea-a81e-a3c623c48bc9",
                                                            "type": "image",
                                                            "children": [],
                                                            "url": "./assets/images/icon_login_password.svg",
                                                            "className": "login-icons"
                                                        },
                                                        {
                                                            "id": "5c31f5f0-ad92-11ea-a2a7-ad35397c4d32",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "5f285290-ad92-11ea-a2a7-ad35397c4d32",
                                                                    "type": "paragraph",
                                                                    "children": [],
                                                                    "text": "[Forgot your password?](https://www.froala.com)",
                                                                    "_sortIndex": -1
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "mt-3 forgot-password-container",
                                                            "flex": "[object Object]"
                                                        }
                                                    ],
                                                    "id": "eb7f413c-93e9-11ea-99e4-a504a663bd48"
                                                }
                                            ],
                                            "id": "eb7f413b-93e9-11ea-99e4-a504a663bd48"
                                        },
                                        {
                                            "nodeType": "DIV",
                                            "type": "container",
                                            "className": "row mt-4",
                                            "children": [
                                                {
                                                    "nodeType": "DIV",
                                                    "type": "container",
                                                    "className": "col",
                                                    "children": [
                                                        {
                                                            "id": "9ceca3a0-db42-11ea-942a-259edbe0bbf2",
                                                            "type": "button",
                                                            "children": [],
                                                            "command": "portal_login",
                                                            "text": "Login",
                                                            "className": "btn-homeowner-login"
                                                        }
                                                    ],
                                                    "id": "eb7f4140-93e9-11ea-99e4-a504a663bd48"
                                                }
                                            ],
                                            "id": "eb7f413f-93e9-11ea-99e4-a504a663bd48"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "nodeType": "DIV",
                                    "className": "",
                                    "component": "Login"
                                }
                            ],
                            "orientation": "vertical",
                            "name": "login",
                            "component": "",
                            "_sortIndex": 710
                        },
                        {
                            "type": "view",
                            "name": "searchbar",
                            "orientation": "horizontal",
                            "component": "Searchbar",
                            "children": [
                                {
                                    "name": "search",
                                    "type": "inputText",
                                    "id": "e7db5108-8c4a-11ea-9466-6f383d5eb483"
                                },
                                {
                                    "id": "e0073b50-8c64-11ea-b629-0161308cc31c",
                                    "type": "button",
                                    "children": [],
                                    "command": "new",
                                    "name": "",
                                    "text": "New"
                                }
                            ],
                            "id": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                            "active": false,
                            "_sortIndex": 720
                        },
                        {
                            "id": "75908760-ea54-11ea-8de6-3f209e03f86c",
                            "type": "view",
                            "children": [
                                {
                                    "id": "8a890750-ea54-11ea-8de6-3f209e03f86c",
                                    "type": "inputSelect",
                                    "children": [],
                                    "name": "status",
                                    "label": "status",
                                    "options": "[{\"label\": \"Approved\", \"value\": \"approved\"}, {\"label\": \"Pending\", \"value\": \"pending\"}, {\"label\": \"Canceled\", \"value\": \"canceled\"}]",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "vertical",
                            "name": "status"
                        }
                    ],
                    "server": "pacificland.bitesizedigital.net",
                    "undoLength": 112,
                    "name": "pacific land - midpoint",
                    "_state": {},
                    "activeApp": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                    "_locked": false,
                    "updateKey": "ea99bc30-ec1a-11ea-a454-09ed37364e43",
                    "": {
                        "id": "5f30e16ddf453329f6ef693d",
                        "type": "project",
                        "children": [
                            {
                                "id": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
                                "type": "app",
                                "name": "dashboard",
                                "sidebar": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                "active": true,
                                "component": "",
                                "_sortIndex": 70
                            },
                            {
                                "id": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                                "type": "app",
                                "children": [],
                                "name": "homeowners",
                                "sidebar": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                                "startPage": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                "active": false,
                                "_sortIndex": 340
                            },
                            {
                                "id": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "35bfcda0-9138-11ea-bd78-399a56c40918",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "name": "homeowner",
                                        "_sortIndex": -1
                                    },
                                    {
                                        "id": "3040a340-9138-11ea-bd78-399a56c40918",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "name",
                                        "_sortIndex": 0
                                    },
                                    {
                                        "id": "40b4ac80-9138-11ea-bd78-399a56c40918",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "description"
                                    },
                                    {
                                        "id": "45aeef70-9138-11ea-bd78-399a56c40918",
                                        "type": "field:number",
                                        "children": [],
                                        "dataType": "number",
                                        "name": "amount"
                                    },
                                    {
                                        "id": "6fa1c410-9138-11ea-bd78-399a56c40918",
                                        "type": "field:json",
                                        "children": [],
                                        "dataType": "json",
                                        "name": "details"
                                    }
                                ],
                                "name": "billing",
                                "_sortIndex": 0
                            },
                            {
                                "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:enumeration",
                                        "children": [],
                                        "dataType": "enumeration",
                                        "name": "type"
                                    },
                                    {
                                        "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "details"
                                    },
                                    {
                                        "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:file",
                                        "children": [],
                                        "dataType": "file",
                                        "collection": "file",
                                        "via": "related",
                                        "allowedTypes": [
                                            "images",
                                            "files",
                                            "videos"
                                        ],
                                        "plugin": "upload",
                                        "name": "photos"
                                    },
                                    {
                                        "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "name": "homeowner"
                                    }
                                ],
                                "name": "comments-suggestions",
                                "_sortIndex": 40
                            },
                            {
                                "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:enumeration",
                                        "children": [],
                                        "dataType": "enumeration",
                                        "name": "type"
                                    },
                                    {
                                        "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "details"
                                    },
                                    {
                                        "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:file",
                                        "children": [],
                                        "dataType": "file",
                                        "collection": "file",
                                        "via": "related",
                                        "allowedTypes": [
                                            "images",
                                            "files",
                                            "videos"
                                        ],
                                        "plugin": "upload",
                                        "name": "photos"
                                    },
                                    {
                                        "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "name": "homeowner"
                                    }
                                ],
                                "name": "complaint",
                                "_sortIndex": 40
                            },
                            {
                                "id": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "1ba0dd50-8ce8-11ea-a283-ebcb859bf8c8",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "name",
                                        "required": true,
                                        "searchable": true
                                    },
                                    {
                                        "id": "22702e60-8ce8-11ea-a283-ebcb859bf8c8",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "description",
                                        "required": true
                                    }
                                ],
                                "name": "department",
                                "active": false,
                                "_sortIndex": 90
                            },
                            {
                                "id": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "7288d810-8c4e-11ea-9466-6f383d5eb483",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "employeeid",
                                        "required": true,
                                        "searchable": true,
                                        "_sortIndex": 0
                                    },
                                    {
                                        "id": "49b35e80-9129-11ea-a5e9-ff76f0b9af84",
                                        "type": "field:email",
                                        "children": [],
                                        "dataType": "email",
                                        "name": "email",
                                        "required": true,
                                        "_sortIndex": 9
                                    },
                                    {
                                        "id": "780a4c60-8c4e-11ea-9466-6f383d5eb483",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "firstName",
                                        "required": true,
                                        "searchable": true,
                                        "_sortIndex": 10
                                    },
                                    {
                                        "id": "837ebb80-8c4e-11ea-9466-6f383d5eb483",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "lastName",
                                        "required": true,
                                        "searchable": true,
                                        "_sortIndex": 20
                                    },
                                    {
                                        "id": "e68f77d0-8c91-11ea-bb0b-0b00cf6ac98c",
                                        "type": "field:date",
                                        "children": [],
                                        "dataType": "date",
                                        "format": "mm/dd/yyyy",
                                        "name": "birthday",
                                        "required": true,
                                        "_sortIndex": 30
                                    },
                                    {
                                        "id": "c11b9d10-8e7d-11ea-810e-c1e68e180dd7",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                        "name": "department",
                                        "_sortIndex": 40
                                    },
                                    {
                                        "id": "5979e880-8e9e-11ea-9c0f-c7e69d58f37d",
                                        "type": "field:file",
                                        "children": [],
                                        "dataType": "file",
                                        "collection": "file",
                                        "via": "related",
                                        "allowedTypes": [
                                            "images",
                                            "files",
                                            "videos"
                                        ],
                                        "plugin": "upload",
                                        "name": "picture",
                                        "_sortIndex": 50
                                    }
                                ],
                                "name": "employee",
                                "active": false,
                                "_sortIndex": 120
                            },
                            {
                                "id": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "06bec240-911f-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "accountNumber",
                                        "_sortIndex": -1
                                    },
                                    {
                                        "id": "3f18fbb0-9129-11ea-a5e9-ff76f0b9af84",
                                        "type": "field:email",
                                        "children": [],
                                        "dataType": "email",
                                        "name": "email",
                                        "required": true,
                                        "_sortIndex": 0
                                    },
                                    {
                                        "id": "f15753e0-911e-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "firstName",
                                        "required": true,
                                        "_sortIndex": 10
                                    },
                                    {
                                        "id": "f67bdb20-911e-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "lastName",
                                        "required": true,
                                        "_sortIndex": 20
                                    },
                                    {
                                        "id": "fa966c70-911e-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "middleInitial",
                                        "_sortIndex": 30
                                    },
                                    {
                                        "id": "0cbdf210-911f-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "unit",
                                        "_sortIndex": 50
                                    },
                                    {
                                        "id": "101e3410-911f-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "building",
                                        "_sortIndex": 60
                                    },
                                    {
                                        "id": "138b4750-911f-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "contact",
                                        "_sortIndex": 70
                                    }
                                ],
                                "name": "homeowner",
                                "_sortIndex": 150
                            },
                            {
                                "id": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "b3aa23e0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "name": "homeowner"
                                    },
                                    {
                                        "id": "c20c3d10-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:enumeration",
                                        "children": [],
                                        "dataType": "enumeration",
                                        "name": "topic"
                                    },
                                    {
                                        "id": "d7f25150-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "title"
                                    },
                                    {
                                        "id": "dd8b6c50-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "price"
                                    },
                                    {
                                        "id": "e1c656e0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "description"
                                    },
                                    {
                                        "id": "eb73c1a0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "contactNumber"
                                    },
                                    {
                                        "id": "f1279090-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:file",
                                        "children": [],
                                        "dataType": "file",
                                        "collection": "file",
                                        "via": "related",
                                        "allowedTypes": [
                                            "images",
                                            "files",
                                            "videos"
                                        ],
                                        "plugin": "upload",
                                        "name": "photos"
                                    },
                                    {
                                        "id": "ff6cabe0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "status"
                                    },
                                    {
                                        "id": "2cdc7120-db5c-11ea-968c-6f86147884ca",
                                        "type": "field:json",
                                        "children": [],
                                        "dataType": "json",
                                        "name": "comments"
                                    }
                                ],
                                "_sortIndex": 729,
                                "name": "message-board"
                            },
                            {
                                "id": "77e05b60-911e-11ea-9e19-2d2007118721",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "7bedcd50-911e-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "name",
                                        "required": true
                                    },
                                    {
                                        "id": "8093aeb0-911e-11ea-9e19-2d2007118721",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "text",
                                        "required": true
                                    },
                                    {
                                        "id": "88181ef0-911e-11ea-9e19-2d2007118721",
                                        "type": "field:date",
                                        "children": [],
                                        "dataType": "date",
                                        "format": "mm/dd/yyyy",
                                        "name": "noticeStart",
                                        "required": true
                                    },
                                    {
                                        "id": "97459e70-911e-11ea-9e19-2d2007118721",
                                        "type": "field:date",
                                        "children": [],
                                        "dataType": "date",
                                        "format": "mm/dd/yyyy",
                                        "name": "noticeEnd"
                                    },
                                    {
                                        "id": "9eb945d0-911e-11ea-9e19-2d2007118721",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "source"
                                    },
                                    {
                                        "id": "a096b760-9129-11ea-a5e9-ff76f0b9af84",
                                        "type": "field",
                                        "children": [],
                                        "dataType": "string",
                                        "name": "status"
                                    }
                                ],
                                "name": "notification",
                                "_sortIndex": 360
                            },
                            {
                                "id": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "c4081f00-d9c6-11ea-93cf-3dd7e562aac3",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "name": "unit"
                                    },
                                    {
                                        "id": "d7708910-d9c6-11ea-93cf-3dd7e562aac3",
                                        "type": "field:enumeration",
                                        "children": [],
                                        "dataType": "enumeration",
                                        "name": "type"
                                    },
                                    {
                                        "id": "e5db54d0-d9c6-11ea-93cf-3dd7e562aac3",
                                        "type": "field:date",
                                        "children": [],
                                        "dataType": "date",
                                        "format": "mm/dd/yyyy",
                                        "name": "schedule"
                                    },
                                    {
                                        "id": "ee4c9610-d9c6-11ea-93cf-3dd7e562aac3",
                                        "type": "field:json",
                                        "children": [],
                                        "dataType": "json",
                                        "name": "items"
                                    },
                                    {
                                        "id": "f2ed6e60-d9c6-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "company"
                                    },
                                    {
                                        "id": "00b0fda0-d9c7-11ea-93cf-3dd7e562aac3",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "status"
                                    }
                                ],
                                "name": "pass-delivery",
                                "_sortIndex": 390
                            },
                            {
                                "id": "a5454c50-911e-11ea-9e19-2d2007118721",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "c7d2c620-d9c7-11ea-93cf-3dd7e562aac3",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "name": "unit"
                                    },
                                    {
                                        "id": "cfdbf6c0-d9c7-11ea-93cf-3dd7e562aac3",
                                        "type": "field:json",
                                        "children": [],
                                        "dataType": "json",
                                        "name": "visitor",
                                        "required": true
                                    },
                                    {
                                        "id": "fa4d2320-d9c7-11ea-93cf-3dd7e562aac3",
                                        "type": "field:date",
                                        "children": [],
                                        "dataType": "date",
                                        "format": "mm/dd/yyyy",
                                        "name": "startDate"
                                    },
                                    {
                                        "id": "ff0ec9e0-d9c7-11ea-93cf-3dd7e562aac3",
                                        "type": "field:date",
                                        "children": [],
                                        "dataType": "date",
                                        "format": "mm/dd/yyyy",
                                        "name": "endDate"
                                    },
                                    {
                                        "id": "06a73750-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "field:file",
                                        "children": [],
                                        "dataType": "file",
                                        "collection": "file",
                                        "via": "related",
                                        "allowedTypes": [
                                            "images",
                                            "files",
                                            "videos"
                                        ],
                                        "plugin": "upload",
                                        "name": "visitorsId"
                                    },
                                    {
                                        "id": "7b087c60-da1a-11ea-b50d-8323e621ee63",
                                        "type": "field:enumeration",
                                        "children": [],
                                        "dataType": "enumeration",
                                        "name": "type"
                                    }
                                ],
                                "name": "pass-visitor",
                                "_sortIndex": 420
                            },
                            {
                                "id": "555659e0-9138-11ea-bd78-399a56c40918",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "5a734000-9138-11ea-bd78-399a56c40918",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                        "name": "billing"
                                    },
                                    {
                                        "id": "5ecc8800-9138-11ea-bd78-399a56c40918",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "name": "homeowner"
                                    },
                                    {
                                        "id": "697da720-9138-11ea-bd78-399a56c40918",
                                        "type": "field:number",
                                        "children": [],
                                        "dataType": "number",
                                        "name": "amount"
                                    }
                                ],
                                "name": "payment",
                                "_sortIndex": 450
                            },
                            {
                                "id": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "a86f3310-d84e-11ea-acd1-c1cd20d0424a",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "number"
                                    },
                                    {
                                        "id": "b91aee20-d84e-11ea-acd1-c1cd20d0424a",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "address"
                                    },
                                    {
                                        "id": "bc50ebd0-d84e-11ea-acd1-c1cd20d0424a",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "description"
                                    },
                                    {
                                        "id": "c2e7a0b0-d84e-11ea-acd1-c1cd20d0424a",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "name": "homeowner",
                                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                    },
                                    {
                                        "id": "ae9f6c60-d9ab-11ea-b236-955d418fe434",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "status"
                                    }
                                ],
                                "name": "unit",
                                "_sortIndex": 560
                            },
                            {
                                "id": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "ffb19150-d84d-11ea-acd1-c1cd20d0424a",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "type"
                                    },
                                    {
                                        "id": "09a553e0-d84e-11ea-acd1-c1cd20d0424a",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "status"
                                    },
                                    {
                                        "id": "125e2570-d84e-11ea-acd1-c1cd20d0424a",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "name": "unit"
                                    }
                                ],
                                "name": "unit-request",
                                "_sortIndex": 580
                            },
                            {
                                "id": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                                "type": "model",
                                "children": [
                                    {
                                        "id": "59d52f00-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:relation",
                                        "children": [],
                                        "dataType": "relation",
                                        "name": "unit",
                                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                    },
                                    {
                                        "id": "5fa7a980-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:enumeration",
                                        "children": [],
                                        "dataType": "enumeration",
                                        "name": "natureOfWork"
                                    },
                                    {
                                        "id": "680a92e0-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "contractor"
                                    },
                                    {
                                        "id": "6cc22780-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "personInCharge"
                                    },
                                    {
                                        "id": "77ba88d0-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "contactPersonInCharge"
                                    },
                                    {
                                        "id": "82778ed0-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:json",
                                        "children": [],
                                        "dataType": "json",
                                        "name": "workers"
                                    },
                                    {
                                        "id": "87d65ff0-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "description"
                                    },
                                    {
                                        "id": "951e5690-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:json",
                                        "children": [],
                                        "dataType": "json",
                                        "name": "materials"
                                    },
                                    {
                                        "id": "9a41cc60-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:json",
                                        "children": [],
                                        "dataType": "json",
                                        "name": "tools"
                                    },
                                    {
                                        "id": "a0da7d10-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:date",
                                        "children": [],
                                        "dataType": "date",
                                        "format": "mm/dd/yyyy",
                                        "name": "startDate"
                                    },
                                    {
                                        "id": "a5a376d0-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:date",
                                        "children": [],
                                        "dataType": "date",
                                        "format": "mm/dd/yyyy",
                                        "name": "endDate"
                                    },
                                    {
                                        "id": "abf13bd0-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:file",
                                        "children": [],
                                        "dataType": "file",
                                        "collection": "file",
                                        "via": "related",
                                        "allowedTypes": [
                                            "images",
                                            "files",
                                            "videos"
                                        ],
                                        "plugin": "upload",
                                        "name": "plans"
                                    },
                                    {
                                        "id": "afc85b80-dd5a-11ea-b1bd-f3d0d3926db1",
                                        "type": "field:text",
                                        "children": [],
                                        "dataType": "text",
                                        "name": "status"
                                    }
                                ],
                                "name": "work-permit"
                            },
                            {
                                "id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                        "children": [
                                            {
                                                "id": "7aa7e100-9138-11ea-bd78-399a56c40918",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7aa7e102-9138-11ea-bd78-399a56c40918",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "homeowner",
                                                        "label": "homeowner",
                                                        "dataField": "homeowner",
                                                        "saveObject": true,
                                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "7aa7e103-9138-11ea-bd78-399a56c40918",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7aa7e105-9138-11ea-bd78-399a56c40918",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "name",
                                                        "label": "name",
                                                        "dataField": "name"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "7aa7e106-9138-11ea-bd78-399a56c40918",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7aa7e108-9138-11ea-bd78-399a56c40918",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "description",
                                                        "label": "description",
                                                        "dataField": "description"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "7aa7e109-9138-11ea-bd78-399a56c40918",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7aa7e10b-9138-11ea-bd78-399a56c40918",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "amount",
                                                        "label": "amount",
                                                        "dataField": "amount"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "7aa7e10c-9138-11ea-bd78-399a56c40918",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7aa7e10e-9138-11ea-bd78-399a56c40918",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "details",
                                                        "label": "details",
                                                        "dataField": "details"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "7aa39b40-9138-11ea-bd78-399a56c40918",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "billing-detail-page",
                                "route": "/billings/:id",
                                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                "component": "",
                                "_sortIndex": 10
                            },
                            {
                                "id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                        "children": [
                                            {
                                                "id": "7ab04570-9138-11ea-bd78-399a56c40918",
                                                "type": "subView",
                                                "children": [],
                                                "name": "searchbar",
                                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "7ab48b30-9138-11ea-bd78-399a56c40918",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "7ab94620-9138-11ea-bd78-399a56c40918",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "homeowner",
                                                        "label": "homeowner",
                                                        "dataField": "homeowner"
                                                    },
                                                    {
                                                        "id": "7ab94622-9138-11ea-bd78-399a56c40918",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "name",
                                                        "label": "name",
                                                        "dataField": "name"
                                                    },
                                                    {
                                                        "id": "7ab94624-9138-11ea-bd78-399a56c40918",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "description",
                                                        "label": "description",
                                                        "dataField": "description"
                                                    },
                                                    {
                                                        "id": "7ab94626-9138-11ea-bd78-399a56c40918",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "amount",
                                                        "label": "amount",
                                                        "dataField": "amount"
                                                    },
                                                    {
                                                        "id": "7ab94628-9138-11ea-bd78-399a56c40918",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "details",
                                                        "label": "details",
                                                        "dataField": "details"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "table"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "billing-list-page",
                                "route": "/billings-list",
                                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                "_sortIndex": 20
                            },
                            {
                                "id": "77bdc480-dd57-11ea-9ac6-91ffe70e2d7c",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "77a819a1-dd57-11ea-9ac6-91ffe70e2d7c",
                                        "children": [
                                            {
                                                "id": "77c369d0-dd57-11ea-9ac6-91ffe70e2d7c",
                                                "type": "subView",
                                                "children": [],
                                                "name": "searchbar",
                                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "77c824c0-dd57-11ea-9ac6-91ffe70e2d7c",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "77cd54e0-dd57-11ea-9ac6-91ffe70e2d7c",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "type",
                                                        "label": "type",
                                                        "dataField": "type"
                                                    },
                                                    {
                                                        "id": "77cd54e2-dd57-11ea-9ac6-91ffe70e2d7c",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "details",
                                                        "label": "details",
                                                        "dataField": "details"
                                                    },
                                                    {
                                                        "id": "77cd7bf0-dd57-11ea-9ac6-91ffe70e2d7c",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "photos",
                                                        "label": "photos",
                                                        "dataField": "photos"
                                                    },
                                                    {
                                                        "id": "77cd7bf2-dd57-11ea-9ac6-91ffe70e2d7c",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "homeowner",
                                                        "label": "homeowner",
                                                        "dataField": "homeowner"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "table"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "comments-suggestions-list-page",
                                "route": "/comments-suggestions-list",
                                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1"
                            },
                            {
                                "id": "0c4b0b30-914f-11ea-b2e4-0783314da7b1",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                        "children": [
                                            {
                                                "id": "0c5cbe70-914f-11ea-b2e4-0783314da7b1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0c5cbe72-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "homeowner",
                                                        "label": "homeowner",
                                                        "dataField": "homeowner",
                                                        "saveObject": true,
                                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                                        "popup": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 0
                                            },
                                            {
                                                "id": "c9f10042-914f-11ea-b2e4-0783314da7b1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c9f10044-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "item",
                                                        "label": "item",
                                                        "dataField": "item"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 10
                                            },
                                            {
                                                "id": "0c5cbe73-914f-11ea-b2e4-0783314da7b1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0c5cbe75-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "details",
                                                        "label": "details",
                                                        "dataField": "details"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 20
                                            },
                                            {
                                                "id": "0c5cbe76-914f-11ea-b2e4-0783314da7b1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0c5cbe78-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "photo",
                                                        "label": "photo",
                                                        "dataField": "photo",
                                                        "basePath": "complaints"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 30
                                            },
                                            {
                                                "id": "c9f0d930-914f-11ea-b2e4-0783314da7b1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c9f10041-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "category",
                                                        "label": "category",
                                                        "dataField": "category"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 39
                                            },
                                            {
                                                "id": "0c5cbe79-914f-11ea-b2e4-0783314da7b1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0c5cbe7b-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "status",
                                                        "label": "status",
                                                        "dataField": "status"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 40
                                            },
                                            {
                                                "id": "9bf98160-9151-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9bf98162-9151-11ea-9407-3325ebf78f84",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "location",
                                                        "label": "location",
                                                        "dataField": "location"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "ac07cb60-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ac07cb62-d9c8-11ea-93cf-3dd7e562aac3",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "type",
                                                        "label": "type",
                                                        "dataField": "type"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "ac07f270-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ac07f272-d9c8-11ea-93cf-3dd7e562aac3",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "photos",
                                                        "label": "photos",
                                                        "dataField": "photos",
                                                        "basePath": "complaints"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "className": ""
                                    },
                                    {
                                        "id": "0c56cb00-914f-11ea-b2e4-0783314da7b1",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "complaint-detail-page",
                                "route": "/complaints/:id",
                                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                "_sortIndex": 50
                            },
                            {
                                "id": "0c6263c0-914f-11ea-b2e4-0783314da7b1",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                        "children": [
                                            {
                                                "id": "0c67e200-914f-11ea-b2e4-0783314da7b1",
                                                "type": "subView",
                                                "children": [],
                                                "name": "searchbar",
                                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "0c6d6040-914f-11ea-b2e4-0783314da7b1",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "0c72b770-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "homeowner",
                                                        "label": "homeowner",
                                                        "dataField": "homeowner"
                                                    },
                                                    {
                                                        "id": "0c72b772-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "details",
                                                        "label": "details",
                                                        "dataField": "details"
                                                    },
                                                    {
                                                        "id": "0c72b774-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "photo",
                                                        "label": "photo",
                                                        "dataField": "photo"
                                                    },
                                                    {
                                                        "id": "0c72b776-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "status",
                                                        "label": "status",
                                                        "dataField": "status"
                                                    },
                                                    {
                                                        "id": "c9fa4f10-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "category",
                                                        "label": "category",
                                                        "dataField": "category"
                                                    },
                                                    {
                                                        "id": "c9fa4f12-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "item",
                                                        "label": "item",
                                                        "dataField": "item"
                                                    },
                                                    {
                                                        "id": "9bffc2f0-9151-11ea-9407-3325ebf78f84",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "location",
                                                        "label": "location",
                                                        "dataField": "location"
                                                    },
                                                    {
                                                        "id": "ac0c1120-d9c8-11ea-93cf-3dd7e562aac3",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "type",
                                                        "label": "type",
                                                        "dataField": "type"
                                                    },
                                                    {
                                                        "id": "ac0c1122-d9c8-11ea-93cf-3dd7e562aac3",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "photos",
                                                        "label": "photos",
                                                        "dataField": "photos"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "table"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "complaint-list-page",
                                "route": "/complaints-list",
                                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                "_sortIndex": 60
                            },
                            {
                                "id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                        "children": [
                                            {
                                                "id": "99dbbf60-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "99dbbf62-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "name",
                                                        "label": "Department",
                                                        "dataField": "name"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "99dbbf63-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "99dbbf65-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "description",
                                                        "label": "Description",
                                                        "dataField": "description",
                                                        "description": "",
                                                        "multiline": true
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "99d8b220-912e-11ea-ad57-6526d978cdf6",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "department-detail-page",
                                "route": "/departments/:id",
                                "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                "_sortIndex": 100
                            },
                            {
                                "id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                        "children": [
                                            {
                                                "id": "94311830-912e-11ea-ad57-6526d978cdf6",
                                                "type": "subView",
                                                "children": [],
                                                "name": "searchbar",
                                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "9433b040-912e-11ea-ad57-6526d978cdf6",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "94364850-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "name",
                                                        "label": "Name",
                                                        "dataField": "name"
                                                    },
                                                    {
                                                        "id": "94364852-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "description",
                                                        "label": "Description",
                                                        "dataField": "description"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "table"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "department-list-page",
                                "route": "/departments-list",
                                "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                "_sortIndex": 110
                            },
                            {
                                "id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                        "children": [
                                            {
                                                "id": "a5a55360-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a5a55362-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "employeeid",
                                                        "label": "employeeid",
                                                        "dataField": "employeeid"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a5a55363-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a5a55365-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "email",
                                                        "label": "email",
                                                        "dataField": "email"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a5a55366-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a5a55368-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "firstName",
                                                        "label": "firstName",
                                                        "dataField": "firstName"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a5a55369-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a5a5536b-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "lastName",
                                                        "label": "lastName",
                                                        "dataField": "lastName"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a5a5536c-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a5a5536e-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "birthday",
                                                        "label": "birthday",
                                                        "dataField": "birthday",
                                                        "format": "mm/dd/yyyy"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a5a5536f-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a5a55371-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "department",
                                                        "label": "department",
                                                        "dataField": "department",
                                                        "saveObject": true,
                                                        "relation": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a5a55372-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a5a55374-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "picture",
                                                        "label": "picture",
                                                        "dataField": "picture",
                                                        "basePath": "employees"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "a5a26d30-912e-11ea-ad57-6526d978cdf6",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "employee-detail-page",
                                "route": "/employees/:id",
                                "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                "_sortIndex": 130
                            },
                            {
                                "id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                        "children": [
                                            {
                                                "id": "a5aa8380-912e-11ea-ad57-6526d978cdf6",
                                                "type": "subView",
                                                "children": [],
                                                "name": "searchbar",
                                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "a5ae05f0-912e-11ea-ad57-6526d978cdf6",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "a5b0c510-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "employeeid",
                                                        "label": "employeeid",
                                                        "dataField": "employeeid"
                                                    },
                                                    {
                                                        "id": "a5b0c512-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "email",
                                                        "label": "email",
                                                        "dataField": "email"
                                                    },
                                                    {
                                                        "id": "a5b0c514-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "firstName",
                                                        "label": "firstName",
                                                        "dataField": "firstName"
                                                    },
                                                    {
                                                        "id": "a5b0c516-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "lastName",
                                                        "label": "lastName",
                                                        "dataField": "lastName"
                                                    },
                                                    {
                                                        "id": "a5b0c518-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "birthday",
                                                        "label": "birthday",
                                                        "dataField": "birthday"
                                                    },
                                                    {
                                                        "id": "a5b0c51a-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "department",
                                                        "label": "department",
                                                        "dataField": "department"
                                                    },
                                                    {
                                                        "id": "a5b0c51c-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "picture",
                                                        "label": "picture",
                                                        "dataField": "picture"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "table"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "employee-list-page",
                                "route": "/employees-list",
                                "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                "_sortIndex": 140
                            },
                            {
                                "id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "4840fc41-9149-11ea-a6b6-e7e287632ad5",
                                        "children": [
                                            {
                                                "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "My Account",
                                                "className": "has-text-centered"
                                            },
                                            {
                                                "id": "60c4c5d0-9149-11ea-a6b6-e7e287632ad5",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "22f17b80-914a-11ea-8caa-6d37b4107440",
                                                        "type": "paragraph",
                                                        "children": [],
                                                        "text": "Account Name:"
                                                    },
                                                    {
                                                        "id": "24c48dd0-914a-11ea-8caa-6d37b4107440",
                                                        "type": "paragraph",
                                                        "children": [],
                                                        "name": "accountName",
                                                        "dataField": "firstName"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "accountName",
                                                "className": "py-1"
                                            },
                                            {
                                                "id": "370763a0-914a-11ea-8caa-6d37b4107440",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "43754df0-914a-11ea-8caa-6d37b4107440",
                                                        "type": "textBlock",
                                                        "children": [],
                                                        "text": "Email Address:"
                                                    },
                                                    {
                                                        "id": "4bf0a150-914a-11ea-8caa-6d37b4107440",
                                                        "type": "paragraph",
                                                        "children": [],
                                                        "name": "email",
                                                        "text": "",
                                                        "dataField": "email"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "email",
                                                "className": "py-1"
                                            },
                                            {
                                                "id": "39ff46e0-914a-11ea-8caa-6d37b4107440",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "46fa07e0-914a-11ea-8caa-6d37b4107440",
                                                        "type": "textBlock",
                                                        "children": [],
                                                        "text": "Contact Number:"
                                                    },
                                                    {
                                                        "id": "49cfbd20-914a-11ea-8caa-6d37b4107440",
                                                        "type": "paragraph",
                                                        "children": [],
                                                        "name": "phoneNumber",
                                                        "text": "",
                                                        "dataField": "contact"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "phoneNumber",
                                                "className": "py-1"
                                            },
                                            {
                                                "id": "47ffc560-a551-11ea-b01c-9d4389ff2ddd",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "56a0e360-a551-11ea-b01c-9d4389ff2ddd",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "6bd54d90-914a-11ea-8caa-6d37b4107440",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "",
                                                                "path": "/billing",
                                                                "_sortIndex": -1,
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_balance.svg\"><img src=\"./assets/images/icon_balance.svg\" /></object>View Balance</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "67303cd0-a551-11ea-b01c-9d4389ff2ddd",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "6dde15e0-914a-11ea-8caa-6d37b4107440",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "",
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pay.svg\"><img src=\"./assets/images/icon_pay.svg\" /></object>Pay Dues</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "columns is-centered mt-2"
                                            },
                                            {
                                                "id": "7ac953d0-a551-11ea-b01c-9d4389ff2ddd",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7e5f4bd0-a551-11ea-b01c-9d4389ff2ddd",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "6fb0b300-914a-11ea-8caa-6d37b4107440",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "",
                                                                "path": "/change-password",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_password.svg\"><img src=\"./assets/images/icon_password.svg\" /></object>Change Password</div>",
                                                                "className": "app-menu-item"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "81fc96d0-a551-11ea-b01c-9d4389ff2ddd",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "718ee8e0-914a-11ea-8caa-6d37b4107440",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "",
                                                                "path": "/profile/me",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_profile.svg\"><img src=\"./assets/images/icon_profile.svg\" /></object>My Profile</div>",
                                                                "className": "app-menu-item"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "columns is-centered"
                                            }
                                        ],
                                        "component": "Account",
                                        "className": ""
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-account",
                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                "route": "/my-account/:id",
                                "component": "",
                                "_sortIndex": 160
                            },
                            {
                                "id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                                "type": "page",
                                "children": [
                                    {
                                        "id": "62b4dc20-96d2-11ea-8416-c79b465e39ab",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "6560c240-96d2-11ea-8416-c79b465e39ab",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Billing"
                                            },
                                            {
                                                "id": "71d451e0-96d2-11ea-8416-c79b465e39ab",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "7b996350-96d2-11ea-8416-c79b465e39ab",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-billing",
                                "route": "/billing",
                                "_sortIndex": 170
                            },
                            {
                                "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                        "children": [
                                            {
                                                "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Comments and Suggestions",
                                                "_sortIndex": 0,
                                                "name": "",
                                                "className": "has-text-centered"
                                            },
                                            {
                                                "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "View complaint history",
                                                        "path": "/filed-complaints",
                                                        "variant": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 9,
                                                "className": "justify-content-center"
                                            },
                                            {
                                                "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "dataField": "type",
                                                        "label": "Type of issue",
                                                        "options": "[{\"label\": \"Repairs needed\", \"value\": \"repairs-needed\"},{\"label\": \"Cleanliness\", \"value\": \"cleanliness\"},{\"label\": \"Noise levels\", \"value\": \"noise-levels\"},{\"label\": \"Unruly residents\", \"value\": \"unruly-residents\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 10,
                                                "className": ""
                                            },
                                            {
                                                "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "details",
                                                        "label": "Details of the issue/concern",
                                                        "multiline": true,
                                                        "name": ""
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 20,
                                                "className": ""
                                            },
                                            {
                                                "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "label": "Photo",
                                                        "dataField": "photo"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 40,
                                                "className": ""
                                            },
                                            {
                                                "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "Save",
                                                        "command": "save",
                                                        "variant": "contained",
                                                        "color": "primary",
                                                        "className": "",
                                                        "fullWidth": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 50,
                                                "className": ""
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-comments-suggestions",
                                "route": "/homeowner-comments-suggestions/:id",
                                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                "className": "",
                                "_sortIndex": 180
                            },
                            {
                                "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                        "children": [
                                            {
                                                "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Comments and Suggestions",
                                                "_sortIndex": 0,
                                                "name": "",
                                                "className": "has-text-centered"
                                            },
                                            {
                                                "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "View complaint history",
                                                        "path": "/filed-complaints",
                                                        "variant": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 9,
                                                "className": "justify-content-center"
                                            },
                                            {
                                                "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "dataField": "type",
                                                        "label": "Type of issue"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 10,
                                                "className": ""
                                            },
                                            {
                                                "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "details",
                                                        "label": "Details of the issue/concern",
                                                        "multiline": true,
                                                        "name": ""
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 20,
                                                "className": ""
                                            },
                                            {
                                                "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "label": "Photo",
                                                        "dataField": "photo"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 40,
                                                "className": ""
                                            },
                                            {
                                                "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "Save",
                                                        "command": "save",
                                                        "variant": "contained",
                                                        "color": "primary",
                                                        "className": "",
                                                        "fullWidth": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 50,
                                                "className": ""
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-complaint",
                                "route": "/file-complaint/:id",
                                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                "className": "",
                                "_sortIndex": 180
                            },
                            {
                                "id": "47440520-971c-11ea-8a71-fd00fe88963d",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "47440521-971c-11ea-8a71-fd00fe88963d",
                                        "children": [
                                            {
                                                "id": "4b1a6180-971c-11ea-8a71-fd00fe88963d",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Complaints",
                                                "className": "has-text-centered"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "52a624c0-971c-11ea-8a71-fd00fe88963d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "5d6c5280-971c-11ea-8a71-fd00fe88963d",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "63bf6eb0-971c-11ea-8a71-fd00fe88963d",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "dataField": "item"
                                                    },
                                                    {
                                                        "id": "6749a6e0-971c-11ea-8a71-fd00fe88963d",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "dataField": "item"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "5a577160-971c-11ea-8a71-fd00fe88963d",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                "route": "/filed-complaints",
                                "name": "homeowner-complaints",
                                "className": "",
                                "_sortIndex": 190
                            },
                            {
                                "id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "4e8b5d81-9157-11ea-8382-2991800d56bc",
                                        "children": [
                                            {
                                                "id": "268a56f0-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "name": "",
                                                "text": "Delivery/ Pull-out Pass"
                                            },
                                            {
                                                "id": "3cd79170-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "40347810-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "View History",
                                                        "variant": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "justify-content-center pt-1 pb-1"
                                            },
                                            {
                                                "id": "590cca40-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "5f544db0-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                        "dataField": "unit",
                                                        "label": "Unit"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "66e4a4d0-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "6b30cd70-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "dataField": "type",
                                                        "label": "Type of Pass",
                                                        "options": "[{\"label\": \"Delivery Pass\", \"value\": \"delivery-pass\"},{\"label\": \"Pull-Out Pass\", \"value\": \"pull-out-pass\"}]",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "7d085e50-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d3f88870-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "inputDateTime",
                                                        "children": [],
                                                        "dataField": "schedule",
                                                        "label": "Schedule"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "87486c20-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "8c652b30-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "inputList",
                                                        "children": [],
                                                        "label": "Item(s) - Please enumerate including quantities.",
                                                        "dataField": "items"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a733cf20-da0a-11ea-87ea-f5980a7a1cab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ac4da800-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "company",
                                                        "label": "Delivery Company"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "df2a4d60-dac7-11ea-9725-dfc9cbd591dc",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "e07a7410-dac7-11ea-9725-dfc9cbd591dc",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "save",
                                                        "text": "Submit",
                                                        "fullWidth": true,
                                                        "className": "mt-1"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-delivery-pass",
                                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                "route": "/create-delivery-pass/:id",
                                "className": "",
                                "_sortIndex": 200
                            },
                            {
                                "id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "db3a70c1-96c7-11ea-bb44-c34ef5a44500",
                                        "children": [
                                            {
                                                "id": "e5006c90-96c7-11ea-bb44-c34ef5a44500",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Delivery Passes"
                                            },
                                            {
                                                "id": "e8824050-96c7-11ea-bb44-c34ef5a44500",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "f0fafba0-96c7-11ea-bb44-c34ef5a44500",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "ae53bb10-96c8-11ea-bb44-c34ef5a44500",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "dataField": "courier"
                                                            },
                                                            {
                                                                "id": "b91bbd90-96c8-11ea-bb44-c34ef5a44500",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "dataField": "items"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "path": "/create-delivery-pass"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "ed6551c0-96c7-11ea-bb44-c34ef5a44500",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-delivery-passes",
                                "route": "/delivery-passes",
                                "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                "_sortIndex": 210
                            },
                            {
                                "id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "0ad19881-93ea-11ea-99e4-a504a663bd48"
                                    },
                                    {
                                        "id": "1acacc70-93ea-11ea-9384-d1e4789a27a9",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e1156440-93e9-11ea-99e4-a504a663bd48"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-login-page",
                                "component": "",
                                "route": "/login",
                                "_sortIndex": 240
                            },
                            {
                                "id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "63850e21-a55c-11ea-8feb-c7034be1bc66",
                                        "children": [
                                            {
                                                "id": "da5a80c0-a55c-11ea-99ce-d1e75946b32d",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Marketplace"
                                            },
                                            {
                                                "id": "ec182100-a55c-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "f232a100-a55c-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "07f88310-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "button",
                                                                "children": [],
                                                                "className": "app-menu-item",
                                                                "text": "",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_water.svg\"><img src=\"./assets/images/icon_water.svg\" /></object>Drinking Water</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "014dd100-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "26cecc90-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "button",
                                                                "children": [],
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_gas.svg\"><img src=\"./assets/images/icon_gas.svg\" /></object>Gasul</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "columns is-centered mt-2"
                                            },
                                            {
                                                "id": "b1d7e880-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "494d3680-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "516b0090-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "button",
                                                                "children": [],
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Rice</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "63098010-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "69f72e90-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "button",
                                                                "children": [],
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_laundry.svg\"><img src=\"./assets/images/icon_laundry.svg\" /></object>Laundry</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "columns is-centered"
                                            },
                                            {
                                                "id": "c6888a00-a55d-11ea-99ce-d1e75946b32d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ccd51680-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "d4ae5d80-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "button",
                                                                "children": [],
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pet.svg\"><img src=\"./assets/images/icon_pet.svg\" /></object>Pet Grooming</div>",
                                                                "className": "app-menu-item"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "f062cd90-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "f2893000-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "button",
                                                                "children": [],
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_cleaning.svg\"><img src=\"./assets/images/icon_cleaning.svg\" /></object>Cleaning Service</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "columns is-centered"
                                            }
                                        ],
                                        "className": "has-text-centered",
                                        "_sortIndex": -1
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-marketplace",
                                "route": "/market-place",
                                "_sortIndex": 470
                            },
                            {
                                "id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "231003d1-c920-11ea-9080-1563a0e60ff0",
                                        "className": "p1",
                                        "children": [
                                            {
                                                "id": "709bda20-c920-11ea-9080-1563a0e60ff0",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Message Board",
                                                "className": "has-text-centered"
                                            },
                                            {
                                                "id": "53470b10-c921-11ea-84bc-97bd01295e5b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "566450a0-c921-11ea-84bc-97bd01295e5b",
                                                        "type": "button",
                                                        "children": [],
                                                        "variant": "text",
                                                        "text": "New Post",
                                                        "path": "/new-post/0"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "justify-content-center pt-1 pb-1"
                                            },
                                            {
                                                "id": "5b0277e0-db4b-11ea-bdb5-fdc0741685fd",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "component": "MessageBoard"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-message-board",
                                "route": "/message-board",
                                "_sortIndex": 530,
                                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                            },
                            {
                                "id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "233b7c71-c927-11ea-84bc-97bd01295e5b",
                                        "children": [
                                            {
                                                "id": "521b2680-c927-11ea-84bc-97bd01295e5b",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "New Post",
                                                "className": "has-text-centered"
                                            },
                                            {
                                                "id": "59e133a0-c927-11ea-84bc-97bd01295e5b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "62ac7bc0-c927-11ea-84bc-97bd01295e5b",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "",
                                                        "label": "Post Topic",
                                                        "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"lf\", \"label\": \"LF (Looking For)\"}]",
                                                        "dataField": "topic"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "bite-select pt-2"
                                            },
                                            {
                                                "id": "df291320-c927-11ea-8d76-f7e6b086de0b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "e2116600-c927-11ea-8d76-f7e6b086de0b",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "",
                                                        "label": "Title",
                                                        "dataField": "title"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "eb70b0c0-c927-11ea-8d76-f7e6b086de0b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ed3236e0-c927-11ea-8d76-f7e6b086de0b",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "",
                                                        "label": "Price",
                                                        "dataField": "price"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "f42897f0-c927-11ea-8d76-f7e6b086de0b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "f98df8c0-c927-11ea-8d76-f7e6b086de0b",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "",
                                                        "label": "Description",
                                                        "multiline": true,
                                                        "dataField": "description"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "0b6fc2d0-c928-11ea-8d76-f7e6b086de0b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0d2784f0-c928-11ea-8d76-f7e6b086de0b",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "",
                                                        "label": "Contact Number",
                                                        "dataField": "contactNumber"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "373868e0-c928-11ea-8d76-f7e6b086de0b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "38f5f760-c928-11ea-8d76-f7e6b086de0b",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "",
                                                        "label": "Add Photos",
                                                        "dataField": "photos"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "bite-select"
                                            },
                                            {
                                                "id": "27ad7fc0-c92b-11ea-ad72-2b470c4935f8",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "28c00130-c92b-11ea-ad72-2b470c4935f8",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "Submit",
                                                        "fullWidth": true,
                                                        "command": "save",
                                                        "className": "mt-1"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-new-post",
                                "route": "/new-post/:id",
                                "_sortIndex": 540,
                                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                            },
                            {
                                "id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "c2c20490-91b3-11ea-ba7f-61310864338b",
                                        "children": [
                                            {
                                                "id": "d1894830-91b3-11ea-ba7f-61310864338b",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Notifications"
                                            },
                                            {
                                                "id": "26512330-96da-11ea-a306-e3cce4ba1daa",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "294a17e0-96da-11ea-a306-e3cce4ba1daa",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "2e1311a0-96da-11ea-a306-e3cce4ba1daa",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "327a8a70-96da-11ea-a306-e3cce4ba1daa",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "dataField": "name"
                                                            },
                                                            {
                                                                "id": "346c2140-96da-11ea-a306-e3cce4ba1daa",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "dataField": "text"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-notifications",
                                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                "route": "/notifications",
                                "_sortIndex": 250
                            },
                            {
                                "id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "a259bcb1-96d2-11ea-8416-c79b465e39ab",
                                        "children": [
                                            {
                                                "id": "a82b73e0-96d2-11ea-8416-c79b465e39ab",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Change password"
                                            },
                                            {
                                                "id": "d3e59b00-96d2-11ea-8416-c79b465e39ab",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "d63c5c40-96d2-11ea-8416-c79b465e39ab",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "aab74ee0-96d2-11ea-8416-c79b465e39ab",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-password",
                                "route": "/change-password",
                                "_sortIndex": 260
                            },
                            {
                                "id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "id": "2587d890-db5e-11ea-b61b-f3aa751f3b6f",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c5cddbb1-c92f-11ea-baed-470d8f727899",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "e297edd0-dc30-11ea-abb1-8b4052c10758",
                                                        "type": "carousel",
                                                        "children": [],
                                                        "dataField": "photos",
                                                        "text": "Preview",
                                                        "direction": "ltr",
                                                        "autoplay": false
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "main",
                                                "component": "",
                                                "flex": "1"
                                            },
                                            {
                                                "id": "3175e480-db5e-11ea-b61b-f3aa751f3b6f",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal",
                                                "flex": "1",
                                                "component": "MessageBoardInfo"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "856f1e40-dc39-11ea-b64f-0dfbc9206e7f",
                                        "type": "container",
                                        "children": [],
                                        "orientation": "horizontal",
                                        "component": "MessageBoardComments"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-post",
                                "route": "/post/:id",
                                "_sortIndex": 550,
                                "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                                "noPadding": true
                            },
                            {
                                "id": "17466790-96d2-11ea-9995-9d94cc393546",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "0e752c01-96d2-11ea-9995-9d94cc393546",
                                        "children": [
                                            {
                                                "id": "8ad561c0-96d2-11ea-8416-c79b465e39ab",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Profile"
                                            },
                                            {
                                                "id": "8de3b330-96d2-11ea-8416-c79b465e39ab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9c2e1e50-96d5-11ea-8416-c79b465e39ab",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "firstName",
                                                        "label": "First Name"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a6f620d0-96d5-11ea-8416-c79b465e39ab",
                                                "type": "inputText",
                                                "children": [],
                                                "dataField": "lastName",
                                                "label": "Last Name"
                                            },
                                            {
                                                "id": "c5bbc880-96d5-11ea-8416-c79b465e39ab",
                                                "type": "button",
                                                "children": [],
                                                "command": "save",
                                                "text": "Save"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-profile",
                                "route": "/profile/:id",
                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                "_sortIndex": 270
                            },
                            {
                                "id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "df79f2a1-914a-11ea-8bb4-63310f210ad3",
                                        "children": [
                                            {
                                                "id": "376608f0-914b-11ea-9d03-f552f48ec793",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Unit Request"
                                            },
                                            {
                                                "id": "3e665510-914b-11ea-9d03-f552f48ec793",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "4c028630-914b-11ea-93c5-9720e806f72b",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "View service history",
                                                        "path": "/service-requests",
                                                        "className": "",
                                                        "variant": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "justify-content-center pt-1 pb-1",
                                                "flex": "[object Object]"
                                            },
                                            {
                                                "id": "427db210-914b-11ea-9d03-f552f48ec793",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "bf2b7640-d902-11ea-b7fc-037f001b1342",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "type",
                                                        "options": "[{ \"value\": \"repairs\", \"label\": \"Repairs\"},{ \"value\": \"renovation\", \"label\": \"Renovation\"},{ \"value\": \"carSticker\", \"label\": \"Car Sticker\", data: null }]",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "justify-content-center pt-2 pb-2",
                                                "name": "select"
                                            },
                                            {
                                                "id": "44bc5770-914b-11ea-9d03-f552f48ec793",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a35c3b70-c90d-11ea-8c59-196c3ec4f839",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                        "name": "repairs"
                                                    },
                                                    {
                                                        "id": "2697efe0-c911-11ea-9dd0-6723feee229e",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                        "name": "renovation"
                                                    },
                                                    {
                                                        "id": "2a9de7c0-c911-11ea-9dd0-6723feee229e",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                        "name": "carSticker"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "pt-2 pb-2",
                                                "name": "select",
                                                "component": "UnitRequest"
                                            },
                                            {
                                                "id": "2ac5bef0-ad21-11ea-8384-7b922845035e",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "316b40e0-ad21-11ea-8384-7b922845035e",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "save",
                                                        "variant": "",
                                                        "text": "submit",
                                                        "color": "primary",
                                                        "className": "",
                                                        "fullWidth": true
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "className": "p-1"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-service-request",
                                "route": "/create-service-request/:id",
                                "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                                "_sortIndex": 280
                            },
                            {
                                "id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "6053d8f1-91b4-11ea-ad0e-b15c87c4d0e0",
                                        "children": [
                                            {
                                                "id": "bc5b9200-91b4-11ea-ad0e-b15c87c4d0e0",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Unit Request"
                                            },
                                            {
                                                "id": "39f86670-91b5-11ea-ad0e-b15c87c4d0e0",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "3e960a70-91b5-11ea-ad0e-b15c87c4d0e0",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "424ad510-91b5-11ea-ad0e-b15c87c4d0e0",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "",
                                                                "dataField": "details"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "path": "/create-service-request"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "3c5ac070-91b5-11ea-ad0e-b15c87c4d0e0",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-service-requests",
                                "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                                "route": "/service-requests",
                                "_sortIndex": 290
                            },
                            {
                                "id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "d2d04d11-d902-11ea-b7fc-037f001b1342",
                                        "className": "",
                                        "children": [
                                            {
                                                "id": "04c0e0a0-d903-11ea-b7fc-037f001b1342",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Unit Request",
                                                "className": "has-text-centered"
                                            },
                                            {
                                                "id": "afdbb6d0-d904-11ea-8fb7-85b0ea54cdde",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "bfa2b730-d904-11ea-8fb7-85b0ea54cdde",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "View Unit Requests History",
                                                        "variant": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "justify-content-center pt-1 pb-1"
                                            },
                                            {
                                                "id": "84f0dbb0-d906-11ea-bd61-bf1e0d1003bd",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "020ce720-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "04ead9c0-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "name": "unit",
                                                                "label": "Unit",
                                                                "dataField": "",
                                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "unit-container"
                                                    },
                                                    {
                                                        "id": "21b5e9f0-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "29662520-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "saveObject": true,
                                                                "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                                "dataField": "type",
                                                                "name": "type",
                                                                "label": "Type"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "type-container"
                                                    },
                                                    {
                                                        "id": "47ac1580-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                        "name": "homeowner-unit-request-service-request"
                                                    },
                                                    {
                                                        "id": "60137d70-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                        "name": "homeowner-unit-request-plans-documents"
                                                    },
                                                    {
                                                        "id": "71865910-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                        "name": "homeowner-unit-request-refund-renovation-bond"
                                                    },
                                                    {
                                                        "id": "7c6788e0-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                                        "name": "homeowner-unit-request-vehicle-sticker"
                                                    },
                                                    {
                                                        "id": "82e844c0-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                                        "name": "homeowner-unit-request-grease-trap-cleaning"
                                                    },
                                                    {
                                                        "id": "30acac90-d9a7-11ea-9c8b-b7f572d43c0f",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                                        "name": "homeowner-unit-request-aircon-cleaning"
                                                    },
                                                    {
                                                        "id": "16922b80-d9a9-11ea-9331-090047662dd2",
                                                        "type": "subView",
                                                        "children": [],
                                                        "name": "homeowner-unit-request-others",
                                                        "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "component": "UnitRequest",
                                                "name": ""
                                            },
                                            {
                                                "id": "0dd9e6c0-dac8-11ea-9725-dfc9cbd591dc",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0f33f880-dac8-11ea-9725-dfc9cbd591dc",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "Submit",
                                                        "fullWidth": true,
                                                        "className": "mt-1",
                                                        "command": "save"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-request",
                                "className": "",
                                "route": "/homeowner-unit-request/:id",
                                "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                                "_sortIndex": 630
                            },
                            {
                                "id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "b3e97d21-9156-11ea-88a4-7f4a31012a76",
                                        "children": [
                                            {
                                                "id": "cda662f0-9156-11ea-88a4-7f4a31012a76",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Visitor Pass",
                                                "_sortIndex": 0,
                                                "className": "has-text-centered"
                                            },
                                            {
                                                "id": "24752260-9157-11ea-88a4-7f4a31012a76",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "80f84ec0-96c2-11ea-8d81-e3df0146485b",
                                                        "type": "button",
                                                        "children": [],
                                                        "path": "/visitor-passes",
                                                        "text": "My passes",
                                                        "variant": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 9,
                                                "className": "justify-content-center"
                                            },
                                            {
                                                "id": "d2c56bf0-9156-11ea-88a4-7f4a31012a76",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "65ddc8e0-da1a-11ea-b50d-8323e621ee63",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                        "dataField": "unit",
                                                        "label": "Unit"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 10,
                                                "className": ""
                                            },
                                            {
                                                "id": "d4c90420-9156-11ea-88a4-7f4a31012a76",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "b1b7f790-da1a-11ea-b50d-8323e621ee63",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "dataField": "type",
                                                        "label": "Type of Pass",
                                                        "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 20,
                                                "className": ""
                                            },
                                            {
                                                "id": "d80f2e70-9156-11ea-88a4-7f4a31012a76",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0ca06880-da1c-11ea-87e9-9de57275fc27",
                                                        "type": "subView",
                                                        "children": [],
                                                        "name": "homeowner-visitor-pass-visitor-pass",
                                                        "view": "824f51f0-da1b-11ea-87e9-9de57275fc27"
                                                    },
                                                    {
                                                        "id": "f591eef0-da1d-11ea-abba-db7aa6b9e7d7",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                                        "name": "homeowner-visitor-pass-short-term"
                                                    },
                                                    {
                                                        "id": "8667e010-da1e-11ea-abba-db7aa6b9e7d7",
                                                        "type": "subView",
                                                        "children": [],
                                                        "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                                        "name": "homeowner-visitor-pass-long-term"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 30,
                                                "className": "justify-content-center",
                                                "component": "VisitorPass"
                                            },
                                            {
                                                "id": "da09ed00-9156-11ea-88a4-7f4a31012a76",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "fdeeebd0-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "save",
                                                        "text": "Submit",
                                                        "variant": "",
                                                        "fullWidth": true,
                                                        "className": "mt-1"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 40,
                                                "className": "justify-content-center"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-visitor-pass",
                                "route": "/create-visitor-pass/:id",
                                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                                "className": "",
                                "_sortIndex": 310
                            },
                            {
                                "id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "id": "bf9e7ca0-96c5-11ea-8624-43dc05a96ae0",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c95f4850-96c5-11ea-8624-43dc05a96ae0",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Visitor Passes"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": -1
                                    },
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "09704a71-96c1-11ea-8d81-e3df0146485b",
                                        "children": [
                                            {
                                                "id": "6534ea90-96c2-11ea-8d81-e3df0146485b",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "678b84c0-96c2-11ea-8d81-e3df0146485b",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "dataField": "visitor"
                                                    },
                                                    {
                                                        "id": "6a228350-96c2-11ea-8d81-e3df0146485b",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "dataField": "purpose"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "path": "/create-visitor-pass"
                                            }
                                        ],
                                        "_sortIndex": 0
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-visitor-passes",
                                "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                                "route": "/visitor-passes",
                                "_sortIndex": 320
                            },
                            {
                                "id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                                "type": "page",
                                "children": [
                                    {
                                        "id": "478f4730-c6f9-11ea-8043-0381a4f882af",
                                        "type": "subView",
                                        "children": [],
                                        "view": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-welcome-page",
                                "route": "/welcome/:id",
                                "component": "",
                                "_sortIndex": 490
                            },
                            {
                                "id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "dac583a1-d9ca-11ea-b444-450a6b52ef90",
                                        "children": [
                                            {
                                                "id": "f6978dd0-d9ca-11ea-b444-450a6b52ef90",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Work Permit",
                                                "className": "has-text-centered"
                                            },
                                            {
                                                "id": "a2b3bab0-da09-11ea-87ea-f5980a7a1cab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a4b07510-da09-11ea-87ea-f5980a7a1cab",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "View Work Permit History",
                                                        "variant": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "justify-content-center pt-1 pb-1"
                                            },
                                            {
                                                "id": "5ad52cd0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "607dff40-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                        "name": "unit",
                                                        "label": "Unit",
                                                        "dataField": "unit"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "9d05c150-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "bb60e760-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "dataField": "natureOfWork",
                                                        "label": "Nature of Work",
                                                        "options": "[{\"label\": \"Civil / Carpentry / Painting\", \"value\": \"civil-carpentry-painting\"},{\"label\": \"Air Conditioning\", \"value\": \"air-conditioning\"},{\"label\": \"Mechanical\", \"value\": \"mechanical\"},{\"label\": \"Plumbing\", \"value\": \"plumbing\"},{\"label\": \"Communications\", \"value\": \"communications\"},{\"label\": \"Electrical\", \"value\": \"eletrical\"},{\"label\": \"Others\", \"value\": \"others\"}]",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "d06acc70-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d1a632a0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "contractor",
                                                        "label": "Name of Contractor"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "dfc94700-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "e0f19a60-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "personInCharge",
                                                        "label": "Name of Person in Charge"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "ec2bbfa0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "eee8e3d0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "contactPersonInCharge",
                                                        "label": "Contact Number of Person in Charge"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "4f549340-d9cc-11ea-83f3-4b9e6a8a3ce6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "12242dc0-d9cf-11ea-be94-03767312b052",
                                                        "type": "inputList",
                                                        "children": [],
                                                        "label": "Workers",
                                                        "dataField": "workers"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "77fa9030-da06-11ea-8a8e-430e67178950",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "7d094580-da06-11ea-8a8e-430e67178950",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "multiline": true,
                                                        "dataField": "description",
                                                        "label": "Description of Work"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "f2f0d5b0-da06-11ea-8a8e-430e67178950",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "fe279f90-da06-11ea-8a8e-430e67178950",
                                                        "type": "inputList",
                                                        "children": [],
                                                        "dataField": "materials",
                                                        "label": "List of Materials"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "2ebf0bc0-da07-11ea-8a8e-430e67178950",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "33285950-da07-11ea-8a8e-430e67178950",
                                                        "type": "inputList",
                                                        "children": [],
                                                        "dataField": "tools",
                                                        "label": "List of Tools"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "3bd32120-da07-11ea-8a8e-430e67178950",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "3e8fd020-da07-11ea-8a8e-430e67178950",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "dataField": "startDate",
                                                        "label": "Proposed Start Date "
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "5217a910-da07-11ea-8a8e-430e67178950",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "558c3660-da07-11ea-8a8e-430e67178950",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "label": "Target End Date",
                                                        "dataField": "endDate"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "67781290-da07-11ea-8a8e-430e67178950",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "6a6f0b70-da07-11ea-8a8e-430e67178950",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "dataField": "plans",
                                                        "label": "Photo of Plans"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "3ad7b170-dac8-11ea-9725-dfc9cbd591dc",
                                                "type": "button",
                                                "children": [],
                                                "command": "save",
                                                "text": "Submit",
                                                "className": "mt-1"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-work-permit",
                                "dataModel": "4da7a910-dd5a-11ea-b1bd-f3d0d3926db1",
                                "route": "/homeowner-work-permit/:id"
                            },
                            {
                                "id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                        "children": [
                                            {
                                                "id": "a8fabff0-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a8fabff2-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "name",
                                                        "label": "name",
                                                        "dataField": "name"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a8fabff3-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a8fabff5-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "text",
                                                        "label": "text",
                                                        "dataField": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a8fabff6-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a8fabff8-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "noticeStart",
                                                        "label": "noticeStart",
                                                        "dataField": "noticeStart",
                                                        "format": "mm/dd/yyyy"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a8fabff9-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a8fabffb-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "noticeEnd",
                                                        "label": "noticeEnd",
                                                        "dataField": "noticeEnd",
                                                        "format": "mm/dd/yyyy"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a8fabffc-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a8fabffe-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "source",
                                                        "label": "source",
                                                        "dataField": "source"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "a8fabfff-912e-11ea-ad57-6526d978cdf6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a8fac001-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "status",
                                                        "label": "status",
                                                        "dataField": "status"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "a8f7b2b0-912e-11ea-ad57-6526d978cdf6",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "notification-detail-page",
                                "route": "/notifications/:id",
                                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                "_sortIndex": 370
                            },
                            {
                                "id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                        "children": [
                                            {
                                                "id": "a9012890-912e-11ea-ad57-6526d978cdf6",
                                                "type": "subView",
                                                "children": [],
                                                "name": "searchbar",
                                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "a9045ce0-912e-11ea-ad57-6526d978cdf6",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "a907b840-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "name",
                                                        "label": "name",
                                                        "dataField": "name"
                                                    },
                                                    {
                                                        "id": "a907b842-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "text",
                                                        "label": "text",
                                                        "dataField": "text"
                                                    },
                                                    {
                                                        "id": "a907b844-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "noticeStart",
                                                        "label": "noticeStart",
                                                        "dataField": "noticeStart"
                                                    },
                                                    {
                                                        "id": "a907b846-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "noticeEnd",
                                                        "label": "noticeEnd",
                                                        "dataField": "noticeEnd"
                                                    },
                                                    {
                                                        "id": "a907b848-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "source",
                                                        "label": "source",
                                                        "dataField": "source"
                                                    },
                                                    {
                                                        "id": "a907b84a-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "status",
                                                        "label": "status",
                                                        "dataField": "status"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "table"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "notification-list-page",
                                "route": "/notifications-list",
                                "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                "_sortIndex": 380
                            },
                            {
                                "id": "9c0ea950-914c-11ea-b5d1-292f71dadca1",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "9c0ea951-914c-11ea-b5d1-292f71dadca1",
                                        "children": [
                                            {
                                                "id": "9c219510-914c-11ea-b5d1-292f71dadca1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ecae3670-da15-11ea-83e6-9b97cbfc3324",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "unitNumber",
                                                        "label": "Unit Number",
                                                        "dataField": "unitNumber"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 0
                                            },
                                            {
                                                "id": "9c21bc21-914c-11ea-b5d1-292f71dadca1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "01710060-da16-11ea-83e6-9b97cbfc3324",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "requestDate",
                                                        "label": "Date of Request",
                                                        "dataField": "requestDate"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 10
                                            },
                                            {
                                                "id": "9c21bc24-914c-11ea-b5d1-292f71dadca1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9c21bc26-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "requestType",
                                                        "label": "Type of Request",
                                                        "dataField": "requestType"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 20
                                            },
                                            {
                                                "id": "9c21bc27-914c-11ea-b5d1-292f71dadca1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9c21bc29-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "typeOfWork",
                                                        "label": "Type of Work",
                                                        "dataField": "typeOfWork"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 30
                                            },
                                            {
                                                "id": "9c21bc2a-914c-11ea-b5d1-292f71dadca1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9c21bc2c-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "status",
                                                        "label": "Status",
                                                        "dataField": "status"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 40
                                            },
                                            {
                                                "id": "9c21bc33-914c-11ea-b5d1-292f71dadca1",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9c21bc35-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "notes",
                                                        "label": "Notes",
                                                        "dataField": "notes"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 80
                                            }
                                        ]
                                    },
                                    {
                                        "id": "9c1bc8b0-914c-11ea-b5d1-292f71dadca1",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "service-request-detail-page",
                                "route": "/service-requests/:id",
                                "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b"
                            },
                            {
                                "id": "9c276170-914c-11ea-b5d1-292f71dadca1",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "9c0ea951-914c-11ea-b5d1-292f71dadca1",
                                        "children": [
                                            {
                                                "id": "9c2d06c0-914c-11ea-b5d1-292f71dadca1",
                                                "type": "subView",
                                                "children": [],
                                                "name": "searchbar",
                                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "9c325df0-914c-11ea-b5d1-292f71dadca1",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "9c387870-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "homeowner",
                                                        "label": "Unit NUmber",
                                                        "dataField": "unitNumber"
                                                    },
                                                    {
                                                        "id": "9c387872-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "requestDate",
                                                        "label": "Date of Requests",
                                                        "dataField": "requestDate"
                                                    },
                                                    {
                                                        "id": "9c389f81-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "requestType",
                                                        "label": "Type of Requests",
                                                        "dataField": "requestType"
                                                    },
                                                    {
                                                        "id": "9c389f83-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "typeOfWork",
                                                        "label": "Type of Work",
                                                        "dataField": "typeOfWork"
                                                    },
                                                    {
                                                        "id": "9c389f85-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "status",
                                                        "label": "Status",
                                                        "dataField": "status"
                                                    },
                                                    {
                                                        "id": "9c389f87-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "notes",
                                                        "label": "Notes",
                                                        "dataField": "notes"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "table"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "service-request-list-page",
                                "route": "/service-requests-list",
                                "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b"
                            },
                            {
                                "id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                        "children": [
                                            {
                                                "id": "0824d8f0-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0824d8f2-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "number",
                                                        "label": "number",
                                                        "dataField": "number"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "0824d8f3-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0824d8f5-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "address",
                                                        "label": "address",
                                                        "dataField": "address"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "0824d8f6-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0824d8f8-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "description",
                                                        "label": "description",
                                                        "dataField": "description"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "0824d8f9-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "0824d8fb-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "homeowner",
                                                        "label": "homeowner",
                                                        "dataField": "homeowner",
                                                        "saveObject": true,
                                                        "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "081fa8d0-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "subView",
                                        "children": [],
                                        "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "unit-detail-page",
                                "route": "/units/:id",
                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                "_sortIndex": 610
                            },
                            {
                                "id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                                "type": "page",
                                "children": [
                                    {
                                        "name": "main",
                                        "type": "container",
                                        "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                        "children": [
                                            {
                                                "id": "08310df0-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "subView",
                                                "children": [],
                                                "name": "searchbar",
                                                "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "08341b30-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "table",
                                                "children": [
                                                    {
                                                        "id": "0838fd30-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "number",
                                                        "label": "number",
                                                        "dataField": "number"
                                                    },
                                                    {
                                                        "id": "0838fd32-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "address",
                                                        "label": "address",
                                                        "dataField": "address"
                                                    },
                                                    {
                                                        "id": "0838fd34-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "description",
                                                        "label": "description",
                                                        "dataField": "description"
                                                    },
                                                    {
                                                        "id": "0838fd36-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "column",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "name": "homeowner",
                                                        "label": "homeowner",
                                                        "dataField": "homeowner"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "table"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "unit-list-page",
                                "route": "/units-list",
                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                "_sortIndex": 620
                            },
                            {
                                "type": "view",
                                "name": "commandbar",
                                "orientation": "horizontal",
                                "children": [
                                    {
                                        "name": "save",
                                        "type": "button",
                                        "id": "e7db5105-8c4a-11ea-9466-6f383d5eb483",
                                        "command": "save"
                                    },
                                    {
                                        "name": "delete",
                                        "type": "button",
                                        "id": "e7db5106-8c4a-11ea-9466-6f383d5eb483",
                                        "command": "delete"
                                    }
                                ],
                                "id": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                                "active": false,
                                "_sortIndex": 30
                            },
                            {
                                "id": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "7ee9d700-8ef4-11ea-9b90-bfc3ee1ce33c",
                                        "type": "menu",
                                        "children": [
                                            {
                                                "id": "3cf81350-9130-11ea-af3e-29e246413619",
                                                "type": "menuItem",
                                                "children": [],
                                                "_sortIndex": 0,
                                                "path": "/",
                                                "label": "Main Menu"
                                            },
                                            {
                                                "id": "7abd8be0-9138-11ea-bd78-399a56c40918",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/billings-list",
                                                "label": "Billing",
                                                "_sortIndex": 10
                                            },
                                            {
                                                "id": "1854b570-914f-11ea-b2e4-0783314da7b1",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/comments-suggestions-list",
                                                "label": "Comments & Suggestions",
                                                "_sortIndex": 19
                                            },
                                            {
                                                "id": "d8c9a170-912d-11ea-82bc-71c30f9d69df",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/departments-list",
                                                "label": "Departments",
                                                "_sortIndex": 20
                                            },
                                            {
                                                "id": "df5755a0-912d-11ea-82bc-71c30f9d69df",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/employees-list",
                                                "label": "Employees",
                                                "_sortIndex": 30
                                            },
                                            {
                                                "id": "e1041b40-912d-11ea-82bc-71c30f9d69df",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/homeowners-list",
                                                "label": "Homeowners",
                                                "_sortIndex": 40
                                            },
                                            {
                                                "id": "e3379d10-912d-11ea-82bc-71c30f9d69df",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/notifications-list",
                                                "label": "Notifications",
                                                "_sortIndex": 50
                                            },
                                            {
                                                "id": "e6566940-912d-11ea-82bc-71c30f9d69df",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/pass-deliveries-list",
                                                "label": "Passes - Delivery",
                                                "_sortIndex": 60
                                            },
                                            {
                                                "id": "e86c29e0-912d-11ea-82bc-71c30f9d69df",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/pass-visitors-list",
                                                "label": "Passes - Visitors",
                                                "_sortIndex": 70
                                            },
                                            {
                                                "id": "9c3e1dc0-914c-11ea-b5d1-292f71dadca1",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/service-requests-list",
                                                "label": "Service Request",
                                                "_sortIndex": 80
                                            },
                                            {
                                                "id": "7bde5e60-dd59-11ea-878d-279a484325f4",
                                                "type": "menuItem",
                                                "children": [],
                                                "label": "Units",
                                                "path": "/units-list"
                                            },
                                            {
                                                "id": "875d7be0-dd59-11ea-878d-279a484325f4",
                                                "type": "menuItem",
                                                "children": [],
                                                "label": "Work Permit",
                                                "path": "/work-permits-list"
                                            },
                                            {
                                                "id": "d72baba0-dd5a-11ea-b1bd-f3d0d3926db1",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/message-boards-list",
                                                "label": "message-board-list-page"
                                            }
                                        ],
                                        "name": ""
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "dashboard-sidebar",
                                "active": false,
                                "_sortIndex": 80
                            },
                            {
                                "id": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "24e082d0-8ef0-11ea-981d-018b5a72c307",
                                        "type": "menu",
                                        "children": [
                                            {
                                                "id": "26cac6a0-8ef0-11ea-981d-018b5a72c307",
                                                "type": "menuItem",
                                                "children": [],
                                                "name": "home",
                                                "_sortIndex": 0,
                                                "label": "Main Menu",
                                                "path": "/"
                                            },
                                            {
                                                "id": "13a38940-912a-11ea-a5e9-ff76f0b9af84",
                                                "type": "menuItem",
                                                "children": [],
                                                "_sortIndex": 10,
                                                "name": "account",
                                                "label": "My Account",
                                                "path": "/my-account/me"
                                            },
                                            {
                                                "id": "152c2510-912a-11ea-a5e9-ff76f0b9af84",
                                                "type": "menuItem",
                                                "children": [],
                                                "_sortIndex": 20,
                                                "name": "service",
                                                "label": "Unit Request",
                                                "path": "/homeowner-unit-request/0"
                                            },
                                            {
                                                "id": "5968bca0-d9ac-11ea-b236-955d418fe434",
                                                "type": "menuItem",
                                                "children": [],
                                                "name": "work-permit",
                                                "path": "/homeowner-work-permit/0",
                                                "label": "Work Permit"
                                            },
                                            {
                                                "id": "166eb730-912a-11ea-a5e9-ff76f0b9af84",
                                                "type": "menuItem",
                                                "children": [],
                                                "_sortIndex": 30,
                                                "label": "Comments and Suggestions",
                                                "name": "complaint",
                                                "path": "/homeowner-comments-suggestions/0"
                                            },
                                            {
                                                "id": "17a5feb0-912a-11ea-a5e9-ff76f0b9af84",
                                                "type": "menuItem",
                                                "children": [],
                                                "_sortIndex": 40,
                                                "label": "Visitor Pass",
                                                "name": "visitor",
                                                "path": "/create-visitor-pass/0"
                                            },
                                            {
                                                "id": "19b110f0-912a-11ea-a5e9-ff76f0b9af84",
                                                "type": "menuItem",
                                                "children": [],
                                                "_sortIndex": 50,
                                                "name": "delivery",
                                                "label": "Delivery pass",
                                                "path": "/create-delivery-pass/0"
                                            },
                                            {
                                                "id": "58256b20-c92d-11ea-8660-8b92cdcd0efe",
                                                "type": "menuItem",
                                                "children": [],
                                                "path": "/message-board",
                                                "label": "Message Board",
                                                "_sortIndex": 70
                                            },
                                            {
                                                "id": "295939b0-8ef0-11ea-981d-018b5a72c307",
                                                "type": "menuItem",
                                                "children": [],
                                                "name": "logout",
                                                "_sortIndex": 71,
                                                "label": "Logout",
                                                "path": "/login"
                                            }
                                        ]
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-sidebar",
                                "active": false,
                                "_sortIndex": 300
                            },
                            {
                                "id": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "d3e39f50-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d5efea10-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "meta.units",
                                                "label": "No. of Units"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "db6aa7a0-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "2d6be400-d9a8-11ea-b622-15dab671ce91",
                                                "type": "inputDateTime",
                                                "children": [],
                                                "name": "meta.schedule",
                                                "label": "Schedule"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-request-aircon-cleaning",
                                "_sortIndex": 670
                            },
                            {
                                "id": "0c744880-d9a5-11ea-a304-319701533d32",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "1c9ddb40-d9a5-11ea-a304-319701533d32",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "267c1a00-d9a5-11ea-a304-319701533d32",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "meta.schedule",
                                                "description": "",
                                                "label": "Schedule"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-request-grease-trap-cleaning",
                                "_sortIndex": 660
                            },
                            {
                                "id": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "fe4688f0-d9a8-11ea-9331-090047662dd2",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ff497a00-d9a8-11ea-9331-090047662dd2",
                                                "type": "inputText",
                                                "children": [],
                                                "multiline": true,
                                                "name": "meta.details",
                                                "label": "Please Specify"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-request-others",
                                "_sortIndex": 680
                            },
                            {
                                "id": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "e85ead40-c910-11ea-9dd0-6723feee229e",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "076a4bb0-c91e-11ea-831c-99fd7b91f646",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "meta.plans",
                                                "label": "Plans Required",
                                                "options": "[{ \"value\": \"standard\", \"label\": \"Standard\"},{ \"value\": \"as-built\", \"label\": \"As-built Plant\"}]",
                                                "className": "bite-select",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "name": "homeowner-service-request-repairs",
                                        "className": "bite-select"
                                    },
                                    {
                                        "id": "112746d0-c91e-11ea-831c-99fd7b91f646",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "44b19ca0-d916-11ea-9ea3-7184c3941d0d",
                                                "type": "inputSelect",
                                                "children": [],
                                                "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                                "saveObject": true,
                                                "name": "meta.paper-size",
                                                "label": "Paper Size"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "97e9eee0-d916-11ea-9ea3-7184c3941d0d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "9a6e28c0-d916-11ea-9ea3-7184c3941d0d",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "meta.type",
                                                "label": "Type of Plan",
                                                "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing/sanitary\", \"label\": \"Plumbing / Sanitary\"},{ \"value\": \"electric-plant\", \"label\": \"Electric Plant\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-request-plans-documents",
                                "_sortIndex": 510
                            },
                            {
                                "id": "063f7330-c911-11ea-9dd0-6723feee229e",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "03ee4ee0-c91f-11ea-b910-c30a77ee18ba",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "59be4b60-d917-11ea-9ea3-7184c3941d0d",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "meta.date",
                                                "label": "Date of Bond Payment"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "1cea7cc0-c91f-11ea-b910-c30a77ee18ba",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "8dc40350-d917-11ea-9ea3-7184c3941d0d",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "meta.amount",
                                                "label": "Amount"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": ""
                                    },
                                    {
                                        "id": "9be36e30-d917-11ea-9ea3-7184c3941d0d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "9fea9e90-d917-11ea-9ea3-7184c3941d0d",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "meta.OR-no",
                                                "label": "O.R./C.R. No."
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "b9eb6b30-d917-11ea-9ea3-7184c3941d0d",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "bb01bd30-d917-11ea-9ea3-7184c3941d0d",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "meta.inspection-date",
                                                "description": "",
                                                "label": "Inspection Date"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-request-refund-renovation-bond",
                                "_sortIndex": 520
                            },
                            {
                                "id": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "743faf70-c90d-11ea-8c59-196c3ec4f839",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a9319f50-c911-11ea-98ab-e51558e3434e",
                                                "type": "inputSelect",
                                                "children": [],
                                                "label": "Type of Work",
                                                "name": "meta.type",
                                                "options": "[{\"label\": \"Installation\", \"value\": \"installation\"},{\"label\": \"Repair\", \"value\": \"repair\"},{\"label\": \"Replacement\", \"value\": \"Replacement\"},{\"label\": \"De-clogging\", \"value\": \"de-clogging\"},{\"label\": \"Other\", \"value\": \"others\"}]",
                                                "className": "bite-select",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "bite-select"
                                    },
                                    {
                                        "id": "c6681b80-c911-11ea-98ab-e51558e3434e",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c84dcb70-c911-11ea-98ab-e51558e3434e",
                                                "type": "inputText",
                                                "children": [],
                                                "label": "Work Details",
                                                "name": "meta.details",
                                                "multiline": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "cf0bde70-c911-11ea-98ab-e51558e3434e",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d46647a0-d908-11ea-8bc1-2d8749eeeb64",
                                                "type": "inputText",
                                                "children": [],
                                                "label": "Preferred Schedule",
                                                "name": "meta.schedule"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "bite-select"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-request-service-request",
                                "_sortIndex": 500
                            },
                            {
                                "id": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "9a02aad0-d918-11ea-b3eb-d9cf4159b042",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a6157cd0-d918-11ea-b3eb-d9cf4159b042",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "meta.parking-unit-number",
                                                "label": "Parking Unit Number"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "02e8c340-d919-11ea-b3eb-d9cf4159b042",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "25111df0-d919-11ea-b3eb-d9cf4159b042",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "meta.status",
                                                "label": "Status",
                                                "options": "[{\"label\":\"Owned\", \"value\":\"owned\"},{\"label\":\"Rented\", \"value\":\"rented\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "b33b28f0-d919-11ea-b3eb-d9cf4159b042",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "b80cd540-d919-11ea-b3eb-d9cf4159b042",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "meta.make",
                                                "label": "Make",
                                                "options": "[{\"label\": \"Honda\", \"value\": \"honda\"},{\"label\": \"Toyota\", \"value\": \"toyota\"},{\"label\": \"Ford\", \"value\": \"ford\"},{\"label\": \"Mercedez-Benz\", \"value\": \"mercedez-benz\"},{\"label\": \"BMW\", \"value\": \"bmw\"},{\"label\": \"Chevrolet\", \"value\": \"chevrolet\"},{\"label\": \"Nissan\", \"value\": \"nissan\"},{\"label\": \"Volkswagen\", \"value\": \"volkswagen\"},{\"label\": \"Lexus\", \"value\": \"lexus\"},{\"label\": \"Volvo\", \"value\": \"volvo\"},{\"label\": \"Hyundai\", \"value\": \"hyundai\"},{\"label\": \"Kia\", \"value\": \"kia\"},{\"label\": \"Mistubishi\", \"value\": \"mistubishi\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"Infiniti\", \"value\": \"infiniti\"},{\"label\": \"Suzuki\", \"value\": \"suzuki\"},{\"label\": \"others\", \"value\": \"others\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c394f370-d919-11ea-b3eb-d9cf4159b042",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c6827670-d919-11ea-b3eb-d9cf4159b042",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "meta.model",
                                                "label": "Model"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "ad71c600-d9a4-11ea-a304-319701533d32",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "b1b90ca0-d9a4-11ea-a304-319701533d32",
                                                "type": "inputSelect",
                                                "children": [],
                                                "name": "meta.color",
                                                "label": "Color",
                                                "options": "[{\"label\": \"Black\", \"value\": \"black\"},{\"label\": \"Gray\", \"value\": \"gray\"},{\"label\": \"Silver\", \"value\": \"silver\"},{\"label\": \"Blue\", \"value\": \"blue\"},{\"label\": \"Red\", \"value\": \"red\"},{\"label\": \"Orange\", \"value\": \"orange\"},{\"label\": \"Yellow\", \"value\": \"yellow\"},{\"label\": \"Purple\", \"value\": \"purple\"},{\"label\": \"Brown\", \"value\": \"brown\"},{\"label\": \"White\", \"value\": \"white\"}]",
                                                "saveObject": true
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "c744cbe0-d9a4-11ea-a304-319701533d32",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "cfb34e00-d9a4-11ea-a304-319701533d32",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "meta.plate-number",
                                                "label": "Plate Number"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "ddd18060-d9a4-11ea-a304-319701533d32",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "deacec40-d9a4-11ea-a304-319701533d32",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "meta.OR",
                                                "description": "",
                                                "label": "Upload O.R. / C.R."
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-request-vehicle-sticker",
                                "_sortIndex": 640
                            },
                            {
                                "id": "b8fe9280-c6f5-11ea-b415-c195f50f114e",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "aca19b50-c6f9-11ea-a851-fde2cfff9ce9",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ae765b50-c6f9-11ea-a851-fde2cfff9ce9",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Select Unit"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "columns is-centered pb-1"
                                    },
                                    {
                                        "id": "d8bbe3d0-c6f9-11ea-a851-fde2cfff9ce9",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "dde9e0f0-c6f9-11ea-a851-fde2cfff9ce9",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "029256d0-c6fa-11ea-a851-fde2cfff9ce9",
                                                        "type": "button",
                                                        "children": [],
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 01</div>",
                                                        "className": "app-menu-item",
                                                        "path": "/welcome/01"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "472397a0-c6fa-11ea-a851-fde2cfff9ce9",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "53985fc0-c6fa-11ea-a851-fde2cfff9ce9",
                                                        "type": "button",
                                                        "children": [],
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 02</div>",
                                                        "className": "app-menu-item",
                                                        "path": "/welcome/02"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "columns is-centered pt-2"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-unit-selection",
                                "className": "",
                                "_sortIndex": 480
                            },
                            {
                                "id": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "38c26740-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "3d14aa60-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputList",
                                                "children": [],
                                                "name": "visitor",
                                                "label": "Name/s"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "49eb4190-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "4b75d930-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "startDate",
                                                "label": "Start Date"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "5279ced0-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "542d4b30-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "endDate",
                                                "label": "End Date"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "5ed590b0-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "603f80f0-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "visitorsId",
                                                "label": "Upload valid ID/ Passport (required)"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "6f845900-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "710098c0-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "meta.contract",
                                                "label": "Upload Contract of Lease (required)"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-visitor-pass-long-term"
                            },
                            {
                                "id": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "a0ff15c0-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a2036660-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputList",
                                                "children": [],
                                                "name": "visitor",
                                                "label": "Name/s"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "bb50c180-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c70a7700-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "startDate",
                                                "label": "Start Date"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "cdb2b810-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "d233d360-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "endDate",
                                                "label": "End Date"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "e0d391d0-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "e4f28ff0-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "visitorsId",
                                                "label": "Upload Valid ID/ Passport"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-visitor-pass-short-term"
                            },
                            {
                                "id": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "94ab5510-da1b-11ea-87e9-9de57275fc27",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "9c1bc820-da1b-11ea-87e9-9de57275fc27",
                                                "type": "inputText",
                                                "children": [],
                                                "name": "meta.relationship",
                                                "label": "Relationship to Owner"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "b7ed0f00-da1b-11ea-87e9-9de57275fc27",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "b9749960-da1b-11ea-87e9-9de57275fc27",
                                                "type": "inputList",
                                                "children": [],
                                                "name": "visitor",
                                                "description": "",
                                                "label": "Name/s"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "cb6d1fc0-da1b-11ea-87e9-9de57275fc27",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "cebbae80-da1b-11ea-87e9-9de57275fc27",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "startDate",
                                                "label": "Start Date"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "d92934f0-da1b-11ea-87e9-9de57275fc27",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "db4adc70-da1b-11ea-87e9-9de57275fc27",
                                                "type": "inputDate",
                                                "children": [],
                                                "name": "endDate",
                                                "label": "End Date"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    },
                                    {
                                        "id": "e6edfcb0-da1b-11ea-87e9-9de57275fc27",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "ed1bdda0-da1b-11ea-87e9-9de57275fc27",
                                                "type": "inputMedia",
                                                "children": [],
                                                "name": "visitorsId",
                                                "label": "Upload Valid ID (optional)"
                                            }
                                        ],
                                        "orientation": "horizontal"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-visitor-pass-visitor-pass"
                            },
                            {
                                "id": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "29bfe9a0-97d6-11ea-a9fa-07f95ad6f0c6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "a1eade70-8ef4-11ea-9b90-bfc3ee1ce33c",
                                                "type": "header",
                                                "children": [],
                                                "size": 2,
                                                "text": "Welcome",
                                                "_sortIndex": 0,
                                                "component": ""
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 0,
                                        "className": "columns is-centered pb-1"
                                    },
                                    {
                                        "id": "78539d80-9148-11ea-a6b6-e7e287632ad5",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "c87fc130-9148-11ea-a6b6-e7e287632ad5",
                                                "type": "button",
                                                "children": [],
                                                "text": "View Notifications",
                                                "path": "/notifications",
                                                "className": "",
                                                "variant": "text"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "_sortIndex": 10,
                                        "className": "columns is-centered p-2"
                                    },
                                    {
                                        "id": "2124bcc0-97d7-11ea-a9fa-07f95ad6f0c6",
                                        "type": "container",
                                        "children": [
                                            {
                                                "id": "25f3f810-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d37f2010-97da-11ea-a9fa-07f95ad6f0c6",
                                                        "type": "button",
                                                        "children": [],
                                                        "path": "/my-account/me",
                                                        "name": "My Account",
                                                        "className": "app-menu-item",
                                                        "icon": "",
                                                        "text": "",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_account.svg\"><img src=\"./assets/images/icon_account.svg\" /></object>My Account</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "2ef5e090-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "856e1b80-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "",
                                                        "path": "/homeowner-unit-request/0",
                                                        "flex": "[object Object]",
                                                        "className": "app-menu-item",
                                                        "_sortIndex": 10,
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_service.svg\"><img src=\"./assets/images/icon_service.svg\" /></object>Unit Request</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "351a2490-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "874e7440-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "",
                                                        "path": "/homeowner-comments-suggestions/0",
                                                        "_sortIndex": 20,
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_complaint.svg\"><img src=\"./assets/images/icon_complaint.svg\" /></object>Comments and Suggestions</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "38053690-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [
                                                            {
                                                                "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "text": "",
                                                                        "path": "/create-visitor-pass/0",
                                                                        "_sortIndex": 30,
                                                                        "className": "app-menu-item",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            }
                                                        ],
                                                        "text": "",
                                                        "path": "/create-visitor-pass/0",
                                                        "_sortIndex": 30,
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "8ac2b370-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "",
                                                        "path": "/create-delivery-pass/0",
                                                        "_sortIndex": 40,
                                                        "className": "app-menu-item",
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_delivery.svg\"><img src=\"./assets/images/icon_delivery.svg\" /></object>Delivery Pass</div>"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            },
                                            {
                                                "id": "2290a530-c921-11ea-9848-69dad3ad605b",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "2b49ebf0-c921-11ea-9848-69dad3ad605b",
                                                        "type": "button",
                                                        "children": [],
                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_board.svg\"><img src=\"./assets/images/icon_board.svg\" /></object>Message Board</div>",
                                                        "className": "app-menu-item",
                                                        "path": "/message-board"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "app-menu-item-container"
                                            }
                                        ],
                                        "orientation": "horizontal",
                                        "className": "columns is-centered",
                                        "_sortIndex": 19
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "homeowner-welcome",
                                "active": false,
                                "className": "column is-centered",
                                "component": "",
                                "_sortIndex": 330
                            },
                            {
                                "id": "e1156440-93e9-11ea-99e4-a504a663bd48",
                                "type": "view",
                                "children": [
                                    {
                                        "id": "eb7f4134-93e9-11ea-99e4-a504a663bd48",
                                        "type": "container",
                                        "children": [
                                            {
                                                "nodeType": "DIV",
                                                "type": "container",
                                                "className": "row",
                                                "children": [
                                                    {
                                                        "nodeType": "DIV",
                                                        "type": "container",
                                                        "className": "col",
                                                        "children": [
                                                            {
                                                                "nodeType": "H1",
                                                                "type": "header",
                                                                "className": "h1",
                                                                "children": [],
                                                                "text": "Log In",
                                                                "id": "eb7f4137-93e9-11ea-99e4-a504a663bd48"
                                                            }
                                                        ],
                                                        "id": "eb7f4136-93e9-11ea-99e4-a504a663bd48"
                                                    }
                                                ],
                                                "id": "eb7f4135-93e9-11ea-99e4-a504a663bd48"
                                            },
                                            {
                                                "nodeType": "DIV",
                                                "type": "container",
                                                "className": "row mt-4",
                                                "children": [
                                                    {
                                                        "nodeType": "DIV",
                                                        "type": "container",
                                                        "className": "col",
                                                        "children": [
                                                            {
                                                                "nodeType": "INPUT",
                                                                "type": "inputText",
                                                                "className": "form-control login-form-input",
                                                                "children": [],
                                                                "placeholder": "Email",
                                                                "id": "eb7f413a-93e9-11ea-99e4-a504a663bd48",
                                                                "name": "email",
                                                                "variant": "standard"
                                                            },
                                                            {
                                                                "id": "f6061850-ad8d-11ea-a81e-a3c623c48bc9",
                                                                "type": "image",
                                                                "children": [],
                                                                "url": "./assets/images/icon_user.svg",
                                                                "className": "login-icons"
                                                            }
                                                        ],
                                                        "id": "eb7f4139-93e9-11ea-99e4-a504a663bd48"
                                                    }
                                                ],
                                                "id": "eb7f4138-93e9-11ea-99e4-a504a663bd48"
                                            },
                                            {
                                                "nodeType": "DIV",
                                                "type": "container",
                                                "className": "row mt-3",
                                                "children": [
                                                    {
                                                        "nodeType": "DIV",
                                                        "type": "container",
                                                        "className": "col",
                                                        "children": [
                                                            {
                                                                "nodeType": "INPUT",
                                                                "type": "inputText",
                                                                "className": "form-control login-form-input",
                                                                "children": [],
                                                                "placeholder": "Password",
                                                                "id": "eb7f413d-93e9-11ea-99e4-a504a663bd48",
                                                                "name": "password",
                                                                "password": true,
                                                                "variant": "standard"
                                                            },
                                                            {
                                                                "id": "3a4be650-ad90-11ea-a81e-a3c623c48bc9",
                                                                "type": "image",
                                                                "children": [],
                                                                "url": "./assets/images/icon_login_password.svg",
                                                                "className": "login-icons"
                                                            },
                                                            {
                                                                "id": "5c31f5f0-ad92-11ea-a2a7-ad35397c4d32",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "5f285290-ad92-11ea-a2a7-ad35397c4d32",
                                                                        "type": "paragraph",
                                                                        "children": [],
                                                                        "text": "[Forgot your password?](https://www.froala.com)",
                                                                        "_sortIndex": -1
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "mt-3 forgot-password-container",
                                                                "flex": "[object Object]"
                                                            }
                                                        ],
                                                        "id": "eb7f413c-93e9-11ea-99e4-a504a663bd48"
                                                    }
                                                ],
                                                "id": "eb7f413b-93e9-11ea-99e4-a504a663bd48"
                                            },
                                            {
                                                "nodeType": "DIV",
                                                "type": "container",
                                                "className": "row mt-4",
                                                "children": [
                                                    {
                                                        "nodeType": "DIV",
                                                        "type": "container",
                                                        "className": "col",
                                                        "children": [
                                                            {
                                                                "id": "9ceca3a0-db42-11ea-942a-259edbe0bbf2",
                                                                "type": "button",
                                                                "children": [],
                                                                "command": "portal_login",
                                                                "text": "Login"
                                                            }
                                                        ],
                                                        "id": "eb7f4140-93e9-11ea-99e4-a504a663bd48"
                                                    }
                                                ],
                                                "id": "eb7f413f-93e9-11ea-99e4-a504a663bd48"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "nodeType": "DIV",
                                        "className": "",
                                        "component": "Login"
                                    }
                                ],
                                "orientation": "vertical",
                                "name": "login",
                                "component": "",
                                "_sortIndex": 350
                            },
                            {
                                "type": "view",
                                "name": "searchbar",
                                "orientation": "horizontal",
                                "component": "Searchbar",
                                "children": [
                                    {
                                        "name": "search",
                                        "type": "inputText",
                                        "id": "e7db5108-8c4a-11ea-9466-6f383d5eb483"
                                    },
                                    {
                                        "id": "e0073b50-8c64-11ea-b629-0161308cc31c",
                                        "type": "button",
                                        "children": [],
                                        "command": "new",
                                        "name": "",
                                        "text": "New"
                                    }
                                ],
                                "id": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                "active": false,
                                "_sortIndex": 460
                            }
                        ],
                        "server": "pacificland.bitesizedigital.net",
                        "undoLength": 0,
                        "name": "pacific land - midpoint",
                        "_state": {},
                        "activeApp": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                        "_locked": false,
                        "updateKey": "af76f540-dd5c-11ea-9941-f94cb241993a",
                        "": {
                            "id": "5f30e16ddf453329f6ef693d",
                            "type": "project",
                            "routes": [
                                {
                                    "page": "billing-detail-page",
                                    "path": "/billings/:id",
                                    "node": {
                                        "_id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "billing-list-page",
                                    "path": "/billings-list",
                                    "node": {
                                        "_id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "complaint-detail-page",
                                    "path": "/complaints/:id",
                                    "node": {
                                        "_id": "0c4b0b30-914f-11ea-b2e4-0783314da7b1",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "complaint-list-page",
                                    "path": "/complaints-list",
                                    "node": {
                                        "_id": "0c6263c0-914f-11ea-b2e4-0783314da7b1",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "department-detail-page",
                                    "path": "/departments/:id",
                                    "node": {
                                        "_id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "department-list-page",
                                    "path": "/departments-list",
                                    "node": {
                                        "_id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "employee-detail-page",
                                    "path": "/employees/:id",
                                    "node": {
                                        "_id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "employee-list-page",
                                    "path": "/employees-list",
                                    "node": {
                                        "_id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-account",
                                    "path": "/my-account/:id",
                                    "node": {
                                        "_id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-billing",
                                    "path": "/billing",
                                    "node": {
                                        "_id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-complaint",
                                    "path": "/file-complaint/:id",
                                    "node": {
                                        "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-complaints",
                                    "path": "/filed-complaints",
                                    "node": {
                                        "_id": "47440520-971c-11ea-8a71-fd00fe88963d",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-delivery-pass",
                                    "path": "/create-delivery-pass/:id",
                                    "node": {
                                        "_id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-delivery-passes",
                                    "path": "/delivery-passes",
                                    "node": {
                                        "_id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-login-page",
                                    "path": "/login",
                                    "node": {
                                        "_id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-notifications",
                                    "path": "/notifications",
                                    "node": {
                                        "_id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-password",
                                    "path": "/change-password",
                                    "node": {
                                        "_id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-profile",
                                    "path": "/profile/:id",
                                    "node": {
                                        "_id": "17466790-96d2-11ea-9995-9d94cc393546",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-service-request",
                                    "path": "/create-service-request/:id",
                                    "node": {
                                        "_id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-service-requests",
                                    "path": "/service-requests",
                                    "node": {
                                        "_id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-visitor-pass",
                                    "path": "/create-visitor-pass/:id",
                                    "node": {
                                        "_id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-visitor-passes",
                                    "path": "/visitor-passes",
                                    "node": {
                                        "_id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "notification-detail-page",
                                    "path": "/notifications/:id",
                                    "node": {
                                        "_id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "notification-list-page",
                                    "path": "/notifications-list",
                                    "node": {
                                        "_id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-marketplace",
                                    "path": "/market-place",
                                    "node": {
                                        "_id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-welcome-page",
                                    "path": "/welcome/:id",
                                    "node": {
                                        "_id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-message-board",
                                    "path": "/message-board",
                                    "node": {
                                        "_id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-new-post",
                                    "path": "/new-post",
                                    "node": {
                                        "_id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-post",
                                    "path": "/post/:id",
                                    "node": {
                                        "_id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "unit-detail-page",
                                    "path": "/units/:id",
                                    "node": {
                                        "_id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "unit-list-page",
                                    "path": "/units-list",
                                    "node": {
                                        "_id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-unit-request",
                                    "path": "/homeowner-unit-request/:id",
                                    "node": {
                                        "_id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                                        "type": "page"
                                    }
                                },
                                {
                                    "page": "homeowner-work-permit",
                                    "path": "/homeowner-work-permit/:id",
                                    "node": {
                                        "_id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                                        "type": "page"
                                    }
                                }
                            ],
                            "children": [
                                {
                                    "id": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "35bfcda0-9138-11ea-bd78-399a56c40918",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                            "name": "homeowner",
                                            "_sortIndex": -1
                                        },
                                        {
                                            "id": "3040a340-9138-11ea-bd78-399a56c40918",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "name",
                                            "_sortIndex": 0
                                        },
                                        {
                                            "id": "40b4ac80-9138-11ea-bd78-399a56c40918",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "description"
                                        },
                                        {
                                            "id": "45aeef70-9138-11ea-bd78-399a56c40918",
                                            "type": "field:number",
                                            "children": [],
                                            "dataType": "number",
                                            "name": "amount"
                                        },
                                        {
                                            "id": "6fa1c410-9138-11ea-bd78-399a56c40918",
                                            "type": "field:json",
                                            "children": [],
                                            "dataType": "json",
                                            "name": "details"
                                        }
                                    ],
                                    "name": "billing",
                                    "_sortIndex": 0
                                },
                                {
                                    "id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                            "children": [
                                                {
                                                    "id": "7aa7e100-9138-11ea-bd78-399a56c40918",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "7aa7e102-9138-11ea-bd78-399a56c40918",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "name": "homeowner",
                                                            "label": "homeowner",
                                                            "dataField": "homeowner",
                                                            "saveObject": true,
                                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "7aa7e103-9138-11ea-bd78-399a56c40918",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "7aa7e105-9138-11ea-bd78-399a56c40918",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "name",
                                                            "label": "name",
                                                            "dataField": "name"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "7aa7e106-9138-11ea-bd78-399a56c40918",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "7aa7e108-9138-11ea-bd78-399a56c40918",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "description",
                                                            "label": "description",
                                                            "dataField": "description"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "7aa7e109-9138-11ea-bd78-399a56c40918",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "7aa7e10b-9138-11ea-bd78-399a56c40918",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "amount",
                                                            "label": "amount",
                                                            "dataField": "amount"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "7aa7e10c-9138-11ea-bd78-399a56c40918",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "7aa7e10e-9138-11ea-bd78-399a56c40918",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "details",
                                                            "label": "details",
                                                            "dataField": "details"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "7aa39b40-9138-11ea-bd78-399a56c40918",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "billing-detail-page",
                                    "route": "/billings/:id",
                                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                    "component": "",
                                    "_sortIndex": 10
                                },
                                {
                                    "id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                            "children": [
                                                {
                                                    "id": "7ab04570-9138-11ea-bd78-399a56c40918",
                                                    "type": "subView",
                                                    "children": [],
                                                    "name": "searchbar",
                                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                },
                                                {
                                                    "id": "7ab48b30-9138-11ea-bd78-399a56c40918",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "7ab94620-9138-11ea-bd78-399a56c40918",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "homeowner",
                                                            "label": "homeowner",
                                                            "dataField": "homeowner"
                                                        },
                                                        {
                                                            "id": "7ab94622-9138-11ea-bd78-399a56c40918",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "name",
                                                            "label": "name",
                                                            "dataField": "name"
                                                        },
                                                        {
                                                            "id": "7ab94624-9138-11ea-bd78-399a56c40918",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "description",
                                                            "label": "description",
                                                            "dataField": "description"
                                                        },
                                                        {
                                                            "id": "7ab94626-9138-11ea-bd78-399a56c40918",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "amount",
                                                            "label": "amount",
                                                            "dataField": "amount"
                                                        },
                                                        {
                                                            "id": "7ab94628-9138-11ea-bd78-399a56c40918",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "details",
                                                            "label": "details",
                                                            "dataField": "details"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "table"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "billing-list-page",
                                    "route": "/billings-list",
                                    "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                    "_sortIndex": 20
                                },
                                {
                                    "type": "view",
                                    "name": "commandbar",
                                    "orientation": "horizontal",
                                    "children": [
                                        {
                                            "name": "save",
                                            "type": "button",
                                            "id": "e7db5105-8c4a-11ea-9466-6f383d5eb483",
                                            "command": "save"
                                        },
                                        {
                                            "name": "delete",
                                            "type": "button",
                                            "id": "e7db5106-8c4a-11ea-9466-6f383d5eb483",
                                            "command": "delete"
                                        }
                                    ],
                                    "id": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                                    "active": false,
                                    "_sortIndex": 30
                                },
                                {
                                    "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:enumeration",
                                            "children": [],
                                            "dataType": "enumeration",
                                            "name": "type"
                                        },
                                        {
                                            "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "details"
                                        },
                                        {
                                            "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:file",
                                            "children": [],
                                            "dataType": "file",
                                            "collection": "file",
                                            "via": "related",
                                            "allowedTypes": [
                                                "images",
                                                "files",
                                                "videos"
                                            ],
                                            "plugin": "upload",
                                            "name": "photos"
                                        },
                                        {
                                            "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                            "name": "homeowner"
                                        }
                                    ],
                                    "name": "complaint",
                                    "_sortIndex": 40
                                },
                                {
                                    "id": "0c4b0b30-914f-11ea-b2e4-0783314da7b1",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                            "children": [
                                                {
                                                    "id": "0c5cbe70-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0c5cbe72-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "name": "homeowner",
                                                            "label": "homeowner",
                                                            "dataField": "homeowner",
                                                            "saveObject": true,
                                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                                            "popup": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 0
                                                },
                                                {
                                                    "id": "c9f10042-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "c9f10044-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "item",
                                                            "label": "item",
                                                            "dataField": "item"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 10
                                                },
                                                {
                                                    "id": "0c5cbe73-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0c5cbe75-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "details",
                                                            "label": "details",
                                                            "dataField": "details"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 20
                                                },
                                                {
                                                    "id": "0c5cbe76-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0c5cbe78-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "inputMedia",
                                                            "children": [],
                                                            "name": "photo",
                                                            "label": "photo",
                                                            "dataField": "photo",
                                                            "basePath": "complaints"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 30
                                                },
                                                {
                                                    "id": "c9f0d930-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "c9f10041-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "category",
                                                            "label": "category",
                                                            "dataField": "category"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 39
                                                },
                                                {
                                                    "id": "0c5cbe79-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0c5cbe7b-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "status",
                                                            "label": "status",
                                                            "dataField": "status"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 40
                                                },
                                                {
                                                    "id": "9bf98160-9151-11ea-9407-3325ebf78f84",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "9bf98162-9151-11ea-9407-3325ebf78f84",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "location",
                                                            "label": "location",
                                                            "dataField": "location"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "ac07cb60-d9c8-11ea-93cf-3dd7e562aac3",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ac07cb62-d9c8-11ea-93cf-3dd7e562aac3",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "type",
                                                            "label": "type",
                                                            "dataField": "type"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "ac07f270-d9c8-11ea-93cf-3dd7e562aac3",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ac07f272-d9c8-11ea-93cf-3dd7e562aac3",
                                                            "type": "inputMedia",
                                                            "children": [],
                                                            "name": "photos",
                                                            "label": "photos",
                                                            "dataField": "photos",
                                                            "basePath": "complaints"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ],
                                            "className": ""
                                        },
                                        {
                                            "id": "0c56cb00-914f-11ea-b2e4-0783314da7b1",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "complaint-detail-page",
                                    "route": "/complaints/:id",
                                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                    "_sortIndex": 50
                                },
                                {
                                    "id": "0c6263c0-914f-11ea-b2e4-0783314da7b1",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                            "children": [
                                                {
                                                    "id": "0c67e200-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "subView",
                                                    "children": [],
                                                    "name": "searchbar",
                                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                },
                                                {
                                                    "id": "0c6d6040-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "0c72b770-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "homeowner",
                                                            "label": "homeowner",
                                                            "dataField": "homeowner"
                                                        },
                                                        {
                                                            "id": "0c72b772-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "details",
                                                            "label": "details",
                                                            "dataField": "details"
                                                        },
                                                        {
                                                            "id": "0c72b774-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "photo",
                                                            "label": "photo",
                                                            "dataField": "photo"
                                                        },
                                                        {
                                                            "id": "0c72b776-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "status",
                                                            "label": "status",
                                                            "dataField": "status"
                                                        },
                                                        {
                                                            "id": "c9fa4f10-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "category",
                                                            "label": "category",
                                                            "dataField": "category"
                                                        },
                                                        {
                                                            "id": "c9fa4f12-914f-11ea-b2e4-0783314da7b1",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "item",
                                                            "label": "item",
                                                            "dataField": "item"
                                                        },
                                                        {
                                                            "id": "9bffc2f0-9151-11ea-9407-3325ebf78f84",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "location",
                                                            "label": "location",
                                                            "dataField": "location"
                                                        },
                                                        {
                                                            "id": "ac0c1120-d9c8-11ea-93cf-3dd7e562aac3",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "type",
                                                            "label": "type",
                                                            "dataField": "type"
                                                        },
                                                        {
                                                            "id": "ac0c1122-d9c8-11ea-93cf-3dd7e562aac3",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "photos",
                                                            "label": "photos",
                                                            "dataField": "photos"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "table"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "complaint-list-page",
                                    "route": "/complaints-list",
                                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                    "_sortIndex": 60
                                },
                                {
                                    "id": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
                                    "type": "app",
                                    "children": [],
                                    "name": "dashboard",
                                    "sidebar": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                    "active": true,
                                    "component": "",
                                    "_sortIndex": 70
                                },
                                {
                                    "id": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "7ee9d700-8ef4-11ea-9b90-bfc3ee1ce33c",
                                            "type": "menu",
                                            "children": [
                                                {
                                                    "id": "3cf81350-9130-11ea-af3e-29e246413619",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "_sortIndex": 0,
                                                    "path": "/",
                                                    "label": "Main Menu"
                                                },
                                                {
                                                    "id": "7abd8be0-9138-11ea-bd78-399a56c40918",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/billings-list",
                                                    "label": "Billing",
                                                    "_sortIndex": 10
                                                },
                                                {
                                                    "id": "1854b570-914f-11ea-b2e4-0783314da7b1",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/complaints-list",
                                                    "label": "Complaints",
                                                    "_sortIndex": 19
                                                },
                                                {
                                                    "id": "d8c9a170-912d-11ea-82bc-71c30f9d69df",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/departments-list",
                                                    "label": "Departments",
                                                    "_sortIndex": 20
                                                },
                                                {
                                                    "id": "df5755a0-912d-11ea-82bc-71c30f9d69df",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/employees-list",
                                                    "label": "Employees",
                                                    "_sortIndex": 30
                                                },
                                                {
                                                    "id": "e1041b40-912d-11ea-82bc-71c30f9d69df",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/homeowners-list",
                                                    "label": "Homeowners",
                                                    "_sortIndex": 40
                                                },
                                                {
                                                    "id": "e3379d10-912d-11ea-82bc-71c30f9d69df",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/notifications-list",
                                                    "label": "Notifications",
                                                    "_sortIndex": 50
                                                },
                                                {
                                                    "id": "e6566940-912d-11ea-82bc-71c30f9d69df",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/pass-deliveries-list",
                                                    "label": "Passes - Delivery",
                                                    "_sortIndex": 60
                                                },
                                                {
                                                    "id": "e86c29e0-912d-11ea-82bc-71c30f9d69df",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/pass-visitors-list",
                                                    "label": "Passes - Visitors",
                                                    "_sortIndex": 70
                                                },
                                                {
                                                    "id": "9c3e1dc0-914c-11ea-b5d1-292f71dadca1",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/service-requests-list",
                                                    "label": "Service Request",
                                                    "_sortIndex": 80
                                                }
                                            ],
                                            "name": ""
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "dashboard-sidebar",
                                    "active": false,
                                    "_sortIndex": 80
                                },
                                {
                                    "id": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "1ba0dd50-8ce8-11ea-a283-ebcb859bf8c8",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "name",
                                            "required": true,
                                            "searchable": true
                                        },
                                        {
                                            "id": "22702e60-8ce8-11ea-a283-ebcb859bf8c8",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "description",
                                            "required": true
                                        }
                                    ],
                                    "name": "department",
                                    "active": false,
                                    "_sortIndex": 90
                                },
                                {
                                    "id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                            "children": [
                                                {
                                                    "id": "99dbbf60-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "99dbbf62-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "name",
                                                            "label": "Department",
                                                            "dataField": "name"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "99dbbf63-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "99dbbf65-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "description",
                                                            "label": "Description",
                                                            "dataField": "description",
                                                            "description": "",
                                                            "multiline": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "99d8b220-912e-11ea-ad57-6526d978cdf6",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "department-detail-page",
                                    "route": "/departments/:id",
                                    "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                    "_sortIndex": 100
                                },
                                {
                                    "id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                            "children": [
                                                {
                                                    "id": "94311830-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "subView",
                                                    "children": [],
                                                    "name": "searchbar",
                                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                },
                                                {
                                                    "id": "9433b040-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "94364850-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "name",
                                                            "label": "Name",
                                                            "dataField": "name"
                                                        },
                                                        {
                                                            "id": "94364852-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "description",
                                                            "label": "Description",
                                                            "dataField": "description"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "table"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "department-list-page",
                                    "route": "/departments-list",
                                    "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                    "_sortIndex": 110
                                },
                                {
                                    "id": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "7288d810-8c4e-11ea-9466-6f383d5eb483",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "employeeid",
                                            "required": true,
                                            "searchable": true,
                                            "_sortIndex": 0
                                        },
                                        {
                                            "id": "49b35e80-9129-11ea-a5e9-ff76f0b9af84",
                                            "type": "field:email",
                                            "children": [],
                                            "dataType": "email",
                                            "name": "email",
                                            "required": true,
                                            "_sortIndex": 9
                                        },
                                        {
                                            "id": "780a4c60-8c4e-11ea-9466-6f383d5eb483",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "firstName",
                                            "required": true,
                                            "searchable": true,
                                            "_sortIndex": 10
                                        },
                                        {
                                            "id": "837ebb80-8c4e-11ea-9466-6f383d5eb483",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "lastName",
                                            "required": true,
                                            "searchable": true,
                                            "_sortIndex": 20
                                        },
                                        {
                                            "id": "e68f77d0-8c91-11ea-bb0b-0b00cf6ac98c",
                                            "type": "field:date",
                                            "children": [],
                                            "dataType": "date",
                                            "format": "mm/dd/yyyy",
                                            "name": "birthday",
                                            "required": true,
                                            "_sortIndex": 30
                                        },
                                        {
                                            "id": "c11b9d10-8e7d-11ea-810e-c1e68e180dd7",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                            "name": "department",
                                            "_sortIndex": 40
                                        },
                                        {
                                            "id": "5979e880-8e9e-11ea-9c0f-c7e69d58f37d",
                                            "type": "field:file",
                                            "children": [],
                                            "dataType": "file",
                                            "collection": "file",
                                            "via": "related",
                                            "allowedTypes": [
                                                "images",
                                                "files",
                                                "videos"
                                            ],
                                            "plugin": "upload",
                                            "name": "picture",
                                            "_sortIndex": 50
                                        }
                                    ],
                                    "name": "employee",
                                    "active": false,
                                    "_sortIndex": 120
                                },
                                {
                                    "id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                            "children": [
                                                {
                                                    "id": "a5a55360-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a5a55362-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "employeeid",
                                                            "label": "employeeid",
                                                            "dataField": "employeeid"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a5a55363-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a5a55365-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "email",
                                                            "label": "email",
                                                            "dataField": "email"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a5a55366-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a5a55368-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "firstName",
                                                            "label": "firstName",
                                                            "dataField": "firstName"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a5a55369-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a5a5536b-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "lastName",
                                                            "label": "lastName",
                                                            "dataField": "lastName"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a5a5536c-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a5a5536e-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputDate",
                                                            "children": [],
                                                            "name": "birthday",
                                                            "label": "birthday",
                                                            "dataField": "birthday",
                                                            "format": "mm/dd/yyyy"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a5a5536f-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a5a55371-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "name": "department",
                                                            "label": "department",
                                                            "dataField": "department",
                                                            "saveObject": true,
                                                            "relation": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a5a55372-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a5a55374-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputMedia",
                                                            "children": [],
                                                            "name": "picture",
                                                            "label": "picture",
                                                            "dataField": "picture",
                                                            "basePath": "employees"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "a5a26d30-912e-11ea-ad57-6526d978cdf6",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "employee-detail-page",
                                    "route": "/employees/:id",
                                    "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                    "_sortIndex": 130
                                },
                                {
                                    "id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                            "children": [
                                                {
                                                    "id": "a5aa8380-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "subView",
                                                    "children": [],
                                                    "name": "searchbar",
                                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                },
                                                {
                                                    "id": "a5ae05f0-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "a5b0c510-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "employeeid",
                                                            "label": "employeeid",
                                                            "dataField": "employeeid"
                                                        },
                                                        {
                                                            "id": "a5b0c512-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "email",
                                                            "label": "email",
                                                            "dataField": "email"
                                                        },
                                                        {
                                                            "id": "a5b0c514-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "firstName",
                                                            "label": "firstName",
                                                            "dataField": "firstName"
                                                        },
                                                        {
                                                            "id": "a5b0c516-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "lastName",
                                                            "label": "lastName",
                                                            "dataField": "lastName"
                                                        },
                                                        {
                                                            "id": "a5b0c518-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "birthday",
                                                            "label": "birthday",
                                                            "dataField": "birthday"
                                                        },
                                                        {
                                                            "id": "a5b0c51a-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "department",
                                                            "label": "department",
                                                            "dataField": "department"
                                                        },
                                                        {
                                                            "id": "a5b0c51c-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "picture",
                                                            "label": "picture",
                                                            "dataField": "picture"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "table"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "employee-list-page",
                                    "route": "/employees-list",
                                    "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                    "_sortIndex": 140
                                },
                                {
                                    "id": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "06bec240-911f-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "accountNumber",
                                            "_sortIndex": -1
                                        },
                                        {
                                            "id": "3f18fbb0-9129-11ea-a5e9-ff76f0b9af84",
                                            "type": "field:email",
                                            "children": [],
                                            "dataType": "email",
                                            "name": "email",
                                            "required": true,
                                            "_sortIndex": 0
                                        },
                                        {
                                            "id": "f15753e0-911e-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "firstName",
                                            "required": true,
                                            "_sortIndex": 10
                                        },
                                        {
                                            "id": "f67bdb20-911e-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "lastName",
                                            "required": true,
                                            "_sortIndex": 20
                                        },
                                        {
                                            "id": "fa966c70-911e-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "middleInitial",
                                            "_sortIndex": 30
                                        },
                                        {
                                            "id": "0cbdf210-911f-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "unit",
                                            "_sortIndex": 50
                                        },
                                        {
                                            "id": "101e3410-911f-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "building",
                                            "_sortIndex": 60
                                        },
                                        {
                                            "id": "138b4750-911f-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "contact",
                                            "_sortIndex": 70
                                        }
                                    ],
                                    "name": "homeowner",
                                    "_sortIndex": 150
                                },
                                {
                                    "id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "4840fc41-9149-11ea-a6b6-e7e287632ad5",
                                            "children": [
                                                {
                                                    "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "My Account",
                                                    "className": "has-text-centered"
                                                },
                                                {
                                                    "id": "60c4c5d0-9149-11ea-a6b6-e7e287632ad5",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "22f17b80-914a-11ea-8caa-6d37b4107440",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "text": "Account Name:"
                                                        },
                                                        {
                                                            "id": "24c48dd0-914a-11ea-8caa-6d37b4107440",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "name": "accountName",
                                                            "dataField": "firstName"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "accountName",
                                                    "className": "py-1"
                                                },
                                                {
                                                    "id": "370763a0-914a-11ea-8caa-6d37b4107440",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "43754df0-914a-11ea-8caa-6d37b4107440",
                                                            "type": "textBlock",
                                                            "children": [],
                                                            "text": "Email Address:"
                                                        },
                                                        {
                                                            "id": "4bf0a150-914a-11ea-8caa-6d37b4107440",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "name": "email",
                                                            "text": "",
                                                            "dataField": "email"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "email",
                                                    "className": "py-1"
                                                },
                                                {
                                                    "id": "39ff46e0-914a-11ea-8caa-6d37b4107440",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "46fa07e0-914a-11ea-8caa-6d37b4107440",
                                                            "type": "textBlock",
                                                            "children": [],
                                                            "text": "Contact Number:"
                                                        },
                                                        {
                                                            "id": "49cfbd20-914a-11ea-8caa-6d37b4107440",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "name": "phoneNumber",
                                                            "text": "",
                                                            "dataField": "contact"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "phoneNumber",
                                                    "className": "py-1"
                                                },
                                                {
                                                    "id": "47ffc560-a551-11ea-b01c-9d4389ff2ddd",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "56a0e360-a551-11ea-b01c-9d4389ff2ddd",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "6bd54d90-914a-11ea-8caa-6d37b4107440",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "text": "",
                                                                    "path": "/billing",
                                                                    "_sortIndex": -1,
                                                                    "className": "app-menu-item",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_balance.svg\"><img src=\"./assets/images/icon_balance.svg\" /></object>View Balance</div>"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        },
                                                        {
                                                            "id": "67303cd0-a551-11ea-b01c-9d4389ff2ddd",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "6dde15e0-914a-11ea-8caa-6d37b4107440",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "text": "",
                                                                    "className": "app-menu-item",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pay.svg\"><img src=\"./assets/images/icon_pay.svg\" /></object>Pay Dues</div>"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "columns is-centered mt-2"
                                                },
                                                {
                                                    "id": "7ac953d0-a551-11ea-b01c-9d4389ff2ddd",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "7e5f4bd0-a551-11ea-b01c-9d4389ff2ddd",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "6fb0b300-914a-11ea-8caa-6d37b4107440",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "text": "",
                                                                    "path": "/change-password",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_password.svg\"><img src=\"./assets/images/icon_password.svg\" /></object>Change Password</div>",
                                                                    "className": "app-menu-item"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        },
                                                        {
                                                            "id": "81fc96d0-a551-11ea-b01c-9d4389ff2ddd",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "718ee8e0-914a-11ea-8caa-6d37b4107440",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "text": "",
                                                                    "path": "/profile/me",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_profile.svg\"><img src=\"./assets/images/icon_profile.svg\" /></object>My Profile</div>",
                                                                    "className": "app-menu-item"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "columns is-centered"
                                                }
                                            ],
                                            "component": "Account",
                                            "className": ""
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-account",
                                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "route": "/my-account/:id",
                                    "component": "",
                                    "_sortIndex": 160
                                },
                                {
                                    "id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                                    "type": "page",
                                    "children": [
                                        {
                                            "id": "62b4dc20-96d2-11ea-8416-c79b465e39ab",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "6560c240-96d2-11ea-8416-c79b465e39ab",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Billing"
                                                },
                                                {
                                                    "id": "71d451e0-96d2-11ea-8416-c79b465e39ab",
                                                    "type": "container",
                                                    "children": [],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "7b996350-96d2-11ea-8416-c79b465e39ab",
                                                    "type": "container",
                                                    "children": [],
                                                    "orientation": "horizontal"
                                                }
                                            ],
                                            "orientation": "vertical"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-billing",
                                    "route": "/billing",
                                    "_sortIndex": 170
                                },
                                {
                                    "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                            "children": [
                                                {
                                                    "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Comments and Suggestions",
                                                    "_sortIndex": 0,
                                                    "name": "",
                                                    "className": "has-text-centered"
                                                },
                                                {
                                                    "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "View complaint history",
                                                            "path": "/filed-complaints",
                                                            "variant": "text"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 9,
                                                    "className": "justify-content-center"
                                                },
                                                {
                                                    "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "dataField": "type",
                                                            "label": "Type of issue"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 10,
                                                    "className": ""
                                                },
                                                {
                                                    "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "details",
                                                            "label": "Details of the issue/concern",
                                                            "multiline": true,
                                                            "name": ""
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 20,
                                                    "className": ""
                                                },
                                                {
                                                    "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                                            "type": "inputMedia",
                                                            "children": [],
                                                            "label": "Photo",
                                                            "dataField": "photo"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 40,
                                                    "className": ""
                                                },
                                                {
                                                    "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "Save",
                                                            "command": "save",
                                                            "variant": "contained",
                                                            "color": "primary",
                                                            "className": "",
                                                            "fullWidth": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 50,
                                                    "className": ""
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-complaint",
                                    "route": "/file-complaint/:id",
                                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                    "className": "",
                                    "_sortIndex": 180
                                },
                                {
                                    "id": "47440520-971c-11ea-8a71-fd00fe88963d",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "47440521-971c-11ea-8a71-fd00fe88963d",
                                            "children": [
                                                {
                                                    "id": "4b1a6180-971c-11ea-8a71-fd00fe88963d",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Complaints",
                                                    "className": "has-text-centered"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "52a624c0-971c-11ea-8a71-fd00fe88963d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "5d6c5280-971c-11ea-8a71-fd00fe88963d",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "63bf6eb0-971c-11ea-8a71-fd00fe88963d",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "dataField": "item"
                                                        },
                                                        {
                                                            "id": "6749a6e0-971c-11ea-8a71-fd00fe88963d",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "dataField": "item"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "5a577160-971c-11ea-8a71-fd00fe88963d",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                    "route": "/filed-complaints",
                                    "name": "homeowner-complaints",
                                    "className": "",
                                    "_sortIndex": 190
                                },
                                {
                                    "id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "4e8b5d81-9157-11ea-8382-2991800d56bc",
                                            "children": [
                                                {
                                                    "id": "268a56f0-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "name": "",
                                                    "text": "Delivery/ Pull-out Pass"
                                                },
                                                {
                                                    "id": "3cd79170-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "40347810-da0a-11ea-87ea-f5980a7a1cab",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "View History",
                                                            "variant": "text"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "justify-content-center pt-1 pb-1"
                                                },
                                                {
                                                    "id": "590cca40-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "5f544db0-da0a-11ea-87ea-f5980a7a1cab",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                            "dataField": "unit",
                                                            "label": "Unit"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "66e4a4d0-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "6b30cd70-da0a-11ea-87ea-f5980a7a1cab",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "dataField": "type",
                                                            "label": "Type of Pass"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "7d085e50-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "d3f88870-da0a-11ea-87ea-f5980a7a1cab",
                                                            "type": "inputDateTime",
                                                            "children": [],
                                                            "dataField": "schedule",
                                                            "label": "Schedule"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "87486c20-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "8c652b30-da0a-11ea-87ea-f5980a7a1cab",
                                                            "type": "inputList",
                                                            "children": [],
                                                            "label": "Item(s) - Please enumerate including quantities.",
                                                            "dataField": "items"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a733cf20-da0a-11ea-87ea-f5980a7a1cab",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ac4da800-da0a-11ea-87ea-f5980a7a1cab",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "company",
                                                            "label": "Delivery Company"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "df2a4d60-dac7-11ea-9725-dfc9cbd591dc",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "e07a7410-dac7-11ea-9725-dfc9cbd591dc",
                                                            "type": "button",
                                                            "children": [],
                                                            "command": "save",
                                                            "text": "Submit",
                                                            "fullWidth": true,
                                                            "className": "mt-1"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-delivery-pass",
                                    "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                    "route": "/create-delivery-pass/:id",
                                    "className": "",
                                    "_sortIndex": 200
                                },
                                {
                                    "id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "db3a70c1-96c7-11ea-bb44-c34ef5a44500",
                                            "children": [
                                                {
                                                    "id": "e5006c90-96c7-11ea-bb44-c34ef5a44500",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Delivery Passes"
                                                },
                                                {
                                                    "id": "e8824050-96c7-11ea-bb44-c34ef5a44500",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "f0fafba0-96c7-11ea-bb44-c34ef5a44500",
                                                            "type": "table",
                                                            "children": [
                                                                {
                                                                    "id": "ae53bb10-96c8-11ea-bb44-c34ef5a44500",
                                                                    "type": "column",
                                                                    "children": [],
                                                                    "orientation": "horizontal",
                                                                    "dataField": "courier"
                                                                },
                                                                {
                                                                    "id": "b91bbd90-96c8-11ea-bb44-c34ef5a44500",
                                                                    "type": "column",
                                                                    "children": [],
                                                                    "orientation": "horizontal",
                                                                    "dataField": "items"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "path": "/create-delivery-pass"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "ed6551c0-96c7-11ea-bb44-c34ef5a44500",
                                                    "type": "container",
                                                    "children": [],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-delivery-passes",
                                    "route": "/delivery-passes",
                                    "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                    "_sortIndex": 210
                                },
                                {
                                    "id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "0ad19881-93ea-11ea-99e4-a504a663bd48"
                                        },
                                        {
                                            "id": "1acacc70-93ea-11ea-9384-d1e4789a27a9",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e1156440-93e9-11ea-99e4-a504a663bd48"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-login-page",
                                    "component": "",
                                    "route": "/login",
                                    "_sortIndex": 240
                                },
                                {
                                    "id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "c2c20490-91b3-11ea-ba7f-61310864338b",
                                            "children": [
                                                {
                                                    "id": "d1894830-91b3-11ea-ba7f-61310864338b",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Notifications"
                                                },
                                                {
                                                    "id": "26512330-96da-11ea-a306-e3cce4ba1daa",
                                                    "type": "container",
                                                    "children": [],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "294a17e0-96da-11ea-a306-e3cce4ba1daa",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "2e1311a0-96da-11ea-a306-e3cce4ba1daa",
                                                            "type": "table",
                                                            "children": [
                                                                {
                                                                    "id": "327a8a70-96da-11ea-a306-e3cce4ba1daa",
                                                                    "type": "column",
                                                                    "children": [],
                                                                    "orientation": "horizontal",
                                                                    "dataField": "name"
                                                                },
                                                                {
                                                                    "id": "346c2140-96da-11ea-a306-e3cce4ba1daa",
                                                                    "type": "column",
                                                                    "children": [],
                                                                    "orientation": "horizontal",
                                                                    "dataField": "text"
                                                                }
                                                            ],
                                                            "orientation": "horizontal"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-notifications",
                                    "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                    "route": "/notifications",
                                    "_sortIndex": 250
                                },
                                {
                                    "id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "a259bcb1-96d2-11ea-8416-c79b465e39ab",
                                            "children": [
                                                {
                                                    "id": "a82b73e0-96d2-11ea-8416-c79b465e39ab",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Change password"
                                                },
                                                {
                                                    "id": "d3e59b00-96d2-11ea-8416-c79b465e39ab",
                                                    "type": "container",
                                                    "children": [],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "d63c5c40-96d2-11ea-8416-c79b465e39ab",
                                                    "type": "container",
                                                    "children": [],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "aab74ee0-96d2-11ea-8416-c79b465e39ab",
                                            "type": "container",
                                            "children": [],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-password",
                                    "route": "/change-password",
                                    "_sortIndex": 260
                                },
                                {
                                    "id": "17466790-96d2-11ea-9995-9d94cc393546",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "0e752c01-96d2-11ea-9995-9d94cc393546",
                                            "children": [
                                                {
                                                    "id": "8ad561c0-96d2-11ea-8416-c79b465e39ab",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Profile"
                                                },
                                                {
                                                    "id": "8de3b330-96d2-11ea-8416-c79b465e39ab",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "9c2e1e50-96d5-11ea-8416-c79b465e39ab",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "firstName",
                                                            "label": "First Name"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a6f620d0-96d5-11ea-8416-c79b465e39ab",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "dataField": "lastName",
                                                    "label": "Last Name"
                                                },
                                                {
                                                    "id": "c5bbc880-96d5-11ea-8416-c79b465e39ab",
                                                    "type": "button",
                                                    "children": [],
                                                    "command": "save",
                                                    "text": "Save"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-profile",
                                    "route": "/profile/:id",
                                    "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                    "_sortIndex": 270
                                },
                                {
                                    "id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "df79f2a1-914a-11ea-8bb4-63310f210ad3",
                                            "children": [
                                                {
                                                    "id": "376608f0-914b-11ea-9d03-f552f48ec793",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Unit Request"
                                                },
                                                {
                                                    "id": "3e665510-914b-11ea-9d03-f552f48ec793",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "4c028630-914b-11ea-93c5-9720e806f72b",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "View service history",
                                                            "path": "/service-requests",
                                                            "className": "",
                                                            "variant": "text"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "justify-content-center pt-1 pb-1",
                                                    "flex": "[object Object]"
                                                },
                                                {
                                                    "id": "427db210-914b-11ea-9d03-f552f48ec793",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "bf2b7640-d902-11ea-b7fc-037f001b1342",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "name": "type",
                                                            "options": "[{ \"value\": \"repairs\", \"label\": \"Repairs\"},{ \"value\": \"renovation\", \"label\": \"Renovation\"},{ \"value\": \"carSticker\", \"label\": \"Car Sticker\", data: null }]",
                                                            "saveObject": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "justify-content-center pt-2 pb-2",
                                                    "name": "select"
                                                },
                                                {
                                                    "id": "44bc5770-914b-11ea-9d03-f552f48ec793",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a35c3b70-c90d-11ea-8c59-196c3ec4f839",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                            "name": "repairs"
                                                        },
                                                        {
                                                            "id": "2697efe0-c911-11ea-9dd0-6723feee229e",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                            "name": "renovation"
                                                        },
                                                        {
                                                            "id": "2a9de7c0-c911-11ea-9dd0-6723feee229e",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                            "name": "carSticker"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "pt-2 pb-2",
                                                    "name": "select",
                                                    "component": "UnitRequest"
                                                },
                                                {
                                                    "id": "2ac5bef0-ad21-11ea-8384-7b922845035e",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "316b40e0-ad21-11ea-8384-7b922845035e",
                                                            "type": "button",
                                                            "children": [],
                                                            "command": "save",
                                                            "variant": "",
                                                            "text": "submit",
                                                            "color": "primary",
                                                            "className": "",
                                                            "fullWidth": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ],
                                            "className": "p-1"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-service-request",
                                    "route": "/create-service-request/:id",
                                    "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                                    "_sortIndex": 280
                                },
                                {
                                    "id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "6053d8f1-91b4-11ea-ad0e-b15c87c4d0e0",
                                            "children": [
                                                {
                                                    "id": "bc5b9200-91b4-11ea-ad0e-b15c87c4d0e0",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Unit Request"
                                                },
                                                {
                                                    "id": "39f86670-91b5-11ea-ad0e-b15c87c4d0e0",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "3e960a70-91b5-11ea-ad0e-b15c87c4d0e0",
                                                            "type": "table",
                                                            "children": [
                                                                {
                                                                    "id": "424ad510-91b5-11ea-ad0e-b15c87c4d0e0",
                                                                    "type": "column",
                                                                    "children": [],
                                                                    "orientation": "horizontal",
                                                                    "name": "",
                                                                    "dataField": "details"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "path": "/create-service-request"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "3c5ac070-91b5-11ea-ad0e-b15c87c4d0e0",
                                                    "type": "container",
                                                    "children": [],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-service-requests",
                                    "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                                    "route": "/service-requests",
                                    "_sortIndex": 290
                                },
                                {
                                    "id": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "24e082d0-8ef0-11ea-981d-018b5a72c307",
                                            "type": "menu",
                                            "children": [
                                                {
                                                    "id": "26cac6a0-8ef0-11ea-981d-018b5a72c307",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "name": "home",
                                                    "_sortIndex": 0,
                                                    "label": "Main Menu",
                                                    "path": "/"
                                                },
                                                {
                                                    "id": "13a38940-912a-11ea-a5e9-ff76f0b9af84",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "_sortIndex": 10,
                                                    "name": "account",
                                                    "label": "My Account",
                                                    "path": "/my-account/me"
                                                },
                                                {
                                                    "id": "152c2510-912a-11ea-a5e9-ff76f0b9af84",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "_sortIndex": 20,
                                                    "name": "service",
                                                    "label": "Unit Request",
                                                    "path": "/homeowner-unit-request/0"
                                                },
                                                {
                                                    "id": "5968bca0-d9ac-11ea-b236-955d418fe434",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "name": "work-permit",
                                                    "path": "/homeowner-work-permit/0",
                                                    "label": "Work Permit"
                                                },
                                                {
                                                    "id": "166eb730-912a-11ea-a5e9-ff76f0b9af84",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "_sortIndex": 30,
                                                    "label": "Comments and Suggestions",
                                                    "name": "complaint",
                                                    "path": "/file-complaint/0"
                                                },
                                                {
                                                    "id": "17a5feb0-912a-11ea-a5e9-ff76f0b9af84",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "_sortIndex": 40,
                                                    "label": "Visitor Pass",
                                                    "name": "visitor",
                                                    "path": "/create-visitor-pass/0"
                                                },
                                                {
                                                    "id": "19b110f0-912a-11ea-a5e9-ff76f0b9af84",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "_sortIndex": 50,
                                                    "name": "delivery",
                                                    "label": "Delivery pass",
                                                    "path": "/create-delivery-pass/0"
                                                },
                                                {
                                                    "id": "58256b20-c92d-11ea-8660-8b92cdcd0efe",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "path": "/message-board",
                                                    "label": "Message Board",
                                                    "_sortIndex": 70
                                                },
                                                {
                                                    "id": "295939b0-8ef0-11ea-981d-018b5a72c307",
                                                    "type": "menuItem",
                                                    "children": [],
                                                    "name": "logout",
                                                    "_sortIndex": 71,
                                                    "label": "Logout",
                                                    "path": "/login"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-sidebar",
                                    "active": false,
                                    "_sortIndex": 300
                                },
                                {
                                    "id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "b3e97d21-9156-11ea-88a4-7f4a31012a76",
                                            "children": [
                                                {
                                                    "id": "cda662f0-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Visitor Pass",
                                                    "_sortIndex": 0,
                                                    "className": "has-text-centered"
                                                },
                                                {
                                                    "id": "24752260-9157-11ea-88a4-7f4a31012a76",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "80f84ec0-96c2-11ea-8d81-e3df0146485b",
                                                            "type": "button",
                                                            "children": [],
                                                            "path": "/visitor-passes",
                                                            "text": "My passes",
                                                            "variant": "text"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 9,
                                                    "className": "justify-content-center"
                                                },
                                                {
                                                    "id": "d2c56bf0-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "65ddc8e0-da1a-11ea-b50d-8323e621ee63",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                            "dataField": "unit",
                                                            "label": "Unit"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 10,
                                                    "className": ""
                                                },
                                                {
                                                    "id": "d4c90420-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "b1b7f790-da1a-11ea-b50d-8323e621ee63",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "dataField": "type",
                                                            "label": "Type of Pass",
                                                            "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                                            "saveObject": true
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 20,
                                                    "className": ""
                                                },
                                                {
                                                    "id": "d80f2e70-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0ca06880-da1c-11ea-87e9-9de57275fc27",
                                                            "type": "subView",
                                                            "children": [],
                                                            "name": "homeowner-visitor-pass-visitor-pass",
                                                            "view": "824f51f0-da1b-11ea-87e9-9de57275fc27"
                                                        },
                                                        {
                                                            "id": "f591eef0-da1d-11ea-abba-db7aa6b9e7d7",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                                            "name": "homeowner-visitor-pass-short-term"
                                                        },
                                                        {
                                                            "id": "8667e010-da1e-11ea-abba-db7aa6b9e7d7",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                                            "name": "homeowner-visitor-pass-long-term"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 30,
                                                    "className": "justify-content-center",
                                                    "component": "VisitorPass"
                                                },
                                                {
                                                    "id": "da09ed00-9156-11ea-88a4-7f4a31012a76",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "fdeeebd0-9156-11ea-88a4-7f4a31012a76",
                                                            "type": "button",
                                                            "children": [],
                                                            "command": "save",
                                                            "text": "Submit",
                                                            "variant": "",
                                                            "fullWidth": true,
                                                            "className": "mt-1"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "_sortIndex": 40,
                                                    "className": "justify-content-center"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-visitor-pass",
                                    "route": "/create-visitor-pass/:id",
                                    "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                                    "className": "",
                                    "_sortIndex": 310
                                },
                                {
                                    "id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                                    "type": "page",
                                    "children": [
                                        {
                                            "id": "bf9e7ca0-96c5-11ea-8624-43dc05a96ae0",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c95f4850-96c5-11ea-8624-43dc05a96ae0",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Visitor Passes"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": -1
                                        },
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "09704a71-96c1-11ea-8d81-e3df0146485b",
                                            "children": [
                                                {
                                                    "id": "6534ea90-96c2-11ea-8d81-e3df0146485b",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "678b84c0-96c2-11ea-8d81-e3df0146485b",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "dataField": "visitor"
                                                        },
                                                        {
                                                            "id": "6a228350-96c2-11ea-8d81-e3df0146485b",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "dataField": "purpose"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "path": "/create-visitor-pass"
                                                }
                                            ],
                                            "_sortIndex": 0
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-visitor-passes",
                                    "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                                    "route": "/visitor-passes",
                                    "_sortIndex": 320
                                },
                                {
                                    "id": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "29bfe9a0-97d6-11ea-a9fa-07f95ad6f0c6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a1eade70-8ef4-11ea-9b90-bfc3ee1ce33c",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Welcome",
                                                    "_sortIndex": 0,
                                                    "component": ""
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 0,
                                            "className": "columns is-centered pb-1"
                                        },
                                        {
                                            "id": "78539d80-9148-11ea-a6b6-e7e287632ad5",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c87fc130-9148-11ea-a6b6-e7e287632ad5",
                                                    "type": "button",
                                                    "children": [],
                                                    "text": "View Notifications",
                                                    "path": "/notifications",
                                                    "className": "",
                                                    "variant": "text"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "_sortIndex": 10,
                                            "className": "columns is-centered p-2"
                                        },
                                        {
                                            "id": "2124bcc0-97d7-11ea-a9fa-07f95ad6f0c6",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "25f3f810-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "d37f2010-97da-11ea-a9fa-07f95ad6f0c6",
                                                            "type": "button",
                                                            "children": [],
                                                            "path": "/my-account/me",
                                                            "name": "My Account",
                                                            "className": "app-menu-item",
                                                            "icon": "",
                                                            "text": "",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_account.svg\"><img src=\"./assets/images/icon_account.svg\" /></object>My Account</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "2ef5e090-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "856e1b80-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "",
                                                            "path": "/homeowner-unit-request/0",
                                                            "flex": "[object Object]",
                                                            "className": "app-menu-item",
                                                            "_sortIndex": 10,
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_service.svg\"><img src=\"./assets/images/icon_service.svg\" /></object>Unit Request</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "351a2490-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "874e7440-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "",
                                                            "path": "/file-complaint/0",
                                                            "_sortIndex": 20,
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_complaint.svg\"><img src=\"./assets/images/icon_complaint.svg\" /></object>Comments and Suggestions</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "38053690-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [
                                                                {
                                                                    "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                                    "type": "container",
                                                                    "children": [
                                                                        {
                                                                            "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                                            "type": "button",
                                                                            "children": [],
                                                                            "text": "",
                                                                            "path": "/create-visitor-pass/0",
                                                                            "_sortIndex": 30,
                                                                            "className": "app-menu-item",
                                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                                        }
                                                                    ],
                                                                    "orientation": "horizontal",
                                                                    "className": "app-menu-item-container"
                                                                }
                                                            ],
                                                            "text": "",
                                                            "path": "/create-visitor-pass/0",
                                                            "_sortIndex": 30,
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "8ac2b370-9148-11ea-a6b6-e7e287632ad5",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "",
                                                            "path": "/create-delivery-pass/0",
                                                            "_sortIndex": 40,
                                                            "className": "app-menu-item",
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_delivery.svg\"><img src=\"./assets/images/icon_delivery.svg\" /></object>Delivery Pass</div>"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "2290a530-c921-11ea-9848-69dad3ad605b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "2b49ebf0-c921-11ea-9848-69dad3ad605b",
                                                            "type": "button",
                                                            "children": [],
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_board.svg\"><img src=\"./assets/images/icon_board.svg\" /></object>Message Board</div>",
                                                            "className": "app-menu-item",
                                                            "path": "/message-board"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "columns is-centered",
                                            "_sortIndex": 19
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-welcome",
                                    "active": false,
                                    "className": "column is-centered",
                                    "component": "",
                                    "_sortIndex": 330
                                },
                                {
                                    "id": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                                    "type": "app",
                                    "children": [],
                                    "name": "homeowners",
                                    "sidebar": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                                    "startPage": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                    "active": false,
                                    "_sortIndex": 340
                                },
                                {
                                    "id": "e1156440-93e9-11ea-99e4-a504a663bd48",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "eb7f4134-93e9-11ea-99e4-a504a663bd48",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "nodeType": "DIV",
                                                    "type": "container",
                                                    "className": "row",
                                                    "children": [
                                                        {
                                                            "nodeType": "DIV",
                                                            "type": "container",
                                                            "className": "col",
                                                            "children": [
                                                                {
                                                                    "nodeType": "H1",
                                                                    "type": "header",
                                                                    "className": "h1",
                                                                    "children": [],
                                                                    "text": "Log In",
                                                                    "id": "eb7f4137-93e9-11ea-99e4-a504a663bd48"
                                                                }
                                                            ],
                                                            "id": "eb7f4136-93e9-11ea-99e4-a504a663bd48"
                                                        }
                                                    ],
                                                    "id": "eb7f4135-93e9-11ea-99e4-a504a663bd48"
                                                },
                                                {
                                                    "nodeType": "DIV",
                                                    "type": "container",
                                                    "className": "row mt-4",
                                                    "children": [
                                                        {
                                                            "nodeType": "DIV",
                                                            "type": "container",
                                                            "className": "col",
                                                            "children": [
                                                                {
                                                                    "nodeType": "INPUT",
                                                                    "type": "inputText",
                                                                    "className": "form-control login-form-input",
                                                                    "children": [],
                                                                    "placeholder": "Email",
                                                                    "id": "eb7f413a-93e9-11ea-99e4-a504a663bd48",
                                                                    "name": "email",
                                                                    "variant": "standard"
                                                                },
                                                                {
                                                                    "id": "f6061850-ad8d-11ea-a81e-a3c623c48bc9",
                                                                    "type": "image",
                                                                    "children": [],
                                                                    "url": "./assets/images/icon_user.svg",
                                                                    "className": "login-icons"
                                                                }
                                                            ],
                                                            "id": "eb7f4139-93e9-11ea-99e4-a504a663bd48"
                                                        }
                                                    ],
                                                    "id": "eb7f4138-93e9-11ea-99e4-a504a663bd48"
                                                },
                                                {
                                                    "nodeType": "DIV",
                                                    "type": "container",
                                                    "className": "row mt-3",
                                                    "children": [
                                                        {
                                                            "nodeType": "DIV",
                                                            "type": "container",
                                                            "className": "col",
                                                            "children": [
                                                                {
                                                                    "nodeType": "INPUT",
                                                                    "type": "inputText",
                                                                    "className": "form-control login-form-input",
                                                                    "children": [],
                                                                    "placeholder": "Password",
                                                                    "id": "eb7f413d-93e9-11ea-99e4-a504a663bd48",
                                                                    "name": "password",
                                                                    "password": true,
                                                                    "variant": "standard"
                                                                },
                                                                {
                                                                    "id": "3a4be650-ad90-11ea-a81e-a3c623c48bc9",
                                                                    "type": "image",
                                                                    "children": [],
                                                                    "url": "./assets/images/icon_login_password.svg",
                                                                    "className": "login-icons"
                                                                },
                                                                {
                                                                    "id": "5c31f5f0-ad92-11ea-a2a7-ad35397c4d32",
                                                                    "type": "container",
                                                                    "children": [
                                                                        {
                                                                            "id": "5f285290-ad92-11ea-a2a7-ad35397c4d32",
                                                                            "type": "paragraph",
                                                                            "children": [],
                                                                            "text": "[Forgot your password?](https://www.froala.com)",
                                                                            "_sortIndex": -1
                                                                        }
                                                                    ],
                                                                    "orientation": "horizontal",
                                                                    "className": "mt-3 forgot-password-container",
                                                                    "flex": "[object Object]"
                                                                }
                                                            ],
                                                            "id": "eb7f413c-93e9-11ea-99e4-a504a663bd48"
                                                        }
                                                    ],
                                                    "id": "eb7f413b-93e9-11ea-99e4-a504a663bd48"
                                                },
                                                {
                                                    "nodeType": "DIV",
                                                    "type": "container",
                                                    "className": "row mt-4",
                                                    "children": [
                                                        {
                                                            "nodeType": "DIV",
                                                            "type": "container",
                                                            "className": "col",
                                                            "children": [
                                                                {
                                                                    "nodeType": "BUTTON",
                                                                    "type": "button",
                                                                    "className": "btn btn-outline-secondary",
                                                                    "children": [],
                                                                    "id": "eb7f4141-93e9-11ea-99e4-a504a663bd48",
                                                                    "command": "local_login",
                                                                    "text": "Login",
                                                                    "variant": "",
                                                                    "color": "primary"
                                                                }
                                                            ],
                                                            "id": "eb7f4140-93e9-11ea-99e4-a504a663bd48"
                                                        }
                                                    ],
                                                    "id": "eb7f413f-93e9-11ea-99e4-a504a663bd48"
                                                }
                                            ],
                                            "orientation": "vertical",
                                            "nodeType": "DIV",
                                            "className": "fdb-box px-2"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "login",
                                    "component": "Login",
                                    "_sortIndex": 350
                                },
                                {
                                    "id": "77e05b60-911e-11ea-9e19-2d2007118721",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "7bedcd50-911e-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "name",
                                            "required": true
                                        },
                                        {
                                            "id": "8093aeb0-911e-11ea-9e19-2d2007118721",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "text",
                                            "required": true
                                        },
                                        {
                                            "id": "88181ef0-911e-11ea-9e19-2d2007118721",
                                            "type": "field:date",
                                            "children": [],
                                            "dataType": "date",
                                            "format": "mm/dd/yyyy",
                                            "name": "noticeStart",
                                            "required": true
                                        },
                                        {
                                            "id": "97459e70-911e-11ea-9e19-2d2007118721",
                                            "type": "field:date",
                                            "children": [],
                                            "dataType": "date",
                                            "format": "mm/dd/yyyy",
                                            "name": "noticeEnd"
                                        },
                                        {
                                            "id": "9eb945d0-911e-11ea-9e19-2d2007118721",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "source"
                                        },
                                        {
                                            "id": "a096b760-9129-11ea-a5e9-ff76f0b9af84",
                                            "type": "field",
                                            "children": [],
                                            "dataType": "string",
                                            "name": "status"
                                        }
                                    ],
                                    "name": "notification",
                                    "_sortIndex": 360
                                },
                                {
                                    "id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                            "children": [
                                                {
                                                    "id": "a8fabff0-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a8fabff2-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "name",
                                                            "label": "name",
                                                            "dataField": "name"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a8fabff3-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a8fabff5-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "text",
                                                            "label": "text",
                                                            "dataField": "text"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a8fabff6-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a8fabff8-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputDate",
                                                            "children": [],
                                                            "name": "noticeStart",
                                                            "label": "noticeStart",
                                                            "dataField": "noticeStart",
                                                            "format": "mm/dd/yyyy"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a8fabff9-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a8fabffb-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputDate",
                                                            "children": [],
                                                            "name": "noticeEnd",
                                                            "label": "noticeEnd",
                                                            "dataField": "noticeEnd",
                                                            "format": "mm/dd/yyyy"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a8fabffc-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a8fabffe-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "source",
                                                            "label": "source",
                                                            "dataField": "source"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "a8fabfff-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a8fac001-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "status",
                                                            "label": "status",
                                                            "dataField": "status"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "a8f7b2b0-912e-11ea-ad57-6526d978cdf6",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "notification-detail-page",
                                    "route": "/notifications/:id",
                                    "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                    "_sortIndex": 370
                                },
                                {
                                    "id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                            "children": [
                                                {
                                                    "id": "a9012890-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "subView",
                                                    "children": [],
                                                    "name": "searchbar",
                                                    "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                },
                                                {
                                                    "id": "a9045ce0-912e-11ea-ad57-6526d978cdf6",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "a907b840-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "name",
                                                            "label": "name",
                                                            "dataField": "name"
                                                        },
                                                        {
                                                            "id": "a907b842-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "text",
                                                            "label": "text",
                                                            "dataField": "text"
                                                        },
                                                        {
                                                            "id": "a907b844-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "noticeStart",
                                                            "label": "noticeStart",
                                                            "dataField": "noticeStart"
                                                        },
                                                        {
                                                            "id": "a907b846-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "noticeEnd",
                                                            "label": "noticeEnd",
                                                            "dataField": "noticeEnd"
                                                        },
                                                        {
                                                            "id": "a907b848-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "source",
                                                            "label": "source",
                                                            "dataField": "source"
                                                        },
                                                        {
                                                            "id": "a907b84a-912e-11ea-ad57-6526d978cdf6",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "status",
                                                            "label": "status",
                                                            "dataField": "status"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "table"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "notification-list-page",
                                    "route": "/notifications-list",
                                    "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                    "_sortIndex": 380
                                },
                                {
                                    "id": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "c4081f00-d9c6-11ea-93cf-3dd7e562aac3",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "name": "unit"
                                        },
                                        {
                                            "id": "d7708910-d9c6-11ea-93cf-3dd7e562aac3",
                                            "type": "field:enumeration",
                                            "children": [],
                                            "dataType": "enumeration",
                                            "name": "type"
                                        },
                                        {
                                            "id": "e5db54d0-d9c6-11ea-93cf-3dd7e562aac3",
                                            "type": "field:date",
                                            "children": [],
                                            "dataType": "date",
                                            "format": "mm/dd/yyyy",
                                            "name": "schedule"
                                        },
                                        {
                                            "id": "ee4c9610-d9c6-11ea-93cf-3dd7e562aac3",
                                            "type": "field:json",
                                            "children": [],
                                            "dataType": "json",
                                            "name": "items"
                                        },
                                        {
                                            "id": "f2ed6e60-d9c6-11ea-93cf-3dd7e562aac3",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "company"
                                        },
                                        {
                                            "id": "00b0fda0-d9c7-11ea-93cf-3dd7e562aac3",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "status"
                                        }
                                    ],
                                    "name": "pass-delivery",
                                    "_sortIndex": 390
                                },
                                {
                                    "id": "a5454c50-911e-11ea-9e19-2d2007118721",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "c7d2c620-d9c7-11ea-93cf-3dd7e562aac3",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "name": "unit"
                                        },
                                        {
                                            "id": "cfdbf6c0-d9c7-11ea-93cf-3dd7e562aac3",
                                            "type": "field:json",
                                            "children": [],
                                            "dataType": "json",
                                            "name": "visitor",
                                            "required": true
                                        },
                                        {
                                            "id": "fa4d2320-d9c7-11ea-93cf-3dd7e562aac3",
                                            "type": "field:date",
                                            "children": [],
                                            "dataType": "date",
                                            "format": "mm/dd/yyyy",
                                            "name": "startDate"
                                        },
                                        {
                                            "id": "ff0ec9e0-d9c7-11ea-93cf-3dd7e562aac3",
                                            "type": "field:date",
                                            "children": [],
                                            "dataType": "date",
                                            "format": "mm/dd/yyyy",
                                            "name": "endDate"
                                        },
                                        {
                                            "id": "06a73750-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:file",
                                            "children": [],
                                            "dataType": "file",
                                            "collection": "file",
                                            "via": "related",
                                            "allowedTypes": [
                                                "images",
                                                "files",
                                                "videos"
                                            ],
                                            "plugin": "upload",
                                            "name": "visitorsId"
                                        },
                                        {
                                            "id": "7b087c60-da1a-11ea-b50d-8323e621ee63",
                                            "type": "field:enumeration",
                                            "children": [],
                                            "dataType": "enumeration",
                                            "name": "type"
                                        }
                                    ],
                                    "name": "pass-visitor",
                                    "_sortIndex": 420
                                },
                                {
                                    "id": "555659e0-9138-11ea-bd78-399a56c40918",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "5a734000-9138-11ea-bd78-399a56c40918",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                            "name": "billing"
                                        },
                                        {
                                            "id": "5ecc8800-9138-11ea-bd78-399a56c40918",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                            "name": "homeowner"
                                        },
                                        {
                                            "id": "697da720-9138-11ea-bd78-399a56c40918",
                                            "type": "field:number",
                                            "children": [],
                                            "dataType": "number",
                                            "name": "amount"
                                        }
                                    ],
                                    "name": "payment",
                                    "_sortIndex": 450
                                },
                                {
                                    "type": "view",
                                    "name": "searchbar",
                                    "orientation": "horizontal",
                                    "component": "Searchbar",
                                    "children": [
                                        {
                                            "name": "search",
                                            "type": "inputText",
                                            "id": "e7db5108-8c4a-11ea-9466-6f383d5eb483"
                                        },
                                        {
                                            "id": "e0073b50-8c64-11ea-b629-0161308cc31c",
                                            "type": "button",
                                            "children": [],
                                            "command": "new",
                                            "name": "",
                                            "text": "New"
                                        }
                                    ],
                                    "id": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                    "active": false,
                                    "_sortIndex": 460
                                },
                                {
                                    "id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "63850e21-a55c-11ea-8feb-c7034be1bc66",
                                            "children": [
                                                {
                                                    "id": "da5a80c0-a55c-11ea-99ce-d1e75946b32d",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Marketplace"
                                                },
                                                {
                                                    "id": "ec182100-a55c-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "f232a100-a55c-11ea-99ce-d1e75946b32d",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "07f88310-a55d-11ea-99ce-d1e75946b32d",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "className": "app-menu-item",
                                                                    "text": "",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_water.svg\"><img src=\"./assets/images/icon_water.svg\" /></object>Drinking Water</div>"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        },
                                                        {
                                                            "id": "014dd100-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "26cecc90-a55d-11ea-99ce-d1e75946b32d",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "className": "app-menu-item",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_gas.svg\"><img src=\"./assets/images/icon_gas.svg\" /></object>Gasul</div>"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "columns is-centered mt-2"
                                                },
                                                {
                                                    "id": "b1d7e880-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "494d3680-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "516b0090-a55d-11ea-99ce-d1e75946b32d",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "className": "app-menu-item",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Rice</div>"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        },
                                                        {
                                                            "id": "63098010-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "69f72e90-a55d-11ea-99ce-d1e75946b32d",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "className": "app-menu-item",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_laundry.svg\"><img src=\"./assets/images/icon_laundry.svg\" /></object>Laundry</div>"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "columns is-centered"
                                                },
                                                {
                                                    "id": "c6888a00-a55d-11ea-99ce-d1e75946b32d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ccd51680-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "d4ae5d80-a55d-11ea-99ce-d1e75946b32d",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pet.svg\"><img src=\"./assets/images/icon_pet.svg\" /></object>Pet Grooming</div>",
                                                                    "className": "app-menu-item"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        },
                                                        {
                                                            "id": "f062cd90-a55d-11ea-99ce-d1e75946b32d",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "f2893000-a55d-11ea-99ce-d1e75946b32d",
                                                                    "type": "button",
                                                                    "children": [],
                                                                    "className": "app-menu-item",
                                                                    "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_cleaning.svg\"><img src=\"./assets/images/icon_cleaning.svg\" /></object>Cleaning Service</div>"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "className": "app-menu-item-container"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "columns is-centered"
                                                }
                                            ],
                                            "className": "has-text-centered",
                                            "_sortIndex": -1
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-marketplace",
                                    "route": "/market-place",
                                    "_sortIndex": 470
                                },
                                {
                                    "id": "b8fe9280-c6f5-11ea-b415-c195f50f114e",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "aca19b50-c6f9-11ea-a851-fde2cfff9ce9",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ae765b50-c6f9-11ea-a851-fde2cfff9ce9",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Select Unit"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "columns is-centered pb-1"
                                        },
                                        {
                                            "id": "d8bbe3d0-c6f9-11ea-a851-fde2cfff9ce9",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "dde9e0f0-c6f9-11ea-a851-fde2cfff9ce9",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "029256d0-c6fa-11ea-a851-fde2cfff9ce9",
                                                            "type": "button",
                                                            "children": [],
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 01</div>",
                                                            "className": "app-menu-item",
                                                            "path": "/welcome/01"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                },
                                                {
                                                    "id": "472397a0-c6fa-11ea-a851-fde2cfff9ce9",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "53985fc0-c6fa-11ea-a851-fde2cfff9ce9",
                                                            "type": "button",
                                                            "children": [],
                                                            "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 02</div>",
                                                            "className": "app-menu-item",
                                                            "path": "/welcome/02"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "app-menu-item-container"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "columns is-centered pt-2"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-selection",
                                    "className": "",
                                    "_sortIndex": 480
                                },
                                {
                                    "id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                                    "type": "page",
                                    "children": [
                                        {
                                            "id": "478f4730-c6f9-11ea-8043-0381a4f882af",
                                            "type": "subView",
                                            "children": [],
                                            "view": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-welcome-page",
                                    "route": "/welcome/:id",
                                    "component": "",
                                    "_sortIndex": 490
                                },
                                {
                                    "id": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "743faf70-c90d-11ea-8c59-196c3ec4f839",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a9319f50-c911-11ea-98ab-e51558e3434e",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "label": "Type of Work",
                                                    "name": "meta.type",
                                                    "options": "[{\"value\": \"masonry\", \"label\": \"Masonry\"}, {\"value\": \"carpentry\", \"label\": \"Carpentry\"}, {\"value\": \"electrical\", \"label\": \"Electrical\"}, {\"value\": \"plumbing\", \"label\": \"Plumbing\"}, {\"value\": \"communication\", \"label\": \"Communication\"}, {\"value\": \"painting\", \"label\": \"Painting\"}]",
                                                    "className": "bite-select"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "bite-select"
                                        },
                                        {
                                            "id": "c6681b80-c911-11ea-98ab-e51558e3434e",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c84dcb70-c911-11ea-98ab-e51558e3434e",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "label": "Work Details",
                                                    "name": "meta.details",
                                                    "multiline": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "cf0bde70-c911-11ea-98ab-e51558e3434e",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d46647a0-d908-11ea-8bc1-2d8749eeeb64",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "label": "Preferred Schedule",
                                                    "name": "meta.schedule"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "bite-select"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-request-service-request",
                                    "_sortIndex": 500
                                },
                                {
                                    "id": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "e85ead40-c910-11ea-9dd0-6723feee229e",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "076a4bb0-c91e-11ea-831c-99fd7b91f646",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "meta.plans",
                                                    "label": "Plans Required",
                                                    "options": "[{ \"value\": \"standard\", \"label\": \"Standard\"},{ \"value\": \"as-built\", \"label\": \"As-built Plant\"}]",
                                                    "className": "bite-select",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "name": "homeowner-service-request-repairs",
                                            "className": "bite-select"
                                        },
                                        {
                                            "id": "112746d0-c91e-11ea-831c-99fd7b91f646",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "44b19ca0-d916-11ea-9ea3-7184c3941d0d",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                                    "saveObject": true,
                                                    "name": "meta.paper-size",
                                                    "label": "Paper Size"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "97e9eee0-d916-11ea-9ea3-7184c3941d0d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "9a6e28c0-d916-11ea-9ea3-7184c3941d0d",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "meta.type",
                                                    "label": "Type of Plan",
                                                    "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing/sanitary\", \"label\": \"Plumbing / Sanitary\"},{ \"value\": \"electric-plant\", \"label\": \"Electric Plant\"}]",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-request-plans-documents",
                                    "_sortIndex": 510
                                },
                                {
                                    "id": "063f7330-c911-11ea-9dd0-6723feee229e",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "03ee4ee0-c91f-11ea-b910-c30a77ee18ba",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "59be4b60-d917-11ea-9ea3-7184c3941d0d",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "meta.date",
                                                    "label": "Date of Bond Payment"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "1cea7cc0-c91f-11ea-b910-c30a77ee18ba",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "8dc40350-d917-11ea-9ea3-7184c3941d0d",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "meta.amount",
                                                    "label": "Amount"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": ""
                                        },
                                        {
                                            "id": "9be36e30-d917-11ea-9ea3-7184c3941d0d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "9fea9e90-d917-11ea-9ea3-7184c3941d0d",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "meta.OR-no",
                                                    "label": "O.R./C.R. No."
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "b9eb6b30-d917-11ea-9ea3-7184c3941d0d",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "bb01bd30-d917-11ea-9ea3-7184c3941d0d",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "meta.inspection-date",
                                                    "description": "",
                                                    "label": "Inspection Date"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-request-refund-renovation-bond",
                                    "_sortIndex": 520
                                },
                                {
                                    "id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "231003d1-c920-11ea-9080-1563a0e60ff0",
                                            "className": "p1",
                                            "children": [
                                                {
                                                    "id": "709bda20-c920-11ea-9080-1563a0e60ff0",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Message Board",
                                                    "className": "has-text-centered"
                                                },
                                                {
                                                    "id": "53470b10-c921-11ea-84bc-97bd01295e5b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "566450a0-c921-11ea-84bc-97bd01295e5b",
                                                            "type": "button",
                                                            "children": [],
                                                            "variant": "text",
                                                            "text": "New Post",
                                                            "path": "/new-post"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "justify-content-center pt-1 pb-1"
                                                },
                                                {
                                                    "id": "136c6820-c95b-11ea-82c1-ab59a2213c10",
                                                    "type": "container",
                                                    "children": [],
                                                    "orientation": "horizontal",
                                                    "component": "MessageBoard"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-message-board",
                                    "route": "/message-board",
                                    "_sortIndex": 530
                                },
                                {
                                    "id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "233b7c71-c927-11ea-84bc-97bd01295e5b",
                                            "children": [
                                                {
                                                    "id": "521b2680-c927-11ea-84bc-97bd01295e5b",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "New Post",
                                                    "className": "has-text-centered"
                                                },
                                                {
                                                    "id": "59e133a0-c927-11ea-84bc-97bd01295e5b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "62ac7bc0-c927-11ea-84bc-97bd01295e5b",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "name": "",
                                                            "label": "Post Topic",
                                                            "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"lf\", \"label\": \"LF (Looking For)\"}]",
                                                            "dataField": "topic"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "bite-select pt-2"
                                                },
                                                {
                                                    "id": "df291320-c927-11ea-8d76-f7e6b086de0b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "e2116600-c927-11ea-8d76-f7e6b086de0b",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "",
                                                            "label": "Title",
                                                            "dataField": "title"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "eb70b0c0-c927-11ea-8d76-f7e6b086de0b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "ed3236e0-c927-11ea-8d76-f7e6b086de0b",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "",
                                                            "label": "Price",
                                                            "dataField": "price"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "f42897f0-c927-11ea-8d76-f7e6b086de0b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "f98df8c0-c927-11ea-8d76-f7e6b086de0b",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "",
                                                            "label": "Description",
                                                            "multiline": true,
                                                            "dataField": "description"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "0b6fc2d0-c928-11ea-8d76-f7e6b086de0b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0d2784f0-c928-11ea-8d76-f7e6b086de0b",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "",
                                                            "label": "Contact Number",
                                                            "dataField": "contactNumber"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "373868e0-c928-11ea-8d76-f7e6b086de0b",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "38f5f760-c928-11ea-8d76-f7e6b086de0b",
                                                            "type": "inputMedia",
                                                            "children": [],
                                                            "name": "",
                                                            "label": "Add Photos",
                                                            "dataField": "photos"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "bite-select"
                                                },
                                                {
                                                    "id": "27ad7fc0-c92b-11ea-ad72-2b470c4935f8",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "28c00130-c92b-11ea-ad72-2b470c4935f8",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "Submit",
                                                            "fullWidth": true,
                                                            "command": "save",
                                                            "className": "mt-1"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-new-post",
                                    "route": "/new-post",
                                    "_sortIndex": 540,
                                    "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                                },
                                {
                                    "id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "c5cddbb1-c92f-11ea-baed-470d8f727899",
                                            "children": [
                                                {
                                                    "id": "e1ed3730-c95e-11ea-a864-cfc8ec85c16d",
                                                    "type": "image",
                                                    "children": [],
                                                    "url": "/assets/images/example_furniture.png"
                                                },
                                                {
                                                    "id": "f4e74f10-c95e-11ea-a864-cfc8ec85c16d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "f7e2b4c0-c95e-11ea-a864-cfc8ec85c16d",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "name": "",
                                                            "text": "Sale"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "0bc61b30-c95f-11ea-a864-cfc8ec85c16d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0ceff530-c95f-11ea-a864-cfc8ec85c16d",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "text": "Title: Selling Furniture"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "2f0738e0-c95f-11ea-a864-cfc8ec85c16d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "32075980-c95f-11ea-a864-cfc8ec85c16d",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "text": "Price: Php 1000.00"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "4e2e1360-c95f-11ea-a864-cfc8ec85c16d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "5103c8a0-c95f-11ea-a864-cfc8ec85c16d",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "text": "Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet elit tellus, nec euismod elit vehicula eget. Morbi quis accumsan orci. Nam sed egestas est. Sed facilisis varius risus sit amet eleifend. Praesent consequat quam in lorem elementum, nec porttitor tellus iaculis. Mauris ultrices mauris eu erat ullamcorper, in molestie neque dapibus. Proin aliquet laoreet mi ac suscipit. Maecenas facilisis at nunc nec eleifend. Sed at nisl nisl. Praesent quis interdum augue. Aliquam pulvinar ligula quis purus bibendum malesuada non sed magna."
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "66519480-c95f-11ea-a864-cfc8ec85c16d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "68412f80-c95f-11ea-a864-cfc8ec85c16d",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "text": "Contact Number: +639123456789"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "8919b1a0-c95f-11ea-a864-cfc8ec85c16d",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "8a3b4e40-c95f-11ea-a864-cfc8ec85c16d",
                                                            "type": "paragraph",
                                                            "children": [],
                                                            "text": "Comments"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "form-label"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-post",
                                    "route": "/post/:id",
                                    "_sortIndex": 550
                                },
                                {
                                    "id": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "a86f3310-d84e-11ea-acd1-c1cd20d0424a",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "number"
                                        },
                                        {
                                            "id": "b91aee20-d84e-11ea-acd1-c1cd20d0424a",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "address"
                                        },
                                        {
                                            "id": "bc50ebd0-d84e-11ea-acd1-c1cd20d0424a",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "description"
                                        },
                                        {
                                            "id": "c2e7a0b0-d84e-11ea-acd1-c1cd20d0424a",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "name": "homeowner",
                                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                        },
                                        {
                                            "id": "ae9f6c60-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "status"
                                        }
                                    ],
                                    "name": "unit",
                                    "_sortIndex": 560
                                },
                                {
                                    "id": "8a327e70-d772-11ea-bc04-91a5baad80fc",
                                    "type": "app",
                                    "children": [],
                                    "_sortIndex": 570
                                },
                                {
                                    "id": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "ffb19150-d84d-11ea-acd1-c1cd20d0424a",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "type"
                                        },
                                        {
                                            "id": "09a553e0-d84e-11ea-acd1-c1cd20d0424a",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "status"
                                        },
                                        {
                                            "id": "125e2570-d84e-11ea-acd1-c1cd20d0424a",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "name": "unit"
                                        }
                                    ],
                                    "name": "unit-request",
                                    "_sortIndex": 580
                                },
                                {
                                    "id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                            "children": [
                                                {
                                                    "id": "0824d8f0-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0824d8f2-d84f-11ea-acd1-c1cd20d0424a",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "number",
                                                            "label": "number",
                                                            "dataField": "number"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "0824d8f3-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0824d8f5-d84f-11ea-acd1-c1cd20d0424a",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "address",
                                                            "label": "address",
                                                            "dataField": "address"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "0824d8f6-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0824d8f8-d84f-11ea-acd1-c1cd20d0424a",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "name": "description",
                                                            "label": "description",
                                                            "dataField": "description"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "0824d8f9-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0824d8fb-d84f-11ea-acd1-c1cd20d0424a",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "name": "homeowner",
                                                            "label": "homeowner",
                                                            "dataField": "homeowner",
                                                            "saveObject": true,
                                                            "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "081fa8d0-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "subView",
                                            "children": [],
                                            "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "unit-detail-page",
                                    "route": "/units/:id",
                                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "_sortIndex": 610
                                },
                                {
                                    "id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                            "children": [
                                                {
                                                    "id": "08310df0-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "subView",
                                                    "children": [],
                                                    "name": "searchbar",
                                                    "view": "7ab04570-9138-11ea-bd78-399a56c40918"
                                                },
                                                {
                                                    "id": "08341b30-d84f-11ea-acd1-c1cd20d0424a",
                                                    "type": "table",
                                                    "children": [
                                                        {
                                                            "id": "0838fd30-d84f-11ea-acd1-c1cd20d0424a",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "number",
                                                            "label": "number",
                                                            "dataField": "number"
                                                        },
                                                        {
                                                            "id": "0838fd32-d84f-11ea-acd1-c1cd20d0424a",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "address",
                                                            "label": "address",
                                                            "dataField": "address"
                                                        },
                                                        {
                                                            "id": "0838fd34-d84f-11ea-acd1-c1cd20d0424a",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "description",
                                                            "label": "description",
                                                            "dataField": "description"
                                                        },
                                                        {
                                                            "id": "0838fd36-d84f-11ea-acd1-c1cd20d0424a",
                                                            "type": "column",
                                                            "children": [],
                                                            "orientation": "horizontal",
                                                            "name": "homeowner",
                                                            "label": "homeowner",
                                                            "dataField": "homeowner"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "name": "table"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "unit-list-page",
                                    "route": "/units-list",
                                    "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                    "_sortIndex": 620
                                },
                                {
                                    "id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "d2d04d11-d902-11ea-b7fc-037f001b1342",
                                            "className": "",
                                            "children": [
                                                {
                                                    "id": "04c0e0a0-d903-11ea-b7fc-037f001b1342",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Unit Request",
                                                    "className": "has-text-centered"
                                                },
                                                {
                                                    "id": "afdbb6d0-d904-11ea-8fb7-85b0ea54cdde",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "bfa2b730-d904-11ea-8fb7-85b0ea54cdde",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "View Unit Requests History",
                                                            "variant": "text"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "justify-content-center pt-1 pb-1"
                                                },
                                                {
                                                    "id": "84f0dbb0-d906-11ea-bd61-bf1e0d1003bd",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "020ce720-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "04ead9c0-d9a6-11ea-891d-3722b00ad4e7",
                                                                    "type": "inputSelect",
                                                                    "children": [],
                                                                    "name": "unit",
                                                                    "label": "Unit",
                                                                    "dataField": "",
                                                                    "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "name": "unit-container"
                                                        },
                                                        {
                                                            "id": "21b5e9f0-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "container",
                                                            "children": [
                                                                {
                                                                    "id": "29662520-d9a6-11ea-891d-3722b00ad4e7",
                                                                    "type": "inputSelect",
                                                                    "children": [],
                                                                    "saveObject": true,
                                                                    "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                                    "dataField": "type",
                                                                    "name": "type",
                                                                    "label": "Type"
                                                                }
                                                            ],
                                                            "orientation": "horizontal",
                                                            "name": "type-container"
                                                        },
                                                        {
                                                            "id": "47ac1580-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                            "name": "homeowner-unit-request-service-request"
                                                        },
                                                        {
                                                            "id": "60137d70-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                            "name": "homeowner-unit-request-plans-documents"
                                                        },
                                                        {
                                                            "id": "71865910-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                            "name": "homeowner-unit-request-refund-renovation-bond"
                                                        },
                                                        {
                                                            "id": "7c6788e0-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                                            "name": "homeowner-unit-request-vehicle-sticker"
                                                        },
                                                        {
                                                            "id": "82e844c0-d9a6-11ea-891d-3722b00ad4e7",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                                            "name": "homeowner-unit-request-grease-trap-cleaning"
                                                        },
                                                        {
                                                            "id": "30acac90-d9a7-11ea-9c8b-b7f572d43c0f",
                                                            "type": "subView",
                                                            "children": [],
                                                            "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                                            "name": "homeowner-unit-request-aircon-cleaning"
                                                        },
                                                        {
                                                            "id": "16922b80-d9a9-11ea-9331-090047662dd2",
                                                            "type": "subView",
                                                            "children": [],
                                                            "name": "homeowner-unit-request-others",
                                                            "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "component": "UnitRequest",
                                                    "name": ""
                                                },
                                                {
                                                    "id": "0dd9e6c0-dac8-11ea-9725-dfc9cbd591dc",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "0f33f880-dac8-11ea-9725-dfc9cbd591dc",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "Submit",
                                                            "fullWidth": true,
                                                            "className": "mt-1",
                                                            "command": "save"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-request",
                                    "className": "",
                                    "route": "/homeowner-unit-request/:id",
                                    "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                                    "_sortIndex": 630
                                },
                                {
                                    "id": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "9a02aad0-d918-11ea-b3eb-d9cf4159b042",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a6157cd0-d918-11ea-b3eb-d9cf4159b042",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "meta.parking-unit-number",
                                                    "label": "Parking Unit Number"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "02e8c340-d919-11ea-b3eb-d9cf4159b042",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "25111df0-d919-11ea-b3eb-d9cf4159b042",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "meta.status",
                                                    "label": "Status",
                                                    "options": "[{\"label\":\"Owned\", \"value\":\"owned\"},{\"label\":\"Rented\", \"value\":\"rented\"}]",
                                                    "saveObject": true
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "b33b28f0-d919-11ea-b3eb-d9cf4159b042",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "b80cd540-d919-11ea-b3eb-d9cf4159b042",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "meta.make",
                                                    "label": "Make"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c394f370-d919-11ea-b3eb-d9cf4159b042",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c6827670-d919-11ea-b3eb-d9cf4159b042",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "meta.model",
                                                    "label": "Model"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "ad71c600-d9a4-11ea-a304-319701533d32",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "b1b90ca0-d9a4-11ea-a304-319701533d32",
                                                    "type": "inputSelect",
                                                    "children": [],
                                                    "name": "meta.color",
                                                    "label": "Color"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "c744cbe0-d9a4-11ea-a304-319701533d32",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "cfb34e00-d9a4-11ea-a304-319701533d32",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "meta.plate-number",
                                                    "label": "Plate Number"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "ddd18060-d9a4-11ea-a304-319701533d32",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "deacec40-d9a4-11ea-a304-319701533d32",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "meta.OR",
                                                    "description": "",
                                                    "label": "Upload O.R. / C.R."
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-request-vehicle-sticker",
                                    "_sortIndex": 640
                                },
                                {
                                    "id": "0c744880-d9a5-11ea-a304-319701533d32",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "1c9ddb40-d9a5-11ea-a304-319701533d32",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "267c1a00-d9a5-11ea-a304-319701533d32",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "meta.schedule",
                                                    "description": "",
                                                    "label": "Schedule"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-request-grease-trap-cleaning",
                                    "_sortIndex": 660
                                },
                                {
                                    "id": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "d3e39f50-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d5efea10-d9a6-11ea-891d-3722b00ad4e7",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "meta.units",
                                                    "label": "No. of Units"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "db6aa7a0-d9a6-11ea-891d-3722b00ad4e7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "2d6be400-d9a8-11ea-b622-15dab671ce91",
                                                    "type": "inputDateTime",
                                                    "children": [],
                                                    "name": "meta.schedule",
                                                    "label": "Schedule"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-request-aircon-cleaning",
                                    "_sortIndex": 670
                                },
                                {
                                    "id": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "fe4688f0-d9a8-11ea-9331-090047662dd2",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ff497a00-d9a8-11ea-9331-090047662dd2",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "multiline": true,
                                                    "name": "meta.details",
                                                    "label": "Please Specify"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-unit-request-others",
                                    "_sortIndex": 680
                                },
                                {
                                    "id": "9923cf30-d9aa-11ea-b236-955d418fe434",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "a2da88c0-d9aa-11ea-b236-955d418fe434",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                            "name": "unit"
                                        },
                                        {
                                            "id": "3c73c200-d9c7-11ea-93cf-3dd7e562aac3",
                                            "type": "field:enumeration",
                                            "children": [],
                                            "dataType": "enumeration",
                                            "name": "natureOfWork"
                                        },
                                        {
                                            "id": "e5adc5e0-d9aa-11ea-b236-955d418fe434",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "contractor"
                                        },
                                        {
                                            "id": "f6154c00-d9aa-11ea-b236-955d418fe434",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "personInCharge"
                                        },
                                        {
                                            "id": "007fec40-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "contactPersonInCharge"
                                        },
                                        {
                                            "id": "65eeb840-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:json",
                                            "children": [],
                                            "dataType": "json",
                                            "name": "workers"
                                        },
                                        {
                                            "id": "71f75110-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "description"
                                        },
                                        {
                                            "id": "7a2f59e0-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:json",
                                            "children": [],
                                            "dataType": "json",
                                            "name": "materials"
                                        },
                                        {
                                            "id": "84753410-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:json",
                                            "children": [],
                                            "dataType": "json",
                                            "name": "tools"
                                        },
                                        {
                                            "id": "8a20ecb0-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:date",
                                            "children": [],
                                            "dataType": "date",
                                            "format": "mm/dd/yyyy",
                                            "name": "startDate"
                                        },
                                        {
                                            "id": "9113f260-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:date",
                                            "children": [],
                                            "dataType": "date",
                                            "format": "mm/dd/yyyy",
                                            "name": "endDate"
                                        },
                                        {
                                            "id": "990a5e50-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:file",
                                            "children": [],
                                            "dataType": "file",
                                            "collection": "file",
                                            "via": "related",
                                            "allowedTypes": [
                                                "images",
                                                "files",
                                                "videos"
                                            ],
                                            "plugin": "upload",
                                            "name": "plans"
                                        },
                                        {
                                            "id": "a6388b60-d9ab-11ea-b236-955d418fe434",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "status"
                                        }
                                    ],
                                    "name": "work-permit",
                                    "_sortIndex": 690
                                },
                                {
                                    "id": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                                    "type": "model",
                                    "children": [
                                        {
                                            "id": "b3aa23e0-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:relation",
                                            "children": [],
                                            "dataType": "relation",
                                            "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                            "name": "homeowner"
                                        },
                                        {
                                            "id": "c20c3d10-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:enumeration",
                                            "children": [],
                                            "dataType": "enumeration",
                                            "name": "topic"
                                        },
                                        {
                                            "id": "d7f25150-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "title"
                                        },
                                        {
                                            "id": "dd8b6c50-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "price"
                                        },
                                        {
                                            "id": "e1c656e0-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "description"
                                        },
                                        {
                                            "id": "eb73c1a0-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "contactNumber"
                                        },
                                        {
                                            "id": "f1279090-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:file",
                                            "children": [],
                                            "dataType": "file",
                                            "collection": "file",
                                            "via": "related",
                                            "allowedTypes": [
                                                "images",
                                                "files",
                                                "videos"
                                            ],
                                            "plugin": "upload",
                                            "name": "photos"
                                        },
                                        {
                                            "id": "ff6cabe0-d9c8-11ea-93cf-3dd7e562aac3",
                                            "type": "field:text",
                                            "children": [],
                                            "dataType": "text",
                                            "name": "status"
                                        }
                                    ],
                                    "_sortIndex": 729,
                                    "name": "message-board"
                                },
                                {
                                    "id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                                    "type": "page",
                                    "children": [
                                        {
                                            "name": "main",
                                            "type": "container",
                                            "id": "dac583a1-d9ca-11ea-b444-450a6b52ef90",
                                            "children": [
                                                {
                                                    "id": "f6978dd0-d9ca-11ea-b444-450a6b52ef90",
                                                    "type": "header",
                                                    "children": [],
                                                    "size": 2,
                                                    "text": "Work Permit",
                                                    "className": "has-text-centered"
                                                },
                                                {
                                                    "id": "a2b3bab0-da09-11ea-87ea-f5980a7a1cab",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "a4b07510-da09-11ea-87ea-f5980a7a1cab",
                                                            "type": "button",
                                                            "children": [],
                                                            "text": "View Work Permit History",
                                                            "variant": "text"
                                                        }
                                                    ],
                                                    "orientation": "horizontal",
                                                    "className": "justify-content-center pt-1 pb-1"
                                                },
                                                {
                                                    "id": "5ad52cd0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "607dff40-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                            "name": "unit",
                                                            "label": "Unit",
                                                            "dataField": "unit"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "9d05c150-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "bb60e760-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                            "type": "inputSelect",
                                                            "children": [],
                                                            "dataField": "natureOfWork",
                                                            "label": "Nature of Work"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "d06acc70-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "d1a632a0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "contractor",
                                                            "label": "Name of Contractor"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "dfc94700-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "e0f19a60-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "personInCharge",
                                                            "label": "Name of Person in Charge"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "ec2bbfa0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "eee8e3d0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "dataField": "contactPersonInCharge",
                                                            "label": "Contact Number of Person in Charge"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "4f549340-d9cc-11ea-83f3-4b9e6a8a3ce6",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "12242dc0-d9cf-11ea-be94-03767312b052",
                                                            "type": "inputList",
                                                            "children": [],
                                                            "label": "Workers",
                                                            "dataField": "workers"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "77fa9030-da06-11ea-8a8e-430e67178950",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "7d094580-da06-11ea-8a8e-430e67178950",
                                                            "type": "inputText",
                                                            "children": [],
                                                            "multiline": true,
                                                            "dataField": "description",
                                                            "label": "Description of Work"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "f2f0d5b0-da06-11ea-8a8e-430e67178950",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "fe279f90-da06-11ea-8a8e-430e67178950",
                                                            "type": "inputList",
                                                            "children": [],
                                                            "dataField": "materials",
                                                            "label": "List of Materials"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "2ebf0bc0-da07-11ea-8a8e-430e67178950",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "33285950-da07-11ea-8a8e-430e67178950",
                                                            "type": "inputList",
                                                            "children": [],
                                                            "dataField": "tools",
                                                            "label": "List of Tools"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "3bd32120-da07-11ea-8a8e-430e67178950",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "3e8fd020-da07-11ea-8a8e-430e67178950",
                                                            "type": "inputDate",
                                                            "children": [],
                                                            "dataField": "startDate",
                                                            "label": "Proposed Start Date "
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "5217a910-da07-11ea-8a8e-430e67178950",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "558c3660-da07-11ea-8a8e-430e67178950",
                                                            "type": "inputDate",
                                                            "children": [],
                                                            "label": "Target End Date",
                                                            "dataField": "endDate"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "67781290-da07-11ea-8a8e-430e67178950",
                                                    "type": "container",
                                                    "children": [
                                                        {
                                                            "id": "6a6f0b70-da07-11ea-8a8e-430e67178950",
                                                            "type": "inputMedia",
                                                            "children": [],
                                                            "dataField": "plans",
                                                            "label": "Photo of Plans"
                                                        }
                                                    ],
                                                    "orientation": "horizontal"
                                                },
                                                {
                                                    "id": "3ad7b170-dac8-11ea-9725-dfc9cbd591dc",
                                                    "type": "button",
                                                    "children": [],
                                                    "command": "save",
                                                    "text": "Submit",
                                                    "className": "mt-1"
                                                }
                                            ]
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-work-permit",
                                    "dataModel": "9923cf30-d9aa-11ea-b236-955d418fe434",
                                    "route": "/homeowner-work-permit/:id"
                                },
                                {
                                    "id": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "94ab5510-da1b-11ea-87e9-9de57275fc27",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "9c1bc820-da1b-11ea-87e9-9de57275fc27",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "meta.relationship",
                                                    "label": "Relationship to Owner"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "b7ed0f00-da1b-11ea-87e9-9de57275fc27",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "b9749960-da1b-11ea-87e9-9de57275fc27",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "name": "visitor",
                                                    "description": "",
                                                    "label": "Name/s"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "cb6d1fc0-da1b-11ea-87e9-9de57275fc27",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "cebbae80-da1b-11ea-87e9-9de57275fc27",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "startDate",
                                                    "label": "Start Date"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "d92934f0-da1b-11ea-87e9-9de57275fc27",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "db4adc70-da1b-11ea-87e9-9de57275fc27",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "endDate",
                                                    "label": "End Date"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "e6edfcb0-da1b-11ea-87e9-9de57275fc27",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "ed1bdda0-da1b-11ea-87e9-9de57275fc27",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "visitorsId",
                                                    "label": "Upload Valid ID (optional)"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-visitor-pass-visitor-pass"
                                },
                                {
                                    "id": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "a0ff15c0-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "a2036660-da1d-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "name": "visitor",
                                                    "label": "Name/s"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "bb50c180-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "c70a7700-da1d-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "startDate",
                                                    "label": "Start Date"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "cdb2b810-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "d233d360-da1d-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "endDate",
                                                    "label": "End Date"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "e0d391d0-da1d-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "e4f28ff0-da1d-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "visitorsId",
                                                    "label": "Upload Valid ID/ Passport"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-visitor-pass-short-term"
                                },
                                {
                                    "id": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                    "type": "view",
                                    "children": [
                                        {
                                            "id": "38c26740-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3d14aa60-da1e-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputList",
                                                    "children": [],
                                                    "name": "visitor",
                                                    "label": "Name/s"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "49eb4190-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "4b75d930-da1e-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputDate",
                                                    "children": [],
                                                    "name": "startDate",
                                                    "label": "Start Date"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "5279ced0-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "542d4b30-da1e-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "name": "endDate",
                                                    "label": "End Date"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "5ed590b0-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "603f80f0-da1e-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "visitorsId",
                                                    "label": "Upload valid ID/ Passport (required)"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        },
                                        {
                                            "id": "6f845900-da1e-11ea-abba-db7aa6b9e7d7",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "710098c0-da1e-11ea-abba-db7aa6b9e7d7",
                                                    "type": "inputMedia",
                                                    "children": [],
                                                    "name": "meta.contract",
                                                    "label": "Upload Contract of Lease (required)"
                                                }
                                            ],
                                            "orientation": "horizontal"
                                        }
                                    ],
                                    "orientation": "vertical",
                                    "name": "homeowner-visitor-pass-long-term"
                                }
                            ],
                            "server": "pacificland.bitesizedigital.net",
                            "undoLength": 0,
                            "name": "pacific land - midpoint",
                            "_state": {},
                            "activeApp": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                            "_locked": false,
                            "updateKey": "3be954f0-d9ca-11ea-b444-450a6b52ef90",
                            "": {
                                "_id": "5f30dd3a1c197f15c652bf5f",
                                "id": "001118a0-dace-11ea-b1ee-f7a46b202fc4",
                                "type": "project",
                                "routes": [
                                    {
                                        "page": "billing-detail-page",
                                        "path": "/billings/:id",
                                        "node": {
                                            "_id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "billing-list-page",
                                        "path": "/billings-list",
                                        "node": {
                                            "_id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "complaint-detail-page",
                                        "path": "/complaints/:id",
                                        "node": {
                                            "_id": "0c4b0b30-914f-11ea-b2e4-0783314da7b1",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "complaint-list-page",
                                        "path": "/complaints-list",
                                        "node": {
                                            "_id": "0c6263c0-914f-11ea-b2e4-0783314da7b1",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "department-detail-page",
                                        "path": "/departments/:id",
                                        "node": {
                                            "_id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "department-list-page",
                                        "path": "/departments-list",
                                        "node": {
                                            "_id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "employee-detail-page",
                                        "path": "/employees/:id",
                                        "node": {
                                            "_id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "employee-list-page",
                                        "path": "/employees-list",
                                        "node": {
                                            "_id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-account",
                                        "path": "/my-account/:id",
                                        "node": {
                                            "_id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-billing",
                                        "path": "/billing",
                                        "node": {
                                            "_id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-complaint",
                                        "path": "/file-complaint/:id",
                                        "node": {
                                            "_id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-complaints",
                                        "path": "/filed-complaints",
                                        "node": {
                                            "_id": "47440520-971c-11ea-8a71-fd00fe88963d",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-delivery-pass",
                                        "path": "/create-delivery-pass/:id",
                                        "node": {
                                            "_id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-delivery-passes",
                                        "path": "/delivery-passes",
                                        "node": {
                                            "_id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-login-page",
                                        "path": "/login",
                                        "node": {
                                            "_id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-notifications",
                                        "path": "/notifications",
                                        "node": {
                                            "_id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-password",
                                        "path": "/change-password",
                                        "node": {
                                            "_id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-profile",
                                        "path": "/profile/:id",
                                        "node": {
                                            "_id": "17466790-96d2-11ea-9995-9d94cc393546",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-service-request",
                                        "path": "/create-service-request/:id",
                                        "node": {
                                            "_id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-service-requests",
                                        "path": "/service-requests",
                                        "node": {
                                            "_id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-visitor-pass",
                                        "path": "/create-visitor-pass/:id",
                                        "node": {
                                            "_id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-visitor-passes",
                                        "path": "/visitor-passes",
                                        "node": {
                                            "_id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "notification-detail-page",
                                        "path": "/notifications/:id",
                                        "node": {
                                            "_id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "notification-list-page",
                                        "path": "/notifications-list",
                                        "node": {
                                            "_id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-marketplace",
                                        "path": "/market-place",
                                        "node": {
                                            "_id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-welcome-page",
                                        "path": "/welcome/:id",
                                        "node": {
                                            "_id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-message-board",
                                        "path": "/message-board",
                                        "node": {
                                            "_id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-new-post",
                                        "path": "/new-post",
                                        "node": {
                                            "_id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-post",
                                        "path": "/post/:id",
                                        "node": {
                                            "_id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "unit-detail-page",
                                        "path": "/units/:id",
                                        "node": {
                                            "_id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "unit-list-page",
                                        "path": "/units-list",
                                        "node": {
                                            "_id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-unit-request",
                                        "path": "/homeowner-unit-request/:id",
                                        "node": {
                                            "_id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                                            "type": "page"
                                        }
                                    },
                                    {
                                        "page": "homeowner-work-permit",
                                        "path": "/homeowner-work-permit/:id",
                                        "node": {
                                            "_id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                                            "type": "page"
                                        }
                                    }
                                ],
                                "children": [
                                    {
                                        "id": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "35bfcda0-9138-11ea-bd78-399a56c40918",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                                "name": "homeowner",
                                                "_sortIndex": -1
                                            },
                                            {
                                                "id": "3040a340-9138-11ea-bd78-399a56c40918",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "name",
                                                "_sortIndex": 0
                                            },
                                            {
                                                "id": "40b4ac80-9138-11ea-bd78-399a56c40918",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "description"
                                            },
                                            {
                                                "id": "45aeef70-9138-11ea-bd78-399a56c40918",
                                                "type": "field:number",
                                                "children": [],
                                                "dataType": "number",
                                                "name": "amount"
                                            },
                                            {
                                                "id": "6fa1c410-9138-11ea-bd78-399a56c40918",
                                                "type": "field:json",
                                                "children": [],
                                                "dataType": "json",
                                                "name": "details"
                                            }
                                        ],
                                        "name": "billing",
                                        "_sortIndex": 0
                                    },
                                    {
                                        "id": "7a9ce480-9138-11ea-bd78-399a56c40918",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                                "children": [
                                                    {
                                                        "id": "7aa7e100-9138-11ea-bd78-399a56c40918",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "7aa7e102-9138-11ea-bd78-399a56c40918",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "name": "homeowner",
                                                                "label": "homeowner",
                                                                "dataField": "homeowner",
                                                                "saveObject": true,
                                                                "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "7aa7e103-9138-11ea-bd78-399a56c40918",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "7aa7e105-9138-11ea-bd78-399a56c40918",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "name",
                                                                "label": "name",
                                                                "dataField": "name"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "7aa7e106-9138-11ea-bd78-399a56c40918",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "7aa7e108-9138-11ea-bd78-399a56c40918",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "description",
                                                                "label": "description",
                                                                "dataField": "description"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "7aa7e109-9138-11ea-bd78-399a56c40918",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "7aa7e10b-9138-11ea-bd78-399a56c40918",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "amount",
                                                                "label": "amount",
                                                                "dataField": "amount"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "7aa7e10c-9138-11ea-bd78-399a56c40918",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "7aa7e10e-9138-11ea-bd78-399a56c40918",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "details",
                                                                "label": "details",
                                                                "dataField": "details"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": "7aa39b40-9138-11ea-bd78-399a56c40918",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "billing-detail-page",
                                        "route": "/billings/:id",
                                        "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                        "component": "",
                                        "_sortIndex": 10
                                    },
                                    {
                                        "id": "7aac26c0-9138-11ea-bd78-399a56c40918",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "1d764df1-9138-11ea-bd78-399a56c40918",
                                                "children": [
                                                    {
                                                        "id": "7ab04570-9138-11ea-bd78-399a56c40918",
                                                        "type": "subView",
                                                        "children": [],
                                                        "name": "searchbar",
                                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                    },
                                                    {
                                                        "id": "7ab48b30-9138-11ea-bd78-399a56c40918",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "7ab94620-9138-11ea-bd78-399a56c40918",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "homeowner",
                                                                "label": "homeowner",
                                                                "dataField": "homeowner"
                                                            },
                                                            {
                                                                "id": "7ab94622-9138-11ea-bd78-399a56c40918",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "name",
                                                                "label": "name",
                                                                "dataField": "name"
                                                            },
                                                            {
                                                                "id": "7ab94624-9138-11ea-bd78-399a56c40918",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "description",
                                                                "label": "description",
                                                                "dataField": "description"
                                                            },
                                                            {
                                                                "id": "7ab94626-9138-11ea-bd78-399a56c40918",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "amount",
                                                                "label": "amount",
                                                                "dataField": "amount"
                                                            },
                                                            {
                                                                "id": "7ab94628-9138-11ea-bd78-399a56c40918",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "details",
                                                                "label": "details",
                                                                "dataField": "details"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "table"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "billing-list-page",
                                        "route": "/billings-list",
                                        "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                        "_sortIndex": 20
                                    },
                                    {
                                        "type": "view",
                                        "name": "commandbar",
                                        "orientation": "horizontal",
                                        "children": [
                                            {
                                                "name": "save",
                                                "type": "button",
                                                "id": "e7db5105-8c4a-11ea-9466-6f383d5eb483",
                                                "command": "save"
                                            },
                                            {
                                                "name": "delete",
                                                "type": "button",
                                                "id": "e7db5106-8c4a-11ea-9466-6f383d5eb483",
                                                "command": "delete"
                                            }
                                        ],
                                        "id": "e7db5104-8c4a-11ea-9466-6f383d5eb483",
                                        "active": false,
                                        "_sortIndex": 30
                                    },
                                    {
                                        "id": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "5ffd16d0-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:enumeration",
                                                "children": [],
                                                "dataType": "enumeration",
                                                "name": "type"
                                            },
                                            {
                                                "id": "6453edd0-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "details"
                                            },
                                            {
                                                "id": "6ac651d0-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:file",
                                                "children": [],
                                                "dataType": "file",
                                                "collection": "file",
                                                "via": "related",
                                                "allowedTypes": [
                                                    "images",
                                                    "files",
                                                    "videos"
                                                ],
                                                "plugin": "upload",
                                                "name": "photos"
                                            },
                                            {
                                                "id": "848ad8c0-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                                "name": "homeowner"
                                            }
                                        ],
                                        "name": "complaint",
                                        "_sortIndex": 40
                                    },
                                    {
                                        "id": "0c4b0b30-914f-11ea-b2e4-0783314da7b1",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                                "children": [
                                                    {
                                                        "id": "0c5cbe70-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0c5cbe72-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "name": "homeowner",
                                                                "label": "homeowner",
                                                                "dataField": "homeowner",
                                                                "saveObject": true,
                                                                "relation": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                                                "popup": true
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 0
                                                    },
                                                    {
                                                        "id": "c9f10042-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "c9f10044-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "item",
                                                                "label": "item",
                                                                "dataField": "item"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 10
                                                    },
                                                    {
                                                        "id": "0c5cbe73-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0c5cbe75-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "details",
                                                                "label": "details",
                                                                "dataField": "details"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 20
                                                    },
                                                    {
                                                        "id": "0c5cbe76-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0c5cbe78-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "inputMedia",
                                                                "children": [],
                                                                "name": "photo",
                                                                "label": "photo",
                                                                "dataField": "photo",
                                                                "basePath": "complaints"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 30
                                                    },
                                                    {
                                                        "id": "c9f0d930-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "c9f10041-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "category",
                                                                "label": "category",
                                                                "dataField": "category"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 39
                                                    },
                                                    {
                                                        "id": "0c5cbe79-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0c5cbe7b-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "status",
                                                                "label": "status",
                                                                "dataField": "status"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 40
                                                    },
                                                    {
                                                        "id": "9bf98160-9151-11ea-9407-3325ebf78f84",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "9bf98162-9151-11ea-9407-3325ebf78f84",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "location",
                                                                "label": "location",
                                                                "dataField": "location"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "ac07cb60-d9c8-11ea-93cf-3dd7e562aac3",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "ac07cb62-d9c8-11ea-93cf-3dd7e562aac3",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "type",
                                                                "label": "type",
                                                                "dataField": "type"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "ac07f270-d9c8-11ea-93cf-3dd7e562aac3",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "ac07f272-d9c8-11ea-93cf-3dd7e562aac3",
                                                                "type": "inputMedia",
                                                                "children": [],
                                                                "name": "photos",
                                                                "label": "photos",
                                                                "dataField": "photos",
                                                                "basePath": "complaints"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ],
                                                "className": ""
                                            },
                                            {
                                                "id": "0c56cb00-914f-11ea-b2e4-0783314da7b1",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "complaint-detail-page",
                                        "route": "/complaints/:id",
                                        "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                        "_sortIndex": 50
                                    },
                                    {
                                        "id": "0c6263c0-914f-11ea-b2e4-0783314da7b1",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                                "children": [
                                                    {
                                                        "id": "0c67e200-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "subView",
                                                        "children": [],
                                                        "name": "searchbar",
                                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                    },
                                                    {
                                                        "id": "0c6d6040-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "0c72b770-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "homeowner",
                                                                "label": "homeowner",
                                                                "dataField": "homeowner"
                                                            },
                                                            {
                                                                "id": "0c72b772-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "details",
                                                                "label": "details",
                                                                "dataField": "details"
                                                            },
                                                            {
                                                                "id": "0c72b774-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "photo",
                                                                "label": "photo",
                                                                "dataField": "photo"
                                                            },
                                                            {
                                                                "id": "0c72b776-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "status",
                                                                "label": "status",
                                                                "dataField": "status"
                                                            },
                                                            {
                                                                "id": "c9fa4f10-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "category",
                                                                "label": "category",
                                                                "dataField": "category"
                                                            },
                                                            {
                                                                "id": "c9fa4f12-914f-11ea-b2e4-0783314da7b1",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "item",
                                                                "label": "item",
                                                                "dataField": "item"
                                                            },
                                                            {
                                                                "id": "9bffc2f0-9151-11ea-9407-3325ebf78f84",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "location",
                                                                "label": "location",
                                                                "dataField": "location"
                                                            },
                                                            {
                                                                "id": "ac0c1120-d9c8-11ea-93cf-3dd7e562aac3",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "type",
                                                                "label": "type",
                                                                "dataField": "type"
                                                            },
                                                            {
                                                                "id": "ac0c1122-d9c8-11ea-93cf-3dd7e562aac3",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "photos",
                                                                "label": "photos",
                                                                "dataField": "photos"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "table"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "complaint-list-page",
                                        "route": "/complaints-list",
                                        "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                        "_sortIndex": 60
                                    },
                                    {
                                        "id": "bf15a990-8eee-11ea-812f-f97d6ad426ae",
                                        "type": "app",
                                        "children": [],
                                        "name": "dashboard",
                                        "sidebar": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                        "active": true,
                                        "component": "",
                                        "_sortIndex": 70
                                    },
                                    {
                                        "id": "7b034e00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "7ee9d700-8ef4-11ea-9b90-bfc3ee1ce33c",
                                                "type": "menu",
                                                "children": [
                                                    {
                                                        "id": "3cf81350-9130-11ea-af3e-29e246413619",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "_sortIndex": 0,
                                                        "path": "/",
                                                        "label": "Main Menu"
                                                    },
                                                    {
                                                        "id": "7abd8be0-9138-11ea-bd78-399a56c40918",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/billings-list",
                                                        "label": "Billing",
                                                        "_sortIndex": 10
                                                    },
                                                    {
                                                        "id": "1854b570-914f-11ea-b2e4-0783314da7b1",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/complaints-list",
                                                        "label": "Complaints",
                                                        "_sortIndex": 19
                                                    },
                                                    {
                                                        "id": "d8c9a170-912d-11ea-82bc-71c30f9d69df",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/departments-list",
                                                        "label": "Departments",
                                                        "_sortIndex": 20
                                                    },
                                                    {
                                                        "id": "df5755a0-912d-11ea-82bc-71c30f9d69df",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/employees-list",
                                                        "label": "Employees",
                                                        "_sortIndex": 30
                                                    },
                                                    {
                                                        "id": "e1041b40-912d-11ea-82bc-71c30f9d69df",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/homeowners-list",
                                                        "label": "Homeowners",
                                                        "_sortIndex": 40
                                                    },
                                                    {
                                                        "id": "e3379d10-912d-11ea-82bc-71c30f9d69df",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/notifications-list",
                                                        "label": "Notifications",
                                                        "_sortIndex": 50
                                                    },
                                                    {
                                                        "id": "e6566940-912d-11ea-82bc-71c30f9d69df",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/pass-deliveries-list",
                                                        "label": "Passes - Delivery",
                                                        "_sortIndex": 60
                                                    },
                                                    {
                                                        "id": "e86c29e0-912d-11ea-82bc-71c30f9d69df",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/pass-visitors-list",
                                                        "label": "Passes - Visitors",
                                                        "_sortIndex": 70
                                                    },
                                                    {
                                                        "id": "9c3e1dc0-914c-11ea-b5d1-292f71dadca1",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/service-requests-list",
                                                        "label": "Service Request",
                                                        "_sortIndex": 80
                                                    }
                                                ],
                                                "name": ""
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "dashboard-sidebar",
                                        "active": false,
                                        "_sortIndex": 80
                                    },
                                    {
                                        "id": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "1ba0dd50-8ce8-11ea-a283-ebcb859bf8c8",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "name",
                                                "required": true,
                                                "searchable": true
                                            },
                                            {
                                                "id": "22702e60-8ce8-11ea-a283-ebcb859bf8c8",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "description",
                                                "required": true
                                            }
                                        ],
                                        "name": "department",
                                        "active": false,
                                        "_sortIndex": 90
                                    },
                                    {
                                        "id": "99cfb170-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                                "children": [
                                                    {
                                                        "id": "99dbbf60-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "99dbbf62-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "name",
                                                                "label": "Department",
                                                                "dataField": "name"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "99dbbf63-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "99dbbf65-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "description",
                                                                "label": "Description",
                                                                "dataField": "description",
                                                                "description": "",
                                                                "multiline": true
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": "99d8b220-912e-11ea-ad57-6526d978cdf6",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "department-detail-page",
                                        "route": "/departments/:id",
                                        "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                        "_sortIndex": 100
                                    },
                                    {
                                        "id": "942e0af0-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                                "children": [
                                                    {
                                                        "id": "94311830-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "subView",
                                                        "children": [],
                                                        "name": "searchbar",
                                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                    },
                                                    {
                                                        "id": "9433b040-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "94364850-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "name",
                                                                "label": "Name",
                                                                "dataField": "name"
                                                            },
                                                            {
                                                                "id": "94364852-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "description",
                                                                "label": "Description",
                                                                "dataField": "description"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "table"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "department-list-page",
                                        "route": "/departments-list",
                                        "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                        "_sortIndex": 110
                                    },
                                    {
                                        "id": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "7288d810-8c4e-11ea-9466-6f383d5eb483",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "employeeid",
                                                "required": true,
                                                "searchable": true,
                                                "_sortIndex": 0
                                            },
                                            {
                                                "id": "49b35e80-9129-11ea-a5e9-ff76f0b9af84",
                                                "type": "field:email",
                                                "children": [],
                                                "dataType": "email",
                                                "name": "email",
                                                "required": true,
                                                "_sortIndex": 9
                                            },
                                            {
                                                "id": "780a4c60-8c4e-11ea-9466-6f383d5eb483",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "firstName",
                                                "required": true,
                                                "searchable": true,
                                                "_sortIndex": 10
                                            },
                                            {
                                                "id": "837ebb80-8c4e-11ea-9466-6f383d5eb483",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "lastName",
                                                "required": true,
                                                "searchable": true,
                                                "_sortIndex": 20
                                            },
                                            {
                                                "id": "e68f77d0-8c91-11ea-bb0b-0b00cf6ac98c",
                                                "type": "field:date",
                                                "children": [],
                                                "dataType": "date",
                                                "format": "mm/dd/yyyy",
                                                "name": "birthday",
                                                "required": true,
                                                "_sortIndex": 30
                                            },
                                            {
                                                "id": "c11b9d10-8e7d-11ea-810e-c1e68e180dd7",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8",
                                                "name": "department",
                                                "_sortIndex": 40
                                            },
                                            {
                                                "id": "5979e880-8e9e-11ea-9c0f-c7e69d58f37d",
                                                "type": "field:file",
                                                "children": [],
                                                "dataType": "file",
                                                "collection": "file",
                                                "via": "related",
                                                "allowedTypes": [
                                                    "images",
                                                    "files",
                                                    "videos"
                                                ],
                                                "plugin": "upload",
                                                "name": "picture",
                                                "_sortIndex": 50
                                            }
                                        ],
                                        "name": "employee",
                                        "active": false,
                                        "_sortIndex": 120
                                    },
                                    {
                                        "id": "a59a2fd0-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                                "children": [
                                                    {
                                                        "id": "a5a55360-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a5a55362-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "employeeid",
                                                                "label": "employeeid",
                                                                "dataField": "employeeid"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a5a55363-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a5a55365-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "email",
                                                                "label": "email",
                                                                "dataField": "email"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a5a55366-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a5a55368-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "firstName",
                                                                "label": "firstName",
                                                                "dataField": "firstName"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a5a55369-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a5a5536b-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "lastName",
                                                                "label": "lastName",
                                                                "dataField": "lastName"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a5a5536c-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a5a5536e-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputDate",
                                                                "children": [],
                                                                "name": "birthday",
                                                                "label": "birthday",
                                                                "dataField": "birthday",
                                                                "format": "mm/dd/yyyy"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a5a5536f-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a5a55371-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "name": "department",
                                                                "label": "department",
                                                                "dataField": "department",
                                                                "saveObject": true,
                                                                "relation": "13adccc0-8ce8-11ea-a283-ebcb859bf8c8"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a5a55372-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a5a55374-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputMedia",
                                                                "children": [],
                                                                "name": "picture",
                                                                "label": "picture",
                                                                "dataField": "picture",
                                                                "basePath": "employees"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": "a5a26d30-912e-11ea-ad57-6526d978cdf6",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "employee-detail-page",
                                        "route": "/employees/:id",
                                        "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                        "_sortIndex": 130
                                    },
                                    {
                                        "id": "a5a79d50-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                                "children": [
                                                    {
                                                        "id": "a5aa8380-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "subView",
                                                        "children": [],
                                                        "name": "searchbar",
                                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                    },
                                                    {
                                                        "id": "a5ae05f0-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "a5b0c510-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "employeeid",
                                                                "label": "employeeid",
                                                                "dataField": "employeeid"
                                                            },
                                                            {
                                                                "id": "a5b0c512-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "email",
                                                                "label": "email",
                                                                "dataField": "email"
                                                            },
                                                            {
                                                                "id": "a5b0c514-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "firstName",
                                                                "label": "firstName",
                                                                "dataField": "firstName"
                                                            },
                                                            {
                                                                "id": "a5b0c516-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "lastName",
                                                                "label": "lastName",
                                                                "dataField": "lastName"
                                                            },
                                                            {
                                                                "id": "a5b0c518-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "birthday",
                                                                "label": "birthday",
                                                                "dataField": "birthday"
                                                            },
                                                            {
                                                                "id": "a5b0c51a-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "department",
                                                                "label": "department",
                                                                "dataField": "department"
                                                            },
                                                            {
                                                                "id": "a5b0c51c-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "picture",
                                                                "label": "picture",
                                                                "dataField": "picture"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "table"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "employee-list-page",
                                        "route": "/employees-list",
                                        "dataModel": "ec0dfe30-8c4a-11ea-9466-6f383d5eb483",
                                        "_sortIndex": 140
                                    },
                                    {
                                        "id": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "06bec240-911f-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "accountNumber",
                                                "_sortIndex": -1
                                            },
                                            {
                                                "id": "3f18fbb0-9129-11ea-a5e9-ff76f0b9af84",
                                                "type": "field:email",
                                                "children": [],
                                                "dataType": "email",
                                                "name": "email",
                                                "required": true,
                                                "_sortIndex": 0
                                            },
                                            {
                                                "id": "f15753e0-911e-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "firstName",
                                                "required": true,
                                                "_sortIndex": 10
                                            },
                                            {
                                                "id": "f67bdb20-911e-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "lastName",
                                                "required": true,
                                                "_sortIndex": 20
                                            },
                                            {
                                                "id": "fa966c70-911e-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "middleInitial",
                                                "_sortIndex": 30
                                            },
                                            {
                                                "id": "0cbdf210-911f-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "unit",
                                                "_sortIndex": 50
                                            },
                                            {
                                                "id": "101e3410-911f-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "building",
                                                "_sortIndex": 60
                                            },
                                            {
                                                "id": "138b4750-911f-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "contact",
                                                "_sortIndex": 70
                                            }
                                        ],
                                        "name": "homeowner",
                                        "_sortIndex": 150
                                    },
                                    {
                                        "id": "4840fc40-9149-11ea-a6b6-e7e287632ad5",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "4840fc41-9149-11ea-a6b6-e7e287632ad5",
                                                "children": [
                                                    {
                                                        "id": "55fa2b40-9149-11ea-a6b6-e7e287632ad5",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "My Account",
                                                        "className": "has-text-centered"
                                                    },
                                                    {
                                                        "id": "60c4c5d0-9149-11ea-a6b6-e7e287632ad5",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "22f17b80-914a-11ea-8caa-6d37b4107440",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "Account Name:"
                                                            },
                                                            {
                                                                "id": "24c48dd0-914a-11ea-8caa-6d37b4107440",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "name": "accountName",
                                                                "dataField": "firstName"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "accountName",
                                                        "className": "py-1"
                                                    },
                                                    {
                                                        "id": "370763a0-914a-11ea-8caa-6d37b4107440",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "43754df0-914a-11ea-8caa-6d37b4107440",
                                                                "type": "textBlock",
                                                                "children": [],
                                                                "text": "Email Address:"
                                                            },
                                                            {
                                                                "id": "4bf0a150-914a-11ea-8caa-6d37b4107440",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "name": "email",
                                                                "text": "",
                                                                "dataField": "email"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "email",
                                                        "className": "py-1"
                                                    },
                                                    {
                                                        "id": "39ff46e0-914a-11ea-8caa-6d37b4107440",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "46fa07e0-914a-11ea-8caa-6d37b4107440",
                                                                "type": "textBlock",
                                                                "children": [],
                                                                "text": "Contact Number:"
                                                            },
                                                            {
                                                                "id": "49cfbd20-914a-11ea-8caa-6d37b4107440",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "name": "phoneNumber",
                                                                "text": "",
                                                                "dataField": "contact"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "phoneNumber",
                                                        "className": "py-1"
                                                    },
                                                    {
                                                        "id": "47ffc560-a551-11ea-b01c-9d4389ff2ddd",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "56a0e360-a551-11ea-b01c-9d4389ff2ddd",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "6bd54d90-914a-11ea-8caa-6d37b4107440",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "text": "",
                                                                        "path": "/billing",
                                                                        "_sortIndex": -1,
                                                                        "className": "app-menu-item",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_balance.svg\"><img src=\"./assets/images/icon_balance.svg\" /></object>View Balance</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            },
                                                            {
                                                                "id": "67303cd0-a551-11ea-b01c-9d4389ff2ddd",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "6dde15e0-914a-11ea-8caa-6d37b4107440",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "text": "",
                                                                        "className": "app-menu-item",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pay.svg\"><img src=\"./assets/images/icon_pay.svg\" /></object>Pay Dues</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "columns is-centered mt-2"
                                                    },
                                                    {
                                                        "id": "7ac953d0-a551-11ea-b01c-9d4389ff2ddd",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "7e5f4bd0-a551-11ea-b01c-9d4389ff2ddd",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "6fb0b300-914a-11ea-8caa-6d37b4107440",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "text": "",
                                                                        "path": "/change-password",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_password.svg\"><img src=\"./assets/images/icon_password.svg\" /></object>Change Password</div>",
                                                                        "className": "app-menu-item"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            },
                                                            {
                                                                "id": "81fc96d0-a551-11ea-b01c-9d4389ff2ddd",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "718ee8e0-914a-11ea-8caa-6d37b4107440",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "text": "",
                                                                        "path": "/profile/me",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_profile.svg\"><img src=\"./assets/images/icon_profile.svg\" /></object>My Profile</div>",
                                                                        "className": "app-menu-item"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "columns is-centered"
                                                    }
                                                ],
                                                "component": "Account",
                                                "className": ""
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-account",
                                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "route": "/my-account/:id",
                                        "component": "",
                                        "_sortIndex": 160
                                    },
                                    {
                                        "id": "0e752c00-96d2-11ea-9995-9d94cc393546",
                                        "type": "page",
                                        "children": [
                                            {
                                                "id": "62b4dc20-96d2-11ea-8416-c79b465e39ab",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "6560c240-96d2-11ea-8416-c79b465e39ab",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Billing"
                                                    },
                                                    {
                                                        "id": "71d451e0-96d2-11ea-8416-c79b465e39ab",
                                                        "type": "container",
                                                        "children": [],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "7b996350-96d2-11ea-8416-c79b465e39ab",
                                                        "type": "container",
                                                        "children": [],
                                                        "orientation": "horizontal"
                                                    }
                                                ],
                                                "orientation": "vertical"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-billing",
                                        "route": "/billing",
                                        "_sortIndex": 170
                                    },
                                    {
                                        "id": "c886d3d0-914d-11ea-b2e4-0783314da7b1",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "c886d3d1-914d-11ea-b2e4-0783314da7b1",
                                                "children": [
                                                    {
                                                        "id": "def43920-9150-11ea-9407-3325ebf78f84",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Comments and Suggestions",
                                                        "_sortIndex": 0,
                                                        "name": "",
                                                        "className": "has-text-centered"
                                                    },
                                                    {
                                                        "id": "799fc430-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "7b5870b0-9156-11ea-88a4-7f4a31012a76",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "View complaint history",
                                                                "path": "/filed-complaints",
                                                                "variant": "text"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 9,
                                                        "className": "justify-content-center"
                                                    },
                                                    {
                                                        "id": "e0fd0170-9150-11ea-9407-3325ebf78f84",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "d8cc8d60-dac8-11ea-9725-dfc9cbd591dc",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "dataField": "type",
                                                                "label": "Type of issue"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 10,
                                                        "className": ""
                                                    },
                                                    {
                                                        "id": "e30f8dc0-9150-11ea-9407-3325ebf78f84",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a40486c0-9151-11ea-9407-3325ebf78f84",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "dataField": "details",
                                                                "label": "Details of the issue/concern",
                                                                "multiline": true,
                                                                "name": ""
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 20,
                                                        "className": ""
                                                    },
                                                    {
                                                        "id": "e70b4c70-9150-11ea-9407-3325ebf78f84",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "c7fa7580-9151-11ea-9407-3325ebf78f84",
                                                                "type": "inputMedia",
                                                                "children": [],
                                                                "label": "Photo",
                                                                "dataField": "photo"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 40,
                                                        "className": ""
                                                    },
                                                    {
                                                        "id": "e90aed00-9150-11ea-9407-3325ebf78f84",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "bd1f1210-9151-11ea-9407-3325ebf78f84",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "Save",
                                                                "command": "save",
                                                                "variant": "contained",
                                                                "color": "primary",
                                                                "className": "",
                                                                "fullWidth": true
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 50,
                                                        "className": ""
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-complaint",
                                        "route": "/file-complaint/:id",
                                        "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                        "className": "",
                                        "_sortIndex": 180
                                    },
                                    {
                                        "id": "47440520-971c-11ea-8a71-fd00fe88963d",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "47440521-971c-11ea-8a71-fd00fe88963d",
                                                "children": [
                                                    {
                                                        "id": "4b1a6180-971c-11ea-8a71-fd00fe88963d",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Complaints",
                                                        "className": "has-text-centered"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": "52a624c0-971c-11ea-8a71-fd00fe88963d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "5d6c5280-971c-11ea-8a71-fd00fe88963d",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "63bf6eb0-971c-11ea-8a71-fd00fe88963d",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "dataField": "item"
                                                            },
                                                            {
                                                                "id": "6749a6e0-971c-11ea-8a71-fd00fe88963d",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "dataField": "item"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "5a577160-971c-11ea-8a71-fd00fe88963d",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "dataModel": "d1683c00-914d-11ea-b2e4-0783314da7b1",
                                        "route": "/filed-complaints",
                                        "name": "homeowner-complaints",
                                        "className": "",
                                        "_sortIndex": 190
                                    },
                                    {
                                        "id": "4e8b5d80-9157-11ea-8382-2991800d56bc",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "4e8b5d81-9157-11ea-8382-2991800d56bc",
                                                "children": [
                                                    {
                                                        "id": "268a56f0-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "name": "",
                                                        "text": "Delivery/ Pull-out Pass"
                                                    },
                                                    {
                                                        "id": "3cd79170-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "40347810-da0a-11ea-87ea-f5980a7a1cab",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "View History",
                                                                "variant": "text"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "justify-content-center pt-1 pb-1"
                                                    },
                                                    {
                                                        "id": "590cca40-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "5f544db0-da0a-11ea-87ea-f5980a7a1cab",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                                "dataField": "unit",
                                                                "label": "Unit"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "66e4a4d0-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "6b30cd70-da0a-11ea-87ea-f5980a7a1cab",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "dataField": "type",
                                                                "label": "Type of Pass"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "7d085e50-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "d3f88870-da0a-11ea-87ea-f5980a7a1cab",
                                                                "type": "inputDateTime",
                                                                "children": [],
                                                                "dataField": "schedule",
                                                                "label": "Schedule"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "87486c20-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "8c652b30-da0a-11ea-87ea-f5980a7a1cab",
                                                                "type": "inputList",
                                                                "children": [],
                                                                "label": "Item(s) - Please enumerate including quantities.",
                                                                "dataField": "items"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a733cf20-da0a-11ea-87ea-f5980a7a1cab",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "ac4da800-da0a-11ea-87ea-f5980a7a1cab",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "dataField": "company",
                                                                "label": "Delivery Company"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "df2a4d60-dac7-11ea-9725-dfc9cbd591dc",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "e07a7410-dac7-11ea-9725-dfc9cbd591dc",
                                                                "type": "button",
                                                                "children": [],
                                                                "command": "save",
                                                                "text": "Submit",
                                                                "fullWidth": true,
                                                                "className": "mt-1"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-delivery-pass",
                                        "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                        "route": "/create-delivery-pass/:id",
                                        "className": "",
                                        "_sortIndex": 200
                                    },
                                    {
                                        "id": "db3a70c0-96c7-11ea-bb44-c34ef5a44500",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "db3a70c1-96c7-11ea-bb44-c34ef5a44500",
                                                "children": [
                                                    {
                                                        "id": "e5006c90-96c7-11ea-bb44-c34ef5a44500",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Delivery Passes"
                                                    },
                                                    {
                                                        "id": "e8824050-96c7-11ea-bb44-c34ef5a44500",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "f0fafba0-96c7-11ea-bb44-c34ef5a44500",
                                                                "type": "table",
                                                                "children": [
                                                                    {
                                                                        "id": "ae53bb10-96c8-11ea-bb44-c34ef5a44500",
                                                                        "type": "column",
                                                                        "children": [],
                                                                        "orientation": "horizontal",
                                                                        "dataField": "courier"
                                                                    },
                                                                    {
                                                                        "id": "b91bbd90-96c8-11ea-bb44-c34ef5a44500",
                                                                        "type": "column",
                                                                        "children": [],
                                                                        "orientation": "horizontal",
                                                                        "dataField": "items"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "path": "/create-delivery-pass"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "ed6551c0-96c7-11ea-bb44-c34ef5a44500",
                                                        "type": "container",
                                                        "children": [],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-delivery-passes",
                                        "route": "/delivery-passes",
                                        "dataModel": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                        "_sortIndex": 210
                                    },
                                    {
                                        "id": "0ad19880-93ea-11ea-99e4-a504a663bd48",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "0ad19881-93ea-11ea-99e4-a504a663bd48"
                                            },
                                            {
                                                "id": "1acacc70-93ea-11ea-9384-d1e4789a27a9",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e1156440-93e9-11ea-99e4-a504a663bd48"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-login-page",
                                        "component": "",
                                        "route": "/login",
                                        "_sortIndex": 240
                                    },
                                    {
                                        "id": "c2c1dd80-91b3-11ea-ba7f-61310864338b",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "c2c20490-91b3-11ea-ba7f-61310864338b",
                                                "children": [
                                                    {
                                                        "id": "d1894830-91b3-11ea-ba7f-61310864338b",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Notifications"
                                                    },
                                                    {
                                                        "id": "26512330-96da-11ea-a306-e3cce4ba1daa",
                                                        "type": "container",
                                                        "children": [],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "294a17e0-96da-11ea-a306-e3cce4ba1daa",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "2e1311a0-96da-11ea-a306-e3cce4ba1daa",
                                                                "type": "table",
                                                                "children": [
                                                                    {
                                                                        "id": "327a8a70-96da-11ea-a306-e3cce4ba1daa",
                                                                        "type": "column",
                                                                        "children": [],
                                                                        "orientation": "horizontal",
                                                                        "dataField": "name"
                                                                    },
                                                                    {
                                                                        "id": "346c2140-96da-11ea-a306-e3cce4ba1daa",
                                                                        "type": "column",
                                                                        "children": [],
                                                                        "orientation": "horizontal",
                                                                        "dataField": "text"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-notifications",
                                        "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                        "route": "/notifications",
                                        "_sortIndex": 250
                                    },
                                    {
                                        "id": "a259bcb0-96d2-11ea-8416-c79b465e39ab",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "a259bcb1-96d2-11ea-8416-c79b465e39ab",
                                                "children": [
                                                    {
                                                        "id": "a82b73e0-96d2-11ea-8416-c79b465e39ab",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Change password"
                                                    },
                                                    {
                                                        "id": "d3e59b00-96d2-11ea-8416-c79b465e39ab",
                                                        "type": "container",
                                                        "children": [],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "d63c5c40-96d2-11ea-8416-c79b465e39ab",
                                                        "type": "container",
                                                        "children": [],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": "aab74ee0-96d2-11ea-8416-c79b465e39ab",
                                                "type": "container",
                                                "children": [],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-password",
                                        "route": "/change-password",
                                        "_sortIndex": 260
                                    },
                                    {
                                        "id": "17466790-96d2-11ea-9995-9d94cc393546",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "0e752c01-96d2-11ea-9995-9d94cc393546",
                                                "children": [
                                                    {
                                                        "id": "8ad561c0-96d2-11ea-8416-c79b465e39ab",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Profile"
                                                    },
                                                    {
                                                        "id": "8de3b330-96d2-11ea-8416-c79b465e39ab",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "9c2e1e50-96d5-11ea-8416-c79b465e39ab",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "dataField": "firstName",
                                                                "label": "First Name"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a6f620d0-96d5-11ea-8416-c79b465e39ab",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "dataField": "lastName",
                                                        "label": "Last Name"
                                                    },
                                                    {
                                                        "id": "c5bbc880-96d5-11ea-8416-c79b465e39ab",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "save",
                                                        "text": "Save"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-profile",
                                        "route": "/profile/:id",
                                        "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                        "_sortIndex": 270
                                    },
                                    {
                                        "id": "df79f2a0-914a-11ea-8bb4-63310f210ad3",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "df79f2a1-914a-11ea-8bb4-63310f210ad3",
                                                "children": [
                                                    {
                                                        "id": "376608f0-914b-11ea-9d03-f552f48ec793",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Unit Request"
                                                    },
                                                    {
                                                        "id": "3e665510-914b-11ea-9d03-f552f48ec793",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "4c028630-914b-11ea-93c5-9720e806f72b",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "View service history",
                                                                "path": "/service-requests",
                                                                "className": "",
                                                                "variant": "text"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "justify-content-center pt-1 pb-1",
                                                        "flex": "[object Object]"
                                                    },
                                                    {
                                                        "id": "427db210-914b-11ea-9d03-f552f48ec793",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "bf2b7640-d902-11ea-b7fc-037f001b1342",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "name": "type",
                                                                "options": "[{ \"value\": \"repairs\", \"label\": \"Repairs\"},{ \"value\": \"renovation\", \"label\": \"Renovation\"},{ \"value\": \"carSticker\", \"label\": \"Car Sticker\", data: null }]",
                                                                "saveObject": true
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "justify-content-center pt-2 pb-2",
                                                        "name": "select"
                                                    },
                                                    {
                                                        "id": "44bc5770-914b-11ea-9d03-f552f48ec793",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a35c3b70-c90d-11ea-8c59-196c3ec4f839",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                                "name": "repairs"
                                                            },
                                                            {
                                                                "id": "2697efe0-c911-11ea-9dd0-6723feee229e",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                                "name": "renovation"
                                                            },
                                                            {
                                                                "id": "2a9de7c0-c911-11ea-9dd0-6723feee229e",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                                "name": "carSticker"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "pt-2 pb-2",
                                                        "name": "select",
                                                        "component": "UnitRequest"
                                                    },
                                                    {
                                                        "id": "2ac5bef0-ad21-11ea-8384-7b922845035e",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "316b40e0-ad21-11ea-8384-7b922845035e",
                                                                "type": "button",
                                                                "children": [],
                                                                "command": "save",
                                                                "variant": "",
                                                                "text": "submit",
                                                                "color": "primary",
                                                                "className": "",
                                                                "fullWidth": true
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ],
                                                "className": "p-1"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-service-request",
                                        "route": "/create-service-request/:id",
                                        "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                                        "_sortIndex": 280
                                    },
                                    {
                                        "id": "6053d8f0-91b4-11ea-ad0e-b15c87c4d0e0",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "6053d8f1-91b4-11ea-ad0e-b15c87c4d0e0",
                                                "children": [
                                                    {
                                                        "id": "bc5b9200-91b4-11ea-ad0e-b15c87c4d0e0",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Unit Request"
                                                    },
                                                    {
                                                        "id": "39f86670-91b5-11ea-ad0e-b15c87c4d0e0",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "3e960a70-91b5-11ea-ad0e-b15c87c4d0e0",
                                                                "type": "table",
                                                                "children": [
                                                                    {
                                                                        "id": "424ad510-91b5-11ea-ad0e-b15c87c4d0e0",
                                                                        "type": "column",
                                                                        "children": [],
                                                                        "orientation": "horizontal",
                                                                        "name": "",
                                                                        "dataField": "details"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "path": "/create-service-request"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "3c5ac070-91b5-11ea-ad0e-b15c87c4d0e0",
                                                        "type": "container",
                                                        "children": [],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-service-requests",
                                        "dataModel": "f40d79c0-914b-11ea-93c5-9720e806f72b",
                                        "route": "/service-requests",
                                        "_sortIndex": 290
                                    },
                                    {
                                        "id": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "24e082d0-8ef0-11ea-981d-018b5a72c307",
                                                "type": "menu",
                                                "children": [
                                                    {
                                                        "id": "26cac6a0-8ef0-11ea-981d-018b5a72c307",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "name": "home",
                                                        "_sortIndex": 0,
                                                        "label": "Main Menu",
                                                        "path": "/"
                                                    },
                                                    {
                                                        "id": "13a38940-912a-11ea-a5e9-ff76f0b9af84",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "_sortIndex": 10,
                                                        "name": "account",
                                                        "label": "My Account",
                                                        "path": "/my-account/me"
                                                    },
                                                    {
                                                        "id": "152c2510-912a-11ea-a5e9-ff76f0b9af84",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "_sortIndex": 20,
                                                        "name": "service",
                                                        "label": "Unit Request",
                                                        "path": "/homeowner-unit-request/0"
                                                    },
                                                    {
                                                        "id": "5968bca0-d9ac-11ea-b236-955d418fe434",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "name": "work-permit",
                                                        "path": "/homeowner-work-permit/0",
                                                        "label": "Work Permit"
                                                    },
                                                    {
                                                        "id": "166eb730-912a-11ea-a5e9-ff76f0b9af84",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "_sortIndex": 30,
                                                        "label": "Comments and Suggestions",
                                                        "name": "complaint",
                                                        "path": "/file-complaint/0"
                                                    },
                                                    {
                                                        "id": "17a5feb0-912a-11ea-a5e9-ff76f0b9af84",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "_sortIndex": 40,
                                                        "label": "Visitor Pass",
                                                        "name": "visitor",
                                                        "path": "/create-visitor-pass/0"
                                                    },
                                                    {
                                                        "id": "19b110f0-912a-11ea-a5e9-ff76f0b9af84",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "_sortIndex": 50,
                                                        "name": "delivery",
                                                        "label": "Delivery pass",
                                                        "path": "/create-delivery-pass/0"
                                                    },
                                                    {
                                                        "id": "58256b20-c92d-11ea-8660-8b92cdcd0efe",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "path": "/message-board",
                                                        "label": "Message Board",
                                                        "_sortIndex": 70
                                                    },
                                                    {
                                                        "id": "295939b0-8ef0-11ea-981d-018b5a72c307",
                                                        "type": "menuItem",
                                                        "children": [],
                                                        "name": "logout",
                                                        "_sortIndex": 71,
                                                        "label": "Logout",
                                                        "path": "/login"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-sidebar",
                                        "active": false,
                                        "_sortIndex": 300
                                    },
                                    {
                                        "id": "b3e97d20-9156-11ea-88a4-7f4a31012a76",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "b3e97d21-9156-11ea-88a4-7f4a31012a76",
                                                "children": [
                                                    {
                                                        "id": "cda662f0-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Visitor Pass",
                                                        "_sortIndex": 0,
                                                        "className": "has-text-centered"
                                                    },
                                                    {
                                                        "id": "24752260-9157-11ea-88a4-7f4a31012a76",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "80f84ec0-96c2-11ea-8d81-e3df0146485b",
                                                                "type": "button",
                                                                "children": [],
                                                                "path": "/visitor-passes",
                                                                "text": "My passes",
                                                                "variant": "text"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 9,
                                                        "className": "justify-content-center"
                                                    },
                                                    {
                                                        "id": "d2c56bf0-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "65ddc8e0-da1a-11ea-b50d-8323e621ee63",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                                "dataField": "unit",
                                                                "label": "Unit"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 10,
                                                        "className": ""
                                                    },
                                                    {
                                                        "id": "d4c90420-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "b1b7f790-da1a-11ea-b50d-8323e621ee63",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "dataField": "type",
                                                                "label": "Type of Pass",
                                                                "options": "[{\"label\":\"Visitor Pass\", \"value\":\"visitor-pass\"},{\"label\":\"Short-term Tenant (Airbnb)\", \"value\":\"short-term\"}, {\"label\":\"Long-term Tenant\", \"value\":\"long-term\"}]",
                                                                "saveObject": true
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 20,
                                                        "className": ""
                                                    },
                                                    {
                                                        "id": "d80f2e70-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0ca06880-da1c-11ea-87e9-9de57275fc27",
                                                                "type": "subView",
                                                                "children": [],
                                                                "name": "homeowner-visitor-pass-visitor-pass",
                                                                "view": "824f51f0-da1b-11ea-87e9-9de57275fc27"
                                                            },
                                                            {
                                                                "id": "f591eef0-da1d-11ea-abba-db7aa6b9e7d7",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                                                "name": "homeowner-visitor-pass-short-term"
                                                            },
                                                            {
                                                                "id": "8667e010-da1e-11ea-abba-db7aa6b9e7d7",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                                                "name": "homeowner-visitor-pass-long-term"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 30,
                                                        "className": "justify-content-center",
                                                        "component": "VisitorPass"
                                                    },
                                                    {
                                                        "id": "da09ed00-9156-11ea-88a4-7f4a31012a76",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "fdeeebd0-9156-11ea-88a4-7f4a31012a76",
                                                                "type": "button",
                                                                "children": [],
                                                                "command": "save",
                                                                "text": "Submit",
                                                                "variant": "",
                                                                "fullWidth": true,
                                                                "className": "mt-1"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "_sortIndex": 40,
                                                        "className": "justify-content-center"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-visitor-pass",
                                        "route": "/create-visitor-pass/:id",
                                        "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                                        "className": "",
                                        "_sortIndex": 310
                                    },
                                    {
                                        "id": "09704a70-96c1-11ea-8d81-e3df0146485b",
                                        "type": "page",
                                        "children": [
                                            {
                                                "id": "bf9e7ca0-96c5-11ea-8624-43dc05a96ae0",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c95f4850-96c5-11ea-8624-43dc05a96ae0",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Visitor Passes"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": -1
                                            },
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "09704a71-96c1-11ea-8d81-e3df0146485b",
                                                "children": [
                                                    {
                                                        "id": "6534ea90-96c2-11ea-8d81-e3df0146485b",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "678b84c0-96c2-11ea-8d81-e3df0146485b",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "dataField": "visitor"
                                                            },
                                                            {
                                                                "id": "6a228350-96c2-11ea-8d81-e3df0146485b",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "dataField": "purpose"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "path": "/create-visitor-pass"
                                                    }
                                                ],
                                                "_sortIndex": 0
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-visitor-passes",
                                        "dataModel": "a5454c50-911e-11ea-9e19-2d2007118721",
                                        "route": "/visitor-passes",
                                        "_sortIndex": 320
                                    },
                                    {
                                        "id": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "29bfe9a0-97d6-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a1eade70-8ef4-11ea-9b90-bfc3ee1ce33c",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Welcome",
                                                        "_sortIndex": 0,
                                                        "component": ""
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 0,
                                                "className": "columns is-centered pb-1"
                                            },
                                            {
                                                "id": "78539d80-9148-11ea-a6b6-e7e287632ad5",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c87fc130-9148-11ea-a6b6-e7e287632ad5",
                                                        "type": "button",
                                                        "children": [],
                                                        "text": "View Notifications",
                                                        "path": "/notifications",
                                                        "className": "",
                                                        "variant": "text"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "_sortIndex": 10,
                                                "className": "columns is-centered p-2"
                                            },
                                            {
                                                "id": "2124bcc0-97d7-11ea-a9fa-07f95ad6f0c6",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "25f3f810-97d7-11ea-a9fa-07f95ad6f0c6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "d37f2010-97da-11ea-a9fa-07f95ad6f0c6",
                                                                "type": "button",
                                                                "children": [],
                                                                "path": "/my-account/me",
                                                                "name": "My Account",
                                                                "className": "app-menu-item",
                                                                "icon": "",
                                                                "text": "",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_account.svg\"><img src=\"./assets/images/icon_account.svg\" /></object>My Account</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "2ef5e090-97d7-11ea-a9fa-07f95ad6f0c6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "856e1b80-9148-11ea-a6b6-e7e287632ad5",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "",
                                                                "path": "/homeowner-unit-request/0",
                                                                "flex": "[object Object]",
                                                                "className": "app-menu-item",
                                                                "_sortIndex": 10,
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_service.svg\"><img src=\"./assets/images/icon_service.svg\" /></object>Unit Request</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "351a2490-97d7-11ea-a9fa-07f95ad6f0c6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "874e7440-9148-11ea-a6b6-e7e287632ad5",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "",
                                                                "path": "/file-complaint/0",
                                                                "_sortIndex": 20,
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_complaint.svg\"><img src=\"./assets/images/icon_complaint.svg\" /></object>Comments and Suggestions</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "38053690-97d7-11ea-a9fa-07f95ad6f0c6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                                "type": "button",
                                                                "children": [
                                                                    {
                                                                        "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                                        "type": "container",
                                                                        "children": [
                                                                            {
                                                                                "id": "88e8ea60-9148-11ea-a6b6-e7e287632ad5",
                                                                                "type": "button",
                                                                                "children": [],
                                                                                "text": "",
                                                                                "path": "/create-visitor-pass/0",
                                                                                "_sortIndex": 30,
                                                                                "className": "app-menu-item",
                                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                                            }
                                                                        ],
                                                                        "orientation": "horizontal",
                                                                        "className": "app-menu-item-container"
                                                                    }
                                                                ],
                                                                "text": "",
                                                                "path": "/create-visitor-pass/0",
                                                                "_sortIndex": 30,
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_visitor.svg\"><img src=\"./assets/images/icon_visitor.svg\" /></object>Visitor Pass</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "40c3fb90-97d7-11ea-a9fa-07f95ad6f0c6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "8ac2b370-9148-11ea-a6b6-e7e287632ad5",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "",
                                                                "path": "/create-delivery-pass/0",
                                                                "_sortIndex": 40,
                                                                "className": "app-menu-item",
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_delivery.svg\"><img src=\"./assets/images/icon_delivery.svg\" /></object>Delivery Pass</div>"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "2290a530-c921-11ea-9848-69dad3ad605b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "2b49ebf0-c921-11ea-9848-69dad3ad605b",
                                                                "type": "button",
                                                                "children": [],
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_board.svg\"><img src=\"./assets/images/icon_board.svg\" /></object>Message Board</div>",
                                                                "className": "app-menu-item",
                                                                "path": "/message-board"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "columns is-centered",
                                                "_sortIndex": 19
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-welcome",
                                        "active": false,
                                        "className": "column is-centered",
                                        "component": "",
                                        "_sortIndex": 330
                                    },
                                    {
                                        "id": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                                        "type": "app",
                                        "children": [],
                                        "name": "homeowners",
                                        "sidebar": "1aad9460-8ef0-11ea-981d-018b5a72c307",
                                        "startPage": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c",
                                        "active": false,
                                        "_sortIndex": 340
                                    },
                                    {
                                        "id": "e1156440-93e9-11ea-99e4-a504a663bd48",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "eb7f4134-93e9-11ea-99e4-a504a663bd48",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "nodeType": "DIV",
                                                        "type": "container",
                                                        "className": "row",
                                                        "children": [
                                                            {
                                                                "nodeType": "DIV",
                                                                "type": "container",
                                                                "className": "col",
                                                                "children": [
                                                                    {
                                                                        "nodeType": "H1",
                                                                        "type": "header",
                                                                        "className": "h1",
                                                                        "children": [],
                                                                        "text": "Log In",
                                                                        "id": "eb7f4137-93e9-11ea-99e4-a504a663bd48"
                                                                    }
                                                                ],
                                                                "id": "eb7f4136-93e9-11ea-99e4-a504a663bd48"
                                                            }
                                                        ],
                                                        "id": "eb7f4135-93e9-11ea-99e4-a504a663bd48"
                                                    },
                                                    {
                                                        "nodeType": "DIV",
                                                        "type": "container",
                                                        "className": "row mt-4",
                                                        "children": [
                                                            {
                                                                "nodeType": "DIV",
                                                                "type": "container",
                                                                "className": "col",
                                                                "children": [
                                                                    {
                                                                        "nodeType": "INPUT",
                                                                        "type": "inputText",
                                                                        "className": "form-control login-form-input",
                                                                        "children": [],
                                                                        "placeholder": "Email",
                                                                        "id": "eb7f413a-93e9-11ea-99e4-a504a663bd48",
                                                                        "name": "email",
                                                                        "variant": "standard"
                                                                    },
                                                                    {
                                                                        "id": "f6061850-ad8d-11ea-a81e-a3c623c48bc9",
                                                                        "type": "image",
                                                                        "children": [],
                                                                        "url": "./assets/images/icon_user.svg",
                                                                        "className": "login-icons"
                                                                    }
                                                                ],
                                                                "id": "eb7f4139-93e9-11ea-99e4-a504a663bd48"
                                                            }
                                                        ],
                                                        "id": "eb7f4138-93e9-11ea-99e4-a504a663bd48"
                                                    },
                                                    {
                                                        "nodeType": "DIV",
                                                        "type": "container",
                                                        "className": "row mt-3",
                                                        "children": [
                                                            {
                                                                "nodeType": "DIV",
                                                                "type": "container",
                                                                "className": "col",
                                                                "children": [
                                                                    {
                                                                        "nodeType": "INPUT",
                                                                        "type": "inputText",
                                                                        "className": "form-control login-form-input",
                                                                        "children": [],
                                                                        "placeholder": "Password",
                                                                        "id": "eb7f413d-93e9-11ea-99e4-a504a663bd48",
                                                                        "name": "password",
                                                                        "password": true,
                                                                        "variant": "standard"
                                                                    },
                                                                    {
                                                                        "id": "3a4be650-ad90-11ea-a81e-a3c623c48bc9",
                                                                        "type": "image",
                                                                        "children": [],
                                                                        "url": "./assets/images/icon_login_password.svg",
                                                                        "className": "login-icons"
                                                                    },
                                                                    {
                                                                        "id": "5c31f5f0-ad92-11ea-a2a7-ad35397c4d32",
                                                                        "type": "container",
                                                                        "children": [
                                                                            {
                                                                                "id": "5f285290-ad92-11ea-a2a7-ad35397c4d32",
                                                                                "type": "paragraph",
                                                                                "children": [],
                                                                                "text": "[Forgot your password?](https://www.froala.com)",
                                                                                "_sortIndex": -1
                                                                            }
                                                                        ],
                                                                        "orientation": "horizontal",
                                                                        "className": "mt-3 forgot-password-container",
                                                                        "flex": "[object Object]"
                                                                    }
                                                                ],
                                                                "id": "eb7f413c-93e9-11ea-99e4-a504a663bd48"
                                                            }
                                                        ],
                                                        "id": "eb7f413b-93e9-11ea-99e4-a504a663bd48"
                                                    },
                                                    {
                                                        "nodeType": "DIV",
                                                        "type": "container",
                                                        "className": "row mt-4",
                                                        "children": [
                                                            {
                                                                "nodeType": "DIV",
                                                                "type": "container",
                                                                "className": "col",
                                                                "children": [
                                                                    {
                                                                        "nodeType": "BUTTON",
                                                                        "type": "button",
                                                                        "className": "btn btn-outline-secondary",
                                                                        "children": [],
                                                                        "id": "eb7f4141-93e9-11ea-99e4-a504a663bd48",
                                                                        "command": "local_login",
                                                                        "text": "Login",
                                                                        "variant": "",
                                                                        "color": "primary"
                                                                    }
                                                                ],
                                                                "id": "eb7f4140-93e9-11ea-99e4-a504a663bd48"
                                                            }
                                                        ],
                                                        "id": "eb7f413f-93e9-11ea-99e4-a504a663bd48"
                                                    }
                                                ],
                                                "orientation": "vertical",
                                                "nodeType": "DIV",
                                                "className": "fdb-box px-2"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "login",
                                        "component": "Login",
                                        "_sortIndex": 350
                                    },
                                    {
                                        "id": "77e05b60-911e-11ea-9e19-2d2007118721",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "7bedcd50-911e-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "name",
                                                "required": true
                                            },
                                            {
                                                "id": "8093aeb0-911e-11ea-9e19-2d2007118721",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "text",
                                                "required": true
                                            },
                                            {
                                                "id": "88181ef0-911e-11ea-9e19-2d2007118721",
                                                "type": "field:date",
                                                "children": [],
                                                "dataType": "date",
                                                "format": "mm/dd/yyyy",
                                                "name": "noticeStart",
                                                "required": true
                                            },
                                            {
                                                "id": "97459e70-911e-11ea-9e19-2d2007118721",
                                                "type": "field:date",
                                                "children": [],
                                                "dataType": "date",
                                                "format": "mm/dd/yyyy",
                                                "name": "noticeEnd"
                                            },
                                            {
                                                "id": "9eb945d0-911e-11ea-9e19-2d2007118721",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "source"
                                            },
                                            {
                                                "id": "a096b760-9129-11ea-a5e9-ff76f0b9af84",
                                                "type": "field",
                                                "children": [],
                                                "dataType": "string",
                                                "name": "status"
                                            }
                                        ],
                                        "name": "notification",
                                        "_sortIndex": 360
                                    },
                                    {
                                        "id": "a8ec6810-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                                "children": [
                                                    {
                                                        "id": "a8fabff0-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a8fabff2-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "name",
                                                                "label": "name",
                                                                "dataField": "name"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a8fabff3-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a8fabff5-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "text",
                                                                "label": "text",
                                                                "dataField": "text"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a8fabff6-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a8fabff8-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputDate",
                                                                "children": [],
                                                                "name": "noticeStart",
                                                                "label": "noticeStart",
                                                                "dataField": "noticeStart",
                                                                "format": "mm/dd/yyyy"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a8fabff9-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a8fabffb-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputDate",
                                                                "children": [],
                                                                "name": "noticeEnd",
                                                                "label": "noticeEnd",
                                                                "dataField": "noticeEnd",
                                                                "format": "mm/dd/yyyy"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a8fabffc-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a8fabffe-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "source",
                                                                "label": "source",
                                                                "dataField": "source"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "a8fabfff-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a8fac001-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "status",
                                                                "label": "status",
                                                                "dataField": "status"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": "a8f7b2b0-912e-11ea-ad57-6526d978cdf6",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "notification-detail-page",
                                        "route": "/notifications/:id",
                                        "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                        "_sortIndex": 370
                                    },
                                    {
                                        "id": "a8fdf440-912e-11ea-ad57-6526d978cdf6",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "942e0af1-912e-11ea-ad57-6526d978cdf6",
                                                "children": [
                                                    {
                                                        "id": "a9012890-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "subView",
                                                        "children": [],
                                                        "name": "searchbar",
                                                        "view": "e7db5107-8c4a-11ea-9466-6f383d5eb483"
                                                    },
                                                    {
                                                        "id": "a9045ce0-912e-11ea-ad57-6526d978cdf6",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "a907b840-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "name",
                                                                "label": "name",
                                                                "dataField": "name"
                                                            },
                                                            {
                                                                "id": "a907b842-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "text",
                                                                "label": "text",
                                                                "dataField": "text"
                                                            },
                                                            {
                                                                "id": "a907b844-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "noticeStart",
                                                                "label": "noticeStart",
                                                                "dataField": "noticeStart"
                                                            },
                                                            {
                                                                "id": "a907b846-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "noticeEnd",
                                                                "label": "noticeEnd",
                                                                "dataField": "noticeEnd"
                                                            },
                                                            {
                                                                "id": "a907b848-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "source",
                                                                "label": "source",
                                                                "dataField": "source"
                                                            },
                                                            {
                                                                "id": "a907b84a-912e-11ea-ad57-6526d978cdf6",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "status",
                                                                "label": "status",
                                                                "dataField": "status"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "table"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "notification-list-page",
                                        "route": "/notifications-list",
                                        "dataModel": "77e05b60-911e-11ea-9e19-2d2007118721",
                                        "_sortIndex": 380
                                    },
                                    {
                                        "id": "8345d560-9129-11ea-a5e9-ff76f0b9af84",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "c4081f00-d9c6-11ea-93cf-3dd7e562aac3",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "name": "unit"
                                            },
                                            {
                                                "id": "d7708910-d9c6-11ea-93cf-3dd7e562aac3",
                                                "type": "field:enumeration",
                                                "children": [],
                                                "dataType": "enumeration",
                                                "name": "type"
                                            },
                                            {
                                                "id": "e5db54d0-d9c6-11ea-93cf-3dd7e562aac3",
                                                "type": "field:date",
                                                "children": [],
                                                "dataType": "date",
                                                "format": "mm/dd/yyyy",
                                                "name": "schedule"
                                            },
                                            {
                                                "id": "ee4c9610-d9c6-11ea-93cf-3dd7e562aac3",
                                                "type": "field:json",
                                                "children": [],
                                                "dataType": "json",
                                                "name": "items"
                                            },
                                            {
                                                "id": "f2ed6e60-d9c6-11ea-93cf-3dd7e562aac3",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "company"
                                            },
                                            {
                                                "id": "00b0fda0-d9c7-11ea-93cf-3dd7e562aac3",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "status"
                                            }
                                        ],
                                        "name": "pass-delivery",
                                        "_sortIndex": 390
                                    },
                                    {
                                        "id": "a5454c50-911e-11ea-9e19-2d2007118721",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "c7d2c620-d9c7-11ea-93cf-3dd7e562aac3",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "name": "unit"
                                            },
                                            {
                                                "id": "cfdbf6c0-d9c7-11ea-93cf-3dd7e562aac3",
                                                "type": "field:json",
                                                "children": [],
                                                "dataType": "json",
                                                "name": "visitor",
                                                "required": true
                                            },
                                            {
                                                "id": "fa4d2320-d9c7-11ea-93cf-3dd7e562aac3",
                                                "type": "field:date",
                                                "children": [],
                                                "dataType": "date",
                                                "format": "mm/dd/yyyy",
                                                "name": "startDate"
                                            },
                                            {
                                                "id": "ff0ec9e0-d9c7-11ea-93cf-3dd7e562aac3",
                                                "type": "field:date",
                                                "children": [],
                                                "dataType": "date",
                                                "format": "mm/dd/yyyy",
                                                "name": "endDate"
                                            },
                                            {
                                                "id": "06a73750-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:file",
                                                "children": [],
                                                "dataType": "file",
                                                "collection": "file",
                                                "via": "related",
                                                "allowedTypes": [
                                                    "images",
                                                    "files",
                                                    "videos"
                                                ],
                                                "plugin": "upload",
                                                "name": "visitorsId"
                                            },
                                            {
                                                "id": "7b087c60-da1a-11ea-b50d-8323e621ee63",
                                                "type": "field:enumeration",
                                                "children": [],
                                                "dataType": "enumeration",
                                                "name": "type"
                                            }
                                        ],
                                        "name": "pass-visitor",
                                        "_sortIndex": 420
                                    },
                                    {
                                        "id": "555659e0-9138-11ea-bd78-399a56c40918",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "5a734000-9138-11ea-bd78-399a56c40918",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "2ad37a40-9138-11ea-bd78-399a56c40918",
                                                "name": "billing"
                                            },
                                            {
                                                "id": "5ecc8800-9138-11ea-bd78-399a56c40918",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                                "name": "homeowner"
                                            },
                                            {
                                                "id": "697da720-9138-11ea-bd78-399a56c40918",
                                                "type": "field:number",
                                                "children": [],
                                                "dataType": "number",
                                                "name": "amount"
                                            }
                                        ],
                                        "name": "payment",
                                        "_sortIndex": 450
                                    },
                                    {
                                        "type": "view",
                                        "name": "searchbar",
                                        "orientation": "horizontal",
                                        "component": "Searchbar",
                                        "children": [
                                            {
                                                "name": "search",
                                                "type": "inputText",
                                                "id": "e7db5108-8c4a-11ea-9466-6f383d5eb483"
                                            },
                                            {
                                                "id": "e0073b50-8c64-11ea-b629-0161308cc31c",
                                                "type": "button",
                                                "children": [],
                                                "command": "new",
                                                "name": "",
                                                "text": "New"
                                            }
                                        ],
                                        "id": "e7db5107-8c4a-11ea-9466-6f383d5eb483",
                                        "active": false,
                                        "_sortIndex": 460
                                    },
                                    {
                                        "id": "63850e20-a55c-11ea-8feb-c7034be1bc66",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "63850e21-a55c-11ea-8feb-c7034be1bc66",
                                                "children": [
                                                    {
                                                        "id": "da5a80c0-a55c-11ea-99ce-d1e75946b32d",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Marketplace"
                                                    },
                                                    {
                                                        "id": "ec182100-a55c-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "f232a100-a55c-11ea-99ce-d1e75946b32d",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "07f88310-a55d-11ea-99ce-d1e75946b32d",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "className": "app-menu-item",
                                                                        "text": "",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_water.svg\"><img src=\"./assets/images/icon_water.svg\" /></object>Drinking Water</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            },
                                                            {
                                                                "id": "014dd100-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "26cecc90-a55d-11ea-99ce-d1e75946b32d",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "className": "app-menu-item",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_gas.svg\"><img src=\"./assets/images/icon_gas.svg\" /></object>Gasul</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "columns is-centered mt-2"
                                                    },
                                                    {
                                                        "id": "b1d7e880-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "494d3680-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "516b0090-a55d-11ea-99ce-d1e75946b32d",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "className": "app-menu-item",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_rice.svg\"><img src=\"./assets/images/icon_rice.svg\" /></object>Rice</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            },
                                                            {
                                                                "id": "63098010-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "69f72e90-a55d-11ea-99ce-d1e75946b32d",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "className": "app-menu-item",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_laundry.svg\"><img src=\"./assets/images/icon_laundry.svg\" /></object>Laundry</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "columns is-centered"
                                                    },
                                                    {
                                                        "id": "c6888a00-a55d-11ea-99ce-d1e75946b32d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "ccd51680-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "d4ae5d80-a55d-11ea-99ce-d1e75946b32d",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_pet.svg\"><img src=\"./assets/images/icon_pet.svg\" /></object>Pet Grooming</div>",
                                                                        "className": "app-menu-item"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            },
                                                            {
                                                                "id": "f062cd90-a55d-11ea-99ce-d1e75946b32d",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "f2893000-a55d-11ea-99ce-d1e75946b32d",
                                                                        "type": "button",
                                                                        "children": [],
                                                                        "className": "app-menu-item",
                                                                        "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_cleaning.svg\"><img src=\"./assets/images/icon_cleaning.svg\" /></object>Cleaning Service</div>"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "className": "app-menu-item-container"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "columns is-centered"
                                                    }
                                                ],
                                                "className": "has-text-centered",
                                                "_sortIndex": -1
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-marketplace",
                                        "route": "/market-place",
                                        "_sortIndex": 470
                                    },
                                    {
                                        "id": "b8fe9280-c6f5-11ea-b415-c195f50f114e",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "aca19b50-c6f9-11ea-a851-fde2cfff9ce9",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ae765b50-c6f9-11ea-a851-fde2cfff9ce9",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Select Unit"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "columns is-centered pb-1"
                                            },
                                            {
                                                "id": "d8bbe3d0-c6f9-11ea-a851-fde2cfff9ce9",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "dde9e0f0-c6f9-11ea-a851-fde2cfff9ce9",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "029256d0-c6fa-11ea-a851-fde2cfff9ce9",
                                                                "type": "button",
                                                                "children": [],
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 01</div>",
                                                                "className": "app-menu-item",
                                                                "path": "/welcome/01"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    },
                                                    {
                                                        "id": "472397a0-c6fa-11ea-a851-fde2cfff9ce9",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "53985fc0-c6fa-11ea-a851-fde2cfff9ce9",
                                                                "type": "button",
                                                                "children": [],
                                                                "html": "<div class=\"inner-content-button\"><object type=\"image/svg+xml\" data=\"./assets/images/icon_property.svg\"><img src=\"./assets/images/icon_property.svg\" /></object>Unit 02</div>",
                                                                "className": "app-menu-item",
                                                                "path": "/welcome/02"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "app-menu-item-container"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "columns is-centered pt-2"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-selection",
                                        "className": "",
                                        "_sortIndex": 480
                                    },
                                    {
                                        "id": "36a60df0-c6f9-11ea-8043-0381a4f882af",
                                        "type": "page",
                                        "children": [
                                            {
                                                "id": "478f4730-c6f9-11ea-8043-0381a4f882af",
                                                "type": "subView",
                                                "children": [],
                                                "view": "9c188b00-8ef4-11ea-9b90-bfc3ee1ce33c"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-welcome-page",
                                        "route": "/welcome/:id",
                                        "component": "",
                                        "_sortIndex": 490
                                    },
                                    {
                                        "id": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "743faf70-c90d-11ea-8c59-196c3ec4f839",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a9319f50-c911-11ea-98ab-e51558e3434e",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "label": "Type of Work",
                                                        "name": "meta.type",
                                                        "options": "[{\"value\": \"masonry\", \"label\": \"Masonry\"}, {\"value\": \"carpentry\", \"label\": \"Carpentry\"}, {\"value\": \"electrical\", \"label\": \"Electrical\"}, {\"value\": \"plumbing\", \"label\": \"Plumbing\"}, {\"value\": \"communication\", \"label\": \"Communication\"}, {\"value\": \"painting\", \"label\": \"Painting\"}]",
                                                        "className": "bite-select"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "bite-select"
                                            },
                                            {
                                                "id": "c6681b80-c911-11ea-98ab-e51558e3434e",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c84dcb70-c911-11ea-98ab-e51558e3434e",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "label": "Work Details",
                                                        "name": "meta.details",
                                                        "multiline": true
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "cf0bde70-c911-11ea-98ab-e51558e3434e",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d46647a0-d908-11ea-8bc1-2d8749eeeb64",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "label": "Preferred Schedule",
                                                        "name": "meta.schedule"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": "bite-select"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-request-service-request",
                                        "_sortIndex": 500
                                    },
                                    {
                                        "id": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "e85ead40-c910-11ea-9dd0-6723feee229e",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "076a4bb0-c91e-11ea-831c-99fd7b91f646",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "meta.plans",
                                                        "label": "Plans Required",
                                                        "options": "[{ \"value\": \"standard\", \"label\": \"Standard\"},{ \"value\": \"as-built\", \"label\": \"As-built Plant\"}]",
                                                        "className": "bite-select",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "name": "homeowner-service-request-repairs",
                                                "className": "bite-select"
                                            },
                                            {
                                                "id": "112746d0-c91e-11ea-831c-99fd7b91f646",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "44b19ca0-d916-11ea-9ea3-7184c3941d0d",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "options": "[{ \"value\": \"A4\", \"label\": \"A4\"},{ \"value\": \"A3\", \"label\": \"A3\"}]",
                                                        "saveObject": true,
                                                        "name": "meta.paper-size",
                                                        "label": "Paper Size"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "97e9eee0-d916-11ea-9ea3-7184c3941d0d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9a6e28c0-d916-11ea-9ea3-7184c3941d0d",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "meta.type",
                                                        "label": "Type of Plan",
                                                        "options": "[{ \"value\": \"floor-plan\", \"label\": \"Floor Plan\"},{ \"value\": \"elevation\", \"label\": \"Elevation\"},{ \"value\": \"plumbing/sanitary\", \"label\": \"Plumbing / Sanitary\"},{ \"value\": \"electric-plant\", \"label\": \"Electric Plant\"}]",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-request-plans-documents",
                                        "_sortIndex": 510
                                    },
                                    {
                                        "id": "063f7330-c911-11ea-9dd0-6723feee229e",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "03ee4ee0-c91f-11ea-b910-c30a77ee18ba",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "59be4b60-d917-11ea-9ea3-7184c3941d0d",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "meta.date",
                                                        "label": "Date of Bond Payment"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "1cea7cc0-c91f-11ea-b910-c30a77ee18ba",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "8dc40350-d917-11ea-9ea3-7184c3941d0d",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "meta.amount",
                                                        "label": "Amount"
                                                    }
                                                ],
                                                "orientation": "horizontal",
                                                "className": ""
                                            },
                                            {
                                                "id": "9be36e30-d917-11ea-9ea3-7184c3941d0d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9fea9e90-d917-11ea-9ea3-7184c3941d0d",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "meta.OR-no",
                                                        "label": "O.R./C.R. No."
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "b9eb6b30-d917-11ea-9ea3-7184c3941d0d",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "bb01bd30-d917-11ea-9ea3-7184c3941d0d",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "meta.inspection-date",
                                                        "description": "",
                                                        "label": "Inspection Date"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-request-refund-renovation-bond",
                                        "_sortIndex": 520
                                    },
                                    {
                                        "id": "231003d0-c920-11ea-9080-1563a0e60ff0",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "231003d1-c920-11ea-9080-1563a0e60ff0",
                                                "className": "p1",
                                                "children": [
                                                    {
                                                        "id": "709bda20-c920-11ea-9080-1563a0e60ff0",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Message Board",
                                                        "className": "has-text-centered"
                                                    },
                                                    {
                                                        "id": "53470b10-c921-11ea-84bc-97bd01295e5b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "566450a0-c921-11ea-84bc-97bd01295e5b",
                                                                "type": "button",
                                                                "children": [],
                                                                "variant": "text",
                                                                "text": "New Post",
                                                                "path": "/new-post"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "justify-content-center pt-1 pb-1"
                                                    },
                                                    {
                                                        "id": "136c6820-c95b-11ea-82c1-ab59a2213c10",
                                                        "type": "container",
                                                        "children": [],
                                                        "orientation": "horizontal",
                                                        "component": "MessageBoard"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-message-board",
                                        "route": "/message-board",
                                        "_sortIndex": 530
                                    },
                                    {
                                        "id": "233b7c70-c927-11ea-84bc-97bd01295e5b",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "233b7c71-c927-11ea-84bc-97bd01295e5b",
                                                "children": [
                                                    {
                                                        "id": "521b2680-c927-11ea-84bc-97bd01295e5b",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "New Post",
                                                        "className": "has-text-centered"
                                                    },
                                                    {
                                                        "id": "59e133a0-c927-11ea-84bc-97bd01295e5b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "62ac7bc0-c927-11ea-84bc-97bd01295e5b",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "name": "",
                                                                "label": "Post Topic",
                                                                "options": "[{\"value\": \"sale\", \"label\": \"Sale\"}, {\"value\": \"service\", \"label\": \"Service\"}, {\"value\": \"trade\", \"label\": \"Trade\"}, {\"value\": \"lf\", \"label\": \"LF (Looking For)\"}]",
                                                                "dataField": "topic"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "bite-select pt-2"
                                                    },
                                                    {
                                                        "id": "df291320-c927-11ea-8d76-f7e6b086de0b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "e2116600-c927-11ea-8d76-f7e6b086de0b",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "",
                                                                "label": "Title",
                                                                "dataField": "title"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "eb70b0c0-c927-11ea-8d76-f7e6b086de0b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "ed3236e0-c927-11ea-8d76-f7e6b086de0b",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "",
                                                                "label": "Price",
                                                                "dataField": "price"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "f42897f0-c927-11ea-8d76-f7e6b086de0b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "f98df8c0-c927-11ea-8d76-f7e6b086de0b",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "",
                                                                "label": "Description",
                                                                "multiline": true,
                                                                "dataField": "description"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "0b6fc2d0-c928-11ea-8d76-f7e6b086de0b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0d2784f0-c928-11ea-8d76-f7e6b086de0b",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "",
                                                                "label": "Contact Number",
                                                                "dataField": "contactNumber"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "373868e0-c928-11ea-8d76-f7e6b086de0b",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "38f5f760-c928-11ea-8d76-f7e6b086de0b",
                                                                "type": "inputMedia",
                                                                "children": [],
                                                                "name": "",
                                                                "label": "Add Photos",
                                                                "dataField": "photos"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "bite-select"
                                                    },
                                                    {
                                                        "id": "27ad7fc0-c92b-11ea-ad72-2b470c4935f8",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "28c00130-c92b-11ea-ad72-2b470c4935f8",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "Submit",
                                                                "fullWidth": true,
                                                                "command": "save",
                                                                "className": "mt-1"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-new-post",
                                        "route": "/new-post",
                                        "_sortIndex": 540,
                                        "dataModel": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3"
                                    },
                                    {
                                        "id": "c5cddbb0-c92f-11ea-baed-470d8f727899",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "c5cddbb1-c92f-11ea-baed-470d8f727899",
                                                "children": [
                                                    {
                                                        "id": "e1ed3730-c95e-11ea-a864-cfc8ec85c16d",
                                                        "type": "image",
                                                        "children": [],
                                                        "url": "/assets/images/example_furniture.png"
                                                    },
                                                    {
                                                        "id": "f4e74f10-c95e-11ea-a864-cfc8ec85c16d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "f7e2b4c0-c95e-11ea-a864-cfc8ec85c16d",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "name": "",
                                                                "text": "Sale"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "0bc61b30-c95f-11ea-a864-cfc8ec85c16d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0ceff530-c95f-11ea-a864-cfc8ec85c16d",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "Title: Selling Furniture"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "2f0738e0-c95f-11ea-a864-cfc8ec85c16d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "32075980-c95f-11ea-a864-cfc8ec85c16d",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "Price: Php 1000.00"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "4e2e1360-c95f-11ea-a864-cfc8ec85c16d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "5103c8a0-c95f-11ea-a864-cfc8ec85c16d",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet elit tellus, nec euismod elit vehicula eget. Morbi quis accumsan orci. Nam sed egestas est. Sed facilisis varius risus sit amet eleifend. Praesent consequat quam in lorem elementum, nec porttitor tellus iaculis. Mauris ultrices mauris eu erat ullamcorper, in molestie neque dapibus. Proin aliquet laoreet mi ac suscipit. Maecenas facilisis at nunc nec eleifend. Sed at nisl nisl. Praesent quis interdum augue. Aliquam pulvinar ligula quis purus bibendum malesuada non sed magna."
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "66519480-c95f-11ea-a864-cfc8ec85c16d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "68412f80-c95f-11ea-a864-cfc8ec85c16d",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "Contact Number: +639123456789"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "8919b1a0-c95f-11ea-a864-cfc8ec85c16d",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "8a3b4e40-c95f-11ea-a864-cfc8ec85c16d",
                                                                "type": "paragraph",
                                                                "children": [],
                                                                "text": "Comments"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "form-label"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-post",
                                        "route": "/post/:id",
                                        "_sortIndex": 550
                                    },
                                    {
                                        "id": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "a86f3310-d84e-11ea-acd1-c1cd20d0424a",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "number"
                                            },
                                            {
                                                "id": "b91aee20-d84e-11ea-acd1-c1cd20d0424a",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "address"
                                            },
                                            {
                                                "id": "bc50ebd0-d84e-11ea-acd1-c1cd20d0424a",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "description"
                                            },
                                            {
                                                "id": "c2e7a0b0-d84e-11ea-acd1-c1cd20d0424a",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "name": "homeowner",
                                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                            },
                                            {
                                                "id": "ae9f6c60-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "status"
                                            }
                                        ],
                                        "name": "unit",
                                        "_sortIndex": 560
                                    },
                                    {
                                        "id": "8a327e70-d772-11ea-bc04-91a5baad80fc",
                                        "type": "app",
                                        "children": [],
                                        "_sortIndex": 570
                                    },
                                    {
                                        "id": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "ffb19150-d84d-11ea-acd1-c1cd20d0424a",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "type"
                                            },
                                            {
                                                "id": "09a553e0-d84e-11ea-acd1-c1cd20d0424a",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "status"
                                            },
                                            {
                                                "id": "125e2570-d84e-11ea-acd1-c1cd20d0424a",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "name": "unit"
                                            }
                                        ],
                                        "name": "unit-request",
                                        "_sortIndex": 580
                                    },
                                    {
                                        "id": "0818cb00-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                                "children": [
                                                    {
                                                        "id": "0824d8f0-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0824d8f2-d84f-11ea-acd1-c1cd20d0424a",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "number",
                                                                "label": "number",
                                                                "dataField": "number"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "0824d8f3-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0824d8f5-d84f-11ea-acd1-c1cd20d0424a",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "address",
                                                                "label": "address",
                                                                "dataField": "address"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "0824d8f6-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0824d8f8-d84f-11ea-acd1-c1cd20d0424a",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "name": "description",
                                                                "label": "description",
                                                                "dataField": "description"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "0824d8f9-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0824d8fb-d84f-11ea-acd1-c1cd20d0424a",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "name": "homeowner",
                                                                "label": "homeowner",
                                                                "dataField": "homeowner",
                                                                "saveObject": true,
                                                                "relation": "ed8defd0-911e-11ea-9e19-2d2007118721"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": "081fa8d0-d84f-11ea-acd1-c1cd20d0424a",
                                                "type": "subView",
                                                "children": [],
                                                "view": "e7db5104-8c4a-11ea-9466-6f383d5eb483"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "unit-detail-page",
                                        "route": "/units/:id",
                                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "_sortIndex": 610
                                    },
                                    {
                                        "id": "0829e200-d84f-11ea-acd1-c1cd20d0424a",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "90dddfd1-d84e-11ea-acd1-c1cd20d0424a",
                                                "children": [
                                                    {
                                                        "id": "08310df0-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "subView",
                                                        "children": [],
                                                        "name": "searchbar",
                                                        "view": "7ab04570-9138-11ea-bd78-399a56c40918"
                                                    },
                                                    {
                                                        "id": "08341b30-d84f-11ea-acd1-c1cd20d0424a",
                                                        "type": "table",
                                                        "children": [
                                                            {
                                                                "id": "0838fd30-d84f-11ea-acd1-c1cd20d0424a",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "number",
                                                                "label": "number",
                                                                "dataField": "number"
                                                            },
                                                            {
                                                                "id": "0838fd32-d84f-11ea-acd1-c1cd20d0424a",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "address",
                                                                "label": "address",
                                                                "dataField": "address"
                                                            },
                                                            {
                                                                "id": "0838fd34-d84f-11ea-acd1-c1cd20d0424a",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "description",
                                                                "label": "description",
                                                                "dataField": "description"
                                                            },
                                                            {
                                                                "id": "0838fd36-d84f-11ea-acd1-c1cd20d0424a",
                                                                "type": "column",
                                                                "children": [],
                                                                "orientation": "horizontal",
                                                                "name": "homeowner",
                                                                "label": "homeowner",
                                                                "dataField": "homeowner"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "name": "table"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "unit-list-page",
                                        "route": "/units-list",
                                        "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                        "_sortIndex": 620
                                    },
                                    {
                                        "id": "d2d04d10-d902-11ea-b7fc-037f001b1342",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "d2d04d11-d902-11ea-b7fc-037f001b1342",
                                                "className": "",
                                                "children": [
                                                    {
                                                        "id": "04c0e0a0-d903-11ea-b7fc-037f001b1342",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Unit Request",
                                                        "className": "has-text-centered"
                                                    },
                                                    {
                                                        "id": "afdbb6d0-d904-11ea-8fb7-85b0ea54cdde",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "bfa2b730-d904-11ea-8fb7-85b0ea54cdde",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "View Unit Requests History",
                                                                "variant": "text"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "justify-content-center pt-1 pb-1"
                                                    },
                                                    {
                                                        "id": "84f0dbb0-d906-11ea-bd61-bf1e0d1003bd",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "020ce720-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "04ead9c0-d9a6-11ea-891d-3722b00ad4e7",
                                                                        "type": "inputSelect",
                                                                        "children": [],
                                                                        "name": "unit",
                                                                        "label": "Unit",
                                                                        "dataField": "",
                                                                        "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "name": "unit-container"
                                                            },
                                                            {
                                                                "id": "21b5e9f0-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "container",
                                                                "children": [
                                                                    {
                                                                        "id": "29662520-d9a6-11ea-891d-3722b00ad4e7",
                                                                        "type": "inputSelect",
                                                                        "children": [],
                                                                        "saveObject": true,
                                                                        "options": "[{\"label\": \"Service Request\", \"value\": \"service-request\"},{\"label\":\"Request for Plans and Documents\", \"value\":\"plans-documents\"},{\"label\":\"Request for Refund of Renovation Bond\", \"value\":\"refund-renovation-bond\"},{\"label\":\"Request for Vehicle Sticker\", \"value\":\"vehicle-sticker\"},{\"label\":\"Request for Grease Trap Cleaning\", \"value\":\"grease-trap-cleaning\"},{\"label\":\"Request for Aircon Cleaning\", \"value\":\"aircon-cleaning\"},{\"label\":\"Others\", \"value\":\"others\"}]",
                                                                        "dataField": "type",
                                                                        "name": "type",
                                                                        "label": "Type"
                                                                    }
                                                                ],
                                                                "orientation": "horizontal",
                                                                "name": "type-container"
                                                            },
                                                            {
                                                                "id": "47ac1580-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "e00cd7b0-c90c-11ea-8c59-196c3ec4f839",
                                                                "name": "homeowner-unit-request-service-request"
                                                            },
                                                            {
                                                                "id": "60137d70-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "e60fdb40-c910-11ea-9dd0-6723feee229e",
                                                                "name": "homeowner-unit-request-plans-documents"
                                                            },
                                                            {
                                                                "id": "71865910-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "063f7330-c911-11ea-9dd0-6723feee229e",
                                                                "name": "homeowner-unit-request-refund-renovation-bond"
                                                            },
                                                            {
                                                                "id": "7c6788e0-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                                                "name": "homeowner-unit-request-vehicle-sticker"
                                                            },
                                                            {
                                                                "id": "82e844c0-d9a6-11ea-891d-3722b00ad4e7",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "0c744880-d9a5-11ea-a304-319701533d32",
                                                                "name": "homeowner-unit-request-grease-trap-cleaning"
                                                            },
                                                            {
                                                                "id": "30acac90-d9a7-11ea-9c8b-b7f572d43c0f",
                                                                "type": "subView",
                                                                "children": [],
                                                                "view": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                                                "name": "homeowner-unit-request-aircon-cleaning"
                                                            },
                                                            {
                                                                "id": "16922b80-d9a9-11ea-9331-090047662dd2",
                                                                "type": "subView",
                                                                "children": [],
                                                                "name": "homeowner-unit-request-others",
                                                                "view": "f7b9bf20-d9a8-11ea-9331-090047662dd2"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "component": "UnitRequest",
                                                        "name": ""
                                                    },
                                                    {
                                                        "id": "0dd9e6c0-dac8-11ea-9725-dfc9cbd591dc",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "0f33f880-dac8-11ea-9725-dfc9cbd591dc",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "Submit",
                                                                "fullWidth": true,
                                                                "className": "mt-1",
                                                                "command": "save"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-request",
                                        "className": "",
                                        "route": "/homeowner-unit-request/:id",
                                        "dataModel": "927b58a0-d84d-11ea-acd1-c1cd20d0424a",
                                        "_sortIndex": 630
                                    },
                                    {
                                        "id": "8df270e0-d918-11ea-b3eb-d9cf4159b042",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "9a02aad0-d918-11ea-b3eb-d9cf4159b042",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a6157cd0-d918-11ea-b3eb-d9cf4159b042",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "meta.parking-unit-number",
                                                        "label": "Parking Unit Number"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "02e8c340-d919-11ea-b3eb-d9cf4159b042",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "25111df0-d919-11ea-b3eb-d9cf4159b042",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "meta.status",
                                                        "label": "Status",
                                                        "options": "[{\"label\":\"Owned\", \"value\":\"owned\"},{\"label\":\"Rented\", \"value\":\"rented\"}]",
                                                        "saveObject": true
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "b33b28f0-d919-11ea-b3eb-d9cf4159b042",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "b80cd540-d919-11ea-b3eb-d9cf4159b042",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "meta.make",
                                                        "label": "Make"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "c394f370-d919-11ea-b3eb-d9cf4159b042",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c6827670-d919-11ea-b3eb-d9cf4159b042",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "meta.model",
                                                        "label": "Model"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "ad71c600-d9a4-11ea-a304-319701533d32",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "b1b90ca0-d9a4-11ea-a304-319701533d32",
                                                        "type": "inputSelect",
                                                        "children": [],
                                                        "name": "meta.color",
                                                        "label": "Color"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "c744cbe0-d9a4-11ea-a304-319701533d32",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "cfb34e00-d9a4-11ea-a304-319701533d32",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "meta.plate-number",
                                                        "label": "Plate Number"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "ddd18060-d9a4-11ea-a304-319701533d32",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "deacec40-d9a4-11ea-a304-319701533d32",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "meta.OR",
                                                        "description": "",
                                                        "label": "Upload O.R. / C.R."
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-request-vehicle-sticker",
                                        "_sortIndex": 640
                                    },
                                    {
                                        "id": "0c744880-d9a5-11ea-a304-319701533d32",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "1c9ddb40-d9a5-11ea-a304-319701533d32",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "267c1a00-d9a5-11ea-a304-319701533d32",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "meta.schedule",
                                                        "description": "",
                                                        "label": "Schedule"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-request-grease-trap-cleaning",
                                        "_sortIndex": 660
                                    },
                                    {
                                        "id": "989101e0-d9a6-11ea-891d-3722b00ad4e7",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "d3e39f50-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d5efea10-d9a6-11ea-891d-3722b00ad4e7",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "meta.units",
                                                        "label": "No. of Units"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "db6aa7a0-d9a6-11ea-891d-3722b00ad4e7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "2d6be400-d9a8-11ea-b622-15dab671ce91",
                                                        "type": "inputDateTime",
                                                        "children": [],
                                                        "name": "meta.schedule",
                                                        "label": "Schedule"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-request-aircon-cleaning",
                                        "_sortIndex": 670
                                    },
                                    {
                                        "id": "f7b9bf20-d9a8-11ea-9331-090047662dd2",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "fe4688f0-d9a8-11ea-9331-090047662dd2",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ff497a00-d9a8-11ea-9331-090047662dd2",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "multiline": true,
                                                        "name": "meta.details",
                                                        "label": "Please Specify"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-unit-request-others",
                                        "_sortIndex": 680
                                    },
                                    {
                                        "id": "9923cf30-d9aa-11ea-b236-955d418fe434",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "a2da88c0-d9aa-11ea-b236-955d418fe434",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                "name": "unit"
                                            },
                                            {
                                                "id": "3c73c200-d9c7-11ea-93cf-3dd7e562aac3",
                                                "type": "field:enumeration",
                                                "children": [],
                                                "dataType": "enumeration",
                                                "name": "natureOfWork"
                                            },
                                            {
                                                "id": "e5adc5e0-d9aa-11ea-b236-955d418fe434",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "contractor"
                                            },
                                            {
                                                "id": "f6154c00-d9aa-11ea-b236-955d418fe434",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "personInCharge"
                                            },
                                            {
                                                "id": "007fec40-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "contactPersonInCharge"
                                            },
                                            {
                                                "id": "65eeb840-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:json",
                                                "children": [],
                                                "dataType": "json",
                                                "name": "workers"
                                            },
                                            {
                                                "id": "71f75110-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "description"
                                            },
                                            {
                                                "id": "7a2f59e0-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:json",
                                                "children": [],
                                                "dataType": "json",
                                                "name": "materials"
                                            },
                                            {
                                                "id": "84753410-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:json",
                                                "children": [],
                                                "dataType": "json",
                                                "name": "tools"
                                            },
                                            {
                                                "id": "8a20ecb0-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:date",
                                                "children": [],
                                                "dataType": "date",
                                                "format": "mm/dd/yyyy",
                                                "name": "startDate"
                                            },
                                            {
                                                "id": "9113f260-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:date",
                                                "children": [],
                                                "dataType": "date",
                                                "format": "mm/dd/yyyy",
                                                "name": "endDate"
                                            },
                                            {
                                                "id": "990a5e50-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:file",
                                                "children": [],
                                                "dataType": "file",
                                                "collection": "file",
                                                "via": "related",
                                                "allowedTypes": [
                                                    "images",
                                                    "files",
                                                    "videos"
                                                ],
                                                "plugin": "upload",
                                                "name": "plans"
                                            },
                                            {
                                                "id": "a6388b60-d9ab-11ea-b236-955d418fe434",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "status"
                                            }
                                        ],
                                        "name": "work-permit",
                                        "_sortIndex": 690
                                    },
                                    {
                                        "id": "af7f65f0-d9c8-11ea-93cf-3dd7e562aac3",
                                        "type": "model",
                                        "children": [
                                            {
                                                "id": "b3aa23e0-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:relation",
                                                "children": [],
                                                "dataType": "relation",
                                                "dataModel": "ed8defd0-911e-11ea-9e19-2d2007118721",
                                                "name": "homeowner"
                                            },
                                            {
                                                "id": "c20c3d10-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:enumeration",
                                                "children": [],
                                                "dataType": "enumeration",
                                                "name": "topic"
                                            },
                                            {
                                                "id": "d7f25150-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "title"
                                            },
                                            {
                                                "id": "dd8b6c50-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "price"
                                            },
                                            {
                                                "id": "e1c656e0-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "description"
                                            },
                                            {
                                                "id": "eb73c1a0-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "contactNumber"
                                            },
                                            {
                                                "id": "f1279090-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:file",
                                                "children": [],
                                                "dataType": "file",
                                                "collection": "file",
                                                "via": "related",
                                                "allowedTypes": [
                                                    "images",
                                                    "files",
                                                    "videos"
                                                ],
                                                "plugin": "upload",
                                                "name": "photos"
                                            },
                                            {
                                                "id": "ff6cabe0-d9c8-11ea-93cf-3dd7e562aac3",
                                                "type": "field:text",
                                                "children": [],
                                                "dataType": "text",
                                                "name": "status"
                                            }
                                        ],
                                        "_sortIndex": 729,
                                        "name": "message-board"
                                    },
                                    {
                                        "id": "dac583a0-d9ca-11ea-b444-450a6b52ef90",
                                        "type": "page",
                                        "children": [
                                            {
                                                "name": "main",
                                                "type": "container",
                                                "id": "dac583a1-d9ca-11ea-b444-450a6b52ef90",
                                                "children": [
                                                    {
                                                        "id": "f6978dd0-d9ca-11ea-b444-450a6b52ef90",
                                                        "type": "header",
                                                        "children": [],
                                                        "size": 2,
                                                        "text": "Work Permit",
                                                        "className": "has-text-centered"
                                                    },
                                                    {
                                                        "id": "a2b3bab0-da09-11ea-87ea-f5980a7a1cab",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "a4b07510-da09-11ea-87ea-f5980a7a1cab",
                                                                "type": "button",
                                                                "children": [],
                                                                "text": "View Work Permit History",
                                                                "variant": "text"
                                                            }
                                                        ],
                                                        "orientation": "horizontal",
                                                        "className": "justify-content-center pt-1 pb-1"
                                                    },
                                                    {
                                                        "id": "5ad52cd0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "607dff40-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "relation": "0ad92cf0-d772-11ea-89fb-4ff0b8ab7297",
                                                                "name": "unit",
                                                                "label": "Unit",
                                                                "dataField": "unit"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "9d05c150-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "bb60e760-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                                "type": "inputSelect",
                                                                "children": [],
                                                                "dataField": "natureOfWork",
                                                                "label": "Nature of Work"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "d06acc70-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "d1a632a0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "dataField": "contractor",
                                                                "label": "Name of Contractor"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "dfc94700-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "e0f19a60-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "dataField": "personInCharge",
                                                                "label": "Name of Person in Charge"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "ec2bbfa0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "eee8e3d0-d9cb-11ea-83f3-4b9e6a8a3ce6",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "dataField": "contactPersonInCharge",
                                                                "label": "Contact Number of Person in Charge"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "4f549340-d9cc-11ea-83f3-4b9e6a8a3ce6",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "12242dc0-d9cf-11ea-be94-03767312b052",
                                                                "type": "inputList",
                                                                "children": [],
                                                                "label": "Workers",
                                                                "dataField": "workers"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "77fa9030-da06-11ea-8a8e-430e67178950",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "7d094580-da06-11ea-8a8e-430e67178950",
                                                                "type": "inputText",
                                                                "children": [],
                                                                "multiline": true,
                                                                "dataField": "description",
                                                                "label": "Description of Work"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "f2f0d5b0-da06-11ea-8a8e-430e67178950",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "fe279f90-da06-11ea-8a8e-430e67178950",
                                                                "type": "inputList",
                                                                "children": [],
                                                                "dataField": "materials",
                                                                "label": "List of Materials"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "2ebf0bc0-da07-11ea-8a8e-430e67178950",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "33285950-da07-11ea-8a8e-430e67178950",
                                                                "type": "inputList",
                                                                "children": [],
                                                                "dataField": "tools",
                                                                "label": "List of Tools"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "3bd32120-da07-11ea-8a8e-430e67178950",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "3e8fd020-da07-11ea-8a8e-430e67178950",
                                                                "type": "inputDate",
                                                                "children": [],
                                                                "dataField": "startDate",
                                                                "label": "Proposed Start Date "
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "5217a910-da07-11ea-8a8e-430e67178950",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "558c3660-da07-11ea-8a8e-430e67178950",
                                                                "type": "inputDate",
                                                                "children": [],
                                                                "label": "Target End Date",
                                                                "dataField": "endDate"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "67781290-da07-11ea-8a8e-430e67178950",
                                                        "type": "container",
                                                        "children": [
                                                            {
                                                                "id": "6a6f0b70-da07-11ea-8a8e-430e67178950",
                                                                "type": "inputMedia",
                                                                "children": [],
                                                                "dataField": "plans",
                                                                "label": "Photo of Plans"
                                                            }
                                                        ],
                                                        "orientation": "horizontal"
                                                    },
                                                    {
                                                        "id": "3ad7b170-dac8-11ea-9725-dfc9cbd591dc",
                                                        "type": "button",
                                                        "children": [],
                                                        "command": "save",
                                                        "text": "Submit",
                                                        "className": "mt-1"
                                                    }
                                                ]
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-work-permit",
                                        "dataModel": "9923cf30-d9aa-11ea-b236-955d418fe434",
                                        "route": "/homeowner-work-permit/:id"
                                    },
                                    {
                                        "id": "824f51f0-da1b-11ea-87e9-9de57275fc27",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "94ab5510-da1b-11ea-87e9-9de57275fc27",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "9c1bc820-da1b-11ea-87e9-9de57275fc27",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "meta.relationship",
                                                        "label": "Relationship to Owner"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "b7ed0f00-da1b-11ea-87e9-9de57275fc27",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "b9749960-da1b-11ea-87e9-9de57275fc27",
                                                        "type": "inputList",
                                                        "children": [],
                                                        "name": "visitor",
                                                        "description": "",
                                                        "label": "Name/s"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "cb6d1fc0-da1b-11ea-87e9-9de57275fc27",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "cebbae80-da1b-11ea-87e9-9de57275fc27",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "startDate",
                                                        "label": "Start Date"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "d92934f0-da1b-11ea-87e9-9de57275fc27",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "db4adc70-da1b-11ea-87e9-9de57275fc27",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "endDate",
                                                        "label": "End Date"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "e6edfcb0-da1b-11ea-87e9-9de57275fc27",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "ed1bdda0-da1b-11ea-87e9-9de57275fc27",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "visitorsId",
                                                        "label": "Upload Valid ID (optional)"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-visitor-pass-visitor-pass"
                                    },
                                    {
                                        "id": "9667e740-da1d-11ea-abba-db7aa6b9e7d7",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "a0ff15c0-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "a2036660-da1d-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputList",
                                                        "children": [],
                                                        "name": "visitor",
                                                        "label": "Name/s"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "bb50c180-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "c70a7700-da1d-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "startDate",
                                                        "label": "Start Date"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "cdb2b810-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "d233d360-da1d-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "endDate",
                                                        "label": "End Date"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "e0d391d0-da1d-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "e4f28ff0-da1d-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "visitorsId",
                                                        "label": "Upload Valid ID/ Passport"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-visitor-pass-short-term"
                                    },
                                    {
                                        "id": "338e2890-da1e-11ea-abba-db7aa6b9e7d7",
                                        "type": "view",
                                        "children": [
                                            {
                                                "id": "38c26740-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "3d14aa60-da1e-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputList",
                                                        "children": [],
                                                        "name": "visitor",
                                                        "label": "Name/s"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "49eb4190-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "4b75d930-da1e-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputDate",
                                                        "children": [],
                                                        "name": "startDate",
                                                        "label": "Start Date"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "5279ced0-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "542d4b30-da1e-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputText",
                                                        "children": [],
                                                        "name": "endDate",
                                                        "label": "End Date"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "5ed590b0-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "603f80f0-da1e-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "visitorsId",
                                                        "label": "Upload valid ID/ Passport (required)"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            },
                                            {
                                                "id": "6f845900-da1e-11ea-abba-db7aa6b9e7d7",
                                                "type": "container",
                                                "children": [
                                                    {
                                                        "id": "710098c0-da1e-11ea-abba-db7aa6b9e7d7",
                                                        "type": "inputMedia",
                                                        "children": [],
                                                        "name": "meta.contract",
                                                        "label": "Upload Contract of Lease (required)"
                                                    }
                                                ],
                                                "orientation": "horizontal"
                                            }
                                        ],
                                        "orientation": "vertical",
                                        "name": "homeowner-visitor-pass-long-term"
                                    }
                                ],
                                "server": "pacificland.bitesizedigital.net",
                                "undoLength": 0,
                                "name": "pacific land - midpoint",
                                "_state": {},
                                "activeApp": "af1b6430-8eee-11ea-812f-f97d6ad426ae",
                                "_locked": false,
                                "updateKey": "3be954f0-d9ca-11ea-b444-450a6b52ef90"
                            },
                            "_id": "5f30e16ddf453329f6ef693d"
                        },
                        "_id": "5f30e16ddf453329f6ef693d",
                        "joined": true
                    },
                    "joined": true,
                    "activeAppName": "dashboard",
                    "_id": "5f30e16ddf453329f6ef693d"
                },
                "joined": true,
                "activeAppName": "homeowners",
                "_id": "5f30e16ddf453329f6ef693d"
            },
            "joined": true,
            "activeAppName": "dashboard",
            "_id": "5f30e16ddf453329f6ef693d"
        },
        "joined": true,
        "activeAppName": "homeowners",
        "_id": "5f38f1a9d3b9d51b787af797"
    },
    "joined": true,
    "activeAppName": "dashboard",
    "_id": "60032b045720c30df42fdc7e"
};

export { app }

