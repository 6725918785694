import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import events from 'libs/events';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import Btn from '@material-ui/core/Button';
import Icon from 'components/icons/Icon';

const Button = withRouter((props) => {
  let node = props.node;
  let context = props.context;
  const [disabled, setDisabled] = React.useState(node.disabled || false);

  const onButtonClick = async (evt) => {
    console.log(evt);
    if (node.command) {
      events.$emit(node.command, { ...node, row: props.row });

      if (node.command == 'save') {
        setTimeout(() => {
          if (context.hasErrors() == 0) {
            setDisabled(true);
          }
        }, 10);
      }

      setTimeout(() => {
        setDisabled(false);
      }, 6000);
    }

    if (node.path) {
      props.history.push(node.path);
    }
  };

  let text = node.text || node.name || 'button';
  let color = node.color || 'primary';
  let variant = node.variant || 'contained';
  let fullWidth = node.fullWidth || false;
  let _props = { ...props };
  delete _props.staticContext;
  let html = node.html || null;
  let startIcon = node.startIcon || null;
  let endIcon = node.endIcon || null;
  let type = node.isSubmit ? 'submit' : 'button';

  let classes = clsx(node.className);

  if (fullWidth) {
    classes = clsx(node.className, 'MuiButton-fullWidth');
  }

  React.useEffect(() => {
    console.log('rerendered');
  }, [disabled]);

  return (
    <div className={classes} style={{ padding: '2px' }}>
      <Btn
        {..._props}
        variant={variant}
        color={color}
        onClick={onButtonClick}
        fullWidth
        disabled={disabled}
        startIcon={startIcon ? <Icon icon={`mui${startIcon}`} /> : null}
        type={type}
      >
        {html ? (
          <div
            className="inner-button"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        ) : (
          text
        )}

        {endIcon && <Icon icon={endIcon} />}
      </Btn>
    </div>
  );
});

RenderRegistry.add({
  button: Button,
});
