export default {
  auth: {
    server: {
      url: 'https://myplv.ph',
    },
  },
  app: {
    id: 'pacificland',
    server: {
      url: 'https://myplv.ph',
    },
    mobile: false,
  },
};
